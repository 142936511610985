// importovanje komponenti 
import { useRef, useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import axios from '../api/axios';
import { Container, Row, Col, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import useAuth from '../hooks/useAuth';
import React from 'react';
import { API } from '../constants';

const LOGIN_URL = '/auth/login';
const COMPANY_URL = '/preduzeca/all';
const OPCIJEAPLIKACIJE_URL = '/opcijeAplikacije/all';
const OPCIJE_URL = '/opcijeAplikacije/allOpcije';
// komponenta koju kreiramo

const Login = () => {
    // definisanje konstanti koje koristimo za fokusiranje polja
    const navigate = useNavigate();
    const location = useLocation();
    const { setAuth, persist, setPersist } = useAuth();
    const userRef = useRef();
    const codeRef = useRef();
    const companyRef = useRef();
    const errRef = useRef();
    // definisanje stejtova i prva inicijalizacija; objekat i funkcija za taj objekat

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [company, setCompany] = useState(null);
    const [selectData, setSelectData] = useState([]);
    const [selectDataOpcijeAplikacije, setSelectDataOpcijeAplikacije] = useState([]);
    const [selectDataOpcije, setSelectDataOpcije] = useState([]);
    const [dataUser, setDataUser] = useState([]);
    const from = location.state?.from?.pathname || "/";
    const [passwordType, setPasswordType] = useState("password");

    const [errorInputPassword, setErrorInputPassword] = useState(false);
    const [errorInputName, setErrorInputName] = useState(false);
    const [errorInputCompany, setErrorInputCompany] = useState(false);
    const [errorMsgPassword, setErrorMsgPassword] = useState(false);
    const [errorMsgName, setErrorMsgName] = useState(false);
    const [errorMsgCompany, setErrorMsgCompany] = useState(false);

    const [modalErrorUserData, setModalErrorUserData] = useState(false);

    const togglePassword = () => {

        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }
    // povlacenje podataka iz lokal storidza
    const token = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : null

    const fetchData = () => {
        return axios.get(COMPANY_URL).then(result => {
            const res = result.data;
            setSelectData(res);
            return res
        }
        )
    }

    const fetchDataOpcijeAplikacije = () => {
        return axios.get(OPCIJEAPLIKACIJE_URL).then(result => {
            const res = result.data;
            localStorage.setItem("robno", res.robno);
            localStorage.setItem("finansije", res.finansije);
            localStorage.setItem("fakturisanje", res.fakturisanje);
            localStorage.setItem("blagajna", res.blagajna);
            localStorage.setItem("kufkif", res.kufkif);
            setSelectDataOpcijeAplikacije(res);
            return res
        }
        )
    }

    const fetchDataOpcije = () => {
        return axios.get(OPCIJE_URL).then(result => {
            const res = result.data;
            console.log(res, 'res')
            localStorage.setItem("zal_plus", res.zal_plus);
            localStorage.setItem("cjenovnik", res.cjenovnik);
            setSelectDataOpcije(res);
            return res
        }
        )
    }

    // const fetchDataUser = () => {
    //     return axios.post(LOGIN_URL).then(result => {
    //         const res = result.data;
    //         setSelectData(res);
    //         return res
    //     }
    //     )
    // }

    const fetchDataUser = () => {
        fetch(API + `/login`, {
            method: 'POST',
            headers: {
                //   'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setDataUser(data);
            })
    }

    // const fetchDataUser = async () => {
    //     const result = await axios.post(LOGIN_URL);
    //     const res = result.data;
    //     setDataUser(res);
    //     return res;
    // }
    // poziva se inicijalno pri otvaranju forma i poziva api za popunjavanje tabele

    useEffect(() => {
        userRef.current.focus();
        fetchData();
    }, [])

    useEffect(() => {
        fetchDataOpcijeAplikacije();
        fetchDataOpcije();
    }, [])

    // useEffect(() => {
    //     fetchDataUser();
    //     console.log('upada ovdje odmah')
    // }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])

    const handleSubmit = async (e) => {
        e.preventDefault();

        // uslov: ako nisu unesena obavezna polja, zaustavlja upis
        if (!unosIspravanDo('UPIS')) return;

        try {
            const response = await axios.post(LOGIN_URL,
                JSON.stringify({ user, pwd }),
                {

                    headers: { 'Content-Type': 'application/json' }
                }

            );
            localStorage.setItem("accessToken", JSON.stringify(response?.data?.token));
            localStorage.setItem("company", company.SIFRA);
            localStorage.setItem("companyNaziv", company.NAZIV);
            localStorage.setItem("companyYear", company.GODINA);
            //  localStorage.setItem("accessToken", JSON.stringify(response?.data?.token));
            localStorage.setItem("companyJib", company.IDENTBROJ);
            localStorage.setItem("companyTelephone", company.TELEFON);
            localStorage.setItem("companyPdv", company.PDVBROJ);
            localStorage.setItem("companyAddress", company.ADRESA);
            localStorage.setItem("companyPlaces", company.MJESTO);
            localStorage.setItem("companyMb", company.MATICNI);
            localStorage.setItem("BankAccount", company.ZIRORAC);
            localStorage.setItem("BankAccount1", company.ZIRORACKM);
            localStorage.setItem("pdv", company.PDV);

            localStorage.setItem("userCode", response?.data?.code);
            localStorage.setItem("ovlfik", response?.data?.ovlfik);
            localStorage.setItem("ovlrobno", response?.data?.ovlrobno);
            localStorage.setItem("ovlfakt", response?.data?.ovlfakt);
            localStorage.setItem("ovlblagajna", response?.data?.ovlblagajna);
            localStorage.setItem("ovlascenje", response?.data?.ovlascenje);

            // localStorage.setItem("zal_plus", response?.data?.zal_plus);
            // console.log(response?.data?.zal_plus, 'response?.data?.zal_plus')

            sessionStorage.setItem("isUserLogged", JSON.stringify(response?.data?.code));



            const accessToken = response?.data?.token;
            const roles = 3;
            setAuth({ user, pwd, roles, accessToken });
            setUser('');
            setPwd('');
            // if (company == '') {
            //     setErrMsg('Preduzece je obavezno')
            // } else {
            //     setSuccess(true);
            //     navigate(from, { replace: true });
            //     window.location.reload(true);
            // }

            setSuccess(true);
            navigate(from, { replace: true });
            window.location.reload(true);



        } catch (err) {
            // if (!err?.response) {
            //     setErrMsg('Nema odgovora od servera');
            // } else if (err.response?.status === 400) {
            //     setErrMsg('Unesite korisnicko ime ili lozinku');
            // } else if (err.response?.status === 401) {
            //     setErrMsg('Unauthorized');
            // } else {
            //     setErrMsg('Login Failed');
            // }
            // errRef.current.focus();

            if (!err?.response) {
                setErrMsg('Nema odgovora od servera');
            } else if (err.response?.data === 'Incorrect user and/or Password!') {
                setModalErrorUserData(true);
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
        }
    }

    // uslov: ako nije upisana ni vrijednost, izbaci poruku, setuje crveni okvir i fokusira polje  
    // u suprotnom prolazi dalje
    const checkNameRequired = (value) => {
        if (!value || value === 0) {
            setErrorMsgName('Korisničko ime je obavezno!')
            setErrorInputName(true)
            userRef.current.focus();
            return false
        } else {
            setErrorMsgName(null)
            setErrorInputName(false)
            return true
        }
    }

    // uslov: ako nije upisana ni vrijednost, izbaci poruku, setuje crveni okvir i fokusira polje  
    // u suprotnom prolazi dalje
    const checkCodeRequired = (value) => {
        if (!value || value === 0) {
            setErrorMsgPassword('Lozinka je obavezna!')
            setErrorInputPassword(true)
            codeRef.current.focus();
            return false
        } else {
            setErrorInputPassword(null)
            setErrorMsgPassword(false)
            return true
        }
    }


    // uslov: ako nije upisana ni vrijednost, izbaci poruku, setuje crveni okvir i fokusira polje  
    // u suprotnom prolazi dalje
    const checkCompanyRequired = (value) => {
        if (!value || value === 0) {
            setErrorMsgCompany('Preduzeće je obavezno!')
            setErrorInputCompany(true)
            companyRef.current.focus();
            return false
        } else {
            setErrorInputCompany(null)
            setErrorMsgCompany(false)
            return true
        }
    }

    // funkcija za kontrolu obaveznih polja
    function unosIspravanDo(name) {
        if (name === 'NAZIV') {
            return true
        }

        if (!checkNameRequired(user)) {
            return false
        }

        if (name === 'SIFRA') {
            return true
        }

        if (!checkCodeRequired(pwd)) {
            return false
        }

        if (name === 'PRED') {
            return true
        }

        if (!checkCompanyRequired(company)) {
            return false
        }

        if (name === 'PRIJAVA') {
            return true
        }

        return true
    }

    const defaultProps = {
        options: selectData,
        getOptionLabel: (option) => option ? `${option.SIFRA} ${option.NAZIV}` : '',
    };


    useEffect(() => {
    }, [company])

    return (

        <div className='login-container'>
            {/* //    <h3>Infosistem</h3> */}
            <h6>Prijava korisnika</h6>
            <form className='form' onSubmit={handleSubmit}>
                <div className={errorInputName ? 'inputWithError' : 'field-container'}>
                    <label htmlFor="username" id="media-query-label">Korisničko ime:</label>
                    <input
                        type="text"
                        id="username"
                        tabIndex="1"
                        ref={userRef}
                        autoComplete="off"
                        onChange={(e) => setUser(e.target.value)}
                        value={user}
                        onFocus={() => {
                            unosIspravanDo("NAZIV")
                        }}
                    //  required
                    />
                    {errorMsgName ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgName}</span> : ''}
                </div>

                <div className={errorInputPassword ? 'inputWithError' : 'field-container-number'}>
                    <label htmlFor="password" id="media-query-label">Lozinka:</label>
                    <div className="password-container">
                        <input
                            type={passwordType}
                            id="password"
                            tabIndex="2"
                            onChange={(e) => setPwd(e.target.value)}
                            value={pwd}
                            ref={codeRef}
                            onFocus={() => {
                                unosIspravanDo("SIFRA")
                            }}
                        //  required
                        />
                        <span onClick={() => togglePassword()}>
                            {passwordType === "password" ? <i className="far fa-eye"></i> : <i className="far fa-eye-slash"></i>}
                        </span>
                    </div>
                    {errorMsgPassword ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgPassword}</span> : ''}
                </div>

                <div className={errorInputCompany ? 'inputWithError' : 'field-container'}>
                    <Autocomplete
                        //ukoliko nema ove funkcije, u konzoli izbacuje upozorenje da nije vrijednost jedinstvena
                        isOptionEqualToValue={(option, value) => option.id === value.id}

                        // svaka stavka(child) u objektu company mora imati jedinstven kljuc
                        renderOption={(props, company) => {
                            return (
                                <li {...props} key={company.SIFRA}>
                                    <span>{company.SIFRA}</span>
                                    <span className="ps-1">{company.NAZIV}</span>
                                </li>
                            );
                        }}
                        {...defaultProps}
                        id="controlled-demo"
                        value={company}
                        disablePortal={true}
                        onFocus={() => {
                            unosIspravanDo("PRED")
                        }}
                        onChange={(event, newValue) => {
                            setCompany(newValue);
                        }}

                        renderInput={(params) => (
                            <TextField type={'select'} {...params}
                                tabIndex="3"
                                ref={companyRef}
                                placeholder=' Izaberite preduzeće'
                                variant="standard" />
                        )}
                    />
                    {errorMsgCompany ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgCompany}</span> : ''}
                </div>

                <button className='btn btn-primary'>Prijava</button>
                <p className="offscreen" onFocus={() => { unosIspravanDo("PRIJAVA") }} tabIndex="4" aria-live="assertive"></p>
            </form>

            {/* render za poruku */}
            <div>
                <Modal isOpen={Boolean(modalErrorUserData)} centered  >
                    <ModalHeader>Upozorenje!</ModalHeader>
                    <ModalBody>
                        <span>{`Korisnik sa datim imenom ili lozinkom ne postoji!`}</span>
                    </ModalBody>
                    <ModalFooter className="justify-content-center">
                        <div>
                            <button onClick={() => setModalErrorUserData(false)} className="button-yes">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

        </div>
    );
}

export default Login