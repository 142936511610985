// importovanje komponenti 
import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import DataTable from 'react-data-table-component';
import "react-datepicker/dist/react-datepicker.css";
import { API } from '../../constants';
import { RadioButton } from '../radioButton';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Spinner } from "reactstrap";
import { numberWithCommas, numberWithCommasColumns, roundTO, getCompanyData, defaultComponentOptions } from '../utilities';
import { useWindowSize } from '../useWindowSize';
import { useLocation, useNavigate } from "react-router-dom";
import { NumericFormat } from "react-number-format";

// komponenta koju kreiramo
function IzvjestajiPoBrojuNaloga() {
    const windowSize = useWindowSize();
    const location = useLocation();
    const [finReport, setFinReport] = useState(null);
    const [sum, setSum] = useState(0);
    const [sumduguje, setSumDuguje] = useState(false);
    const [sumpotraz, setSumPotraz] = useState(false);
    const [vrsta, setVrsta] = useState(null);
    const [konto, setKonto] = useState(null);
    const [selectDataVrsta, setSelectDataVrsta] = useState([]);
    const [selectDataKonto, setSelectDataKonto] = useState([]);
    const [spinner, setSpinner] = useState(false);
    const [modalErrorOpenPrint, setModalErrorOpenPrint] = useState(false);
    const [pending, setPending] = React.useState(false);
    const [errorDateFrom, setErrorDateFrom] = useState(false);
    const [errorDateTo, setErrorDateTo] = useState(false);

    const [errorMsgDateFrom, setErrorMsgDateFrom] = useState(false);
    const [errorMsgDateTo, setErrorMsgDateTo] = useState(false);

    // stejtovi za spinere na dugmadima
    const [faSpin, setFaSpin] = useState(false);
    const [faSpinT, setFaSpinT] = useState(false);

    const nalogOdRef = useRef();
    const nalogDoRef = useRef();
    const vrstaRef = useRef();
    const kontRef = useRef();
    const datumOdRef = useRef();
    const datumDoRef = useRef();
    const opisRef = useRef();

     const token = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : null

    // // dodaje datum od i datum do. O pocetka godine do danasnjeg dana new Date() ili do kraja godine new Date(currentDate.getFullYear(), 0, 0)
    // let dateFrom
    // let dateTo
    // let currentDate = new Date()
    // if (currentDate.getFullYear() === Number(year)) {
    //     dateFrom = new Date(currentDate.getFullYear(), 0, 1);
    //     dateTo = new Date()
    // } else {
    //     let oldYear = new Date()
    //     oldYear = new Date(Number(oldYear.setFullYear(year)))
    //     dateFrom = new Date(oldYear.getFullYear(), 0, 1);
    //     dateTo = new Date(Number(oldYear.setFullYear(year)))
    // }
    // error row inputs
    // const [date, setDate] = useState({
    //     checkDatDok: true,
    //     checkDatStorno: false
    // });

    const [form, setForm] = useState({
        NALOGOD: '',
        NALOGDO: '',
        VRSTA: '',
        KONTID: '',
        DATUMOD: '',
        DATUMDO: '',
        OPIS: '',
        buttonType: '',
    })

    // funkcija za povlacenje podataka o preduzecu
    getCompanyData(form)

    // funkcija za popunjavanje datumskih polja u zavisnosti od godine
    const checkCompanyYear = () => {
        //  let dateFrom
        //  let dateTo
        let currentDate = new Date()
        if (currentDate.getFullYear() === Number(form.companyYear)) {
            setForm(prev => ({ ...prev, DATUMOD: moment.unix(new Date(currentDate.getFullYear(), 0, 1).getTime() / 1000).format("DD.MM.YYYY"), DATUMDO: moment.unix(new Date().getTime() / 1000).format("DD.MM.YYYY") }))
        } else {
            let oldYear = new Date()
            oldYear = new Date(Number(oldYear.setFullYear(form.companyYear)))
            setForm(prev => ({ ...prev, DATUMOD: moment.unix(new Date(oldYear.getFullYear(), 0, 1).getTime() / 1000).format("DD.MM.YYYY"), DATUMDO: moment.unix(new Date(Number(oldYear.setFullYear(form.companyYear))).getTime() / 1000).format("DD.MM.YYYY") }))
        }
    }

    // const [startDate, setStartDate] = useState(dateFrom);
    // const [endDate, setEndDate] = useState(dateTo);

    const getReports = () => {
        // filter
        if (form) {
            //  form.NALOGOD = form.NALOGOD ? form.NALOGOD : '';
            //    form.NALOGDO = form.NALOGDO ? form.NALOGDO : '';
            form.VRSTA = vrsta && vrsta.VRSTAD ? vrsta.VRSTAD : null;
            form.KONTID = konto && konto.ID ? konto.ID : null
            //  form.checkDatDok = date.checkDatDok
            // form.DATUMOD = startDate
            // form.DATUMDO = endDate
            //                      form.radioDatumDok = date?.checkDate
            //                      form.radioDatumNal = date?.checkDateCancelled
            //   form.DATN = setDate.DATN
            //    form.DATD = setDate.DATD
            // form.buttonType = buttonType

        }

        // api za mrezu
        // u responsu puni mrezu 
        fetch(API + `/izvjestajiPoBrojuNalogaMreza/all/${form.companyCode}`, {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                'Authorization': `Bearer ${form.token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setFinReport(data.items)
                let DUGUJESum = 0.00;
                let POTRAZSum = 0.00;
                let SALDOSum = 0.00;
                // petlja za citanje podataka
                for (let i = 0; i < data.items.length; i++) {
                    DUGUJESum += data.items[i].DUGUJE
                    POTRAZSum += data.items[i].POTRAZ
                    SALDOSum += (data.items[i].DUGUJE) - (data.items[i].POTRAZ)
                }

                setSumDuguje(numberWithCommasColumns(roundTO((DUGUJESum), 2)))
                setSumPotraz(numberWithCommasColumns(roundTO((POTRAZSum), 2)))
                setSum(numberWithCommasColumns(roundTO((SALDOSum), 2)))
                setPending(false)
            })
    }

    // funkcija kojom se preuzima broj naloga kad se klikne na dugme stampa na unosu naloga
    function finNalog(location) {
        const { nalog } = location.state ? location.state : '';
        setForm(prev => ({ ...prev, NALOGOD: nalog ? nalog : '', NALOGDO: nalog ? nalog : '' }))
    }

    useEffect(() => {
        finNalog(location)
    }, [])

    const print = () => {
        setSpinner(true)
        // uslov: ako nije izabran datum od datum do i ako nije izabran izvjestaj(nije kliknuto na dugme za izvjestaj), nece otvoriti izvjestaj 
        if (!unosIspravanDo('PRINT')) return setSpinner(false)
        if (form.buttonType === "") return setSpinner(false), setModalErrorOpenPrint(true)
        //filter
        if (form) {
            form.NAZIV_PRED = form.companyName
            form.VRSTA = vrsta && vrsta.VRSTAD ? vrsta.VRSTAD : null;
            form.KONTID = konto && konto.ID ? konto.ID : null
            //  form.checkDatDok = date.checkDatDok
            // form.checkPdvObveznici = 
            // form.DATUM = setDate.DATUM
            // form.STORNO = setReversal.STORNO
            // form.PDV = setTaxPayers.PDV
            //   form.buttonType = buttonType ? buttonType : null
            // if (buttonType === null) {
            //     setModalErrorOpenPrint(true)
            //     // zatvaranje spinera
            //     setSpinner(false)
            // }
        }
        // fetch za crtanje izvjestaja
        // u bodiju saljemo sql koji je vec pokupio i form
        fetch(API + `/` + form.buttonType + `/print/${form.companyCode}`, {
     //   fetch(API + `/pregledPoNalogu/print/${form.companyCode}`, {
            method: 'POST',
            body: JSON.stringify({ form }),
            responseType: 'blob',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                if (typeof window != 'undefined') {
                    setTimeout(() => {
                        window.open(API + data.link)
                    }, 500);
                }
             //   window.open(API + data.link)
                setSpinner(false)
            })
    }

    // funkcija koja poziva citanje vrste
    const getVrstaData = () => {
        fetch(API + `/dokument/all`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${form.token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataVrsta(data)
            })
    }

    // const getTableData = () => {
    //     fetch(API + `/kontoReport/all/${companyCode}`, {
    //         method: 'GET',
    //         headers: {
    //             'Authorization': `Bearer ${token}`,
    //             'Content-type': 'application/json; charset=UTF-8'
    //         },
    //     })
    //         .then((response) => response.json())
    //         .then(data => {
    //             setSelectDataClients(data)
    //         })
    // }

    // funkcija koja poziva citanje konta
    const getKontoData = () => {
        fetch(API + `/konto/all`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${form.token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataKonto(data)
            })
    }

    // funkcija koja prati promjenu stanja(poziva apije za citanje i popunjavanje autokomplitova) 
    useEffect(() => {
        getKontoData();
        getVrstaData()
    }, [])

    // cekiranje radio dugmadi u zavisnosti odabrane opcije
    // const radioChangeHandler = (e) => {
    //     if (e.target.name === 'checkDatDok') {
    //         setDate({
    //             // odabir datuma dokumenata
    //             checkDatDok: true,
    //             checkDatStorno: false
    //         });
    //     } else {
    //         setDate({
    //             checkDatDok: false,
    //             // odabir datuma storniranja
    //             checkDatStorno: true
    //         });
    //     }
    // };

    // funkcija koja se poziva prilikom promjene vrijednosti u inputu
    const handleChange = (event) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name;
        // postavlja sa funkcijom  setForm((prevState) mijejenja predhodno stanje sa novom vrijednoscu
        setForm((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    // funkcija za kontrolu obaveznih polja
    function unosIspravanDo(name) {
        if (name === 'NALOGOD') {
            return true
        }

        if (name === 'NALOGDO') {
            return true
        }

        if (name === 'VRSTA') {
            return true
        }

        if (name === 'KONTID') {
            return true
        }

        if (name === 'DATUMOD') {
            return true
        }

        let reqex = /^(0[1-9]|[12]\d|3[01]).(0[1-9]|1[0-2]).([12]\d{3})$/gi;

        if (!reqex.test(form.DATUMOD)) {
            setErrorDateFrom(true)
            setErrorMsgDateFrom('Datum od nije validan!')
            datumOdRef.current.focus();
            return false
        } else {
            setErrorDateFrom(false)
            setErrorMsgDateFrom(null)
        }

        if (name === 'DATUMDO') {
            return true
        }

        let reqexDATUMDO = /^(0[1-9]|[12]\d|3[01]).(0[1-9]|1[0-2]).([12]\d{3})$/gi;

        if (!reqexDATUMDO.test(form.DATUMDO)) {
            setErrorDateTo(true)
            setErrorMsgDateTo('Datum do nije validan!')
            datumDoRef.current.focus();
            return false
        } else {
            setErrorDateTo(false)
            setErrorMsgDateTo(null)
        }

        if (name === 'OPIS') {
            return true
        }

        return true
    }

    let sirinaZaKoloneVrijednosti = 
    windowSize.width > 2048 ? '220px'
    : windowSize.width > 1920 ? '210px'
        : windowSize.width > 1680 ? '120px'
            : windowSize.width > 1600 ? '110px'
                : windowSize.width > 1440 ? '100px'
                    : windowSize.width > 1280 ? '100px'
                        : windowSize.width > 1024 ? '100px'
                            : '80px';

    // funkcija za popunjavanje kolona u mrezi
    function checkingFields() {
        let nalog = {
            name: 'Nalog',
            selector: row => row.NALOG,
            // sirina polja po rezolucijama
            width:
            windowSize.width > 2048 ? '140px'
               : windowSize.width > 1920 ? '100px'
                    : windowSize.width > 1680 ? '120px'
                        : windowSize.width > 1600 ? '90px'
                            : windowSize.width > 1440 ? '100px'
                                : windowSize.width > 1280 ? '80px'
                                    : windowSize.width > 1024 ? '80px'
                                        : '80px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.NALOG}</span>
                </>
            ),
        }
        let datN =
        {
            name: 'Dat.Nal',
            selector: row => moment(row.DATN).format('DD.MM.YYYY'),
            width:
            windowSize.width > 2048 ? '110px'
               : windowSize.width > 1920 ? '100px'
                    : windowSize.width > 1680 ? '150px'
                        : windowSize.width > 1600 ? '100px'
                            : windowSize.width > 1440 ? '100px'
                                : windowSize.width > 1280 ? '95px'
                                    : windowSize.width > 1024 ? '80px'
                                        : '80px',
                                        cell: (row) => (
                                            <>
                                               <span id='media-query-rows-text'>{moment(row.DATN).format('DD.MM.YYYY')}</span>
                                            </>
                                        ),
        }
        let konto =
        {
            name: 'Konto',
            selector: row => row.KONTO,
            width:
            windowSize.width > 2048 ? '110px'
               : windowSize.width > 1920 ? '90px'
                    : windowSize.width > 1680 ? '110px'
                        : windowSize.width > 1600 ? '100px'
                            : windowSize.width > 1440 ? '100px'
                                : windowSize.width > 1280 ? '80px'
                                    : windowSize.width > 1024 ? '80px'
                                        : '80px',
                                        cell: (row) => (
                                            <>
                                                <span id='media-query-rows'>{row.KONTO}</span>
                                            </>
                                        ),
        }
        let kontoNaziv =
        {
            name: 'Naziv konta',
            selector: row => row.kontoNaziv,
            width:
            windowSize.width > 2048 ? '1170px'
               : windowSize.width > 1920 ? '850px'
                    : windowSize.width > 1680 ? '750px'
                        : windowSize.width > 1600 ? '790px'
                            : windowSize.width > 1440 ? '730px'
                                : windowSize.width > 1280 ? '620px'
                                    : windowSize.width > 1024 ? '540px'
                                        : '175px',
                                        cell: (row) => (
                                            <>
                                                <span id='media-query-rows-text'>{row.kontoNaziv}</span>
                                            </>
                                        ),
        }
        let duguje =
        {
            name: 'Duguje',
            selector: row => row.DUGUJE,
            width: sirinaZaKoloneVrijednosti,
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{numberWithCommasColumns(row.DUGUJE)}</span>
                </>
            ),
        }
        let potraz =
        {
            name: 'Potražuje',
            selector: row => row.POTRAZ,
            width: sirinaZaKoloneVrijednosti,
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{numberWithCommasColumns(row.POTRAZ)}</span>
                </>
            ),
        }
        let saldo =
        {
            name: 'Saldo',
            selector: row => row.SALDO,
            width: sirinaZaKoloneVrijednosti,
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{numberWithCommasColumns(row.SALDO)}</span>
                </>
            ),
        }
        if (form.buttonType.startsWith('pregled')) {
            let red = {
                name: 'Red',
                selector: row => row.RED,
                width:
                windowSize.width > 2048 ? '120px'
                  :  windowSize.width > 1920 ? '100px'
                        : windowSize.width > 1680 ? '120px'
                            : windowSize.width > 1600 ? '90px'
                                : windowSize.width > 1440 ? '100px'
                                    : windowSize.width > 1280 ? '80px'
                                        : windowSize.width > 1024 ? '80px'
                                            : '80px',
                right: 'boolean',
                cell: (row) => (
                    <>
                        <span id='media-query-rows'>{row.RED}</span>
                    </>
                ),
            }
            let datD =
            {
                name: 'Dat.Dok',
                selector: row => moment(row.DATD).format('DD.MM.YYYY'),
                width:
                windowSize.width > 2048 ? '200px'
                   : windowSize.width > 1920 ? '100px'
                        : windowSize.width > 1680 ? '150px'
                            : windowSize.width > 1600 ? '100px'
                                : windowSize.width > 1440 ? '100px'
                                    : windowSize.width > 1280 ? '95px'
                                        : windowSize.width > 1024 ? '80px'
                                            : '80px',
                                            cell: (row) => (
                                                <>
                                                    <span id='media-query-rows-text'>{moment(row.DATD).format('DD.MM.YYYY')}</span>
                                                </>
                                            ),
            }
            let vrsta =
            {
                name: 'Vrsta',
                selector: row => row.VRSTA,
                // sirina polja po rezolucijama
                width:
                windowSize.width > 2048 ? '120px'
                   : windowSize.width > 1920 ? '100px'
                        : windowSize.width > 1680 ? '100px'
                            : windowSize.width > 1600 ? '90px'
                                : windowSize.width > 1440 ? '100px'
                                    : windowSize.width > 1280 ? '80px'
                                        : windowSize.width > 1024 ? '80px'
                                            : '80px',
                right: 'boolean',
                cell: (row) => (
                    <>
                        <span id='media-query-rows'>{row.VRSTA}</span>
                    </>
                ),
            }
            let opis =
            {
                name: 'Opis',
                selector: row => row.OPIS,
                // sirina polja po rezolucijama
                width:
                windowSize.width > 2048 ? '585px'
                  :  windowSize.width > 1920 ? '400px'
                        : windowSize.width > 1680 ? '250px'
                            : windowSize.width > 1600 ? '370px'
                                : windowSize.width > 1440 ? '315px'
                                    : windowSize.width > 1280 ? '260px'
                                        : windowSize.width > 1024 ? '240px'
                                            : '175px',
                                            cell: (row) => (
                                                <>
                                                    <span id='media-query-rows-text'>{row.OPIS}</span>
                                                </>
                                            ),
            }
            let sifrak =
            {
                name: 'Šifra k.',
                selector: row => row.SIFRAK,
                right: 'boolean',
                // sirina polja po rezolucijama
                width:
                    windowSize.width > 1920 ? '150px'
                        : windowSize.width > 1680 ? '120px'
                            : windowSize.width > 1600 ? '90px'
                                : windowSize.width > 1440 ? '100px'
                                    : windowSize.width > 1280 ? '95px'
                                        : windowSize.width > 1024 ? '80px'
                                            : '80px',
                right: 'boolean',
                cell: (row) => (
                    <>
                        <span id='media-query-rows'>{row.SIFRAK}</span>
                    </>
                ),
            }
            return [konto, nalog, red, vrsta, datD, datN, opis, sifrak, duguje, potraz, saldo,]
        } else {
            return [nalog, datN, konto, kontoNaziv, duguje, potraz, saldo]
        }
    }

    const defaultPropsVrsta = {
        options: selectDataVrsta,
        getOptionLabel: (option) => option ? `${option.VRSTAD} ${option.OPISD}` : '',
    };

    // propsovi za auto komplit  - konto
    const defaultPropsKonto = {
        options: selectDataKonto,
        getOptionLabel: (option) => option ? `${option.KONTO} ${option.NAZ}` : '',
    };

    return (

        <div>
            {/* Kontejner za naslov  */}
            <div className="title-container-report">
                {/* Naziv forma */}
                <header className="title-header">
                    <h2 id="media-query-title">Izvještaj po broju naloga</h2>
                </header>

                <div >
                    <div>
                        {/* Klasa za ikonice, stampa*/}
                        <ul >
                            <input type="image" alt="print" className='dugme-stampa' title='Štampa' tabIndex="10" src={require('../../assets/images/printer.png')} onClick={print} />
                        </ul>
                    </div>
                </div>
            </div>
            <Container fluid>
                <div className="row">
                    <Col lg='2'>


                        {/* <div className='form-field-container' style={{ display: 'contents' }}> */}
                        {/* red sirine */}
                        {/* <Col lg='12'> */}
                        {/* definsanje froma za radio dugmad */}
                        {/* <div className="col-sm radio-box" id='media-query-radioButton-date'> */}
                        {/* <RadioButton
                                        changed={(e) => radioChangeHandler(e)}
                                        isSelected={date?.checkDatDok}
                                        label="Dat.dok."
                                        id="1"
                                        value={date?.checkDatDok}
                                        name='checkDatDok'
                                    /> */}
                        {/*  radio dugme za datum storniranja   */}
                        {/* <RadioButton
                                        changed={(e) => radioChangeHandler(e)}
                                        isSelected={date?.checkDatStorno}
                                        label="Dat.naloga"
                                        id="2"
                                        value={date?.checkDatStorno}
                                        name='checkDatStorno'
                                    /> */}
                        {/* </div> */}
                        {/* </Col> */}
                        {/* </div> */}
                    </Col>

                    <Col lg='6'>

                        <div className="col-12" style={{ display: 'flex', }}>
                            <div className="col-12">

                                <div className="form-group row label-box-div">
                                    <label className="col-form-label col-lg-2 label-box" id="media-query-label-report">Nal. od:</label>
                                    <div className="col-2">
                                        <div className='field-container'>
                                        <NumericFormat
                                                type="text"
                                                className='field-alignment p-1'
                                                getInputRef={nalogOdRef}
                                                maxLength="9"
                                                autoFocus
                                                tabIndex="1"
                                                id="media-query-input"
                                                onFocus={() => {
                                                    unosIspravanDo("NALOGOD")
                                                }}
                                                placeholder="nalog od"
                                                name='NALOGOD'
                                                value={form?.NALOGOD}
                                                onChange={(e) => handleChange(e)} />
                                        </div>
                                    </div>

                                    <label className="col-form-label col-lg-2 label-box" id="media-query-label-DateTo">do:</label>
                                    <div className="col-2">
                                        <div className="field-container">
                                        <NumericFormat
                                                type="text"
                                                className='field-alignment p-1'
                                                getInputRef={nalogDoRef}
                                                maxLength="9"
                                                tabIndex="2"
                                                id="media-query-input-to"
                                                onFocus={() => {
                                                    unosIspravanDo("NALOGDO")
                                                }}
                                                placeholder="nalog do"
                                                value={form?.NALOGDO}
                                                name='NALOGDO'
                                                onChange={(e) => handleChange(e)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12" style={{ display: 'flex' }}>
                            <div className="col-12">
                                <div className="form-group row label-box-div" >
                                    <label className="col-form-label col-lg-2 label-box" id="media-query-label-report">Vrsta:</label>
                                    <div className="col-8">
                                        <Autocomplete
                                            //ukoliko nema ove funkcije, u konzoli izbacuje upozorenje da nije vrijednost jedinstvena
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            {...defaultPropsVrsta}
                                            value={vrsta}
                                            disablePortal={true}
                                            onFocus={() => {
                                                unosIspravanDo("VRSTA")
                                            }}

                                            // ako se promijeni vrsta, uzima podatke za nov izabranu vrstu
                                            onChange={(event, newValue) => {
                                                setForm(prev => ({ ...prev, VRSTA: newValue && newValue != '' ? newValue.VRSTAD : '' }))
                                                setVrsta(newValue);
                                                // fokus se mora vratiti inace tabIndex ne radi dobro
                                                vrstaRef.current.focus()
                                            }}

                                            renderInput={(params) => (
                                                <TextField {...params}
                                                    tabIndex="3"
                                                    className="clients-textinput"
                                                    ref={vrstaRef}
                                                    placeholder=' Izaberite komitenta'
                                                    variant="standard" />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12" style={{ display: 'flex' }}>
                            <div className="col-12">
                                <div className="form-group row label-box-div">
                                    <label className="col-form-label col-lg-2 label-box" id="media-query-label-report">Konto:</label>
                                    <div className="col-8">
                                        <Autocomplete
                                            //ukoliko nema ove funkcije, u konzoli izbacuje upozorenje da nije vrijednost jedinstvena
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            {...defaultPropsKonto}
                                            value={konto}
                                            disablePortal={true}
                                            onFocus={() => {
                                                unosIspravanDo("KONTID")
                                            }}
                                            // ako se promijeni konto, uzima podatke za novo izabrano konto
                                            onChange={(event, newValue) => {
                                                setForm(prev => ({ ...prev, KONTID: newValue && newValue != '' ? newValue.ID : '' }))
                                                setKonto(newValue);
                                                // fokus se mora vratiti inace tabIndex ne radi dobro
                                                kontRef.current.focus()
                                            }}

                                            renderInput={(params) => (
                                                <TextField {...params}
                                                    tabIndex="4"
                                                    ref={kontRef}
                                                    className="clients-textinput"
                                                    placeholder=' Izaberite konto'
                                                    variant="standard" />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12" style={{ display: 'flex', }}>
                            <div className="col-12">

                                <div className="form-group row label-box-div">
                                    <label className="col-form-label col-lg-2 label-box" id="media-query-label-report">Dat. od:</label>
                                    <div className="col-2">
                                        <div className={errorDateFrom ? 'inputWithError' : 'field-container'}>
                                            <input
                                                type='text'
                                                tabIndex="5"
                                                id="media-query-input"
                                                ref={datumOdRef}
                                                onFocus={() => {
                                                    unosIspravanDo("DATUMOD")
                                                    checkCompanyYear();
                                                }}
                                                placeholder="datum od"
                                                name='DATUMOD'
                                                value={form?.DATUMOD}
                                                onChange={(e) => handleChange(e)} />
                                            {errorMsgDateFrom ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgDateFrom}</span> : ''}
                                        </div>
                                    </div>

                                    <label className="col-form-label col-lg-2 label-box" id="media-query-label-DateTo">do:</label>
                                    <div className="col-2">
                                        <div className={errorDateTo ? 'inputWithError' : 'field-container'}>
                                            <input type='text'
                                                tabIndex="6"
                                                id="media-query-input-to"
                                                ref={datumDoRef}
                                                onFocus={() => {
                                                    unosIspravanDo("DATUMDO")
                                                }}
                                                placeholder="datum do"
                                                value={form?.DATUMDO}
                                                name='DATUMDO'
                                                onChange={(e) => handleChange(e)} />
                                            {errorMsgDateTo ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgDateTo}</span> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12" style={{ display: 'flex' }}>
                            <div className="col-12">
                                <div className="form-group row label-box-div" >
                                    <label className="col-form-label col-lg-2 label-box" id="media-query-label-report">Opis:</label>
                                    <div className="col-8">
                                        <input
                                            type='text'
                                            tabIndex="7"
                                            id="media-query-input"
                                            ref={opisRef}
                                            onFocus={() => {
                                                unosIspravanDo("OPIS")
                                            }}
                                            placeholder="opis"
                                            name='OPIS'
                                            value={form?.OPIS}
                                            onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg='4'>
                        <Row>
                            <Col lg='12' >
                                <div className=" flex-lg-row justify-content-end" id='media-query-buttonType'>
                                    <div style={{ display: 'inline-grid' }} className={faSpin ? "btn-report-red" : "btn-report"}>
                                        <button tabIndex={8} onClick={() => {
                                            // uslov: ako nisu unesena obavezna polja, zaustavlja upis
                                            if (!unosIspravanDo('DUGME')) return;
                                            form.buttonType = 'pregledPoNalogu'
                                            setFaSpin(true)
                                            setFaSpinT(false)
                                            setPending(true)
                                            getReports()
                                        }}>{faSpin && !finReport ? <i className="fa fa-spinner fa-spin"></i> : ''} Pregled po nalogu</button>
                                    </div>
                                    <div style={{ display: 'inline-grid' }} className={faSpinT ? "btn-report-red" : "btn-report"}>
                                        <button tabIndex={9} onClick={() => {
                                            // uslov: ako nisu unesena obavezna polja, zaustavlja upis
                                            if (!unosIspravanDo('DUGME')) return;
                                            form.buttonType = 'totalPoNalogu'
                                            setFaSpin(false)
                                            setFaSpinT(true)
                                            setPending(true)
                                            getReports()
                                        }}>{faSpinT && !finReport ? <i className="fa fa-spinner fa-spin"></i> : ''}Total po nalogu</button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </div>
            </Container >

            {
                < DataTable
                    columns={form.buttonType ? checkingFields() : []}
                    data={finReport ? finReport : []}
                    pagination
                    responsive
                    striped
                    dense
                    pointerOnHover
                    paginationComponentOptions={defaultComponentOptions}
                    paginationPerPage={[windowSize.height > 1300 ? 29
                        : windowSize.height > 1246 ? 26
                        : windowSize.height > 1222 ? 25
                        : windowSize.height > 1200 ? 23
                        : windowSize.height > 1000 ? 19
                        : windowSize.height > 920 ? 15
                        : windowSize.height > 900 ? 14
                        : windowSize.height > 880 ? 13
                        : windowSize.height > 820 ? 12
                        : windowSize.height > 720 ? 10
                                            : 4]}
                    // paginationRowsPerPageOptions={[2,4,8,12,15]}
                    progressPending={pending}
                />
            }
            {/* <div className="naslov-ikonice">
                <ul className="nav flex-column flex-lg-row justify-content-end">
                    <div style={{ display: 'flex', justifyContent: 'end', position: 'fixed', bottom: '0', flex: '0 0 auto' }}>

                        <li className="nav-item toolbaritem" style={{ width: '21%', marginRight: '4px' }}>
                            <div className='col-form-right'>
                                <div className="field-container-number">
                                    <label id="media-query-label-sum">Duguje</label>
                                    <input className="form-control" readOnly={true} type='text' id="media-query-input-text" value={sumduguje && sumduguje != 'NaN' ? numberWithCommas(sumduguje) : 0} />
                                </div>
                            </div>
                        </li>

                        <li className="nav-item toolbaritem" style={{ width: '21%', marginRight: '7px' }}>
                            <div className='col-form-right'>
                                <div className="field-container-number">
                                    <label id="media-query-label-sum">Potražuje</label>
                                    <input type='text' className="form-control" readOnly={true} id="media-query-input-text" value={sumpotraz && sumpotraz != 'NaN' ? numberWithCommas(sumpotraz) : 0} />
                                </div>
                            </div>
                        </li>

                        <li className="nav-item toolbaritem" style={{ width: '21%', marginRight: '5px' }}>
                            <div className='col-form-right'>
                                <div className="field-container-number">
                                    <label id="media-query-label-sum">Saldo</label>
                                    <input className="total" readOnly={true} type='text' id="media-query-input-text" value={sum && sum != 'NaN' ? numberWithCommas(sum) : 0} />
                                </div>
                            </div>
                        </li>

                    </div>
                </ul>
            </div> */}

            <div className="naslov-ikonice">
                <ul className="nav flex-column flex-lg-row justify-content-end">
                    <div style={{ display: 'flex', justifyContent: 'end', position: 'fixed', bottom: '0', flex: '0 0 auto' }}>

                        <li className="nav-item toolbaritem" style={{ width: '23%' }}>
                            <div className='col-form-right'>
                                <div className="field-container-number-totals ">
                                    <label id="media-query-label-sum">Duguje</label>
                                    <input className="form-control" readOnly={true} tabIndex="-1" type='text' id="media-query-input-text" value={sumduguje && sumduguje != 'NaN' ? sumduguje : 0} />
                                </div>
                            </div>
                        </li>

                        <li className="nav-item toolbaritem" style={{ width: '23%' }}>
                            <div className='col-form-right'>
                                <div className="field-container-number-totals ">
                                    <label id="media-query-label-sum">Potražuje</label>
                                    <input className="form-control" readOnly={true} type='text' tabIndex="-1" id="media-query-input-text" value={sumpotraz && sumpotraz != 'NaN' ? sumpotraz : 0} />
                                </div>
                            </div>
                        </li>

                        <li className="nav-item toolbaritem" style={{ width: '23%' }}>
                            <div className='col-form-right'>
                                <div className="field-container-number-totals ">
                                    <label id="media-query-label-sum">Saldo</label>
                                    <input type='text' className="total" readOnly={true} id="media-query-input-text" tabIndex="-1" value={sum && sum != 'NaN' ? (sum) : 0} />
                                </div>
                            </div>
                        </li>
                    </div>
                </ul>
            </div>
            {/* spiner ili progres bar ya prikaz pokretanja datog izvjestaja */}
            <div>
                {spinner ? <Spinner className='spinner' color="primary" id='loader' /> : ''}
            </div>

            {/* {spinner ? <Progress
                animated
                bar
                color="success"
                value="100" >
                Loading
            </Progress> : ''} */}

        </div >
    );

}

export default IzvjestajiPoBrojuNaloga;


