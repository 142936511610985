// importovanje komponenti 
import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { API } from '../constants';
import axios from '../api/axios';
import { Spinner } from "reactstrap";
import { getCompanyData, defaultComponentOptions } from '../components/utilities';
import { NumericFormat } from 'react-number-format';
import { useWindowSize } from '../components/useWindowSize';
// komponenta koju kreiramo
function Konto() {
  const windowSize = useWindowSize();

  // definisanje konstanti koje koristimo za fokusiranje polja
  const kontoRef = useRef();
  const nameRef = useRef();
  const kufkifRef = useRef();

  const [konto, setKonto] = useState(null);
  const [perPage, setPerPage] = useState(10);
  const [searchInput, setSearchInput] = useState([]);

  const token = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : null;

  const [errorInputKonto, setErrorInputKonto] = useState(false);
  const [errorInputName, setErrorInputName] = useState(false);
  const [errorMsgKonto, setErrorMsgKonto] = useState(false);
  const [errorMsgName, setErrorMsgName] = useState(false);
  const [modalErrorOpenData, setModalErrorOpenData] = useState(false);
  const [pending, setPending] = React.useState(false);
  const [spinner, setSpinner] = useState(false);
  const [modalErrorAuthorizationData, setModalErrorAuthorizationOpen] = useState(false);
  const [modalDeleteItem, setModalDeleteItem] = useState(false);

  const [form, setForm] = useState({
    KONTO: '',
    NAZ: '',
    KUFKIF: '',
    ENTITET: '',
    BROJKUF: '',
    OZNAKA: '',
    stavkaPronadjena: false,
  })
  // funkcija za povlacenje podataka o preduzecu
  getCompanyData(form)

  const onSubmit = () => {

    // uslov: ako nisu unesena obavezna polja, zaustavlja upis
    if (!unosIspravanDo('UPIS')) return;

    form.SIFKOR = form.userCode
    form.SIFRAP = form.companyCode

    fetch(API + '/konto/new', {
      method: 'POST',
      body: JSON.stringify(form),
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-type': 'application/json; charset=UTF-8'
      },
    })
      .then((response) => {
        response.json()
        setForm({
          KONTO: '',
          NAZ: '',
          KUFKIF: '',
          ENTITET: '',
          BROJKUF: '',
          OZNAKA: '',
          stavkaPronadjena: false
        })
        kontoRef.current.focus()
        getTableData()
      })


  }

  const getTableData = () => {
    fetch(API + `/konto/all`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-type': 'application/json; charset=UTF-8'
      },
    })
      .then((response) => response.json())
      .then(data => {
        setKonto(data.result)
        setPending(false)
      })
  }

  const onDelete = (ID) => {
    // provjera ovlascenja za brisanje 
    if (form.ovlfik == 4) return setModalErrorAuthorizationOpen(true)
    fetch(API + '/konto/delete/' + ID, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-type': 'application/json; charset=UTF-8'
      },
    })
      .then((response) => {
        response.json()
        setForm({
          KONTO: '',
          NAZ: '',
          KUFKIF: '',
          ENTITET: '',
          BROJKUF: '',
          OZNAKA: '',
          stavkaPronadjena: false
        })
        kontoRef.current.focus()
        getTableData()
      })
  }

  // prilikom pocetne inicijalizacije, cita mrezu
  useEffect(() => {
    // zaustavljanje fokusa
    const timeout = setTimeout(() => {
      getTableData()
    }, 100);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  // funkcija koja se poziva klikom na dugme Edit
  // postavlja fokus na polje NAZIV 
  // popunjava polja podacima iz mreze
  const onEdit = (item) => {
    item.stavkaPronadjena = true
    setForm(item)
    // zaustavljanje fokusa(pauza za fokus dok ne odradi upis) 
    const timeout = setTimeout(() => {
      nameRef.current.focus()
    }, 100);
    return () => {
      clearTimeout(timeout);
    };
  }

  // funkcija koja se poziva prilikom promjene vrijednosti u inputu
  const handleChange = (event) => {
    const target = event.target;
    let value = target.value;
    const name = event.target.name;

    setForm((prevState) => ({
      ...prevState,
      [name]: value
    }));

  };

  // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto) ili ako nije upisana ni vrijednost niti je odabran konto, izbaci poruku, setuje crveni okvir i fokusira polje  
  // u suprotnom prolazi dalje
  const checkKontoRequired = (value) => {
    //    console.log(value, 'value')
    if (!value || value == 0) {
      setErrorMsgKonto('Konto je obavezan!')
      setErrorInputKonto(true)
      kontoRef.current.focus();
      return false
    } else {
      setErrorMsgKonto(null)
      setErrorInputKonto(false)
      return true
    }
  }

  // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto) ili ako nije upisana ni vrijednost niti je odabran dokument, izbaci poruku, setuje crveni okvir i fokusira polje  
  // u suprotnom prolazi dalje
  const checkNameRequired = (value) => {
    if (!value || value == 0) {
      setErrorMsgName('Naziv je obavezan!')
      setErrorInputName(true)
      nameRef.current.focus();
      return false
    } else {
      setErrorMsgName(null)
      setErrorInputName(false)
      return true
    }
  }

  // funkcija za kontrolu obaveznih polja
  function unosIspravanDo(name) {
    if (name === 'KONTO') {
      return true
    }

    if (!checkKontoRequired(form.KONTO)) {
      return false
    }

    if (name === 'NAZ') {
      return true
    }

    if (!checkNameRequired(form.NAZ)) {
      return false
    }

    if (name === 'KUFKIF') {
      return true
    }

    return true
  }


  const columns = [
    {
      name: 'Konto',
      selector: row => row.KONTO,
      // sirina polja po rezolucijama
      width:
        windowSize.width > 1920 ? '130px'
          : windowSize.width > 1680 ? '100px'
            : windowSize.width > 1600 ? '100px'
              : windowSize.width > 1440 ? '100px'
                : windowSize.width > 1280 ? '100px'
                  : windowSize.width > 1024 ? '100px'
                    : '100px',
      cell: (row) => (
        <>
          <span id='media-query-rows-text'>{row.KONTO}</span>
        </>
      ),
    },
    {
      name: 'Naziv',
      selector: row => row.NAZ,
      width:
        windowSize.width > 1920 ? '700px'
          : windowSize.width > 1680 ? '870px'
            : windowSize.width > 1600 ? '730px'
              : windowSize.width > 1440 ? '650px'
                : windowSize.width > 1280 ? '500px'
                  : windowSize.width > 1024 ? '500px'
                    : '350px',
      cell: (row) => (
        <>
          <span id='media-query-rows-text'>{row.NAZ}</span>
        </>
      ),
    },
    {
      name: 'Kuf/kif',
      selector: row => row.KUFKIF,
      width:
        windowSize.width > 1920 ? '115px'
          : windowSize.width > 1680 ? '100px'
            : windowSize.width > 1600 ? '100px'
              : windowSize.width > 1440 ? '100px'
                : windowSize.width > 1280 ? '100px'
                  : windowSize.width > 1024 ? '90px'
                    : '100px',
      cell: (row) => (
        <>
          <span id='media-query-rows-text'>{row.KUFKIF}</span>
        </>
      ),
    },
    {
      name: 'Entitet',
      selector: row => row.ENTITET,
      width:
        windowSize.width > 1920 ? '150px'
          : windowSize.width > 1680 ? '100px'
            : windowSize.width > 1600 ? '100px'
              : windowSize.width > 1440 ? '100px'
                : windowSize.width > 1280 ? '100px'
                  : windowSize.width > 1024 ? '90px'
                    : '100px',
      cell: (row) => (
        <>
          <span id='media-query-rows-text'>{row.ENTITET}</span>
        </>
      ),
    },
    {
      name: 'Broj kuf/kif',
      selector: row => row.BROJKUF,
      width:
        windowSize.width > 1920 ? '200px'
          : windowSize.width > 1680 ? '100px'
            : windowSize.width > 1600 ? '100px'
              : windowSize.width > 1440 ? '100px'
                : windowSize.width > 1280 ? '100px'
                  : windowSize.width > 1024 ? '100px'
                    : '100px',
      right: 'boolean',
      cell: (row) => (
        <>
          <span id='media-query-rows'>{row.BROJKUF}</span>
        </>
      ),
    },
    {
      name: 'Oznaka',
      selector: row => row.OZNAKA,
      width:
      windowSize.width > 2048 ? '480px'
        : windowSize.width > 1920 ? '380px'
          : windowSize.width > 1680 ? '100px'
            : windowSize.width > 1600 ? '100px'
              : windowSize.width > 1440 ? '100px'
                : windowSize.width > 1280 ? '100px'
                  : windowSize.width > 1024 ? '90px'
                    : '100px',
      cell: (row) => (
        <>
          <span id='media-query-rows-text'>{row.OZNAKA}</span>
        </>
      ),
    },
    {
      name: 'Action',
      right: 'boolean',
      cell: (row) => (
        <>
          <span onClick={() => onEdit(row)} id='media-query-table-buttons' className='btn btn-primary nav naslov-ikonice flex-column flex-lg-row justify-content-end'>Edit</span>{'     '}
          <span onClick={() => { onEdit(row); setModalDeleteItem(true) }} id='media-query-table-buttons' className='btn btn-danger naslov-ikonice nav flex-column flex-lg-row justify-content-end'>Brisanje</span>
        </>

      ),

    }
  ];

  const onPrint = () => {
    setSpinner(true)
    // uslov preko koga saljemo naziv preduzeca sa frontenda na bekend
    // ako je objekat form popunjen, napuni polje NAZIV_PRED iz lokal storidza
    // poziva api za stampu, otvara izvjestaj(API + data.link)
    if (konto && konto.length === 0) {
      setSpinner(false)
      setModalErrorOpenData(true)
    } else {
      if (form) {
        form.NAZIV_PRED = form.companyName
      }
      fetch(API + '/konto/print', {
        method: 'POST',
        body: JSON.stringify(form),
        responseType: 'blob',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-type': 'application/json; charset=UTF-8'
        },
      })
        .then((response) => response.json())
        .then(data => {
          if (typeof window != 'undefined') {
            setTimeout(() => {
              window.open(API + data.link)
            }, 200);
          }
          setSpinner(false)
        })
    }
  }

  // funkcija za pretragu iz mreze
  // salje zahtjev na bekend prema upitu sa parametrima koje posaljemo
  // u responsu vraca podatke koje dobio iz upita 
  const searchChange = (e) => {
    e.preventDefault();
    const searchInput = e.target.value
    setSearchInput(searchInput);
    const fetchData = async () => {
      const res = await axios.get(`${API}/konto/query/${form.companyCode}?q=${searchInput}`, { headers: { "Authorization": `Bearer ${token}` } });
      setKonto(res.data);
    };
    if (searchInput.length === 0 || searchInput.length >= 1) fetchData();

  };

  return (

    <div>
      <div className="title-container">

        <header className="title-header">
          <h1 id="media-query-title">Konta</h1>
        </header>


        <div className="naslov-ikonice">
          <ul className="nav flex-column flex-lg-row justify-content-end">

            <li className="nav-item toolbaritem">
              <input type="image" alt="save" className='dugme-sacuvaj' title='Sačuvaj' tabIndex="7" src={require('./../assets/images/floppydisk.png')} onFocus={() => onSubmit()} />
            </li>

            <li className="nav-item toolbaritem">
              <input type="image" alt="print" className='dugme-stampa' title='Štampa' tabIndex="8" src={require('./../assets/images/printer.png')} onClick={onPrint} />
            </li>

          </ul>
        </div>
      </div>
      <Container fluid>
        <Row>
          <div className='products-form'>
            <Row>
              <div className="col-6" style={{ display: 'flex', marginBottom: '0px', width: '60%' }}>
                <div className="col-1">
                  <div className={errorInputKonto ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px', marginBottom: '0px' }} >
                    <label id="media-query-label">Šifra:</label>
                    <input type="text"
                      id="media-query-input-text"
                      maxLength="8"
                      placeholder="Konto"
                      tabIndex="1"
                      name='KONTO'
                      value={form?.KONTO}
                      autoFocus={true}
                      ref={kontoRef}
                      onFocus={() => {
                        unosIspravanDo("KONTO")
                        // setForm((prev) => ({
                        //   ...prev,
                        //   KONTO: "",
                        //   NAZ: '',
                        //   KUFKIF: '',
                        //   ENTITET: '',
                        //   BROJKUF: '',
                        //   OZNAKA: '', stavkaPronadjena: false

                        // }))
                      }}
                      //  onBlur={(e) => formCheck(e)}
                      onChange={(e) => handleChange(e)}
                      onClick={() => {
                        // ako je odradjen dvoklik na stavku u mrezi, cisti polja  
                        setForm(prev => ({
                          ...prev, KONTO: '',
                          NAZ: '',
                          KUFKIF: '',
                          ENTITET: '',
                          BROJKUF: '',
                          OZNAKA: '', stavkaPronadjena: false
                        }))
                      }}
                    />
                    {errorMsgKonto ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgKonto}</span> : ''}
                  </div>
                </div>

                <div className="col-6">
                  <div className={errorInputName ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px' }} >
                    <label id="media-query-label">Naziv:</label>
                    <input type="text"
                      id="media-query-input-text"
                      maxLength="50"
                      placeholder="naziv"
                      ref={nameRef}
                      tabIndex="2"
                      name='NAZ'
                      value={form ? form.NAZ : ''}
                      onFocus={() => {
                        unosIspravanDo("NAZ")
                        // uslov za citanje sloga po sifri
                        if (unosIspravanDo("NAZ")) {
                          let selectedItem = []
                          selectedItem = konto.filter((c) => c.KONTO === form.KONTO)
                          if (selectedItem.length > 0) {
                            onEdit(selectedItem[0])
                          }
                        }
                      }}
                      //  onBlur={(e) => formCheck(e)}
                      onChange={(e) => handleChange(e)} />
                    {errorMsgName ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgName}</span> : ''}

                  </div>
                </div>

                <div className="col-1">
                  <div className='field-container' style={{ marginRight: '3px' }}>
                    <label id="media-query-label">Kuf/kif:</label>
                    <input type="text" id="media-query-input-text" maxLength="2" placeholder="kufkif" tabIndex="3" name='KUFKIF' value={form ? form.KUFKIF : ''} onFocus={() => unosIspravanDo("KUFKIF")} onChange={(e) => handleChange(e)} />
                  </div>
                </div>

                <div className="col-1">
                  <div className='field-container' style={{ marginRight: '3px' }}>
                    <label id="media-query-label">Entitet:</label>
                    <input type="text" id="media-query-input-text" maxLength="3" placeholder="entitet" tabIndex="4" name='ENTITET' value={form ? form.ENTITET : ''} onChange={(e) => handleChange(e)} />
                  </div>
                </div>

                <div className="col-2">
                  <div className='col-form-right'>
                    <div className='field-container-number' style={{ marginRight: '3px' }}>
                      <label id="media-query-label">Br. Kuf/kif:</label>
                      <NumericFormat
                        type="text"
                        className='field-alignment p-1'
                        getInputRef={kufkifRef}
                        maxLength="2"
                        decimalScale={0}
                        id="media-query-input-text"
                        placeholder="Broj kufa/kifa"
                        tabIndex="5"
                        name='BROJKUF'
                        value={form ? form.BROJKUF : ''}
                        onChange={(e) => handleChange(e)} />
                    </div>
                  </div>
                  {/* <button onClick={onSubmit} style={{ marginTop: '29px' }} className='button-dark btn-md' type='submit'>Submit</button> */}
                </div>

                <div className="col-1">
                  <div className='field-container' style={{ marginRight: '3px' }}>
                    <label id="media-query-label">K.obavezan:</label>
                    <select value={form?.OZNAKA}
                      id="media-query-input-text"
                      name='OZNAKA'
                      className='select-style'
                      placeholder="komit. obavezan"
                      onChange={(e) => handleChange(e)}
                      tabIndex="6">
                      <option value={''}>{''}</option>
                      <option value={'N'}>{'Ne'}</option>
                      <option value={'D'}>{'Da'}</option>
                    </select>
                  </div>
                </div>
              </div>
            </Row>
          </div>
        </Row>
      </Container>

      <div style={{ display: 'flex' }} className='search'>
        <input type='text' id="media-query-input-text" placeholder='Pretraga...'
          onChange={(e) => {
            // pretraga mreze(ukoliko je unesen bilo koji karakter, vrsi pretragu)
            if (searchInput.length >= 0) {
              searchChange(e)
            } else {
              getTableData()
            }
          }}
          value={searchInput} />

        {/* // <img src={Pencil} className='search-icon' /> */}
      </div>

      <DataTable
        columns={columns}
        data={konto ? konto : []}
        pagination
        responsive
        striped
        dense
        pointerOnHover
        paginationComponentOptions={defaultComponentOptions}
        paginationPerPage={[windowSize.height > 1300 ? 28
          : windowSize.height > 1270 ? 26
          : windowSize.height > 1222 ? 25
          : windowSize.height > 1200 ? 24
          : windowSize.height > 1000 ? 20
          : windowSize.height > 920 ? 17
          : windowSize.height > 900 ? 16
          : windowSize.height > 880 ? 15
          : windowSize.height > 820 ? 14
          : windowSize.height > 720 ? 13
                              : 4]}
        progressPending={pending}
      />

      {/* render za poruku */}
      <div>
        <Modal isOpen={Boolean(modalDeleteItem)} centered>
          <ModalHeader>Brisanje stavke</ModalHeader>
          <ModalBody>
            <span>{`Da li želite da obrišete konto ${form.KONTO} ? `}</span>
          </ModalBody>
          <ModalFooter>
            <div>
              <button
                onClick={() => { setModalDeleteItem(false); nameRef.current.focus() }}
                className="button-no">Ne
              </button>
              <button
                onClick={() => {
                  onDelete(form.ID);
                  setModalDeleteItem(false)
                }}
                className="button-yes">Da
              </button>
            </div>
          </ModalFooter>
        </Modal>
      </div>


      {/* render za poruku */}
      <div>
        <Modal isOpen={Boolean(modalErrorAuthorizationData)} centered  >
          <ModalHeader>Upozorenje!</ModalHeader>
          <ModalBody>
            <span>{`Nemate ovlašćenje za brisanje!`}</span>
          </ModalBody>
          <ModalFooter>
            <div>
              <button onClick={() => setModalErrorAuthorizationOpen(false)} className="button-no">Ok</button>
            </div>
          </ModalFooter>
        </Modal>
      </div>

      <div>
        <Modal isOpen={Boolean(modalErrorOpenData)} centered  >
          <ModalHeader>Nema podataka!</ModalHeader>
          <ModalFooter>
            <div>
              {/* Dugme za zatvaranje modalnog forma */}
              <button onClick={() => {
                setModalErrorOpenData(false)
              }} className="button-yes">Ok</button>
            </div>
          </ModalFooter>
        </Modal>
      </div>

      <div>
        {spinner ? <Spinner className='spinner' color="primary" id='loader' /> : ''}
      </div>

    </div>
  );

}

export default Konto;


