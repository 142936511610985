// importovanje komponenti 
import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import DataTable from 'react-data-table-component';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { API } from '../../constants';
import TextField from '@mui/material/TextField';
import { createFilterOptions } from '@mui/material/Autocomplete';
import Autocomplete from '@mui/material/Autocomplete';
import SVG from 'react-inlinesvg';
import { Spinner } from "reactstrap";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import {
    getCompanyData, getFakturniIznos, getIznosRabataIzlaz, getRabatProcenat, getPorezUIznosu, getPorezIzIznosa, getIznosMarzeMPredrac, getProdajnaCijena, getProcenatMarzeM, getIznosMarzeV, getProcenatMarzeV, getProdajniIznos, getNabavniIznos,
    helperValidateOnlyNumbers, numberWithCommas, numberWithCommasColumns, numberWithCommasColumns3, defaultComponentOptions, roundTO
} from '../utilities';
import { NumericFormat } from 'react-number-format';
import deleteDocument from '../../assets/delete_document.svg';

// komponenta koju kreiramo
function Predracun() {

    // definisanje referenci, stejtova i prva inicijalizacija; objekat i funkcija za taj objekat
    const dateDeliveryRef = useRef();
    const documentRef = useRef();
    const nomenRef = useRef();
    const dateRef = useRef();
    const clientsRef = useRef();
    const malcenRef = useRef();
    const quantityRef = useRef();
    const jmRef = useRef();
    const repositoryRef = useRef();
    const rbrRef = useRef();
    const currencyRef = useRef();
    const vrstaRef = useRef();
    const malcenPRef = useRef();
    const racunRef = useRef();
    const rabvelRef = useRef();
    const rabatRef = useRef();

    const navigate = useNavigate();
    let date = new Date();
    let year = new Date().getFullYear();

    // const [limit, setLimit] = useState(500);


    const [predracun, setPredracun] = useState([])
    const [vrsta, setVrsta] = useState(null);
    const [repository, setRepository] = useState(null)
    const [updateDocument, setUpdateDocument] = useState(false)
    const [clients, setClients] = useState(null);
    const [tax, setTax] = useState(null);
    const [artikal, setArtikal] = useState(null);
    const [selectData, setSelectData] = useState([]);
    const [selectDataNomen, setSelectDataNomen] = useState([]);
    const [selectDataVrsta, setSelectDataVrsta] = useState([]);
    const [selectDataClients, setSelectDataClients] = useState([]);
    const [startDate, setStartDate] = useState(date);
    const [endDate, setEndDate] = useState(date);
    const [currencyDate, setCurrencyDate] = useState(date);
    const [firstDockumentNumber, setFirstDockumentNumber] = useState(null)
    const [itemUpdate, setItemUpdate] = useState(false)
    const [sumNomenIznos, setSumNomenIznos] = useState(false)
    const [sumOsnovica, setSumOsnovica] = useState(false)
    const [sumProdajniIznos, setSumProdajniIznos] = useState(false)
    const [sumIznosRabata, setSumIznosRabata] = useState(false)
    const [sumIznosMarze, setSumIznosMarze] = useState(false)
    const [sumIznosPoreza, setSumIznosPoreza] = useState(false)
    const [totalstrana, setTotalStrana] = useState(0)
    const [perPage, setPerPage] = useState(10);

    const [errorInputRepository, setErrorInputRepository] = useState(false);
    const [errorInputClients, setErrorInputClients] = useState(false)
    const [errorInputVrsta, setErrorInputVrsta] = useState(false);
    const [errorInputDocument, setErrorInputDocument] = useState(false)
    const [errorInputNomen, setErrorInputNomen] = useState(false)
    const [errorInputQuantity, setErrorInputQuantity] = useState(false)
    const [errorInputPrice, setErrorInputPrice] = useState(false)
    const [errorInputRbr, setErrorInputRbr] = useState(false)
    const [errorDate, setErrorDate] = useState(false)
    const [errorDateDelivery, setErrorDateDelivery] = useState(false)
    const [errorInputJM, setErrorInputJM] = useState(false)
    const [errorInputMarza, setErrorInputMarza] = useState(false)
    const [errorInputMarzaSum, setErrorInputMarzaSum] = useState(false)
    const [errorInputMalcenP, setErrorInputMalcenP] = useState(false)

    const [errorMsgRepository, setErrorMsgRepository] = useState(false);
    const [errorMsgDocument, setErrorMsgDocument] = useState(false)
    const [errorMsgNomen, setErrorMsgNomen] = useState(false)
    const [errorMsgClients, setErrorMsgClients] = useState(false)
    const [errorMsgQuantity, setErrorMsgQuantity] = useState(false)
    const [errorMsgRbr, setErrorMsgRbr] = useState(false)
    const [errorMsgDate, setErrorMsgDate] = useState(false)
    const [errorMsgDateDelivery, setErrorMsgDateDelivery] = useState(false)
    const [errorMsgCurrency, setErrorMsgCurrency] = useState(false)
    const [errorCurrency, setErrorCurrency] = useState(false)
    const [errorMsgVrsta, setErrorMsgVrsta] = useState(false)
    const [errorMsgPrice, setErrorMsgPrice] = useState(false)
    const [errorMsgJM, setErrorMsgJM] = useState(false)
    const [errorMsgMalcenP, setErrorMsgMalcenP] = useState(false)

    const [repositoryFocus, setRepositoryFocus] = useState(true);
    const [vrstaFocus, setVrstaFocus] = useState(true);
    const [documentFocus, setDocumentFocus] = useState(false);
    const [clientsFocus, setClientsFocus] = useState(false);
    const [priceFocus, setPriceFocus] = useState(false)
    const [quantityFocus, setQuantityFocus] = useState(false)
    const [rbrFocus, setRbrFocus] = useState(false)
    const [malcenPFocus, setMalcenPFocus] = useState(false)


    const [dateFocus, setDateFocus] = useState(false)
    const [dateDeliveryFocus, setDateDeliveryFocus] = useState(false)
    const [currencyFocus, setCurrencyFocus] = useState(false)
    const [nomenFocus, setNomenFocus] = useState(false)

    const [modalDeleteItem, setModalDeleteItem] = useState(false);
    const [modalErrorOpen, setModalErrorOpen] = useState(false);
    const [modalErrorLogin, setModalErrorLogin] = useState(false);
    const [modalErrorDeleteDocumentOpen, setModalErrorDeleteDocumentOpen] = useState(false);
    const [modalErrorDeleteItemOpen, setModalErrorDeleteItemOpen] = useState(false)
    const [modalErrorOpenData, setModalErrorOpenData] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    // povlacenje podataka iz lokal storidza
    const token = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : null;
    // definisanje stejta(objekat i funkcija za taj objekat), prva inicijalizacija
    const [form, setForm] = useState({
        SKLAID: '',
        DOK: '',
        DPO: '',
        DPOR: '',
        VALUTA: '',
        KOMTID: 0,
        RACUN: '',
        VRPROM: '',
        NOMENID: 0,
        RBR: '',
        KOL: '',
        RABVEL: '',
        IZNOSRAB: '',
        NABCEN: 0.0000,
        IZND: 0.000,
        JM: '',
        SIFRA: '',
        VRSTA_SKLADISTA: '',
        MALCEN: '',
        MALCENP: '',
        IZNMALD: 0.000,
        IZNMALDP: 0.000,
        PDV: 0.000,
        IZPDV: 0.000,
        RAZCEN: 0.000,
        IZNRAZD: 0.000,
        PREDRACID: 0,
        ID: 0,
        VRSTA_SKLADISTA: '',
        NAZIV: '',
        LAGER: '0.00',
        FAKTCEN: 0,
        IZNFAKT: 0,
    })
    // funkcija za povlacenje podataka o preduzecu
    getCompanyData(form)

    //funkcije za pretragu autokomplita na artiklima 
    const OPTIONS_LIMIT = 10;
    const defaultFilterOptions = createFilterOptions();
    const filterOptions = (options, state) => {
        return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
    };

    // console.log(localStorage.getItem('company'), 'localStorage.getItem') 
    // funkcija koja se poziva klikom na dugme 'Sacuvaj'
    const onSubmit = () => {
        // uslov: ako nisu unesena obavezna polja, zaustavlja upis
        if (!unosIspravanDo('UPIS')) return;

        //setovanje datuma(ako nisu zadate druge vrijednosti, uzima difoltne)
        if (form && !form.DPO && !form.DPOR) {
            form.DPO = startDate
            form.DPOR = endDate
            form.VALUTA = currencyDate
        }
        form.SIFKOR = form.userCode

        // kontrolisanje obaveznih polja na dugmetu sacuvaj 
        // uslov: ukoliko neko od obaveznih polja nije popunjeno, zaustavlja upis i vraca na to polje. U suprotnom prolazi na upis 

        // poziva api za upis, osvjezavanje polja, mreze i totala nakon upisa
        //    console.log('upada u novi');
        //    console.log(form.RBR, 'rbr')
        fetch(API + '/predracun/new', {

            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        }).then((response) => {
            response.json()
            setForm(prev => ({
                ...prev, LAGER: '', PDV: '',
                IZPDV: '', RAZCEN: '', IZNRAZD: '', RBR: '', NOMENID: '', JM: '', KOL: '', RABVEL: '', IZNOSRAB: '', NABCEN: '', IZND: '', MALCEN: '', MALCENP: '', IZNMALD: '', IZNMALDP: ''
            }))
            setArtikal(null)
        }).then(() => {
            getPredracunData(1, perPage)
            getTotal()
            setErrorInputMarza(false)
        })
        // zaustavljanje fokusa(pauza za fokus dok ne odradi upis) 
        const timeout = setTimeout(() => {
            rbrRef.current.focus()
        }, 100);
        return () => {
            clearTimeout(timeout);
        };
    }

    // funkcija koja prati promjenu stanja(poziva apije za citanje i popunjavanje autokomplitova) 
    useEffect(() => {
        getRepositoryData()
        getNomenData()
        getVrstaData()
        getClientsData()
        //  // setovanje vrste prometa na UL
        //  setVrsta({
        //     VRSTA: "IZ",
        //     NAZIV: ""
        // });
    }, [])

    // funkcija koja poziva citanje skladista
    const getRepositoryData = () => {
        fetch(API + `/skladista/all/${(form.companyCode)}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectData(data)
            })
        // .catch((error) => {
        //     setModalErrorLogin(true)
        // });
    }

    // funkcija koja poziva citanje artikla
    const getNomenData = () => {
        fetch(API + `/artikal/autocomplete`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                //  setSelectDataNomen(data)
                setSelectDataNomen(data);
            })
    }

    // funkcija koja poziva citanje poreza
    const getTaxData = () => {
        fetch(API + `/porezi/procenat`, {
            method: 'POST',
            body: JSON.stringify({ nomenId: form.NOMENID }),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                if (data && data.length) {
                    setForm(prev => ({ ...prev, PDV: data[0].PROCENAT }))
                }
            })

    }

    // funkcija koja poziva citanje lagera
    const getLagerData = () => {
        //   console.log(form.SKLAID, 'form.SKLAID', form.NOMENID, 'form.NOMENID')
        fetch(API + `/lager/all`, {
            method: 'POST',
            body: JSON.stringify({ sklaId: repository && repository.ID ? repository.ID : '', nomenId: form.NOMENID }),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                if (data && data.length) {
                    setForm(prev => ({ ...prev, LAGER: data[0].LAGER }))
                } else {
                    setForm(prev => ({ ...prev, LAGER: 0 }))
                }
            })

    }

    // funkcija koja poziva citanje cjenovnika
    const getCjenovnikData = () => {
        fetch(API + `/cjenovnik/all`, {
            method: 'POST',
            body: JSON.stringify({ sklaId: repository && repository.ID ? repository.ID : '', nomenId: form.NOMENID }),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                //    console.log(data)
                if (data && data.length) {
                    setForm(prev => ({ ...prev, MALCEN: (data[0].PRODCENA).toFixed(2), NABCEN: data[0].NABCENA, FAKTCEN: data[0].NABCENA }))
                } else {
                    setForm(prev => ({ ...prev, NABCEN: 0, FAKTCEN: 0 }))
                }
            })

    }

    // funkcija koja poziva citanje vrste
    const getVrstaData = () => {
        fetch(API + `/vrstaDok/all`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataVrsta(data)
            })

    }

    // funkcija koja poziva citanje naziva vrste prema poslatom parametru
    // const getVrstaName = () => {
    //     console.log(vrsta, 'vrsta', vrsta.VRSTA, 'vrsta.VRSTA')
    //     fetch(API + `/vrstaDok/name`, {
    //         method: 'POST',
    //         body: JSON.stringify({vrprom: vrsta.VRSTA }),
    //         headers: {
    //             'Authorization': `Bearer ${token}`,
    //             'Content-type': 'application/json; charset=UTF-8'
    //         },
    //     })
    //         .then((response) => response.json())
    //         .then(data => {
    //             console.log(data, 'data')
    //            // setSelectDataVrstaName(data)
    //            setVrsta(prev => ({ ...prev, NAZIV: data[0].NAZIV }))

    //         })

    // }

    // funkcija koja poziva citanje komitenta
    const getClientsData = () => {
        fetch(API + `/komitenti/all/${(form.companyCode)}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataClients(data)
            })

    }

    // funkcija koja poziva api za pronalazenje prvog slobodnog dokumenta
    // setovanje polja DOK i ID
    const getPredracunFirstFreeNumber = () => {
        fetch(API + `/predracun/firstFreeNumber`, {
            method: 'POST',
            body: JSON.stringify({ sklaId: repository && repository.ID ? repository.ID : '', vrprom: vrsta && vrsta.VRSTA ? vrsta.VRSTA : '' }),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setForm(prev => ({ ...prev, DOK: data[0].DOK, ID: data[0].ID }))
                setFirstDockumentNumber(data[0].DOK, data[0].ID)
                //  getPredracunFirstFreeNumberRbr()
            })

    }

    // funkcija koja poziva api za pronalazenje prvog slobodnog rednog broja
    // setovanje polja RBR
    const getPredracunFirstFreeNumberRbr = () => {
        fetch(API + `/predracun/firstFreeNumberRbr`, {
            method: 'POST',
            body: JSON.stringify({ sklaId: repository && repository.ID ? repository.ID : '', vrprom: vrsta && vrsta.VRSTA ? vrsta.VRSTA : '', dok: form.DOK }),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                //   console.log('getPredracunFirstFreeNumberRbr', data[0].RBR)
                setForm(prev => ({ ...prev, RBR: data[0].RBR }))
            })

    }

    // funkcija koja poziva api za mrezu
    // setovanje mreze 
    const getPredracunData = (page, limit) => {
        fetch(API + `/predracun/document/${page}/${limit}`, {
            method: 'POST',
            body: JSON.stringify({ sklaId: repository && repository.ID ? repository.ID : '', vrprom: form.VRPROM, dok: form.DOK }),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setPredracun(data.result)
                setTotalStrana(data.totalstrana)
                // provjera da li ima podataka( u zavisnosti od toga da li ima podataka ili ne, setuje PREDRACID)
                if (data != null && data.result.length > 0) {
                    setForm(prev => ({ ...prev, PREDRACID: data.result[0].PREDRACID }))
                    //  console.log('getPredracunData1')
                } else {
                    setForm(prev => ({ ...prev, PREDRACID: 0 }))
                    //   console.log('getPredracunData2')
                }
            })

    }

    // funkcija koja poziva api za citanje po dokumentu
    const checkDocument = () => {
        fetch(API + `/predracun/check/documentData`, {
            method: 'POST',
            body: JSON.stringify({ sklaId: repository && repository.ID ? repository.ID : '', vrprom: form.VRPROM, dok: form.DOK }),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        }).then((response) => response.json())
            .then(data => {

                //uslov: ako ima podataka, da popuni polja, procita mrezu i procita sume. U suprotnom cisti sume
                if (data && data.length) {

                    setForm(prev => ({ ...prev, DPO: moment.unix(new Date(data[0].DPO).getTime() / 1000).format("DD.MM.YYYY"), DPOR: moment.unix(new Date(data[0].DPOR).getTime() / 1000).format("DD.MM.YYYY"), VALUTA: moment.unix(new Date(data[0].VALUTA).getTime() / 1000).format("DD.MM.YYYY"), KOMTID: data[0].KOMTID, ID: data[0].ID, RACUN: data[0].RACUN }))
                    selectDataClients.map((item) => {
                        if (item.ID == data[0].KOMTID) {
                            setClients(item)
                        }
                    })
                    getPredracunData(1, perPage)
                    setUpdateDocument(true)
                    getTotal()
                } else {
                    console.log('upada u else')
                    setUpdateDocument(false)
                    setSumNomenIznos(false)
                    setSumOsnovica(false)
                    setSumProdajniIznos(false)
                    setSumIznosRabata(false)
                    setSumIznosPoreza(false)
                    setSumIznosMarze(false)
                    setErrorInputMarza(false)
                    setErrorInputMarzaSum(false)
                    setClients(null);
                    setPredracun(null)
                    setForm(prev => ({ ...prev, DPO: moment.unix(new Date(startDate).getTime() / 1000).format("DD.MM.YYYY"), DPOR: moment.unix(new Date(endDate).getTime() / 1000).format("DD.MM.YYYY"), VALUTA: moment.unix(new Date(currencyDate).getTime() / 1000).format("DD.MM.YYYY") }))
                }
            })

    }

    // funkcija koja poziva api za citanje totala(suma)
    const getTotal = () => {
        fetch(API + `/predracun/total`, {
            method: 'POST',
            body: JSON.stringify({ sklaId: repository && repository.ID ? repository.ID : '', vrprom: form.VRPROM, dok: form.DOK }),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        }).then((response) => response.json())
            .then(data => {

                // 1.uslov: ako ima podataka, izvrsice se racunske operacije, kumulativno punjenje rabata, poreza i marze 
                if (data && data.length) {

                    // 2.uslov: ako je u pitanju maloprodaja, izvrsice se racunske operacije i setovanje suma. U suprotnom izvrsice se racunske operacije za veleprodaju i setovanje suma  
                    if (form.VRSTA_SKLADISTA === 'M') {
                        setSumNomenIznos((data[0].IZNMALD - data[0].IZPDV).toFixed(2))
                        setSumOsnovica((data[0].PRODIZNOS - data[0].IZPDV).toFixed(2))
                        setSumProdajniIznos((data[0].PRODIZNOS).toFixed(2))
                    } else {
                        setSumNomenIznos((data[0].IZNMALD).toFixed(2))
                        setSumOsnovica((data[0].PRODIZNOS).toFixed(2))
                        setSumProdajniIznos((data[0].PRODIZNOS + data[0].IZPDV).toFixed(2))
                    }
                    setSumIznosRabata((data[0].IZNOSRABSum).toFixed(2))
                    setSumIznosPoreza((data[0].IZPDVSum).toFixed(2))
                    setSumIznosMarze((data[0].IZNRAZDSum).toFixed(2))
                    if (data[0].IZNRAZDSum < 0) {
                        setErrorInputMarzaSum(true)
                    } else {
                        setErrorInputMarzaSum(false)
                    }
                }
            })

    }

    // funkcija koja poziva brisanje dokumenta
    const onDelete = () => {
        let rbrw = 0;
        let nabcenw = 0;
        let predracIdw = 0;
        // fetch(API + `/predracun/delete/${sklaId}/${vrprom}/${dok}`, {
        //     method: 'DELETE',
        //     headers: {
        //         'Authorization': `Bearer ${token}`,
        //         'Content-type': 'application/json; charset=UTF-8'
        //     },
        // })
        //     .then((response) => {
        //         if (response.status == 200) {
        //             setModalOpen(false)
        //             setPredracun(null)
        //             setClients(null)
        //             setArtikal(null)
        //             //   setUpdateDocument(false)
        //             checkDocument()
        //             setForm(prev => ({
        //                 ...prev,   PREDRACID: 0,
        //                 DPO: '',
        //                 DPOR: '',
        //                 VALUTA: '',
        //                 KOMTID: 0,
        //                 RACUN: '',
        //                 NOMENID: 0,
        //                 RBR: '',
        //                 KOL: '',
        //                 RABVEL: '',
        //                 IZNOSRAB: '',
        //                 NABCEN: 0.0000,
        //                 IZND: 0.000,
        //                 JM: '',
        //                 SIFRA: '',
        //                 MALCEN: '',
        //                 MALCENP: '',
        //                 IZNMALD: 0.000,
        //                 IZNMALDP: 0.000,
        //                 PDV: 0.000,
        //                 IZPDV: 0.000,
        //                 RAZCEN: 0.000,
        //                 IZNRAZD: 0.000,
        //                 PREDRACID: 0,
        //                 ID: 0,
        //                 NAZIV: '',
        //                 LAGER: '0.00',
        //                 FAKTCEN: 0,
        //                 IZNFAKT: 0,
        //             }))
        //         }
        //     })

        //  zaustavljanje fokusa(pauza za fokus dok ne odradi brisanje) 
        // const timeout = setTimeout(() => {
        //     documentRef.current.focus()
        // }, 400);
        // return () => {
        //     clearTimeout(timeout);
        // };

        // petlja za citanje podataka
        for (let i = 0; i < predracun.length; i++) {
            rbrw = predracun[i].RBR
            nabcenw = predracun[i].NABCEN
            predracIdw = predracun[i].PREDRACID
            onDeleteItems(predracIdw, rbrw)
            setModalOpen(false)
        }
        setForm(prev => ({
            ...prev, 
            PREDRACID: 0,
            DPO: '',
            DPOR: '',
            VALUTA: '',
            RACUN: '',
        }))
        setClients(null)
         const timeout = setTimeout(() => {
            documentRef.current.focus()
        }, 500);
        return () => {
            clearTimeout(timeout);
        };
    }

    // funkcija koja poziva api za brisanje stavki
    const onDeleteItems = (predracId, rbr) => {
        fetch(API + `/predracun/item/delete/${predracId}/${rbr}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                // uslov: ako je uspjesno obrisano, cisti sva polja u vezi sa stavkom  
                if (response.status == 200) {
                    setModalDeleteItem(false)
                    // setPredracun(null)
                    setArtikal(null)
                   checkDocument()
                    setItemUpdate(false)
                    setForm(prev => ({
                        ...prev, JM: '',  KOL: '', NABCEN: '', IZND: '', RABVEL: '', IZNOSRAB: '', PREDRACID: 0,
                        MALCEN: '', MALCENP: '', IZNMALD: '', IZNMALDP: '', PDV: '', IZPDV: '', RAZCEN: '', IZNRAZD: '', LAGER: '',
                    }))
                }
                // zaustavljanje fokusa(pauza za fokus dok ne odradi brisanje) 
                const timeout = setTimeout(() => {
                    rbrRef.current.focus()
                }, 400);
                return () => {
                    clearTimeout(timeout);
                };
            })

    }

    // funkcija koja se poziva dvoklikom na red u mrezi(na stavku), popunjava polja za stavku
    const onEdit = (item) => {
        setItemUpdate(true)
        //     console.log(form.IZNRAZD < 0, 'form.IZNRAZD < 0 prije')
        setForm((prevState) => ({
            ...prevState, PREDRACID: item.PREDRACID, RBR: item.RBR, JM: item.JM, NABCEN: item.NABCEN, IZND: item.IZND, NOMENID: item.NOMENID,
            KOL: item.KOL, RABVEL: item.PROCRABAT, IZNOSRAB: item.Rabat, MALCEN: item.MALCEN, MALCENP: item.Prod_Cijena, IZNMALDP: item.Prod_Iznos, IZPDV: item.IZPDV, RAZCEN: item.RAZCEN, PDV: item.PROCPDV, IZNRAZD: item.MARZA, LAGER: item.LAGER,
        }));

        // IZNRAZD se puni da bismo ga iskoristili za provjeru vrijednosti iznosa marze
        let IZNRAZD = item.IZNRAZD

        // uslov: ako je iznos marze manji od 0, polje ce da pocrveni posle dvoklika na red
        if (IZNRAZD < 0) {
            setErrorInputMarza(true)
        } else {
            setErrorInputMarza(false)
        }

        // form.NOMENID se puni da bi radilo citanje lagera iz mreze
        form.NOMENID = item.NOMENID
        getLagerData()

        // autokomplit polja se moraju dodatno provjeriti i setovati tako sto cemo napraviti mapu ciji je parametar objekat iz datog autokomplita
        // uslov: ako pronadje taj artikal, tek onda ga moze setovati(u funciju setForm iznad) 
        selectDataNomen.map((artikal) => {
            if (artikal.ID == item.NOMENID) {
                setArtikal(artikal)
            }
        })
    }

    // funkcija koja hvata promjene na poljima i  setuje vrijednost u form state
    const handleChange = (event) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name;

        setForm((prevState) => ({
            ...prevState,
            [name]: value
        }));

    };

    // propsovi za auto komplit  - skladiste, komitent, artikal i vrsta; objekat za dati autokomplit iz koga preko mape mozemo procitati podatak upisan u bazu
    //  getOptionLabel - polja koja ce biti prikazana u autokomplitu
    const defaultProps = {
        options: selectData,
        getOptionLabel: (option) => option ? `${option.SIFRA} ${option.NAZIV}` : '',
    };

    const defaultPropsNomen = {
        options: selectDataNomen,
        getOptionLabel: (option) => option ? `${option.SIFRA} ${option.NAZIV}` : '',
    };
    const defaultPropsVrsta = {
        options: selectDataVrsta,
        getOptionLabel: (option) => option ? ` ${option.VRSTA} ${option.NAZIV}` : '',
    };
    const defaultPropsClients = {
        options: selectDataClients,
        getOptionLabel: (option) => option ? `${option.SIFRA} ${option.NAZIV}` : '',
    };

    // funkcije za provjeru obaveznih polja - skladiste
    const checkRepositoryRequired = (value) => {

        // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto) ili ako nije upisana ni vrijednost niti je odabrano skladiste, izbaci poruku, setuje crveni okvir i fokusira polje  
        // u suprotnom prolazi dalje
        if (value && !repository || !value && !repository) {
            setErrorMsgRepository('Skladiste mora biti izabrano!')
            setErrorInputRepository(true)
            repositoryRef.current.focus();
            setRepositoryFocus(true)
            return false
        } else {
            setErrorMsgRepository(null)
            setErrorInputRepository(false)
            return true
        }
    }

    // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto) ili ako nije upisana ni vrijednost niti je odabrana vrsta, izbaci poruku, setuje crveni okvir i fokusira polje  
    // u suprotnom prolazi dalje
    const checkVrstaRequired = (value) => {
        if (value && !vrsta || !value && !vrsta) {
            setErrorMsgVrsta('Vrsta mora biti izabrana!')
            setErrorInputVrsta(true)
            vrstaRef.current.focus();
            setVrstaFocus(true)
            return false
        } else {
            setErrorMsgVrsta(null)
            setErrorInputVrsta(false)
            return true
        }
    }

    // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto) ili ako nije upisana ni vrijednost niti je odabran komitent, izbaci poruku, setuje crveni okvir i fokusira polje  
    // u suprotnom prolazi dalje
    const checkClientsRequired = (value) => {
        if (value && !clients || !value && !clients) {
            setErrorMsgClients('Komitent mora biti izabran!')
            setErrorInputClients(true)
            clientsRef.current.focus();
            setClientsFocus(true)
            return false
        } else {
            setErrorMsgClients(null)
            setErrorInputClients(false)
            return true
        }
    }

    // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto) ili ako nije upisana ni vrijednost niti je odabran dokument, izbaci poruku, setuje crveni okvir i fokusira polje  
    // u suprotnom prolazi dalje
    const checkDocumentRequired = (value) => {
        //    console.log(value, 'value')
        if (!value || value == 0) {
            setErrorMsgDocument('Dokument je obavezan!')
            setErrorInputDocument(true)
            documentRef.current.focus();
            return false
        } else {
            setErrorMsgDocument(null)
            setErrorInputDocument(false)
            return true
        }
    }

    // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto) ili ako nije upisana ni vrijednost niti je odabran dokument, izbaci poruku, setuje crveni okvir i fokusira polje  
    // u suprotnom prolazi dalje
    const checkRBRRequired = (value) => {
        //   console.log(value, 'value RBR')
        if (!value || value == 0) {
            //    console.log('upada u rbr', value)
            setErrorMsgRbr('Rbr je obavezan!')
            setErrorInputRbr(true)
            rbrRef.current.focus();
            return false
        } else {
            setErrorMsgRbr(null)
            setErrorInputRbr(false)
            return true
        }
    }

    // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto) ili ako nije upisana ni vrijednost niti je odabran artikal, izbaci poruku, setuje crveni okvir i fokusira polje  
    // u suprotnom prolazi dalje
    const checkNomenRequired = (value) => {
        if (value && !artikal || !value && !artikal) {
            setErrorMsgNomen('Artikal je obavezan!')
            setErrorInputNomen(true)
            nomenRef.current.focus();
            return false
        } else {
            setErrorMsgNomen(null)
            setErrorInputNomen(false)
            return true
        }
    }

    // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto) ili ako nije upisana ni vrijednost niti je odabran dokument, izbaci poruku, setuje crveni okvir i fokusira polje  
    // u suprotnom prolazi dalje
    const checkKolRequired = (value) => {
        // uslov: ako nije upisana vrijednost ili je vrijednost 0 i odabran je artikal, izbaci poruku i setuje crveni okvir. U suprotnom: prolazi dalje i racuna prodajni iznos(misli se na racunanje unazad)
        if (!value || value == 0) {
            setErrorMsgQuantity(helperValidateOnlyNumbers(value))
            setErrorInputQuantity(helperValidateOnlyNumbers(value) ? true : false)
            setErrorMsgQuantity('Količina je obavezna!')
            quantityRef.current.focus();
            setQuantityFocus(true)
            return false
        } else {
            setErrorMsgQuantity(null)
            setErrorInputQuantity(false)
            setForm(prev => ({ ...prev, IZNMALD: (Number(form.KOL) * Number(form.MALCEN)) }))
            return true
        }
    }

    const checkMalcenRequired = (value) => {
        if (!value || value == 0) {
            setErrorMsgPrice(helperValidateOnlyNumbers(value))
            setErrorInputPrice(helperValidateOnlyNumbers(value) ? true : false)
            setErrorMsgPrice('Cijena je obavezna!')
            malcenRef.current.focus();
            setPriceFocus(true)
            return false

        } else {
            setErrorMsgPrice(null)
            setErrorInputPrice(false)
            setForm(prev => ({ ...prev, IZNMALD: (Number(form.KOL) * Number(form.MALCEN)), MALCENP: Number(form.MALCEN), IZNMALDP: Number(form.IZNMALD) }))
            // if (form.VRSTA_SKLADISTA == 'V') {
            //     console.log(form.IZNMALD, 'form.IZNMALD vp')
            //     setForm(prev => ({ ...prev, IZPDV: ((Number(form.IZNMALD * form.PDV)) / 100).toFixed(3) }))
            //     //    console.log(form.IZPDV, 'form.IZPDV2')
            // } else {
            //     const IZPDV = getPorezIzIznosa(form.PDV, form.IZNMALD)
            //     setForm(prev => ({ ...prev, IZPDV: Number(IZPDV) }))
            // }
            const IZNFAKT = getFakturniIznos(form.KOL, form.FAKTCEN)
            setForm(prev => ({ ...prev, IZNFAKT: Number(IZNFAKT) }))
            const IZND = getNabavniIznos(form.KOL, form.NABCEN)
            //   console.log('ovdje sam')
            setForm(prev => ({ ...prev, IZND: Number(IZND) }))
            return true
        }
    }
    // funkcija za kontrolu obaveznih polja
    function unosIspravanDo(name) {
        let stariRabat;
        if (name === 'SKLAID') {
            return true
        }

        if (!checkRepositoryRequired(form.SKLAID)) {
            return false
        }

        // kontrolise sva polja do polja VRPROM
        if (name === 'VRPROM') {
            return true
        }

        if (!checkVrstaRequired(form.VRPROM)) {
            return false
        }

        // kontrolise sva polja do polja DOK
        if (name === 'DOK') {
            return true
        }

        if (!checkDocumentRequired(form.DOK)) {
            return false
        }

        // kontrolise sva polja do polja DPO 
        if (name === 'DPO') {
            return true
        }

        let reqex = /^(0[1-9]|[12]\d|3[01]).(0[1-9]|1[0-2]).([12]\d{3})$/gi;

        if (!reqex.test(form.DPO)) {
            setErrorDate(true)
            setErrorMsgDate('Datum nije validan!')
            dateRef.current.focus()
            return false
        } else {
            setErrorDate(false)
            setErrorMsgDate(null)
            //   setStartDate(startDate)
        }

        // kontrolise sva polja do polja DPOR 
        if (name === 'DPOR') {
            return true
        }

        let reqexDPOR = /^(0[1-9]|[12]\d|3[01]).(0[1-9]|1[0-2]).([12]\d{3})$/gi;
        if (!reqexDPOR.test(form.DPOR)) {
            setErrorDateDelivery(true)
            setErrorMsgDateDelivery('Datum nije validan!')
            dateDeliveryRef.current.focus()
            return false
        } else {
            setErrorDateDelivery(false)
            setErrorMsgDateDelivery(null)
        }

        // kontrolise sva polja do polja VALUTA 
        if (name === 'VALUTA') {
            return true
        }
        let reqexVALUTA = /^(0[1-9]|[12]\d|3[01]).(0[1-9]|1[0-2]).([12]\d{3})$/gi;
        if (!reqexVALUTA.test(form.VALUTA)) {
            setErrorCurrency(true)
            setErrorMsgCurrency('Valuta nije validna!')
            currencyRef.current.focus();
            return false
        } else {
            setErrorCurrency(false)
            setErrorMsgCurrency(null)
        }

        // kontrolise sva polja do polja RACUN 
        if (name === 'RACUN') {
            return true
        }

        // kontrolise sva polja do polja KOMTID 
        if (name === 'KOMTID') {
            return true
        }

        if (!checkClientsRequired(form.KOMTID)) {
            return false
        }

        // kontrolise sva polja do polja RBR 
        if (name === 'RBR') {
            return true
        }

        if (!checkRBRRequired(form.RBR)) {
            return false
        }

        // kontrolise sva polja do polja NOMENID 
        if (name === 'NOMENID') {

            return true
        }

        if (!checkNomenRequired(form.NOMENID)) {
            return false
        }

        // kontrolise sva polja do polja KOL 
        if (name === 'KOL') {
            return true
        }

        if (!checkKolRequired(form.KOL)) {
            return false
        }

        // kontrolise sva polja do polja MALCEN 
        if (name === 'MALCEN') {
            return true
        }

        if (!checkMalcenRequired(form.MALCEN)) {
            return false
        }

        // kontrolise sva polja do polja RABVEL 
        if (name === 'RABVEL') {
            return true
        }
        
        if(form.IZNOSRAB == 0){
            const IZNOSRAB = getIznosRabataIzlaz(form.RABVEL, form.IZNMALD)
            setForm(prev => ({ ...prev, IZNOSRAB: Number(IZNOSRAB) }))
        }

        // kontrolise sva polja do polja IZNOSRAB 
        if (name === 'IZNOSRAB') {
            return true
        }

        // uslov: ako iznos rabata ima neku vrijednost i ta vrijednost nije 0, racuna prodajnu cijenu umanjenu za iznos rabata, a i prodajni iznos, takodje. U suprotnom: prodajna cijena i prodajni iznos ostaju na pocetnim vrijednostima
        //  if (form.IZNOSRAB && form.IZNOSRAB !== 0) {
        const MALCENP = getProdajnaCijena(form.KOL, form.IZNMALD, form.IZNOSRAB)
        setForm(prev => ({ ...prev, MALCENP: Number(MALCENP) }))
        const IZNMALDP = getProdajniIznos(form.IZNMALD, form.IZNOSRAB)
        setForm(prev => ({ ...prev, IZNMALDP: Number(IZNMALDP) }))
        //  } else {
        //   setForm(prev => ({ ...prev, MALCENP: Number(form.MALCEN), IZNMALDP: Number(form.IZNMALD) }))
        //  }

        // uslov: ukoliko se mijenja iznos rabata, ponovo racuna procenat rabata  
        // stariRabat = form.IZNOSRAB
        let staraProdajnaCijena = form.MALCEN

        // kontrolise sva polja do polja IZNOSRAB 
        if (name === 'MALCENP') {
            return true
        }
        if (form.IZNOSRAB == stariRabat) {
            // const RABVEL = getRabatProcenat(form.IZNOSRAB, form.IZNMALD)
            // setForm(prev => ({ ...prev, RABVEL: Number(RABVEL) }))
            stariRabat = roundTO((form.MALCEN * form.KOL * form.RABVEL)/100, 2) 
        }

        if(form.IZNOSRAB !== stariRabat) {
            const RABVEL = roundTO(form.MALCEN != 0 ? (form.IZNOSRAB * 100) / (form.MALCEN * form.KOL) : 0, 2); 
            setForm(prev => ({ ...prev, RABVEL: Number(RABVEL) }))
        }
        //uslov: ukoliko se mijenja prodajna cijena, racuna unazad iznos rabata i procenat rabata
        // if (form.MALCENP != staraProdajnaCijena) {
        //     console.log(form.MALCENP != staraProdajnaCijena, 'form.MALCENP != staraProdajnaCijena', form.MALCENP, 'form.MALCENP', staraProdajnaCijena, 'staraProdajnaCijena')
        //     setForm(prev => ({ ...prev, IZNMALDP: (Number(form.MALCENP) * (form.KOL)) }))
        //     form.IZNOSRAB = (Number(form.MALCEN) - Number(form.MALCENP)).toFixed(2)
        //     setForm(prev => ({
        //         ...prev, IZNOSRAB: Number(form.IZNOSRAB)
        //     }))
        //     const RABVEL = getRabatProcenat(form.IZNOSRAB, form.IZNMALD)
        //     setForm(prev => ({ ...prev, RABVEL: Number(RABVEL) }))
        // }

        // uslov: ako nema nabavne cijene u cjenovniku, racuna nabavnu cijenu i ostala racunanja u zavisnosti vrste skladista
        if (form.NABCEN == '' || form.NABCEN == 0) {

            //uslov: ako je vrsta skladista maloprodaja
            if (form.VRSTA_SKLADISTA == 'M') {

                form.IZPDV = getPorezUIznosu(form.PDV, form.KOL, form.MALCEN)
                setForm(prev => ({ ...prev, IZPDV: Number(form.IZPDV) }))
                form.NABCEN = (((Number(form.KOL) * Number(form.MALCEN)) - form.IZPDV) / form.KOL).toFixed(3)
                setForm(prev => ({ ...prev, NABCEN: Number(form.NABCEN) }))
                form.FAKTCEN = form.NABCEN
                setForm(prev => ({ ...prev, FAKTCEN: Number(form.FAKTCEN) }))

                form.IZNFAKT = getFakturniIznos(form.KOL, form.FAKTCEN)
                setForm(prev => ({ ...prev, IZNFAKT: Number(form.IZNFAKT) }))
                form.IZND = getNabavniIznos(form.KOL, form.NABCEN)

                setForm(prev => ({ ...prev, IZND: Number(form.IZND) }))
                form.IZNRAZD = getIznosMarzeMPredrac(form.IZNMALD, form.IZND, form.IZPDV, form.IZNOSRAB)
                setForm(prev => ({ ...prev, IZNRAZD: Number(form.IZNRAZD) }))

                //uslov: ukoliko je iznos marze manji od 0, polje pocrveni
                // if (form.IZNRAZD < 0) {
                //     setErrorInputMarza(true)
                // } else {
                //     setErrorInputMarza(false)
                // }
                form.RAZCEN = getProcenatMarzeM(form.IZNRAZD, form.IZND)
                setForm(prev => ({ ...prev, RAZCEN: Number((form.RAZCEN)).toFixed(3) }))

                //uslov: ako je vrsta skladista veleprodaja
            } else {
                form.IZPDV = getPorezIzIznosa(form.PDV, form.MALCENP, form.KOL)
                setForm(prev => ({ ...prev, IZPDV: Number(form.IZPDV) }))

                form.NABCEN = ((Number(form.KOL) * Number(form.MALCEN)) / form.KOL).toFixed(3)
                setForm(prev => ({ ...prev, NABCEN: Number(form.NABCEN) }))

                form.FAKTCEN = form.NABCEN
                setForm(prev => ({ ...prev, FAKTCEN: Number(form.FAKTCEN) }))
                form.IZNFAKT = getFakturniIznos(form.KOL, form.FAKTCEN)
                setForm(prev => ({ ...prev, IZNFAKT: Number(form.IZNFAKT) }))
                form.IZND = getNabavniIznos(form.KOL, form.NABCEN)

                setForm(prev => ({ ...prev, IZND: Number(form.IZND) }))

                form.IZNRAZD = ((Number(form.KOL) * Number(form.MALCENP)) - Number(form.IZND))
                setForm(prev => ({
                    ...prev, IZNRAZD: Number((form.IZNRAZD)).toFixed(2)
                }))

                //uslov: ukoliko je iznos marze manji od 0, polje pocrveni
                // if (form.IZNRAZD < 0) {
                //     setErrorInputMarza(true)
                // } else {
                //     setErrorInputMarza(false)
                // }
                form.RAZCEN = getProcenatMarzeV(form.IZND, form.IZNRAZD)
                setForm(prev => ({ ...prev, RAZCEN: Number(form.RAZCEN) }))

            }

            //uslov: ukoliko ima nabavna cijena, koristi tu nabavnu cijenu za racunanje
        } else {
            //uslov: ako je vrsta skladista maloprodaja
            if (form.VRSTA_SKLADISTA == 'M') {
                form.IZPDV = getPorezUIznosu(form.PDV, form.KOL, form.MALCEN)
                setForm(prev => ({ ...prev, IZPDV: Number(form.IZPDV) }))
                console.log(form.IZNMALD, form.IZND, form.IZPDV, form.IZNOSRAB, 'form.IZNMALD, form.IZND, form.IZPDV, form.IZNOSRAB', 'predracun')
                form.IZNRAZD = getIznosMarzeMPredrac(form.IZNMALD, form.IZND, form.IZPDV, form.IZNOSRAB)
                setForm(prev => ({ ...prev, IZNRAZD: Number(form.IZNRAZD) }))

                //uslov: ako je iznos marze manji od 0, polje ce da pocrveni
                // if (form.IZNRAZD < 0) {
                //     setErrorInputMarza(true)
                // } else {
                //     setErrorInputMarza(false)
                // }
                form.RAZCEN = getProcenatMarzeM(form.IZNRAZD, form.IZND)
                setForm(prev => ({ ...prev, RAZCEN: Number((form.RAZCEN)).toFixed(3) }))

                //uslov: ako je vrsta skladista veleprodaja
            } else {
                form.IZPDV = getPorezIzIznosa(form.PDV, form.MALCENP, form.KOL)
                setForm(prev => ({ ...prev, IZPDV: Number(form.IZPDV) }))

                form.IZNRAZD = getIznosMarzeV(form.IZNMALD, form.IZND, form.IZNOSRAB)
                setForm(prev => ({ ...prev, IZNRAZD: Number(form.IZNRAZD) }))
                //uslov: ako je iznos marze manji od 0, polje ce da pocrveni
                // if (form.IZNRAZD < 0) {

                //     setErrorInputMarza(true)
                // } else {
                //     setErrorInputMarza(false)
                // }
                form.RAZCEN = getProcenatMarzeV(form.IZND, form.IZNRAZD)
                setForm(prev => ({ ...prev, RAZCEN: Number(form.RAZCEN) }))
            }
        }

        // kontrolise sva polja do polja IZNMALDP
        if (name === 'IZNMALDP') {
            return true
        }

        if (form.IZNRAZD < 0) {

            setErrorInputMarza(true)
        } else {
            setErrorInputMarza(false)
        }

        // const MALCENP = getProdajnaCijena(form.KOL, form.IZNMALD, form.IZNOSRAB)
        // setForm(prev => ({ ...prev, MALCENP: Number(MALCENP) }))
        return true
    }

    // funkcija za stampu
    const onPrint = () => {
        setSpinner(true)
        //  uslov: ukoliko polja skladiste, vrsta i dokument nisu popunjeni, zaustavlja spinner i vraca poruku
        if (form.SKLAID === "" || form.VRPROM === "" || form.DOK === "" || form.DOK === firstDockumentNumber || !predracun) {
            setSpinner(false)
            setModalErrorOpen(true)
        }
        else {
            //u suprotnom prolazi na stampu
            fetch(API + '/predracun/print', {
                method: 'POST',
                body: JSON.stringify(form),
                responseType: 'blob',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-type': 'application/json; charset=UTF-8'
                },
            })
                .then((response) => {

                    //uslov: ukoliko ima podataka, generise izvjestaj
                    if (response.ok) {
                        return response.blob().then(blob => {
                            let blobURL = URL.createObjectURL(blob);

                            let iframe = document.createElement('iframe'); //load content in an iframe to print later
                            document.body.appendChild(iframe);
                            iframe.style.display = 'none';
                            iframe.src = blobURL;
                            iframe.onload = function () {
                                setTimeout(function () {
                                    iframe.focus();
                                    iframe.contentWindow.print();
                                }, 1);
                            };
                            setSpinner(false)
                        });
                    }
                })
        }
    }

    // funkcija za polja u mrezi
    const columns = [
        {
            name: 'Rbr.',
            selector: row => row.RBR,
            width: '70px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.RBR}</span>
                </>
            ),
        },
        {
            name: 'Šifra',
            width: '100px',
            selector: row => row.Sifra,
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.Sifra}</span>
                </>
            ),
        },
        {
            name: 'Artikal',
            width: '420px',
            selector: row => row.Artikal,
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.Artikal}</span>
                </>
            ),
        },
        {
            name: 'JM',
            width: '70px',
            selector: row => row.JM,
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.JM}</span>
                </>
            ),
        },
        {
            name: 'Količina ',
            selector: row => row.KOL,
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{numberWithCommasColumns(row.KOL)}</span>
                </>
            ),
        },
        {
            name: 'Nom. Cijena ',
            selector: row => row.MALCEN,
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{numberWithCommasColumns(row.MALCEN)}</span>
                </>
            ),
        },
        {
            name: 'Rabat % ',
            selector: row => row.PROCRABAT,
            width: '150px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{numberWithCommasColumns(row.PROCRABAT)}</span>
                </>
            ),
        },
        {
            name: 'Rabat',
            selector: row => row.Rabat,
            right: 'boolean',
            width: '150px',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{numberWithCommasColumns(row.Rabat)}</span>
                </>
            ),
        },
        {
            name: 'Prod. Cijena',
            selector: row => row.Prod_Cijena,
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{numberWithCommas(row.Prod_Cijena)}</span>
                </>
            ),
        },
        {
            name: 'Prod. Iznos',
            selector: row => row.Prod_Iznos,
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{numberWithCommasColumns(row.Prod_Iznos)}</span>
                </>
            ),
        },
    ];

    const handlePageChange = page => {
        getPredracunData(page, perPage)
    }

    const handlePerRowsChange = async (newPerPage, page) => {
        if (perPage != newPerPage) {
            setPerPage(newPerPage)
            getPredracunData(1, newPerPage)
            repositoryRef.current.focus()
        }
    }

    // render
    return (

        <div>

            {/* kontejner za naslov i ikonice */}
            <div className="title-container">

                <header className="title-header">
                    <h1 id="media-query-title">Predračun</h1>
                </header>

                <div className="naslov-ikonice">
                    <ul className="nav flex-column flex-lg-row justify-content-end">

                        <li className="nav-item toolbaritem">
                            <input type="image" alt="save" className='dugme-sacuvaj' title='Sačuvaj' tabIndex="19" src={require('./../../assets/images/floppydisk.png')} onFocus={() => onSubmit()} onClick={() => onSubmit()} />
                        </li>

                        <li className="nav-item toolbaritem">
                            <input type="image" alt="print" className='dugme-stampa' title='Štampa' tabIndex="20" src={require('./../../assets/images/printer.png')} onClick={onPrint} />
                        </li>

                        <li className="nav-item toolbaritem">
                            <div onClick={form.DOK && form.DOK !== '' && form.DOK !== 0 ? setModalOpen : setModalErrorDeleteDocumentOpen} tabIndex="22">
                                <SVG src={deleteDocument} style={{ margin: '1px' }} title='Brisanje dokumenta' className='dugme-brisanje' />

                            </div>
                        </li>

                        <li className="nav-item toolbaritem">
                            <input type="image" alt="delete item" className='dugme-stampa' title='Brisanje stavke' tabIndex="21" src={require('./../../assets/images/trash1.png')} onClick={itemUpdate ? setModalDeleteItem : setModalErrorDeleteItemOpen} />
                        </li>
                    </ul>
                </div>
            </div>

            {/* kontejner pune sirine */}
            <Container fluid>
                <Row>
                    <div className='products-form'>
                        <Row>
                            {/* Stilovi koji ce se primjenjivati na svim poljima */}
                            <div className="col-5" style={{ display: 'flex', paddingRight: '0px', paddingLeft: '-1px', marginRight: 'auto', marginLeft: '0px' }}>
                                <div className="col-11">
                                    <div className={errorInputRepository ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px', marginBottom: '0px' }} >
                                        <label id="media-query-label">Skladište:</label>
                                        {/* autokomplit za skladiste */}
                                        <Autocomplete
                                            //ukoliko nema ove funkcije, u konzoli izbacuje upozorenje da nije vrijednost jedinstvena
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            {...defaultProps}
                                            value={repository}
                                            disablePortal={true}
                                            onFocus={() => {
                                                //   setRepositoryFocus(true)
                                                unosIspravanDo("SKLAID")
                                            }}
                                            // ako se promijeni skladiste, uzima podatke za novo izabrano skladiste
                                            onChange={(event, newValue) => {
                                                setForm(prev => ({ ...prev, SKLAID: newValue && newValue != null ? newValue.ID : '', VRSTA_SKLADISTA: newValue && newValue != null ? newValue.VRSTA_SKLADISTA : '', }))
                                                setRepository(newValue);
                                                // fokus se mora vratiti inace tabIndex ne radi dobro
                                                repositoryRef.current.focus()
                                            }}

                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    autoFocus
                                                    className="clients-textinput"
                                                    maxLength="295"
                                                    name='SKLAID'
                                                    tabIndex="1"
                                                    ref={repositoryRef}
                                                    //   onBlur={(e) => formCheck(e)}
                                                    placeholder=' Izaberite skladište'
                                                    variant="standard" />
                                            )}
                                        />
                                        {errorMsgRepository ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgRepository}</span> : ''}

                                    </div>
                                </div>
                                <div className="col-1" style={{ display: 'flex', paddingRight: '3px' }}>
                                    <div className='field-container'  >
                                        <label></label>
                                        <input className="form-controlvr" readOnly={true} maxLength="30" type='text' id="media-query-input-text" tabIndex="-1" value={form.VRSTA_SKLADISTA} />
                                    </div>
                                </div>
                            </div>

                            <div className="col-4" style={{ display: 'flex', paddingRight: '0px', paddingLeft: '0px', marginRight: 'auto', marginLeft: '0px' }}>

                                {/* Stilovi koji ce se primjenjivati na svim poljima */}
                                <div className="col-12">
                                    <div className={errorInputVrsta ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px', marginBottom: '0px' }} >
                                        <label id="media-query-label">Vrsta:</label>

                                        {/* autokomplit za vrstu */}
                                        <Autocomplete
                                            //ukoliko nema ove funkcije, u konzoli izbacuje upozorenje da nije vrijednost jedinstvena
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            {...defaultPropsVrsta}
                                            value={vrsta}
                                            disablePortal={true}
                                            onFocus={() => {
                                                //  setVrstaFocus(true)
                                                unosIspravanDo("VRPROM")
                                                // getVrstaName();
                                            }}
                                            // ako se promijeni vrsta, uzima podatke za novu izabranu vrstu
                                            onChange={(event, newValue) => {
                                                setForm(prev => ({ ...prev, VRPROM: newValue && newValue != null ? newValue.VRSTA : '', NAZIV: newValue && newValue != null ? newValue.NAZIV : '' }))
                                                setVrsta(newValue);
                                                // fokus se mora vratiti inace tabIndex ne radi dobro
                                                vrstaRef.current.focus()
                                            }}

                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    tabIndex="2"
                                                    maxLength="2"
                                                    className="clients-textinput"
                                                    name='VRPROM'
                                                    ref={vrstaRef}
                                                    //   onBlur={(e) => formCheck(e)}
                                                    placeholder=''
                                                    variant="standard" />
                                            )}
                                        />
                                        {errorMsgVrsta ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgVrsta}</span> : ''}

                                    </div>
                                </div>

                                {/* <div className="col-10" >
                                    <label></label>
                                    <input className="form-controlvr" maxLength="228" readOnly={true} type='text' id="media-query-input-text" tabIndex="-1" value={form.NAZIV} />

                                </div> */}
                            </div>

                            <div className="col-3" style={{ display: 'flex', paddingRight: '0px', paddingLeft: '0px', marginRight: 'auto', marginLeft: '0px' }}>

                                <div className="col-4" style={{ marginLeft: '3px', marginRight: '-3px' }}>
                                    <div className='col-form-right'>
                                        <div className={errorInputDocument ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px' }} >
                                            <label id="media-query-label">Dok.:</label>
                                            {/* komponenta za formatiranje brojeva */}
                                            <input
                                                type="text"
                                                ref={documentRef}
                                                className='field-alignment p-1'
                                                id="media-query-input-text"
                                                maxLength="9"
                                                tabIndex="3"
                                                // broj decimala
                                                //    decimalScale={0}
                                                onFocus={() => {
                                                    // setDocumentFocus(true)
                                                    unosIspravanDo("DOK")
                                                    getPredracunFirstFreeNumber()
                                                }}
                                                placeholder="0"
                                                name='DOK'
                                                value={form?.DOK}
                                                onChange={(e) => handleChange(e)}
                                                onClick={() => {
                                                    // ako je odradjen dvoklik na stavku u mrezi, a zatim klik na dokument, cisti polja         
                                                    setUpdateDocument(false)
                                                    setItemUpdate(false)
                                                    setForm(prev => ({
                                                        ...prev, LAGER: '', PDV: '', RACUN: '',
                                                        IZPDV: '', RAZCEN: '', IZNRAZD: '', NOMENID: '', JM: '', KOL: '', RABVEL: '', IZNOSRAB: '', NABCEN: '', IZND: '', MALCEN: '', MALCENP: '', IZNMALD: '', IZNMALDP: '', PREDRACID: 0,
                                                    }))
                                                    setArtikal(null)
                                                }} />
                                            {errorMsgDocument ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgDocument}</span> : ''}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div className='col-form-right'>
                                        <div className="field-container-number" style={{ marginRight: '3px' }} >

                                            <label id="media-query-label">Porez% :</label>
                                            <NumericFormat className="form-control" readOnly={true} tabIndex="-1" type='text' id="media-query-input-text" placeholder="" name='PDV' value={form ? numberWithCommasColumns(form.PDV) : ''} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div className='col-form-right'>
                                        <div className="field-container-number" style={{ marginRight: '3px' }} >

                                            <label id="media-query-label">Porez :</label>
                                            <input className="form-control field-alignment"
                                                readOnly={true}
                                                tabIndex="-1"
                                                type='text'
                                                id="media-query-input-text"
                                                placeholder=""
                                                name='IZPDV'
                                                value={form && itemUpdate ? numberWithCommasColumns3(form.IZPDV) : form ? numberWithCommasColumns3(form.IZPDV) : ''} />
                                        </div>
                                    </div>
                                </div>

                            </div>


                        </Row >

                        <Row>

                            {/* Sirina polja */}
                            <div className="col-12" style={{ display: 'flex', paddingRight: '0px', paddingLeft: '-1px', marginRight: 'auto', marginLeft: '0px' }}>

                                <div className="col-1">
                                    <div className={errorDate ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px' }} >
                                        <label id="media-query-label">Dat. fakt.:</label>
                                        <input
                                            type="text"
                                            id="media-query-input-text"
                                            tabIndex="4"
                                            ref={dateRef}
                                            onFocus={() => {
                                                setDateFocus(true)
                                                unosIspravanDo("DPO")
                                                // if (form.DOK != firstDockumentNumber) {
                                                //     checkDocument()
                                                // } else {
                                                //     setUpdateDocument(false)
                                                //     setErrorInputMarza(false)
                                                //     setErrorInputMarzaSum(false)
                                                //     setClients(null);
                                                //     setPredracun(null)
                                                //     setForm(prev => ({ ...prev, DPO: moment.unix(new Date(startDate).getTime() / 1000).format("DD.MM.YYYY"), DPOR: moment.unix(new Date(endDate).getTime() / 1000).format("DD.MM.YYYY"), VALUTA: moment.unix(new Date(currencyDate).getTime() / 1000).format("DD.MM.YYYY") }))
                                                // }
                                                // console.log(form.DOK == firstDockumentNumber || form.DOK > firstDockumentNumber, 'form.DOK == firstDockumentNumber || form.DOK > firstDockumentNumber')
                                                // if (form.DOK == firstDockumentNumber || form.DOK > firstDockumentNumber) {
                                                // setUpdateDocument(false)
                                                // setErrorInputMarza(false)
                                                // setErrorInputMarzaSum(false)
                                                // setClients(null);
                                                // setPredracun(null)
                                                // setForm(prev => ({ ...prev, DPO: moment.unix(new Date(startDate).getTime() / 1000).format("DD.MM.YYYY"), DPOR: moment.unix(new Date(endDate).getTime() / 1000).format("DD.MM.YYYY"), VALUTA: moment.unix(new Date(currencyDate).getTime() / 1000).format("DD.MM.YYYY") }))
                                                //   } else {
                                                checkDocument()
                                                //  }
                                            }}
                                            placeholder=""
                                            name='DPO'
                                            value={form?.DPO}
                                            onChange={(e) => handleChange(e)}
                                        //    onBlur={(e) => formCheck(e)}
                                        />
                                        {errorMsgDate ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgDate}</span> : ''}
                                    </div>
                                </div>


                                <div className="col-1">
                                    <div className={errorDateDelivery ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px' }} >
                                        <label id="media-query-label">Dat. ispor.:</label>
                                        <input
                                            type="text"
                                            id="media-query-input-text"
                                            tabIndex="5"
                                            ref={dateDeliveryRef}
                                            onFocus={() => {
                                                setDateDeliveryFocus(true)
                                                unosIspravanDo("DPOR")
                                            }}
                                            placeholder=""
                                            name='DPOR'
                                            value={form?.DPOR}
                                            onChange={(e) => handleChange(e)}
                                        //    onBlur={(e) => formCheck(e)} 
                                        />
                                        {errorMsgDateDelivery ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgDateDelivery}</span> : ''}
                                    </div>
                                </div>

                                {/* Sirina polja */}
                                <div className="col-1">
                                    <div className={errorCurrency ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px' }}>
                                        {/* format datum i poziv DatePicker preko kojeg cemo odabrati potreban datum */}
                                        <label id="media-query-label">Valuta:</label>
                                        <input
                                            type="text"
                                            id="media-query-input-text"
                                            tabIndex="6"
                                            ref={currencyRef}
                                            onFocus={() => {
                                                setCurrencyFocus(true)
                                                unosIspravanDo("VALUTA")
                                            }}
                                            placeholder=""
                                            name='VALUTA'
                                            value={form?.VALUTA}
                                            onChange={(e) => handleChange(e)}
                                        //    onBlur={(e) => formCheck(e)} 
                                        />
                                        {errorMsgCurrency ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgCurrency}</span> : ''}
                                    </div>
                                </div>

                                {/* sirina polja */}
                                <div className="col-2">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label id="media-query-label">Račun:</label>
                                        <input type="text"
                                            id="media-query-input-text"
                                            ref={racunRef}
                                            onFocus={() => {
                                                unosIspravanDo("RACUN")
                                            }}
                                            maxLength="30"
                                            tabIndex="7"
                                            placeholder="Unesite racun "
                                            name='RACUN'
                                            value={form ? form.RACUN : ''}
                                            onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>

                                {/* sirina polja */}
                                <div className="col-4">
                                    <div className={errorInputClients ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px' }} >
                                        <label id="media-query-label">Komitent:</label>
                                        {/* autokomplit za komitenta */}
                                        <Autocomplete
                                            //ukoliko nema ove funkcije, u konzoli izbacuje upozorenje da nije vrijednost jedinstvena
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            {...defaultPropsClients}
                                            value={clients}
                                            disablePortal={true}
                                            onFocus={() => {
                                                setClientsFocus(true)
                                                unosIspravanDo("KOMTID")
                                            }}
                                            // ako se promijeni komitent, uzima podatke za novog izabranog komitenta
                                            onChange={(event, newValue) => {
                                                setForm(prev => ({ ...prev, KOMTID: newValue && newValue != null ? newValue.ID : '' }))
                                                setClients(newValue);
                                                // fokus se mora vratiti inace tabIndex ne radi dobro
                                                clientsRef.current.focus()
                                            }}

                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    tabIndex="8"
                                                    maxLength="283"
                                                    className="clients-textinput"
                                                    name='KOMTID'
                                                    ref={clientsRef}
                                                    //    onBlur={(e) => formCheck(e)}
                                                    placeholder=' Izaberite komitenta'
                                                    variant="standard"
                                                />
                                            )}
                                        />
                                        {errorMsgClients ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgClients}</span> : ''}
                                    </div>
                                </div>
                                <div className="col-1" style={{ marginLeft: '3px', marginRight: '-3px' }}>
                                    <div className='col-form-right'>
                                        <div className='field-container' style={{ marginRight: '3px' }} >
                                            <label id="media-query-label">Lager:</label>
                                            <input className="field-alignment p-1"
                                                readOnly={true}
                                                type='text'
                                                tabIndex="-1"
                                                id="media-query-input-text"
                                                value={form && itemUpdate ? numberWithCommasColumns(form.LAGER) : form ? numberWithCommasColumns(form.LAGER) : ''} />
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="col-1" style={{ marginLeft: '-10px', marginRight: '7px' }}>
                                    <div className='col-form-right'>
                                        <div className='field-container-number' style={{ marginRight: '3px' }} >
                                            <label id="media-query-label">Lager:</label>
                                            <input className="total field-alignment"
                                                readOnly={true}
                                                type='text'
                                                tabIndex="-1"
                                                id="media-query-input-text"
                                                value={form && itemUpdate ? numberWithCommasColumns(form.LAGER) : form ? form.LAGER : ''} />
                                        </div>
                                    </div>
                                </div> */}

                                <div className="col-1">
                                    <div className='col-form-right'>
                                        <div className="field-container-number" style={{ marginRight: '3px' }} >

                                            <label id="media-query-label">Marža% :</label>
                                            <NumericFormat className="form-control field-alignment"
                                                readOnly={true}
                                                type='text'
                                                id="media-query-input-text"
                                                tabIndex="-1"
                                                placeholder=""
                                                name='RAZCEN'
                                                value={form && itemUpdate ? numberWithCommasColumns3(form.RAZCEN) : form ? numberWithCommasColumns3(form.RAZCEN) : ''} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-1">
                                    <div className='col-form-right'>
                                        <div className='field-container-number' style={{ marginRight: '3px' }} >

                                            <label id="media-query-label">Marža:</label>
                                            <input className={errorInputMarza ? 'less-then-0' : 'form-control field-alignment'}
                                                readOnly={true}
                                                type='text'
                                                id="media-query-input-text"
                                                tabIndex="-1"
                                                placeholder=""
                                                name='IZNRAZD'
                                                value={form && itemUpdate ? numberWithCommasColumns(form.IZNRAZD) : form ? form.IZNRAZD : ''} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </div >
                </Row >

                <Row>
                    <div className="col-6" style={{ display: 'flex', paddingRight: '0px', paddingLeft: '-1px', marginRight: 'auto', marginLeft: '0px' }}>
                        <div className="col-1">
                            <div className='col-form-right'>
                                <div className={errorInputRbr ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px' }} >
                                    <label id="media-query-label">Rbr.</label>
                                    {/* komponenta za formatiranje brojeva */}
                                    <input
                                        type="text"
                                        ref={rbrRef}
                                        className='field-alignment p-1'
                                        id="media-query-input-text"
                                        maxLength="3"
                                        // broj decimala
                                        //   decimalScale={0}
                                        tabIndex="9"
                                        placeholder="0"
                                        name='RBR'
                                        value={form ? form.RBR : ''}
                                        onFocus={() => {
                                            setRbrFocus(true)
                                            unosIspravanDo("RBR")
                                            getPredracunFirstFreeNumberRbr()
                                        }}
                                        onChange={(e) => {
                                            handleChange(e)
                                        }}
                                        onClick={() => {
                                            // ako je odradjen dvoklik na stavku u mrezi, cisti polja  
                                            setItemUpdate(false)
                                            setForm(prev => ({
                                                ...prev, LAGER: '', PDV: '',
                                                IZPDV: '', RAZCEN: '', IZNRAZD: '', NOMENID: '', JM: '', KOL: '', RABVEL: '', IZNOSRAB: '', NABCEN: '', IZND: '', MALCEN: '', MALCENP: '', IZNMALD: '', IZNMALDP: ''
                                            }))
                                            setArtikal(null)
                                            setErrorInputMarza(false)
                                        }}
                                    />
                                    {errorMsgRbr ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgRbr} </span> : ''}
                                </div>
                            </div>
                        </div>

                        <div className="col-7">
                            <div className={errorInputNomen ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px' }} >
                                <label id="media-query-label">Artikal</label>
                                <Autocomplete
                                    // svaki od ponudjenih artikala mora biti jedinstven(svaka stavka(child) u objektu artikal mora imati jedinstven kljuc)
                                    renderOption={(props, artikal) => {
                                        return (
                                            <li {...props} key={artikal.ID}>
                                                <span>{artikal?.SIFRA}</span>
                                                <span className="ps-1">{artikal?.NAZIV}</span>
                                            </li>
                                        );
                                    }}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    filterOptions={filterOptions}
                                    options={artikal}
                                    getOptionLabel={(option) => option.title}
                                    {...defaultPropsNomen}
                                    value={artikal}
                                    disablePortal={true}
                                    disabled={itemUpdate}
                                    onFocus={() => {
                                        setNomenFocus(true)
                                        unosIspravanDo("NOMENID")

                                    }}
                                    // ako se promijeni artikal, uzima podatke za novi izabrani artikal
                                    onChange={(event, newValue) => {
                                        setForm(prev => ({
                                            ...prev, NOMENID: newValue && newValue != null ? newValue.ID : '', POREZID: newValue && newValue != null ? newValue.POREZID : '',
                                            JM: newValue && newValue != null ? newValue.JM : ''
                                        }))
                                        setArtikal(newValue)
                                        // fokus se mora vratiti inace tabIndex ne radi dobro
                                        nomenRef.current.focus();
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            id='media-query'
                                            {...params}
                                            maxLength="283"
                                            className={itemUpdate ? "form-controlst" : ""}
                                            name='NOMENID'
                                            ref={nomenRef}
                                            tabIndex="10"
                                            readOnly={itemUpdate}
                                            placeholder=' artikal'
                                            variant="standard" />
                                    )}
                                />
                                {errorMsgNomen ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgNomen}</span> : ''}
                            </div>
                        </div>

                        <div className="col-1">
                            <div className={errorInputJM ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px' }} >
                                <label id="media-query-label">Jm :</label>
                                <input className="form-controlst"
                                    readOnly={true}
                                    type='text'
                                    id="media-query-input-text"
                                    ref={jmRef}
                                    placeholder=""
                                    name='JM'
                                    value={form ? form.JM : ''}
                                    onChange={(e) => handleChange(e)}
                                //   onBlur={(e) => formCheck(e)} 
                                />
                                {errorMsgJM ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgJM}</span> : ''}

                            </div>

                        </div>

                        <div className="col-3">
                            <div className='col-form-right'>
                                <div className={errorInputQuantity ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px' }} >
                                    <label id="media-query-label">Količina:</label>
                                    {/* komponenta za formatiranje brojeva */}
                                    <NumericFormat
                                        type="text"
                                        customInput={itemUpdate?.TextField}
                                        className='field-alignment p-1'
                                        getInputRef={quantityRef}
                                        id="media-query-input-text"
                                        maxLength="13"
                                        // broj decimala
                                        decimalScale={3}
                                        onFocus={() => {
                                            setQuantityFocus(true)
                                            unosIspravanDo("KOL")
                                            getCjenovnikData()
                                            getLagerData()
                                            getTaxData()
                                        }}
                                        tabIndex="11"
                                        placeholder="0"
                                        name='KOL'
                                        value={form && itemUpdate ? numberWithCommasColumns(form.KOL) : form.KOL}
                                        onChange={(e) => {
                                            handleChange(e)
                                        }}
                                        readOnly={itemUpdate} />
                                    {errorMsgQuantity ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgQuantity}</span> : ''}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-6" style={{ display: 'flex', paddingRight: '0px', paddingLeft: '0px', marginRight: 'auto', marginLeft: '0px' }}>

                        <div className="col-2">
                            <div className='col-form-right'>
                                <div className={errorInputPrice ? 'inputWithError' : 'field-container-number'} style={{ marginRight: '3px' }} >
                                    <label id="media-query-label">N. Cijena :</label>
                                    {/* komponenta za formatiranje brojeva */}
                                    <NumericFormat
                                        type="text"
                                        customInput={itemUpdate?.TextField}
                                        getInputRef={malcenRef}
                                        className='field-alignment p-1'
                                        id="media-query-input-text"
                                        readOnly={itemUpdate}
                                        maxLength="15"
                                        // broj decimala
                                        decimalScale={4}
                                        tabIndex="14"
                                        placeholder="0"
                                        name='MALCEN'
                                        value={form && itemUpdate ? numberWithCommasColumns3(form.MALCEN) : form.MALCEN}
                                        onFocus={() => {
                                            setPriceFocus(true)
                                            unosIspravanDo("MALCEN")
                                        }}
                                        onChange={(e) => handleChange(e)}
                                    //    onBlur={(e) => formCheck(e)} 
                                    />
                                    {errorMsgPrice ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgPrice}</span> : ''}

                                </div>
                            </div>
                        </div>

                        <div className="col-2">
                            <div className='col-form-right'>
                                <div className='field-container' style={{ marginRight: '3px' }}>
                                    <label id="media-query-label">Rabat%:</label>
                                    {/* komponenta za formatiranje brojeva */}
                                    <NumericFormat
                                        type="number"
                                        customInput={itemUpdate?.TextField}
                                        getInputRef={rabvelRef}
                                        className='field-alignment'
                                        id="media-query-input-text"
                                        readOnly={itemUpdate}
                                        onFocus={() => {
                                            unosIspravanDo("RABVEL")
                                        }}
                                        maxLength="13"
                                        // broj decimala
                                        decimalScale={2}
                                        tabIndex="15"
                                        placeholder="0"
                                        name='RABVEL'
                                        value={form && itemUpdate ? numberWithCommasColumns(form.RABVEL) : form.RABVEL}
                                        onChange={(e) => handleChange(e)}
                                    //   onBlur={(e) => formCheck(e)} 
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-2">
                            <div className='col-form-right'>
                                <div className='field-container' style={{ marginRight: '3px' }}>
                                    <label id="media-query-label">Rabat:</label>
                                    {/* komponenta za formatiranje brojeva */}
                                    <NumericFormat
                                        type="number"
                                        customInput={itemUpdate?.TextField}
                                        getInputRef={rabatRef}
                                        className='field-alignment'
                                        id="media-query-input-text"
                                        readOnly={itemUpdate}
                                        onFocus={() => {
                                            unosIspravanDo("IZNOSRAB")
                                        }}
                                        maxLength="11"
                                        // broj decimala
                                        decimalScale={2}
                                        tabIndex="16"
                                        placeholder="0"
                                        name='IZNOSRAB'
                                        value={form && itemUpdate ? numberWithCommasColumns(form.IZNOSRAB) : form.IZNOSRAB}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-3">
                            <div className='col-form-right'>
                                <div className={errorInputMalcenP ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px' }} >
                                    <label id="media-query-label">P. Cijena :</label>
                                    {/* komponenta za formatiranje brojeva */}
                                    <NumericFormat
                                        type="number"
                                        customInput={itemUpdate?.TextField}
                                        getInputRef={malcenPRef}
                                        className="form-control field-alignment"
                                        readOnly={itemUpdate}
                                        id="media-query-input-text"
                                        maxLength="15"
                                        // broj decimala
                                        decimalScale={4}
                                        tabIndex="17"
                                        placeholder="0"
                                        name='MALCENP'
                                        value={form && itemUpdate ? numberWithCommasColumns3(form.MALCENP) : form ? form.MALCENP : ''}
                                        onFocus={() => {
                                            setMalcenPFocus(true)
                                            unosIspravanDo("MALCENP")
                                        }}
                                        onChange={(e) => handleChange(e)}
                                    //   onBlur={(e) => formCheck(e)} 
                                    />
                                    {errorMsgMalcenP ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgMalcenP}</span> : ''}
                                </div>
                            </div>
                        </div>

                        <div className="col-3">
                            <div className='col-form-right'>
                                <div className='field-container' style={{ marginRight: '3px' }}>
                                    <label id="media-query-label">P. Iznos :</label>
                                    <NumericFormat className="form-control field-alignment"
                                        customInput={itemUpdate?.TextField}
                                        readOnly={true}
                                        type='text'
                                        id="media-query-input-text"
                                        tabIndex="18"
                                        placeholder=""
                                        name='IZNMALD'
                                        onFocus={() => {
                                            unosIspravanDo("IZNMALD")
                                        }}
                                        value={form && itemUpdate ? numberWithCommasColumns(form.IZNMALDP) : form ? form.IZNMALDP : ''}
                                        onChange={(e) => handleChange(e)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>

            </Container >

            {/* render za mrezu */}
            {
                < DataTable
                    columns={columns}
                    data={predracun ? predracun : []}
                    responsive
                    striped
                    dense
                    noDataComponent
                    pointerOnHover
                    persistTableHead
                    pagination
                    paginationServer
                    paginationTotalRows={totalstrana}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handlePerRowsChange}
                    highlightOnHover
                    paginationComponentOptions={defaultComponentOptions}
                    onRowDoubleClicked={(row, event) => {
                        onEdit(row)
                    }
                    }
                />
            }

            {/* kontejner za sume
            <div className="title-container">
                <footer className="title-header">
                    <div></div>
                </footer>
                <div className="naslov-ikonice">
                    <ul className="nav flex-column flex-lg-row justify-content-end">
                        <div style={{ display: 'flex', justifyContent: 'end', position: 'fixed', bottom: '0', flex: '0 0 auto' }}>
                            <li className="nav-item toolbaritem" style={{ width: '10%', marginRight: '5px' }}>
                                <div className='col-form-right'>
                                    <div className="field-container-number">
                                        <label id="media-query-label-sum">Iznos</label>
                                        <input className="form-control" readOnly={true} tabIndex="-1" type='text' id="media-query-input-text" value={sumNomenIznos && sumNomenIznos != 'NaN' ? numberWithCommas(sumNomenIznos) : 0} />
                                    </div>
                                </div>
                            </li>

                            <li className="nav-item toolbaritem" style={{ width: '10%', marginRight: '4px' }}>
                                <div className='col-form-right'>
                                    <div className="field-container-number">
                                        <label id="media-query-label-sum">Iznos Rabata  </label>
                                        <input className="form-control" readOnly={true} type='text' tabIndex="-1" id="media-query-input-text" value={sumIznosRabata && sumIznosRabata != 'NaN' ? numberWithCommas(sumIznosRabata) : 0} />
                                    </div>
                                </div>
                            </li>

                            <li className="nav-item toolbaritem" style={{ width: '10%', marginRight: '7px' }}>
                                <div className='col-form-right'>
                                    <div className="field-container-number">
                                        <label id="media-query-label-sum">Osnovica</label>
                                        <input type='text' className="form-control" readOnly={true} tabIndex="-1" id="media-query-input-text" value={sumOsnovica && sumOsnovica != 'NaN' ? numberWithCommas(sumOsnovica) : 0} />
                                    </div>
                                </div>
                            </li>
                            <li className="nav-item toolbaritem" style={{ width: '10%', marginRight: '5px' }}>
                                <div className='col-form-right'>
                                    <div className="field-container-number">
                                        <label id="media-query-label-sum">Marža</label>
                                        <input className={errorInputMarzaSum ? 'less-then-0' : 'form-control'} readOnly={true} type='text' tabIndex="-1" id="media-query-input-text" value={sumIznosMarze && sumIznosMarze != 'NaN' ? numberWithCommas(sumIznosMarze) : 0} />
                                    </div>
                                </div>
                            </li>

                            <li className="nav-item toolbaritem" style={{ width: '10%', marginRight: '4px' }}>
                                <div className='col-form-right'>
                                    <div className="field-container-number">
                                        <label id="media-query-label-sum">Porez</label>
                                        <input className="form-control" readOnly={true} type='text' id="media-query-input-text" tabIndex="-1" value={sumIznosPoreza && sumIznosPoreza != 'NaN' ? numberWithCommas(sumIznosPoreza) : 0} />
                                    </div>
                                </div>
                            </li>

                            <li className="nav-item toolbaritem" style={{ width: '10%', marginRight: '7px' }}>
                                <div className='col-form-right'>
                                    <div className="field-container-number">
                                        <label id="media-query-label-sum">Iznos</label>
                                        <input type='text' className="total" readOnly={true} id="media-query-input-text" tabIndex="-1" value={sumProdajniIznos && sumProdajniIznos != 'NaN' ? numberWithCommas(sumProdajniIznos) : 0} />
                                    </div>
                                </div>
                            </li>
                        </div>
                    </ul>
                </div>

            </div> */}

            <div className="naslov-ikonice">
                <ul className="nav flex-column flex-lg-row justify-content-end">
                    <div style={{ display: 'flex', justifyContent: 'end', position: 'fixed', bottom: '0', flex: '0 0 auto' }}>

                        <li className="nav-item toolbaritem" style={{ width: '10%', marginRight: '4px' }}>
                            <div className='col-form-right'>
                                <div className="field-container-number">
                                    <label id="media-query-label-sum">Iznos</label>
                                    <input className="form-control" readOnly={true} type='text' tabIndex="-1" id="media-query-input-text" value={sumNomenIznos && sumNomenIznos != 'NaN' ? numberWithCommas(sumNomenIznos) : 0} />
                                </div>
                            </div>
                        </li>

                        <li className="nav-item toolbaritem" style={{ width: '10%', marginRight: '7px' }}>
                            <div className='col-form-right'>
                                <div className="field-container-number">
                                    <label id="media-query-label-sum">Iznos Rabata </label>
                                    <input type='text' className="form-control" readOnly={true} tabIndex="-1" id="media-query-input-text" value={sumIznosRabata && sumIznosRabata != 'NaN' ? numberWithCommas(sumIznosRabata) : 0} />
                                </div>
                            </div>
                        </li>

                        <li className="nav-item toolbaritem" style={{ width: '10%', marginRight: '5px' }}>
                            <div className='col-form-right'>
                                <div className="field-container-number">
                                    <label id="media-query-label-sum">Osnovica</label>
                                    <input className="total" readOnly={true} type='text' tabIndex="-1" id="media-query-input-text" value={sumOsnovica && sumOsnovica != 'NaN' ? numberWithCommas(sumOsnovica) : 0} />
                                </div>
                            </div>
                        </li>

                        <li className="nav-item toolbaritem" style={{ width: '10%', marginRight: '5px' }}>
                            <div className='col-form-right'>
                                <div className="field-container-number">
                                    <label id="media-query-label-sum">Marža</label>
                                    <input className="total" readOnly={true} type='text' tabIndex="-1" id="media-query-input-text" value={sumIznosMarze && sumIznosMarze != 'NaN' ? numberWithCommas(sumIznosMarze) : 0} />
                                </div>
                            </div>
                        </li>

                        <li className="nav-item toolbaritem" style={{ width: '10%', marginRight: '5px' }}>
                            <div className='col-form-right'>
                                <div className="field-container-number">
                                    <label id="media-query-label-sum">Porez</label>
                                    <input className="total" readOnly={true} type='text' tabIndex="-1" id="media-query-input-text" value={sumIznosPoreza && sumIznosPoreza != 'NaN' ? numberWithCommas(sumIznosPoreza) : 0} />
                                </div>
                            </div>
                        </li>

                        <li className="nav-item toolbaritem" style={{ width: '10%', marginRight: '5px' }}>
                            <div className='col-form-right'>
                                <div className="field-container-number">
                                    <label id="media-query-label-sum">Iznos</label>
                                    <input className="total" readOnly={true} type='text' tabIndex="-1" id="media-query-input-text" value={sumProdajniIznos && sumProdajniIznos != 'NaN' ? numberWithCommas(sumProdajniIznos) : 0} />
                                </div>
                            </div>
                        </li>
                    </div>
                </ul>
            </div>

            {/* render za poruku */}
            <div>
                <Modal isOpen={Boolean(modalOpen)} animation="false" centered  >
                    <ModalHeader>Brisanje dokumenta</ModalHeader>
                    <ModalBody>
                        <span>{`Da li želite da obrišete dokument broj ${form.DOK} ? `}</span>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            <button onClick={() => setModalOpen(false)} className="button-no">Ne</button>
                            <button onClick={() => onDelete()} className="button-yes">Da</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>


            {/* // poruke, upozorenja */}
            <div>
                <Modal isOpen={Boolean(modalDeleteItem)} animation="false" centered  >
                    <ModalHeader>Brisanje stavke</ModalHeader>
                    <ModalBody>
                        <span>{`Da li želite da obrišete stavku broj ${form.RBR} ? `}</span>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            <button onClick={() => setModalDeleteItem(false)} className="button-no">Ne</button>
                            <button onClick={() => onDeleteItems(form.PREDRACID, form.RBR)} className="button-yes">Da</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            {/* render za poruku */}
            <div>
                <Modal isOpen={Boolean(modalErrorOpen)} centered  >
                    <ModalHeader>Neispravan unos</ModalHeader>
                    <ModalBody>
                        <span>{`Unesite obavezna polja(skladište, vrstu promjene i dokument)!`}</span>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            <button onClick={() => setModalErrorOpen(false)} className="button-no">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            {/* render za poruku */}
            <div>
                <Modal isOpen={Boolean(modalErrorLogin)} centered  >
                    <ModalHeader>Neispravan unos</ModalHeader>
                    <ModalBody>
                        <span>{`Prijavite se ponovo!`}</span>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            <button onClick={() => setModalErrorLogin(false)} className="button-no">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>


            {/* render za poruku */}
            <div>
                <Modal isOpen={Boolean(modalErrorDeleteItemOpen)} centered  >
                    <ModalHeader>Neispravan unos</ModalHeader>
                    <ModalBody>
                        <span>{`Morate odabrati stavku za brisanje!`}</span>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            <button onClick={() => setModalErrorDeleteItemOpen(false)} className="button-no">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            {/* render za poruku */}
            <div>
                <Modal isOpen={Boolean(modalErrorOpenData)} centered  >
                    <ModalHeader>Nema podataka!</ModalHeader>
                    <ModalFooter>
                        <div>
                            {/* Dugme za zatvaranje modalnog forma */}
                            <button onClick={() => setModalErrorOpenData(false)} className="button-yes">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            {/* funkcija za spiner progres bar */}
            <div>
                {spinner ? <Spinner className='spinner' color="primary" id='loader' /> : ''}
            </div>

        </div >
    );

}

export default Predracun;


