// importovanje komponenti
import React, { useEffect, useState, useRef } from "react";
import {
    Container,
    Row,
    Modal,
    ModalBody,
    ModalHeader,
    Button,
    ModalFooter,
} from "reactstrap";
import { API } from "../../constants";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import SVG from "react-inlinesvg";
import moment from "moment";
import {
    defaultPrometForm,
    defaultForm,
    numberWithCommas,
    roundTO,
} from "../utilities";
import { NumericFormat } from "react-number-format";
import dayjs from "dayjs";
import "dayjs/locale/de";
import cart from "../../assets/cart.svg";
import kusurSvg from "../../assets/kusur.svg";
import deleteDocument from "../../assets/delete_document.svg";


function Kasa() {
    // definisanje referenci, stejtova i prva inicijalizacija; objekat i funkcija za taj objekat
    const documentRef = useRef();
    const nomenRef = useRef();
    const dateRef = useRef();
    const repositoryRef = useRef();
    const smjenaRef = useRef();
    const rbrRef = useRef();
    const sifraRef = useRef();
    const kolicinaRef = useRef();
    const gotovinaRef = useRef();
    const vrstaRef = useRef();
    const saldoRef = useRef();
    const valutaRef = useRef();
    const clientsRef = useRef();

    const [showContent, setShowContent] = useState(false);
    const [prometRead, setPrometRead] = useState(false);
    const [modalLagerErrorOpen, setModalLagerErrorOpen] = useState(false);
    const [modalEditErrorOpen, setModalEditErrorOpen] = useState(false);
    const [modalErrorDeleteDocumentOpen, setModalErrorDeleteDocumentOpen] = useState(false);

    // modal Kasa
    const [modalKasaOpen, setModalKasaOpen] = useState(false);
    const [nazivSkladista, setNazivSkladista] = useState(null);
    const [sifraSkladista, setSifraSkladista] = useState(null);
    const [kasaPromet, setKasaPromet] = useState([]);
    const [repository, setRepository] = useState(null);
    const [smjena, setSmjena] = useState(null);

    const [artikal, setArtikal] = useState(null);
    const [selectData, setSelectData] = useState([]);
    const [selectSmjenaData, setSelectSmjenaData] = useState([]);

    const [selectDataNomen, setSelectDataNomen] = useState([]);
    const [firstDocumentNumber, setFirstDocumentNumber] = useState(null);
    const [itemUpdate, setItemUpdate] = useState(false);

    const [errorInputRepository, setErrorInputRepository] = useState(false);
    const [errorInputDocument, setErrorInputDocument] = useState(false);
    const [errorInputVrsta, setErrorInputVrsta] = useState(false);
    const [errorInputRbr, setErrorInputRbr] = useState(false);
    const [errorInputSifra, setErrorInputSifra] = useState(false);
    const [errorInputKolicina, setErrorInputKolicina] = useState(false);
    const [errorInputClients, setErrorInputClients] = useState(false);
    const [errorInputNomen, setErrorInputNomen] = useState(false);


    const [errorDate, setErrorDate] = useState(false);

    const [errorMsgRepository, setErrorMsgRepository] = useState(false);
    const [errorMsgSmjena, setErrorMsgsmjena] = useState(false);

    const [errorMsgNomen, setErrorMsgNomen] = useState(false);
    const [errorMsgKolicina, setErrorMsgKolicina] = useState(false);
    const [errorMsgRbr, setErrorMsgRbr] = useState(false);
    const [errorMsgVrsta, setErrorMsgVrsta] = useState(false);
    const [errorMsgClients, setErrorMsgClients] = useState(false)
    const [selectDataClients, setSelectDataClients] = useState([]);

    const [errorMsgSifra, setErrorMsgSifra] = useState(null);
    const [errorMsgDocument, setErrorMsgDocument] = useState(false);
    const [errorMsgDate, setErrorMsgDate] = useState(false);
    const [repositoryFocus, setRepositoryFocus] = useState(true);
    const [sifraFocus, setSifraFocus] = useState(false);
    const [clients, setClients] = useState(null);

    const [dateFocus, setDateFocus] = useState(false);

    const [modalDeleteItem, setModalDeleteItem] = useState(false);
    const [modalDeleteDokument, setModalDeleteDokument] = useState(false);
    const [modalErrorDeleteItemOpen, setModalErrorDeleteItemOpen] =
        useState(false);
    const [modalErrorEditOpen, setModalErrorEditOpen] = useState(false);
    const [modalErrorKusurOpen, setModalErrorKusurOpen] = useState(false);
    const [modalErrorAuthorizationData, setModalErrorAuthorizationOpen] =
    useState(false);

    const [spinner, setSpinner] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const toggle = () => setModalOpen(!modalOpen);

    // listener
    const [keysPressed, setKeysPressed] = useState({});

    // iznosi , kalkulacije na dnu stranice
    const [totalIznos, setTotalIznos] = useState(0);

    // povlacenje podataka iz lokal storidza
    const token =
        typeof window !== "undefined" ? localStorage.getItem("accessToken") : null;
    const companyCode = localStorage.getItem("company");
    const companyYear = localStorage.getItem("companyYear");
    let todaysDate = moment().year(companyYear);

    const [totalnoUplaceno, setTotalnoUplaceno] = useState(0);
    const [kusur, setKusur] = useState(0);

    const [form, setForm] = useState({
        ...defaultForm,
    });

    const [prometForm, setPrometForm] = useState({
        ...defaultPrometForm,
        // DPO: dayjs(todaysDate),
        DPO: dayjs(todaysDate).format("DD.MM.YYYY"),
    });

    useEffect(() => {
        setForm((prev) => ({
            ...prev,
            VRPROM: "g",
        }));
    }, [])

    // na dugme izlaz, vracamo vrijednosti inputa unutar modala na pocetne vrijednosti
    const setKasaModalToDefault = () => {
        setForm((prev) => ({
            ...prev,
            GOTOVINA: "",
            VIRMAN: "",
            CEK: "",
            KARTICA: "",
        }));
    };

    // propsovi za auto komplit  - skladiste, komitent, artikal i vrstu; objekat za dati autokomplit iz koga preko mape mozemo procitati podatak upisan u bazu
    //  getOptionLabel - polja koja ce biti prikazana u autokomplitu
    const defaultProps = {
        options: selectData,
        getOptionLabel: (option) =>
            option ? `${option.SIFRA} ${option.NAZIV}` : "",
    };

    const defaultPropsSmjena = {
        options: selectSmjenaData,
        getOptionLabel: (option) =>
            option ? `${option.SIFRA} ${option.NAZIV}` : "",
    };
    const defaultPropsClients = {
        options: selectDataClients,
        getOptionLabel: (option) =>
            option ? `${option.SIFRA} ${option.NAZIV}` : "",
    };

    const defaultPropsNomen = {
        options: selectDataNomen,
        getOptionLabel: (option) =>
            option ? `${option.SIFRA} - ${option.NAZIV} - ${option.JM}` : "",
    };

    // funkcija koja hvata promjene na poljima prometForme i setuje vrijednost u form state
    const handleChangePromet = (event) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name;
        setPrometForm((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    // funkcija koja hvata promjene na poljima forme i setuje vrijednost u form state
    const handleChangePrometS = (event) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name;
        setForm((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        console.log(value, 'value')
    };

    // funkcije za provjeru obaveznih polja - skladiste
    const checkRepositoryRequired = (value) => {
        // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto) ili ako nije upisana ni vrijednost niti je odabrano skladiste, izbaci poruku, setuje crveni okvir i fokusira polje
        // u suprotnom prolazi dalje
        if ((value && !repository) || (!value && !repository)) {
            setErrorMsgRepository("Skladiste mora biti izabrano!");
            setErrorInputRepository(true);
            repositoryRef.current.focus();
            setRepositoryFocus(true);
            return false;
        } else {
            setErrorMsgRepository(null);
            setErrorInputRepository(false);
            return true;
        }
    };

    // funkcije za provjeru obaveznih polja - skladiste
    const checkSmjenaRequired = (value) => {
        // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto) ili ako nije upisana ni vrijednost niti je odabrano skladiste, izbaci poruku, setuje crveni okvir i fokusira polje
        // u suprotnom prolazi dalje
        if ((value && !smjena) || (!value && !smjena)) {
            setErrorMsgsmjena("Smjena mora biti izabrana!");
            //   setErrorInputRepository(true);
            smjenaRef.current.focus();
            //   setRepositoryFocus(true);
            return false;
        } else {
            setErrorMsgsmjena(null);
            //   setErrorInputRepository(false);
            return true;
        }
    };

    // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto) ili ako nije upisana ni vrijednost niti je odabrana vrsta, izbaci poruku, setuje crveni okvir i fokusira polje
    // u suprotnom prolazi dalje
    const checkVrstaRequired = (value) => {
        if (!value || value == 0) {
            setErrorMsgVrsta("Za vrstu promjene unesite G ili R!");
            setErrorInputVrsta(true);
            vrstaRef.current.focus();
            return false;
        } else {
            setErrorMsgVrsta(null);
            setErrorInputVrsta(false);
            return true;
        }
    };

    // uslov: ako nije upisana neka vrijednost ili je vrijednost 0, izbaci poruku, setuje crveni okvir i fokusira polje
    const checkDocumentRequired = (value) => {
        if (!value || value == 0) {
            setErrorMsgDocument("Dokument je obavezan!");
            setErrorInputDocument(true);
            documentRef.current.focus();
            return false;
        } else {
            setErrorMsgDocument(null);
            setErrorInputDocument(false);
            return true;
        }
    };

    // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto) ili ako nije upisana ni vrijednost niti je odabran komitent, izbaci poruku, setuje crveni okvir i fokusira polje  
    // u suprotnom prolazi dalje
    const checkClientsRequired = (value) => {
        if (value && !clients || !value && !clients) {
            setErrorMsgClients('Komitent mora biti izabran!')
            setErrorInputClients(true)
            clientsRef.current.focus();
            return false
        } else {
            setErrorMsgClients(null)
            setErrorInputClients(false)
            return true
        }
    }

    // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto) ili ako nije upisana ni vrijednost niti je odabran dokument, izbaci poruku, setuje crveni okvir i fokusira polje  
    // u suprotnom prolazi dalje
    const checkRBRRequired = (value) => {
        //   console.log(value, 'value RBR')
        if (!value || value == 0) {
            //    console.log('upada u rbr', value)
            setErrorMsgRbr('Rbr je obavezan!')
            setErrorInputRbr(true)
            rbrRef.current.focus();
            return false
        } else {
            setErrorMsgRbr(null)
            setErrorInputRbr(false)
            return true
        }
    }

    // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto)
    // ili ako nije upisana ni vrijednost niti je odabran artikal, izbaci poruku, setuje crveni okvir i fokusira polje
    // u suprotnom prolazi dalje
    const checkSifraRequired = (value) => {
        let doesItemExistInArtikli = selectDataNomen.some(
            (artikal) => artikal.SIFRA == value,
        );
        //   console.log(selectDataNomen, 'selectDataNomen')
        if (!doesItemExistInArtikli) {
            setErrorInputSifra(true)
            setErrorMsgSifra("Unesite šifru artikla!");
            sifraRef.current.focus();
            return false;
        } else {
            setErrorInputSifra(false)
            setErrorMsgSifra(null);
            return true;
        }
    };

    // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto) ili ako nije upisana ni vrijednost niti je odabran artikal, izbaci poruku, setuje crveni okvir i fokusira polje  
    // u suprotnom prolazi dalje
    const checkNomenRequired = (value) => {
        if (value && !artikal || !value && !artikal) {
            setErrorMsgNomen('Artikal je obavezan!')
            setErrorInputNomen(true)
            nomenRef.current.focus();
            return false
        } else {
            setErrorMsgNomen(null)
            setErrorInputNomen(false)
            return true
        }
    }

    // uslov: ako nije upisana vrijednost manja od 0 ili je vrijednost 0,
    // izbaci poruku. U suprotnom: prolazi dalje.
    const checkKolRequired = (value) => {
        if (!value || value == 0) {
            setErrorInputKolicina(true)
            setErrorMsgKolicina("Količina je obavezna!");
            kolicinaRef.current.focus();
            return false;
        } else {
            setErrorInputKolicina(false)
            setErrorMsgKolicina(null);
            return true;
        }
    };

    // funkcija za kontrolu obaveznih polja
    function unosIspravanDo(name) {
        // skladiste
        if (name === "SKLAID") {
            return true;
        }

        if (!checkRepositoryRequired(prometForm.SKLAID)) {
            return false;
        }

        // smjena
        if (name === "SMJENA") {
            return true;
        }

        if (!checkSmjenaRequired(prometForm.SMJENA)) {
            return false;
        }

        // datum
        if (name === "DPO") {
            return true;
        }
        let regex = /^(0[1-9]|[12]\d|3[01]).(0[1-9]|1[0-2]).([12]\d{3})$/gi;
        if (!regex.test(prometForm.DPO)) {
            setErrorDate(true);
            setErrorMsgDate("Datum nije validan!");
            dateRef.current.focus();
            return false;
        } else {
            setErrorDate(false);
            setErrorMsgDate(null);
        }
        // depozit
        if (name === "DEPOZIT") {
            return true;
        }

        // zakljucenje
        if (name === "ZAKLJUCENJE") {
            return true;
        }

        // dugme nastavi
        if (name === "NASTAVI") {
            return true;
        }

        // redni broj
        if (name === "VRPROM") {
            return true;
        }

        if (!checkVrstaRequired(form.VRPROM)) {
            return false;
        }

        // broj dokumenta
        if (name === "DOK") {
            return true;
        }

        if (!checkDocumentRequired(prometForm.DOK)) {
            return false;
        }

        // komitent
        if (name === "KOMTID") {
            return true;
        }

        if (form.VRPROM == 'r') {
            if (!checkClientsRequired(form.KOMTID)) {
                return false;
            }
        }

        // redni broj
        if (name === "RBR") {
            return true;
        }

        if (!checkRBRRequired(form.RBR)) {
            return false;
        }

        // sifra
        if (name == "SIFRA") {
            return true;
        }

        if (!checkSifraRequired(form.SIFRA)) {
            return false;
        }

        // kolicina
        if (name === "ARTIKAL") {
            return true;
        }

        if (!checkNomenRequired(form.NOMENID)) {
            return false
        }


        // kolicina
        if (name === "KOLICINA") {
            return true;
        }

        if (!checkKolRequired(form.KOLICINA)) {
            return false;
        }

        console.log(form.OZNAKA != '-', 'form.OZNAKA != -', form.PROIZVOD != 'u', 'form.PROIZVOD != u', form.LAGER <= 0, 'form.LAGER <= 0', form.KOLICINA > form.LAGER, 'form.KOLICINA > form.LAGER', form.ZAL_PLUS == 'd', 'form.ZAL_PLUS == d')

        if (form.OZNAKA != '-' && form.PROIZVOD != 'u' && form.LAGER <= 0 && form.KOLICINA > form.LAGER && form.ZAL_PLUS == 'd') {
            kolicinaRef.current.focus();
            setModalLagerErrorOpen(true);
        }

        // kolicina
        if (name === "UPIS") {
            return true;
        }

        return true;
    }

    // funkcija koja poziva api za pronalazenje prvog slobodnog dokumenta
    const getPrometFirstFreeNumber = () => {
        fetch(API + `/kasa/firstFreeNumber`, {
            method: "POST",
            body: JSON.stringify({
                sklaId: repository && repository.ID ? repository.ID : "",
                vrprom: form.VRPROM === 'g' ? 'GR' : 'IZ',
            }),
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-type": "application/json; charset=UTF-8",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                let promet = data[0];
                if (promet) {
                    setPrometForm((prev) => ({
                        ...prev,
                        DOK: promet.DOK,
                        PROMETID: promet.ID,
                    }));
                    setFirstDocumentNumber(promet.DOK, promet.ID);
                }
            });
    };

    // definisu se polja u tabeli i popunjavaju
    const columns = [
        {
            name: "Rbr.",
            class: "col-1 fs-6 text-end px-3 text-nowrap ",
        },
        {
            name: "Artikal",
            class: "col-1 fs-6 text-end px-3 text-nowrap",
        },
        {
            name: "Naziv Artikal",
            class: "col-6 fs-6 px-3 text-nowrap",
        },
        {
            name: "Količina",
            class: "col-1 fs-6 text-end px-3 text-nowrap",
        },
        {
            name: "Cijena",
            class: "col-1 fs-6 text-end px-3 text-nowrap",
        },
        {
            name: "Rabat",
            class: "col-1 fs-6 text-end px-3 text-nowrap",
        },
        {
            name: "Ukupno",
            class: "col-1 fs-6 text-end px-3 text-nowrap",
        },
    ];

    // funckija koja poziva upisivanje stavki u tabetu
    const getPromet = () => {
        fetch(API + `/kasa/kasaPromet`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify({
                SKLAID: prometForm.SKLAID,
                DOK: prometForm.DOK,
                VRPROM: form.VRPROM === 'g' ? 'GR' : 'IZ',
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                let promet = data;
                if (promet) {
                    console.log(promet, 'promet')
                    // dobijanje nivelacija i duzine niza
                    setKasaPromet(promet);
                    setPrometForm((prev) => ({
                        ...prev,
                        ID: promet[0]?.PROMETID,
                    }));
                    if (promet.length > 0) {
                        getTotals(promet);
                    }
                }
            });
    };

    // sabiranje totala (desni donji ugao)
    const getTotals = (kasaPromet) => {
        let totals = {
            IZNMALD: 0,
        };

        // iteracija kroz niz i sabiranje vrijednosti za svaki clan niza
        kasaPromet.forEach((k) => {
            totals.IZNMALD += k.UKUPNO;
        });
        // roundujemo iznos na 2 decimale
        setTotalIznos(roundTO(totals.IZNMALD, 2));
        setKusur(0); // setujemo kusur na 0, (desio se novi unos)
    };

    // funkcija koja poziva citanje artikla
    const getArticles = () => {
        fetch(API + `/kasa/getAllArticles`, {
            method: "POST",
            body: JSON.stringify({
                SKLAID: prometForm.SKLAID,
                companyCode: companyCode,
            }),
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-type": "application/json; charset=UTF-8",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                // upisujemo dobijeni rezultat artikala
                setSelectDataNomen(data);
            });
    };

    // funkcija koja poziva citanje lagera
    const getLagerData = (nomenid) => {
        fetch(API + `/lager/all`, {
            method: "POST",
            body: JSON.stringify({
                sklaId: prometForm?.SKLAID || "",
                nomenId: nomenid || 0,
            }),
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-type": "application/json; charset=UTF-8",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.length > 0) {
                    // ukoliko data.length > 0 upisi vrijednosti
                    setForm((prev) => ({
                        ...prev,
                        LAGER: data ? data[0]?.LAGER : 0,
                    }));
                } else {
                    // u suprotnom, nuliraj na promjenu artikla
                    setForm((prev) => ({
                        ...prev,
                        LAGER: 0,
                    }));
                }
            });
    };

    // dobijanje odgovarajuceg poreza za artikal
    const getTaxData = (nomenid) => {
        fetch(API + `/porezi/procenat`, {
            method: "POST",
            body: JSON.stringify({ nomenId: nomenid }),
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-type": "application/json; charset=UTF-8",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data && data.length) {
                    setForm((prev) => ({ ...prev, PDV: data[0].PROCENAT }));
                }
            });
    };

    // funkcija koja poziva api za citanje opcija
    const getOpcijeData = () => {
        fetch(API + `/opcijeAplikacije/allOpcije`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-type": "application/json; charset=UTF-8",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data && data.length) {
                    setForm((prev) => ({ ...prev, ZAL_PLUS: data[0].ZAL_PLUS }));
                }
            });
    };

    // funkcija koja poziva api za pronalazenje informacija o artiklu na osnovu Sifre artikla
    const getArticleInfo = () => {
        fetch(API + `/kasa/articleInfo`, {
            method: "POST",
            body: JSON.stringify({
                SKLAID: prometForm?.SKLAID || "",
                SIFRAARTIKLA: form?.SIFRA || 0,
                companyCode: companyCode || 0,
                prometid: prometForm.PROMETID,
            }),
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-type": "application/json; charset=UTF-8",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                let articleData = data[0];
                if (articleData) {
                    // setovanje lagera i poreza na osnovu articleData
                    getLagerData(articleData?.NOMENID);
                    getTaxData(articleData?.NOMENID);
                    // setovanje forme na osnovu articleData
                    setForm((prev) => ({
                        ...prev,
                        FAKTCEN: articleData?.NABCENA
                            ? articleData?.NABCENA
                            : articleData?.PRODCENA || 0,
                        NAZIV: articleData?.NAZIV || "",
                        SIFRA: articleData?.SIFRA || 0,
                        NABCEN: articleData?.NABCENA || "",
                        MALCEN: articleData?.PRODCENA || 0,
                        STARA_CENA: articleData?.PRODCENA || 0,
                        JM: articleData?.JM || "",
                        NOMENID: articleData?.NOMENID || 0,
                        PRODCENA: articleData?.PRODCENA || 0,
                        IZPDV: articleData.IZPDV * form.KOLICINA,
                        OZNAKA: articleData.OZNAKA,
                        PROIZVOD: articleData.PROIZVOD
                    }));
                    // setovanje artikla
                    setArtikal({
                        NAZIV: articleData?.NAZIV || "",
                        SIFRA: articleData?.SIFRA || 0,
                        NABCEN: articleData?.NABCENA || 0,
                        JM: articleData?.JM || "",
                    });
                }
            });
    };

    // funkcija koja poziva citanje skladista
    const getRepositoryData = () => {
        fetch(API + `/skladista/all/${companyCode}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-type": "application/json; charset=UTF-8",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setSelectData(data);
            });
    };

    // funkcija koja poziva citanje smjene
    const getSmjenaData = () => {
        fetch(API + `/smjena/all`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-type": "application/json; charset=UTF-8",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setSelectSmjenaData(data);
            });
    };

    // funckija koja vraca prvi slovodan redni broj
    const getPrometFirstFreeNumberRbr = () => {
        fetch(API + `/kasa/firstFreeNumberRbr`, {
            method: "POST",
            body: JSON.stringify({
                sklaId: prometForm.SKLAID || "",
                vrprom: form.VRPROM === 'g' ? 'GR' : 'IZ',
                dok: prometForm.DOK,
            }),
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-type": "application/json; charset=UTF-8",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setForm((prev) => ({ ...prev, RBR: data[0].RBR }));
            });
    };

    // prijava na kasu, zatvaranje prvog modala, prikazivanje kontenta i dobijanje prometID-a
    const prijavaNaKasu = () => {
        fetch(API + `/kasa/prijava`, {
            method: "POST",
            body: JSON.stringify({
                SIFRAP: companyCode,
                SMJENA: prometForm.SMJENA,
                DATUM: prometForm.DPO,
                DEPOZIT: prometForm.DEPOZIT,
                ZAK: prometForm.ZAKLJUCENJE,
            }),
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-type": "application/json; charset=UTF-8",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                //   console.log(smjena);
                setShowContent(true);
                setModalOpen(false);
                getPrometFirstFreeNumber();
            });
    };

    // funkcija koja se poziva klikom na dugme 'Sacuvaj'
    // upisivanje nove stavke
    const onSubmit = () => {
        // ukoliko su polja ispravna nastavi
        if (!unosIspravanDo("UPIS")) return;

        // ukoliko nema stanja na lageru i
        // ukoliko je izabrana kolicina veca od stanja na lageru
        // if (form.LAGER <= 0 || form.KOLICINA > form.LAGER) {
        //     kolicinaRef.current.focus();
        //     setModalLagerErrorOpen(true);
        // } else {
        form.VRSTA_SKLADISTA = prometForm.VRSTA_SKLADISTA;
        form.VRPROM = form.VRPROM;
        form.SKLAID = prometForm.SKLAID;
        form.KOMTID = prometForm.KOMTID;
        form.PROMETID = prometForm.ID;
        form.DOK = prometForm.DOK;
        form.SMJENA = prometForm.SMJENA;
        form.IZNMALD = form.KOLICINA * form.MALCEN;

        fetch(API + "/kasa/new", {
            method: "POST",
            body: JSON.stringify(form),
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-type": "application/json; charset=UTF-8",
            },
        })
            .then((response) => {
                response.json();
            })
            .then((data) => {
                getPromet();
                rbrRef.current.focus(); // vracamo korisnika na redni broj input
            });
        //  }
    };

    // funkcija koja se poziva duplim klikom na stavku, punjenje forme vrijednostima iz item-a
    const onEdit = (item) => {
        setItemUpdate(true);
        setForm((prevState) => ({
            ...prevState,
            ...item,
            SIFRA: item.ARTIKAL,
            KOLICINA: item.KOL,
            NAZIV: item.NAZIV_ARTIKLA,
            FAKTCEN: item.CENA,
            STARA_CENA: item.CENA,
            MALCEN: item.CENA,
            IZNMALD: item.UKUPNO,
            LAGER: item.LAGER
        }));
        getLagerData(item.NOMENID);
        // provjeravamo da li je item.NOMENID jednak artikal.SIFRA , ukoliko jeste setujemoArtikal

        selectDataNomen.map((artikal) => {
            if (artikal.SIFRA == item.ARTIKAL) {
                setArtikal(artikal);
            }
        });
    };

    // funkcija koja poziva brisanje dokumenta
    const onDelete = (sklaId, vrprom, dok) => {
        console.log(prometForm.ID, 'prometForm.ID')
        if (form.ovlrobno == 4)
            return setModalErrorAuthorizationOpen(true), setModalDeleteDokument(false);

        fetch(API + `/kasa/document/delete/${sklaId}/${vrprom}/${dok}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                if (response.status == 200) {
                    setModalDeleteDokument(false);
                    setModalDeleteItem(false);
                    setKasaPromet(null);
                    setClients(null)
                    setArtikal(null);
                    // checkDocument();
                    setItemUpdate(false);
                  //  getPrometInfo();
                    setForm((prev) => ({
                        ...prev,
                        ...defaultForm,
                    }));
                }
            })
        }
        // funkcija koja poziva api za brisanje stavki
        const onDeleteItems = (prometID, rbr) => {
            fetch(API + `/kasa/item/delete/${prometID}/${rbr}`, {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-type": "application/json; charset=UTF-8",
                },
            }).then((response) => {
                // uslov: ako je uspjesno obrisano, cisti sva polja u vezi sa stavkom
                if (response.status == 200) {
                    setModalDeleteItem(false);
                    setKasaPromet(null);
                    setArtikal(null);
                    // checkDocument();
                    setItemUpdate(false);
                    let vrprom = form.VRPROM
                    setForm((prev) => ({
                        ...prev,
                        ...defaultForm,
                        VRPROM: vrprom
                    }));
                    getPromet();
                }
                // zaustavljanje fokusa(pauza za fokus dok ne odradi brisanje)
                const timeout = setTimeout(() => {
                    rbrRef.current.focus();
                }, 500);
                return () => {
                    clearTimeout(timeout);
                };
            });
        };

        // funkcija koja poziva citanje komitenta
        const getClientsData = () => {
            fetch(API + `/komitenti/all/${companyCode}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-type": "application/json; charset=UTF-8",
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    setSelectDataClients(data);
                });
        };

        // useeffect koji se poziva na prvom ulazu na /kasa
        useEffect(() => {
            setModalOpen(true);
            getRepositoryData();
            getSmjenaData();
            getArticles();
            getClientsData();
        }, [!repository && !smjena && !clients && !artikal]);

        // useeffect koji se poziva kada showContent promijeni vrijednost
        useEffect(() => {
            if (showContent) {
                rbrRef.current.focus();
                // getPrometFirstFreeNumberRbr();
            }
        }, [showContent]);

        // listener za 0 + ENTER
        useEffect(() => {
            const handleKeyDown = (event) => {
                setKeysPressed((prevState) => ({ ...prevState, [event.key]: true }));
            };

            const handleKeyUp = (event) => {
                setKeysPressed((prevState) => ({ ...prevState, [event.key]: false }));
            };

            window.addEventListener("keydown", handleKeyDown);
            window.addEventListener("keyup", handleKeyUp);

            // Cleanup function to remove the event listeners
            return () => {
                window.removeEventListener("keydown", handleKeyDown);
                window.removeEventListener("keyup", handleKeyUp);
            };
        }, []);

        // slusamo svaku promjenu keysPressed i
        // ukoliko kasa ima stavki otvori modal
        useEffect(() => {
            if (kasaPromet.length > 0) {
                if (keysPressed["0"] && keysPressed["Enter"]) {
                    // Perform your action here
                    setModalKasaOpen(true);
                    setTimeout(() => {
                        gotovinaRef.current.focus();
                    }, 100);
                }
            }
        }, [keysPressed]);

        // funkcija koja hvata promjene na poljima i  setuje vrijednost u form state
        const handleChange = (event) => {
            let value = event.target.value;
            const name = event.target.name;
            setPrometForm((prevState) => ({
                ...prevState,
                [name]: value,
            }));
            prometForm.DOK = value
        };

        return (
            <div>
                {showContent && (
                    <div>
                        <div className="title-container">
                            <header className="title-header">
                                {/* informacije o smjenu, skladistu, dokumentu  */}
                                <div className="text-light fw-bold fs-5 d-flex">
                                    <span>SMJENA: {smjena.SIFRA} </span>
                                    <span>- {prometForm.DPO} | </span>
                                    <span> {sifraSkladista} - {nazivSkladista} | </span>
                                    <span style={{ marginRight: '-120px' }}>
                                        <div className={errorInputVrsta ? 'form-group row inputWithError-columns' : 'form-group row label-box-div'} >
                                            <label className="col-form-label text-light col-lg-1 label-box" id="media-query-label-report"></label>
                                            <div className="col-4" >
                                                <input
                                                    type="text"
                                                    id="media-query-input-text"
                                                    maxLength="1"
                                                    tabIndex="1"
                                                    ref={vrstaRef}
                                                    name="VRPROM"
                                                    value={form?.VRPROM}
                                                    onFocus={() => {
                                                        unosIspravanDo("VRPROM");
                                                    }}
                                                    onClick={() => {
                                                        // ako je odradjen dvoklik na stavku u mrezi, a zatim klik na dokument, cisti polja
                                                        setForm((prev) => ({
                                                            ...prev,
                                                            ...defaultForm,
                                                        }));
                                                        setClients(null);
                                                        setArtikal(null);
                                                    }}
                                                    onChange={(e) => handleChangePrometS(e)}
                                                />
                                            </div>
                                            {/* prikazivanje greske ukoliko vrsta nije izabrana */}
                                            {errorMsgVrsta && (
                                                <div className="col-12">
                                                    <span className="error-message">{errorMsgVrsta}</span>
                                                </div>
                                            )}
                                        </div>
                                    </span>
                                    <span>
                                        <div className={errorInputDocument ? 'form-group row inputWithError-columns' : 'form-group row label-box-div'} >
                                            <label className="col-form-label text-light text-start ps-1 col-lg-4 label-box" id="media-query-label-report"> | Račun:</label>
                                            <div className="col-5" >
                                                {/* komponenta za formatiranje brojeva */}
                                                <NumericFormat
                                                    type="text"
                                                    className='field-alignment pe-1'
                                                    getInputRef={documentRef}
                                                    id="media-query-input-text"
                                                    tabIndex="2"
                                                    maxLength="9"
                                                    // broj decimala
                                                    decimalScale={0}
                                                    onFocus={() => {
                                                        unosIspravanDo("DOK");
                                                        getPrometFirstFreeNumber();
                                                    }}
                                                    placeholder="0"
                                                    name="BRDOK"
                                                    value={prometForm ? prometForm.DOK : ''}
                                                    onChange={(e) => handleChange(e)}
                                                    //    onBlur={(e) => formCheck(e)}
                                                    onClick={() => {
                                                        let vrprom = form.VRPROM;
                                                        // ako je odradjen dvoklik na stavku u mrezi, a zatim klik na dokument, cisti polja
                                                        setForm((prev) => ({
                                                            ...prev,
                                                            ...defaultForm,
                                                            VRPROM: vrprom,
                                                        }));
                                                        setClients(null);
                                                        setArtikal(null);
                                                    }}
                                                />
                                                {errorMsgDocument ? (
                                                    <span
                                                        style={{
                                                            width: "100%",
                                                            color: "red",
                                                            fontSize: "11px",
                                                            fontFamily: "cursive",
                                                        }}
                                                    >
                                                        {errorMsgDocument}
                                                    </span>
                                                ) : (
                                                    ""
                                                )}

                                            </div>
                                        </div>
                                    </span>
                                </div>
                                <Container fluid>
                                    <Row>
                                        <div className="col-12 flex-column flex-lg-row">
                                            <Row className="col-12 flex-column flex-lg-row">
                                                <div className="col-12 col-lg-4 d-flex flex-column gap-3">
                                                    <div className="col-12">

                                                        <div className={errorInputClients ? 'form-group row inputWithError-columns' : 'form-group row label-box-div'} >
                                                            <label className="col-form-label col-lg-2 label-box" id="media-query-label-report">Komitent:</label>
                                                            <div className="col-10">
                                                                {/* autokomplit za komitenta */}
                                                                <Autocomplete
                                                                    isOptionEqualToValue={(option, value) =>
                                                                        option.id === value.id
                                                                    }
                                                                    {...defaultPropsClients}
                                                                    value={clients}

                                                                    disablePortal={true}
                                                                    onFocus={() => {
                                                                        unosIspravanDo("KOMTID");
                                                                        getPromet();
                                                                    }}
                                                                    // ako se promijeni komitent, uzima podatke za novog izabranog komitenta
                                                                    onChange={(event, newValue) => {
                                                                        setPrometForm((prev) => ({
                                                                            ...prev,
                                                                            KSifra:
                                                                                newValue && newValue != null
                                                                                    ? newValue?.SIFRA
                                                                                    : "",
                                                                            KOMTID:
                                                                                newValue && newValue != null
                                                                                    ? newValue?.ID
                                                                                    : "",
                                                                        }));
                                                                        setClients(newValue);
                                                                        // fokus se mora vratiti inace tabIndex ne radi dobro
                                                                        // clientsRef.current.focus();
                                                                    }}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            {...params}
                                                                            tabIndex="3"
                                                                            ref={clientsRef}
                                                                            maxLength="283"
                                                                            className="clients-textinput"
                                                                            name="KOMTID"
                                                                            // ref={clientsRef}
                                                                            placeholder="Izaberite komitenta"
                                                                            variant="standard"
                                                                        />
                                                                    )}
                                                                />
                                                                {errorMsgClients ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgClients}</span> : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-12 col-lg-4 d-flex flex-column-row gap-3 justify-content-center">
                                                    <label className="col-form-label col-lg-2 label-box" id="media-query-label-report">Saldo:</label>
                                                    <div className="col-4">
                                                        <input
                                                            type='text'
                                                            className="form-controlst"
                                                            readOnly={true}
                                                            tabIndex="-1"
                                                            id="media-query-input"
                                                            ref={saldoRef}
                                                            onFocus={() => {
                                                                unosIspravanDo("SALDO")
                                                            }}
                                                            name='SALDO'
                                                            value={prometForm?.SALDO}
                                                            onChange={(e) => handleChange(e)} />
                                                    </div>
                                                </div>

                                                <div className="col-12 col-lg-4 d-flex flex-column-row gap-3 justify-content-end">

                                                    <label className="col-form-label col-lg-2 label-box" id="media-query-label-report">Valuta:</label>
                                                    <div className="col-3">
                                                        <input
                                                            type='text'
                                                            tabIndex="4"
                                                            id="media-query-input"
                                                            ref={valutaRef}
                                                            //   selected={startDate}
                                                            onFocus={() => {
                                                                unosIspravanDo("VALUTA")
                                                            }}
                                                            placeholder="valuta"
                                                            name='VALUTA'
                                                            value={prometForm?.VALUTA}
                                                            onChange={(e) => handleChange(e)} />
                                                    </div>
                                                </div>

                                            </Row>
                                        </div>
                                    </Row>
                                </Container>

                                <h1 id="media-query-title ">Fiskalna Kasa</h1>
                            </header>
                            <div className="naslov-ikonice">
                                <ul className="nav flex-lg-row justify-content-end">
                                    <li className="nav-item toolbaritem">
                                        <input
                                            type="image"
                                            alt="save"
                                            className="dugme-sacuvaj"
                                            title="Sačuvaj"
                                            tabIndex="26"
                                            src={require("./../../assets/images/floppydisk.png")}
                                            onFocus={() =>
                                                !itemUpdate ? onSubmit() : setModalErrorEditOpen(true)
                                            }
                                        />
                                    </li>

                                    <li className="nav-item toolbaritem">
                                        <div
                                            onClick={
                                                prometForm.DOK && prometForm.DOK !== "" && prometForm.DOK !== 0
                                                    ? setModalDeleteDokument
                                                    : setModalErrorDeleteDocumentOpen
                                            }
                                            tabIndex="27"
                                        >
                                            <SVG
                                                src={deleteDocument}
                                                style={{ margin: "1px" }}
                                                title="Brisanje dokumenta"
                                                className="dugme-brisanje"
                                            />
                                        </div>
                                    </li>

                                    <li className="nav-item toolbaritem">
                                        <input
                                            type="image"
                                            alt="delete item"
                                            className="dugme-stampa"
                                            title="Brisanje stavke"
                                            tabIndex="29"
                                            src={require("./../../assets/images/trash1.png")}
                                            onClick={
                                                itemUpdate
                                                    ? setModalDeleteItem
                                                    : setModalErrorDeleteItemOpen
                                            }
                                        />
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <Container fluid>
                                <Row>
                                    <div className="col-12 d-flex flex-lg-row flex-wrap my-3 p-0">
                                        <div className="col-6 col-lg-1">
                                            <div className={errorInputRbr ? 'inputWithError text-end' : 'field-container text-end'} style={{ marginRight: '3px' }} >
                                                <label className="fs-6 text-nowrap m-0 px-1">
                                                    Rbr.
                                                </label>
                                                {/* komponenta za formatiranje brojeva */}
                                                <NumericFormat
                                                    type="text"
                                                    className='field-alignment pe-1'
                                                    getInputRef={rbrRef}
                                                    maxLength="3"
                                                    decimalScale={0}
                                                    tabIndex={5}
                                                    autoFocus={true}
                                                    name="RBR"
                                                    value={form.RBR}
                                                    onFocus={() => {
                                                        unosIspravanDo("RBR");
                                                        getPrometFirstFreeNumberRbr();
                                                        let rbr = form.RBR;
                                                        let vrprom = form.VRPROM;
                                                        setForm((prev) => ({
                                                            ...prev,
                                                            ...defaultForm,
                                                            VRPROM: vrprom,
                                                            RBR: rbr,
                                                        }));
                                                        setItemUpdate(false);
                                                        setArtikal(null);
                                                        //  getPromet();
                                                        //  sifraRef.current.focus(); // saljemo korisnika na sifra polje
                                                    }}
                                                    onChange={(e) => {
                                                        setForm((prev) => ({
                                                            ...prev,
                                                            RBR: e.target.value,
                                                        }));
                                                        handleChangePrometS(e);
                                                    }}
                                                />
                                                {/* prikazivanje poruke o pogresnom upisu  */}
                                                {errorMsgRbr && !itemUpdate && (
                                                    <div className="col-12">
                                                        <span className="error-message">{errorMsgRbr}</span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-6 col-lg-1 ps-1">
                                            <div
                                                className={
                                                    errorInputSifra && !itemUpdate
                                                        ? "text-end inputWithError"
                                                        : "text-end"
                                                }
                                            >
                                                <div>
                                                    <label className="fs-6 text-nowrap m-0 px-1">
                                                        Šifra
                                                    </label>
                                                    {/* komponenta za formatiranje brojeva */}
                                                    <NumericFormat
                                                        type="text"
                                                        className='field-alignment pe-1'
                                                        getInputRef={sifraRef}
                                                        maxLength="6"
                                                        // broj decimala
                                                        decimalScale={0}
                                                        tabIndex={6}
                                                        placeholder="0"
                                                        name="SIFRA"
                                                        value={form.SIFRA}
                                                        onFocus={() => {
                                                            // setSifraFocus(true);
                                                            unosIspravanDo("SIFRA");
                                                            // ukoliko je korisnik kliknuo na edit, izbaci gresku
                                                            if (itemUpdate) {
                                                                setModalEditErrorOpen(true);
                                                            }
                                                        }}
                                                        onChange={(e) => {
                                                            handleChangePrometS(e);
                                                            setForm((prev) => ({
                                                                ...prev,
                                                                SIFRA: e.target.value,
                                                            }));
                                                        }}
                                                    />
                                                    {/* prikazivanje poruke o pogresnom upisu  */}
                                                    {errorMsgSifra && !itemUpdate && (
                                                        <div className="col-12">
                                                            <span className="error-message">
                                                                {errorMsgSifra}
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-lg-4 ps-1">
                                            {/* artikal */}
                                            <div
                                                className={errorMsgNomen && !itemUpdate ? "inputWithError" : ""}
                                            >
                                                <label className="px-1 text-nowrap">Artikal</label>
                                                <Autocomplete
                                                    ref={nomenRef}
                                                    // svaki od ponudjenih artikala mora biti jedinstven(svaka stavka(child) u objektu artikal mora imati jedinstven kljuc)
                                                    isOptionEqualToValue={(option, value) =>
                                                        option.id === value.id
                                                    }
                                                    renderOption={(props, artikal) => {
                                                        // svaki artikal renderujemo kao -li element unutar koga postoje 2 spana, te su odvojeni ps-3
                                                        return (
                                                            <li {...props} key={artikal.SIFRA}>
                                                                <span>{artikal.SIFRA}</span>
                                                                <span className="ps-3">{artikal.NAZIV}</span>
                                                            </li>
                                                        );
                                                    }}
                                                    {...defaultPropsNomen}
                                                    value={artikal}
                                                    disablePortal={true}
                                                    onFocus={() => {
                                                        // ukoliko je korisnik kliknuo na edit, izbaci gresku
                                                        if (itemUpdate) {
                                                            setModalEditErrorOpen(true);
                                                        }
                                                        unosIspravanDo("ARTIKAL");
                                                    }}
                                                    // ako se promijeni artikal, uzima podatke za novi izabrani artikal
                                                    onChange={(event, newValue) => {
                                                        setForm((prev) => ({
                                                            ...prev,
                                                            NOMENID:
                                                                newValue && newValue != null ? newValue?.ID : "",
                                                            POREZID:
                                                                newValue && newValue != null
                                                                    ? newValue?.POREZID
                                                                    : "",
                                                            JM:
                                                                newValue && newValue != null ? newValue?.JM : "",
                                                            SIFRA:
                                                                newValue && newValue != null
                                                                    ? newValue?.SIFRA
                                                                    : "",
                                                        }));
                                                        // setujemo artikal
                                                        setArtikal(newValue);
                                                        setTimeout(() => {
                                                            //   kolicinaRef.current.focus();
                                                        }, 50);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            maxLength="283"
                                                            tabIndex={7}
                                                            className={
                                                                itemUpdate && !itemUpdate ? "form-controlst" : ""
                                                            }
                                                            name="NOMENID"
                                                            readOnly={itemUpdate}
                                                            placeholder="artikal"
                                                            variant="standard"
                                                        />
                                                    )}
                                                />
                                                {/* prikazivanje greske ukoliko artikal nije izabran */}
                                                {errorMsgNomen && !itemUpdate && (
                                                    <div className="col-12">
                                                        <span className="error-message">{errorMsgNomen}</span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-6 col-lg-1 ps-1">
                                            <div className={
                                                errorInputKolicina && !itemUpdate
                                                    ? "inputWithError text-end"
                                                    : "field-container text-end"
                                            }
                                            >
                                                <label className="fs-6 text-nowrap m-0 px-1">
                                                    Količina
                                                </label>
                                                {/* komponenta za formatiranje brojeva */}
                                                <NumericFormat
                                                    type="text"
                                                    getInputRef={kolicinaRef}
                                                    // broj decimala
                                                    decimalScale={2}
                                                    tabIndex={8}
                                                    className="text-dark text-end"
                                                    placeholder="0"
                                                    name="KOLICINA"
                                                    value={form.KOLICINA}
                                                    onFocus={() => {
                                                        // ukoliko je korisnik kliknuo na edit, izbaci gresku
                                                        if (itemUpdate) {
                                                            setModalEditErrorOpen(true);
                                                        } else {
                                                            setModalLagerErrorOpen(false);
                                                            unosIspravanDo("KOLICINA");
                                                            getArticleInfo();
                                                            getOpcijeData();
                                                        }
                                                    }}
                                                    onChange={(e) => {
                                                        handleChangePrometS(e);
                                                        setForm((prev) => ({
                                                            ...prev,
                                                            KOLICINA: e.target.value,
                                                            IZNMALD: form.MALCEN * e.target.value,
                                                        }));
                                                    }}
                                                />
                                                {/* prikazivanje poruke o pogresnom upisu  */}
                                                {errorMsgKolicina && !itemUpdate && (
                                                    <div className="col-12">
                                                        <span className="error-message">
                                                            {errorMsgKolicina}
                                                        </span>
                                                    </div>
                                                )}

                                            </div>
                                        </div>
                                        <div className="col-6 col-lg-1 ps-1">
                                            <div className="text-end">
                                                <div>
                                                    <label className="fs-6 text-nowrap m-0 px-1">
                                                        Cijena
                                                    </label>
                                                    {/* komponenta za formatiranje brojeva */}
                                                    <NumericFormat
                                                        type="number"
                                                        disabled
                                                        decimalScale={2}
                                                        className="text-dark text-end"
                                                        placeholder="0"
                                                        name="MALCEN"
                                                        value={form.MALCEN}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-lg-1 ps-1">
                                            <div className="text-end">
                                                <div>
                                                    <label className="fs-6  text-nowrap m-0 px-1">
                                                        Rabat %
                                                    </label>
                                                    {/* komponenta za formatiranje brojeva */}
                                                    <NumericFormat
                                                        type="number"
                                                        disabled
                                                        // broj decimala
                                                        decimalScale={2}
                                                        className="text-dark text-end"
                                                        placeholder="0"
                                                        name="RABAT"
                                                        value={form.RABAT}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-lg-1 ps-1">
                                            <div className="text-end">
                                                <div>
                                                    <label className="fs-6 text-nowrap m-0 px-1">
                                                        Rabat
                                                    </label>
                                                    {/* komponenta za formatiranje brojeva */}
                                                    <NumericFormat
                                                        type="number"
                                                        disabled
                                                        decimalScale={2}
                                                        className="text-dark text-end"
                                                        placeholder="0"
                                                        name="IZNRABAT"
                                                        value={form.IZNRABAT}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-lg-1 ps-1">
                                            <div className="text-end">
                                                <div>
                                                    <label className="fs-6 text-nowrap m-0 px-1">
                                                        Zalihe
                                                    </label>
                                                    {/* komponenta za formatiranje brojeva */}
                                                    <NumericFormat
                                                        type="number"
                                                        disabled
                                                        decimalScale={2}
                                                        className="text-dark text-end"
                                                        placeholder="0"
                                                        name="LAGER"
                                                        value={form.LAGER}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-lg-1 ps-1">
                                            <div className="text-end">
                                                <div>
                                                    <label className="fs-6 text-nowrap m-0 px-1 text-">
                                                        Prodajni Iznos
                                                    </label>
                                                    {/* komponenta za formatiranje brojeva */}
                                                    <NumericFormat
                                                        type="number"
                                                        disabled
                                                        decimalScale={2}
                                                        className="text-dark text-end"
                                                        placeholder="0"
                                                        name="IZNMALD"
                                                        value={form.IZNMALD}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Row>
                            </Container>
                            <div className="table-responsive">
                                <table className="table table-sm table-striped table-hover table-light ">
                                    <thead>
                                        <tr>
                                            {/* iteracija kroz columns i dodjeljivanje table headera vrijednostima iz columns[i].name */}
                                            {columns.map((column, index) => {
                                                return (
                                                    <th key={index} scope="col" className={column.class}>
                                                        {column.name}
                                                    </th>
                                                );
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* iteracija kroz kalkulacija[] i dodjeljivanje klasa vrijednostima iz columns[i].class */}
                                        {kasaPromet &&
                                            kasaPromet?.map((s, index) => {
                                                return (
                                                    <tr
                                                        onDoubleClick={() => {
                                                            onEdit(s);
                                                        }}
                                                        key={index}
                                                        className="col-12 user-select-none"
                                                    >
                                                        <td className={columns[0].class}>{s.RBR}</td>
                                                        <td className={columns[1].class}>{s.ARTIKAL}</td>
                                                        <td className={columns[2].class}>
                                                            {s.NAZIV_ARTIKLA}
                                                        </td>
                                                        <td className={columns[3].class}>{s.KOL}</td>
                                                        <td className={columns[4].class}>
                                                            {numberWithCommas(s.CENA)}
                                                        </td>
                                                        <td className={columns[5].class}>
                                                            {numberWithCommas(s.RABAT)}
                                                        </td>
                                                        <td className={columns[6].class}>
                                                            {numberWithCommas(s.UKUPNO + s.RABAT)}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {/* totali */}
                        <div>
                            <ul className="nav flex-column flex-lg-row justify-content-end">
                                <div className="d-flex ms-auto col-9 justify-content-end align-items-center fixed-bottom  mb-3 me-3 gap-3">
                                    {/* kusur */}
                                    <div className="user-select-none col-2">
                                        <div className="user-select-none col-12 position-relative">
                                            <span className=" position-absolute bottom-0 p-2">
                                                <SVG src={kusurSvg} />
                                            </span>
                                            <label className="form-label text-nowrap fs-4">Kusur</label>
                                            <input
                                                type="text"
                                                readOnly={true}
                                                placeholder="0"
                                                value={kusur}
                                                className="h-100 text-end px-2 fs-1"
                                            />
                                        </div>
                                    </div>
                                    {/* naplata */}
                                    <div className="user-select-none col-2">
                                        <div className="user-select-none col-12 position-relative">
                                            <span className="text-danger position-absolute bottom-0 p-2">
                                                <SVG src={cart} />
                                            </span>
                                            <label className="form-label text-nowrap fs-4">
                                                Naplatiti
                                            </label>
                                            <input
                                                type="text"
                                                readOnly={true}
                                                placeholder="0"
                                                value={numberWithCommas(totalIznos)}
                                                className="h-100 text-end text-danger px-2 fs-1"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </ul>
                        </div>
                    </div>
                )}
                {/* modal izaberi smjenu i skladiste */}
                <Modal
                    toggle={toggle}
                    isOpen={Boolean(modalOpen)}
                    animation="false"
                    centered
                >
                    <ModalHeader toggle={toggle}>Prijava za kasu</ModalHeader>
                    <ModalBody>
                        <Row>
                            <div className="col-10 d-flex flex-column gap-2">
                                <div className="col-12">
                                    <div
                                        className={
                                            errorInputRepository
                                                ? "inputWithError d-flex"
                                                : "field-container d-flex gap-1"
                                        }
                                    >
                                        <label className="fs-6 m-0 text-nowrap minWidth120">
                                            Skladište:
                                        </label>
                                        {/* autokomplit za skladiste */}
                                        <Autocomplete
                                            //ukoliko nema ove funkcije, u konzoli izbacuje upozorenje da nije vrijednost jedinstvena
                                            isOptionEqualToValue={(option, value) =>
                                                option.id === value.id
                                            }
                                            {...defaultProps}
                                            className="w-100"
                                            value={repository}
                                            disablePortal={true}
                                            onFocus={() => {
                                                setRepositoryFocus(true);
                                                unosIspravanDo("SKLAID");
                                            }}
                                            // ako se promijeni skladiste, uzima podatke za novo izabrano skladiste
                                            onChange={(event, newValue) => {
                                                // console.log(newValue);
                                                setSifraSkladista(newValue.SIFRA);
                                                setNazivSkladista(newValue.NAZIV);
                                                setPrometForm((prev) => ({
                                                    ...prev,
                                                    SKLAID: newValue && newValue != null ? newValue.ID : "",
                                                    VRSTA_SKLADISTA:
                                                        newValue && newValue != null
                                                            ? newValue.VRSTA_SKLADISTA
                                                            : "",
                                                }));
                                                setRepository(newValue);
                                                repositoryRef.current.focus();
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    autoFocus
                                                    className="clients-textinput"
                                                    maxLength="295"
                                                    name="SKLAID"
                                                    tabIndex="1"
                                                    ref={repositoryRef}
                                                    //   onBlur={(e) => formCheck(e)}
                                                    placeholder="Izaberite skladište"
                                                    variant="standard"
                                                />
                                            )}
                                        />
                                    </div>
                                    {/* prikazivanje greske ukoliko skladiste nije izabrano */}
                                    {errorMsgRepository && (
                                        <div className="col-12 text-center gap-1">
                                            <span className="error-message">{errorMsgRepository}</span>
                                        </div>
                                    )}
                                </div>
                                <div className="col-12">
                                    <div
                                        className={
                                            errorMsgSmjena
                                                ? "inputWithError d-flex"
                                                : "field-container d-flex gap-1"
                                        }
                                    >
                                        <label className="fs-6 m-0 text-nowrap minWidth120">
                                            Smjena:
                                        </label>
                                        {/* autokomplit za skladiste */}
                                        <Autocomplete
                                            //ukoliko nema ove funkcije, u konzoli izbacuje upozorenje da nije vrijednost jedinstvena
                                            isOptionEqualToValue={(option, value) =>
                                                option.id === value.id
                                            }
                                            {...defaultPropsSmjena}
                                            className="w-100"
                                            value={smjena}
                                            disablePortal={true}
                                            onFocus={() => {
                                                // setRepositoryFocus(true);
                                                unosIspravanDo("SMJENA");
                                            }}
                                            // ako se promijeni skladiste, uzima podatke za novo izabrano skladiste
                                            onChange={(event, newValue) => {
                                                setPrometForm((prev) => ({
                                                    ...prev,
                                                    SMJENA: newValue.SIFRA,
                                                }));
                                                setSmjena(newValue);
                                                // fokus se mora vratiti inace tabIndex ne radi dobro
                                                smjenaRef.current.focus()
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    //   autoFocus
                                                    className="clients-textinput"
                                                    maxLength="295"
                                                    name="SMJENA"
                                                    tabIndex="2"
                                                    ref={smjenaRef}
                                                    //   onBlur={(e) => formCheck(e)}
                                                    placeholder="Izaberite smjenu"
                                                    variant="standard"
                                                />
                                            )}
                                        />
                                    </div>
                                    {/* prikazivanje greske ukoliko smjena nije izabrana */}
                                    {errorMsgSmjena && (
                                        <div className="col-12 text-center gap-1">
                                            <span className="error-message">{errorMsgSmjena}</span>
                                        </div>
                                    )}
                                </div>
                                <div className="col-12">
                                    <div
                                        className={
                                            errorDate
                                                ? "inputWithError d-flex justify-content-start gap-1"
                                                : "field-container d-flex justify-content-start gap-1"
                                        }
                                    >
                                        <label className="fs-6 m-0 text-nowrap minWidth120">
                                            Datum:
                                        </label>
                                        <input
                                            type="text"
                                            className="w-25"
                                            tabIndex="3"
                                            ref={dateRef}
                                            onFocus={() => {
                                                setDateFocus(true);
                                                unosIspravanDo("DPO");
                                                setTimeout(() => {
                                                    setPrometForm(prev => ({ ...prev, DPO: dayjs(todaysDate).format("DD.MM.YYYY") }))
                                                }, 500);
                                            }}
                                            placeholder=""
                                            name="DPO"
                                            value={prometForm.DPO}
                                            onChange={(e) => handleChangePromet(e)}
                                        />
                                    </div>
                                    {/* prikazivanje greske ukoliko je izabran los datum  */}
                                    {errorMsgDate && (
                                        <div className="col-6 text-center gap-1">
                                            <span className="error-message">{errorMsgDate}</span>
                                        </div>
                                    )}
                                </div>
                                <div className="col-12">
                                    <div className="field-container d-flex justify-content-start gap-1">
                                        <label className="fs-6 m-0 text-nowrap minWidth120">
                                            Depozit:
                                        </label>
                                        <NumericFormat
                                            type="text"
                                            className="w-25"
                                            maxLength="15"
                                            tabIndex="4"
                                            decimalScale={0}
                                            onFocus={() => {
                                                unosIspravanDo("DEPOZIT");
                                            }}
                                            placeholder=""
                                            name="DEPOZIT"
                                            value={prometForm?.DEPOZIT}
                                            onChange={(e) => {
                                                handleChangePromet(e);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="field-container d-flex justify-content-start gap-1">
                                        <label className="fs-6 m-0 text-nowrap minWidth120">
                                            Zaključenje:
                                        </label>
                                        <input
                                            type="text"
                                            className="w-25"
                                            tabIndex="5"
                                            onFocus={() => {
                                                setDateFocus(true);
                                                unosIspravanDo("ZAKLJUCENJE");
                                            }}
                                            placeholder=""
                                            name="ZAKLJUCENJE"
                                            value={prometForm.ZAKLJUCENJE}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                // provjeravamo da li je unesena vrijednost 'D' i 'A'
                                                if (/^[DA]*$/.test(value)) {
                                                    handleChangePromet(e);
                                                } else {
                                                    e.target.value = "";
                                                    setPrometForm((prev) => ({
                                                        ...prev,
                                                        ZAKLJUCENJE: "",
                                                    }));
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button tabIndex="6"
                            onClick={() => {
                                unosIspravanDo("NASTAVI");
                                if (unosIspravanDo("NASTAVI")) {
                                    prijavaNaKasu();
                                }
                            }}
                            color="primary"
                        >
                            Nastavi
                        </Button>
                    </ModalFooter>
                </Modal>
                {/* render za poruku nedovoljna kolicina lagera  */}
                <Modal isOpen={modalLagerErrorOpen} animation="false" centered>
                    <ModalHeader>Neispravan unos</ModalHeader>
                    <ModalBody>
                        <span>Nemate dovoljnu količinu na zalihama!</span>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            <button
                                onClick={() => {
                                    // kolicinaRef.current.focus();
                                    setModalLagerErrorOpen(false);
                                    let vrprom = form.VRPROM
                                    setForm((prev) => ({
                                        ...defaultForm,
                                        VRPROM: vrprom
                                    }));
                                    setArtikal(null)
                                }}
                                className="button-no"
                            >
                                Ok
                            </button>
                        </div>
                    </ModalFooter>
                </Modal>
                {/* render za poruku greska na editu  */}
                <Modal isOpen={Boolean(modalEditErrorOpen)} animation="false" centered>
                    <ModalHeader>Neispravan unos</ModalHeader>
                    <ModalBody>
                        <span>
                            Ne možete mijenjati slog '{form.RBR}' koji već postoji u dokumentu!
                        </span>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            <button
                                onClick={() => {
                                    rbrRef.current.focus();
                                    setModalEditErrorOpen(false);
                                }}
                                className="button-no"
                            >
                                Ok
                            </button>
                        </div>
                    </ModalFooter>
                </Modal>
                {/* render za poruku ukoliko stavka nije izabrana*/}
                <Modal
                    isOpen={Boolean(modalErrorDeleteItemOpen)}
                    animation="false"
                    centered
                >
                    <ModalHeader>Neispravan unos</ModalHeader>
                    <ModalBody>
                        <span>Morate odabrati stavku za brisanje!</span>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            <button
                                onClick={() => setModalErrorDeleteItemOpen(false)}
                                className="button-no"
                            >
                                Ok
                            </button>
                        </div>
                    </ModalFooter>
                </Modal>

                {/* render za poruku */}
                <div>
                    <Modal isOpen={Boolean(modalErrorDeleteDocumentOpen)} centered>
                        <ModalHeader>Neispravan unos!</ModalHeader>
                        <ModalBody>
                            <span>{`Morate odabrati dokument za brisanje!`}</span>
                        </ModalBody>
                        <ModalFooter style={{ justifyContent: "center" }}>
                            <div>
                                <button
                                    onClick={() => setModalErrorDeleteDocumentOpen(false)}
                                    className="button-yes"
                                >
                                    Ok
                                </button>
                            </div>
                        </ModalFooter>
                    </Modal>
                </div>

                {/* render za poruku */}
                <div>
                    <Modal
                        isOpen={Boolean(modalDeleteDokument)}
                        //  modalTransition={{ timeout: 2000 }}
                        centered
                    >
                        <ModalHeader>Brisanje dokumenta</ModalHeader>
                        <ModalBody>
                            <span>{`Da li želite da obrišete dokument broj ${prometForm.DOK} ? `}</span>
                        </ModalBody>
                        <ModalFooter>
                            <div>
                                <button onClick={() => setModalDeleteDokument(false)} className="button-no">
                                    Ne
                                </button>
                                <button
                                    onClick={() => onDelete(prometForm.SKLAID, form.VRPROM, prometForm.DOK)}
                                    className="button-yes"
                                >
                                    Da
                                </button>
                            </div>
                        </ModalFooter>
                    </Modal>
                </div>

                {/* render za poruku brisanje izabrane stavke  */}
                <Modal isOpen={Boolean(modalDeleteItem)} animation="false" centered>
                    <ModalHeader>Brisanje stavke</ModalHeader>
                    <ModalBody>
                        <span>Da li želite da obrišete stavku broj '{form.RBR}' ?</span>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            <button
                                onClick={() => setModalDeleteItem(false)}
                                className="button-no"
                            >
                                Ne
                            </button>
                            <button
                                onClick={() => onDeleteItems(prometForm.ID, form.RBR)}
                                className="button-yes"
                            >
                                Da
                            </button>
                        </div>
                    </ModalFooter>
                </Modal>
                {/* render za poruku greska na save  */}
                <Modal isOpen={Boolean(modalErrorEditOpen)} animation="false" centered>
                    <ModalHeader>Neispravan unos</ModalHeader>
                    <ModalBody>
                        <span>
                            Ta stavka raćuna postoji uzmite drugi broj računa ili stavke.
                        </span>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            <button
                                onClick={() => {
                                    setModalErrorEditOpen(false);
                                    rbrRef.current.focus();
                                }}
                                className="button-no"
                            >
                                Ok
                            </button>
                        </div>
                    </ModalFooter>
                </Modal>
                {/* render koji se otvara na 0 + ENTER za kasu  */}
                <Modal isOpen={modalKasaOpen} animation="false" centered>
                    <ModalHeader>Izbor vrste plaćanja</ModalHeader>
                    <ModalBody>
                        <div className="d-flex justify-content-end flex-column gap-1">
                            <div className="d-flex justify-content-end gap-2">
                                <span className="text-danger fw-bold">Ukupno:</span>
                                <input
                                    className="text-end text-danger"
                                    defaultValue={totalIznos}
                                    readOnly
                                />
                            </div>
                            <div className="d-flex justify-content-end gap-2">
                                <span>Gotovina:</span>
                                <input
                                    maxLength={8}
                                    ref={gotovinaRef}
                                    className="text-end"
                                    onChange={(e) => {
                                        setForm((prev) => ({
                                            ...prev,
                                            GOTOVINA: e.target.value,
                                        }));
                                    }}
                                    onFocus={() => {
                                        setTotalnoUplaceno(
                                            Number(form.GOTOVINA) +
                                            Number(form.VIRMAN) +
                                            Number(form.CEK) +
                                            Number(form.KARTICA)
                                        );
                                    }}
                                    defaultValue={form.GOTOVINA}
                                />
                            </div>
                            <div className="d-flex justify-content-end gap-2">
                                <span>Virman:</span>
                                <input
                                    maxLength={8}
                                    className="text-end"
                                    onChange={(e) => {
                                        setForm((prev) => ({
                                            ...prev,
                                            VIRMAN: e.target.value,
                                        }));
                                    }}
                                    onFocus={() => {
                                        setTotalnoUplaceno(
                                            Number(form.GOTOVINA) +
                                            Number(form.VIRMAN) +
                                            Number(form.CEK) +
                                            Number(form.KARTICA)
                                        );
                                    }}
                                    defaultValue={form.VIRMAN}
                                />
                            </div>
                            <div className="d-flex justify-content-end gap-2">
                                <span>Ćek:</span>
                                <input
                                    maxLength={8}
                                    className="text-end"
                                    onChange={(e) => {
                                        setForm((prev) => ({
                                            ...prev,
                                            CEK: e.target.value,
                                        }));
                                    }}
                                    onFocus={() => {
                                        setTotalnoUplaceno(
                                            Number(form.GOTOVINA) +
                                            Number(form.VIRMAN) +
                                            Number(form.CEK) +
                                            Number(form.KARTICA)
                                        );
                                    }}
                                    defaultValue={form.CEK}
                                />
                            </div>
                            <div className="d-flex justify-content-end gap-2">
                                <span>Kartica:</span>
                                <input
                                    maxLength={8}
                                    className="text-end"
                                    onChange={(e) => {
                                        setForm((prev) => ({
                                            ...prev,
                                            KARTICA: e.target.value,
                                        }));
                                    }}
                                    onFocus={() => {
                                        setTotalnoUplaceno(
                                            Number(form.GOTOVINA) +
                                            Number(form.VIRMAN) +
                                            Number(form.CEK) +
                                            Number(form.KARTICA)
                                        );
                                    }}
                                    defaultValue={form.KARTICA}
                                />
                            </div>
                            <div className="d-flex justify-content-end gap-2">
                                <span className="text-success fw-bold">Kusur:</span>
                                <input
                                    readOnly
                                    className="text-end text-success"
                                    onFocus={(e) => {
                                        setTotalnoUplaceno(
                                            Number(form.GOTOVINA) +
                                            Number(form.VIRMAN) +
                                            Number(form.CEK) +
                                            Number(form.KARTICA)
                                        );

                                        let sabereniKusur = roundTO(totalnoUplaceno - totalIznos, 2);

                                        setForm((prev) => ({
                                            ...prev,
                                            KUSUR: sabereniKusur,
                                        }));
                                        //   console.log(e.target.value, "etargetvalue");
                                        //   console.log(sabereniKusur, "sabereniKusur");
                                        setKusur(sabereniKusur);
                                    }}
                                    value={kusur}
                                />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <>
                            <Button
                                onFocus={() => {
                                    if (form.KUSUR < 0) {
                                        gotovinaRef.current.focus();
                                        setModalErrorKusurOpen(true);
                                    } else {
                                        setModalKasaOpen(false);
                                        setTotalnoUplaceno(0);
                                        getPrometFirstFreeNumber();
                                        getPrometFirstFreeNumberRbr();
                                        setTimeout(() => {
                                            rbrRef.current.focus();
                                        }, 150);
                                    }
                                }}
                            >
                                Nastavi
                            </Button>
                            <Button
                                onClick={() => {
                                    setModalKasaOpen(false);
                                    setTotalnoUplaceno(0);
                                    setKusur(0);
                                    setKasaModalToDefault();
                                }}
                            >
                                Izlaz
                            </Button>
                        </>
                    </ModalFooter>
                </Modal>
                {/* render za poruku greska kada je kusur u minusu  */}
                <Modal isOpen={Boolean(modalErrorKusurOpen)} animation="false" centered>
                    <ModalHeader>Neispravan unos</ModalHeader>
                    <ModalBody>
                        <span>
                            Ukupan iznos ne može biti manji od iznosa računa({totalIznos}).
                        </span>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            <button
                                onClick={() => {
                                    setModalErrorKusurOpen(false);
                                    // rbrRef.current.focus();
                                    setTimeout(() => {
                                        gotovinaRef.current.focus();
                                    }, 50);
                                }}
                                className="button-no"
                            >
                                Ok
                            </button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    export default Kasa;
