// importovanje komponenti
import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { API } from "../constants";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "../api/axios";
import { Spinner } from "reactstrap";
import {
  getCompanyData,
  defaultComponentOptions,
} from "../components/utilities";
import { NumericFormat } from "react-number-format";
import { useWindowSize } from "../components/useWindowSize";

// komponenta koju kreiramo
function Komitenti() {
  // data provides access to your row data
  //const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data.NAZIV, null, 0)}</pre>;
  const windowSize = useWindowSize();
  // definisanje konstanti koje koristimo za fokusiranje polja
  const codeRef = useRef();
  const nameRef = useRef();
  const entityRef = useRef();
  const vatNumberRef = useRef();
  const placeRef = useRef();
  const vatPayerRef = useRef();
  const limitRef = useRef();

  // definisanje stejtova i prva inicijalizacija; objekat i funkcija za taj objekat
  const [client, setClient] = useState(null);
  const [perPage, setPerPage] = useState(10);
  const [VATPayer, setVATPayer] = useState(null);
  const [selectData, setSelectData] = useState([]);
  const [searchInput, setSearchInput] = useState([]);
  const [pending, setPending] = React.useState(false);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const [modalErrorOpenData, setModalErrorOpenData] = useState(false);
  const [modalErrorAuthorizationData, setModalErrorAuthorizationOpen] =
    useState(false);
  const [modalDeleteItem, setModalDeleteItem] = useState(false);

  // povlacenje podataka iz lokal storidza
  const token =
    typeof window !== "undefined" ? localStorage.getItem("accessToken") : null;

  // error row inputs
  const [errorInputCode, setErrorInputCode] = useState(false);
  const [errorInputName, setErrorInputName] = useState(false);
  const [errorInputEntity, setErrorInputEntity] = useState(false);
  const [errorInputVATPayer, setErrorInputVATPayer] = useState(false);
  const [errorInputVATNumber, setErrorInputVATNumber] = useState(false);
  const [modalErrorKomitenti, setModalErrorKomitenti] = useState(false);

  // error messages inputs
  const [errorMsgCode, setErrorMsgCode] = useState("");
  const [errorMsgName, setErrorMsgName] = useState("");
  const [errorMsgEntity, setErrorMsgEntity] = useState("");
  const [errorMsgVATPayer, setErrorMsgVATPayer] = useState("");
  const [errorMsgVATNumber, setErrorMsgVATNumber] = useState("");
  const [place, setPlace] = useState(null);
  const [spinner, setSpinner] = useState(false);

  // definisanje stejta(objekat i funkcija za taj objekat), prva inicijalizacija
  const [form, setForm] = useState({
    SIFRA: "",
    NAZIV: "",
    MESTO: "",
    ADRESA: "",
    ZIRORAC: "",
    ENTITET: "",
    MESTO: "",
    TEL: "",
    LIMITKUPCA: "",
    PDVBROJ: "",
    PDV: "",
    IDENTBROJ: "",
    stavkaPronadjena: false,
  });

  // funkcija za povlacenje podataka o preduzecu
  getCompanyData(form);

  // funkcija koja se poziva klikom na dugme 'Sacuvaj'
  const onSubmit = () => {
    // uslov: ako nisu unesena obavezna polja, zaustavlja upis
    if (!unosIspravanDo("UPIS")) return;


    // let postoji = client.some((c) => c.SIFRA === form.SIFRA)

    // // uslov: ako konto vec postoji, nece proci upis 
    // if (postoji && !form.stavkaPronadjena) return setModalErrorKomitenti(true)

    // poziva api za upis
    // osvezavanje polja, fokus na polje SIFRA, poziva funkciju getTableData(za upis u mrezu)
    form.SIFKOR = form.userCode;
    fetch(API + `/komitenti/new/${form.companyCode}`, {
      method: "POST",
      body: JSON.stringify(form),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    }).then((response) => {
      response.json();
      setForm({
        SIFRA: "",
        NAZIV: "",
        MESTO: "",
        ADRESA: "",
        ZIRORAC: "",
        ENTITET: "",
        MESTO: "",
        TEL: "",
        LIMITKUPCA: "",
        PDVBROJ: "",
        PDV: "",
        IDENTBROJ: "",
        stavkaPronadjena: false,
      });
      setPlace(null);
      setVATPayer(null);
      codeRef.current.focus();
      getTableData();
    });
  };

  // funkcija koja se poziva pri prvoj inicijalizaciji
  // api za mrezu
  // u responsu puni mrezu(pomocu funkcije setIme) i puni ukupan broj stranica(setTotal)
  const getTableData = () => {
    fetch(API + `/komitenti/all/${form.companyCode}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // popuni mrezu i postavi podatke o komintentima
        setClient(data.result);
        setPending(false);
      });
  };

  // poziva se inicijalno pri otvaranju forma i poziva api za popunjavanje polja
  useEffect(() => {
    getPlacesData();
  }, []);

  // prilikom pocetne inicijalizacije postavlja prvi slobodan broj
  useEffect(() => {
    const timeout = setTimeout(() => {
      getClientFirstFreeNumberSifra();
      getTableData();
    }, 100);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  // funkcija za pronalazenje prvog slobodnog broja
  // poziva api
  // u responsu stejt setForm poziva funkciju za pronalazenje prethodne sifre(iz data) i na osnovu nje odredjuje koja je sljedeca prva slobodna sifra
  const getClientFirstFreeNumberSifra = () => {
    fetch(API + `/komitenti/firstFreeNumberSifra`, {
      method: "POST",
      body: JSON.stringify(form),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setForm((prev) => ({ ...prev, SIFRA: Number(data[0].SIFRA) }));
      });
  };

  // funkcija za povlacenje podataka iz baze
  const getPlacesData = () => {
    fetch(API + `/mjesta/all`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setSelectData(data);
      });
  };

  // funkcija za brisanje koja se poziva klikom na dugme 'Delete'
  //  api za brisanje
  // u responsu osvjezava polja, daje prvi slobodan broj za sifru, fokus na polje sifra, osvjezava mrezu
  const onDelete = (ID) => {
    // provjera ovlascenja za brisanje
    if (form.ovlfakt == 4 || form.ovlfik == 4)
      return setModalErrorAuthorizationOpen(true);

    fetch(API + "/komitenti/delete/" + ID, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    }).then((response) => {
      response.json();
      setForm({
        SIFRA: "",
        NAZIV: "",
        MESTO: "",
        ADRESA: "",
        ZIRORAC: "",
        ENTITET: "",
        MESTO: "",
        TEL: "",
        LIMITKUPCA: "",
        PDVBROJ: "",
        PDV: "",
        IDENTBROJ: "",
        stavkaPronadjena: false,
      });
      setPlace(null);
      setVATPayer(null);
      codeRef.current.focus();
      getTableData();
    });
  };

  // funkcija koja se poziva klikom na dugme Edit
  // postavlja fokus na polje NAZIV
  // popunjava polja podacima iz mreze
  const onEdit = (item) => {
    item.stavkaPronadjena = true;
    setPlace(null);
    selectData.map((place) => {
      if (place.SIFRA == item.MESTO) {
        setPlace(place);
      }
    });

    setForm(item);
    const timeout = setTimeout(() => {
      nameRef.current.focus();
    }, 100);
    return () => {
      clearTimeout(timeout);
    };
  };

  // funkcija koja se poziva klikom na dugme 'Stampa'
  const onPrint = () => {
    setSpinner(true);
    if (client && client.length === 0) {
      setSpinner(false);
      setModalErrorOpenData(true);
    } else {
      // uslov preko koga saljemo naziv preduzeca sa frontenda na bekend
      // ako je objekat form popunjen, napuni polje NAZIV_PRED iz lokal storidza
      // poziva api za stampu, otvara izvjestaj(API + data.link)
      if (form) {
        form.NAZIV_PRED = form.companyName;
      }

      fetch(API + `/komitenti/print`, {
        method: "POST",
        body: JSON.stringify(form),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (typeof window != "undefined") {
            setTimeout(() => {
              window.open(API + data.link);
            }, 500);
          }
          setSpinner(false);
        });
    }
  };

  // funkcija koja se poziva prilikom promjene vrijednosti u inputu
  const handleChange = (event) => {
    const target = event.target;
    let value = target.value;
    const name = event.target.name;

    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // uslov: ako nije upisana ni vrijednost, izbaci poruku, setuje crveni okvir i fokusira polje
  // u suprotnom prolazi dalje
  const checkCodeRequired = (value) => {
    if (!value || value == 0) {
      setErrorMsgCode("Šifra je obavezna!");
      setErrorInputCode(true);
      codeRef.current.focus();
      return false;
    } else {
      setErrorMsgCode(null);
      setErrorInputCode(false);
      return true;
    }
  };

  // uslov: ako nije upisana ni vrijednost, izbaci poruku, setuje crveni okvir i fokusira polje
  // u suprotnom prolazi dalje
  const checkNameRequired = (value) => {
    if (!value || value.length == 0) {
      setErrorMsgName("Naziv je obavezan!");
      setErrorInputName(true);
      nameRef.current.focus();
      return false;
    } else {
      setErrorMsgName(null);
      setErrorInputName(false);
      return true;
    }
  };

  // uslov: ako nije upisana ni vrijednost, izbaci poruku, setuje crveni okvir i fokusira polje
  // u suprotnom prolazi dalje
  const checkEntityRequired = (value) => {
    if (!value || value.length == 0) {
      setErrorMsgEntity("Entitet je obavezan!");
      setErrorInputEntity(true);
      entityRef.current.focus();
      return false;
    } else {
      setErrorMsgEntity(null);
      setErrorInputEntity(false);
      return true;
    }
  };

  // uslov:  ako nije upisana ni vrijednost, izbaci poruku, setuje crveni okvir i fokusira polje
  // u suprotnom prolazi dalje
  const checkVATNumberRequired = (value) => {
    if (!value || value.length == 0) {
      setErrorMsgVATNumber("PDV broj je obavezan!");
      setErrorInputVATNumber(true);
      vatNumberRef.current.focus();
      return false;
    } else {
      setErrorMsgVATNumber(null);
      setErrorInputVATNumber(false);
      return true;
    }
  };

  // uslov: ako nije upisana ni vrijednost, izbaci poruku, setuje crveni okvir i fokusira polje
  // u suprotnom prolazi dalje
  const checkVATPayerRequired = (value) => {
    if (!value || value.length == 0) {
      setErrorMsgVATPayer("Porez je obavezan!");
      setErrorInputVATPayer(true);
      vatPayerRef.current.focus();
      return false;
    } else {
      setErrorMsgVATPayer(null);
      setErrorInputVATPayer(false);
      return true;
    }
  };

  // funkcija za kontrolu obaveznih polja
  function unosIspravanDo(name) {
    if (name === "SIFRA") {
      return true;
    }

    if (!checkCodeRequired(form.SIFRA)) {
      return false;
    }

    if (name === "NAZIV") {
      return true;
    }

    if (!checkNameRequired(form.NAZIV)) {
      return false;
    }

    if (name === "ADRESA") {
      return true;
    }

    if (name === "ZIRORAC") {
      return true;
    }

    if (name === "ENTITET") {
      return true;
    }

    if (!checkEntityRequired(form.ENTITET)) {
      return false;
    }

    if (name === "MESTO") {
      return true;
    }

    if (name === "TEL") {
      return true;
    }

    if (name === "LIMITKUPCA") {
      return true;
    }

    if (name === "PDV") {
      return true;
    }

    if (!checkVATPayerRequired(form.PDV)) {
      return false;
    }

    if (name === "PDVBROJ") {
      return true;
    }

    // uslov: ako je komitent pdv obveznik, polje pdv broj je obavezno
    if (form.PDV === "D") {
      if (!checkVATNumberRequired(form.PDVBROJ)) {
        return false;
      }
    }

    if (name === "IDENTBROJ") {
      return true;
    }

    return true;
  }

  // propsovi za auto komplit  - mjesto
  const defaultProps = {
    options: selectData,
    getOptionLabel: (option) =>
      option ? `${option.SIFRA} ${option.NAZIV}` : "",
  };

  //  Internally, customStyles will deep merges your customStyles with the default styling.
  // const customStyles = {
  //   rows: {
  //     style: {},
  //   },
  //   headCells: {},
  //   cells: {
  //     style: {
  //       minWidth: "50px !important",
  //     },
  //   },
  // };

  // definisu se polja u tabeli i popunjavaju
  const columns = [
    {
      name: "Šifra",
      selector: (row) => row.SIFRA,
      // sirina polja po rezolucijama

      width:
        windowSize.width > 1920
          ? "100px"
          : windowSize.width > 1680
            ? "100px"
            : windowSize.width > 1600
              ? "100px"
              : windowSize.width > 1440
                ? "100px"
                : windowSize.width > 1280
                  ? "80px"
                  : windowSize.width > 1024
                    ? "70px"
                    : "80px",
      sortable: true,
      right: "boolean",
      cell: (row) => (
        <>
          <span id="media-query-rows">{row.SIFRA}</span>
        </>
      ),
    },
    {
      name: "Naziv",
      selector: (row) => row.NAZIV,
      // sirina polja po rezolucijama
      width:
      windowSize.width > 2048 ? '470px'
        : windowSize.width > 1920
          ? "420px"
          : windowSize.width > 1680
            ? "300px"
            : windowSize.width > 1600
              ? "250px"
              : windowSize.width > 1440
                ? "220px"
                : windowSize.width > 1280
                  ? "180px"
                  : windowSize.width > 1024
                    ? "180px"
                    : "150px",
      sortable: true,
      cell: (row) => (
        <>
          <span id="media-query-rows-text">
            {row.NAZIV}
          </span>
        </>
      ),
    },
    {
      name: "Mjesto",
      selector: (row) => row.mestoNaziv,
      // sirina polja po rezolucijama
      width:
      windowSize.width > 2048 ? '300px'
        : windowSize.width > 1920
          ? "250px"
          : windowSize.width > 1680
            ? "130px"
            : windowSize.width > 1600
              ? "130px"
              : windowSize.width > 1440
                ? "130px"
                : windowSize.width > 1280
                  ? "130px"
                  : windowSize.width > 1024
                    ? "180px"
                    : "150px",
      sortable: true,
      cell: (row) => (
        <>
          <span id="media-query-rows-text">{row.mestoNaziv}</span>
        </>
      ),
    },
    {
      name: "Adresa",
      selector: (row) => row.ADRESA,
      // sirina polja po rezolucijama
      width:
      windowSize.width > 2048 ? '400px'
        : windowSize.width > 1920
          ? "350px"
          : windowSize.width > 1680
            ? "200px"
            : windowSize.width > 1600
              ? "270px"
              : windowSize.width > 1440
                ? "270px"
                : windowSize.width > 1280
                  ? "170px"
                  : windowSize.width > 1024
                    ? "170px"
                    : "150px",
      sortable: true,
      cell: (row) => (
        <>
          <span id="media-query-rows-text">{row.ADRESA}</span>
        </>
      ),
    },
    {
      name: "Žiroračun",
      selector: (row) => row.ZIRORAC,
      // sirina polja po rezolucijama
      width:
      windowSize.width > 2048 ? '420px'
        : windowSize.width > 1920
          ? "170px"
          : windowSize.width > 1680
            ? "300px"
            : windowSize.width > 1600
              ? "200px"
              : windowSize.width > 1440
                ? "150px"
                : windowSize.width > 1280
                  ? "150px"
                  : windowSize.width > 1024
                    ? "150px"
                    : "150px",
      sortable: true,
      cell: (row) => (
        <>
          <span id="media-query-rows-text">{row.ZIRORAC}</span>
        </>
      ),
    },
    {
      name: "Pdv broj",
      selector: (row) => row.PDVBROJ,
      // sirina polja po rezolucijama
      width:
        windowSize.width > 1920
          ? "170px"
          : windowSize.width > 1680
            ? "150px"
            : windowSize.width > 1600
              ? "150px"
              : windowSize.width > 1440
                ? "150px"
                : windowSize.width > 1280
                  ? "150px"
                  : windowSize.width > 1024
                    ? "100px"
                    : "100px",
      sortable: true,
      cell: (row) => (
        <>
          <span id="media-query-rows-text">{row.PDVBROJ}</span>
        </>
      ),
    },
    {
      name: "ident. broj",
      selector: (row) => row.IDENTBROJ,
      // sirina polja po rezolucijama
      width:
        windowSize.width > 1920
          ? "170px"
          : windowSize.width > 1680
            ? "150px"
            : windowSize.width > 1600
              ? "150px"
              : windowSize.width > 1440
                ? "150px"
                : windowSize.width > 1280
                  ? "150px"
                  : windowSize.width > 1024
                    ? "100px"
                    : "100px",
      sortable: true,
      cell: (row) => (
        <>
          <span id="media-query-rows-text">{row.IDENTBROJ}</span>
        </>
      ),
    },
    {
      name: "Action",
      right: 'boolean',
      cell: (row) => (
        <>
          <span
            onClick={() => onEdit(row)}
            id="media-query-table-buttons"
            className="btn btn-primary nav flex-column flex-lg-row justify-content-end"
          >
            Edit
          </span>
          {"    "}
          <span
            onClick={() => { onEdit(row); setModalDeleteItem(true) }}
            id="media-query-table-buttons"
            className="btn btn-danger nav flex-column flex-lg-row justify-content-end"
          >
            Brisanje
          </span>
        </>
      ),
    },
  ];


  // funkcija za pretragu iz mreze
  // salje zahtjev na bekend prema upitu sa parametrima koje posaljemo
  // u responsu vraca podatke koje dobio iz upita
  const searchChange = (e) => {
    e.preventDefault();
    const searchInput = e.target.value;
    setSearchInput(searchInput);
    const fetchData = async () => {
      const res = await axios.get(
        `${API}/komitenti/query/${form.companyCode}?q=${searchInput}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setClient(res.data);
    };

    // uslov za za slanje zahtjeva za povlacenje podataka, prazan zbog prikazivanja pocetne vrijednosti fetchData() a vece od 1 , kada se upit mijenja
    if (searchInput.length === 0 || searchInput.length >= 1) fetchData();
  };

  return (
    <div>
      {/* Kontejner za naslov  */}

      <div className="title-container">
        {/* Naziv registra */}
        <header className="title-header">
          <h1 id="media-query-title">Komitenti</h1>
        </header>
        {/* Klasa za ikonice, nalazi se u istom redu kao i naziv, ikonice su formirane kao lista */}

        <div className="naslov-ikonice">
          <ul className="nav flex-column flex-lg-row justify-content-end">
            <li className="nav-item toolbaritem">
              <input
                type="image"
                alt="save"
                className="dugme-sacuvaj"
                title="Sačuvaj"
                tabIndex="12"
                src={require("./../assets/images/floppydisk.png")}
                onFocus={() => onSubmit()}
              />
            </li>

            <li className="nav-item toolbaritem">
              <input
                type="image"
                alt="print"
                className="dugme-stampa"
                title="Štampa"
                tabIndex="13"
                src={require("./../assets/images/printer.png")}
                onClick={onPrint}
              />
            </li>
          </ul>
        </div>
      </div>
      {/* Kontejner pune sirine */}
      <Container fluid>
        <Row>
          <div className="products-form">
            <Row>
              {/* Stilovi koji ce se primjenjivati na svim poljima */}
              <div
                className="col-6"
                style={{
                  display: "flex",
                  paddingRight: "0px",
                  paddingLeft: "-1px",
                  marginRight: "auto",
                  marginLeft: "0px",
                }}
              >
                {/* Sirina polja */}
                <div className="col-1">
                  <div className="col-form-right">
                    {/* Provjera da li je polje popunjeno jer je obavezno */}
                    <div
                      className={
                        errorInputCode ? "inputWithError" : "field-container-number"
                      }
                      style={{ marginRight: "3px", marginBottom: "0px" }}
                    >
                      <label id="media-query-label">Šifra</label>
                      <NumericFormat
                        type="text"
                        className='field-alignment p-1'
                        decimalScale={0}
                        getInputRef={codeRef}
                        id="media-query-input-text"
                        tabIndex="1"
                        maxLength="10"
                        autoFocus={true}
                        placeholder="šifra"
                        name="SIFRA"
                        value={form?.SIFRA}
                        onFocus={() => {
                          getClientFirstFreeNumberSifra();
                          unosIspravanDo("SIFRA");
                        }}
                        // Ako je izmjena na polju
                        onChange={(e) => handleChange(e)}
                        onClick={() => {
                          // ako je kliknuto na dugme Edit, a zatim na sifru - cisti polja
                          setForm((prev) => ({
                            ...prev,
                            NAZIV: "",
                            MESTO: "",
                            ADRESA: "",
                            ZIRORAC: "",
                            ENTITET: "",
                            MESTO: "",
                            TEL: "",
                            LIMITKUPCA: "",
                            PDVBROJ: "",
                            PDV: "",
                            IDENTBROJ: "",
                            stavkaPronadjena: false,
                          }));
                          setPlace(null);
                        }}
                      />
                      {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                      {errorMsgCode ? (
                        <span
                          style={{
                            width: "100%",
                            color: "red",
                            fontSize: "11px",
                            fontFamily: "cursive",
                          }}
                        >
                          {errorMsgCode}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>

                {/* Sirina polja */}
                <div className="col-11">
                  <div
                    className={errorInputName ? "inputWithError" : "field-container"}
                    style={{ marginRight: "3px" }}
                  >
                    <label id="media-query-label">Naziv</label>
                    <input
                      type="text"
                      id="media-query-input-text"
                      maxLength="200"
                      tabIndex="2"
                      autoComplete="off"
                      placeholder="naziv"
                      name="NAZIV"
                      ref={nameRef}
                      value={form?.NAZIV}
                      onFocus={() => {
                        unosIspravanDo("NAZIV");
                        // uslov za citanje sloga po sifri
                        if (unosIspravanDo("NAZIV")) {
                          let selectedItem = []
                          selectedItem = client.filter((c) => c.SIFRA == form.SIFRA)
                          if (selectedItem.length > 0) {
                            onEdit(selectedItem[0])
                          }
                        }
                      }}
                      // Ako je izmjena na polju
                      onChange={(e) => handleChange(e)}
                    />
                    {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                    {errorMsgName ? (
                      <span
                        // className="w-100 color-red fs-2  cursive"
                        style={{
                          width: "100%",
                          color: "red",
                          fontSize: "11px",
                          fontFamily: "cursive",
                        }}
                      >
                        {errorMsgName}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div
                className="col-6"
                style={{
                  display: "flex",
                  paddingRight: "0px",
                  paddingLeft: "0px",
                  marginRight: "auto",
                  marginLeft: "0px",
                }}
              >
                {/* Sirina polja */}
                <div className="col-5">
                  <div
                    className="field-container"
                    style={{ marginRight: "3px" }}
                  >
                    <label id="media-query-label">Adresa</label>
                    <input
                      type="text"
                      id="media-query-input-text"
                      maxLength="70"
                      tabIndex="3"
                      placeholder="adresa"
                      name="ADRESA"
                      value={form ? form.ADRESA : ""}
                      onFocus={() => {
                        unosIspravanDo("ADRESA");
                      }}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                {/* Sirina polja */}
                <div className="col-5">
                  <div
                    className="field-container"
                    style={{ marginRight: "3px" }}
                  >
                    <label id="media-query-label">Žiroračun</label>
                    <input
                      type="text"
                      id="media-query-input-text"
                      maxLength="80"
                      tabIndex="4"
                      autoComplete="off"
                      placeholder="žiroračun"
                      name="ZIRORAC"
                      value={form ? form.ZIRORAC : ""}
                      onFocus={() => {
                        unosIspravanDo("ZIRORAC");
                      }}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                {/* Sirina polja */}

                <div className="col-2">
                  {/* Provjera da li je polje popunjeno jer je obavezno */}
                  <div
                    className={
                      errorInputEntity ? "inputWithError" : "field-container"
                    }
                    style={{ marginRight: "3px" }}
                  >
                    <label id="media-query-label">Entitet</label>
                    <select
                      id="media-query-input-text"
                      value={form?.ENTITET}
                      name="ENTITET"
                      ref={entityRef}
                      className="select-style"
                      placeholder="izaberite ENTITET"
                      onFocus={() => {
                        unosIspravanDo("ENTITET");
                      }}
                      // Ako je izmjena na polju
                      onChange={(e) => handleChange(e)}
                      tabIndex="5"
                    >
                      <option value={""}>{""}</option>
                      <option value={"RS"}>{"Republika Srpska"}</option>
                      <option value={"FBH"}>{"Federacija BiH"}</option>
                      <option value={"BD"}>{"Brčko Distrikt"}</option>
                      <option value={"INO"}>{"Inostranstvo"}</option>
                    </select>
                    {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                    {errorMsgEntity ? (
                      <span
                        style={{
                          width: "100%",
                          color: "red",
                          fontSize: "11px",
                          fontFamily: "cursive",
                        }}
                      >
                        {errorMsgEntity}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </Row>
            <Row>
              <div
                className="col-7"
                style={{
                  display: "flex",
                  paddingRight: "0px",
                  paddingLeft: "-1px",
                  marginRight: "auto",
                  marginLeft: "0px",
                }}
              >
                <div className="col-5">
                  <div
                    className="field-container"
                    style={{ marginRight: "3px" }}
                  >
                    <label id="media-query-label">Mjesto</label>
                    {/* Ako je Autocomplete  poyiva na odabri mjesta*/}
                    <Autocomplete
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      {...defaultProps}
                      value={place}
                      disablePortal={true}
                      onFocus={() => {
                        unosIspravanDo("MESTO");
                      }}
                      onChange={(event, newValue) => {
                        setForm((prev) => ({
                          ...prev,
                          MESTO:
                            newValue && newValue != null ? newValue.SIFRA : "",
                        }));
                        setPlace(newValue);
                        // fokus se mora vratiti inace tabIndex ne radi dobro
                        placeRef.current.focus();
                      }}
                      renderInput={(params) => (
                        <TextField
                          id="media-query"
                          {...params}
                          className="clients-textinput"
                          name="MESTO"
                          ref={placeRef}
                          tabIndex="6"
                          placeholder=" Izaberite mjesto"
                          variant="standard"
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="col-7">
                  <div
                    className="field-container"
                    style={{ marginRight: "3px" }}
                  >
                    <label id="media-query-label">Telefon</label>
                    <input
                      type="text"
                      id="media-query-input-text"
                      maxLength="100"
                      tabIndex="7"
                      placeholder="telefon"
                      name="TEL"
                      value={form ? form.TEL : ""}
                      onFocus={() => {
                        unosIspravanDo("TEL");
                      }}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
              </div>
              <div
                className="col-5"
                style={{
                  display: "flex",
                  paddingRight: "0px",
                  paddingLeft: "0px",
                  marginRight: "auto",
                  marginLeft: "0px",
                }}
              >
                <div className="col-2">
                  <div className="col-form-right">
                    <div
                      className="field-container"
                      style={{ marginRight: "3px" }} >
                      <label id="media-query-label">Limit k.</label>
                      <NumericFormat
                        type="text"
                        className='field-alignment p-1'
                        getInputRef={limitRef}
                        maxLength="12"
                        // broj decimala
                        decimalScale={2}
                        id="media-query-input-text"
                        tabIndex="8"
                        placeholder="limit kupca"
                        name="LIMITKUPCA"
                        value={form ? form.LIMITKUPCA : ""}
                        onFocus={() => {
                          unosIspravanDo("LIMITKUPCA");
                        }}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                </div>

                {/* Sirina polja */}
                <div className="col-2">
                  {/* Provjera da li je polje popunjeno jer je obavezno */}
                  <div
                    className={
                      errorInputVATPayer ? "inputWithError" : "field-container"
                    }
                    style={{ marginRight: "3px" }}
                  >
                    <label id="media-query-label">Pdv ob.</label>
                    <select
                      id="media-query-input-text"
                      value={form?.PDV}
                      name="PDV"
                      ref={vatPayerRef}
                      className="select-style"
                      placeholder="izaberite porez"
                      onChange={(e) => handleChange(e)}
                      tabIndex="9"
                      onFocus={() => {
                        unosIspravanDo("PDV");
                      }}
                      onClick={() => {
                        // klikom na polje PDV obveznik, cisti se polje PDV broj, poruka i crveni okvir se stavljaju na false(polje nije obavezno ukoliko komitent nije pdv obveznik)
                        setForm((prev) => ({
                          ...prev,
                          PDVBROJ: "",
                        }));
                        setErrorMsgVATNumber(null);
                        setErrorInputVATNumber(false);
                      }}
                    >
                      <option value={""}>{""}</option>
                      <option value={"D"}>{"Da"}</option>
                      <option value={"N"}>{"Ne"}</option>
                    </select>

                    {/* Ako  nije polje odabrano i ako je errorInputCode true , prikazuje poruku o gresci */}
                    {errorMsgVATPayer ? (
                      <span
                        style={{
                          width: "100%",
                          color: "red",
                          fontSize: "11px",
                          fontFamily: "cursive",
                        }}
                      >
                        {errorMsgVATPayer}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                {/* Sirina polja */}
                <div className="col-4">
                  {/* Provjera da li je polje popunjeno jer je obavezno */}
                  <div
                    className={
                      errorInputVATNumber ? "inputWithError" : "field-container"
                    }
                    style={{ marginRight: "3px" }}
                  >
                    <label id="media-query-label">Pdv br.</label>
                    <input
                      type="text"
                      id="media-query-input-text"
                      ref={vatNumberRef}
                      maxLength="20"
                      tabIndex="10"
                      placeholder="pdv broj"
                      name="PDVBROJ"
                      value={form?.PDVBROJ}
                      onFocus={() => {
                        unosIspravanDo("PDVBROJ");
                      }}
                      // Ako je izmjena na polju
                      onChange={(e) => handleChange(e)}
                    />
                    {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                    {errorMsgVATNumber ? (
                      <span
                        style={{
                          width: "100%",
                          color: "red",
                          fontSize: "11px",
                          fontFamily: "cursive",
                        }}
                      >
                        {errorMsgVATNumber}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="col-4">
                  <div
                    className="field-container"
                    style={{ marginRight: "3px" }}
                  >
                    <label id="media-query-label">Ident. br.</label>
                    <input
                      type="text"
                      id="media-query-input-text"
                      maxLength="20"
                      tabIndex="11"
                      placeholder="jib"
                      name="IDENTBROJ"
                      value={form ? form.IDENTBROJ : ""}
                      onFocus={() => {
                        unosIspravanDo("IDENTBROJ");
                      }}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
              </div>
            </Row>
          </div>
        </Row>
      </Container>

      {/* Stilovi i klase za polje za pretragu;  */}
      <div className="search">
        {/* vrsta inputa; poziva funkciju za pretragu(searchChange);  */}
        <input
          type="text"
          id="media-query-input-text"
          placeholder="Pretraga..."
          onChange={(e) => {
            // pretraga mreze(ukoliko je unesen bilo koji karakter, vrsi pretragu)
            if (searchInput.length >= 0) {
              searchChange(e);
            } else {
              getTableData();
            }
          }}
          value={searchInput}
        />

        {/* // <img src={Pencil} className='search-icon' /> */}
      </div>

          {/* Mreza */}
          <DataTable
            columns={columns}
            data={client ? client : []}
            pagination
            responsive
            highlightOnHover
            striped
            dense
            pointerOnHover
            //  customStyles={customStyles}
            //   expandableRows
            //    expandableRowsComponent={ExpandedComponent}
            paginationComponentOptions={defaultComponentOptions}
            paginationPerPage={[windowSize.height > 1300 ? 27
              : windowSize.height > 1270 ? 25
              : windowSize.height > 1246 ? 24
              : windowSize.height > 1222 ? 23
              : windowSize.height > 1200 ? 23
              : windowSize.height > 1000 ? 19
               : windowSize.height > 920 ? 16
              : windowSize.height > 900 ? 15
              : windowSize.height > 880 ? 14
              : windowSize.height > 820 ? 13
              : windowSize.height > 720 ? 11
                                  : 4]}
            progressPending={pending}
          />

      {/* render za poruku */ }
  <div>
    <Modal isOpen={Boolean(modalDeleteItem)} centered>
      <ModalHeader>Brisanje stavke</ModalHeader>
      <ModalBody>
        <span>{`Da li želite da obrišete stavku broj ${form.SIFRA} ? `}</span>
      </ModalBody>
      <ModalFooter>
        <div>
          <button
            onClick={() => { setModalDeleteItem(false); nameRef.current.focus() }}
            className="button-no">Ne
          </button>
          <button
            onClick={() => {
              onDelete(form.ID);
              setModalDeleteItem(false)
            }}
            className="button-yes">Da
          </button>
        </div>
      </ModalFooter>
    </Modal>
  </div>

  {/* render za poruku */ }
  <div>
    <Modal isOpen={Boolean(modalErrorKomitenti)} centered  >
      <ModalHeader>Upozorenje!</ModalHeader>
      <ModalBody>
        <span>{`Već postoji komitent sa tom šifrom!`}</span>
      </ModalBody>
      <ModalFooter>
        <div>
          <button onClick={() => { setModalErrorKomitenti(false); codeRef.current.focus() }} className="button-yes">Ok</button>
        </div>
      </ModalFooter>
    </Modal>
  </div>

  {/* render za poruku */ }
  <div>
    <Modal isOpen={Boolean(modalErrorAuthorizationData)} centered>
      <ModalHeader>Upozorenje!</ModalHeader>
      <ModalBody>
        <span>{`Nemate ovlašćenje za brisanje!`}</span>
      </ModalBody>
      <ModalFooter className="justify-content-center">
        <div>
          <button
            onClick={() => setModalErrorAuthorizationOpen(false)}
            className="button-yes"
          >
            Ok
          </button>
        </div>
      </ModalFooter>
    </Modal>
  </div>

  {/* Modalni form za gresku; prikazuje se ako nisu unijeta obavezna polja */ }
      <div>
        <Modal isOpen={Boolean(modalErrorOpen)} centered>
          <ModalHeader>Neispravan unos!</ModalHeader>
          <ModalBody>
            <span>{`Unesite obavezna polja!`}</span>
          </ModalBody>
          <ModalFooter className="justify-content-center">
            <div>
              {/* Dugme za zatvaranje modalnog forma */}
              <button
                onClick={() => setModalErrorOpen(false)}
                className="button-yes"
              >
                Ok
              </button>
            </div>
          </ModalFooter>
        </Modal>
      </div>

      <div>
        <Modal isOpen={Boolean(modalErrorOpenData)} centered>
          <ModalHeader>Nema podataka!</ModalHeader>
          <ModalFooter className="justify-content-center">
            <div>
              {/* Dugme za zatvaranje modalnog forma */}
              <button
                onClick={() => setModalErrorOpenData(false)}
                className="button-yes"
              >
                Ok
              </button>
            </div>
          </ModalFooter>
        </Modal>
      </div>

      <div>
        {spinner ? (
          <Spinner className="spinner" color="primary" id="loader" />
        ) : (
          ""
        )}
      </div>
    </div >
  );
}

export default Komitenti;
