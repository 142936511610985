// importovanje komponenti
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import { API } from "../../constants";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import SVG from "react-inlinesvg";
import { Spinner } from "reactstrap";
import moment from "moment";
import { Dropdown } from "../dropdown";
import {
  getMarza,
  getCompanyData,
  getIznosRabataIzlaz,
  numberWithCommas,
  numberWithCommasColumns,
  onNextClick,
  onLastClick,
  onPaginationClick,
  onPreviosClick,
  onFirstClick,
  calculateFinalPrice,
  calculateFinalSum,
  getRabvelFromValues,
  getMarzavelFromValues,
  roundTO,
  getPorezUIznosu,
  getFakcenMinusRabatUlaz,
  defaultPrometForm,
  defaultForm,
  numberWithCommasColumns3,
  getPageNumber,
  getIznosMarze,
  procenatNaOsnovuIznosa,
  iznosNaOsnovuProcenta,
  getNabavniIznos,
  getNabavnaCijena,
  getIznosMaloprodaje,
  getULPDV, kontrolaLagera
} from "../utilities";
import { NumericFormat } from "react-number-format";
import deleteDocument from "../../assets/delete_document.svg";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import "dayjs/locale/de";
// komponenta koju kreiramo
function Ulaz() {
  // definisanje referenci, stejtova i prva inicijalizacija; objekat i funkcija za taj objekat
  const kursRef = useRef();
  const dateDeliveryRef = useRef();
  const documentRef = useRef();
  const nomenRef = useRef();
  const dateRef = useRef();
  const clientsRef = useRef();
  const faktCenRef = useRef();
  const komtRef = useRef();
  const malcenRef = useRef();
  const jmRef = useRef();
  const repositoryRef = useRef();
  const rbrRef = useRef();
  const sifraRef = useRef();
  const kolicinaRef = useRef();
  const fiRef = useRef();
  const rabRef = useRef();
  const vrstaRef = useRef();
  const malcenPRef = useRef();
  const racunRef = useRef();
  const rabvelRef = useRef();
  const rabatRef = useRef();
  const trosakPRef = useRef();
  const trosakRef = useRef();
  const marzapRef = useRef();
  const cijenaRef = useRef();
  const iznosRef = useRef();

  let date = new Date();

  const [kalkulacija, setKalkulacija] = useState([]);
  const [vrsta, setVrsta] = useState(null);
  const [repository, setRepository] = useState(null);
  const [updateDocument, setUpdateDocument] = useState(false);
  const [clients, setClients] = useState(null);
  const [komt, setKomt] = useState(null);
  const [artikal, setArtikal] = useState(null);
  const [lager, setLager] = useState([]);
  const [selectData, setSelectData] = useState([]);
  const [selectDataNomen, setSelectDataNomen] = useState([]);
  const [selectDataVrsta, setSelectDataVrsta] = useState([]);
  const [selectDataClients, setSelectDataClients] = useState([]);
  const [endDate, setEndDate] = useState(date);
  const [currencyDate, setCurrencyDate] = useState(date);
  const [firstDocumentNumber, setFirstDocumentNumber] = useState(null);
  const [itemUpdate, setItemUpdate] = useState(false);
  const [sumNomenIznos, setSumNomenIznos] = useState(false);
  const [sumOsnovica, setSumOsnovica] = useState(false);
  const [sumProdajniIznos, setSumProdajniIznos] = useState(false);
  const [sumIznosRabata, setSumIznosRabata] = useState(false);
  const [sumIznosMarze, setSumIznosMarze] = useState(false);
  const [sumIznosPoreza, setSumIznosPoreza] = useState(false);

  const [errorInputRepository, setErrorInputRepository] = useState(false);
  const [errorInputClients, setErrorInputClients] = useState(false);
  const [errorInputVrsta, setErrorInputVrsta] = useState(false);
  const [errorInputDocument, setErrorInputDocument] = useState(false);
  const [errorInputRbr, setErrorInputRbr] = useState(false);
  const [errorDate, setErrorDate] = useState(false);
  const [errorDateDelivery, setErrorDateDelivery] = useState(false);
  const [errorInputJM, setErrorInputJM] = useState(false);
  const [errorInputMarza, setErrorInputMarza] = useState(false);
  const [errorInputMalcenP, setErrorInputMalcenP] = useState(false);
  const [errorInputSifra, setErrorInputSifra] = useState(false);

  const [errorMsgRepository, setErrorMsgRepository] = useState(false);
  const [errorMsgDocument, setErrorMsgDocument] = useState(false);
  const [errorMsgNomen, setErrorMsgNomen] = useState(false);
  const [errorMsgClients, setErrorMsgClients] = useState(false);
  const [errorMsgKolicina, setErrorMsgKolicina] = useState(false);
  const [errorMsgRbr, setErrorMsgRbr] = useState(false);
  const [errorMsgFaktCen, setErrorMsgFaktCen] = useState(null);
  const [errorMsgIznfakt, setErrorMsgIznfakt] = useState(false);
  const [errorMsgSifra, setErrorMsgSifra] = useState(null);

  const [errorMsgDate, setErrorMsgDate] = useState(false);
  const [errorMsgDateDelivery, setErrorMsgDateDelivery] = useState(false);
  const [errorMsgCurrency, setErrorMsgCurrency] = useState(false);
  const [errorCurrency, setErrorCurrency] = useState(false);
  const [errorMsgVrsta, setErrorMsgVrsta] = useState(false);
  const [errorMsgPrice, setErrorMsgPrice] = useState(false);
  const [errorMsgJM, setErrorMsgJM] = useState(false);
  const [errorMsgMalcenP, setErrorMsgMalcenP] = useState(false);
  const [errorMsgIznos, setErrorMsgIznos] = useState(false);

  const [repositoryFocus, setRepositoryFocus] = useState(true);
  const [vrstaFocus, setVrstaFocus] = useState(true);
  const [documentFocus, setDocumentFocus] = useState(false);
  const [clientsFocus, setClientsFocus] = useState(false);
  const [sifraFocus, setSifraFocus] = useState(false);
  const [malcenPFocus, setMalcenPFocus] = useState(false);

  const [dateFocus, setDateFocus] = useState(false);

  const [modalDeleteItem, setModalDeleteItem] = useState(false);
  const [modalEditErrorOpen, setModalEditErrorOpen] = useState(false);
  const [modalChangePriceOpen, setModalChangePriceOpen] = useState(false);
  const [modalErrorDeleteDocumentOpen, setModalErrorDeleteDocumentOpen] =
    useState(false);
  const [modalErrorDeleteItemOpen, setModalErrorDeleteItemOpen] =
    useState(false);
  const [modalTrosakErrorOpen, setModalTrosakErrorOpen] = useState(false);
  const [modalTrosakErrorOpenedOnce, setModalTrosakErrorOpenedOnce] =
    useState(false);

  const [modalErrorOpenData, setModalErrorOpenData] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  // tabela i paginacija
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [duzinaNiza, setDuzinaNiza] = useState(0);
  const pageNumbers = [];

  // forma posebni usestatovi radi mijenjanja vrijednosti od nazad
  const [rabatPercentage, setRabatPercentage] = useState(null);
  const [rabat, setRabat] = useState(null);
  const [marzaValue, setMarzaValue] = useState(null);
  const [cijena, setCijena] = useState(null);

  // trosak
  const [trosakOpen, setTrosakOpen] = useState(false);
  const [promjenaNabavneCijene, setPromjenaNabavneCijene] = useState(false);
  const [calculatedMarza, setCalculatedMarza] = useState(false);

  const [startDate, setStartDate] = useState();

  // iznosi , kalkulacije na dnu stranice
  const [totalIznos, setTotalIznos] = useState(0);
  const [totalIznosNabavneCijene, setTotalIznosNabavneCijene] = useState(0);
  const [totalRabat, setTotalRabat] = useState(0);
  const [totalNabavnaCijena, setTotalNabavnaCijena] = useState(0);
  const [totalIznosPDV, setTotalIznosPDV] = useState(0);
  const [totalIznosMarze, setTotalIznosMarze] = useState(0);

  // povlacenje podataka iz lokal storidza
  const token =
    typeof window !== "undefined" ? localStorage.getItem("accessToken") : null;
  const companyCode = localStorage.getItem("company");
  const companyYear = localStorage.getItem("companyYear");

  const [updateCijenaUCjenovniku, setUpdateCijenaUCjenovniku] = useState(false);
  const [proslaVrijednostCijene, setProslaVrijednostCijene] = useState(null);
  // datepicker
  let todaysDate = moment().year(companyYear);
  const [datumK, setDatumK] = useState(dayjs(todaysDate));
  const [datumR, setDatumR] = useState(dayjs(todaysDate));
  const [valuta, setValuta] = useState(dayjs(todaysDate));

  // pomocne varijable
  const [selektovanaVrstaSkladista, setSelektovanaVrstaSkladista] =
    useState("");
  const [iznosTroska, setIznosTroska] = useState(0);
  const [iznosTrosak1, setIznosTrosak1] = useState(null);

  const navigate = useNavigate();
  // definisanje formi
  const [form, setForm] = useState({
    ...defaultForm,
  });
  const [prometForm, setPrometForm] = useState({
    ...defaultPrometForm,
    VRPROM: "UL",
  });

  const [formOznaka, setFormOznaka] = useState({
    OZNAKA: '',
  });

  // funkcija za povlacenje podataka o preduzecu
  getCompanyData(form);
  form.ZAL_PLUS = form.zal_plus;
  // funkcija koja se poziva klikom na dugme 'Sacuvaj'
  const onSubmit = async () => {
    // uslov: ako nisu unesena obavezna polja, zaustavlja upis
    if (!unosIspravanDo("UPIS")) return;
    // setovanje datuma(ako nisu zadate druge vrijednosti, uzima difoltne)
    if (form && !form.DPO && !form.DPOR) {
      form.DPO = startDate;
      form.DPOR = endDate;
      form.VALUTA = currencyDate;
    }
    form.PROMETID = prometForm.PROMETID;
    form.VRPROM = prometForm.VRPROM;
    form.DOK = prometForm.DOK;
    form.SKLAID = prometForm.SKLAID;
    form.KOMTID = prometForm.KOMTID;
    form.RACUN = prometForm.RACUN;
    form.SIFKOR = form.userCode;
    form.VRSTA_SKLADISTA = prometForm.VRSTA_SKLADISTA;
    // datumi, formatiran datum DD.MM.YYYY
    form.DPO = prometForm.DPO;
    form.DPOR = dayjs(datumR).format("DD.MM.YYYY");
    form.VALUTA = dayjs(valuta).format("DD.MM.YYYY");
    // cjenovnik
    updateCijenaUCjenovniku ? (form.UPDATECJENOVNIK = true) : (form.UPDATECJENOVNIK = false);
    // saljemo T, ukoliko nije vrijenost nije T, saljemo prazan string
    form.T = form.T.toUpperCase();
    if (form.T != "T") {
      form.T = "";
    }

    fetch(API + "/ulaz/new", {
      method: "POST",
      body: JSON.stringify(form),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        response.json();
        // vracanje forme na pocetnu vrijednost
        setForm((prev) => ({
          ...prev,
          ...defaultForm,
        }));
        setRabat(null);
        setCijena(null);
        setMarzaValue(null);
        setArtikal(null);
        setTrosakOpen(false);
      })
      .then(() => {
        setErrorInputMarza(false);
        getPrometInfo();
        setUpdateCijenaUCjenovniku(false);
        rbrRef.current.focus();
      });
  };

  // vracanje vrijedosti na pocetnu vrijednost(null)
  const setPrometSStatesToNull = () => {
    setRabat(null);
    setCijena(null);
    setMarzaValue(null);
  };

  // funkcija koja prati promjenu stanja(poziva apije za citanje i popunjavanje autokomplitova)
  useEffect(() => {
    getRepositoryData();
    // getArticles();
    getVrstaData();
    getClientsData();
    // setovanje vrste prometa na UL
    setVrsta({
      VRSTA: "UL",
      NAZIV: "ULAZ",
      KOEF: 1,
      STRANA: "U",
      VEZA: "  ",
      ZABRANA: "N",
      naziv1: "KALK.",
      PRIORITET: 0,
    });
    setPrometForm((prev) => ({
      ...prev,
      VRPROM: "UL",
    }));
  }, [!repository && !artikal && !clients && !vrsta]);

  // funkcija koja poziva citanje skladista
  const getRepositoryData = () => {
    fetch(API + `/skladista/all/${form.companyCode}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setSelectData(data);
      });
  };

  // funkcija koja poziva upisivanje informacija u prometS formu
  const getPrometInfo = () => {
    fetch(API + `/ulaz/promet`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({
        SKLAID: prometForm.SKLAID,
        DOK: prometForm.DOK,
        VRPROM: prometForm.VRPROM,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        let promet = data[0];
        if (promet) {
          setPrometForm((prev) => ({ ...prev, ...promet }));
          setClients({
            NAZIV: promet.NAZIV,
            SIFRA: promet.KSifra,
          });
          setKomt(promet.NAZIV);
          setDatumK(promet.DPO);
          setDatumR(dayjs(promet.DPOR, "DD.MM.YYYY"));
          setValuta(dayjs(promet.VALUTA, "DD.MM.YYYY"));
          setKalkulacija([]);
          setCurrentPage(1);
          setDuzinaNiza(0);
          SetTotalsToDefaults();
          getPromet();
          getArticles();
          getPrometFirstFreeNumberRbr();
        } else {
          // vracanje datepickera na pocetne vrijednosti
          getArticles();
          setDatumK(todaysDate);
          setDatumR(todaysDate);
          setValuta(todaysDate);
          // vracanje forme na pocetne vrijednost
          setForm((prev) => ({
            ...prev,
            ...defaultForm,
          }));
          // vracanje na pocetne vrijednosti prometID, datuma racuna, naziva racuna, kursa
          setPrometForm((prev) => ({
            ...prev,
            PROMETID: 0,
            DPO: moment(todaysDate).format("DD.MM.YYYY"),
            RACUN: "",
            KURS: "",
          }));
          setKalkulacija([]);
          setCurrentPage(1);
          setDuzinaNiza(0);
          SetTotalsToDefaults();
          // setClients(null);
          getPrometFirstFreeNumberRbr();
        }
      });
  };

  // funckija koja poziva upisivanje stavki u tabetu
  const getPromet = () => {
    fetch(
      API + `/ulaz/all?page=${currentPage}&limit=${itemsPerPage}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          SKLAID: prometForm.SKLAID,
          DOK: prometForm.DOK,
          VRPROM: prometForm.VRPROM,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setKalkulacija(data.result);
        setDuzinaNiza(Math.ceil(data.total / itemsPerPage));
        getTotals();
      });
  };

  // dobijanje totanih vrijednosti sabiranjem iz svake stavke is tabelete prometS
  const getTotals = () => {
    fetch(API + `/ulaz/total`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({
        SKLAID: prometForm.SKLAID,
        DOK: prometForm.DOK,
        VRPROM: prometForm.VRPROM,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        getSumTotals(data.result);
      });
  };

  // funckija koja sabira totale i prikazuje ih u desnom donjem cosku
  const getSumTotals = (iznosi) => {
    let totals = {
      IZNFAKT: 0,
      IZNRABAT: 0,
      NABCEN: 0,
      IZPDV: 0,
      ULPDV: 0,
      IZNRAZD: 0,
      IZNMALD: 0,
    };
    // upisivanje, sabiranje vrijednosti iz svake stavke
    iznosi.forEach((k) => {
      totals.IZNFAKT += roundTO(k.IZNFAKT, 2);
      totals.IZNRABAT += roundTO(k.IZNRABAT, 2);
      totals.NABCEN += roundTO(k.NABCEN, 2);
      totals.IZPDV += roundTO(k.IZPDV, 2);
      totals.ULPDV += roundTO(k.ULPDV, 2);
      totals.IZNRAZD += roundTO(k.IZNRAZD, 2);
      totals.IZNMALD += roundTO(k.IZNMALD, 2);
      totals.PDV = roundTO(k.PDV, 2);
    });
    // setovanje sabranih vrijednosti u useStatove, te useStatove kasnije pozivamo da citamo vrijednost
    setTotalIznosNabavneCijene(roundTO(totals.IZNFAKT, 2));
    setTotalRabat(roundTO(totals.IZNRABAT, 2));
    setTotalNabavnaCijena(roundTO(totals.IZNFAKT - totals.IZNRABAT, 2));
    // setTotalIznosPDV(roundTO(((totals.IZNFAKT - totals.IZNRABAT) * totals.PDV) / 100, 2));
    setTotalIznosPDV(roundTO(totals.ULPDV, 2));
    setTotalIznosMarze(roundTO(totals.IZNRAZD, 2));
    setTotalIznos(roundTO(totals.IZNMALD, 2));
  };

  // funkcija koja nullira totale
  const SetTotalsToDefaults = () => {
    setTotalIznosNabavneCijene(0);
    setTotalRabat(0);
    setTotalNabavnaCijena(0);
    setTotalIznosPDV(0);
    setTotalIznosMarze(0);
    setTotalIznos(0);
  };

  // useeffect koji se poziva na svaku promjenu u paginaciji (currentPage ,itemsPerPage ) ukoliko prometForm.DOK postoji
  useEffect(() => {
    if (prometForm.DOK) {
      getPromet(currentPage, itemsPerPage);
    }
  }, [currentPage, itemsPerPage]);

  // useeffect koji se poziva na svaku promjenu u paginaciji (currentPage ,itemsPerPage ) ukoliko prometForm.DOK postoji
  useEffect(() => {
    getOpcijeData();
  }, []);

  // funkcija koja poziva citanje artikla
  const getArticles = () => {
    fetch(API + `/ulaz/getAllArticles`, {
      method: "POST",
      body: JSON.stringify({
        SKLAID: prometForm.SKLAID,
        companyCode: companyCode,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // upisujemo dobijeni rezultat u useState
        setSelectDataNomen(data);
      });
  };

  // funkcija koja poziva api za citanje opcija
  const getOpcijeData = () => {
    fetch(API + `/opcijeAplikacije/allOpcije`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data.length) {
          setForm((prev) => ({ ...prev, ZAL_PLUS: data[0].ZAL_PLUS }));
        }
      });
  };

  // funkcija koja poziva citanje oznake artikla
  const provjeraOznakeArtikla = (NOMENID) => {
    fetch(API + `/artikal/provjeraOznakeArtikla`, {
      method: "POST",
      body: JSON.stringify({
        NOMENID: NOMENID,
        companyCode: companyCode,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data.length) {
          let oznakaArtikla = data[0].OZNAKA;
          form.OZNAKA = oznakaArtikla;
          // setForm(prev => ({ ...prev, OZNAKA: data[0].OZNAKA }))
        } else {
          setForm(prev => ({ ...prev, OZNAKA: '' }))
        }
      });
  };

  // funkcija koja poziva citanje lagera
  const getLagerData = (nomenid) => {
    console.log(nomenid, 'nomenid')
    fetch(API + `/lager/all`, {
      method: "POST",
      body: JSON.stringify({
        sklaId: prometForm?.SKLAID || "",
        nomenId: nomenid || 0,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data, 'data')
        setLager(data)
        if (data && data.length) {
          setForm(prev => ({ ...prev, LAGER: data[0].LAGER }))
        } else {
          setForm(prev => ({ ...prev, LAGER: 0 }))
        }
      });
  };

  // funkcija koja poziva citanje vrste
  const getVrstaData = () => {
    fetch(API + `/vrstaDok/all`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setSelectDataVrsta(data);
      });
  };

  // funkcija koja poziva citanje komitenta
  const getClientsData = () => {
    fetch(API + `/komitenti/all/${form.companyCode}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setSelectDataClients(data);
      });
  };

  // funkcija koja poziva api za pronalazenje prvog slobodnog dokumenta
  const getPrometFirstFreeNumber = () => {
    fetch(API + `/ulaz/firstFreeNumber`, {
      method: "POST",
      body: JSON.stringify({
        sklaId: repository && repository.ID ? repository.ID : "",
        vrprom: vrsta && vrsta.VRSTA ? vrsta.VRSTA : "",
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let promet = data[0];
        setPrometForm((prev) => ({
          ...prev,
          DOK: promet.DOK,
          ID: promet.ID,
        }));
        setFirstDocumentNumber(promet.DOK, promet.ID);
      });
  };

  // funkcija koja poziva api za citanje po dokumentu
  const checkDocument = () => {
    fetch(API + `/ulaz/check/documentData`, {
      method: "POST",
      body: JSON.stringify({
        sklaId: repository && repository.ID ? repository.ID : "",
        vrprom: prometForm.VRPROM,
        dok: prometForm.DOK,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // uslov: ako ima podataka, da popuni polja, procita mrezu i procita sume. U suprotnom cisti sume
        if (data && data.length) {
          setPrometForm((prev) => ({
            ...prev,
            DPO: moment
              .unix(new Date(data[0].DPO).getTime() / 1000)
              .format("DD.MM.YYYY"),
            DPOR: moment
              .unix(new Date(data[0].DPOR).getTime() / 1000)
              .format("DD.MM.YYYY"),
            VALUTA: moment
              .unix(new Date(data[0].VALUTA).getTime() / 1000)
              .format("DD.MM.YYYY"),
            KOMTID: data[0].KOMTID,
            ID: data[0].ID,
          }));
          selectDataClients.map((item) => {
            if (item.ID == data[0].SIFRA) {
              setClients(item);
            }
          });
          setUpdateDocument(true);
        } else {
          setUpdateDocument(false);
          setSumNomenIznos(false);
          setSumOsnovica(false);
          setSumProdajniIznos(false);
          setSumIznosRabata(false);
          setSumIznosPoreza(false);
          setSumIznosMarze(false);
        }
      });
  };

  // funkcija koja poziva brisanje dokumenta
  const onDelete = () => {
    // fetch(API + `/ulaz/delete/${sklaId}/${vrprom}/${dok}`, {
    //   method: "DELETE",
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //     "Content-type": "application/json; charset=UTF-8",
    //   },
    // }).then((response) => {
    //   if (response.status == 200) {
    //     setModalOpen(false);
    //     setKalkulacija(null);
    //     setClients(null);
    //     setArtikal(null);
    //     // checkDocument();
    //     setForm((prev) => ({
    //       ...prev,
    //       KOMTID: "",
    //       OTPREM: "",
    //       OTPIZV: "",
    //       NAPOMENA: "",
    //       POR_U_CENI: "",
    //       RBR: "",
    //       USLUGA: "",
    //       PREDRACID: 0,
    //       OPIS: "",
    //       KONTO: "",
    //       JM: "",
    //       KOL: "",
    //       CENA: "",
    //       POREZID: "",
    //       VRED: "",
    //     }));
    //   }
    // });

    let rbrw = 0;
    let nabcenw = 0;
    let prometIdw = 0;
    let sifranw = 0;
    let kolW = 0;
    let izndW = 0;
    let iznmaldW = 0;
    let result = false;
    form.BRISANJE = true;

    result = kalkulacija
    if (!result) return false;
    console.log(kalkulacija, 'kalkulacija')
    // petlja za citanje podataka
    for (let i = 0; i < kalkulacija.length; i++) {
      rbrw = kalkulacija[i].RBR;
      nabcenw = kalkulacija[i].NABCEN;
      sifranw = kalkulacija[i].NOMENID;
      prometIdw = kalkulacija[i].PROMETID;
      kolW = kalkulacija[i].KOL;
      izndW = kalkulacija[i].IZND;
      iznmaldW = kalkulacija[i].IZNMALD;
      // setTimeout(() => {
    //  setTimeout(() => {
        getLagerData(sifranw);
    //  },
     //   50);
      provjeraOznakeArtikla(sifranw)

      if (form.OZNAKA === '-') {
        console.log('upada u if')
        result = true
      }
      // else {
    //  setTimeout(() => {
        result = kontrolaLagera(prometForm.SKLAID, prometForm.GODINA, prometForm.PRSID,
          sifranw, prometForm.VRPROM, kolW, izndW, iznmaldW, form.BRISANJE, vrsta, repository, form.ZAL_PLUS, lager)
        //   }
     // },
      //  100);


     // setTimeout(() => {
      console.log(result, 'result')
        if (result) {
          onDeleteItems(prometIdw, rbrw)
          setModalOpen(false)
        }
        setForm(prev => ({
          ...prev,
          PROMETID: 0,
          DPO: '',
          DPOR: '',
          VALUTA: '',
          RACUN: '',
          KURS: ''
        }))
        setClients(null)
    //  },
     //   100);
    }

    // zaustavljanje fokusa(pauza za fokus dok ne odradi brisanje)
    const timeout = setTimeout(() => {
      documentRef.current.focus();
    }, 400);
    return () => {
      clearTimeout(timeout);
    };
  };

  // funkcija koja poziva api za brisanje stavki
  const onDeleteItems = (prometID, rbr) => {
    fetch(API + `/ulaz/item/delete/${prometID}/${rbr}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    }).then((response) => {
      // uslov: ako je uspjesno obrisano, cisti sva polja u vezi sa stavkom
      if (response.status == 200) {
        setModalDeleteItem(false);
        setKalkulacija(null);
        setArtikal(null);
        // checkDocument();
        setItemUpdate(false);
        setForm((prev) => ({
          ...prev,
          ...defaultForm,
        }));
        getPrometInfo();
      }
      // zaustavljanje fokusa(pauza za fokus dok ne odradi brisanje)
      const timeout = setTimeout(() => {
        rbrRef.current.focus();
      }, 500);
      return () => {
        clearTimeout(timeout);
      };
    });
  };

  // funkcija koja se poziva dvoklikom na red u mrezi/tabeli(na stavku), popunjava polja za stavku
  const onEdit = (item) => {
    // getPromet();
    setItemUpdate(true);
    // update forme na osnovu parametara iz item-a
    setForm((prevState) => ({
      ...prevState,
      ...item,
      KOLICINA: item.KOL,
      SIFRA: item.SifraArtikla,
      PDV: item.PDV,
      IZPDV: getPorezUIznosu(item.PDV, item.KOL, item.MALCEN), // dobijanje iznosa poreza
      NOMENID: item.NOMENID,
      //  LAGER: item.LAGER,
      IZNRAZD: item.IZNRAZD,
      IZND: item.NABCEN * item.KOL,
    }));

    // provjeravamo da li je item.NOMENID jednak artikal.ID, ukoliko jeste setujemoArtikal
    selectDataNomen.map((artikal) => {
      if (artikal.ID == item.NOMENID) {
        setArtikal(artikal);
      }
    });
    // getArticleInfo()

    // form.NOMENID se puni da bi radilo citanje lagera iz mreze
    let NOMENID = item.NOMENID
    getLagerData(NOMENID);

  };

  // funkcija koja hvata promjene na poljima prometForme i setuje vrijednost u form state
  const handleChangePromet = (event) => {
    const target = event.target;
    let value = target.value;
    const name = event.target.name;
    setPrometForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // funkcija koja hvata promjene na poljima forme i setuje vrijednost u form state
  const handleChangePrometS = (event) => {
    const target = event.target;
    let value = target.value;
    const name = event.target.name;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // propsovi za auto komplit  - skladiste, komitent, artikal i vrstu; objekat za dati autokomplit iz koga preko mape mozemo procitati podatak upisan u bazu
  //  getOptionLabel - polja koja ce biti prikazana u autokomplitu
  const defaultProps = {
    options: selectData,
    getOptionLabel: (option) =>
      option ? `${option.SIFRA} ${option.NAZIV}` : "",
  };
  const defaultPropsNomen = {
    options: selectDataNomen,
    getOptionLabel: (option) =>
      option ? `${option.SIFRA} ${option.NAZIV}` : "",
  };
  const defaultPropsVrsta = {
    options: selectDataVrsta,
    getOptionLabel: (option) =>
      option ? ` ${option.VRSTA} ${option.NAZIV}` : "",
  };
  const defaultPropsClients = {
    options: selectDataClients,
    getOptionLabel: (option) =>
      option ? `${option.SIFRA} ${option.NAZIV}` : "",
  };

  // funkcije za provjeru obaveznih polja - skladiste
  const checkRepositoryRequired = (value) => {
    // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto) ili ako nije upisana ni vrijednost niti je odabrano skladiste, izbaci poruku, setuje crveni okvir i fokusira polje
    // u suprotnom prolazi dalje
    if ((value && !repository) || (!value && !repository)) {
      setErrorMsgRepository("Skladiste mora biti izabrano!");
      setErrorInputRepository(true);
      repositoryRef.current.focus();
      setRepositoryFocus(true);
      return false;
    } else {
      setErrorMsgRepository(null);
      setErrorInputRepository(false);
      return true;
    }
  };

  // funkcije za provjeru obaveznih polja - cijena
  const checkPriceRequired = (value) => {
    if (value <= 0 || (cijena && cijena <= 0)) {
      setErrorMsgPrice("Unesite ispravnu fakturnu cijenu");
      cijenaRef.current.focus();
      return false;
    } else {
      setErrorMsgPrice(null);
      return true;
    }
  };

  // funkcije za provjeru obaveznih polja - iznosa
  const checkIznosRequired = (value) => {
    if (value == 0) {
      setErrorMsgIznos("Unesite ispravan iznos");
      iznosRef.current.focus();
      return false;
    } else {
      setErrorMsgIznos(null);
      return true;
    }
  };

  // funkcije za provjeru obaveznih polja - fakturnog iznosa
  const checkFAKTIZRequired = (value) => {
    if (value <= 0 || value == "") {
      setErrorMsgIznfakt("Unesite ispravan fakturni iznos");
      fiRef.current.focus();
      return false;
    } else {
      setErrorMsgIznfakt(null);
      return true;
    }
  };

  // funkcije za provjeru vrijednosti unosa u trosak
  const checkValidTrosak = (value) => {
    if (value !== "t" && value !== "T" && value !== "") {
      setModalTrosakErrorOpen(true);
      setModalTrosakErrorOpenedOnce(true);
      trosakRef.current.focus();
      return false;
    } else {
      setModalTrosakErrorOpen(false);
      setModalTrosakErrorOpenedOnce(false);
      return true;
    }
  };

  // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto) ili ako nije upisana ni vrijednost niti je odabrana vrsta, izbaci poruku, setuje crveni okvir i fokusira polje
  // u suprotnom prolazi dalje
  const checkVrstaRequired = (value) => {
    if ((value && !vrsta) || (!value && !vrsta)) {
      setErrorMsgVrsta("Vrsta mora biti izabrana!");
      setErrorInputVrsta(true);
      vrstaRef.current.focus();
      setVrstaFocus(true);
      return false;
    } else {
      setErrorMsgVrsta(null);
      setErrorInputVrsta(false);
      return true;
    }
  };

  // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto) ili ako nije upisana ni vrijednost niti je odabran komitent, izbaci poruku, setuje crveni okvir i fokusira polje
  // u suprotnom prolazi dalje
  const checkClientsRequired = (value) => {
    if ((value && !clients) || (!value && !clients)) {
      setErrorMsgClients("Komitent mora biti izabran!");
      clientsRef.current.focus();
      return false;
    } else {
      setErrorMsgClients(null);
      return true;
    }
  };

  // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto) ili ako nije upisana ni vrijednost niti je odabran komitent, izbaci poruku, setuje crveni okvir i fokusira polje
  // u suprotnom prolazi dalje
  const checkDocumentRequired = (value) => {
    if (!value || value == 0) {
      setErrorMsgDocument("Dokument je obavezan!");
      setErrorInputDocument(true);
      documentRef.current.focus();
      return false;
    } else {
      setErrorMsgDocument(null);
      setErrorInputDocument(false);
      return true;
    }
  };

  // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto) ili ako nije upisana ni vrijednost niti je odabran dokument, izbaci poruku, setuje crveni okvir i fokusira polje
  // u suprotnom prolazi dalje
  const checkRBRRequired = (value) => {
    if (!value || value <= 0) {
      setErrorMsgRbr("Rbr je obavezan!");
      setErrorInputRbr(true);
      rbrRef.current.focus();
      return false;
    } else {
      setErrorMsgRbr(null);
      setErrorInputRbr(false);
      return true;
    }
  };

  // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto) ili ako nije upisana ni vrijednost niti je odabran dokument, izbaci poruku, setuje crveni okvir i fokusira polje
  // u suprotnom prolazi dalje
  const checkKolRequired = (value) => {
    // uslov: ako nije upisana vrijednost manja od 0 ili je vrijednost 0, izbaci poruku. U suprotnom: prolazi dalje.
    if (value <= 0 || value == "") {
      setErrorMsgKolicina("Količina je obavezna!");
      kolicinaRef.current.focus();
      return false;
    } else {
      setErrorMsgKolicina(null);
      return true;
    }
  };

  // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto)
  // ili ako nije upisana ni vrijednost niti je odabran artikal, izbaci poruku, setuje crveni okvir i fokusira polje
  // u suprotnom prolazi dalje
  const checkSifraRequired = (value) => {
    // provjeravamo da li artikal sa datom sifrom postoji u nizu
    let doesItemExistInArtikli = selectDataNomen.some(
      (artikal) => artikal.SIFRA == value
    );
    if (!doesItemExistInArtikli) {
      setErrorInputSifra(true)
      setErrorMsgSifra("Unesite šifru artikla!");
      sifraRef.current.focus();
      return false;
    } else {
      setErrorMsgSifra(null);
      return true;
    }
  };

  // funkcija unosIspravanDo(validacija), na klik svakog input elementa iz formi,
  // provjerava obavezna polja iz prethodnih te ukazuje na gresku ukoliko je ima.
  function unosIspravanDo(name) {
    // skladiste
    if (name === "SKLAID") {
      return true;
    }

    if (!checkRepositoryRequired(prometForm.SKLAID)) {
      return false;
    }

    // vrsta prometa
    if (name === "VRPROM") {
      return true;
    }

    if (!checkVrstaRequired(prometForm.VRPROM)) {
      return false;
    }

    // dokument
    if (name === "DOK") {
      return true;
    }

    if (!checkDocumentRequired(prometForm.DOK)) {
      return false;
    }

    // dpo
    if (name === "DPO") {
      return true;
    }

    let regex = /^(0[1-9]|[12]\d|3[01]).(0[1-9]|1[0-2]).([12]\d{3})$/gi;
    if (!regex.test(prometForm.DPO)) {
      setErrorDate(true);
      setErrorMsgDate("Datum nije validan!");
      dateRef.current.focus();
      return false;
    } else {
      setErrorDate(false);
      setErrorMsgDate(null);
    }

    // dpor
    if (name === "DPOR") {
      return true;
    }

    // racun
    if (name == "RACUN") {
      return true;
    }

    // kurs
    if (name == "KURS") {
      return true;
    }

    // komitent id
    if (name == "KOMTID") {
      return true;
    }

    if (!checkClientsRequired(prometForm.KOMTID)) {
      return false;
    }

    if (name == "ARTIKAL") {
      return true;
    }

    // rbr
    if (name == "RBR") {
      return true;
    }

    if (!checkRBRRequired(form.RBR)) {
      return false;
    }

    // sifra
    if (name == "SIFRA") {
      return true;
    }

    if (!checkSifraRequired(form.SIFRA)) {
      return false;
    }

    // kolicina
    if (name == "KOLICINA") {
      return true;
    }

    if (!checkKolRequired(form.KOLICINA)) {
      return false;
    }

    // fakturna cijena
    if (name === "FAKTURNACIJENA") {
      return true;
    }

    // fakturni iznos
    if (name === "FAKTURNIIZNOS") {
      return true;
    }

    if (!checkFAKTIZRequired(form.IZNFAKT)) {
      return false;
    }

    // procenat rabata
    if (name == "RABAT") {
      return true;
    }

    // iznos rabata
    if (name == "IZNRABAT") {
      return true;
    }

    // trosak
    if (name == "TROSAK") {
      return true;
    }

    if (!checkValidTrosak(form.TROSAK)) {
      return false;
    }

    // procenat troska
    if (name == "TROSAKODPDVOBVEZNIKA") {
      return true;
    }

    // iznos troska
    if (name == "IZNOSTROSKAODPDVOBVEZNIKA") {
      return true;
    }

    // procenat troska1
    if (name == "TROSAK1PER") {
      return true;
    }

    // iznos troska1
    if (name == "IZNOSTROSKA1PER") {
      return true;
    }

    // procenat marze
    if (name == "MARZAP") {
      return true;
    }

    // iznos marze
    if (name == "MARZA") {
      return true;
    }

    // cijena
    if (name == "CIJENA") {
      return true;
    }

    if (!checkPriceRequired(form.MALCEN)) {
      return false;
    }

    // iznos
    if (name == "IZNOS") {
      return true;
    }

    if (!checkIznosRequired(form.IZNMALD)) {
      return false;
    }

    return true;
  }

  // unosenje headera i stilova unutar tabele sljedecim parametrima:
  const columns = [
    {
      name: "Rbr.",
      class: "col-1 fs-6 text-end px-3 text-nowrap ",
    },
    {
      name: "Šifra",
      class: "col-1 fs-6 text-end px-3 text-nowrap",
    },
    {
      name: "Artikal",
      class: "col-1 fs-6 px-3 text-nowrap",
    },
    {
      name: "J.M.",
      class: "col-1 fs-6 text-end px-3 text-nowrap",
    },
    {
      name: "Količina",
      class: "col-1 fs-6 text-end px-3 text-nowrap",
    },
    {
      name: "Fakturna cijena",
      class: "col-1 fs-6 text-end px-3 text-nowrap",
    },
    {
      name: "Fakturni iznos",
      class: "col-1 fs-6 text-end px-3 text-nowrap",
    },
    {
      name: "Trošak",
      class: "col-1 fs-6 px-3 text-nowrap",
    },
    {
      name: "Marža %",
      class: "col-1 fs-6 text-end px-3 text-nowrap",
    },
    {
      name: "Marža",
      class: "col-1 fs-6 text-end px-3 text-nowrap",
    },
    {
      name: "Cijena",
      class: "col-1 fs-6 text-end px-3 text-nowrap",
    },
    {
      name: "Iznos",
      class: "col-1 fs-6 text-end px-3 text-nowrap",
    },
  ];

  // funkcija koja poziva api za pronalazenje prvog slobodnog rednog broja
  // setovanje polja RBR
  const getPrometFirstFreeNumberRbr = () => {
    fetch(API + `/ulaz/firstFreeNumberRbr`, {
      method: "POST",
      body: JSON.stringify({
        sklaId: prometForm.SKLAID || "",
        vrprom: prometForm.VRPROM || "",
        dok: prometForm.DOK,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setRabat(null);
        setMarzaValue(null);
        setCijena(null);
        setForm((prev) => ({ ...prev, RBR: data[0].RBR }));
      });
  };

  // funkcija koja je zaduzena za prikazivanje sljedecih i prethodnih brojeva stranica na paginaciji
  getPageNumber(currentPage, duzinaNiza, pageNumbers);

  // funkcija koja poziva api za pronalazenje informacija o artiklu na osnovu Sifre artikla
  const getArticleInfo = () => {
    fetch(API + `/ulaz/articleInfo`, {
      method: "POST",
      body: JSON.stringify({
        SKLAID: prometForm?.SKLAID || "",
        SIFRAARTIKLA: form?.SIFRA || 0,
        companyCode: companyCode || 0,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let articleData = data[0];
        if (articleData) {
          getLagerData(articleData?.NOMENID);
          getTaxData(articleData?.NOMENID);
          setForm((prev) => ({
            ...prev,
            FAKTCEN: articleData?.NABCENA
              ? articleData?.NABCENA
              : articleData?.PRODCENA || 0,
            NAZIV: articleData?.NAZIV || "",
            SIFRA: articleData?.SIFRA || 0,
            NABCEN: articleData?.NABCENA || 0,
            MALCEN: articleData?.PRODCENA || 0,
            JM: articleData?.JM || "",
            NOMENID: articleData?.NOMENID || 0,
          }));
          setProslaVrijednostCijene(articleData?.PRODCENA);
          setArtikal({
            NAZIV: articleData?.NAZIV || "",
            ID: articleData?.ID || 0,
            SIFRA: articleData?.SIFRA || 0,
            NABCEN: articleData?.NABCENA || 0,
            JM: articleData?.JM || "",
          });
        }
      });
  };

  // dobijanje odgovarajuceg poreza za artikal
  const getTaxData = (nomenid) => {
    fetch(API + `/porezi/procenat`, {
      method: "POST",
      body: JSON.stringify({ nomenId: nomenid }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data.length) {
          setForm((prev) => ({ ...prev, PDV: data[0].PROCENAT }));
        }
      });
  };

  return (
    <div>
      {/* kontejner za naslov i ikonice */}
      <div className="title-container">
        <header className="title-header">
          <h1 id="posaebantitle" className="media-query-title">
            Ulaz Kalkulacije
          </h1>
        </header>

        <div className="naslov-ikonice">
          <ul className="nav flex-lg-row justify-content-end">
            <li className="nav-item toolbaritem">
              <input
                type="image"
                alt="save"
                className="dugme-sacuvaj"
                title="Sačuvaj"
                tabIndex="26"
                src={require("./../../assets/images/floppydisk.png")}
                onFocus={() => (!itemUpdate ? onSubmit() : null)}
              />
            </li>

            <li className="nav-item toolbaritem">
              <input
                type="image"
                alt="print"
                className="dugme-stampa"
                title="Štampa"
                tabIndex="27"
                src={require("./../../assets/images/printer.png")}
                onFocus={() => {
                  if (prometForm.DOK) {
                    navigate("/kalkulacija", { state: { id: prometForm.ID, dok: prometForm.DOK, skladiste: repository.ID, vrstaSkladista: repository.VRSTA_SKLADISTA, vrstaUlaz: vrsta.VRSTA } });
                    // localStorage.setItem(
                    //   "kalkulacija",
                    //   JSON.stringify({
                    //     ID: prometForm.PROMETID,
                    //     DOK: prometForm?.DOK || 0,
                    //     SKLADISTE: repository,
                    //     VRPROM: vrsta || {},
                    //   })
                    // );
                  }
                }}
              />
            </li>

            <li className="nav-item toolbaritem">
              <div
                onClick={
                  prometForm.DOK &&
                    prometForm.DOK !== "" &&
                    prometForm.DOK !== 0
                    ? setModalOpen
                    : setModalErrorDeleteDocumentOpen
                }
                tabIndex="28"
              >
                <SVG
                  src={deleteDocument}
                  title="Brisanje dokumenta"
                  className="dugme-brisanje"
                />
              </div>
            </li>

            <li className="nav-item toolbaritem">
              <input
                type="image"
                alt="delete item"
                className="dugme-stampa"
                title="Brisanje stavke"
                tabIndex="29"
                src={require("./../../assets/images/trash1.png")}
                onClick={
                  itemUpdate ? setModalDeleteItem : setModalErrorDeleteItemOpen
                }
              />
            </li>
          </ul>
        </div>
      </div>
      {/* kontejner pune sirine */}
      <Container fluid>
        <Row>
          <div className="col-12 flex-column flex-lg-row">
            <Row className="col-12 flex-column flex-lg-row">
              <div className="col-12 col-lg-4 d-flex flex-column gap-3">
                <div className="col-12">
                  <div
                    className={
                      errorInputRepository
                        ? "inputWithError d-flex"
                        : "field-container d-flex gap-1"
                    }
                  >
                    <label className="fs-6 m-0 text-nowrap minWidth80">
                      Skladište:
                    </label>
                    {/* autokomplit za skladiste */}
                    <Autocomplete
                      //ukoliko nema ove funkcije, u konzoli izbacuje upozorenje da nije vrijednost jedinstvena
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      {...defaultProps}
                      className="w-100"
                      value={repository}
                      disablePortal={true}
                      onFocus={() => {
                        setRepositoryFocus(true);
                        unosIspravanDo("SKLAID");
                      }}
                      // ako se promijeni skladiste, uzima podatke za novo izabrano skladiste
                      onChange={(event, newValue) => {
                        setSelektovanaVrstaSkladista(newValue.VRSTA_SKLADISTA);
                        setPrometForm((prev) => ({
                          ...prev,
                          SKLAID:
                            newValue && newValue != null ? newValue.ID : "",
                          VRSTA_SKLADISTA:
                            newValue && newValue != null
                              ? newValue.VRSTA_SKLADISTA
                              : "",
                        }));
                        setRepository(newValue);
                        repositoryRef.current.focus();
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          autoFocus
                          className="clients-textinput"
                          maxLength="295"
                          name="SKLAID"
                          tabIndex="1"
                          ref={repositoryRef}
                          //   onBlur={(e) => formCheck(e)}
                          placeholder="Izaberite skladište"
                          variant="standard"
                        />
                      )}
                    />
                  </div>
                  {/* prikazivanje greske ukoliko skladiste nije izabrano */}
                  {errorMsgRepository && (
                    <div className="col-12">
                      <span className="error-message">
                        {errorMsgRepository}
                      </span>
                    </div>
                  )}
                </div>
                <div className="col-12 d-flex gap-1 my-1">
                  <div className="col-6 d-flex gap-1">
                    <label className="fs-6 m-0 text-nowrap minWidth80">
                      Dok.:
                    </label>
                    {/* komponenta za formatiranje brojeva */}
                    <NumericFormat
                      type="number"
                      getInputRef={documentRef}
                      className="w-25"
                      maxLength="9"
                      tabIndex="3"
                      decimalScale={0}
                      onFocus={() => {
                        setDocumentFocus(true);
                        unosIspravanDo("DOK");
                        getPrometFirstFreeNumber();
                        setKomt(null);
                      }}
                      placeholder="0"
                      name="DOK"
                      value={prometForm?.DOK}
                      onChange={(e) => {
                        handleChangePromet(e);
                      }}
                      onClick={() => {
                        // ako je odradjen dvoklik na stavku u mrezi, a zatim klik na dokument, cisti polja
                        setUpdateDocument(false);
                        setItemUpdate(false);
                        // setPrometForm((prev) => ({
                        //   ...prev,
                        //   defaultPrometForm,
                        // }));
                        setArtikal(null);
                      }}
                    />
                  </div>
                  <div className="col-6">
                    <div
                      className={
                        errorDate
                          ? "inputWithError d-flex justify-content-end gap-2"
                          : "field-container d-flex justify-content-end gap-2"
                      }
                    >
                      <label className="fs-6 m-0 text-nowrap ">Datum K:</label>
                      <input
                        type="text"
                        className="w-50"
                        tabIndex="4"
                        ref={dateRef}
                        onFocus={() => {
                          setDateFocus(true);
                          unosIspravanDo("DPO");
                          getPrometInfo();
                        }}
                        placeholder=""
                        name="DPO"
                        value={prometForm.DPO}
                        onChange={(e) => handleChangePromet(e)}
                      />
                    </div>
                    {/* prikazivanje greske ukoliko je izabran los datum  */}
                    {errorMsgDate && (
                      <div className="col-6 text-end">
                        <span className="error-message">{errorMsgDate}</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12">
                  <div className="field-container d-flex gap-1">
                    <label className="fs-6 m-0 text-nowrap minWidth80">
                      Račun:
                    </label>
                    <input
                      type="text"
                      ref={racunRef}
                      onFocus={() => {
                        unosIspravanDo("RACUN");
                      }}
                      maxLength="30"
                      tabIndex="5"
                      placeholder="Unesite racun "
                      name="RACUN"
                      className="m-0"
                      value={prometForm.RACUN}
                      onChange={(e) => handleChangePromet(e)}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div
                    className={
                      errorMsgClients
                        ? "inputWithError d-flex"
                        : "field-container d-flex gap-1"
                    }
                  >
                    <label className="fs-6 m-0 text-nowrap minWidth80">
                      Komitent:
                    </label>
                    {/* autokomplit za komitenta */}
                    <Autocomplete
                      ref={komtRef}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      {...defaultPropsClients}
                      value={clients}
                      className="w-100"
                      disablePortal={true}
                      onFocus={() => {
                        setClientsFocus(true);
                        unosIspravanDo("KOMTID");
                      }}
                      // ako se promijeni komitent, uzima podatke za novog izabranog komitenta
                      onChange={(event, newValue) => {
                        setPrometForm((prev) => ({
                          ...prev,
                          KSifra:
                            newValue && newValue != null ? newValue?.SIFRA : "",
                          KOMTID:
                            newValue && newValue != null ? newValue?.ID : "",
                        }));
                        setClients(newValue);
                        // fokus se mora vratiti inace tabIndex ne radi dobro
                        clientsRef.current.focus();
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          tabIndex="8"
                          maxLength="283"
                          className="clients-textinput"
                          name="KOMTID"
                          ref={clientsRef}
                          placeholder="Izaberite komitenta"
                          variant="standard"
                        />
                      )}
                    />
                  </div>
                  {/* prikazivanje greske ukoliko komitent nije izabran */}
                  {errorMsgClients && (
                    <div className="col-12">
                      <span className="error-message">{errorMsgClients}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-12 col-lg-4 d-flex flex-column gap-3">
                {/* Stilovi koji ce se primjenjivati na svim poljima */}
                <div className="col-12">
                  <div
                    className={
                      errorInputVrsta
                        ? "inputWithError d-flex"
                        : "field-container d-flex"
                    }
                  >
                    <label className="fs-6 m-0 text-nowrap minWidth80">
                      Vrsta:
                    </label>

                    {/* autokomplit za vrstu */}
                    <Autocomplete
                      //ukoliko nema ove funkcije, u konzoli izbacuje upozorenje da nije vrijednost jedinstvena
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      {...defaultPropsVrsta}
                      value={vrsta}
                      className="w-100"
                      disablePortal={true}
                      onFocus={() => {
                        setVrstaFocus(true);
                        unosIspravanDo("VRPROM");
                      }}
                      // ako se promijeni vrsta, uzima podatke za novu izabranu vrstu
                      onChange={(event, newValue) => {
                        setPrometForm((prev) => ({
                          ...prev,
                          VRPROM:
                            newValue && newValue != null ? newValue.VRSTA : "",
                          NAZIV:
                            newValue && newValue != null ? newValue.NAZIV : "",
                        }));
                        setVrsta(newValue);
                        vrstaRef.current.focus();
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          tabIndex="2"
                          maxLength="2"
                          className="clients-textinput"
                          name="VRPROM"
                          ref={vrstaRef}
                          placeholder=""
                          variant="standard"
                        />
                      )}
                    />
                  </div>
                  {/* prikazivanje greske ukoliko vrsta nije izabrana */}
                  {errorMsgVrsta && (
                    <div className="col-12">
                      <span className="error-message">{errorMsgVrsta}</span>
                    </div>
                  )}
                </div>
                <div className="col-12 d-flex my-1 justify-content-between">
                  <div className="d-flex col-6">
                    <div className="col-1 minWidth80">
                      <label className="fs-6 m-0 text-nowrap gray-500">
                        Datum R:
                      </label>
                    </div>
                    {/* datepicker https://mui.com/x/react-date-pickers/date-picker/ */}
                    <div className="col-8">
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="de"
                        className="w-25"
                      >
                        <DatePicker
                          value={dayjs(datumR)}
                          onChange={(newDate) => {
                            setDatumR(newDate);
                          }}
                          TextField={(params) => (
                            <>
                              <TextField tabIndex={5} {...params} />
                            </>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                  <div className="d-flex col-6 justify-content-between">
                    <div className="col-1 minWidth80">
                      <label className="ps-3 fs-6 m-0 text-nowrap gray-500">
                        Valuta:
                      </label>
                    </div>
                    <div className="col-8">
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="de"
                      >
                        <DatePicker
                          value={dayjs(valuta)}
                          onChange={(newDate) => {
                            unosIspravanDo("DPOR");
                            setValuta(newDate);
                          }}
                          ref={dateDeliveryRef}
                          TextField={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="field-container d-flex">
                    <label className="fs-6 m-0 text-nowrap gray-500 minWidth80">
                      Kurs:
                    </label>
                    <input
                      className="col-2"
                      type="text"
                      ref={kursRef}
                      maxLength="70"
                      placeholder=""
                      tabIndex="6"
                      name="KURS"
                      value={prometForm.KURS ? prometForm?.KURS : ""}
                      onChange={(e) => handleChangePromet(e)}
                      onFocus={() => unosIspravanDo("KURS")}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="col-12 d-flex flex-column flex-md-row justify-content-between">
                  <div className="col-12 col-md-6">
                    <div className="col-12 d-flex flex-column gap-1">
                      {/* promets forma */}
                      <div
                        className={
                          errorInputMalcenP
                            ? "inputWithError d-flex justify-content-between"
                            : "field-container d-flex justify-content-between"
                        }
                      >
                        <label className="fs-6 m-0 text-nowrap">
                          F - R cijena
                        </label>
                        {/* komponenta za formatiranje brojeva */}
                        <input
                          type="number"
                          disabled
                          //  getInputRef={malcenPRef}
                          className="w-50 m-0 px-2"
                          maxLength="15"
                          //decimalScale={3}
                          placeholder="0"
                          value={numberWithCommasColumns3(form.FAKTCEN - form.IZNOSRAB)}
                        />
                      </div>
                      {/* promets forma */}
                      <div className="d-flex justify-content-between">
                        <label className="fs-6 text-nowrap m-0">
                          Nab. cijena:
                        </label>
                        {/* komponenta za formatiranje brojeva */}
                        <NumericFormat
                          type="number"
                          className="w-50 px-2"
                          disabled
                          maxLength="15"
                          //  decimalScale={3}
                          placeholder="0"
                          name="MALCEN"
                          value={form.NABCEN}
                        />
                      </div>
                      {/* promets forma */}

                      <div>
                        <div className="d-flex justify-content-between fs-6">
                          <label className="fs-6 text-nowrap m-0">Porez %:</label>
                          {/* komponenta za formatiranje brojeva */}
                          <NumericFormat
                            disabled
                            type="number"
                            getInputRef={rabatRef}
                            className="w-50 m-0 px-2"
                            maxLength="11"
                            // broj decimala
                            decimalScale={2}
                            placeholder="0"
                            name="IZNOSRAB"
                            value={numberWithCommasColumns(form.PDV)}
                            onChange={(e) => handleChangePromet(e)}
                          />
                        </div>
                      </div>
                      {/* promets forma */}

                      <div>
                        <div className="d-flex justify-content-between fs-6">
                          <label className="fs-6 m-0 text-nowrap">Lager:</label>
                          <input
                            disabled
                            className="w-50 m-0 text-end px-2"
                            placeholder="0"
                            type='text'
                            value={numberWithCommasColumns(form.LAGER)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-5">
                    <div className="col-12 d-flex gap-1 flex-column">
                      <div className="col-12">
                        {/* promets forma */}
                        <div className="d-flex justify-content-between">
                          <label className="fs-6 text-nowrap m-0">
                            Fakturni iznos:
                          </label>
                          <input
                            className="text-end w-50 m-0 px-2"
                            disabled
                            type="text"
                            placeholder=""
                            name="IZNMALD"
                            value={numberWithCommasColumns(form.IZNFAKT)}
                          />
                        </div>
                      </div>
                      {/* promets forma */}
                      <div className="d-flex justify-content-between fs-6">
                        <label className="fs-6 text-nowrap m-0">Nab. iznos:</label>
                        {/* komponenta za formatiranje brojeva */}
                        <NumericFormat
                          type="number"
                          getInputRef={malcenRef}
                          className="text-end w-50 m-0 px-2"
                          disabled
                          maxLength="15"
                          decimalScale={2}
                          placeholder="0"
                          name="MALCEN"
                          value={numberWithCommasColumns(form.IZND)}
                          onChange={(e) => handleChangePromet(e)}
                        />
                      </div>
                      <div>
                        {/* promets forma */}
                        <div className="field-container d-flex justify-content-between">
                          <label className="fs-6 m-0 text-nowrap">Porez:</label>
                          {/* komponenta za formatiranje brojeva */}
                          <NumericFormat
                            type="number"
                            className="text-end w-50 m-0 px-2"
                            disabled
                            maxLength="13"
                            //  decimalScale={2}
                            placeholder="0"
                            name="IZPDV"
                            value={numberWithCommasColumns3(form.IZPDV)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </div>
        </Row>
        <Row className="flex-lg-row mt-3">
          <div className="col-9 col-lg-4 d-flex flex-column">
            <div className="form-group row label-box-div">
              <div className={errorMsgNomen ? "inputWithError d-flex" : "field-container d-flex gap-1"} >
                <label className="fs-6 m-0 text-nowrap minWidth80" id="media-query-label-report">Artikal:</label>
                <div className="col-10">
                  {/* autokomplit za komitenta */}
                  <Autocomplete
                    // svaki od ponudjenih artikala mora biti jedinstven(svaka stavka(child) u objektu artikal mora imati jedinstven kljuc)
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderOption={(props, artikal) => {
                      // svaki artikal renderujemo kao -li element unutar koga postoje 2 spana, te su odvojeni ps-3
                      return (
                        <li {...props} key={artikal.ID}>
                          <span>{artikal?.SIFRA}</span>
                          <span className="ps-1">{artikal?.NAZIV}</span>
                        </li>
                      );
                    }}
                    tabIndex={10}
                    {...defaultPropsNomen}
                    value={artikal}
                    disablePortal={true}
                    onFocus={() => {
                      unosIspravanDo("ARTIKAL");
                    }}
                    // ako se promijeni artikal, uzima podatke za novi izabrani artikal
                    onChange={(event, newValue) => {
                      setForm((prev) => ({
                        ...prev,
                        NOMENID: newValue && newValue != null ? newValue.ID : "",
                        POREZID:
                          newValue && newValue != null ? newValue.POREZID : "",
                        JM: newValue && newValue != null ? newValue.JM : "",
                        SIFRA: newValue && newValue != null ? newValue.SIFRA : "",
                      }));
                      setArtikal(newValue);
                      // biramo prvi slobodan redni broj
                      getPrometFirstFreeNumberRbr();
                      // fokusiramo kolicina polje da bi se ucitale jm, cijena, porez
                      setTimeout(() => {
                        kolicinaRef.current.focus();
                      }, 50);
                    }}

                    renderInput={(params) => (
                      <TextField
                        {...params}
                        maxLength="283"
                        className={itemUpdate ? "form-controlst" : ""}
                        name="NOMENID"
                        ref={nomenRef}
                        readOnly={itemUpdate}
                        placeholder=" artikal"
                        variant="standard"
                      />
                    )}
                  />
                </div>
              </div>
              {/* prikazivanje greske ukoliko artikal nije izabran */}
              {errorMsgNomen && (
                <div className="col-12">
                  <span className="error-message">{errorMsgNomen}</span>
                </div>
              )}
            </div>
          </div>

          {/* jedinica mjere */}
          <div className="col-2 p-0 d-flex flex-column">
            <div className="col-6">
              <label
                className="fs-6 m-0 text-nowrap gray-500 minWidth80 px-1"
                htmlFor="JM"
              >
                JM
              </label>
              <input
                className="px-2 m-0 text-center w-50 info-input"
                readOnly={true}
                type="text"
                ref={jmRef}
                placeholder=""
                id="JM"
                name="JM"
                value={form ? form.JM : ""}
                onChange={(e) => handleChangePromet(e)}
              />
            </div>
          </div>
        </Row>
        <Row>
          {trosakOpen && !itemUpdate && (
            <div className="row col-12  ps-3">
              <div className="col-12 d-flex gap-2 justify-content-center">
                {/* trosak PDV obveznik*/}
                <div className="d-flex gap-1">
                  <label className="fs-6 m-0 text-nowrap">
                    Trošak od PDV obveznika(%):
                  </label>
                  <NumericFormat
                    getInputRef={trosakPRef}
                    type="number"
                    className="text-end"
                    name="TROSAKP"
                    tabIndex={18}
                    maxLength={2}
                    value={form.TROSAKP}
                    onFocus={() => {
                      unosIspravanDo("TROSAKODPDVOBVEZNIKA");
                      setPromjenaNabavneCijene(false);
                    }}
                    onChange={(e) => {
                      handleChangePrometS(e);
                      // vracamo marzu na default
                      setForm((prev) => ({
                        ...prev,
                        RAZCEN: "",
                        IZNRAZD: "",
                      }));
                    }}
                  />
                </div>
                {/* trosak, iznos od PDV obveznika */}
                <div className="d-flex gap-1">
                  <label className="fs-6 m-0 text-nowrap">Iznos:</label>
                  <NumericFormat
                    type="number"
                    className="text-end"
                    name="IZNTROSAK"
                    tabIndex={19}
                    value={form.IZNTROSAK}
                    onFocus={() => {
                      unosIspravanDo("IZNOSTROSKAODPDVOBVEZNIKA");

                      // ukoliko je unijet procenat troska od pdv obveznika izracunaj vrijednost na osnovu procenta
                      if (form.TROSAKP != "") {
                        let iznFaktMinusRabat = getFakcenMinusRabatUlaz(
                          form.RABAT,
                          form.IZNFAKT
                        );
                        let iznTrosak = roundTO(
                          iznosNaOsnovuProcenta(
                            iznFaktMinusRabat,
                            form.TROSAKP
                          ),
                          2
                        );
                        setIznosTroska(iznTrosak);
                        setForm((prev) => ({
                          ...prev,
                          IZNTROSAK: iznTrosak,
                        }));
                      }
                    }}
                    onChange={(e) => {
                      handleChangePrometS(e);
                      // vracamo marzu na default
                      setForm((prev) => ({
                        ...prev,
                        RAZCEN: "",
                        IZNRAZD: "",
                      }));
                      setMarzaValue(null);
                    }}
                  />
                </div>
                {/* trosak Trošak1(%):  */}
                <div className="d-flex gap-1">
                  <label className="fs-6 m-0 text-nowrap">Trošak1(%):</label>
                  <NumericFormat
                    type="number"
                    className="text-end"
                    name="TROSAK1PER"
                    value={form.TROSAK1PER}
                    tabIndex={20}
                    onFocus={() => {
                      unosIspravanDo("TROSAK1PER");
                      let procenat = procenatNaOsnovuIznosa(
                        form.IZNTROSAK,
                        form.NABCEN,
                        form.KOLICINA
                      );

                      setForm((prev) => ({
                        ...prev,
                        TROSAKP: procenat,
                      }));
                    }}
                    onChange={(e) => {
                      handleChangePrometS(e);
                      // vracamo marzu na default
                      setForm((prev) => ({
                        ...prev,
                        RAZCEN: "",
                        IZNRAZD: "",
                      }));
                      setMarzaValue(null);
                    }}
                  />
                </div>
                {/* trosak Iznos Trošak1 */}
                <div className="d-flex gap-1">
                  <label className="fs-6 m-0 text-nowrap">Iznos:</label>
                  <NumericFormat
                    type="number"
                    tabIndex={21}
                    className="text-end"
                    name="IZNTROSAK1PER"
                    value={form.IZNTROSAK1PER}
                    onFocus={() => {
                      unosIspravanDo("IZNOSTROSKA1PER");
                      let iznFaktMinusRabat = getFakcenMinusRabatUlaz(
                        form.RABAT,
                        form.IZNFAKT
                      );
                      let iznTrosak = roundTO(
                        iznosNaOsnovuProcenta(
                          iznFaktMinusRabat,
                          form.TROSAK1PER
                        ),
                        2
                      );
                      let sumaTroskova = roundTO(iznosTroska + iznTrosak, 2);
                      setIznosTroska(sumaTroskova);
                      setForm((prev) => ({
                        ...prev,
                        IZNTROSAK1PER: iznTrosak,
                        IZND:
                          form.NABCEN * form.KOLICINA + Number(sumaTroskova),
                      }));
                    }}
                    onChange={(e) => {
                      handleChangePrometS(e);
                      // vracamo marzu na default
                      setForm((prev) => ({
                        ...prev,
                        RAZCEN: "",
                        IZNRAZD: "",
                      }));
                      setMarzaValue(null);
                      let prethodniTrosak = Number(iznosTroska);
                      if (prethodniTrosak > 0) {
                        setIznosTroska(
                          prethodniTrosak + Number(e.target.value)
                        );
                      }
                      setIznosTrosak1(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </Row>
        <Row>
          <div className="col-12 d-flex flex-lg-row flex-wrap my-3 p-0">
            <div className="col-6 col-lg-1 ">
              <div className={errorMsgRbr ? "text-end inputWithError " : "text-end"}>
                <div>
                  <label className="fs-6 m-0 px-1">Rbr.</label>
                  {/* komponenta za formatiranje brojeva */}
                  <NumericFormat
                    type="number"
                    getInputRef={rbrRef}
                    maxLength="3"
                    decimalScale={0}
                    tabIndex={10}
                    name="RBR"
                    value={form.RBR}
                    onFocus={() => {
                      unosIspravanDo("RBR");
                      if (komt && komt !== clients.NAZIV) {
                        getPrometInfo();
                      } else {
                        getPromet();
                      }
                      getPrometFirstFreeNumberRbr();
                      let rbr = form.RBR;
                      setForm((prev) => ({
                        ...prev,
                        ...defaultForm,
                        RBR: rbr,
                      }));
                      setItemUpdate(false);
                      setTrosakOpen(false);
                      setArtikal(null);
                      setIznosTroska(0);
                      setIznosTrosak1(null);
                      setCalculatedMarza(false);
                    }}
                    onChange={(e) => {
                      handleChangePrometS(e);
                    }}
                  />
                  {/* prikazivanje poruke o pogresnom upisu  */}
                  {errorMsgRbr && (
                    <div className="col-12">
                      <span className="error-message">{errorMsgRbr}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-1 ps-1">
              <div className='col-form-right'>
                <div className={errorInputSifra ? "inputWithError" : "field-container"}>
                  <label className="fs-6 m-0 px-1">Šifra</label>
                  {/* komponenta za formatiranje brojeva */}
                  <NumericFormat
                    type="text"
                    getInputRef={sifraRef}
                    className='field-alignment p-1'
                    maxLength="6"
                    // broj decimala
                    decimalScale={0}
                    tabIndex={11}
                    placeholder="0"
                    name="SIFRA"
                    value={form.SIFRA}
                    onFocus={() => {
                      setSifraFocus(true);
                      unosIspravanDo("SIFRA");
                      // ukoliko je korisnik kliknuo na edit, izbaci gresku
                      if (itemUpdate) {
                        setModalEditErrorOpen(true);
                      }
                    }}
                    onChange={(e) => {
                      handleChangePrometS(e);
                      setForm((prev) => ({
                        ...prev,
                        SIFRA: e.target.value,
                      }));
                    }}
                  />
                  {/* prikazivanje poruke o pogresnom upisu  */}
                  {errorMsgSifra && (
                    <div className="col-12">
                      <span className="error-message">{errorMsgSifra}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-1 ps-1">
              <div className='col-form-right'>
                <div
                  className={errorMsgKolicina ? "text-end inputWithError" : "text-end"}
                >
                  <div>
                    <label className="fs-6 m-0 px-1">Količina</label>
                    {/* komponenta za formatiranje brojeva */}
                    <NumericFormat
                      type="text"
                      getInputRef={kolicinaRef}
                      maxLength="3"
                      // broj decimala
                      tabIndex={12}
                      className="text-dark text-end field-alignment p-1"
                      placeholder="0"
                      name="KOLICINA"
                      value={form.KOLICINA}
                      onFocus={() => {
                        unosIspravanDo("KOLICINA");
                        // ukoliko je korisnik kliknuo na edit, izbaci gresku
                        if (itemUpdate) {
                          setModalEditErrorOpen(true);
                        }
                        // ukoliko polja ispod nisu prazna
                        if (
                          prometForm.SKLAID !== "" &&
                          prometForm.DOK !== "" &&
                          form.RBR !== 0 &&
                          form.SIFRA !== ""
                        ) {
                          getArticleInfo();
                        }
                      }}
                      onChange={(e) => {
                        handleChangePrometS(e);
                        setForm((prev) => ({
                          ...prev,
                          KOLICINA: e.target.value,
                        }));
                      }}
                    />
                    {/* prikazivanje poruke o pogresnom upisu  */}
                    {errorMsgKolicina && (
                      <div className="col-12">
                        <span className="error-message">{errorMsgKolicina}</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-1 ps-1">
              {/* Fakturna cijena */}
              <div className="text-end">
                <div>
                  <label className="fs-6 m-0 px-1 text-nowrap">
                    Fakturna cijena
                  </label>
                  {/* komponenta za formatiranje brojeva */}
                  <NumericFormat
                    type="number"
                    maxLength="3"
                    className="text-dark text-end"
                    decimalScale={3}
                    placeholder="0"
                    tabIndex={13}
                    name="FAKTCEN"
                    getInputRef={faktCenRef}
                    value={form.FAKTCEN}
                    onFocus={() => {
                      // ukoliko je korisnik kliknuo na edit, izbaci gresku
                      if (itemUpdate) {
                        setModalEditErrorOpen(true);
                      }
                      unosIspravanDo("FAKTURNACIJENA");
                    }}
                    onChange={(e) => {
                      handleChangePrometS(e);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-1 ps-1">
              <div
                className={errorMsgIznfakt ? "text-end inputWithError" : "text-end"}
              >
                {/* Fakturni iznos */}
                <div>
                  <label className="fs-6 m-0 px-1 text-nowrap">
                    Fakturni iznos
                  </label>
                  {/* komponenta za formatiranje brojeva */}
                  <NumericFormat
                    type="text"
                    getInputRef={fiRef}
                    maxLength="3"
                    decimalScale={2}
                    tabIndex={14}
                    className="text-dark text-end field-alignment p-1"
                    placeholder="0"
                    name="IZNFAKT"
                    value={form.IZNFAKT}
                    onFocus={() => {
                      // ukoliko je korisnik kliknuo na edit, izbaci gresku
                      if (itemUpdate) {
                        setModalEditErrorOpen(true);
                      }
                      unosIspravanDo("FAKTURNIIZNOS");
                      setForm((prev) => ({
                        ...prev,
                        IZNFAKT: form.KOLICINA * form.FAKTCEN, // fakturni iznos
                        IZND: form.KOLICINA * form.NABCEN, // nabavni iznos
                      }));
                    }}
                    onChange={(e) => {
                      handleChangePrometS(e);
                    }}
                  />
                  {/* prikazivanje poruke o pogresnom upisu  */}
                  {errorMsgIznfakt && (
                    <div className="col-12">
                      <span className="error-message">{errorMsgIznfakt}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-1 ps-1">
              <div className="text-end">
                {/* Rabat% */}
                <div>
                  <label className="fs-6 m-0 px-1 text-nowrap">Rabat %</label>
                  {/* komponenta za formatiranje brojeva */}
                  <NumericFormat
                    type="number"
                    getInputRef={rabRef}
                    maxLength="3"
                    className="text-dark text-end"
                    // broj decimala
                    decimalScale={3}
                    placeholder="0"
                    tabIndex={15}
                    name="RABAT"
                    value={form.RABAT}
                    onFocus={() => {
                      // ukoliko je korisnik kliknuo na edit, izbaci gresku
                      if (itemUpdate) {
                        setModalEditErrorOpen(true);
                      }
                      unosIspravanDo("RABAT");
                      if (form.FAKTCEN !== form.IZNFAKT / form.KOLICINA) {
                        setForm((prev) => ({
                          ...prev,
                          FAKTCEN: form.IZNFAKT / form.KOLICINA,
                        }));
                      }
                      setPrometSStatesToNull();
                    }}
                    onChange={(e) => {
                      handleChangePrometS(e);
                      setForm((prev) => ({
                        ...prev,
                        RABAT: e.target.value,
                      }));
                      setRabatPercentage(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-1 ps-1">
              <div className="text-end">
                {/* Rabat */}
                <div>
                  <label className="fs-6 m-0 px-1 text-nowrap">Rabat</label>
                  <NumericFormat
                    type="number"
                    getInputRef={rabatRef}
                    maxLength="13"
                    className="text-dark text-end"
                    decimalScale={2}
                    tabIndex={16}
                    onFocus={() => {
                      // ukoliko je korisnik kliknuo na edit, izbaci gresku
                      if (itemUpdate) {
                        setModalEditErrorOpen(true);
                      }
                      unosIspravanDo("IZNRABAT");
                      // provjera da li iznos fakture postoji
                      if (form.IZNFAKT && form.IZNFAKT != "") {
                        // izracunavanje iznosa rabata
                        let iznosRabata = getIznosRabataIzlaz(
                          form.RABAT,
                          form.IZNFAKT
                        );
                        setRabat(iznosRabata);

                        let nabcen = getFakcenMinusRabatUlaz(
                          form.RABAT,
                          form.IZNFAKT / form.KOLICINA
                        );
                        // setTotalIznosPDV(roundTO(((totals.IZNFAKT - totals.IZNRABAT) * totals.PDV) / 100, 2));
                        const ulPdv = ((form.IZNFAKT - form.IZNRABAT) * form.PDV) / 100
                        setForm((prev) => ({
                          ...prev,
                          IZNRABAT: iznosRabata,
                          NABCEN: nabcen,
                          FAKTCEN: form.IZNFAKT / form.KOLICINA,
                          ULPDV: ulPdv
                        }));
                      }
                    }}
                    placeholder="0"
                    name="IZNRABAT"
                    value={form.IZNRABAT}
                    onChange={(e) => {
                      handleChangePrometS(e);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-1 ps-1">
              {/* trosak */}
              <div className="text-end">
                <div>
                  <label className="fs-6 m-0 px-1">Trošak</label>
                  <input
                    type="text"
                    ref={trosakRef}
                    maxLength="1"
                    className="text-dark text-end px-2"
                    placeholder=""
                    name="TROSAK"
                    tabIndex={17}
                    value={form?.T}
                    onFocus={(e) => {
                      // ukoliko je korisnik kliknuo na edit, izbaci gresku
                      if (itemUpdate) {
                        setModalEditErrorOpen(true);
                      }
                      unosIspravanDo("TROSAK");
                      e.target.select();
                      // provjera da li je fakturni iznos unijet i da li se iznos rabata promijenio
                      if (form.IZNFAKT != "" && rabat != form.IZNRABAT) {
                        // pronalazak procenta rabata iz iznosa rabata i cijene
                        let rabatPercentage = getRabvelFromValues(
                          form.IZNRABAT,
                          form.IZNFAKT
                        );
                        // pronalazak nabavne cijene iz iznosa procenta rabata i cijene
                        let nabavnaCijena = getFakcenMinusRabatUlaz(
                          rabatPercentage,
                          form.FAKTCEN
                        );
                        // dodjeljivanje dobijenih vrijednosti u formu
                        setForm((prev) => ({
                          ...prev,
                          RABAT: rabatPercentage,
                          NABCEN: nabavnaCijena,
                        }));
                      }
                    }}
                    onChange={(e) => {
                      handleChangePrometS(e);
                      setForm((prev) => ({
                        ...prev,
                        T: e.target.value,
                      }));
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-1 ps-1">
              <div className="text-end">
                {/* marza% */}
                <div>
                  <label className="fs-6 m-0 px-1 text-nowrap">Marža %:</label>
                  {/* komponenta za formatiranje brojeva */}
                  <NumericFormat
                    type="number"
                    getInputRef={rabvelRef}
                    className="text-dark text-end"
                    onFocus={() => {
                      // ukoliko je korisnik kliknuo na edit, izbaci gresku
                      if (itemUpdate) {
                        setModalEditErrorOpen(true);
                      }
                      unosIspravanDo("MARZAP");
                      setCijena(null);
                      if (form?.T == "T" || form?.T == "t") {
                        setTrosakOpen(true);
                        // redirekcija do polja trosakp
                        if (
                          form.TROSAKP == "" &&
                          form.TROSAK1PER == "" &&
                          form.IZNTROSAK1PER == ""
                        ) {
                          setTimeout(() => {
                            trosakPRef.current.focus();
                          }, 50);
                        }
                      } else {
                        setIznosTroska(0);
                        setTrosakOpen(false);
                      }

                      if (
                        (form.TROSAK1PER == "" && form.IZNTROSAK1PER != "") ||
                        iznosTrosak1
                      ) {
                        let procenat = procenatNaOsnovuIznosa(
                          iznosTrosak1 ? iznosTrosak1 : form.IZNTROSAK1PER,
                          form.NABCEN,
                          form.KOLICINA
                        );
                        let iznosSvihTroskova =
                          Number(form.IZNTROSAK) +
                          Number(
                            iznosTrosak1 ? iznosTrosak1 : form.IZNTROSAK1PER
                          );
                        setIznosTroska(iznosSvihTroskova);

                        let novaNabcen = getNabavnaCijena(
                          iznosSvihTroskova,
                          form.KOLICINA,
                          form.NABCEN
                        );

                        let nabavniIznos = getNabavniIznos(
                          form.KOLICINA,
                          novaNabcen
                        );

                        setForm((prev) => ({
                          ...prev,
                          NABCEN: novaNabcen,
                          IZND: nabavniIznos,
                          TROSAK1PER: procenat,
                        }));

                        setCalculatedMarza(true);
                      }
                    }}
                    maxLength="13"
                    decimalScale={3}
                    placeholder="0"
                    tabIndex={22}
                    name="RAZCEN"
                    value={form.RAZCEN}
                    onChange={(e) => {
                      handleChangePrometS(e);
                      setForm((prev) => ({
                        ...prev,
                        RAZCEN: e.target.value,
                      }));
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-1 ps-1">
              <div className="text-end">
                {/* marza */}
                <div>
                  <label className="fs-6 m-0 px-1">Marža</label>
                  <NumericFormat
                    type="number"
                    getInputRef={marzapRef}
                    onFocus={() => {
                      // ukoliko je korisnik kliknuo na edit, izbaci gresku
                      if (itemUpdate) {
                        setModalEditErrorOpen(true);
                      }
                      unosIspravanDo("MARZA");
                      setCijena(null);
                      let iznosMarze = getMarza(
                        form.IZNFAKT + iznosTroska,
                        form.IZNRABAT,
                        form.RAZCEN
                      );
                      setMarzaValue(iznosMarze);
                      setForm((prev) => ({
                        ...prev,
                        IZNRAZD: iznosMarze,
                      }));
                    }}
                    maxLength="11"
                    tabIndex={23}
                    decimalScale={3}
                    className="text-dark text-end"
                    placeholder="0"
                    name="IZNRAZD"
                    value={form.IZNRAZD}
                    onChange={(e) => {
                      handleChangePrometS(e);
                      setMarzaValue(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-1 ps-1">
              <div className='col-form-right'>
                <div className={errorMsgPrice ? "text-end inputWithError" : "text-end"}>
                  {/* cijena */}
                  <div className="fs-6 ">
                    <label className="fs-6 m-0 px-1">Cijena</label>
                    <NumericFormat
                      type="text"
                      getInputRef={cijenaRef}
                      className="text-dark text-end field-alignment p-1"
                      maxLength="15"
                      decimalScale={3}
                      tabIndex={24}
                      placeholder="0"
                      name="MALCEN"
                      value={form.MALCEN}
                      onFocus={() => {
                        // ukoliko je korisnik kliknuo na edit, izbaci gresku
                        if (itemUpdate) {
                          setModalEditErrorOpen(true);
                        }
                        unosIspravanDo("CIJENA");
                        setMalcenPFocus(true);
                        // provjera da li se iznos marze postoji i da li je promijenjen
                        if (marzaValue && marzaValue == form.IZNRAZD) {
                          // sprecava promjenu cijene na klik provjerom da li je promijenjen iznos troska
                          if (!iznosTroska) {
                            // kalkulacija procenta marze(potrebno ukoliko je korisnik unio samo vrijednos marze)
                            let procenatMarze = getMarzavelFromValues(
                              form.IZNFAKT,
                              form.IZNRABAT,
                              marzaValue
                            );

                            // kalkulacija cijene, u zavisnosti od vrste skladista.
                            let price = calculateFinalPrice(
                              selektovanaVrstaSkladista,
                              form.FAKTCEN,
                              form.RABAT,
                              procenatMarze,
                              form.PDV
                            );

                            // dobijanje iznosa poreza
                            let porezUIznosu = getPorezUIznosu(
                              form.PDV,
                              form.KOLICINA,
                              price
                            );

                            setForm((prev) => ({
                              ...prev,
                              MALCEN: price,
                              IZPDV: porezUIznosu,
                            }));
                          }

                          // provjera ukoliko je doslo do promjene
                          if (marzaValue != form.IZNRAZD) {
                            // dodajemo trosak na cijenu i racunamo procenat marze
                            let iznosMarze = getMarzavelFromValues(
                              form.IZNFAKT + iznosTroska,
                              form.IZNRABAT,
                              marzaValue
                            );

                            // kalkulacija cijene, u zavisnosti od vrste skladista.
                            let price = calculateFinalPrice(
                              selektovanaVrstaSkladista,
                              form.FAKTCEN,
                              form.RABAT,
                              form.RAZCEN,
                              form.PDV
                            );

                            // dobijanje iznosa poreza
                            let porezUIznosu = getPorezUIznosu(
                              form.PDV,
                              form.KOLICINA,
                              cijena ? cijena : price
                            );

                            setForm((prev) => ({
                              ...prev,
                              RAZCEN: iznosMarze,
                              MALCEN: cijena ? cijena : price,
                              IZPDV: porezUIznosu,
                            }));
                          }
                          // ukoliko marza nije unijeta a cijena(useState) postoji,
                          // izracunavano iznos pdv-a i setujemo cijenu
                          if (!marzaValue && cijena) {
                            // dobijanje iznosa poreza
                            let iznosPDV = getPorezUIznosu(
                              form.PDV,
                              form.KOLICINA,
                              cijena
                            );
                            setForm((prev) => ({
                              ...prev,
                              MALCEN: cijena,
                              IZPDV: iznosPDV,
                            }));
                          }
                        }
                        // provjera da li je doslo do promjene iznosa marze
                        else if (marzaValue && marzaValue !== form.MARZA) {
                          // dodajemo trosak na cijenu i racunamo procenat marze
                          let iznosMarze = getMarzavelFromValues(
                            form.IZNFAKT + iznosTroska,
                            form.IZNRABAT,
                            marzaValue
                          );
                          // kalkulacija cijene, u zavisnosti od vrste skladista.
                          let price = calculateFinalPrice(
                            selektovanaVrstaSkladista,
                            form.FAKTCEN,
                            form.RABAT,
                            iznosMarze,
                            form.PDV
                          );
                          // dobijanje iznosa poreza
                          let iznosPDV = getPorezUIznosu(
                            form.PDV,
                            form.KOLICINA,
                            price
                          );
                          setCijena(price);
                          setForm((prev) => ({
                            ...prev,
                            RAZCEN: iznosMarze,
                            MALCEN: price,
                            IZPDV: iznosPDV,
                          }));
                        }
                      }}
                      onChange={(e) => {
                        if (form.IZNFAKT !== e.target.value && form.LAGER === 0) {
                          setUpdateCijenaUCjenovniku(true);
                        }
                        handleChangePrometS(e);
                        setCijena(e.target.value);
                      }}
                    />
                    {/* prikazivanje poruke o pogresnom upisu  */}
                    {errorMsgPrice && (
                      <div className="col-12">
                        <span className="error-message">{errorMsgPrice}</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-1 ps-1">
              <div className={errorMsgIznos ? "text-end inputWithError" : "text-end"}>
                {/* iznos */}
                <div>
                  <label className="fs-6 m-0 px-1">Iznos</label>
                  <input
                    ref={iznosRef}
                    type="text"
                    className="text-dark text-end m-0 px-1"
                    tabIndex={25}
                    decimalscale={3}
                    name="IZNOS"
                    placeholder="0"
                    onFocus={() => {
                      // ukoliko je korisnik kliknuo na edit, izbaci gresku
                      if (itemUpdate) {
                        setModalEditErrorOpen(true);
                      }

                      unosIspravanDo("IZNOS");

                      // ukoliko cijena ne postoji,
                      // niti cijena nije ista kao prosla,
                      // nije doslo do promjene cijene,
                      // if (cijena != "" && cijena != proslaVrijednostCijene) {
                      //   setModalChangePriceOpen(true);
                      // } else {
                      //   // ukoliko postoji doslo je do promjene te prikazujemo modal
                      //   setModalChangePriceOpen(false);
                      //   setCijena(null);
                      // }

                      if (cijena != proslaVrijednostCijene) {
                        setModalChangePriceOpen(false);
                        //  setCijena(null);
                      } else {
                        setModalChangePriceOpen(true);
                      }
                      // provjera da li je cijena manja ili jednaka 0
                      // racunanje unaprijed
                      if (form.MALCEN <= 0) {
                      } else {
                        // kalkulisanje iznosa
                        let ukupno = calculateFinalSum(
                          selektovanaVrstaSkladista,
                          form.FAKTCEN,
                          form.RABAT,
                          form.RAZCEN,
                          form.PDV,
                          form.KOLICINA
                        );

                        // dobijanje iznosa poreza
                        let iznosPDV = getPorezUIznosu(
                          form.PDV,
                          form.KOLICINA,
                          cijena != null ? cijena : form.MALCEN
                        );

                        // update forme na osnovu dobijenih vrijednosti
                        setForm((prev) => ({
                          ...prev,
                          IZPDV: iznosPDV,
                          IZNMALD: ukupno,
                        }));

                        // ukoliko cijena postoji i ako je promijenjena vrijednost prodajne cijene
                        if (cijena && cijena !== form.MALCEN) {
                          // dobijanje iznosa poreza
                          let porezUIznosu = getPorezUIznosu(
                            form.PDV,
                            form.KOLICINA,
                            cijena
                          );

                          // izracunavanje maloprodajne cijene
                          let maloprodajniIznos = getIznosMaloprodaje(
                            cijena,
                            form.KOLICINA
                          );

                          // dobijanje iznosa marze
                          let marzaValue = getIznosMarze(
                            selektovanaVrstaSkladista,
                            maloprodajniIznos, // mnozenje cijene i kolicine
                            iznosTroska,
                            roundTO(form.IZNFAKT - form.IZNRABAT, 2), // oduzimanje iznosa rabata fakturnom iznosu
                            porezUIznosu,
                            0
                          );

                          // dodajemo trosak na cijenu i racunamo procenat marze
                          let marzaPercentage = getMarzavelFromValues(
                            form.IZNFAKT + iznosTroska,
                            form.IZNRABAT,
                            marzaValue
                          );

                          // update forme na osnovu dobijenih vrijednosti
                          setForm((prev) => ({
                            ...prev,
                            IZNRAZD: marzaValue,
                            RAZCEN: marzaPercentage,
                            IZNMALD: maloprodajniIznos,
                            IZPDV: porezUIznosu,
                          }));

                          setMarzaValue(marzaValue);
                        } else if (cijena) {
                          // ukoliko cijena postoji
                          // dobijanje iznosa poreza
                          let porezUIznosu = getPorezUIznosu(
                            form.PDV,
                            form.KOLICINA,
                            form.MALCEN
                          );

                          // izracunavanje maloprodajne cijene
                          let maloprodajniIznos = getIznosMaloprodaje(
                            cijena,
                            form.KOLICINA
                          );

                          // dobijanje iznosa marze
                          let marzaValue = getIznosMarze(
                            selektovanaVrstaSkladista,
                            maloprodajniIznos, // mnozenje cijene i kolicine
                            iznosTroska,
                            roundTO(form.IZNFAKT - form.IZNRABAT, 2), // oduzimanje iznosa rabata fakturnom iznosu
                            porezUIznosu,
                            0
                          );

                          // dodajemo trosak na cijenu i racunamo procenat marze
                          let marzaPercentage = getMarzavelFromValues(
                            form.IZNFAKT + iznosTroska,
                            form.IZNRABAT,
                            marzaValue
                          );

                          // update forme na osnovu dobijenih vrijednosti
                          setForm((prev) => ({
                            ...prev,
                            IZNRAZD: marzaValue,
                            RAZCEN: marzaPercentage,
                            IZNMALD: maloprodajniIznos,
                            IZPDV: porezUIznosu,
                          }));
                          setMarzaValue(marzaValue);
                        } else {
                          // dobijanje iznosa poreza
                          let porezUIznosu = getPorezUIznosu(
                            form.PDV,
                            form.KOLICINA,
                            form.MALCEN
                          );

                          // izracunavanje maloprodajne cijene
                          let maloprodajniIznos = getIznosMaloprodaje(
                            form.MALCEN,
                            form.KOLICINA
                          );
                          // ukoliko marza nije promijenjena
                          if (form.RAZCEN == "") {
                            // dobijanje procenta marze
                            let marzaValue = getIznosMarze(
                              selektovanaVrstaSkladista,
                              maloprodajniIznos,
                              iznosTroska,
                              roundTO(form.IZNFAKT - form.IZNRABAT, 2), // oduzimanje iznosa rabata fakturnom iznosu
                              porezUIznosu,
                              0
                            );

                            // dodajemo trosak na cijenu i racunamo procenat marze
                            let marzaPercentage = getMarzavelFromValues(
                              form.IZNFAKT + iznosTroska,
                              form.IZNRABAT,
                              marzaValue
                            );

                            // update forme na osnovu dobijenih vrijednosti
                            setForm((prev) => ({
                              ...prev,
                              IZNRAZD: marzaValue,
                              RAZCEN: marzaPercentage,
                              IZNMALD: maloprodajniIznos,
                              IZPDV: porezUIznosu,
                            }));
                            setMarzaValue(marzaValue);
                          } else {
                            // izracunavanje marze kada marza kao i trosak postoje
                            let iznosMarze = getMarzavelFromValues(
                              form.IZNFAKT + iznosTroska,
                              form.IZNRABAT,
                              marzaValue
                            );
                            // izracunavanje prodajne cijene
                            let malcen = calculateFinalPrice(
                              selektovanaVrstaSkladista,
                              form.FAKTCEN + iznosTroska / form.KOLICINA,
                              form.RABAT,
                              iznosMarze,
                              form.PDV
                            );

                            // izracunavanje maloprodajnog iznosa
                            let maloprodajniIznos = getIznosMaloprodaje(
                              malcen,
                              form.KOLICINA
                            );
                            // update forme na osnovu dobijenih vrijednosti
                            setForm((prev) => ({
                              ...prev,
                              MALCEN: malcen,
                              RAZCEN: iznosMarze,
                              IZNMALD: maloprodajniIznos,
                            }));
                          }
                        }
                      }
                    }}
                    value={form.IZNMALD}
                    onChange={(e) => handleChangePrometS(e)}
                  />
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Container>
      {/* bootstrap 5 tabela */}
      <div className="table-responsive">
        <table className="table table-sm table-striped table-hover table-light ">
          <thead>
            <tr>
              {/* iteracija kroz columns i dodjeljivanje table headera vrijednostima iz columns[i].name */}
              {columns.map((column, index) => {
                return (
                  <th key={index} scope="col" className={column.class}>
                    {column.name}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {/* iteracija kroz niz kalkulacija i dodjeljivanje klasa vrijednostima iz columns[i].class */}
            {kalkulacija &&
              kalkulacija?.map((c, index) => {
                return (
                  <tr
                    onDoubleClick={() => {
                      onEdit(c);
                    }}
                    key={index}
                    className="col-12 user-select-none"
                  >
                    <td className={columns[0].class}>{c.RBR}</td>
                    <td className={columns[1].class}>{c.SifraArtikla}</td>
                    <td className={columns[2].class}>{c.NazivArtikla}</td>
                    <td className={columns[3].class}>{c.JM}</td>
                    <td className={columns[4].class}>
                      {numberWithCommasColumns(c.KOL)}
                    </td>
                    <td className={columns[5].class}>
                      {numberWithCommasColumns(c.FAKTCEN)}
                    </td>
                    <td className={columns[6].class}>
                      {numberWithCommasColumns3(c.IZNFAKT)}
                    </td>
                    <td className={columns[7].class}>{c.T == "" ? "" : "T"}</td>
                    <td className={columns[8].class}>
                      {numberWithCommasColumns3(c.RAZCEN)}
                    </td>
                    <td className={columns[9].class}>
                      {numberWithCommasColumns3(c.IZNRAZD)}
                    </td>
                    <td className={columns[10].class}>
                      {numberWithCommasColumns3(c.MALCEN)}
                    </td>
                    <td className={columns[11].class}>
                      {numberWithCommasColumns(c.IZNMALD)}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>

      {/* paginacija */}
      {duzinaNiza > 0 && (
        // wrapper za select i paginaciju
        <div className="d-flex justify-content-center align-items-center gap-1">
          {/* paginacija za bootstrap tabelu */}
          <div className="mb-3">
            <select
              className="form-select"
              value={parseInt(itemsPerPage)}
              onChange={(e) => {
                setItemsPerPage(parseInt(e.target.value));
                setCurrentPage(1);
              }}
            >
              <option def="5">5</option>
              <option defaultValue value="10">
                10
              </option>
            </select>
          </div>
          <nav aria-label="Page navigation">
            <ul className="pagination">
              <li
                className="page-item"
                onClick={(event) => {
                  onFirstClick(event, setCurrentPage);
                }}
              >
                <a type="button" className="page-link user-select-none">
                  First
                </a>
              </li>
              <li
                className="page-item"
                onClick={(event) => {
                  onPreviosClick(event, currentPage, setCurrentPage);
                }}
              >
                <a className="page-link user-select-none" type="button">
                  Previous
                </a>
              </li>

              {pageNumbers.map((pageNumber) => (
                <li
                  type="button"
                  key={pageNumber}
                  className={
                    currentPage == pageNumber
                      ? "selected page-item user-select-none"
                      : "page-item user-select-none"
                  }
                >
                  <a
                    className="page-link user-select-none"
                    id={pageNumber}
                    onClick={(event) =>
                      onPaginationClick(event, setCurrentPage)
                    }
                  >
                    {pageNumber}
                  </a>
                </li>
              ))}

              <li
                className="page-item"
                onClick={(event) => {
                  onNextClick(
                    event,
                    currentPage + 1,
                    setCurrentPage,
                    duzinaNiza
                  );
                }}
              >
                <a type="button" className="page-link user-select-none">
                  Next
                </a>
              </li>
              <li
                className="page-item"
                onClick={(event) => {
                  onLastClick(event, setCurrentPage, duzinaNiza);
                }}
              >
                <a type="button" className="page-link user-select-none">
                  Last
                </a>
              </li>
            </ul>
          </nav>
        </div>
      )}
      {/* totali */}
      <div>
        <ul className="nav flex-column flex-lg-row justify-content-end">
          <div className="d-flex ms-auto col-9 justify-content-end align-items-center fixed-bottom  mb-3 me-3 gap-3">
            <div className="user-select-none col-2">
              <label className="form-label text-nowrap">Fakturni Iznos</label>
              <input
                type="text"
                value={numberWithCommasColumns(totalIznosNabavneCijene)}
                className="text-end px-2 color: 'black' fs-6 "
                readOnly={true}
              />
            </div>
            <div className="user-select-none col-2">
              <label className="form-label text-nowrap">Rabat</label>
              <input
                type="text"
                value={numberWithCommasColumns(totalRabat)}
                className="text-end px-2 color: 'black' fs-6 "
                readOnly={true}
              />
            </div>
            <div className="user-select-none col-2">
              <label className="form-label text-nowrap">
                Fakturni Iznos - Iznos Rabata
              </label>
              <input
                type="text"
                readOnly={true}
                value={numberWithCommasColumns(totalNabavnaCijena)}
                className="text-end px-2 color: 'black' fs-6 "
              />
            </div>
            <div className="user-select-none col-2">
              <label className="form-label text-nowrap">Iznos Poreza</label>
              <input
                type="text"
                value={numberWithCommasColumns(totalIznosPDV)}
                className="text-end px-2 color: 'black' fs-6 "
                readOnly={true}
              />
            </div>
            <div className="user-select-none col-2">
              <label className="form-label text-nowrap">Marža</label>
              <input
                type="text"
                value={numberWithCommasColumns(totalIznosMarze)}
                className="text-end px-2 color: 'black' fs-6 "
                readOnly={true}
              />
            </div>
            <div className="user-select-none col-2">
              <label className="form-label text-nowrap">Totalni Iznos</label>
              <input
                type="text"
                readOnly={true}
                value={numberWithCommasColumns(totalIznos)}
                className="text-end px-2 color: 'black' fs-6 "
              />
            </div>
          </div>
        </ul>
      </div>
      {/* modali */}
      {/* render za poruku brisanja dokumenta*/}
      <Modal isOpen={Boolean(modalOpen)} animation="false" centered>
        <ModalHeader>Brisanje dokumenta</ModalHeader>
        <ModalBody>
          <span>{`Da li želite da obrišete dokument broj ${prometForm.DOK} ? `}</span>
        </ModalBody>
        <ModalFooter>
          <div>
            <button onClick={() => setModalOpen(false)} className="button-no">
              Ne
            </button>
            <button
              onClick={() =>
                onDelete()
              }
              className="button-yes"
            >
              Da
            </button>
          </div>
        </ModalFooter>
      </Modal>
      {/* render za poruku greska na brisanju izabrane stavke  */}
      <Modal isOpen={Boolean(modalDeleteItem)} animation="false" centered>
        <ModalHeader>Brisanje stavke</ModalHeader>
        <ModalBody>
          <span>Da li želite da obrišete stavku broj '{form.RBR}' ?</span>
        </ModalBody>
        <ModalFooter>
          <div>
            <button
              onClick={() => setModalDeleteItem(false)}
              className="button-no"
            >
              Ne
            </button>
            <button
              onClick={() => onDeleteItems(prometForm.PROMETID, form.RBR)}
              className="button-yes"
            >
              Da
            </button>
          </div>
        </ModalFooter>
      </Modal>
      {/* render za poruku greska na editu  */}
      <Modal isOpen={Boolean(modalEditErrorOpen)} animation="false" centered>
        <ModalHeader>Neispravan unos</ModalHeader>
        <ModalBody>
          <span>
            Ne možete mijenjati slog '{form.RBR}' koji već postoji u dokumentu
          </span>
        </ModalBody>
        <ModalFooter>
          <div>
            <button
              onClick={() => {
                rbrRef.current.focus();
                setModalEditErrorOpen(false);
              }}
              className="button-no"
            >
              Ok
            </button>
          </div>
        </ModalFooter>
      </Modal>
      {/* render za poruku */}
      <Modal isOpen={Boolean(modalChangePriceOpen)} animation="false" centered>
        <ModalHeader>Upozorenje</ModalHeader>
        <ModalBody className="d-flex gap-4 flex-column">
          <span>
            Da li ste sigurni da želite promjenu cijene sa "
            {proslaVrijednostCijene}" na "{cijena}" ?
          </span>
          <span>Sigurno želite nastaviti?</span>
        </ModalBody>
        <ModalFooter>
          <div>
            <button
              onClick={() => {
                setModalChangePriceOpen(false);
                setCijena(null);
                rbrRef.current.focus();
              }}
              className="button-no"
            >
              Ne
            </button>
            <button
              onClick={() => {
                setProslaVrijednostCijene(cijena);
                setTimeout(() => {
                  setModalChangePriceOpen(false);
                  iznosRef.current.focus();
                }, 150);
              }}
              className="button-yes"
            >
              Da
            </button>
          </div>
        </ModalFooter>
      </Modal>
      {/* render za poruku ukoliko stavka nije izabrana*/}
      <Modal
        isOpen={Boolean(modalErrorDeleteItemOpen)}
        animation="false"
        centered
      >
        <ModalHeader>Neispravan unos</ModalHeader>
        <ModalBody>
          <span>Morate odabrati stavku za brisanje!</span>
        </ModalBody>
        <ModalFooter>
          <div>
            <button
              onClick={() => setModalErrorDeleteItemOpen(false)}
              className="button-no"
            >
              Ok
            </button>
          </div>
        </ModalFooter>
      </Modal>
      {/* render za poruku ukoliko je u trosak unesen bilo koji karakter sem T ili t*/}
      <Modal isOpen={Boolean(modalTrosakErrorOpen)} animation="false" centered>
        <ModalHeader>Neispravan unos</ModalHeader>
        <ModalBody>
          <span>Unesite T za trošak.</span>
        </ModalBody>
        <ModalFooter>
          <div>
            <button
              onClick={() => {
                setModalTrosakErrorOpen(false);
                // trosakRef.current.value = "";
                setTimeout(() => {
                  trosakRef.current.focus();
                }, 100);
                // trosakPRef.current.focus();
              }}
              className="button-no"
            >
              Ok
            </button>
          </div>
        </ModalFooter>
      </Modal>
      {/* render za poruku */}
      <Modal isOpen={Boolean(modalErrorOpenData)} animation="false" centered>
        <ModalHeader>Nema podataka!</ModalHeader>
        <ModalFooter>
          <div>
            {/* Dugme za zatvaranje modalnog forma */}
            <button
              onClick={() => setModalErrorOpenData(false)}
              className="button-yes"
            >
              Ok
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
}
export default Ulaz;
