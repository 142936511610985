// importovanje komponenti 
import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { API } from '../constants';
import axios from '../api/axios';
import { Spinner } from "reactstrap";
import { getCompanyData, defaultComponentOptions } from '../components/utilities';
import { NumericFormat } from 'react-number-format';
import { useWindowSize } from '../components/useWindowSize';

// komponenta koju kreiramo
function Dokument() {

    const windowSize = useWindowSize();
    console.log(windowSize.height, 'windowSize.height')
    // definisanje konstanti koje koristimo za fokusiranje polja
    const codeRef = useRef();
    const nameRef = useRef();

    // definisanje stejtova i prva inicijalizacija; objekat i funkcija za taj objekat
    const [dokument, setDokument] = useState(null)
    const [errorInputCode, setErrorInputCode] = useState(false);
    const [errorInputName, setErrorInputName] = useState(false);
    const [errorMsgCode, setErrorMsgCode] = useState(false);
    const [errorMsgName, setErrorMsgName] = useState(false);
    const [pending, setPending] = React.useState(false);
    const [searchInput, setSearchInput] = useState([]);
    const [spinner, setSpinner] = useState(false);
    const [modalErrorOpenData, setModalErrorOpenData] = useState(false);
    const [modalErrorAuthorizationData, setModalErrorAuthorizationOpen] = useState(false);
    const [modalDeleteItem, setModalDeleteItem] = useState(false);

    // povlacenje podataka iz lokal storidza
    const token = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : null

    // definisanje stejta(objekat i funkcija za taj objekat), prva inicijalizacija
    const [form, setForm] = useState({
        VRSTAD: '',
        OPISD: '',
        stavkaPronadjena: false
    })

    // funkcija za povlacenje podataka o preduzecu
    getCompanyData(form)

    // funkcija koja se poziva klikom na dugme 'Sacuvaj'
    const onSubmit = () => {

        // uslov: ako nisu unesena obavezna polja, zaustavlja upis
        if (!unosIspravanDo('UPIS')) return;
        // poziva api za upis
        // osvezavanje polja, fokus na polje SIFRA, poziva funkciju getTableData(za upis u mrezu)
        fetch(API + '/dokument/new', {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                response.json()
                setForm({
                    VRSTAD: '',
                    OPISD: '',
                    stavkaPronadjena: false
                })
                codeRef.current.focus()
                getTableData()
            })
    }

    // funkcija koja se poziva klikom na dugme 'Stampa'
    const onPrint = () => {
        setSpinner(true)
        if (dokument && dokument.length === 0) {
            setSpinner(false)
            setModalErrorOpenData(true)
        } else {
            // uslov preko koga saljemo naziv preduzeca sa frontenda na bekend
            // ako je objekat form popunjen, napuni polje NAZIV_PRED iz lokal storidza
            // poziva api za stampu, otvara izvjestaj(API + data.link)
            if (form) {
                form.NAZIV_PRED = form.companyName
            }
            fetch(API + `/dokument/print`, {
                method: 'POST',
                body: JSON.stringify(form),
                responseType: 'blob',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-type': 'application/json; charset=UTF-8'
                },
            })
                .then((response) => response.json())
                .then(data => {
                    if (typeof window != 'undefined') {
                        setTimeout(() => {
                            window.open(API + data.link)
                        }, 200);
                    }
                    setSpinner(false)
                })
        }
    }

    // funkcija koja se poziva pri prvoj inicijalizaciji
    // api za mrezu
    // u responsu puni mrezi(pomocu funkcije setDokument) i puni ukupan broj stranica(setTotal)
    const getTableData = () => {
        fetch(API + `/dokument/all`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setDokument(data.result)
                setPending(false)
            })
    }

    // prilikom pocetne inicijalizacije, cita mrezu
    useEffect(() => {
        // zaustavljanje fokusa
        const timeout = setTimeout(() => {
            getDokumentFirstFreeNumberSifra()
            getTableData()
        }, 100);
        return () => {
            clearTimeout(timeout);
        };
    }, []);

    // funkcija za pronalazenje prvog slobodnog broja
    // poziva api
    // u responsu stejt setForm poziva funkciju za pronalazenje prethodne sifre(iz data) i na osnovu nje odredjuje koja je sljedeca prva slobodna sifra
    const getDokumentFirstFreeNumberSifra = () => {
        fetch(API + `/dokument/firstFreeNumberSifra`, {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setForm(prev => ({ ...prev, VRSTAD: Number(data[0].VRSTAD) }))
            })
    }

    // funkcija za brisanje koja se poziva klikom na dugme 'Delete'
    //  api za brisanje
    // u responsu osvjezava polja, daje prvi slobodan broj za sifru, fokus na polje sifra, osvjezava mrezu  
    const onDelete = (VRSTAD) => {
        // provjera ovlascenja za brisanje 
        if (form.ovlfik == 4) return setModalErrorAuthorizationOpen(true)

        fetch(API + '/dokument/delete/' + VRSTAD, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                response.json()
                setForm({
                    VRSTAD: '',
                    OPISD: '',
                    stavkaPronadjena: false
                })
                codeRef.current.focus()
                getTableData()
            })
    }

    // funkcija koja se poziva klikom na dugme Edit
    // postavlja fokus na polje NAZIV 
    // popunjava polja podacima iz mreze
    const onEdit = (item) => {
        item.stavkaPronadjena = true
        setForm(item)

        // zaustavljanje fokusa
        const timeout = setTimeout(() => {
            nameRef.current.focus()
        }, 100);
        return () => {
            clearTimeout(timeout);
        };
    }

    // funkcija koja se poziva prilikom promjene vrijednosti u inputu
    const handleChange = (event) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name;

        setForm((prevState) => ({
            ...prevState,
            [name]: value
        }));

    };

    // uslov: ako nije upisana ni vrijednost, izbaci poruku, setuje crveni okvir i fokusira polje  
    // u suprotnom prolazi dalje
    const checkCodeRequired = (value) => {
        if (!value || value == 0) {
            setErrorMsgCode('Šifra je obavezna!')
            setErrorInputCode(true)
            codeRef.current.focus();
            return false
        } else {
            setErrorMsgCode(null)
            setErrorInputCode(false)
            return true
        }
    }

    // uslov: ako nije upisana ni vrijednost, izbaci poruku, setuje crveni okvir i fokusira polje  
    // u suprotnom prolazi dalje
    const checkNameRequired = (value) => {
        if (!value || value == 0) {
            setErrorMsgName('Naziv je obavezan!')
            setErrorInputName(true)
            nameRef.current.focus();
            return false
        } else {
            setErrorMsgName(null)
            setErrorInputName(false)
            return true
        }
    }

    // funkcija za kontrolu obaveznih polja
    function unosIspravanDo(name) {
        if (name === 'VRSTAD') {
            return true
        }

        if (!checkCodeRequired(form.VRSTAD)) {
            return false
        }

        if (name === 'OPISD') {
            return true
        }

        if (!checkNameRequired(form.OPISD)) {
            return false
        }
        return true
    }

    // definisu se polja u tabeli i popunjavaju
    const columns = [
        {
            name: 'Šifra',
            selector: row => row.VRSTAD,
            // sirina polja po rezolucijama
            width:
                windowSize.width > 1920 ? '130px'
                    : windowSize.width > 1680 ? '100px'
                        : windowSize.width > 1600 ? '100px'
                            : windowSize.width > 1440 ? '100px'
                                : windowSize.width > 1280 ? '100px'
                                    : windowSize.width > 1024 ? '100px'
                                        : '100px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.VRSTAD}</span>
                </>
            ),
        },
        {
            name: 'Naziv',
            selector: row => row.OPISD,
            // sirina polja po rezolucijama
            width:
            windowSize.width > 2048 ? '1500px'
                : windowSize.width > 1920 ? '1400px'
                    : windowSize.width > 1680 ? '970px'
                        : windowSize.width > 1600 ? '730px'
                            : windowSize.width > 1440 ? '650px'
                                : windowSize.width > 1280 ? '500px'
                                    : windowSize.width > 1024 ? '600px'
                                        : '350px',
            cell: (row) => (
                <>
                    <span id='media-query-rows-text'>{row.OPISD}</span>
                </>
            ),
        },
        {
            name: 'Action',
            // width: '550px',
            right: 'boolean',
            cell: (row) => (
                // klikom na dugme Edit, aktivira se funkcija onEdit 
                // klikom na dugme Brisanje, aktivira se funkcija onDelete
                <>
                    <span onClick={() => onEdit(row)} id='media-query-table-buttons' className='btn btn-primary nav flex-column flex-lg-row justify-content-end'>Edit</span>{''}
                    <span onClick={() => { onEdit(row); setModalDeleteItem(true) }} id='media-query-table-buttons' className='btn btn-danger nav flex-column flex-lg-row justify-content-end'>Brisanje</span>
                </>
            ),
        }
    ];

    // funkcija za pretragu iz mreze
    // salje zahtjev na bekend prema upitu sa parametrima koje posaljemo
    // u responsu vraca podatke koje dobio iz upita 
    const searchChange = (e) => {
        e.preventDefault();
        const searchInput = e.target.value
        setSearchInput(searchInput);
        const fetchData = async () => {
            const res = await axios.get(`${API}/dokument/query/${form.companyCode}?q=${searchInput}`, { headers: { "Authorization": `Bearer ${token}` } });
            setDokument(res.data);
        };

        // uslov za ya slanje zahtjeva za povlacenje podataka
        if (searchInput.length === 0 || searchInput.length >= 1)
            fetchData();
    };

    return (

        <div>

            {/* Kontejner za naslov  */}
            <div className="title-container">

                {/* Naziv registra */}
                <header className="title-header">
                    <h1 id="media-query-title">Dokumenti</h1>
                </header>

                {/* Klasa za ikonice, nalazi se u istom redu kao i naziv, ikonice su formirane kao lista */}
                <div className="naslov-ikonice">
                    <ul className="nav flex-column flex-lg-row justify-content-end">

                        <li className="nav-item toolbaritem">
                            <input type="image" alt="save" className='dugme-sacuvaj' title='Sačuvaj' tabIndex="3" src={require('./../assets/images/floppydisk.png')} onFocus={() => onSubmit()} />
                        </li>

                        <li className="nav-item toolbaritem">
                            <input type="image" alt="print" className='dugme-stampa' title='Štampa' tabIndex="4" src={require('./../assets/images/printer.png')} onClick={onPrint} />
                        </li>

                    </ul>
                </div>

            </div>

            {/* Kontejner pune sirine */}
            <Container fluid>
                <Row>
                    {/* Stilovi koji ce se primjenjivati na svim poljima */}
                    <div style={{ display: 'flex', marginBottom: '0px', width: '60%' }}>

                        {/* Sirina polja */}
                        <div className="col-1">
                            {/* Provjera da li je polje popunjeno jer je obavezno */}
                            <div className='col-form-right'>
                                <div className={errorInputCode ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px' }}>
                                    <label id="media-query-label">Šifra:</label>
                                    <NumericFormat
                                        type="text"
                                        className='field-alignment p-1'
                                        getInputRef={codeRef}
                                        id="media-query-input-text"
                                        maxLength="3"
                                        tabIndex="1"
                                        decimalScale={0}
                                        autoFocus={true}
                                        placeholder="Vrsta dok."
                                        name='VRSTAD'
                                        value={form?.VRSTAD}
                                        onFocus={() => {
                                            getDokumentFirstFreeNumberSifra()
                                            unosIspravanDo("VRSTAD")
                                        }}
                                        // Ako je izmjena na polju
                                        onChange={(e) => handleChange(e)}
                                        onClick={() => {
                                            // klikom na polje sifra, ciste se ostala polja
                                            setForm(prev => ({
                                                ...prev, OPISD: '', stavkaPronadjena: false
                                            }))
                                        }} />
                                    {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                                    {errorMsgCode ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgCode}</span> : ''}
                                </div>
                            </div>
                        </div>

                        {/* Sirina polja */}
                        <div className="col-8">
                            {/* Provjera da li je polje popunjeno jer je obavezno */}
                            <div className={errorInputName ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px' }} >
                                <label id="media-query-label">Naziv:</label>
                                <input type="text"
                                    id="media-query-input-text"
                                    ref={nameRef}
                                    maxLength="30"
                                    tabIndex="2"
                                    placeholder="Naziv"
                                    name='OPISD'
                                    value={form?.OPISD}
                                    onFocus={() => {
                                        unosIspravanDo("OPISD")
                                        // uslov za citanje sloga po sifri
                                        if (unosIspravanDo("OPISD")) {
                                            let selectedItem = []
                                            selectedItem = dokument.filter((c) => c.VRSTAD == form.VRSTAD)
                                            if (selectedItem.length > 0) {
                                                onEdit(selectedItem[0])
                                            }
                                        }
                                    }}
                                    // Ako je izmjena na polju
                                    onChange={(e) => handleChange(e)} />
                                {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                                {errorMsgName ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgName}</span> : ''}
                            </div>
                        </div>
                    </div>
                </Row>
            </Container>


            {/* Stilovi i klase za polje za pretragu;  */}
            <div style={{ display: 'flex' }} className='search'>
                {/* vrsta inputa; poziva funkciju za pretragu(searchChange);  */}
                <input type='text' id="media-query-input-text" placeholder='Pretraga...'
                    onChange={(e) => {
                        // pretraga mreze(ukoliko je unesen bilo koji karakter, vrsi pretragu)
                        if (searchInput.length >= 0) {
                            searchChange(e)
                        } else {
                            getTableData()
                        }
                    }} value={searchInput} />
            </div>

            {/* Mreza */}

            <DataTable
                columns={columns}
                data={dokument ? dokument : []}
                pagination
                responsive
                highlightOnHover
                striped
                dense
                pointerOnHover
                paginationComponentOptions={defaultComponentOptions}
                paginationPerPage={[windowSize.height > 1300 ? 28
                    : windowSize.height > 1270 ? 26
                    : windowSize.height > 1222 ? 25
                    : windowSize.height > 1200 ? 24
                    : windowSize.height > 1000 ? 20
                    : windowSize.height > 920 ? 17
                    : windowSize.height > 900 ? 16
                    : windowSize.height > 880 ? 15
                    : windowSize.height > 820 ? 14
                    : windowSize.height > 720 ? 13
                                        : 4]}
                progressPending={pending}
            />

            {/* render za poruku */}
            <div>
                <Modal isOpen={Boolean(modalDeleteItem)} centered>
                    <ModalHeader>Brisanje stavke</ModalHeader>
                    <ModalBody>
                        <span>{`Da li želite da obrišete stavku broj ${form.VRSTAD} ? `}</span>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            <button
                                onClick={() => { setModalDeleteItem(false); nameRef.current.focus() }}
                                className="button-no">Ne
                            </button>
                            <button
                                onClick={() => {
                                    onDelete(form.VRSTAD);
                                    setModalDeleteItem(false)
                                }}
                                className="button-yes">Da
                            </button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            {/* render za poruku */}
            <div>
                <Modal isOpen={Boolean(modalErrorAuthorizationData)} centered  >
                    <ModalHeader>Upozorenje!</ModalHeader>
                    <ModalBody>
                        <span>{`Nemate ovlašćenje za brisanje!`}</span>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            <button onClick={() => setModalErrorAuthorizationOpen(false)} className="button-no">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            <div>
                <Modal isOpen={Boolean(modalErrorOpenData)} centered  >
                    <ModalHeader>Nema podataka!</ModalHeader>
                    <ModalFooter>
                        <div>
                            {/* Dugme za zatvaranje modalnog forma */}
                            <button onClick={() => setModalErrorOpenData(false)} className="button-yes">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            <div>
                {spinner ? <Spinner className='spinner' color="primary" id='loader' /> : ''}
            </div>

        </div>

    );

}

export default Dokument;


