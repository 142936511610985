// importovanje komponenti
import { useEffect, useState, useRef } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import moment from "moment";
import {
    defaultPrometForm,
    defaultForm,
    numberWithCommasColumns,
    onLastClick,
    onNextClick,
    onPaginationClick,
    onFirstClick,
    onPreviosClick,
    numberWithCommas,
    getIznosPDVa,
    roundTO,
    getPageNumber,
    upisCijena,
} from "../utilities";
import { API } from "../../constants";
import {
    Container,
    Row,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Spinner,
} from "reactstrap";
import { NumericFormat } from "react-number-format";
import dayjs from "dayjs";

function Nivelacija() {
    // definisanje referenci, stejtova i prva inicijalizacija; objekat i funkcija za taj objekat
    const documentRef = useRef();
    const sifraRef = useRef();
    const nomenRef = useRef();
    const dateRef = useRef();
    const staraCijenaRef = useRef();
    const razlikaCijenaRef = useRef();
    const jmRef = useRef();
    const repositoryRef = useRef();
    const rbrRef = useRef();
    const kolicinaRef = useRef();
    const novaCijenaRef = useRef();
    const vrstaRef = useRef();
    const iznosRazlikeRef = useRef();

    const [nivelacija, setNivelacija] = useState([]);
    const [vrsta, setVrsta] = useState(null);
    const [repository, setRepository] = useState(null);
    const [updateDocument, setUpdateDocument] = useState(false);
    const [clients, setClients] = useState(null);
    const [artikal, setArtikal] = useState(null);
    const [selectData, setSelectData] = useState([]);
    const [selectDataNomen, setSelectDataNomen] = useState([]);
    const [selectDataVrsta, setSelectDataVrsta] = useState([0]);
    const [firstDocumentNumber, setFirstDocumentNumber] = useState(null);
    const [itemUpdate, setItemUpdate] = useState(false);

    const [errorInputRepository, setErrorInputRepository] = useState(false);
    const [errorInputVrsta, setErrorInputVrsta] = useState(false);
    const [errorInputDocument, setErrorInputDocument] = useState(false);
    const [errorInputRbr, setErrorInputRbr] = useState(false);
    const [errorDate, setErrorDate] = useState(false);
    const [errorInputMarza, setErrorInputMarza] = useState(false);

    const [errorMsgRepository, setErrorMsgRepository] = useState(false);
    const [errorMsgDocument, setErrorMsgDocument] = useState(false);
    const [errorMsgNomen, setErrorMsgNomen] = useState(false);
    const [errorInputNomen, setErrorInputNomen] = useState(false);
    const [errorMsgRazlika, setErrorMsgRazlika] = useState(false);
    const [errorMsgKolicina, setErrorMsgKolicina] = useState(false);
    const [errorMsgRbr, setErrorMsgRbr] = useState(false);

    const [errorMsgDate, setErrorMsgDate] = useState(false);
    const [errorMsgVrsta, setErrorMsgVrsta] = useState(false);
    const [errorMsgPrice, setErrorMsgPrice] = useState(false);
    const [errorMsgNewPrice, setErrorMsgNewPrice] = useState(false);
    const [errorRazlikaCijene, setErrorRazlikaCijene] = useState(false);

    const [repositoryFocus, setRepositoryFocus] = useState(true);
    const [vrstaFocus, setVrstaFocus] = useState(true);
    const [documentFocus, setDocumentFocus] = useState(false);

    const [dateFocus, setDateFocus] = useState(false);

    const [modalDeleteItem, setModalDeleteItem] = useState(false);
    const [modalErrorOpen, setModalErrorOpen] = useState(false);
    const [modalChangePriceError, setModalChangePriceError] = useState(false);
    const [modalChangeSamePriceError, setModalChangeSamePriceError] =
        useState(false);
    const [modalErrorDeleteItemOpen, setModalErrorDeleteItemOpen] =
        useState(false);

    const [isModalPopedOutOnce, setIsModalPoppedOutOnce] = useState(false);

    const [errorMsgSifra, setErrorMsgSifra] = useState(null);
    const [sifraFocus, setSifraFocus] = useState(false);

    const [spinner, setSpinner] = useState(false);

    // tabela i paginacija
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [duzinaNiza, setDuzinaNiza] = useState(0);
    const pageNumbers = [];

    // iznosi , kalkulacije na dnu stranice
    const [totalIznos, setTotalIznos] = useState(0);
    const [totalIznosPDV, setTotalIznosPDV] = useState(0);

    // funkcija koja nullira totale
    const SetTotalsToDefaults = () => {
        setTotalIznosPDV(0);
        setTotalIznos(0);
    };

    // povlacenje podataka iz lokal storidza
    const token =
        typeof window !== "undefined" ? localStorage.getItem("accessToken") : null;
    const companyCode = localStorage.getItem("company");
    const companyYear = localStorage.getItem("companyYear");
    const userCode = localStorage.getItem("userCode");

    // datepicker
    let todaysDate = moment().year(companyYear);
    const [datumK, setDatumK] = useState(dayjs(todaysDate));
    const [datumR, setDatumR] = useState(dayjs(todaysDate));
    const [valuta, setValuta] = useState(dayjs(todaysDate));

    // cjenovnik dropdown
    const [izmjenaCijene, setIzmjenaCijene] = useState("D");
    const handleIzmjenaCijene = (event) => {
        setIzmjenaCijene(event.target.value);
    };

    // funkcija koja hvata promjene na poljima prometForme i setuje vrijednost u form state
    const handlePrometChange = (event) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name;
        setPrometForm((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    // funkcija koja hvata promjene na poljima forme i setuje vrijednost u form state
    const handleChangePrometS = (event) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name;
        setForm((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    // propsovi za auto komplit - skladiste, artikal i vrstu;
    // objekat za dati autokomplit iz koga preko mape mozemo procitati podatak upisan u bazu
    const defaultProps = {
        options: selectData,
        getOptionLabel: (option) =>
            option ? `${option.SIFRA} ${option.NAZIV}` : "",
    };
    const defaultPropsVrsta = {
        options: selectDataVrsta,
        getOptionLabel: (option) =>
            option ? ` ${option.VRSTA} ${option.NAZIV}` : "",
    };
    const defaultPropsNomen = {
        options: selectDataNomen,
        getOptionLabel: (option) =>
            option ? `${option.SIFRA} - ${option.NAZIV} - ${option.JM}` : "",
    };

    // definisanje formi i setovanje vrijednosti
    const [form, setForm] = useState({
        ...defaultForm,
    });
    const [prometForm, setPrometForm] = useState({
        ...defaultPrometForm,
    });

    // funkcije za provjeru obaveznih polja - skladiste
    const checkRepositoryRequired = (value) => {
        // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto)
        // ili ako nije upisana ni vrijednost niti je odabrano skladiste, izbaci poruku, setuje crveni okvir i fokusira polje
        // u suprotnom prolazi dalje
        if ((value && !repository) || (!value && !repository)) {
            setErrorMsgRepository("Skladiste mora biti izabrano!");
            setErrorInputRepository(true);
            repositoryRef.current.focus();
            setRepositoryFocus(true);
            return false;
        } else {
            setErrorMsgRepository(null);
            setErrorInputRepository(false);
            return true;
        }
    };

    // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto)
    // ili ako nije upisana ni vrijednost niti je odabrana vrsta, izbaci poruku, setuje crveni okvir i fokusira polje
    // u suprotnom prolazi dalje
    const checkVrstaRequired = (value) => {
        if ((value && !vrsta) || (!value && !vrsta)) {
            setErrorMsgVrsta("Vrsta mora biti izabrana!");
            setErrorInputVrsta(true);
            vrstaRef.current.focus();
            setVrstaFocus(true);
            return false;
        } else {
            setErrorMsgVrsta(null);
            setErrorInputVrsta(false);
            return true;
        }
    };

    // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto)
    // ili ako nije upisana ni vrijednost niti je odabran komitent, izbaci poruku, setuje crveni okvir i fokusira polje
    // u suprotnom prolazi dalje
    const checkDocumentRequired = (value) => {
        if (!value || value == 0) {
            setErrorMsgDocument("Dokument je obavezan!");
            setErrorInputDocument(true);
            documentRef.current.focus();
            return false;
        } else {
            setErrorMsgDocument(null);
            setErrorInputDocument(false);
            return true;
        }
    };

    // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto)
    // ili ako nije upisana ni vrijednost niti je odabran artikal, izbaci poruku, setuje crveni okvir i fokusira polje
    // u suprotnom prolazi dalje
    const checkNomenRequired = (value) => {
        if (value === null || value == 0 || value == "" || value.SIFRA == 0) {
            if (!form.SIFRA) {
                setErrorMsgNomen("Artikal je obavezan!");
                setErrorInputNomen(true);
                nomenRef.current.focus();
                return false;
            }
        } else {
            setErrorMsgNomen(null);
            setErrorInputNomen(false);
            return true;
        }
    };

    const checkRBRRequired = (value) => {
        if (!value || value <= 0) {
            setErrorMsgRbr("Rbr je obavezan!");
            setErrorInputRbr(true);
            rbrRef.current.focus();
            return false;
        } else {
            setErrorMsgRbr(null);
            setErrorInputRbr(false);
            return true;
        }
    };

    const checkKolRequired = (value) => {
        // uslov: ako nije upisana vrijednost manja od 0 ili je vrijednost 0,
        // izbaci poruku. U suprotnom: prolazi dalje.
        if (value <= 0 || value == "" || value == undefined) {
            setErrorMsgKolicina("Količina je obavezna!");
            kolicinaRef.current.focus();
            return false;
        } else {
            setErrorMsgKolicina(null);
            return true;
        }
    };

    // funkcije za provjeru obaveznih polja - cijena
    const checkPriceRequired = (value) => {
        if (value == 0) {
            setErrorMsgPrice("Unesite ispravnu cijenu");
            staraCijenaRef.current.focus();
            return false;
        } else {
            setErrorMsgPrice(null);
            return true;
        }
    };

    // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto)
    // ili ako nije upisana ni vrijednost niti je odabran artikal, izbaci poruku, setuje crveni okvir i fokusira polje
    // u suprotnom prolazi dalje
    const checkSifraRequired = (value) => {
        let doesItemExistInArtikli = selectDataNomen.some(
            (artikal) => artikal.SIFRA == value
        );
        if (!doesItemExistInArtikli) {
            setErrorMsgSifra("Unesite šifru artikla!");
            sifraRef.current.focus();
            return false;
        } else {
            setErrorMsgSifra(null);
            return true;
        }
    };

    // funkcije za provjeru obaveznih polja - nova cijena
    const checkNewPriceRequired = (value) => {
        if (value <= 0) {
            setErrorMsgNewPrice("Unesite ispravnu cijenu");
            novaCijenaRef.current.focus();
            return false;
        } else {
            if (!isModalPopedOutOnce && form.NABCEN > form.NOVA_CENA) {
                setIsModalPoppedOutOnce(true);
                setModalChangePriceError(true);
            }
            if (form.STARA_CENA == form.NOVA_CENA) {
                setErrorMsgNewPrice("Unesite ispravnu cijenu");
                setModalChangeSamePriceError(true);
                novaCijenaRef.current.focus();
                return false;
            }
            setErrorMsgNewPrice(null);
            return true;
        }
    };

    // funkcije za provjeru obaveznih polja - razlika cijene
    const checkRazlikaCijeneRequired = (value) => {
        if (value <= 0) {
            setErrorRazlikaCijene("Unesite ispravnu cijenu");
            razlikaCijenaRef.current.focus();
            return false;
        } else {
            setErrorRazlikaCijene(null);
            return true;
        }
    };

    // funkcija unosIspravanDo(validacija), na klik svakog input elementa iz formi,
    // provjerava obavezna polja iz prethodnih te ukazuje na gresku ukoliko je ima.
    function unosIspravanDo(name) {
        if (name == "SKLAID") {
            return true;
        }

        if (!checkRepositoryRequired(prometForm.SKLAID)) {
            return false;
        }

        if (name == "VRPROM") {
            return true;
        }

        if (!checkVrstaRequired(prometForm.VRPROM)) {
            return false;
        }

        if (name == "DOK") {
            return true;
        }

        if (!checkDocumentRequired(prometForm.DOK)) {
            return false;
        }

        if (name == "DPO") {
            return true;
        }

        let regex = /^(0[1-9]|[12]\d|3[01]).(0[1-9]|1[0-2]).([12]\d{3})$/gi;
        if (!regex.test(prometForm.DPO)) {
            setErrorDate(true);
            setErrorMsgDate("Datum nije validan!");
            dateRef.current.focus();
            return false;
        } else {
            setErrorDate(false);
            setErrorMsgDate(null);
        }

        if (name == "CJENOVNIK") {
            return true;
        }

        if (name == "RBR") {
            return true;
        }
        if (!checkRBRRequired(form.RBR)) {
            return false;
        }

        if (name == "SIFRA") {
            return true;
        }

        if (!checkSifraRequired(form.SIFRA)) {
            return false;
        }

        if (name == "ARTIKAL") {
            return true;
        }

        if (!checkNomenRequired(artikal)) {
            return false;
        }

        if (name == "JM") {
            return true;
        }

        if (name == "KOLICINA") {
            return true;
        }

        if (!checkKolRequired(form.KOLICINA)) {
            return false;
        }

        if (name == "STARACIJENA") {
            return true;
        }

        if (!checkPriceRequired(form.STARA_CENA)) {
            return false;
        }

        if (name == "NOVACIJENA") {
            return true;
        }

        if (!checkNewPriceRequired(form.NOVA_CENA)) {
            return false;
        }

        if (name == "RAZLIKACIJENA") {
            return true;
        }
        if (!checkRazlikaCijeneRequired(form.RAZLIKA)) {
            return false;
        }
        if (name == "IZNOS") {
            return true;
        }

        return true;
    }

    // funkcija koja poziva api za pronalazenje prvog slobodnog dokumenta
    const getPrometFirstFreeNumber = () => {
        fetch(API + `/nivelacija/firstFreeNumber`, {
            method: "POST",
            body: JSON.stringify({
                sklaId: repository && repository.ID ? repository.ID : "",
                vrprom: vrsta && vrsta.VRSTA ? vrsta.VRSTA : "",
            }),
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-type": "application/json; charset=UTF-8",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                let promet = data[0];
                setPrometForm((prev) => ({
                    ...prev,
                    DOK: promet.DOK,
                    ID: promet.ID,
                }));
                setFirstDocumentNumber(promet.DOK, promet.ID);
            });
    };

    // funkcija koja poziva api za pronalazenje prvog slobodnog rednog broja
    // setovanje polja RBR
    const getPrometFirstFreeNumberRbr = () => {
        fetch(API + `/nivelacija/firstFreeNumberRbr`, {
            method: "POST",
            body: JSON.stringify({
                sklaId: prometForm.SKLAID || "",
                vrprom: prometForm.VRPROM || "",
                dok: prometForm.DOK,
            }),
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-type": "application/json; charset=UTF-8",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setForm((prev) => ({ ...prev, RBR: data[0].RBR }));
            });
    };

    // funkcija koja poziva upisivanje informacija u prometS formu
    const getPrometInfo = () => {
        fetch(API + `/nivelacija/promet`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify({
                SKLAID: prometForm.SKLAID,
                DOK: prometForm.DOK,
                VRPROM: prometForm.VRPROM,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                let promet = data[0];
                if (promet) {
                    getPrometFirstFreeNumberRbr();
                    setPrometForm((prev) => ({
                        ...prev,
                        ...promet,
                        DPO: moment(promet.DPO).format("DD.MM.YYYY"),
                        VAL: promet.VAL,
                    }));
                    // setovanje
                    setIzmjenaCijene(promet.VAL);
                    setDatumK(promet.DPO);
                    setDatumR(dayjs(promet.DPOR, "DD.MM.YYYY"));
                    setValuta(dayjs(promet.VALUTA, "DD.MM.YYYY"));
                    setNivelacija([]);
                    setCurrentPage(1);
                    setDuzinaNiza(0);
                    SetTotalsToDefaults();
                    getPromet();
                    getArticles();
                } else {
                    // vracanje datepickera na pocetne vrijednosti
                    getArticles();
                    setDatumK(todaysDate);
                    setDatumR(todaysDate);
                    setValuta(todaysDate);
                    // vracanje forme na pocetne vrijednost
                    setForm((prev) => ({
                        ...prev,
                        ...defaultForm,
                    }));
                    // vracanje na pocetne vrijednosti prometID, datuma racuna, naziva racuna, kursa
                    setPrometForm((prev) => ({
                        ...prev,
                        ID: 0,
                        DPO: moment(todaysDate).format("DD.MM.YYYY"),
                        VAL: "",
                        RACUN: "",
                        KURS: "",
                    }));
                    setNivelacija([]);
                    setCurrentPage(1);
                    setDuzinaNiza(0);
                    setIzmjenaCijene("D");
                    SetTotalsToDefaults();
                    setClients(null);
                    getPrometFirstFreeNumberRbr();
                }
            });
    };

    // funckija koja poziva upisivanje stavki u tabetu
    const getPromet = () => {
        fetch(API + `/nivelacija/all?page=${currentPage}&limit=${itemsPerPage}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify({
                SKLAID: prometForm.SKLAID,
                DOK: prometForm.DOK,
                VRPROM: prometForm.VRPROM,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                let promet = data;
                if (promet) {
                    // dobijanje nivelacija i duzine niza
                    setNivelacija(promet.result);
                    setDuzinaNiza(Math.ceil(data.total / itemsPerPage));
                    getTotals();
                }
            });
    };

    const getTotals = () => {
        fetch(API + `/nivelacija/total`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify({
                SKLAID: prometForm.SKLAID,
                DOK: prometForm.DOK,
                VRPROM: prometForm.VRPROM,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                // totals
                let totals = {
                    IZNOS_RAZLIKE: 0,
                    IZNOS_POREZA: 0,
                };

                // upisivanje, sabiranje vrijednosti iz svake stavke
                data.result.forEach((n) => {
                    totals.IZNOS_RAZLIKE += roundTO(n.IZNOS_RAZLIKE, 3);
                    totals.IZNOS_POREZA += roundTO(n.IZNOS_POREZA, 2);
                });
                // setovanje sabranih vrijednosti u useStatove, te useStatove kasnije pozivamo da citamo vrijednost
                setTotalIznos(roundTO(totals.IZNOS_RAZLIKE, 3));
                setTotalIznosPDV(roundTO(totals.IZNOS_POREZA, 2));
            });
    };

    // dobijanje odgovarajuceg poreza za artikal
    const getTaxData = (nomenid) => {
        fetch(API + `/porezi/procenat`, {
            method: "POST",
            body: JSON.stringify({ nomenId: nomenid }),
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-type": "application/json; charset=UTF-8",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data && data.length) {
                    setForm((prev) => ({ ...prev, PDV: data[0].PROCENAT }));
                }
            });
    };

    // funkcija koja poziva api za pronalazenje informacija o artiklu na osnovu Sifre artikla
    const getArticleInfo = () => {
        fetch(API + `/nivelacija/articleInfo`, {
            method: "POST",
            body: JSON.stringify({
                SKLAID: prometForm?.SKLAID || "",
                SIFRAARTIKLA: form?.SIFRA || 0,
                companyCode: companyCode || 0,
            }),
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-type": "application/json; charset=UTF-8",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                let articleData = data[0];
                if (articleData) {
                    // setovanje lagera i poreza na osnovu articleData
                    getLagerData(articleData?.NOMENID);
                    getTaxData(articleData?.NOMENID);
                    // setovanje forme na osnovu articleData
                    setForm((prev) => ({
                        ...prev,
                        FAKTCEN: articleData?.NABCENA
                            ? articleData?.NABCENA 
                            : articleData?.PRODCENA || 0,
                        NAZIV: articleData?.NAZIV || "",
                        SIFRA: articleData?.SIFRA || 0,
                        NABCEN: articleData?.NABCENA || "",
                        STARA_CENA: articleData?.PRODCENA || 0,
                        JM: articleData?.JM || "",
                        NOMENID: articleData?.ID || 0,
                    }));
                    // setovanje artikla
                    setArtikal({
                        NAZIV: articleData?.NAZIV || "",
                        SIFRA: articleData?.SIFRA || 0,
                        ID: articleData?.ID || 0,
                        NABCEN: articleData?.NABCENA || 0,
                        JM: articleData?.JM || "",
                    });
                }
            });
    };

    useEffect(() => {
        // dobijanje skladista, vrsta i artikala
        getRepositoryData();
        getVrstaData();
        getArticles();
        // setovanje vrste prometa na NI po defaultu
        setVrsta({
            VRSTA: "NI",
            NAZIV: "NIVELACIJA",
            KOEF: 1,
            STRANA: "N",
            VEZA: "  ",
            ZABRANA: "N",
            naziv1: "NIVEL.",
            PRIORITET: 0,
        });
        setPrometForm((prev) => ({
            ...prev,
            VRPROM: "NI",
        }));
    }, [!repository && !artikal && !clients && !vrsta]);

    // funkcija koja poziva citanje lagera
    const getLagerData = (nomenid) => {
        fetch(API + `/lager/all`, {
            method: "POST",
            body: JSON.stringify({
                sklaId: prometForm?.SKLAID || "",
                nomenId: nomenid || 0,
            }),
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-type": "application/json; charset=UTF-8",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.length > 0) {
                    // ukoliko data.length > 0 upisi vrijednosti
                    setForm((prev) => ({
                        ...prev,
                        LAGER: data ? data[0]?.LAGER : 0,
                        KOLICINA: data ? data[0]?.LAGER : 0,
                    }));
                } else {
                    // u suprotnom, nuliraj na promjenu artikla
                    setForm((prev) => ({
                        ...prev,
                        LAGER: 0,
                        KOLICINA: "",
                    }));
                }
            });
    };

    // funkcija koja poziva citanje artikla
    const getArticles = () => {
        fetch(API + `/nivelacija/getAllArticles`, {
            method: "POST",
            body: JSON.stringify({
                SKLAID: prometForm.SKLAID,
                companyCode: companyCode,
            }),
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-type": "application/json; charset=UTF-8",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                // upisujemo dobijeni rezultat artikala
                setSelectDataNomen(data);
            });
    };

    // funkcija koja poziva citanje skladista
    const getRepositoryData = () => {
        fetch(API + `/skladista/all/${companyCode}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-type": "application/json; charset=UTF-8",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                // upisujemo dobijeni rezultat skladista
                setSelectData(data);
            });
    };

    // funkcija koja poziva citanje vrste
    const getVrstaData = () => {
        fetch(API + `/vrstaDok/all`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-type": "application/json; charset=UTF-8",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                // upisujemo dobijeni rezultat vrste prometa
                setSelectDataVrsta(data);
            });
    };

    // funkcija koja se poziva klikom na dugme 'Sacuvaj'
    // upisivanje nove stavke
    const onSubmit = async () => {
        if (!unosIspravanDo("UPIS")) return;
        // kombinovanje formi za send
        form.VRSTA_SKLADISTA = prometForm.VRSTA_SKLADISTA;
        form.VRPROM = prometForm.VRPROM;
        form.SKLAID = prometForm.SKLAID;
        form.KOMTID = prometForm.KOMTID;
        form.PROMETID = prometForm.ID;
        form.RACUN = prometForm.RACUN;
        form.DOK = prometForm.DOK;
        form.DPO = prometForm.DPO;
        form.VAL = izmjenaCijene;
        form.SIFKOR = userCode;
        form.IZPDV = getIznosPDVa(
            form.VRSTA_SKLADISTA,
            form.PDV,
            form.KOLICINA,
            form.IZNOS_RAZLIKE
        );
        form.IZNOS_POREZA = getIznosPDVa(
            form.VRSTA_SKLADISTA,
            form.PDV,
            form.KOLICINA,
            form.IZNOS_RAZLIKE
        );

        fetch(API + "/nivelacija/new", {
            method: "POST",
            body: JSON.stringify(form),
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-type": "application/json; charset=UTF-8",
            },
        })
            .then((response) => {
                response.json();
                setTimeout(() => {
                    if (izmjenaCijene == 'D') {
                        upisCijena(form.NOMENID, prometForm.SKLAID, prometForm.GODINA, prometForm.VRSTA_SKLADISTA, 0, form.NOVA_CENA, 0,  form.SIFKOR)
                    }
                    setForm((prev) => ({
                        ...prev,
                        ...defaultForm,
                    }));
                    setArtikal(null);
                    setIsModalPoppedOutOnce(false);
                },
                    50);
            })
            .then(() => {
                setErrorInputMarza(false);
                getPrometInfo();
                // setujemo fokus na rbr
                rbrRef.current.focus();
            });
    };

    // funkcija koja se poziva dvoklikom na red u mrezi/tabeli(na stavku), popunjava polja za stavku
    const onEdit = async (item) => {
        setItemUpdate(true);
        console.log(item, 'item')

        await fetch(API + `/cjenovnik/all`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-type": "application/json; charset=UTF-8",
            },
            body: JSON.stringify({
                sklaId: prometForm.SKLAID,
                nomenId: item.NOMENID,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                let artikal = data[0];
                if (artikal) {
                    // setovanje forme na osnovnu izabranog itema(stavke)
                    setForm((prev) => ({
                        ...prev,
                        NABCEN: artikal.NABCENA,
                        KOLICINA: item.KOL,
                        LAGER: item.KOL,
                        SIFRA: item.NOMENID,
                        NOMENID: item.NOMENID,
                        JM: item.JM,
                        RBR: item.RBR,
                        PDV: item.PDV,
                        IZNOS_POREZA: getIznosPDVa(
                            prometForm.VRSTA_SKLADISTA,
                            item.PDV,
                            item.KOL,
                            item.IZNOS_RAZLIKE
                        ),
                        IZPDV: getIznosPDVa(
                            prometForm.VRSTA_SKLADISTA,
                            item.PDV,
                            item.KOL,
                            item.IZNOS_RAZLIKE
                        ),
                        STARA_CENA: item.STARA_CENA,
                        NOVA_CENA: item.NOVA_CENA,
                        RAZLIKA: item.RAZLIKA,
                        IZNOS_RAZLIKE: item.IZNOS_RAZLIKE,
                        IZNRAZD: item.IZNRAZD,
                    }));
                }
            });

        // provjeravamo da li je item.SIFRA jednak artikal.SIFRA , ukoliko jeste setujemoArtikal
        selectDataNomen.map((artikal) => {
            if (artikal.SIFRA == item.SIFRA) {
                setArtikal(artikal);
            }
        });
    };

    // brisanje stavke is prometS tabele
    const onDeleteItems = (prometID, rbr) => {
        form.SIFKOR = userCode;
        fetch(API + `/nivelacija/item/delete/${prometID}/${rbr}/`, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-type": "application/json; charset=UTF-8",
            },
        }).then((response) => {
            // uslov: ako je uspjesno obrisano, cisti sva polja u vezi sa stavkom
            if (response.status == 200) {
                if (izmjenaCijene == 'D') {
                    upisCijena(form.NOMENID, prometForm.SKLAID, prometForm.GODINA, prometForm.VRSTA_SKLADISTA, 0, form.STARA_CENA, 0, form.SIFKOR)
                }
                setModalDeleteItem(false);
                setNivelacija(null);
                setArtikal(null);
                setItemUpdate(false);
                setForm((prev) => ({
                    ...prev,
                    ...defaultForm,
                }));
                getPrometInfo();
            }
            // zaustavljanje fokusa(pauza za fokus dok ne odradi brisanje)
            const timeout = setTimeout(() => {
                rbrRef.current.focus();
            }, 500);
            return () => {
                clearTimeout(timeout);
            };
        });
    };

    const columns = [
        {
            name: "Rbr.",
            class: "col-1 fs-6 text-end px-3 text-nowrap ",
        },
        {
            name: "Šifra",
            class: "col-1 fs-6 text-end px-3 text-nowrap",
        },
        {
            name: "Artikal",
            class: "col-4 fs-6 px-3 text-nowrap",
        },
        {
            name: "J.M.",
            class: "col-1 fs-6 px-3 text-nowrap",
        },
        {
            name: "Količina",
            class: "col-1 fs-6 text-end px-3 text-nowrap",
        },
        {
            name: "Stara cijena",
            class: "col-1 fs-6 text-end px-3 text-nowrap",
        },
        {
            name: "Nova cijena",
            class: "col-1 fs-6 text-end px-3 text-nowrap",
        },
        {
            name: "Razlika cijene",
            class: "col-1 fs-6 text-end px-3 text-nowrap",
        },
        {
            name: "Iznos",
            class: "col-1 fs-6 text-end px-3 text-nowrap",
        },
    ];

    // funkcija koja je zaduzena za prikazivanje sljedecih i prethodnih brojeva stranica na paginaciji
    getPageNumber(currentPage, duzinaNiza, pageNumbers);

    // useeffect koji se poziva na svaku promjenu u paginaciji (currentPage, itemsPerPage) ukoliko prometForm.DOK postoji
    useEffect(() => {
        if (prometForm.DOK) {
            getPromet(currentPage, itemsPerPage);
        }
    }, [currentPage, itemsPerPage]);

    return (
        <div>
            <div className="title-container">
                <header className="title-header">
                    <h1 id="media-query-title">Nivelacija</h1>
                </header>

                <div className="naslov-ikonice">
                    <ul className="nav flex-lg-row justify-content-end">
                        <li className="nav-item toolbaritem">
                            <input
                                type="image"
                                className="dugme-sacuvaj"
                                title="Sačuvaj"
                                tabIndex="13"
                                src={require("./../../assets/images/floppydisk.png")}
                                onFocus={() => (!itemUpdate ? onSubmit() : null)}
                            />
                        </li>

                        <li className="nav-item toolbaritem">
                            <input
                                type="image"
                                className="dugme-stampa"
                                title="Štampa"
                                tabIndex="14"
                                src={require("./../../assets/images/printer.png")}
                            // onClick={onPrint}
                            />
                        </li>

                        <li className="nav-item toolbaritem">
                            <input
                                type="image"
                                className="dugme-stampa"
                                title="Brisanje stavke"
                                tabIndex="15"
                                src={require("./../../assets/images/trash1.png")}
                                onClick={
                                    itemUpdate ? setModalDeleteItem : setModalErrorDeleteItemOpen
                                }
                            />
                        </li>
                    </ul>
                </div>
            </div>
            <Container fluid>
                <div className="col-12 d-flex justify-content-between flex-column flex-lg-row gap-3 gap-lg-0">
                    <div className="col-12 col-lg-7 d-flex flex-column gap-2">
                        <div className="col-12 d-flex flex-column flex-lg-row">
                            <div className="col-12 col-lg-6 ">
                                <div
                                    className={
                                        errorInputRepository
                                            ? "inputWithError d-flex"
                                            : "field-container d-flex gap-1"
                                    }
                                >
                                    <label className="fs-6 m-0 text-nowrap minWidth80">
                                        Skladište:
                                    </label>
                                    {/* autokomplit za skladiste */}
                                    <Autocomplete
                                        //ukoliko nema ove funkcije, u konzoli izbacuje upozorenje da nije vrijednost jedinstvena
                                        isOptionEqualToValue={(option, value) =>
                                            option.id === value.id
                                        }
                                        {...defaultProps}
                                        className="inputWithError w-100"
                                        value={repository}
                                        disablePortal={true}
                                        onFocus={() => {
                                            setRepositoryFocus(true);
                                            unosIspravanDo("SKLAID");
                                        }}
                                        // ako se promijeni skladiste, uzima podatke za novo izabrano skladiste
                                        onChange={(event, newValue) => {
                                            setPrometForm((prev) => ({
                                                ...prev,
                                                SKLAID: newValue && newValue != null ? newValue.ID : "",
                                                VRSTA_SKLADISTA:
                                                    newValue && newValue != null
                                                        ? newValue.VRSTA_SKLADISTA
                                                        : "",
                                                LAGER: "",
                                            }));
                                            setRepository(newValue);
                                            repositoryRef.current.focus();
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                autoFocus
                                                className="clients-textinput"
                                                maxLength="295"
                                                name="SKLAID"
                                                tabIndex="1"
                                                ref={repositoryRef}
                                                //   onBlur={(e) => formCheck(e)}
                                                placeholder="Izaberite skladište"
                                                variant="standard"
                                            />
                                        )}
                                    />
                                </div>
                                {/* prikazivanje greske ukoliko skladiste nije izabrano */}
                                {errorMsgRepository && (
                                    <div className="col-12">
                                        <span className="error-message">{errorMsgRepository}</span>
                                    </div>
                                )}
                            </div>
                            <div className="col-12 col-lg-6">
                                <div
                                    className={
                                        errorInputVrsta
                                            ? "inputWithError d-flex"
                                            : "field-container d-flex ps-3"
                                    }
                                >
                                    <label className="fs-6 m-0 text-nowrap minWidth80">
                                        Vrsta:
                                    </label>

                                    {/* autokomplit za vrstu */}
                                    <Autocomplete
                                        //ukoliko nema ove funkcije, u konzoli izbacuje upozorenje da nije vrijednost jedinstvena
                                        isOptionEqualToValue={(option, value) =>
                                            option.id === value.id
                                        }
                                        {...defaultPropsVrsta}
                                        value={vrsta}
                                        className="w-100"
                                        disablePortal={true}
                                        onFocus={() => {
                                            setVrstaFocus(true);
                                            unosIspravanDo("VRPROM");
                                        }}
                                        // ako se promijeni vrsta, uzima podatke za novu izabranu vrstu
                                        onChange={(event, newValue) => {
                                            setPrometForm((prev) => ({
                                                ...prev,
                                                VRPROM:
                                                    newValue && newValue != null ? newValue.VRSTA : "",
                                                NAZIV:
                                                    newValue && newValue != null ? newValue.NAZIV : "",
                                            }));
                                            setVrsta(newValue);
                                            vrstaRef.current.focus();
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                tabIndex="2"
                                                maxLength="2"
                                                className="clients-textinput"
                                                name="VRPROM"
                                                ref={vrstaRef}
                                                placeholder=""
                                                variant="standard"
                                            />
                                        )}
                                    />
                                </div>
                                {/* prikazivanje greske ukoliko vrsta nije izabrana */}
                                {errorMsgVrsta && (
                                    <div className="col-12">
                                        <span className="error-message">{errorMsgVrsta}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-8 d-flex align-items-start gap-3">
                            <div className="col-4 d-flex gap-1">
                                <label className="fs-6 m-0 text-nowrap minWidth80">Dok.:</label>
                                {/* komponenta za formatiranje brojeva */}
                                <NumericFormat
                                    type="number"
                                    getInputRef={documentRef}
                                    className="w-25"
                                    maxLength="9"
                                    tabIndex="3"
                                    decimalScale={0}
                                    onFocus={() => {
                                        unosIspravanDo("DOK");
                                        setDocumentFocus(true);
                                        getPrometFirstFreeNumber();
                                    }}
                                    placeholder="0"
                                    name="DOK"
                                    value={prometForm?.DOK}
                                    onChange={(e) => {
                                        handlePrometChange(e);
                                    }}
                                    onClick={() => {
                                        setUpdateDocument(false);
                                        setItemUpdate(false);
                                        setArtikal(null);
                                    }}
                                />
                            </div>
                            <div
                                className={
                                    errorMsgDate
                                        ? "inputWithError"
                                        : "col-4 d-flex justify-content-between "
                                }
                            >
                                <label className="fs-6 m-0 text-nowrap minWidth80">
                                    Datum K:
                                </label>
                                <input
                                    type="text"
                                    className="w-100 m-0"
                                    tabIndex="4"
                                    ref={dateRef}
                                    onFocus={() => {
                                        setDateFocus(true);
                                        unosIspravanDo("DPO");
                                        getPrometInfo();
                                    }}
                                    placeholder=""
                                    name="DPO"
                                    value={prometForm.DPO}
                                    onChange={(e) => handlePrometChange(e)}
                                />
                                {/* prikazivanje greske ukoliko je izabran los datum  */}
                                {errorMsgDate && (
                                    <div className="col-6">
                                        <span className="error-message">{errorMsgDate}</span>
                                    </div>
                                )}
                            </div>
                            <div className="col-4 d-flex justify-content-between">
                                <label className="fs-6 m-0 text-nowrap minWidth80">
                                    Izmjene cijene
                                </label>
                                <select
                                    tabIndex="5"
                                    className="px-3 rounded rounded-3"
                                    value={izmjenaCijene}
                                    onFocus={() => unosIspravanDo("CJENOVNIK")}
                                    onChange={(e) => handleIzmjenaCijene(e)}
                                >
                                    <option value="D">D</option>
                                    <option value="N">N</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4">
                        <div className="col-12 d-flex flex-column flex-md-row justify-content-between">
                            <div className="col-12 col-md-6">
                                <div className="col-12 d-flex flex-column gap-1">

                                    <div className="d-flex justify-content-between">
                                        <label className="fs-6 text-nowrap m-0">Nab. cijena:</label>
                                        {/* komponenta za formatiranje brojeva */}
                                        <NumericFormat
                                            type="number"
                                            className={
                                                !itemUpdate &&
                                                    form.NOVA_CENA &&
                                                    form.NOVA_CENA < form.NABCEN
                                                    ? "bg-danger w-50 px-2"
                                                    : "w-50 px-2"
                                            }
                                            disabled
                                            maxLength="15"
                                            decimalScale={3}
                                            placeholder="0"
                                            value={numberWithCommasColumns(form.NABCEN || 0)}
                                        />
                                    </div>
                                    
                                    <div>
                                        <div className="d-flex justify-content-between">
                                            <label className="text-nowrap fs-6 m-0">Porez %:</label>
                                            {/* komponenta za formatiranje brojeva */}
                                            <NumericFormat
                                                disabled
                                                type="number"
                                                className="w-50 m-0 px-2"
                                                maxLength="11"
                                                decimalScale={2}
                                                placeholder="0"
                                                value={numberWithCommasColumns(form.PDV)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-5">
                                <div className="col-12 d-flex flex-column gap-1">
                                    <div>
                                        <div className="d-flex justify-content-between">
                                            <label className="m-0 text-nowrap fs-6">Lager:</label>
                                            <NumericFormat
                                                disabled
                                                className="w-50 m-0 text-end px-2"
                                                placeholder="0"
                                                type="number"
                                                decimalScale={3}
                                                value={numberWithCommasColumns(form?.LAGER || "")}
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <div className="d-flex justify-content-between">
                                            <label className="text-nowrap fs-6 m-0">Iznos Poreza</label>
                                            {/* komponenta za formatiranje brojeva */}
                                            <NumericFormat
                                                disabled
                                                type="number"
                                                className="w-50 m-0 px-2"
                                                maxLength="13"
                                                decimalScale={2}
                                                placeholder="0"
                                                value={numberWithCommasColumns(form.IZNOS_POREZA)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
            <Container fluid>
                <Row>
                    <div className="col-12 d-flex flex-lg-row flex-wrap my-3 p-0">
                        <div className="col-6 col-lg-1">
                            <div className={errorMsgRbr ? "text-end inputWithError" : "text-end"}>
                                <div>
                                    <label className="fs-6 m-0 px-1">Rbr.</label>
                                    {/* komponenta za formatiranje brojeva */}
                                    <NumericFormat
                                        type="text"
                                        getInputRef={rbrRef}
                                        className='field-alignment p-1'
                                        maxLength="3"
                                        decimalScale={0}
                                        tabIndex={6}
                                        name="RBR"
                                        value={form.RBR}
                                        onFocus={() => {
                                            unosIspravanDo("RBR");
                                            getPrometFirstFreeNumberRbr();
                                            let rbr = form.RBR;
                                            setForm((prev) => ({
                                                ...prev,
                                                ...defaultForm,
                                                RBR: rbr,
                                            }));
                                            setItemUpdate(false);
                                            setArtikal(null);
                                        }}
                                        onChange={(e) => {
                                            handleChangePrometS(e);
                                        }}
                                    />
                                    {/* prikazivanje poruke o pogresnom upisu  */}
                                    {errorMsgRbr && (
                                        <div className="col-12">
                                            <span className="error-message">{errorMsgRbr}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-lg-1 ps-1">
                            <div className={errorMsgSifra ? "text-end inputWithError" : "text-end"}>
                                <div>
                                    <label className="fs-6 m-0 px-1">Šifra</label>
                                    {/* komponenta za formatiranje brojeva */}
                                    <NumericFormat
                                        type="text"
                                        getInputRef={sifraRef}
                                        maxLength="6"
                                        className='field-alignment p-1'
                                        // broj decimala
                                        decimalScale={0}
                                        tabIndex={7}
                                        placeholder="0"
                                        name="SIFRA"
                                        value={form.SIFRA}
                                        onFocus={() => {
                                            setSifraFocus(true);
                                            unosIspravanDo("SIFRA");
                                            // ukoliko je korisnik kliknuo na edit, izbaci gresku
                                            if (itemUpdate) {
                                                setModalErrorOpen(true);
                                            }
                                        }}
                                        onChange={(e) => {
                                            handleChangePrometS(e);
                                            setForm((prev) => ({
                                                ...prev,
                                                SIFRA: e.target.value,
                                            }));
                                        }}
                                    />
                                    {/* prikazivanje poruke o pogresnom upisu  */}
                                    {errorMsgSifra && (
                                        <div className="col-12">
                                            <span className="error-message">{errorMsgSifra}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-lg-5 ps-1">
                            {/* artikal */}
                            <div className={errorMsgNomen ? "inputWithError" : ""}>
                                <label className="px-1">Artikal</label>
                                <Autocomplete
                                    ref={nomenRef}
                                    // svaki od ponudjenih artikala mora biti jedinstven(svaka stavka(child) u objektu artikal mora imati jedinstven kljuc)
                                    isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                    }
                                    renderOption={(props, artikal) => {
                                        // svaki artikal renderujemo kao -li element unutar koga postoje 2 spana, te su odvojeni ps-3
                                        return (
                                            <li {...props} key={artikal.SIFRA}>
                                                <span>{artikal.SIFRA}</span>
                                                <span className="ps-3">{artikal.NAZIV}</span>
                                            </li>
                                        );
                                    }}
                                    {...defaultPropsNomen}
                                    value={artikal}
                                    disablePortal={true}
                                    onFocus={() => {
                                        // ukoliko je korisnik kliknuo na edit, izbaci gresku
                                        if (itemUpdate) {
                                            setModalErrorOpen(true);
                                        }
                                        unosIspravanDo("ARTIKAL");
                                    }}
                                    // ako se promijeni artikal, uzima podatke za novi izabrani artikal
                                    onChange={(event, newValue) => {
                                        setForm((prev) => ({
                                            ...prev,
                                            NOMENID: newValue && newValue != null ? newValue?.ID : "",
                                            POREZID:
                                                newValue && newValue != null ? newValue?.POREZID : "",
                                            JM: newValue && newValue != null ? newValue?.JM : "",
                                            SIFRA:
                                                newValue && newValue != null ? newValue?.SIFRA : "",
                                        }));
                                        // setujemo artikal
                                        setArtikal(newValue);
                                        setTimeout(() => {
                                            kolicinaRef.current.focus();
                                        }, 50);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            maxLength="283"
                                            className={itemUpdate ? "form-controlst" : ""}
                                            name="NOMENID"
                                            readOnly={itemUpdate}
                                            placeholder="artikal"
                                            variant="standard"
                                        />
                                    )}
                                />
                                {/* prikazivanje greske ukoliko artikal nije izabran */}
                                {errorMsgNomen && (
                                    <div className="col-12">
                                        <span className="error-message">{errorMsgNomen}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-6 col-lg-1 ps-1">
                            <div
                                className={errorMsgKolicina ? "inputWithError text-end" : "text-end"}
                            >
                                <div>
                                    <label className="fs-6 m-0 px-1">Količina</label>
                                    {/* komponenta za formatiranje brojeva */}
                                    <NumericFormat
                                        type="text"
                                        getInputRef={kolicinaRef}
                                        className='field-alignment p-1'
                                        // broj decimala
                                        decimalScale={2}
                                        tabIndex={8}
                                        //   className="text-dark text-end"
                                        placeholder="0"
                                        name="KOLICINA"
                                        value={form.KOLICINA}
                                        onFocus={() => {
                                            // ukoliko je korisnik kliknuo na edit, izbaci gresku
                                            if (itemUpdate) {
                                                setModalErrorOpen(true);
                                            } else {
                                                unosIspravanDo("KOLICINA");
                                                getArticleInfo();
                                            }
                                        }}
                                        onChange={(e) => {
                                            handleChangePrometS(e);
                                            setForm((prev) => ({
                                                ...prev,
                                                KOLICINA: e.target.value,
                                            }));
                                        }}
                                    />
                                    {/* prikazivanje poruke o pogresnom upisu  */}
                                    {errorMsgKolicina && (
                                        <div className="col-12">
                                            <span className="error-message">{errorMsgKolicina}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-lg-1 ps-1">
                            {/* Fakturna cijena */}
                            <div className={errorMsgPrice ? "inputWithError text-end" : "text-end"}>
                                <div>
                                    <label className="fs-6 m-0 px-1 text-nowrap">
                                        Stara cijena
                                    </label>
                                    {/* komponenta za formatiranje brojeva */}
                                    <NumericFormat
                                        type="text"
                                        maxLength="3"
                                        className='field-alignment p-1'
                                        decimalScale={3}
                                        placeholder="0"
                                        tabIndex={9}
                                        name="FAKTCEN"
                                        getInputRef={staraCijenaRef}
                                        value={form.STARA_CENA}
                                        onFocus={(e) => {
                                            // ukoliko je korisnik kliknuo na edit, izbaci gresku
                                            if (itemUpdate) {
                                                setModalErrorOpen(true);
                                            } else {
                                                unosIspravanDo("STARACIJENA");
                                            }
                                        }}
                                        onChange={(e) => {
                                            handleChangePrometS(e);
                                            setForm((prev) => ({
                                                ...prev,
                                                STARA_CENA: e.target.value,
                                            }));
                                        }}
                                    />
                                    {/* prikazivanje poruke o pogresnom upisu  */}
                                    {errorMsgPrice && (
                                        <div className="col-12">
                                            <span className="error-message">{errorMsgPrice}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-lg-1 ps-1">
                            <div
                                className={errorMsgNewPrice ? "inputWithError text-end" : "text-end"}
                            >
                                <div>
                                    <label className="fs-6 m-0 px-1 text-nowrap">
                                        Nova cijena
                                    </label>
                                    {/* komponenta za formatiranje brojeva */}
                                    <NumericFormat
                                        type="text"
                                        getInputRef={novaCijenaRef}
                                        maxLength="3"
                                        decimalScale={2}
                                        tabIndex={10}
                                        className='field-alignment p-1'
                                        // broj decimala
                                        placeholder="0"
                                        name="IZNFAKT"
                                        value={form.NOVA_CENA}
                                        onFocus={() => {
                                            // ukoliko je korisnik kliknuo na edit, izbaci gresku
                                            if (itemUpdate) {
                                                setModalErrorOpen(true);
                                            } else {
                                                unosIspravanDo("NOVACIJENA");
                                            }
                                        }}
                                        onChange={(e) => {
                                            handleChangePrometS(e);
                                            setForm((prev) => ({
                                                ...prev,
                                                NOVA_CENA: e.target.value,
                                            }));
                                        }}
                                    />
                                    {/* prikazivanje poruke o pogresnom upisu  */}
                                    {errorMsgNewPrice && (
                                        <div className="col-12">
                                            <span className="error-message">{errorMsgNewPrice}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-lg-1 ps-1">
                            <div
                                className={errorMsgRazlika ? "inputWithError text-end" : "text-end"}
                            >
                                <label className="fs-6 m-0 px-1 text-nowrap">
                                    Razlika cijene
                                </label>
                                {/* komponenta za formatiranje brojeva */}
                                <NumericFormat
                                    type="text"
                                    getInputRef={razlikaCijenaRef}
                                    maxLength="3"
                                    className='field-alignment p-1'
                                    // broj decimala
                                    decimalScale={1}
                                    placeholder="0"
                                    tabIndex={11}
                                    name="RABAT"
                                    value={form.RAZLIKA}
                                    onFocus={() => {
                                        // ukoliko je korisnik kliknuo na edit, izbaci gresku
                                        if (itemUpdate) {
                                            setModalErrorOpen(true);
                                        } else {
                                            unosIspravanDo("RAZLIKACIJENA");
                                            if (form.NOVA_CENA > 0 && form.STARA_CENA > 0) {
                                                setForm((prev) => ({
                                                    ...prev,
                                                    RAZLIKA: form.NOVA_CENA - form.STARA_CENA,
                                                    IZNOS_RAZLIKE:
                                                        (form.NOVA_CENA - form.STARA_CENA) * form.KOLICINA,
                                                }));
                                            }
                                        }
                                    }}
                                    onChange={(e) => {
                                        handleChangePrometS(e);
                                        setForm((prev) => ({
                                            ...prev,
                                            RAZLIKA: form.NOVA_CENA - form.STARA_CENA,
                                        }));
                                    }}
                                />
                                {/* prikazivanje poruke o pogresnom upisu  */}
                                {errorMsgRazlika && (
                                    <div className="col-12">
                                        <span className="error-message">{errorMsgRazlika}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-6 col-lg-1 ps-1">
                            <div className="text-end">
                                <div>
                                    <label className="fs-6 m-0 px-1 text-nowrap">Iznos</label>
                                    <NumericFormat
                                        type="number"
                                        getInputRef={iznosRazlikeRef}
                                        maxLength="13"
                                        disabled
                                        className="text-dark text-end"
                                        decimalScale={2}
                                        placeholder="0"
                                        name="IZNOS_RAZLIKE"
                                        value={form.IZNOS_RAZLIKE}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>
            </Container>

            {/* bootstrap 5 tabela */}
            <div className="table-responsive">
                <table className="table table-sm table-striped table-hover table-light ">
                    <thead>
                        <tr>
                            {/* iteracija kroz columns i dodjeljivanje table headera vrijednostima iz columns[i].name */}
                            {columns.map((column, index) => {
                                return (
                                    <th key={index} scope="col" className={column.class}>
                                        {column.name}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {/* iteracija kroz kalkulacija[] i dodjeljivanje klasa vrijednostima iz columns[i].class */}
                        {nivelacija &&
                            nivelacija?.map((n, index) => {
                                return (
                                    <tr
                                        onDoubleClick={() => {
                                            onEdit(n);
                                        }}
                                        key={index}
                                        className="col-12 user-select-none"
                                    >
                                        <td className={columns[0].class}>{n.RBR}</td>
                                        <td className={columns[1].class}>{n.SIFRA}</td>
                                        <td className={columns[2].class}>{n.NAZIV_ARTIKLA}</td>
                                        <td className={columns[3].class}>{n.JM}</td>
                                        <td className={columns[4].class}>
                                            {numberWithCommas(n.KOL)}
                                        </td>
                                        <td className={columns[5].class}>
                                            {numberWithCommas(n.STARA_CENA)}
                                        </td>
                                        <td className={columns[6].class}>
                                            {numberWithCommas(n.NOVA_CENA)}
                                        </td>
                                        <td className={columns[7].class}>
                                            {numberWithCommas(n.RAZLIKA)}
                                        </td>
                                        <td className={columns[8].class}>
                                            {numberWithCommas(n.IZNOS_RAZLIKE)}
                                        </td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            </div>
            {/* paginacija */}
            {/* prikazivanje paginacije samo ukoliko je duzina niza veca od 0  */}
            {duzinaNiza > 0 && (
                // wrapper za select i paginaciju
                <div className="d-flex justify-content-center align-items-center gap-1">
                    <div className="mb-3">
                        <select
                            className="form-select"
                            value={parseInt(itemsPerPage)}
                            onChange={(e) => {
                                setItemsPerPage(parseInt(e.target.value));
                                setCurrentPage(1);
                            }}
                        >
                            <option def="5">5</option>
                            <option defaultValue value="10">
                                10
                            </option>
                        </select>
                    </div>
                    <nav aria-label="Page navigation">
                        <ul className="pagination">
                            <li
                                className="page-item"
                                onClick={(event) => {
                                    onFirstClick(event, setCurrentPage);
                                }}
                            >
                                <a type="button" className="page-link user-select-none">
                                    First
                                </a>
                            </li>
                            <li
                                className="page-item"
                                onClick={(event) => {
                                    onPreviosClick(event, currentPage, setCurrentPage);
                                }}
                            >
                                <a className="page-link user-select-none" type="button">
                                    Previous
                                </a>
                            </li>

                            {pageNumbers.map((number) => (
                                <li
                                    type="button"
                                    key={number}
                                    className={
                                        currentPage == number
                                            ? "selected page-item user-select-none"
                                            : "page-item user-select-none"
                                    }
                                >
                                    <a
                                        className="page-link user-select-none"
                                        id={number}
                                        onClick={(event) =>
                                            onPaginationClick(event, setCurrentPage)
                                        }
                                    >
                                        {number}
                                    </a>
                                </li>
                            ))}

                            <li
                                className="page-item"
                                onClick={(event) => {
                                    onNextClick(
                                        event,
                                        currentPage + 1,
                                        setCurrentPage,
                                        duzinaNiza
                                    );
                                }}
                            >
                                <a type="button" className="page-link user-select-none">
                                    Next
                                </a>
                            </li>
                            <li
                                className="page-item"
                                onClick={(event) => {
                                    onLastClick(event, setCurrentPage, duzinaNiza);
                                }}
                            >
                                <a type="button" className="page-link user-select-none">
                                    Last
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            )}
            {/* totals */}
            <div>
                <ul className="nav flex-column flex-lg-row justify-content-end">
                    <div className="d-flex justify-content-end align-items-center fixed-bottom mb-3 me-3 gap-3">
                        <div className="user-select-none">
                            <label htmlFor="formPDV" className="form-label text-nowrap">
                                Iznos Poreza
                            </label>
                            <input
                                type="text"
                                placeholder={numberWithCommas(totalIznosPDV)}
                                className="text-end px-2"
                                id="formPDV"
                                readOnly={true}
                            />
                        </div>
                        <div className="user-select-none">
                            <label htmlFor="formSaldo" className="form-label text-nowrap">
                                Totalni Iznos
                            </label>
                            <input
                                type="text"
                                readOnly={true}
                                placeholder={numberWithCommas(totalIznos)}
                                className="text-end px-2"
                                id="formSaldo"
                            />
                        </div>
                    </div>
                </ul>
            </div>
            {/* iskakajuci modali */}
            <Modal isOpen={modalDeleteItem} animation="false" centered>
                <ModalHeader>Brisanje stavke</ModalHeader>
                <ModalBody>
                    <span>{`Da li želite da obrišete stavku broj ${form.RBR} ? `}</span>
                </ModalBody>
                <ModalFooter>
                    <div>
                        <button
                            onClick={() => setModalDeleteItem(false)}
                            className="button-no"
                        >
                            Ne
                        </button>
                        <button
                            onClick={() =>
                                onDeleteItems(
                                    prometForm.ID,
                                    form.RBR,
                                    form.NOMENID,
                                    prometForm.SKLAID
                                )
                            }
                            className="button-yes"
                        >
                            Da
                        </button>
                    </div>
                </ModalFooter>
            </Modal>
            {/* render za poruku greska na editu  */}
            <Modal isOpen={modalErrorOpen} animation="false" centered>
                <ModalHeader>Neispravan unos</ModalHeader>
                <ModalBody>
                    <span>
                        Ne možete mijenjati slog '{form.RBR}' koji već postoji u dokumentu
                    </span>
                </ModalBody>
                <ModalFooter>
                    <div>
                        <button
                            onClick={() => {
                                rbrRef.current.focus();
                                setModalErrorOpen(false);
                            }}
                            className="button-no"
                        >
                            Ok
                        </button>
                    </div>
                </ModalFooter>
            </Modal>

            <Modal isOpen={modalDeleteItem} animation="false" centered>
                <ModalHeader>Brisanje stavke</ModalHeader>
                <ModalBody>
                    <span>Da li želite da obrišete stavku broj '{form.RBR}' ?</span>
                </ModalBody>
                <ModalFooter>
                    <div>
                        <button
                            onClick={() => setModalDeleteItem(false)}
                            className="button-no"
                        >
                            Ne
                        </button>
                        <button
                            onClick={() =>
                                onDeleteItems(
                                    prometForm.ID,
                                    form.RBR,
                                    form.NOMENID,
                                    prometForm.SKLAID
                                )
                            }
                            className="button-yes"
                        >
                            Da
                        </button>
                    </div>
                </ModalFooter>
            </Modal>
            {/* render za poruku */}
            <Modal isOpen={modalErrorDeleteItemOpen} animation="false" centered>
                <ModalHeader>Neispravan unos</ModalHeader>
                <ModalBody>
                    <span>Morate odabrati stavku za brisanje!</span>
                </ModalBody>
                <ModalFooter>
                    <div>
                        <button
                            onClick={() => setModalErrorDeleteItemOpen(false)}
                            className="button-no"
                        >
                            Ok
                        </button>
                    </div>
                </ModalFooter>
            </Modal>

            {/* render za poruku */}
            <Modal isOpen={modalChangePriceError} animation="false" centered>
                <ModalHeader>Neispravan unos</ModalHeader>
                <ModalBody>
                    <span>Nova cijena je ispod nabavne cijene!</span>
                </ModalBody>
                <ModalFooter>
                    <div>
                        <button
                            onClick={() => { setModalChangePriceError(false) }}
                            className="button-no"
                        >
                            Ok
                        </button>
                    </div>
                </ModalFooter>
            </Modal>

            {/* render za poruku */}
            <Modal isOpen={modalChangeSamePriceError} animation="false" centered>
                <ModalHeader>Neispravan unos</ModalHeader>
                <ModalBody>
                    <span>Unesite novu cijenu!</span>
                </ModalBody>
                <ModalFooter>
                    <div>
                        <button
                            onClick={() => setModalChangeSamePriceError(false)}
                            className="button-no"
                        >
                            Ok
                        </button>
                    </div>
                </ModalFooter>
            </Modal>
            {/* spiner koji ce se aktivirati kada se dokument printa */}
            <div>
                {spinner && <Spinner className="spinner" color="primary" id="loader" />}
            </div>
        </div>
    );
}

export default Nivelacija;
