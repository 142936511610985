import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import "react-datepicker/dist/react-datepicker.css";
import SVG from "react-inlinesvg";
import { NumericFormat } from "react-number-format";
import {
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import deleteDocument from "../../assets/delete_document.svg";
import { useWindowSize } from "../../components/useWindowSize";
import {
  getCompanyData,
  getProdajniIznos,
  numberWithCommas,
  numberWithCommasColumns3,
  numberWithCommasColumns,
  roundTO,
} from "../../components/utilities";
import { API } from "../../constants";
// komponenta koju kreiramo
function UnosFakture() {
  const windowSize = useWindowSize();
  // definisanje stejtova i prva inicijalizacija; objekat i funkcija za taj objekat
  const yearRef = useRef();
  const documentRef = useRef();
  const dateRef = useRef();
  const clientsRef = useRef();
  const serviceRef = useRef();
  const dispatchNoteRef = useRef();
  const contractRef = useRef();
  const noteRef = useRef();
  const taxInPriceRef = useRef();
  const descriptionRef = useRef();
  const quantityRef = useRef();
  const priceRef = useRef();
  const taxRef = useRef();
  const totalRef = useRef();
  const repositoryRef = useRef();
  const rbrRef = useRef();
  const inputRef = useRef();
  // const inputRef = useRef();
  const currencyRef = useRef();

  let date = new Date();

  const [invoices, setInvoices] = useState([]);
  const [repository, setRepository] = useState(null);
  const [clients, setClients] = useState(null);
  const [service, setService] = useState(null);
  const [tax, setTax] = useState(null);
  const [selectData, setSelectData] = useState([]);
  const [selectDataClients, setSelectDataClients] = useState([]);
  const [selectDataService, setSelectDataService] = useState([]);
  const [selectDataTax, setSelectDataTax] = useState([]);
  const [startDate, setStartDate] = useState(date);
  const [currencyDate, setCurrencyDate] = useState(date);
  const [firstDockumentNumber, setFirstDockumentNumber] = useState(null);
  const [firstRbrNumber, setFirstRbrNumber] = useState(null);
  const [updateDocument, setUpdateDocument] = useState(false);
  //const [itemUpdate, setItemUpdate] = useState(false)
  const [sumValue, setSumValue] = useState(false);
  const [sumTax, setSumTax] = useState(false);
  const [sumTotal, setSumTotal] = useState(false);
  const [pending, setPending] = React.useState(false);
  const [errorInputRepository, setErrorInputRepository] = useState(false);
  const [errorInputTax, setErrorInputTax] = useState(false);
  const [errorInputDescription, setErrorInputDescription] = useState(false);
  const [errorInputDocument, setErrorInputDocument] = useState(false);
  const [errorInputclients, setErrorInputClients] = useState(false);
  const [errorInputService, setErrorInputService] = useState(false);
  const [errorInputQuantity, setErrorInputQuantity] = useState(false);
  const [errorInputPrice, setErrorInputPrice] = useState(false);
  const [errorInputValue, setErrorInputValue] = useState(false);
  const [errorInputRbr, setErrorInputRbr] = useState(false);
  const [errorDate, setErrorDate] = useState(false);
  const [errorInputYear, setErrorInputYear] = useState(false);
  const [itemUpdate, setItemUpdate] = useState(false);
  const [errorMsgRepository, setErrorMsgRepository] = useState(false);
  const [errorMsgDocument, setErrorMsgDocument] = useState(false);
  const [errorMsgClients, setErrorMsgClients] = useState(false);
  const [errorMsgService, setErrorMsgService] = useState(false);
  const [errorMsgQuantity, setErrorMsgQuantity] = useState(false);
  const [errorMsgDescription, setErrorMsgDescription] = useState(false);
  const [errorMsgPrice, setErrorMsgPrice] = useState(false);
  const [errorMsgValue, setErrorMsgValue] = useState(false);
  const [errorMsgRbr, setErrorMsgRbr] = useState(false);
  const [errorMsgTax, setErrorMsgTax] = useState(false);
  const [errorMsgDate, setErrorMsgDate] = useState(false);
  const [errorMsgCurrency, setErrorMsgCurrency] = useState(false);
  const [errorMsgYear, setErrorMsgYear] = useState(false);
  const [errorCurrency, setErrorCurrency] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalDeleteItem, setModalDeleteItem] = useState(false);
  const [modalErrorDeleteDocumentOpen, setModalErrorDeleteDocumentOpen] =
    useState(false);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const [modalErrorDeleteItemOpen, setModalErrorDeleteItemOpen] =
    useState(false);
  const [modalErrorOpenData, setModalErrorOpenData] = useState(false);
  const [modalErrorAuthorizationData, setModalErrorAuthorizationOpen] =
    useState(false);

  const [spinner, setSpinner] = useState(false);

  const token =
    typeof window !== "undefined" ? localStorage.getItem("accessToken") : null;

  // definisanje stejta(objekat i funkcija za taj objekat), prva inicijalizacija
  const [form, setForm] = useState({
    SKLAID: "",
    VRSTA_SKLADISTA: "",
    GODINA: "",
    BRDOK: "",
    DATUM: "",
    VALUTA: "",
    KOMTID: "",
    OTPREM: "",
    OTPIZV: "",
    NAPOMENA: "",
    POR_U_CENI: "",
    RBR: 0,
    USLUGA: "",
    OPIS: "",
    KONTO: "",
    JM: "",
    KOL: 0,
    CENA: "",
    POREZID: "",
    VRED: 0,
    stavkaPronadjena: false,
    staraKolicina: "",
    staraVrijednost: "",
    staraCijena: "",
    // pdvObveznik: ""
  });

  // funkcija za povlacenje podataka o preduzecu
  getCompanyData(form);
  let year = form.companyYear;
  let pdvObveznik = form.Pdv;

  // funkcija koja se poziva klikom na dugme 'Sacuvaj'
  const onSubmit = () => {
    // uslov: ako nisu unesena obavezna polja, zaustavlja upis
    if (!unosIspravanDo("UPIS")) return;
    if (form && !form.DATUM) {
      form.DATUM = startDate;
      form.VALUTA = currencyDate;
    }
    form.SIFKOR = form.userCode;

    fetch(API + "/unosFakture/new", {
      method: "POST",
      body: JSON.stringify(form),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setForm((prev) => ({
          ...prev,
          USLUGA: "",
          OPIS: "",
          KONTO: "",
          JM: "",
          KOL: "",
          CENA: "",
          POREZ: "",
          POREZID: "",
          VRED: "",
          VRED_POR: "",
          stavkaPronadjena: false,
        }));
        setTax(null);
        setService(null);
        setUpdateDocument(false);
        setItemUpdate(false);
        setFirstDockumentNumber(false);
        getInvoicesData();
      });
    // zaustavljanje fokusa(pauza za fokus dok ne odradi upis)
    const timeout = setTimeout(() => {
      rbrRef.current.focus();
    }, 100);
    return () => {
      clearTimeout(timeout);
    };
  };

  // funkcija koja prati promjenu stanja(poziva apije za citanje i popunjavanje autokomplitova)
  useEffect(() => {
    getRepositoryData();
    getClientsData();
    getServiceData();
    getTaxData();
    repositoryRef.current.focus();
  }, [!repository && !service && !clients && !tax]);

  // funkcija koja poziva citanje skladista
  const getRepositoryData = () => {
    fetch(API + `/skladista/all/${form.companyCode}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setSelectData(data);
      });
  };
  // funkcija koja poziva citanje komitenta
  const getClientsData = () => {
    fetch(API + `/komitenti/all/${form.companyCode}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setSelectDataClients(data);
      });
  };

  // funkcija koja poziva citanje usluge
  const getServiceData = () => {
    fetch(API + `/usluge/all`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setSelectDataService(data);
      });
  };

  // funkcija koja poziva citanje konta, jedinice mjere, cijene i poreza po sifri usluge
  const getReadService = () => {
    fetch(API + `/usluge/readService`, {
      method: "POST",
      body: JSON.stringify({
        uslugaU: service && service.SIFRA ? service.SIFRA : "",
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data.length) {
          setForm((prev) => ({
            ...prev,
            KONTO: data[0].KONTO,
            JM: data[0].JM,
            CENA: data[0].CENA,
            //  POREZID: data[0].ID,
            //  POREZ: data[0].PROCENAT,
          }));
          // selectDataTax.map((tax) => {
          //   if (tax.ID == data[0].POREZID) {
          //     setTax(tax);
          //   }
          // });
        } else {
          setForm((prev) => ({
            ...prev,
            KONTO: "",
            JM: "",
            CENA: "",
            //  POREZID: "",
            //  POREZ: "",
          }));
          setTax(null);
        }
      });
  };

  // funkcija koja poziva citanje poreza
  const getTaxData = () => {
    fetch(API + `/porezi/all`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setSelectDataTax(data);
      });
  };

  // funkcija koja poziva pronalazenje prvog slobodnog dokumenta
  const getInvoiceFirstFreeNumber = () => {
    fetch(API + `/unosFakture/firstFreeNumber`, {
      method: "POST",
      body: JSON.stringify({
        sklaId: repository && repository.ID ? repository.ID : "",
        godina: form.GODINA,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setForm((prev) => ({ ...prev, BRDOK: data[0].DOK }));
        setFirstDockumentNumber(data[0].DOK);
      });
  };

  // funkcija koja poziva pronalazenje prvog slobodnog rednog broja
  const getInvoiceFirstFreeNumberRbr = () => {
    fetch(API + `/unosFakture/firstFreeNumberRbr`, {
      method: "POST",
      body: JSON.stringify({
        sklaId: repository && repository.ID ? repository.ID : "",
        godina: form.GODINA,
        brdok: form.BRDOK,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setForm((prev) => ({ ...prev, RBR: data[0].RBR }));
        setFirstDockumentNumber(data[0].RBR);
      });
  };

  // funkcija koja poziva citanje mreze
  const getInvoicesData = () => {
    fetch(API + `/unosFakture/document`, {
      method: "POST",
      body: JSON.stringify({
        sklaId: repository && repository.ID ? repository.ID : "",
        godina: form.GODINA,
        brdok: form.BRDOK,
        pdvObveznik: pdvObveznik
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setInvoices(data);
        let VRIJEDNOSTSum = 0;
        let POREZSum = 0;
        let UKUPNOSum = 0;
        // petlja za citanje podataka
        for (let i = 0; i < data.length; i++) {
          VRIJEDNOSTSum += data[i].VRED;
          POREZSum += data[i].IZNOSPOR;
          UKUPNOSum += data[i].VRED + data[i].IZNOSPOR;
        }
        setSumValue(numberWithCommasColumns(roundTO(VRIJEDNOSTSum, 2)));
        setSumTax(numberWithCommasColumns(roundTO(POREZSum, 2)));
        setSumTotal(numberWithCommasColumns(roundTO(UKUPNOSum, 2)));
        setPending(false);
      });
  };

  // funkcija koja poziva citanje po dokumentu
  const checkDocument = () => {
    fetch(API + `/unosFakture/check/documentData`, {
      method: "POST",
      body: JSON.stringify({
        sklaId: repository && repository.ID ? repository.ID : "",
        godina: form.GODINA,
        brdok: form.BRDOK,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data.length) {
          setForm((prev) => ({
            ...prev,
            DATUM: moment
              .unix(new Date(data[0].DATUM).getTime() / 1000)
              .format("DD.MM.YYYY"),
            VALUTA: moment
              .unix(new Date(data[0].VALUTA).getTime() / 1000)
              .format("DD.MM.YYYY"),
            KOMTID: data[0].KOMTID,
            NAPOMENA: data[0].NAPOMENA,
            OTPREM: data[0].OTPREM,
            OTPIZV: data[0].OTPIZV,
            POR_U_CENI: data[0].POR_U_CENI,
          }));
          selectDataClients.map((item) => {
            if (item.ID == data[0].KOMTID) {
              setClients(item);
            }
          });
          getInvoicesData();
          setUpdateDocument(true);
          setItemUpdate(false)
          //  getTotal()
        } else {
          form.stavkaPronadjena = false;
          setSumValue(false);
          setSumTax(false);
          setSumTotal(false);
        }
      });
  };
  // funkcija koja poziva citanje totala
  // const getTotal = () => {
  //     fetch(API + `/invoice/total`, {
  //         method: 'POST',
  //         body: JSON.stringify({ sklaId: repository && repository.ID ? repository.ID : '', godina: year, brdok: form.BRDOK }),
  //         headers: {
  //             'Authorization': `Bearer ${token}`,
  //             'Content-type': 'application/json; charset=UTF-8'
  //         },
  //     }).then((response) => response.json())
  //         .then(data => {
  //             if (data && data.length) {
  //                 setSumValue(data[0].VRIJEDNOST.toFixed(2))
  //                 setSumTax(data[0].POREZ.toFixed(2))
  //                 setSumTotal(data[0].UKUPNO.toFixed(2))
  //             }
  //         })
  // }

  // funkcija koja poziva brisanje dokumenta
  const onDelete = (sklaid, godina, brdok) => {
    // provjera ovlascenja za brisanje
    if (form.ovlfakt == 4)
      return setModalErrorAuthorizationOpen(true), setModalOpen(false);

    fetch(API + `/unosFakture/delete/${sklaid}/${godina}/${brdok}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    }).then((response) => {
      if (response.status == 200) {
        setModalOpen(false);
        setInvoices(null);
        setClients(null);
        setService(null);
        setTax(null);
        setItemUpdate(false);
        checkDocument();
        setForm((prev) => ({
          ...prev,
          DATUM: "",
          VALUTA: "",
          KOMTID: "",
          OTPREM: "",
          OTPIZV: "",
          NAPOMENA: "",
          POR_U_CENI: "",
          RBR: "",
          USLUGA: "",
          OPIS: "",
          KONTO: "",
          JM: "",
          KOL: "",
          CENA: "",
          POREZID: "",
          VRED: "",
          VRED_POR: "",
          stavkaPronadjena: false,
          staraKolicina: "",
          staraCijena: "",
          staraVrijednost: "",
        }));
      }
    });

    //  zaustavljanje fokusa(pauza za fokus dok ne odradi brisanje)
    const timeout = setTimeout(() => {
      documentRef.current.focus();
    }, 400);
    return () => {
      clearTimeout(timeout);
    };
  };

  // funkcija koja poziva brisanje stavke
  const onDeleteItems = (sklaid, godina, brdok, rbr) => {
    // provjera ovlascenja za brisanje
    if (form.ovlfakt == 4)
      return setModalErrorAuthorizationOpen(true), setModalDeleteItem(false);

    fetch(API + `/unosFakture/item/delete/${sklaid}/${godina}/${brdok}/${rbr}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    }).then((response) => {
      if (response.status == 200) {
        setModalDeleteItem(false);
        setInvoices(null);
        //  setClients(null)
        setService(null);
        setTax(null);
        setItemUpdate(false);
        checkDocument();
        setForm((prev) => ({
          ...prev,
          USLUGA: "",
          OPIS: "",
          KONTO: "",
          JM: "",
          KOL: "",
          CENA: "",
          POREZID: "",
          VRED: "",
          VRED_POR: "",
          stavkaPronadjena: false,
        }));
      }
    });

    // zaustavljanje fokusa(pauza za fokus dok ne odradi brisanje)
    const timeout = setTimeout(() => {
      rbrRef.current.focus();
    }, 400);
    return () => {
      clearTimeout(timeout);
    };
  };

  // funkcija koja poziva citanje stavke iz mreze i postavljanje na ekran
  const onEdit = (item) => {
    setItemUpdate(true);
    // if (item.USLUGA == 0) {
    //   setService(null);
    // }
    form.stavkaPronadjena = true;
    setTax(null);
    // setForm((prevState) => ({
    //   ...prevState, ...item,
    // }));
    setForm((prevState) => ({
      ...prevState, RBR: item.RBR, USLUGA: item.USLUGA, OPIS: item.OPIS, KONTO: item.KONTO, JM: item.JM,
      KOL: item.KOL, CENA: item.CENA, POREZ: item.PROCENAT, POREZID: item.POREZID, staraKolicina: item.KOL, staraCijena: item.CENA, staraVrijednost: item.VRED, VRED: item.VRED
    })); 
    //console.log(form, 'form')
    // console.log(form, 'form')

    selectDataService.map((service) => {
      if (service.SIFRA == item.USLUGA) {
        setService(service);
      }
    });
    selectDataTax.map((tax) => {
      if (tax.ID == item.POREZID) {
        setForm((prev) => ({
          ...prev,
          POREZID: tax && tax != null ? tax.ID : "",
          POREZ: tax && tax != null && tax.PROCENAT ? tax.PROCENAT : 0,
        }));
        setTax(tax);
      }
    });
  };

  // funkcija koja hvata promjene na poljima i  setuje vrijednost u form state
  const handleChange = (event) => {
    let value = event.target.value;
    const name = event.target.name;

    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // propsovi za auto komplit  - skladiste, komitent, usluga, porez
  const defaultProps = {
    options: selectData,
    getOptionLabel: (option) =>
      option ? `${option.SIFRA} ${option.NAZIV}` : "",
  };

  const defaultPropsClients = {
    options: selectDataClients,
    getOptionLabel: (option) =>
      option ? `${option.SIFRA} ${option.NAZIV}` : "",
  };

  const defaultPropsService = {
    options: selectDataService,
    getOptionLabel: (option) =>
      option ? `${option.SIFRA} ${option.NAZIV}` : "",
  };

  const defaultPropsTax = {
    options: selectDataTax,
    getOptionLabel: (option) =>
      option ? `${option.SIFRA} ${option.NAZIV}` : "",
  };

  // funkcije za provjeru obaveznih polja - 3 skladiste, komitent, usluga
  const checkRepositoryRequired = (value) => {
    if ((value && !repository) || (!value && !repository)) {
      setErrorMsgRepository("Skladiste mora biti izabrano");
      setErrorInputRepository(true);
      repositoryRef.current.focus();
      return false;
    } else {
      setErrorMsgRepository(null);
      setErrorInputRepository(false);
      return true;
    }
  };

  // uslov: ako nije upisana neka vrijednost ili je vrijednost 0, izbaci poruku, setuje crveni okvir i fokusira polje
  // u suprotnom prolazi dalje
  const checkYearRequired = (value) => {
    if (!value || value == 0) {
      setErrorMsgYear("Godina je obavezna!");
      setErrorInputYear(true);
      yearRef.current.focus();
      return false;
    } else {
      setErrorMsgYear(null);
      setErrorInputYear(false);
      return true;
    }
  };


  // uslov: ako nije upisana neka vrijednost ili je vrijednost 0, izbaci poruku, setuje crveni okvir i fokusira polje
  const checkDocumentRequired = (value) => {
    if (!value || value == 0) {
      setErrorMsgDocument("Dokument je obavezan!");
      setErrorInputDocument(true);
      documentRef.current.focus();
      // setDocumentFocus(true)
      return false;
    } else {
      setErrorMsgDocument(null);
      setErrorInputDocument(false);
      return true;
    }
  };

  //  uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto) ili ako nije upisana ni vrijednost niti je odabran komitent, izbaci poruku, setuje crveni okvir i fokusira polje
  const checkClientsRequired = (value) => {
    if ((value && !clients) || (!value && !clients)) {
      setErrorMsgClients("Komitent mora biti izabran!");
      setErrorInputClients(true);
      clientsRef.current.focus();
      return false;
    } else {
      setErrorMsgClients(null);
      setErrorInputClients(false);
      return true;
    }
  };

  // uslov: ako nije upisana neka vrijednost ili je vrijednost 0, izbaci poruku, setuje crveni okvir i fokusira polje
  const checkRBRRequired = (value) => {
    if (!value || value == 0) {
      setErrorMsgRbr("Rbr je obavezan!");
      setErrorInputRbr(true);
      rbrRef.current.focus();
      return false;
    } else {
      setErrorMsgRbr(null);
      setErrorInputRbr(false);
      return true;
    }
  };

  // funkcije za provjeru obaveznih polja - 3 skladiste, komitent, usluga
  const checkServiceRequired = (value) => {
    if ((value && !service) || (!value && !service)) {
      setErrorMsgService("Usluga mora biti izabrana");
      setErrorInputService(true);
      serviceRef.current.focus();
      return false;
    } else {
      setErrorMsgService(null);
      setErrorInputService(false);
      return true;
    }
  };

  // uslov: ako nije upisana neka vrijednost ili je vrijednost 0, izbaci poruku, setuje crveni okvir i fokusira polje    // u suprotnom prolazi dalje
  const checkKolRequired = (value) => {
    // uslov: ako nije upisana vrijednost ili je vrijednost 0 i odabran je artikal, izbaci poruku i setuje crveni okvir. U suprotnom: prolazi dalje i racuna prodajni iznos(misli se na racunanje unazad)
    if (!value || value == 0) {
      setErrorInputQuantity(true);
      setErrorMsgQuantity("Količina je obavezna!");
      quantityRef.current.focus();
      return false;
    } else {
      setErrorMsgQuantity(null);
      setErrorInputQuantity(false);
      setForm((prev) => ({
        ...prev,
        VRED: Number(form.KOL) * Number(form.CENA),
      }));
      return true;
    }
  };

  // uslov: ako nije upisana neka vrijednost ili je vrijednost 0, izbaci poruku, setuje crveni okvir i fokusira polje    // u suprotnom prolazi dalje
  const checkPriceRequired = (value) => {
    // uslov: ako nije upisana vrijednost ili je vrijednost 0 i odabran je artikal, izbaci poruku i setuje crveni okvir. U suprotnom: prolazi dalje i racuna prodajni iznos(misli se na racunanje unazad)
    if (!value || value == 0 || form.CENA == 0) {
      setErrorInputPrice(true);
      setErrorMsgPrice("Cijena je obavezna!");
      priceRef.current.focus();
      return false;
    } else {
      setErrorMsgPrice(null);
      setErrorInputPrice(false);
      //   setForm(prev => ({ ...prev, VRED: (Number(form.KOL) * Number(form.CENA)) }))
      return true;
    }
  };

  // uslov: ako nije upisana neka vrijednost ili je vrijednost 0, izbaci poruku, setuje crveni okvir i fokusira polje    // u suprotnom prolazi dalje
  const checkTaxRequired = (value) => {
    // uslov: ako nije upisana vrijednost ili je vrijednost 0, izbaci poruku i setuje crveni okvir. U suprotnom: prolazi dalje
    if (!value || value == 0) {
      setErrorInputTax(true);
      setErrorMsgTax("Porez je obavezan!");
      taxRef.current.focus();
      return false;
    } else {
      setErrorMsgTax(null);
      setErrorInputTax(false);
      return true;
    }
  };

  // uslov: ako nije upisana neka vrijednost ili je vrijednost 0, izbaci poruku, setuje crveni okvir i fokusira polje    // u suprotnom prolazi dalje
  const checkTotalRequired = (value) => {
    // uslov: ako nije upisana vrijednost ili je vrijednost 0 i odabran je artikal, izbaci poruku i setuje crveni okvir. U suprotnom: prolazi dalje i racuna prodajni iznos(misli se na racunanje unazad)
    if (!value || value == 0) {
      setErrorInputValue(true);
      setErrorMsgValue("Vrijednost je obavezna!");
      totalRef.current.focus();
      return false;
    } else {
      setErrorMsgValue(null);
      setErrorInputValue(false);
      form.CENA = Number(form.VRED) / Number(form.KOL)
      return true;
    }
  };

  // funkcija za racunanje unazad(ako se promijeni vrijednost, racuna ponovo cijenu i preko mape izvlaci podatke u vezi sa porezom)
  // const staraVrijednost = () => {
  //   if (form.staraVrijednost !== form.VRED) {
  //     if (form.KOL) {
  //       form.CENA = Number(form.VRED) / Number(form.KOL);
  //       setForm((prev) => ({
  //         ...prev,
  //         CENA: Number(form.CENA),
  //       }));
  //       // ako se mijenja vrijednost(racuna ponovo iznos poreza)
  //       selectDataTax.map((tax) => {
  //         if (tax.ID == form.POREZID) {
  //           setForm((prev) => ({
  //             ...prev,
  //             POREZID: tax && tax != null ? tax.ID : "",
  //             POREZ: tax && tax != null && tax.PROCENAT ? tax.PROCENAT : "",
  //           }));
  //         }
  //       });
  //     } else {
  //       // ako nije upisana kolicina, ne racuna porez
  //       selectDataTax.map((tax) => {
  //         if (tax.ID == form.POREZID) {
  //           setForm((prev) => ({ ...prev, POREZID: "", POREZ: "" }));
  //         }
  //       });
  //     }
  //   }
  // };

  // funkcija za racunanje vrijednosti ukoliko se ne ide tabom nego se klikne na dugme sacuvaj(ako se promijeni cijena, racuna ponovo vrijednost i preko mape izvlaci podatke u vezi sa porezom)
  // const staraCijena = () => {
  //   if (form.staraCijena !== form.CENA) {
  //     if (form.KOL) {
  //       form.VRED = Number(form.CENA) * Number(form.KOL);
  //       setForm((prev) => ({
  //         ...prev,
  //         VRED: Number(form.VRED),
  //       }));
  //     }
  //     // else {
  //     //     // ako nije upisana kolicina, ne racuna porez
  //     //     selectDataTax.map((tax) => {
  //     //         if (tax.ID == form.POREZID) {
  //     //             setForm(prev => ({ ...prev, POREZID: '', POREZ: '' }))

  //     //         }
  //     //     })
  //     // }
  //   }
  // };

  // funkcija za kontrolisanje obaveznih polja, kontrolise sva polja do polja na koje je dosao fokus
  function unosIspravanDo(name) {
    if (name === "SKLAID") {
      return true;
    }

    if (!checkRepositoryRequired(form.SKLAID)) {
      return false;
    }

    // kontrolise sva polja do polja GODINA
    if (name === "GODINA") {
      return true;
    }

    if (!checkYearRequired(form.GODINA)) {
      return false;
    }

    // kontrolise sva polja do polja BRDOK
    if (name === "BRDOK") {
      return true;
    }

    if (!checkDocumentRequired(form.BRDOK)) {
      return false;
    }

    // kontrolise sva polja do polja DATUM
    if (name === "DATUM") {
      return true;
    }

    let reqex = /^(0[1-9]|[12]\d|3[01]).(0[1-9]|1[0-2]).([12]\d{3})$/gi;

    if (!reqex.test(form.DATUM)) {
      setErrorDate(true);
      setErrorMsgDate("Datum nije validan!");
      dateRef.current.focus();
      return false;
    } else {
      setErrorDate(false);
      setErrorMsgDate(null);
      //   setStartDate(startDate)
    }

    // kontrolise sva polja do polja VALUTA
    if (name === "VALUTA") {
      return true;
    }

    let reqexVALUTA = /^(0[1-9]|[12]\d|3[01]).(0[1-9]|1[0-2]).([12]\d{3})$/gi;
    if (!reqexVALUTA.test(form.VALUTA)) {
      setErrorCurrency(true);
      setErrorMsgCurrency("Valuta nije validna!");
      currencyRef.current.focus();
      return false;
    } else {
      setErrorCurrency(false);
      setErrorMsgCurrency(null);
    }

    // kontrolise sva polja do polja KOMTID
    if (name === "KOMTID") {
      return true;
    }

    if (!checkClientsRequired(form.KOMTID)) {
      return false;
    }

    // kontrolise sva polja do polja NAPOMENA
    if (name === "NAPOMENA") {
      return true;
    }

    // kontrolise sva polja do polja POR_U_CENI
    if (name === "POR_U_CENI") {
      return true;
    }

    // kontrolise sva polja do polja OTPREM
    if (name === "OTPREM") {
      return true;
    }

    // kontrolise sva polja do polja OTPIZV
    if (name === "OTPIZV") {
      return true;
    }

    // kontrolise sva polja do polja RBR
    if (name === "RBR") {
      return true;
    }

    if (!checkRBRRequired(form.RBR)) {
      return false;
    }

    // kontrolise sva polja do polja USLUGA
    if (name === "USLUGA") {
      return true;
    }

    if (!checkServiceRequired(form.USLUGA)) {
      return false;
    }

    // kontrolise sva polja do polja OPIS
    if (name === "OPIS") {
      return true;
    }

    // kontrolise sva polja do polja KONTO
    if (name === "KONTO") {
      return true;
    }

    // kontrolise sva polja do polja JM
    if (name === "JM") {
      return true;
    }

    // kontrolise sva polja do polja KOL
    if (name === "KOL") {
      return true;
    }

    // uslov: ako je odabrana usluga, provjerava da li je unijeta kolicina
    // if (form.USLUGA != 0) {
    if (!checkKolRequired(form.KOL)) {
      return false;
    }
    // }

    // kontrolise sva polja do polja CENA
    if (name === "CENA") {
      return true;
    }

    if (form.staraKolicina !== form.KOL && form.stavkaPronadjena) {
      form.VRED = Number(form.KOL) * Number(form.CENA);
      setForm((prev) => ({
        ...prev,
        VRED: Number(form.VRED),
      }));
      //     form.CENA = (Number(form.VRED) / Number(form.KOL))
      //     setForm(prev => ({
      //        ...prev, CENA: Number(form.CENA)
      //    }))
    }
    //   (form.CENA, 'cijenaaa prva')

    // uslov: ako je odabrana usluga, provjerava da li je unijeta cijena
    // if (form.USLUGA != 0) {
    if (!checkPriceRequired(form.CENA)) {
      return false;
    }
    // }

    // kontrolise sva polja do polja POREZ
    if (name === "POREZ") {
      return true;
    }

    // uslov: ako je pdv obveznik, porez je obavezno polje
    if (pdvObveznik === 'D') {
      if (!checkTaxRequired(form.POREZID)) {
        return false;
      }
      // setForm((prev) => ({
      //   ...prev,
      //   VRED_POR: Number(form.CENA) * Number(form.KOL),
      // }));
    }

    // uslov: ako je unijeta kolicina i cijena, racuna vrijednost
    // if (pdvObveznik == 'D') {
    //   setForm((prev) => ({
    //     ...prev,
    //     VRED_POR: Number(form.CENA) * Number(form.KOL),
    //   }));
    // }


    // kontrolise sva polja do polja VRED
    if (name === "VRED") {
      return true;
    }
    // uslov: ako je odabrana usluga, provjerava da li je unijeta vrijednost
    // if (form.USLUGA != 0) {
    if (!checkTotalRequired(form.VRED)) {
      return false;
    }

    form.staraVrijednost = form.VRED
   // console.log(form.staraVrijednost, 'form.staraVrijednost', form.VRED, 'form.VRED')
   // console.log(form.staraVrijednost != form.VRED, 'form.staraVrijednost != form.VRED')
    if (form.staraVrijednost != form.VRED) {
      setForm((prev) => ({
        ...prev,
        CENA: Number(form.VRED) / Number(form.KOL),
      }));;
    }

    // kontrolise sva polja do polja UPIS
    if (name === "UPIS") {
      return true;
    }


    // uslov: ako je sve u redu, prolazi na upis
    return true;
  }

  // funkcija koja poziva api za stampu
  const onPrint = () => {
    setSpinner(true);
    // uslov: ako nije izabrano skladiste, godina i broj dokumenta(ako je prvi slobodan dokument), nece otvoriti izvjestaj
    if (!unosIspravanDo("DATUM")) return setSpinner(false);
    if (form.BRDOK === firstDockumentNumber)
      return setSpinner(false), setModalErrorOpen(true);

    if (form) {
      form.SIFRAP = form.companyCode;
    }

    fetch(API + "/unosFakture/print", {
      method: "POST",
      body: JSON.stringify(form),
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    }).then((response) => {
      if (response.ok) {
        // checks if the response is with status 200 (successful)
        return response.blob().then((blob) => {
          let blobURL = URL.createObjectURL(blob);

          let iframe = document.createElement("iframe"); //load content in an iframe to print later
          document.body.appendChild(iframe);

          iframe.style.display = "none";
          iframe.src = blobURL;
          iframe.onload = function () {
            setTimeout(function () {
              iframe.focus();
              iframe.contentWindow.print();
            }, 1);
          };
          setSpinner(false);
        });
      }
    });
  };

  // funkcija za polja u tabeli
  // dodavanje imena i klase za dati table header
  // const columns = [
  //   {
  //     name: "Rbr",
  //     class: "col-1 fs-6 text-end px-1",
  //   },
  //   {
  //     name: "Usluga",
  //     class: "col-2 fs-6 px-1",
  //   },
  //   {
  //     name: "Opis",
  //     class: "col-3 fs-6 pe-3",
  //   },
  //   {
  //     name: "Konto",
  //     class: "col-1 fs-6 px-1",
  //   },
  //   {
  //     name: "JM",
  //     class: "col-1 fs-6 px-1",
  //   },
  //   {
  //     name: "Količina",
  //     class: "col-1 fs-6 text-end px-1",
  //   },
  //   {
  //     name: "Cijena",
  //     class: "col-1 fs-6 text-end",
  //   },
  //   {
  //     name: "Vr. bez pdv-a",
  //     class: "col-1 fs-6 text-end px-1",
  //   },
  //   {
  //     name: "Porez",
  //     class: "col-1 fs-6 text-end px-1",
  //   },
  //   {
  //     name: "Vr.sa pdv",
  //     class: "col-1 fs-6 text-end px-1",
  //   },
  // ];

  // funkcija za polja u mrezi
  const columns = [
    {
      name: 'Rbr',
      selector: row => row.RBR,
      // sirina polja po rezolucijama
      width:
        windowSize.width > 3072 ? '200px'
          : windowSize.width > 2048 ? '200px'
            : windowSize.width > 1920 ? '100px'
              : windowSize.width > 1680 ? '90px'
                : windowSize.width > 1600 ? '90px'
                  : windowSize.width > 1440 ? '70px'
                    : windowSize.width > 1280 ? '60px'
                      : windowSize.width > 1024 ? '60px'
                        : '60px',
      right: 'boolean',
      cell: (row) => (
        <>
          <span id='media-query-rows'>{row.RBR}</span>
        </>
      ),
    },
    {
      name: 'Usluga',
      selector: row => row.SIFRAU,
      selector: row => row.NAZIVU,
      // sirina polja po rezolucijama
      width:
        windowSize.width > 3072 ? '400px'
          : windowSize.width > 2048 ? '400px'
            : windowSize.width > 1920 ? '350px'
              : windowSize.width > 1680 ? '330px'
                : windowSize.width > 1600 ? '340px'
                  : windowSize.width > 1440 ? '330px'
                    : windowSize.width > 1280 ? '270px'
                      : windowSize.width > 1024 ? '240px'
                        : '300px',
      //  right: 'boolean',
      cell: (row) => (
        <>
          <span id='media-query-rows-text'>{row.SIFRAU} {row.NAZIVU}</span>
        </>
      ),
    },
    {
      name: 'Opis',
      selector: row => row.OPIS,
      // sirina polja po rezolucijama
      width:
        windowSize.width > 3072 ? '680px'
          : windowSize.width > 2048 ? '480px'
            : windowSize.width > 1920 ? '340px'
              : windowSize.width > 1680 ? '330px'
                : windowSize.width > 1600 ? '320px'
                  : windowSize.width > 1440 ? '310px'
                    : windowSize.width > 1280 ? '270px'
                      : windowSize.width > 1024 ? '230px'
                        : '300px',
      cell: (row) => (
        <>
          <span id='media-query-rows-text'>{row.OPIS}</span>
        </>
      ),
    },
    {
      name: 'Konto',
      selector: row => row.KONTO,
      // sirina polja po rezolucijama
      width:
        windowSize.width > 3072 ? '200px'
          : windowSize.width > 2048 ? '100px'
            : windowSize.width > 1920 ? '100px'
              : windowSize.width > 1680 ? '90px'
                : windowSize.width > 1600 ? '100px'
                  : windowSize.width > 1440 ? '80px'
                    : windowSize.width > 1280 ? '70px'
                      : windowSize.width > 1024 ? '70px'
                        : '100px',
      cell: (row) => (
        <>
          <span id='media-query-rows-text'>{row.KONTO}</span>
        </>
      ),
    },
    {
      name: 'JM',
      selector: row => row.JM,
      // sirina polja po rezolucijama
      width:
        windowSize.width > 3072 ? '200px'
          : windowSize.width > 2048 ? '100px'
            : windowSize.width > 1920 ? '100px'
              : windowSize.width > 1680 ? '90px'
                : windowSize.width > 1600 ? '80px'
                  : windowSize.width > 1440 ? '70px'
                    : windowSize.width > 1280 ? '60px'
                      : windowSize.width > 1024 ? '50px'
                        : '80px',
      cell: (row) => (
        <>
          <span id='media-query-rows-text'>{row.JM}</span>
        </>
      ),
    },
    {
      name: 'Količina',
      selector: row => row.KOL,
      // sirina polja po rezolucijama
      width:
        windowSize.width > 3072 ? '200px'
          : windowSize.width > 2048 ? '200px'
            : windowSize.width > 1920 ? '110px'
              : windowSize.width > 1680 ? '100px'
                : windowSize.width > 1600 ? '90px'
                  : windowSize.width > 1440 ? '80px'
                    : windowSize.width > 1280 ? '80px'
                      : windowSize.width > 1024 ? '90px'
                        : '100px',
      right: 'boolean',
      cell: (row) => (
        <>
          <span id='media-query-rows'>{numberWithCommasColumns(row.KOL)}</span>
        </>
      ),
    },
    {
      name: 'Cijena',
      selector: row => row.CENA,
      // sirina polja po rezolucijama
      width:
        windowSize.width > 3072 ? '200px'
          : windowSize.width > 2048 ? '200px'
            : windowSize.width > 1920 ? '110px'
              : windowSize.width > 1680 ? '100px'
                : windowSize.width > 1600 ? '100px'
                  : windowSize.width > 1440 ? '100px'
                    : windowSize.width > 1280 ? '90px'
                      : windowSize.width > 1024 ? '90px'
                        : '90px',
      right: 'boolean',
      cell: (row) => (
        <>
          <span id='media-query-rows'>{numberWithCommasColumns(row.CENA)}</span>
        </>
      ),
    },
    {
      name: 'Vrijednost bez pdv',
      selector: row => row.VRED,
      // sirina polja po rezolucijama
      width:
        windowSize.width > 3072 ? '200px'
          : windowSize.width > 2048 ? '200px'
            : windowSize.width > 1920 ? '190px'
              : windowSize.width > 1680 ? '120px'
                : windowSize.width > 1600 ? '100px'
                  : windowSize.width > 1440 ? '100px'
                    : windowSize.width > 1280 ? '95px'
                      : windowSize.width > 1024 ? '95px'
                        : '90px',
      right: 'boolean',
      cell: (row) => (
        <>
          <span id='media-query-rows'>{numberWithCommasColumns(row.VRED)}</span>
        </>
      ),
    },
    {
      name: 'Porez',
      selector: row => row.IZNOSPOR,
      // sirina polja po rezolucijama
      width:
        windowSize.width > 3072 ? '200px'
          : windowSize.width > 2048 ? '200px'
            : windowSize.width > 1920 ? '190px'
              : windowSize.width > 1680 ? '120px'
                : windowSize.width > 1600 ? '100px'
                  : windowSize.width > 1440 ? '100px'
                    : windowSize.width > 1280 ? '90px'
                      : windowSize.width > 1024 ? '80px'
                        : '90px',
      right: 'boolean',
      cell: (row) => (
        <>
          <span id='media-query-rows'>{roundTO((row.IZNOSPOR), 4)}</span>
        </>
      ),
    },
    {
      name: 'Vrijednost sa pdv',
      selector: row => row.VRED_POR,
      // sirina polja po rezolucijama
      width:
        windowSize.width > 3072 ? '200px'
          : windowSize.width > 2048 ? '200px'
            : windowSize.width > 1920 ? '190px'
              : windowSize.width > 1680 ? '120px'
                : windowSize.width > 1600 ? '100px'
                  : windowSize.width > 1440 ? '100px'
                    : windowSize.width > 1280 ? '95px'
                      : windowSize.width > 1024 ? '95px'
                        : '90px',
      right: 'boolean',
      cell: (row) => (
        <>
          <span id='media-query-rows'>{numberWithCommasColumns(row.VRED_POR)}</span>
        </>
      ),
    },
  ];

  // fokus pri renderu
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      inputRef.current.focus();
    }, 100);
    return () => {
      clearTimeout(timeout);
    };
  }, []);



  return (
    // kontejner(napravljen u css) u kome se nalazi naslov i ikonice(za cuvanje, brisanje, stampu itd.)
    <div>
      <div className="title-container">
        <header className="title-header">
          <h1 id="media-query-title">Faktura</h1>
        </header>

        <div className="naslov-ikonice">
          <ul className="nav flex-column flex-lg-row justify-content-end">
            <li className="nav-item toolbaritem">
              <input
                type="image"
                alt="save"
                className="dugme-sacuvaj"
                title="Sačuvaj"
                tabIndex="20"
                src={require("../../assets/images/floppydisk.png")}
                onFocus={() => onSubmit()}
              />
            </li>

            <li className="nav-item toolbaritem">
              <input
                type="image"
                alt="print"
                className="dugme-stampa"
                title="Štampa"
                tabIndex="21"
                src={require("../../assets/images/printer.png")}
                onClick={onPrint}
              />
            </li>

            <li className="nav-item toolbaritem">
              <div
                onClick={
                  form.BRDOK && form.BRDOK !== "" && form.BRDOK !== 0
                    ? setModalOpen
                    : setModalErrorDeleteDocumentOpen
                }
                tabIndex="22"
              >
                <SVG
                  src={deleteDocument}
                  style={{ margin: "1px" }}
                  title="Brisanje dokumenta"
                  className="dugme-brisanje"
                />
              </div>
            </li>

            <li className="nav-item toolbaritem">
              <input
                type="image"
                alt="delete item"
                className="dugme-stampa"
                title="Brisanje stavke"
                tabIndex="23"
                src={require("../../assets/images/trash1.png")}
                onClick={
                  form.RBR &&
                    form.RBR !== "" &&
                    form.RBR !== 0 &&
                    form.RBR !== firstRbrNumber
                    ? setModalDeleteItem
                    : setModalErrorDeleteItemOpen
                }
              />
            </li>
          </ul>
        </div>
      </div>
      {/* // kontejner pune sirine */}
      <Container fluid>
        <Row>
          <div className="products-form">
            <Row>
              <div
                className="col-12"
                style={{
                  display: "flex",
                  paddingRight: "0px",
                  paddingLeft: "-1px",
                  marginRight: "auto",
                  marginLeft: "0px",
                }}
              >
                <div className="col-4">
                  <div
                    className={
                      errorInputRepository ? "inputWithError" : "field-container"
                    }
                    style={{ marginRight: "3px", marginBottom: "0px" }}
                  >
                    <label id="media-query-label">Skladište:</label>
                    <Autocomplete
                      //ukoliko nema ove funkcije, u konzoli izbacuje upozorenje da nije vrijednost jedinstvena
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      {...defaultProps}
                      value={repository}
                      disablePortal={true}
                      onFocus={() => {
                        unosIspravanDo("SKLAID");
                      }}
                      // ako se promijeni skladiste, uzima podatke za nov izabran0 skladiste
                      onChange={(event, newValue) => {
                        setForm((prev) => ({
                          ...prev,
                          SKLAID: newValue && newValue != "" ? newValue.ID : "", VRSTA_SKLADISTA: newValue && newValue != "" ? newValue.VRSTA_SKLADISTA : "",
                        }));
                        setRepository(newValue);
                        // fokus se mora vratiti inace tabIndex ne radi dobro
                        repositoryRef.current.focus();
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputRef={inputRef}
                          className="clients-textinput"
                          maxLength="295"
                          name="SKLAID"
                          tabIndex="1"
                          ref={repositoryRef}
                          //    onBlur={(e) => formCheck(e)}
                          placeholder=" Izaberite skladište"
                          variant="standard"
                        />
                      )}
                    />
                    {errorMsgRepository ? (
                      <span
                        style={{
                          width: "100%",
                          color: "red",
                          fontSize: "11px",
                          fontFamily: "cursive",
                        }}
                      >
                        {errorMsgRepository}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="col-1">
                  <div className="col-form-right">
                    <div
                      className={
                        errorInputYear ? "inputWithError" : "field-container"
                      }
                      style={{ marginRight: "3px" }}
                    >
                      <label id="media-query-label">Godina:</label>
                      {/* komponenta za formatiranje brojeva */}
                      <NumericFormat
                        type="text"
                        className='field-alignment p-1'
                        getInputRef={yearRef}
                        id="media-query-input-text"
                        tabIndex="2"
                        maxLength="4"
                        // broj decimala
                        decimalScale={0}
                        autoComplete="off"
                        placeholder="godina"
                        name="GODINA"
                        onFocus={() => {
                          unosIspravanDo("GODINA")
                          setForm(prev => ({ ...prev, GODINA: year }))
                        }}
                        value={form ? form.GODINA : ''}
                        onChange={(e) => handleChange(e)}
                      //   onBlur={(e) => formCheck(e)}
                      />
                      {errorMsgYear ? (
                        <span
                          style={{
                            width: "100%",
                            color: "red",
                            fontSize: "11px",
                            fontFamily: "cursive",
                          }}
                        >
                          {errorMsgYear}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-1">
                  <div className="col-form-right">
                    <div
                      className={
                        errorInputDocument ? "inputWithError" : "field-container"
                      }
                      style={{ marginRight: "3px" }}
                    >
                      <label id="media-query-label">Dok.:</label>
                      {/* komponenta za formatiranje brojeva */}
                      <NumericFormat
                        type="text"
                        className='field-alignment p-1'
                        getInputRef={documentRef}
                        id="media-query-input-text"
                        tabIndex="3"
                        maxLength="9"
                        // broj decimala
                        decimalScale={0}
                        onFocus={() => {
                          unosIspravanDo("BRDOK");
                          getInvoiceFirstFreeNumber();
                        }}
                        placeholder="0"
                        name="BRDOK"
                        value={form?.BRDOK}
                        onChange={(e) => handleChange(e)}
                        //    onBlur={(e) => formCheck(e)}
                        onClick={() => {
                          // ako je odradjen dvoklik na stavku u mrezi, a zatim klik na dokument, cisti polja
                          setUpdateDocument(false);
                          setItemUpdate(false)
                          form.stavkaPronadjena = false;
                          setForm((prev) => ({
                            ...prev,
                            DATUM: "",
                            VALUTA: "",
                            KOMTID: "",
                            NAPOMENA: "",
                            POR_U_CENI: "",
                            OTPREM: "",
                            OTPIZV: "",
                            RBR: "",
                            USLUGA: "",
                            OPIS: "",
                            KONTO: "",
                            JM: "",
                            KOL: "",
                            CENA: "",
                            POREZID: "",
                            POREZ: "",
                            VRED: "",
                            VRED_POR: "",
                          }));
                          setClients(null);
                          setService(null);
                          setTax(null);
                        }}
                      />
                      {errorMsgDocument ? (
                        <span
                          style={{
                            width: "100%",
                            color: "red",
                            fontSize: "11px",
                            fontFamily: "cursive",
                          }}
                        >
                          {errorMsgDocument}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-1">
                  <div
                    className={errorDate ? "inputWithError" : "field-container"}
                    style={{ marginRight: "3px" }}
                  >
                    <label id="media-query-label">Datum:</label>
                    <input
                      type="text"
                      id="media-query-input-text"
                      tabIndex="4"
                      ref={dateRef}
                      onFocus={() => {
                        unosIspravanDo("DATUM");
                        if (
                          form.BRDOK === firstDockumentNumber ||
                          form.BRDOK > firstDockumentNumber
                        ) {
                          setUpdateDocument(false);
                          setItemUpdate(false)
                          setClients(null);
                          setInvoices(null);
                          setForm((prev) => ({
                            ...prev,
                            DATUM: moment
                              .unix(new Date(startDate).getTime() / 1000)
                              .format("DD.MM.YYYY"),
                            VALUTA: moment
                              .unix(new Date(currencyDate).getTime() / 1000)
                              .format("DD.MM.YYYY"),
                          }));
                        }
                        checkDocument();
                      }}
                      placeholder="datum"
                      name="DATUM"
                      value={form?.DATUM}
                      onChange={(e) => handleChange(e)}
                    //   onBlur={(e) => formCheck(e)}
                    />
                    {errorMsgDate ? (
                      <span
                        style={{
                          width: "100%",
                          color: "red",
                          fontSize: "11px",
                          fontFamily: "cursive",
                        }}
                      >
                        {errorMsgDate}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="col-1">
                  <div
                    className={errorCurrency ? "inputWithError" : "field-container"}
                    style={{ marginRight: "3px" }}
                  >
                    <label id="media-query-label">Valuta:</label>
                    <input
                      type="text"
                      id="media-query-input-text"
                      tabIndex="5"
                      ref={currencyRef}
                      onFocus={() => {
                        unosIspravanDo("VALUTA");
                      }}
                      placeholder="valuta"
                      name="VALUTA"
                      value={form?.VALUTA}
                      onChange={(e) => handleChange(e)}
                    //   onBlur={(e) => formCheck(e)}
                    />
                    {errorMsgCurrency ? (
                      <span
                        style={{
                          width: "100%",
                          color: "red",
                          fontSize: "11px",
                          fontFamily: "cursive",
                        }}
                      >
                        {errorMsgCurrency}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="col-4">
                  <div
                    className={
                      errorInputclients ? "inputWithError" : "field-container"
                    }
                    style={{ marginRight: "6px" }}
                  >
                    <label id="media-query-label">Komitent:</label>
                    <Autocomplete
                      //ukoliko nema ove funkcije, u konzoli izbacuje upozorenje da nije vrijednost jedinstvena
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      {...defaultPropsClients}
                      value={clients}
                      onFocus={() => {
                        unosIspravanDo("KOMTID");
                      }}
                      disablePortal={true}
                      // ako se promijeni komitent, uzima podatke za novog izabranog komitenta
                      onChange={(event, newValue) => {
                        setForm((prev) => ({
                          ...prev,
                          KOMTID:
                            newValue && newValue != null ? newValue.ID : "",
                        }));
                        setClients(newValue);
                        // fokus se mora vratiti inace tabIndex ne radi dobro
                        clientsRef.current.focus();
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          tabIndex="6"
                          name="KOMT"
                          ref={clientsRef}
                          className="clients-textinput"
                          placeholder=" Izaberite komitenta"
                          variant="standard"
                        //    onBlur={(e) => formCheck(e)}
                        />
                      )}
                    />
                    {errorMsgClients ? (
                      <span
                        style={{
                          width: "100%",
                          color: "red",
                          fontSize: "11px",
                          fontFamily: "cursive",
                        }}
                      >
                        {errorMsgClients}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </Row>
            <Row>
              <div
                className="col-12"
                style={{
                  display: "flex",
                  paddingRight: "0px",
                  paddingLeft: "-1px",
                  marginRight: "auto",
                  marginLeft: "0px",
                }}
              >
                <div className="col-5">
                  <div
                    className="field-container"
                    style={{ marginRight: "3px" }}
                  >
                    <label id="media-query-label">Napomena:</label>
                    <input
                      type="text"
                      id="media-query-input-text"
                      maxLength="300"
                      tabIndex="7"
                      ref={noteRef}
                      placeholder="napomena"
                      name="NAPOMENA"
                      value={form ? form.NAPOMENA : ""}
                      onFocus={() => {
                        unosIspravanDo("NAPOMENA");
                      }}
                      onChange={(e) => handleChange(e)}
                    //    onBlur={(e) => formCheck(e)}
                    />
                  </div>
                </div>

                <div className="col-1">
                  <div
                    className="field-container"
                    style={{ marginRight: "3px" }}
                  >
                    <label id="media-query-label">
                      P.u cijeni:
                    </label>
                    <input type="text"
                      id="media-query-input-text"
                      value={form?.POR_U_CENI}
                      name="POR_U_CENI"
                      ref={taxInPriceRef}
                      placeholder="porez uračunat u cijenu"
                      onChange={(e) => handleChange(e)}
                      tabIndex="8"
                      onFocus={() => {
                        unosIspravanDo("POR_U_CENI");
                        if (form.VRSTA_SKLADISTA == 'V') {
                          setForm((prev) => ({
                            ...prev,
                            POR_U_CENI: "N",
                          }));
                        }
                        if (form.VRSTA_SKLADISTA == 'M') {
                          setForm((prev) => ({
                            ...prev,
                            POR_U_CENI: "D",
                          }));
                        }
                      }}
                    />
                  </div>
                </div>

                <div className="col-3">
                  <div
                    className="field-container"
                    style={{ marginRight: "3px" }}
                  >
                    <label id="media-query-label">Otpremnica:</label>
                    <input
                      type="text"
                      id="media-query-input-text"
                      maxLength="10"
                      tabIndex="9"
                      ref={dispatchNoteRef}
                      placeholder="otpremnica"
                      name="OTPREM"
                      value={form ? form.OTPREM : ""}
                      onChange={(e) => handleChange(e)}
                      onFocus={() => {
                        unosIspravanDo("OTPREM");
                      }}
                    //   onBlur={(e) => formCheck(e)}
                    />
                  </div>
                </div>

                <div className="col-3">
                  <div
                    className="field-container"
                    style={{ marginRight: "6px" }}
                  >
                    <label id="media-query-label">Ugovor:</label>
                    <input
                      type="text"
                      id="media-query-input-text"
                      maxLength="20"
                      tabIndex="10"
                      ref={contractRef}
                      placeholder="ugovor"
                      name="OTPIZV"
                      value={form ? form.OTPIZV : ""}
                      onChange={(e) => handleChange(e)}
                      onFocus={() => {
                        unosIspravanDo("OTPIZV");
                      }}
                    //   onBlur={(e) => formCheck(e)}
                    />
                  </div>
                </div>
              </div>
            </Row>
            <Row>
              <div
                className="col-12 "
                style={{
                  display: "flex",
                  paddingRight: "0px",
                  paddingLeft: "-1px",
                  marginRight: "auto",
                  marginLeft: "0px",
                }}
              >
                <div className="col-1">
                  <div className="col-form-right">
                    <div
                      className={errorInputRbr ? "inputWithError" : "field-container"}
                      style={{ marginRight: "3px" }}
                    >
                      <label id="media-query-label">Rbr.:</label>
                      {/* komponenta za formatiranje brojeva */}
                      <NumericFormat
                        type="text"
                        className='field-alignment p-1'
                        getInputRef={rbrRef}
                        id="media-query-input-text"
                        maxLength="3"
                        // broj decimala
                        decimalScale={0}
                        onFocus={() => {
                          unosIspravanDo("RBR");
                          getInvoiceFirstFreeNumberRbr();
                        }}
                        tabIndex="11"
                        placeholder="0"
                        name="RBR"
                        value={form ? form.RBR : ""}
                        onChange={(e) => handleChange(e)}
                        //    onBlur={(e) => formCheck(e)}
                        onClick={() => {
                          // ako je odradjen dvoklik na stavku u mrezi, cisti polja
                          form.stavkaPronadjena = false;
                          setForm((prev) => ({
                            ...prev,
                            USLUGA: "",
                            OPIS: "",
                            KONTO: "",
                            JM: "",
                            KOL: "",
                            CENA: "",
                            POREZID: "",
                            PROCENAT: "",
                            POREZ: "",
                            VRED: "",
                            VRED_POR: "",
                          }));
                          setService(null);
                          setTax(null);
                        }}
                      />
                      {errorMsgRbr ? (
                        <span
                          style={{
                            width: "100%",
                            color: "red",
                            fontSize: "11px",
                            fontFamily: "cursive",
                          }}
                        >
                          {errorMsgRbr}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-2">
                  <div
                    className={
                      errorInputService ? "inputWithError" : "field-container"
                    }
                    style={{ marginRight: "3px" }}
                  >
                    <label id="media-query-label">Usluga:</label>
                    <Autocomplete
                      // svaki od ponudjenih artikala mora biti jedinstven(svaka stavka(child) u objektu artikal mora imati jedinstven kljuc)
                      renderOption={(props, service) => {
                        return (
                          <li {...props} key={service.SIFRA}>
                            <span>{service.SIFRA}</span>
                            <span className="ps-1">{service.NAZIV}</span>
                          </li>
                        );
                      }}
                      //ukoliko nema ove funkcije, u konzoli izbacuje upozorenje da nije vrijednost jedinstvena
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      {...defaultPropsService}
                      value={service}
                      disablePortal={true}
                      onFocus={() => {
                        unosIspravanDo("USLUGA");
                        // uslov za citanje sloga po rednom broju
                        if (unosIspravanDo("USLUGA")) {
                          let selectedItem = []
                          selectedItem = invoices.filter((c) => c.RBR == form.RBR)
                          if (selectedItem.length > 0) {
                            onEdit(selectedItem[0])
                          }
                        }
                      }}
                      // ako se promijeni usluga, uzima podatke za novu izabranu uslugu
                      onChange={(event, newValue) => {
                        setForm((prev) => ({
                          ...prev,
                          USLUGA:
                            newValue && newValue != null ? newValue.SIFRA : "",
                          OPIS:
                            newValue && newValue != null ? newValue.NAZIV : "",
                        }));
                        setService(newValue);
                        // fokus se mora vratiti inace tabIndex ne radi dobro
                        serviceRef.current.focus();
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          tabIndex="12"
                          ref={serviceRef}
                          name="USLUGA"
                          className="clients-textinput"
                          placeholder=" Izaberite uslugu"
                          variant="standard"
                        // onFocus={() => {
                        //   // fokus se mora vratiti inace tabIndex ne radi dobro
                        //   serviceRef.current.focus();
                        // }}
                        //    onBlur={(e) => formCheck(e)}
                        />
                      )}
                    />
                    {errorMsgService ? (
                      <span
                        style={{
                          width: "100%",
                          color: "red",
                          fontSize: "11px",
                          fontFamily: "cursive",
                        }}
                      >
                        {errorMsgService}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="col-3">
                  <div
                    className={
                      errorInputDescription ? "inputWithError" : "field-container"
                    }
                    style={{ marginRight: "3px" }}
                  >
                    <label id="media-query-label">Opis:</label>
                    <input
                      type="text"
                      id="media-query-input-text"
                      maxLength="200"
                      ref={descriptionRef}
                      tabIndex="13"
                      placeholder="opis"
                      name="OPIS"
                      onFocus={() => {
                        unosIspravanDo("OPIS");
                        if (form.stavkaPronadjena == false) {
                          getReadService();
                        }
                      }}
                      value={form ? form.OPIS : ""}
                      onChange={(e) => handleChange(e)}
                    //    onBlur={(e) => formCheck(e)}
                    />
                    {errorMsgDescription ? (
                      <span
                        style={{
                          width: "100%",
                          color: "red",
                          fontSize: "11px",
                          fontFamily: "cursive",
                        }}
                      >
                        {errorMsgClients}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="col-1">
                  <div
                    className="field-container"
                    style={{ marginRight: "3px" }}
                  >
                    <label id="media-query-label">Konto:</label>
                    <input
                      type="text"
                      id="media-query-input-text"
                      maxLength="8"
                      tabIndex="14"
                      placeholder="konto"
                      name="KONTO"
                      onFocus={() => {
                        unosIspravanDo("KONTO");
                      }}
                      value={form ? form.KONTO : ""}
                      onChange={(e) => handleChange(e)}
                    //   onBlur={(e) => formCheck(e)}
                    />
                  </div>
                </div>

                <div className="col-1">
                  <div
                    className="field-container"
                    style={{ marginRight: "3px" }}
                  >
                    <label id="media-query-label">JM:</label>
                    <input
                      type="text"
                      id="media-query-input-text"
                      maxLength="3"
                      tabIndex="15"
                      placeholder="jm"
                      name="JM"
                      onFocus={() => {
                        unosIspravanDo("JM");
                      }}
                      value={form ? form.JM : ""}
                      onChange={(e) => handleChange(e)}
                    //    onBlur={(e) => formCheck(e)}
                    />
                  </div>
                </div>

                <div className="col-1">
                  <div className="col-form-right">
                    <div
                      className={
                        errorInputQuantity ? "inputWithError" : "field-container"
                      }
                      style={{ marginRight: "3px" }}
                    >
                      <label id="media-query-label">Količina:</label>
                      {/* komponenta za formatiranje brojeva */}
                      <NumericFormat
                        type="text"
                        className='field-alignment p-1'
                        customInput={itemUpdate?.TextField}
                        getInputRef={quantityRef}
                        id="media-query-input-text"
                        maxLength="16"
                        // broj decimala
                        decimalScale={3}
                        tabIndex="16"
                        placeholder="0"
                        name="KOL"
                        onFocus={() => {
                          unosIspravanDo("KOL");
                        }}
                        value={form && itemUpdate ? numberWithCommasColumns(form.KOL) : form.KOL}
                        onChange={(e) => handleChange(e)}
                      //   onBlur={(e) => formCheck(e)}
                      />
                      {errorMsgQuantity ? (
                        <span
                          style={{
                            width: "100%",
                            color: "red",
                            fontSize: "11px",
                            fontFamily: "cursive",
                          }}
                        >
                          {errorMsgQuantity}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-1">
                  <div className="col-form-right">
                    <div
                      className={
                        errorInputPrice ? "inputWithError" : "field-container"
                      }
                      style={{ marginRight: "3px" }}
                    >
                      <label id="media-query-label">Cijena:</label>
                      {/* komponenta za formatiranje brojeva */}
                      <NumericFormat
                        type="text"
                        customInput={itemUpdate?.TextField}
                        getInputRef={priceRef}
                        className='field-alignment p-1'
                        id="media-query-input-text"
                        tabIndex="17"
                        maxLength="16"
                        // broj decimala
                        decimalScale={3}
                        placeholder="0"
                        name="CENA"
                        value={form && itemUpdate ? roundTO(form.CENA, 2) : form.CENA}
                        onFocus={() => {
                          unosIspravanDo("CENA");
                        }}
                        onChange={(e) => handleChange(e)}
                      //  onBlur={() => staraCijena()}
                      />
                      {errorMsgPrice ? (
                        <span
                          style={{
                            width: "100%",
                            color: "red",
                            fontSize: "11px",
                            fontFamily: "cursive",
                          }}
                        >
                          {errorMsgPrice}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-1">
                  <div
                    className={errorInputTax ? "inputWithError" : "field-container"}
                    style={{ marginRight: "3px" }}
                  >
                    <label id="media-query-label">Porez:</label>
                    <Autocomplete
                      //ukoliko nema ove funkcije, u konzoli izbacuje upozorenje da nije vrijednost jedinstvena
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      {...defaultPropsTax}
                      inputref={taxRef}
                      value={tax}
                      disablePortal={true}
                      onFocus={() => {
                        unosIspravanDo("POREZ");
                      }}
                      // ako se promijeni porez, uzima podatke za novi izabrani porez
                      onChange={(event, newValue) => {
                        setTax(newValue);
                        setForm((prev) => ({
                          ...prev,
                          POREZID:
                            newValue && newValue != null ? newValue.ID : "",
                          POREZ:
                            newValue && newValue != null && newValue.PROCENAT
                              ? newValue.PROCENAT
                              : "",
                        }));
                        // fokus se mora vratiti inace tabIndex ne radi dobro
                        taxRef.current.focus();
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="clients-textinput"
                          name="POREZ"
                          ref={taxRef}
                          tabIndex={"18"}
                          //    onBlur={(e) => formCheck(e)}
                          placeholder="Porez"
                          variant="standard"
                        />
                      )}
                    />
                    {errorMsgTax ? (
                      <span
                        style={{
                          width: "100%",
                          color: "red",
                          fontSize: "11px",
                          fontFamily: "cursive",
                        }}
                      >
                        {errorMsgTax}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="col-1">
                  <div className="col-form-right">
                    <div
                      className={
                        errorInputValue ? "inputWithError" : "field-container"
                      }
                      style={{ marginRight: "3px" }}
                    >
                      <label
                        style={{ marginLeft: "0px" }}
                        id="media-query-label"
                      >
                        Vrijednost:
                      </label>
                      {/* komponenta za formatiranje brojeva */}
                      <NumericFormat
                        type="text"
                        customInput={itemUpdate?.TextField}
                        getInputRef={totalRef}
                        className='field-alignment p-1'
                        id="media-query-input-text"
                        tabIndex="19"
                        maxLength="16"
                        // broj decimala
                        decimalScale={3}
                        placeholder="0"
                        name="VRED"
                        value={form && itemUpdate ? numberWithCommasColumns(form.VRED) : numberWithCommasColumns(form.VRED)}
                        onChange={(e) => handleChange(e)}
                        onFocus={() => {
                          unosIspravanDo("VRED");
                        }}
                      //  onBlur={() => staraVrijednost()}
                      />
                      {errorMsgValue ? (
                        <span
                          style={{
                            width: "100%",
                            color: "red",
                            fontSize: "11px",
                            fontFamily: "cursive",
                          }}
                        >
                          {errorMsgValue}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </div>
        </Row>
      </Container>

      {/* render za mrezu  */}
      {
        <DataTable
          columns={columns}
          data={invoices ? invoices : []}
          responsive
          striped
          dense
          noDataComponent
          pointerOnHover
          persistTableHead
          onRowDoubleClicked={(row, event) => {
            onEdit(row);
          }}
          progressPending={pending}
          paginationPerPage={[
            windowSize.width > 1920
              ? 28
              : windowSize.width > 1680
                ? 16
                : windowSize.width > 1600
                  ? 14
                  : 10,
          ]}
        />
      }

      {/* kontejner za sume */}
      {/* <div className="title-container">
                <footer className="title-header">
                    <div></div>
                </footer> */}
      <div className="naslov-ikonice">
        <ul className="nav flex-column flex-lg-row justify-content-end">
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              position: "fixed",
              bottom: "0",
              flex: "0 0 auto",
            }}
          >
            <li
              className="nav-item toolbaritem"
              style={{ width: "23%", marginRight: "5px" }}
            >
              <div className="col-form-right">
                <div className="field-container-number-totals">
                  <label id="media-query-label-sum">Saldo bez PDV-a</label>
                  <input
                    className="form-control"
                    readOnly={true}
                    tabIndex="-1"
                    type="text"
                    id="media-query-input-text"
                    value={
                      sumValue && sumValue != "NaN"
                        ? numberWithCommas(sumValue)
                        : 0
                    }
                  />
                </div>
              </div>
            </li>

            <li
              className="nav-item toolbaritem"
              style={{ width: "23%", marginRight: "4px" }}
            >
              <div className="col-form-right">
                <div className="field-container-number-totals">
                  <label id="media-query-label-sum">PDV</label>
                  <input
                    className="form-control"
                    readOnly={true}
                    type="text"
                    tabIndex="-1"
                    id="media-query-input-text"
                    value={
                      sumTax && sumTax != "NaN" ? numberWithCommas(sumTax) : 0
                    }
                  />
                </div>
              </div>
            </li>

            <li
              className="nav-item toolbaritem"
              style={{ width: "23%", marginRight: "7px" }}
            >
              <div className="col-form-right">
                <div className="field-container-number-totals">
                  <label id="media-query-label-sum">Ukupno</label>
                  <input
                    type="text"
                    className="total"
                    readOnly={true}
                    id="media-query-input-text"
                    tabIndex="-1"
                    value={
                      sumTotal && sumTotal != "NaN"
                        ? numberWithCommas(sumTotal)
                        : 0
                    }
                  />
                </div>
              </div>
            </li>
          </div>
        </ul>
      </div>

      {/* </div> */}

      {/* render za poruku */}
      <div>
        <Modal isOpen={Boolean(modalErrorAuthorizationData)} centered>
          <ModalHeader>Upozorenje!</ModalHeader>
          <ModalBody>
            <span>{`Nemate ovlašćenje za brisanje!`}</span>
          </ModalBody>
          <ModalFooter style={{ justifyContent: "center" }}>
            <div>
              <button
                onClick={() => setModalErrorAuthorizationOpen(false)}
                className="button-yes"
              >
                Ok
              </button>
            </div>
          </ModalFooter>
        </Modal>
      </div>

      {/* render za poruku */}
      <div>
        <Modal
          isOpen={Boolean(modalOpen)}
          //  modalTransition={{ timeout: 2000 }}
          centered
        >
          <ModalHeader>Brisanje dokumenta</ModalHeader>
          <ModalBody>
            <span>{`Da li želite da obrišete dokument broj ${form.BRDOK} ? `}</span>
          </ModalBody>
          <ModalFooter>
            <div>
              <button onClick={() => setModalOpen(false)} className="button-no">
                Ne
              </button>
              <button
                onClick={() => onDelete(form.SKLAID, form.GODINA, form.BRDOK)}
                className="button-yes"
              >
                Da
              </button>
            </div>
          </ModalFooter>
        </Modal>
      </div>

      {/* render za poruku */}
      <div>
        <Modal isOpen={Boolean(modalDeleteItem)} centered>
          <ModalHeader>Brisanje stavke</ModalHeader>
          <ModalBody>
            <span>{`Da li želite da obrišete stavku broj ${form.RBR} ? `}</span>
          </ModalBody>
          <ModalFooter>
            <div>
              <button
                onClick={() => setModalDeleteItem(false)}
                className="button-no"
              >
                Ne
              </button>
              <button
                onClick={() =>
                  onDeleteItems(form.SKLAID, form.GODINA, form.BRDOK, form.RBR)
                }
                className="button-yes"
              >
                Da
              </button>
            </div>
          </ModalFooter>
        </Modal>
      </div>

      {/* render za poruku */}
      <div>
        <Modal isOpen={Boolean(modalErrorOpen)} centered>
          <ModalHeader>Neispravan unos!</ModalHeader>
          <ModalBody>
            <span>{`Unesite validan dokument!`}</span>
          </ModalBody>
          <ModalFooter style={{ justifyContent: "center" }}>
            <div>
              <button
                onClick={() => {
                  setModalErrorOpen(false);
                }}
                className="button-yes"
              >
                Ok
              </button>
            </div>
          </ModalFooter>
        </Modal>
      </div>

      {/* render za poruku */}
      <div>
        <Modal isOpen={Boolean(modalErrorDeleteDocumentOpen)} centered>
          <ModalHeader>Neispravan unos!</ModalHeader>
          <ModalBody>
            <span>{`Morate odabrati dokument za brisanje!`}</span>
          </ModalBody>
          <ModalFooter style={{ justifyContent: "center" }}>
            <div>
              <button
                onClick={() => setModalErrorDeleteDocumentOpen(false)}
                className="button-yes"
              >
                Ok
              </button>
            </div>
          </ModalFooter>
        </Modal>
      </div>

      {/* render za poruku */}
      <div>
        <Modal isOpen={Boolean(modalErrorDeleteItemOpen)} centered>
          <ModalHeader>Neispravan unos!</ModalHeader>
          <ModalBody>
            <span>{`Morate odabrati stavku za brisanje!`}</span>
          </ModalBody>
          <ModalFooter style={{ justifyContent: "center" }}>
            <div>
              <button
                onClick={() => setModalErrorDeleteItemOpen(false)}
                className="button-yes"
              >
                Ok
              </button>
            </div>
          </ModalFooter>
        </Modal>
      </div>

      {/* render za poruku */}
      <div>
        <Modal isOpen={Boolean(modalErrorOpenData)} centered>
          <ModalHeader>Nema podataka!</ModalHeader>
          <ModalFooter style={{ justifyContent: "center" }}>
            <div>
              {/* Dugme za zatvaranje modalnog forma */}
              <button
                onClick={() => setModalErrorOpenData(false)}
                className="button-yes"
              >
                Ok
              </button>
            </div>
          </ModalFooter>
        </Modal>
      </div>
      {/* funkcija za spiner progres bar */}
      <div>
        {spinner ? (
          <Spinner className="spinner" color="primary" id="loader" />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default UnosFakture;
