import React from 'react';
import * as RiIcons from 'react-icons/ri';
import SVG from 'react-inlinesvg';
import homeIcon from '../assets/home.svg';
import listIcon from '../assets/list.svg';
import bookIcon from '../assets/book.svg';
import cubeIcon from '../assets/cube.svg';
import calculatorIcon from '../assets/calculator.svg';
import paperIcon from '../assets/paper.svg';
import settingsIcon from '../assets/settings.svg';
import supportIcon from '../assets/support.svg';
import pencilIcon from '../assets/pencil1.svg';
import { ovlascenje, ovlfik, ovlrobno, ovlfakt, ovlblagajna, robno, finansije, fakturisanje, blagajna, kufkif } from '../components/utilities';

//const [visible, setVisible] = useState(false)
export const SidebarData = [
  {
    title: 'Početna',
    path: '/',
    icon: <SVG src={homeIcon} />,
    visible: true,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    title: "Robno",
    path: "/",
    icon: <SVG src={bookIcon} />,
    visible: ovlrobno != 1 && robno === 'D',
    cName: "nav-text",

    subNav: [
      {
        title: "Registri",
        path: "/",
        icon: <SVG src={listIcon} />,
        visible: ovlascenje != 2 && ovlrobno != 2,
        cName: "nav-text",
        subSubNav: [
          {
            title: "Komitent",
            path: "/komitenti",
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: "nav-text",
          },
          {
            title: "Država",
            path: "/drzave",
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: "nav-text",
          },
          {
            title: "Regija",
            path: "/regije",
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: "nav-text",
          },
          {
            title: "Mjesta",
            path: "/mjesta",
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: "nav-text",
          },
          {
            title: "Putnik",
            path: "/putnici",
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: "nav-text",
          },
          {
            title: "Poslovna jedinica",
            path: "/poslovna",
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: "nav-text",
          },
          {
            title: "Artikal",
            path: "/artikal",
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: "nav-text",
          },
          {
            title: "Opis artikla",
            path: "/opza",
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: "nav-text",
          }, 
          {
            title: "Grupa",
            path: "/grupa",
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: "nav-text",
          },
          {
            title: "Podgrupa",
            path: "/podgrupa",
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: "nav-text",
          },
          {
            title: "Brend",
            path: "/brend",
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: "nav-text",
          },
          {
            title: "Porez",
            path: "/porezi",
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: "nav-text",
          },
          {
            title: "Ambalaža",
            path: "/ambalaza",
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: "nav-text",
          },
          {
            title: "Trošak",
            path: "/trosak",
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: "nav-text",
          },
          {
            title: "Napomena",
            path: "/napomene",
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: "nav-text",
          },
          {
            title: "Smjena",
            path: "/smjena",
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: "nav-text",
          },
          {
            title: "Vozila",
            path: "/vozila",
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: "nav-text",
          },
          {
            title: "Valuta",
            path: "/valuta",
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: "nav-text",
          },
          {
            title: "Skladište",
            path: "/skladista",
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: "nav-text",
          },
          {
            title: "Preduzeće",
            path: "/preduzeca",
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: "nav-text",
          },
        ],
      },
      {
        title: "Promet",
        path: "/",
        icon: <SVG src={listIcon} />,
        visible: ovlascenje != 2 && ovlrobno != 2 && ovlrobno != 3,
        cName: "nav-text",
        subSubNav: [
          {
            title: "Predračun",
            path: "/predracun",
            icon: <SVG src={listIcon} />,
            visible: true,
            cName: "nav-text",
          },
          {
            title: "Ulaz - kalkulacije",
            path: "/ulaz",
            icon: <SVG src={listIcon} />,
            visible: true,
            cName: "nav-text",
          },
          {
            title: "Izlaz - fakturisanje",
            path: "/izlaz",
            icon: <SVG src={listIcon} />,
            visible: true,
            cName: "nav-text",
          },
          {
            title: "Nivelacija",
            path: "/nivelacija",
            icon: <SVG src={listIcon} />,
            visible: true,
            cName: "nav-text",
          },
        ],
      },
      {
        title: "Kasa",
        path: "/",
        icon: <SVG src={listIcon} />,
        visible: true,
        cName: "nav-text",
        subSubNav: [
          {
            title: "Kasa - ulaz",
            path: "/kasa",
            icon: <SVG src={listIcon} />,
            visible: true,
            cName: "nav-text",
          },
        ],
      },
      {
        title: "Izvještaji",
        path: "/",
        icon: <SVG src={listIcon} />,
        visible: true,
        cName: "nav-text",
        subSubNav: [
          {
            title: "Kalkulacija",
            path: "/kalkulacija",
            icon: <SVG src={listIcon} />,
            visible: true,
            cName: "nav-text",
          },
        ],
      },
    ],
  },
  {
    title: 'Fakturisanje',
    path: '/',
    icon: <SVG src={bookIcon} />,
    visible: ovlfakt != 1 && fakturisanje === 'D',
    cName: 'nav-text',
    subNav: [
      {
        title: 'Registri',
        path: '/',
        icon: <SVG src={pencilIcon} />,
        visible: ovlascenje != 2 && ovlfakt != 2,
        cName: 'nav-text',
        subSubNav: [
          {
            title: 'Država',
            path: '/drzave',
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: 'nav-text'
          },
          {
            title: 'Regija',
            path: '/regije',
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: 'nav-text'
          },
          {
            title: 'Komitenata',
            path: '/komitenti',
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: 'nav-text'
          },
          {
            title: 'Preduzeća',
            path: '/preduzeca',
            visible: true,
            //  visible: ovlfakt != 2,
            icon: <SVG src={listIcon} />,
            cName: 'nav-text'
          },
          {
            title: 'Usluga',
            path: '/usluge',
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: 'nav-text'
          },
          {
            title: 'Mjesta',
            path: '/mjesta',
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: 'nav-text'
          },
          // {
          //   title: 'Poslovna jedinica',
          //   path: '/poslovna',
          //   icon: <SVG src={listIcon} />,
          //   cName: 'nav-text'
          // },
          {
            title: 'Putnika',
            path: '/putnici',
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: 'nav-text',
          },
          {
            title: 'Poreza',
            path: '/porezi',
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: 'nav-text'
          },
          {
            title: 'Napomena',
            path: '/napomene',
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: 'nav-text'
          },
          {
            title: 'Skladišta',
            path: '/skladista',
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: 'nav-text'
          },
          // {
          //   title: 'vrstaDok',
          //   path: '/vrstaDok',
          //   visible: true,
          //   icon: <SVG src={listIcon} />,
          //   cName: 'nav-text'
          // }
        ],
      },
      {
        title: 'Promet',
        path: '/',
        icon: <SVG src={cubeIcon} />,
        visible: ovlascenje != 2 && ovlfakt != 2 && ovlfakt != 3,
        cName: 'nav-text',
        subSubNav: [
          {
            title: 'Faktura',
            path: '/unosFakture',
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: 'nav-text'
          }
        ]
      },
      {
        title: 'Izvještaji',
        path: '/',
        icon: <SVG src={calculatorIcon} />,
        visible: true,
        cName: 'nav-text',
        subSubNav: [
          {
            title: 'Izvještaj',
            path: '/izvjestajiFakturisanje',
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: 'nav-text',
          }
        ]
      },
    ],
  },
  {
    title: 'Finansije',
    path: '/',
    icon: <SVG src={bookIcon} />,
    visible: ovlfik != 1 && finansije === 'D',
    cName: 'nav-text',
    subNav: [
      {
        title: 'Registri',
        path: '/',
        icon: <SVG src={pencilIcon} />,
        visible: ovlascenje != 2 && ovlfik != 2,
        cName: 'nav-text',
        subSubNav: [
          {
            title: 'Država',
            path: '/drzave',
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: 'nav-text'
          },
          {
            title: 'Regija',
            path: '/regije',
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: 'nav-text'
          },
          {
            title: 'Dokumenata',
            path: '/dokument',
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: 'nav-text'
          },
          {
            title: 'Konta',
            path: '/konto',
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: 'nav-text'
          },
          {
            title: 'Komitenata',
            path: '/komitenti',
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: 'nav-text'
          },
          {
            title: 'Preduzeća',
            path: '/preduzeca',
            visible: true,
            //  visible: ovlfakt != 2,
            icon: <SVG src={listIcon} />,
            cName: 'nav-text'
          },
          {
            title: 'Mjesta',
            path: '/mjesta',
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: 'nav-text'
          },
        ],
      },
      {
        title: 'Promet',
        path: '/',
        icon: <SVG src={cubeIcon} />,
        visible: ovlascenje != 2 && ovlfik != 2 && ovlfik != 3,
        cName: 'nav-text',
        subSubNav: [
          {
            title: 'Unos naloga',
            path: '/unosNaloga',
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: 'nav-text'
          }
        ]
      },
      {
        title: 'Izvještaji',
        path: '/',
        icon: <SVG src={calculatorIcon} />,
        visible: true,
        cName: 'nav-text',
        subSubNav: [
          {
            title: 'Izvještaj po br. n.',
            path: '/izvjestajiPoBrojuNaloga',
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: 'nav-text'
          },
          {
            title: 'Prikaz po vrsti k.',
            path: '/prikazPoVrstiKnjizenja',
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: 'nav-text'
          },
          {
            title: 'Analitika kom. i kon.',
            path: '/analitikaPoKomitentimaIKontima',
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: 'nav-text'
          },
          {
            title: 'Analitika i bilans',
            path: '/analitikaIBilans',
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: 'nav-text'
          },
          {
            title: 'Bruto bilans sint.',
            path: '/brutoBilansSintetike',
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: 'nav-text'
          },
        ]
      },
    ]
  },
  // {
  //   title: 'Blagajna',
  //   path: '/',
  //   icon: <SVG src={bookIcon} />,
  //   visible: ovlblagajna != 1,
  //   cName: 'nav-text',
  //   subNav: [
  //     {
  //       title: 'Blagajna',
  //       path: '/blagajna',
  //       icon: <SVG src={listIcon} />,
  //       cName: 'nav-text'
  //     },
  //     {
  //       title: 'Izvještaj',
  //       path: '/blagajnaReport',
  //       icon: <SVG src={listIcon} />,
  //       cName: 'nav-text',
  //     }
  //   ]
  // },
  // {
  //   title: 'Pregled',
  //   path: '/pregledPoVrstiIKomitentu',
  //   icon: <SVG src={settingsIcon} />,
  //   visible: true,
  //   cName: 'nav-text'
  // },
  {
    title: 'Servis',
    path: '/',
    icon: <SVG src={settingsIcon} />,
    visible: true,
    cName: 'nav-text'
  },
  {
    title: 'Podrška',
    path: '/',
    icon: <SVG src={supportIcon} />,
    visible: true,
    cName: 'nav-text'
  },
];
