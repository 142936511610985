import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import React from 'react';

const RequireAuth = ({ allowedRoles }) => {
    const { auth } = useAuth();
    const location = useLocation();
    const token = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : null
    if ((auth && allowedRoles.includes(auth.roles)) || token) {
        return <Outlet />
    } else {
        if (auth && auth.accessToken) {
            return <Navigate to="/unauthorized" state={{ from: location }} replace />
        } else {
            return <Navigate to="/login" state={{ from: location }} replace />
        }
    }


    // return (
    //     auth?.allowedRoles?.includes(auth.roles)
    //         ? <Outlet />
    //         : auth?.accessToken 
    //             ? <Navigate to="/unauthorized" state={{ from: location }} replace />
    //             : <Navigate to="/login" state={{ from: location }} replace />
    // );

    // function isAuthenticated() {
    //     const token = localStorage.getItem('token');
    //     const refreshToken = localStorage.getItem('refreshToken');
    //     try {
    //         decode(token);
    //         const { exp } = decode(refreshToken);
    //         if (Date.now() >= exp * 1000) {
    //             return false;
    //         }
    //     } catch (err) {
    //         return false;
    //     }
    //     return true;
        
    // }
    
}



export default RequireAuth;