// importovanje komponenti
import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { API } from '../../constants';
import exportIcon from '../../assets/export.svg';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import axios from '../../api/axios';
import moment from 'moment';
import { Spinner } from "reactstrap";
import { textAlign } from '@mui/system';
import { set } from 'react-hook-form';
import { First } from 'react-bootstrap/esm/PageItem';
// komponenta koju kreiramo

function Blagajna() {
    // definisanje konstanti koje koristimo za fokusiranje polja kursRef
    const dateRef = useRef();
    const kursRef = useRef();
    const komtRef = useRef();
    const rbrRef = useRef();
    const nalogRef = useRef();
    const valutaRef = useRef();
    const iznosRef = useRef()
    const kontRef = useRef()
    const orgRef = useRef()
    const mjestoRef = useRef()
    const vrstaRef = useRef()
    const blagajnaRef = useRef()
    const racunRef = useRef()



    let date = new Date();
    // definisanje stejtova i prva inicijalizacija; objekat i funkcija za taj objekat
    const [sifrao, setSifrao] = useState(null)
    const [total, setTotal] = useState(0)
    const [perPage, setPerPage] = useState(10);
    const [valuta, setValuta] = useState(null);
    const [kont, setKont] = useState(null);
    const [komt, setKomt] = useState(null);
    const [org, setOrg] = useState(null);
    const [mjesto, setMjesto] = useState(null);
    const [vrsta, setVrsta] = useState(null);
    const [blagajna, setBlagajna] = useState(null);
    const [normDate, setNormDate] = useState(date);
    const [selectData, setSelectData] = useState([]);
    const [selectDataKomt, setSelectDataKomt] = useState([]);
    const [selectDataVr, setSelectDataVr] = useState([]);
    const [selectDataOrg, setSelectDataOrg] = useState([]);
    const [selectDataValuta, setSelectDataValuta] = useState([]);
    const [selectDataKonto, setSelectDataKonto] = useState([]);
    const [selectDataBlagajna, setSelectDataBlagajna] = useState([]);
    const [selectDataMjesto, setSelectDataMjesto] = useState([]);
    const [firstNalogNumber, setFirstNalogNumber] = useState(null)
    const [firstRedNumber, setFirstRedNumber] = useState(null)
    const [searchInput, setSearchInput] = useState([]);
    const [itemUpdate, setItemUpdate] = useState(false)
    const [sumTotal, setSumTotal] = useState(false)
    const [sumDuguje, setSumDuguje] = useState(false)
    const [sumPotraz, setSumPotraz] = useState(false)
    const [initialFormValues, setInitialFormValues] = useState(null);
    const [racun, setRacun] = useState(null);





    // error row inputs
    const [errorInputVrsta, setErrorInputVrsta] = useState(false);
    const [errorInputNalog, setErrorInputNalog] = useState(false);
    const [errorInputDatn, setErrorInputDatn] = useState(false);
    const [errorInputRed, setErrorInputRed] = useState(false);
    const [errorInputIznos, setErrorInputIznos] = useState(false);
    const [errorInputTax, setErrorInputTax] = useState(false);
    const [errorInputJM, setErrorInputJM] = useState(false);
    const [ErrorInputGrupa, setErrorInputGrupa] = useState(false);
    // error messages inputs
    const [errorMsgVrsta, setErrorMsgVrsta] = useState(false);
    const [errorMsgNalog, setErrorMsgNalog] = useState(false);
    const [errorMsgDatn, setErrorMsgDatn] = useState(false);
    const [errorMsgIznos, setErrorMsgIznos] = useState(false);
    const [errorMsgRed, setErrorMsgRed] = useState(false);
    const [errorMsgJM, setErrorMsgJM] = useState(false);
    const [errorMsgDate, setErrorMsgDate] = useState(false);
    const [errorDate, setErrorDate] = useState(false)


    const [vrstaFocus, setVrstaFocus] = useState(false);
    const [datnFocus, setDatnFocus] = useState(false);
    const [nalogFocus, setNalogFocus] = useState(false);
    const [redFocus, setRedFocus] = useState(false);
    const [iznosFocus, setIznosFocus] = useState(false);
    const [modalErrorOpen, setModalErrorOpen] = useState(false);
    const [editClient, setEditClient] = useState(false);
    const [taxFocus, setTaxFocus] = useState(false);
    const [jmFocus, setJmFocus] = useState(false);
    const [sifraoFocus, setSifraoFocus] = useState(false);
    const [brendFocus, setBrendFocus] = useState(false);
    const [dateFocus, setDateFocus] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [modalErrorOpenData, setModalErrorOpenData] = useState(false);
    const [modalErrorDeleteItemOpen, setModalErrorDeleteItemOpen] = useState(false)
    const [modalDeleteItem, setModalDeleteItem] = useState(false);
    const [handledBlur, setHandledBlur] = useState(false);





    // povlacenje podataka iz lokal storidza
    const token = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : null
    //  const artikalCode = typeof window !== 'undefined' ? localStorage.getItem('artikal') : null
    const companyCode = typeof window !== 'undefined' ? localStorage.getItem('company') : null
    const companyNaziv = typeof window !== 'undefined' ? localStorage.getItem('companyNaziv') : null
    // niz prema kome se formira pagination u mrezi(pri kreiranju 'DataTable')
    const defaultComponentOptions = {
        rowsPerPageText: 'Redovi po stranici:',
        rangeSeparatorText: 'od',
        noRowsPerPage: false,
        selectAllRowsItem: false,
        selectAllRowsItemText: 'Sve',
    };
    // definisanje stejta(objekat i funkcija za taj objekat), prva inicijalizacija

    const [form, setForm] = useState({
        SIFRA: companyCode,
        NALOG: 0,
        RED: 0,
        VRSTA: 0,
        VRSTAD: 0,
        DATN: moment.unix(new Date(normDate).getTime() / 1000).format("DD.MM.YYYY"),
        OPIS: '',
        IZNOS: 0.00,
        IZNOSV: 0.00,
        SALDO: 0.00,
        RACUN: '',
        KOMTID: 0,
        KONTID: 0,
        ORGJEDID: 0,
        MJESTOTROSKAID: 0,
        VALUTA_NAZIV: 0,
        VAL: 0,
        SIFRAO: 0,
        CNAZIV: '',
        DUGUJE: 0.00,
        DUGUJEV: 0.00,
        POTRAZ: 0.00,
        POTRAZV: 0.00,


    })


    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    // funkcija koja se poziva klikom na dugme 'Sacuvaj'

    const onSubmit = () => {
        //    console.log(form);
        // provjera obaveznih polja na dugmetu
        // ukoliko nisu popunjena obavezna polja, zaustavlja akciju i prikazuje upozorenje(poruka)
        // ukoliko su popunjena obavezna polja, upada u else i izvrsava se funkcija

        if (form.VRSTA === 0 || form.VRSTA === "" || form.NALOG === 0 || form.NALOG === "" || form.RED === 0 || form.RED === "" || form.DATN === 0 || form.DATN === "" || form.IZNOS === 0 || form.IZNOS === "") {
            setModalErrorOpen(true)

        } else {



            if (form && itemUpdate) {
                //  ako su polja popunjena i ako itemUpdate aktivan ide se na update

                fetch(API + `/blagajna/update/${companyCode}`, {
                    method: 'POST',
                    body: JSON.stringify(form),
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    },
                })
                    .then((response) => {
                        response.json()
                        setForm(prev => ({
                            ...prev,
                            SIFRA: companyCode,
                            RED: '',
                            IZNOSV: '',
                            OPIS: '',
                            IZNOS: '',
                            SALDO: '',
                            RACUN: '',
                            SIFRAO: '',
                            SIFRA: '',
                            KOMTID: '',
                            KONTID: '',
                            MJESTOTROSKAID: '',
                            DUGUJE: '',
                            DUGUJEV: '',
                            POTRAZ: '',
                            POTRAZV: '',
                        }))
                        setKomt(null)
                        setSifrao(null)
                        setKont(null)
                        setMjesto(null)



                    }).then(() => {
                        getBlagajnaData(1, perPage)
                        getTotal()
                        setItemUpdate(false)
                        rbrRef.current.focus()


                    })
            }

            // u suprotnom ide na upis sloga
            // poziva api za upis
            else {
                fetch(API + `/blagajna/new/${companyCode}`, {

                    method: 'POST',
                    body: JSON.stringify(form),
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    },
                })
                    .then((response) => {
                        response.json()
                        setForm(prev => ({
                            ...prev,

                            RED: '',
                            OPIS: '',
                            IZNOS: '',
                            IZNOSV: '',
                            RACUN: '',
                            SIFRAO: '',
                            SIFRA: '',
                            KOMTID: '',
                            KONTID: '',
                            MJESTOTROSKAID: '',
                            DUGUJE: '',
                            DUGUJEV: '',
                            POTRAZ: '',
                            POTRAZV: '',
                        }))
                        setKomt(null)
                        setSifrao(null)
                        setKont(null)
                        setMjesto(null)

                    }).then(() => {
                        getBlagajnaData(1, perPage)
                        getTotal()
                        rbrRef.current.focus()


                    })
            }
        }
    }





    // prati promjene state form i vrijednosti u polju nalog i u zavisnosti od uslova mjenja vrijednosti u samom state form
    useEffect(() => {
        if (form && form.NALOG && form.NALOG != firstNalogNumber) {
            setForm(prev => ({
                ...prev,
                SIFRA: companyCode,
                RED: 1,
                OPIS: '',
                IZNOS: '',
                IZNOSV: '',
                SALDO: '',
                RACUN: '',
                SIFRAO: '',
                BNAZIV: '',
                SIFRA: '',
                KOMTID: '',
                KONTID: '',
                ORGJEDID: '',
                MJESTOTROSKAID: '',
                VALUTA_NAZIV: '',
                VAL: '',
                DUGUJE: '',
                DUGUJEV: '',
                POTRAZ: '',
                POTRAZV: '',
            }))
            getBlagajnaData(1, perPage)

            setKomt(null)
            setValuta(null)
            setKont(null)
            setMjesto(null)


            checkDocument()
        } else {
            setBlagajna(null)
            setNormDate(normDate)
            setSumTotal(null)

        }
    }, [form.NALOG])


    // funkcija koja se poziva onCjange da bi ocistila ili promijenila podatke u poljima u zavisnosti od unesenog rednog broja 
    const resetForm = (row) => {

        if (form && form.RED && form.RED >= firstRedNumber) {
            setItemUpdate(false);
            setForm(prev => ({
                ...prev,
                SIFRA: companyCode,
                OPIS: '',
                IZNOS: '',
                IZNOSV: '',
                SALDO: '',
                RACUN: '',
                SIFRAO: '',
                DUGUJE: '',
                DUGUJEV: '',
                POTRAZ: '',
                POTRAZV: '',
            }));
            setKomt(null);
            setKont(null);
            setMjesto(null);
            setSifrao(null);
            setNormDate(normDate);
        } else if (form && form.RED && form.RED < firstRedNumber) {
            setItemUpdate(false);
            setForm(prev => ({
                ...prev,
                SIFRA: companyCode,
                OPIS: '',
                IZNOS: '',
                IZNOSV: '',
                SALDO: '',
                RACUN: '',
                SIFRAO: '',
                DUGUJE: '',
                DUGUJEV: '',
                POTRAZ: '',
                POTRAZV: '',
            }));
            // selectDataKomt.map((komt) => {

            //     setKomt(komt)

            // })


        }
    };


    // prati promjenu stranica
    useEffect(() => {
        getBlagajnaData(1, perPage)
        orgRef.current.focus()

    }, [perPage])


    useEffect(() => {
    }, [form])

    useEffect(() => {

    }, [blagajna])


    // prati promjenu na organizacionoj i vrsti i zatim trazi prvi slobodan nalog
    useEffect(() => {
        getBlagajnaFirstFreeNumber()
    }, [!form.ORGJEDID && !form.VRSTA])

    // prati promjenu vrste i trazi 1. slobodan nalog 
    useEffect(() => {
        getBlagajnaFirstFreeNumber()
    }, [form.VRSTA])



    // api za dobijanje prvog slobodnog broja za nalog 
    const getBlagajnaFirstFreeNumber = () => {
        fetch(API + `/blagajna/firstFreeNumber`, {
            method: 'POST',
            body: JSON.stringify({ orgjedid: form.ORGJEDID, vrsta: form.VRSTA }),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setForm(prev => ({ ...prev, NALOG: data[0].NALOG, }))
                setFirstNalogNumber(data[0].NALOG)

            })
    }
    // api za dobijanje prvog slobodnog broja za redni broj

    const getBlagajnaFirstFreeNumberRbr = () => {
        fetch(API + `/blagajna/firstFreeNumberRbr/${companyCode}`, {
            method: 'POST',
            body: JSON.stringify({ orgjedid: form.ORGJEDID, vrsta: form.VRSTA, nalog: form.NALOG }),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setForm(prev => ({ ...prev, RED: Number(data) }))
                setFirstRedNumber(Number(data))
            })
    }
    // api za dobijanje podataka za vrstu 

    const getVrstaData = () => {
        fetch(API + `/vrstaKnjizenja/all`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataVr(data)
            })
    }
    // api za dobijanje podataka za mjesto 

    const getMjestoData = () => {
        fetch(API + `/mjestoTroska/all`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataMjesto(data)
            })
    }

    // api za dobijanje podataka za organizacionu jedinicu 

    const getOrgData = () => {
        fetch(API + `/organizacioneJedinice/all`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataOrg(data)
            })
    }

    // api za dobijanje podataka za valutu 

    const getValutaData = () => {
        fetch(API + `/valuta/all`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataValuta(data)
            })
    }

    // api za dobijanje podataka za opis 

    const getOpisbData = () => {
        fetch(API + `/blagajnaOpis/all`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataBlagajna(data)
            })
    }

    // api za dobijanje podataka za Konto 

    const getKontData = () => {
        fetch(API + `/konto/all`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataKonto(data)
            })
    }

    // api za dobijanje podataka za komitenta 

    const getKomtData = () => {
        fetch(API + `/client/all/${companyCode}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataKomt(data)
            })
    }
    // api za dobijanje podataka za mrezu 

    const getBlagajnaData = (page, limit) => {
        fetch(API + `/blagajna/document/${page}/${limit}/${companyCode}`, {
            method: 'POST',
            body: JSON.stringify({ nalog: form.NALOG, vrsta: form.VRSTA }),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                getBlagajnaFirstFreeNumberRbr()
                setBlagajna(data.result)
                setTotal(data.total)
                // let red = data?.map((item) => item.RED);
                // setForm(prev => ({ ...prev, RED: Number(red.length - 1) + 2, }))
            })
    }
    // api za dobijanje podataka za SALDO 

    const getTotal = () => {
        fetch(API + `/blagajna/total/${companyCode}`, { // dohvatanje apija iz servera 
            method: 'POST',
            body: JSON.stringify({ vrsta: form.VRSTA, nalog: form.NALOG }), // uslovi 
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        }).then((response) => response.json())
            .then(data => {
                if (data && data.length) {
                    setSumDuguje(data[0].DUGUJE) //  postavljanje stejtova koji sluze za racunanje salda (totala)
                    setSumPotraz(data[0].POTRAZ)
                    setSumTotal(data[0].UKUPNO)


                }
            })
    }

    // api za dobijanje podataka za Prvi red podataka  

    const checkDocument = () => {
        fetch(API + `/blagajna/check/documentData/${companyCode}`, {
            method: 'POST',
            body: JSON.stringify({ nalog: form.NALOG, vrsta: form.VRSTA }),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        }).then((response) => response.json())
            .then(data => {
                if (data && data.length) {
                    setForm(prev => ({ // citanje forma za datum i valutu
                        ...prev, SIFRA: companyCode,


                        DATN: moment.unix(new Date(data[0].DATN).getTime() / 1000).format("DD.MM.YYYY"), VAL: data[0].VAL,
                    }))



                    selectDataValuta.map((item) => { // citanje forma za valutu
                        if (item.VALUTA_NAZIV == data[0].VALUTA) {
                            setValuta(item)
                        }
                    })
                    getBlagajnaData(1, perPage)
                    getTotal()
                } else { // cisti saldo
                    setSumTotal(false)

                }
            })
    }


    // funkcija koja se poziva na polju IZNOS da bi sprijecila ponovno racunanje u slucaju da je iznos isti
    const handleBlur = (e) => {
        if (!handledBlur && form.IZNOS != (Number(form.IZNOS) * Number(form.VAL))) { // ako se do sada nije desio jangle blur i ako trenutno u iznosu vrijednos nije ista kao u navedenom izracunavanju ona se desava
            formCheck(e);  // stepenasta
            setHandledBlur(false);  // i postavlja se handleblur
        } if (form.IZNOS == (Number(form.IZNOS) * Number(form.VAL))) { //

            setHandledBlur(true);
        }
    };




    // funkcija za brisanje koja se poziva klikom na dugme 'Delete'
    //  api za brisanje

    const onDelete = (companyCode, nalog, red, orgjedid, vrsta) => {
        fetch(API + `/blagajna/delete/${companyCode}/${nalog}/${red}/${orgjedid}/${vrsta}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                if (response.status == 200) {
                    setModalDeleteItem(false)
                    setKomt(null)
                    setSifrao(null)
                    setKont(null)
                    setMjesto(null)
                    setBlagajna(null)
                    setNormDate(null)
                    checkDocument()
                    setItemUpdate(false)
                    setForm(prev => ({
                        ...prev, RED: 1,
                        OPIS: '',
                        IZNOS: 0.00,
                        SALDO: 0.00,
                        RACUN: '',
                        KOMTID: 0,
                        KONTID: 0,
                        MJESTOTROSKAID: 0,
                        VALUTA_NAZIV: 0,
                        SIFRAO: 0,
                        VAL: 0,
                    }))
                    rbrRef.current.focus()
                    getBlagajnaFirstFreeNumberRbr()
                    getBlagajnaData(1, perPage)
                }
            })
    }
    // funkcija koja se poziva klikom na dugme Edit
    // postavlja fokus na polje NAZIV 
    // popunjava polja podacima iz mreze

    const onEdit = (item) => {
        setKomt(null)
        setKont(null)
        setSifrao(null)
        setItemUpdate(true)
        setForm((prevState) => ({
            ...prevState, OPIS: item.OPIS, SIFRA: item.SIFRA, RED: item.RED,
            VRSTA: item.VRSTA, ORGJEDID: item.ORGJEDID, RACUN: item.RACUN, IZNOS: item.IZNOS,
            VAL: item.VAL, KOMTID: item.KOMTID, KONTID: item.KONTID, IZNOSV: item.IZNOSV,
            MJESTOTROSKAID: item.MJESTOTROSKAID, DUGUJE: item.DUGUJE, DUGUJEV: item.DUGUJEV, POTRAZ: item.POTRAZ, POTRAZV: item.POTRAZV,
        }));

        selectDataKomt.map((komt) => {
            if (komt.ID == item.KOMTID) { // ako je sve povezano uzimaju se podaci iz mreze
                setKomt(komt)
            }
        })
        selectDataVr.map((vrsta) => {
            if (vrsta.VRSTAD == item.VRSTA) {
                setVrsta(vrsta)
            }
        })
        selectDataOrg.map((org) => {
            if (org.ID == item.ORGJEDID) {
                setOrg(org)
            }
        })

        selectDataKonto.map((kont) => {
            if (kont.ID == item.KONTID) {
                setKont(kont)
            }
        })

        selectDataBlagajna.map((sifrao) => {
            if (sifrao.SIFRA == item.SIFRAO) {
                setSifrao(sifrao)
            }
        })

        selectDataMjesto.map((mjesto) => {
            if (mjesto.ID == item.MJESTOTROSKAID) {
                setMjesto(mjesto)
            }
        })

        selectDataValuta.map((valuta) => {
            if (valuta.VALUTA == item.VALUTA_NAZIV) {
                setValuta(valuta)
            }
        })



    }
    // funkcija koja se poziva klikom na dugme 'Stampa'

    const onPrint = () => {
        // uslov preko koga saljemo naziv preduzeca sa frontenda na bekend
        // ako je objekat form popunjen, napuni polje NAZIV_PRED iz lokal storidza
        // poziva api za stampu, otvara izvjestaj(API + data.link)
        setSpinner(true)
        if (form.VRSTA === "" || form.NALOG === "" || form.RED === "") {//
            setSpinner(false)
            setModalErrorOpenData(true)
        }

        if (form.NALOG && !blagajna) {//

            setSpinner(false)
            setModalErrorOpenData(true)
        } else {
            if (form) { // formiraju se polja  companyCode i  companyNaziv
                form.SIFRA = companyCode
                form.CNAZIV = companyNaziv
            }
            fetch(API + '/blagajna/print', {// poziva se api za print sa servera
                method: 'POST',
                body: JSON.stringify(form),
                responseType: 'blob',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-type': 'application/json; charset=UTF-8'
                },
            })
                .then((response) => {
                    if (response.ok) { // checks if the response is with status 200 (successful)
                        return response.blob().then(blob => {
                            let blobURL = URL.createObjectURL(blob);
                            let iframe = document.createElement('iframe'); //load content in an iframe to print later
                            document.body.appendChild(iframe);
                            iframe.style.display = 'none';
                            iframe.src = blobURL;
                            iframe.onload = function () {
                                setTimeout(function () {
                                    iframe.focus();
                                    iframe.contentWindow.print();
                                }, 1);
                            };
                            setSpinner(false)

                        });

                    }

                })

        }
    }
    // funkcija koja se poziva prilikom promjene vrijednosti u inputu
    const handleChange = (event) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name;

        setForm((prevState) => ({
            ...prevState,
            [name]: value
        }));

    };

    useEffect(() => {
        if (rbrRef.current && errorInputRed) {
            rbrRef.current.focus();
        }
    }, [!redFocus]);

    // validacija praznog polja
    const helperValidateEmptyField = (input) => {
        if (input.length < 1) return 'Polje je obavezno!';
    }

    // stepenasta
    const formCheck = (event) => {

        const target = event.target;
        let value = target.value;
        const name = event.target.name;

        switch (name) { // switch case petlja za provjeru obaveznih polja
            case 'VRSTA': {
                setErrorMsgVrsta(helperValidateEmptyField(value))
                setErrorInputVrsta(helperValidateEmptyField(value) ? true : false)
                break;

            }
            case 'NALOG': {
                setErrorMsgNalog(helperValidateEmptyField(value))
                setErrorInputNalog(helperValidateEmptyField(value) ? true : false)
                break;
            }
            case 'DATN': {
                let reqex = /^(0[1-9]|[12]\d|3[01]).(0[1-9]|1[0-2]).([12]\d{3})$/gi;
                if (value && reqex.test(value)) {
                    setErrorInputDatn(false)
                    setErrorMsgDatn(null)
                } else {
                    setErrorInputDatn(true)
                    setErrorMsgDatn('Datum nije validan')

                }

                break
            }
            case 'RED': {
                setErrorMsgRed(helperValidateEmptyField(value))
                setErrorInputRed(helperValidateEmptyField(value) ? true : false)
                break;

            }

            case 'IZNOS': {
                setErrorMsgIznos(helperValidateEmptyField(value))
                setErrorInputIznos(helperValidateEmptyField(value) ? true : false)
                if ((form.VAL >= 1)) {// ako je valuta veca ili jednaka 1 vrsi se dato preracunavanje

                    setIznosFocus(true)

                    setForm(prev => ({ ...prev, IZNOSV: (Number(form.IZNOS)), IZNOS: (Number(form.IZNOS) * Number(form.VAL)) }))


                }


                break
            }


        }

        // fokusi za obavezna polja
        if (name === 'VRSTA') {
            if (!form.VRSTA) {
                setVrstaFocus(false)
                vrstaRef.current.focus()

            } else {
                setVrstaFocus(false)
                nalogRef.current.focus()
            }
        }

        if (name === 'NALOG') {
            if (errorInputVrsta) {
                setVrstaFocus(false)
                vrstaRef.current.focus()
            }
            else {
                setNalogFocus(false)
                dateRef.current.focus()
            }
        }
        if (name === 'DATN') {
            if (errorInputNalog) {
                setNalogFocus(false)
                nalogRef.current.focus()
            } else if (!form.DATN) {
                setDatnFocus(false)
                dateRef.current.focus()

            } else {
                setDatnFocus(false)
                valutaRef.current.focus()
            }
        }
        if (name === 'VALUTA') {
            if (errorInputDatn) {
                setDateFocus(true)
                dateRef.current.focus()
            } else {
                kursRef.current.focus()
            }
        }


        if (name === 'RED') {
            if (!form.RED) {
                setRedFocus(false)
                rbrRef.current.focus()

            } else {
                setRedFocus(false)
                blagajnaRef.current.focus()
            }
        }

        if (name === 'SIFRAO') {
            if (errorInputRed) {
                setRedFocus(false)
                rbrRef.current.focus()
            }
            else {
                setSifraoFocus(false)
                blagajnaRef.current.focus()
            }
        }

        if (name === 'IZNOS') {
            if (!form.IZNOS) {
                setIznosFocus(false)
                iznosRef.current.focus()

            } else {
                setIznosFocus(false)
                racunRef.current.focus()
            }
        }

        // if (name === 'SIFRAO') {
        //     if (errorInputRed) {
        //         setRedFocus(false)
        //         rbrRef.current.focus()
        //     }
        //     else {
        //         setSifraoFocus(false)
        //         blagajnaRef.current.focus()
        //     }
        // }

    }







    useEffect(() => {
        getOpisbData()
    }, [!selectDataBlagajna])

    useEffect(() => {
        getKomtData()
    }, [!selectDataKomt])
    useEffect(() => {
        getKontData()
    }, [!selectDataKonto])
    useEffect(() => {
        getMjestoData()
    }, [!selectDataMjesto])
    useEffect(() => {
        getVrstaData()
    }, [!selectDataVr])
    useEffect(() => {
        getValutaData()
    }, [!selectDataValuta])
    useEffect(() => {
        getOrgData()
    }, [!selectDataOrg])


    // definisu se polja u tabeli i popunjavaju

    const columns = [
        {
            name: 'RBR',
            selector: row => row.RED,
            width: '100px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.RED}</span>
                </>
            ),
        },

        {
            name: 'OPIS',
            selector: row => row.OPIS,
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.OPIS}</span>
                </>
            ),
        },
        {
            name: 'KONTO',
            selector: row => row.kontNaziv,

            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.kontNaziv}</span>
                </>
            ),
        },
        {
            name: 'KOMITENT',
            selector: row => row.komtNaziv,

            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.komtNaziv}</span>
                </>
            ),
        },

        {
            name: 'IZNOS',
            selector: row => row.IZNOS,
            width: '100px',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.IZNOS}</span>
                </>
            ),
        },

        {
            name: 'RACUN',
            selector: row => row.RACUN,
            width: '100px',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.RACUN}</span>
                </>
            ),
        },


    ];

    // funkcija se poziva prilikom promjene stranice u tabeli stranica 1,2,3 ...
    const handlePageChange = page => {
        getBlagajnaData(page, perPage)
    }
    // funkcija se poziva prilikom promjene broja redova ispisa u tabeli 10, 15, 30 ...
    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
    }


    // propsovi za auto komplit  org
    const defaultPropsOrg = {
        options: selectDataOrg,
        getOptionLabel: (option) => option ? `${option.SIFRA} ${option.NAZIV}` : '',
    };
    // propsovi za uvoznika 

    // propsovi za auto komplit  komt
    const defaultPropsKomt = {
        options: selectDataKomt,
        getOptionLabel: (option) => option ? `${option.SIFRA} ${option.NAZIV}` : '',
    };

    // valuta

    const defaultPropsValuta = {
        options: selectDataValuta,
        getOptionLabel: (option) => option ? `${option.VALUTA}` : '',
    };

    // vrsta

    const defaultPropsVrsta = {
        options: selectDataVr,
        getOptionLabel: (option) => option ? `${option.VRSTAD} ${option.OPISD}` : '',
    };
    // mjesto
    const defaultPropsMjesto = {
        options: selectDataMjesto,
        getOptionLabel: (option) => option ? `${option.SIFRA} ${option.NAZIV}` : '',
    };

    // sifra opisa
    const defaultPropsSifrao = {
        options: selectDataBlagajna,
        getOptionLabel: (option) => option ? ` ${option.SIFRA} ${option.NAZIV}` : '',
    };

    // konto

    const defaultPropsKont = {
        options: selectDataKonto,
        getOptionLabel: (option) => option ? `${option.ID} ${option.NAZ} ` : '',
    };
    // funkcija za pretragu iz mreze
    // salje zahtjev na bekend prema upitu sa parametrima koje posaljemo
    // u responsu vraca podatke koje dobio iz upita 

    const searchChange = (e) => {
        e.preventDefault();
        setSearchInput(e.target.value);
        const fetchData = async () => {//
            const res = await axios.get(`${API}/blagajna/query/${companyCode}?q=${searchInput}`, { headers: { "Authorization": `Bearer ${token}` } });
            setBlagajna(res.data);
        };
        // uslov za za slanje zahtjeva za povlacenje podataka
        if (searchInput.length === 0 || searchInput.length > 2) fetchData();  //  u uslovu se provjerava da li je input prazan i da li je veci od dva i ako je veci onda krece pretraga

    };
    // funkcija za brisanje pretrage klikom na dugme 'Ocisti'
    // osvjezava polje za pretragu
    // osvjezava mrezu

    const handleClear = () => {
        setSearchInput('');
        getBlagajnaData(1, perPage)
    }

    // fokus pri renderu
    React.useEffect(() => {
        const timeout = setTimeout(() => {
            orgRef.current.focus();
        }, 100);
        return () => {
            clearTimeout(timeout);
        };
    }, []);

    return (

        <div>
            {/* Kontejner za naslov  */}

            <div className="title-container">
                {/* Naziv registra */}
                <header className="title-header">
                    <h1 id="media-query-title">Blagajna</h1>
                </header>


                <div className="naslov-ikonice">
                    <ul className="nav flex-column flex-lg-row justify-content-end">

                        <li className="nav-item toolbaritem">
                            <input type="image" alt="save" className='dugme-sacuvaj' title='Sačuvaj' tabIndex="15" src={require('../../assets/images/floppydisk.png')} onFocus={onSubmit} />
                        </li>

                        <li className="nav-item toolbaritem">
                            <input type="image" alt="print" className='dugme-stampa' title='Štampa' tabIndex="16" src={require('../../assets/images/printer.png')} onClick={onPrint} />
                        </li>
                        <li className="nav-item toolbaritem">
                            <input type="image" alt="delete item" className='dugme-stampa' title='Brisanje stavke' tabIndex="17" src={require('../../assets/images/trash1.png')} onClick={form.RED && form.RED !== '' && form.RED !== 0 && form.RED !== '' ? setModalDeleteItem : setModalErrorDeleteItemOpen} />
                        </li>
                    </ul>
                </div>

            </div>
            {/* Kontejner pune sirine */}
            <Container fluid >
                <Row>
                    <div className='products-form'>
                        <Row>
                            <div className="col-6" style={{ display: 'flex', paddingRight: '0px', paddingLeft: '-1px', marginRight: 'auto', marginLeft: '0px' }}>
                                {/* Sirina reda */}
                                <div className="col-4">
                                    <div className='field-container' style={{ marginRight: '3px' }} >
                                        <label id="media-query-label">Organizaciona jedinica</label>
                                        <Autocomplete
                                            {...defaultPropsOrg}
                                            value={org}
                                            onChange={(event, newValue) => {
                                                setOrg(newValue);
                                                setForm(prev => ({ ...prev, ORGJEDID: newValue && newValue != null ? newValue.ID : null }))
                                            }}

                                            renderInput={(params) => (
                                                <TextField {...params}
                                                    className="clients-textinput"
                                                    name=''
                                                    onFocus={() => setTaxFocus(true)}
                                                    inputRef={orgRef}
                                                    autoFocus
                                                    tabIndex={1}
                                                    autoComplete="on"
                                                    onBlur={(e) => formCheck(e)}
                                                    placeholder='Pretražite po organizacionoj jedininici'
                                                    variant="standard" />
                                            )}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className={errorInputVrsta ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px' }} >
                                        <label id="media-query-label">Vrsta Knjizenja</label>
                                        <Autocomplete
                                            {...defaultPropsVrsta}
                                            value={vrsta}
                                            disablePortal={true}
                                            onChange={(event, newValue) => {
                                                setForm(prev => ({ ...prev, VRSTA: newValue && newValue != null ? newValue.VRSTAD : null }))
                                                setVrsta(newValue);
                                            }}

                                            renderInput={(params) => (
                                                <TextField {...params}
                                                    className="clients-textinput"
                                                    name='VRSTA'
                                                    onFocus={() => setVrstaFocus(true)}
                                                    ref={vrstaRef}
                                                    tabIndex={2}
                                                    onBlur={(e) => formCheck(e)}
                                                    placeholder='Pretraži po vrsti knjiženja'
                                                    variant="standard" />
                                            )}
                                        />
                                        {errorMsgVrsta ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgVrsta}</span> : ''}
                                    </div>
                                </div>
                                {/* Sirina polja */}
                                <div className="col-3">
                                    {/* Provjera da li je polje popunjeno jer je obavezno */}
                                    <div className={errorInputNalog ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px', textAlign: 'right' }} >
                                        <label id="media-query-label-report">Nalog:</label>
                                        <input type="number"
                                            id="media-query-input-text"
                                            maxLength="70"
                                            tabIndex={3}
                                            placeholder=""
                                            name='NALOG'
                                            value={form?.NALOG}
                                            ref={nalogRef}
                                            // Postavlja stejt za fokus na true jer je potrebno zbog stepenaste kontrole
                                            onFocus={() => setNalogFocus(true)}
                                            // Prilikom prelaska na drugo polje, aktivira se stepenasta kontrola
                                            onBlur={(e) => formCheck(e)}
                                            // Ako je izmjena na polju
                                            onChange={(e) => handleChange(e)} />
                                        {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                                        {errorMsgNalog ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgNalog}</span> : ''}

                                    </div>
                                </div>

                                <div className="col-2">
                                    <div className={errorInputDatn ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px' }} >
                                        <label id="media-query-label">Datum naloga:</label>
                                        <input
                                            type="text"
                                            id="media-query-input-text"
                                            tabIndex={4}
                                            ref={dateRef}
                                            onFocus={() => setDateFocus(true)}
                                            placeholder=""
                                            name='DATN'
                                            value={form?.DATN}
                                            onChange={(e) => handleChange(e)}
                                            onBlur={(e) => formCheck(e)} />
                                        {errorMsgDatn ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgDatn}</span> : ''}
                                    </div>
                                </div>
                                {/* Sirina polja */}
                            </div>
                            {/* Sirina polja */}
                            <div className="col-6" style={{ display: 'flex', paddingRight: '0px', paddingLeft: '0px', marginRight: 'auto', marginLeft: '0px' }}>
                                <div className="col-2">
                                    <div className='field-container' style={{ marginRight: '3px' }} >
                                        <label id="media-query-label">Valuta:</label>
                                        <Autocomplete
                                            {...defaultPropsValuta}
                                            value={valuta}
                                            onChange={(event, newValue) => {
                                                setValuta(newValue);
                                                setForm(prev => ({ ...prev, VALUTA_NAZIV: newValue && newValue != null ? newValue.VALUTA : null, VAL: newValue && newValue != null ? newValue.KURS : null, }))
                                            }}

                                            renderInput={(params) => (
                                                <TextField {...params}
                                                    className="clients-textinput"
                                                    name='VALUTA'

                                                    ref={valutaRef}

                                                    tabIndex={5}
                                                    autoComplete="on"
                                                    onChange={(e) => handleChange(e)}
                                                    onBlur={(e) => formCheck(e)}
                                                    placeholder='Pretražite po valuti'
                                                    variant="standard" />
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className='field-container' style={{ marginRight: '3px', textAlign: 'right' }}>
                                        <label id="media-query-label">Kurs:</label>
                                        <input style={{ color: 'grey' }} type="number" id="media-query-input-text" readOnly={true} ref={kursRef} maxLength="70" tabIndex="6" placeholder="" name='KURS' value={form ? form.VAL : ''} onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>

                                {/* Sirina polja */}
                                <div className="col-4">
                                    <div className="form-group row label-box-div">

                                        <label className="col-form-label col-lg-7 label-box" id="media-query-label-report" >ㅤㅤㅤㅤㅤㅤ</label>
                                        <div className="col-4" style={{ marginLeft: '' }}>
                                            <input className="total123" readOnly={true} type='text' id="media-query-input-text" tabIndex="-1" />
                                        </div>

                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="form-group row label-box-div">

                                        <label className="col-form-label col-lg-1 label-box" id="media-query-label-report" >ㅤ</label>
                                        <div className="col-1" style={{ marginLeft: '' }}>
                                            <input className="total123" readOnly={true} type='text' id="media-query-input-text" tabIndex="-1" />
                                        </div>

                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className='col-form-right'>
                                        <div style={{ position: 'relative', left: '-7px' }} className="field-container-number">
                                            <label id="media-query-label-sum">ㅤIznos V:</label>
                                            <input className="totalb" readOnly={true} tabIndex="-1" type='text' id="media-query-input-text" value={form ? form.IZNOSV : ''} onChange={(e) => {
                                                handleChange(e);

                                            }} />
                                        </div>
                                    </div>
                                </div>


                                {/* Sirina polja */}


                            </div>
                        </Row>

                        <Row>
                            {/* Sirina reda */}
                            <div className="col-6" style={{ display: 'flex', paddingRight: '0px', paddingLeft: '-1px', marginRight: 'auto', marginLeft: '0px' }}>

                                <div className="col-1">
                                    <div className='col-form-right'>
                                        <div className={errorInputRed ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px' }} >
                                            <label id="media-query-label">Rbr.</label>
                                            <input
                                                type="number"
                                                id="media-query-input-text"
                                                ref={rbrRef}
                                                tabIndex="7"
                                                placeholder="0"
                                                name='RED'
                                                value={form?.RED}
                                                onFocus={() => setRedFocus(true)}
                                                onBlur={(e) => {
                                                    resetForm();
                                                    formCheck(e);
                                                }}

                                                onChange={(e) => {
                                                    handleChange(e);
                                                }}
                                            />
                                            {errorMsgRed ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgRed}</span> : ''}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-3">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label id="media-query-label">Sifra Opisa </label>
                                        {/* Ako je Autocomplete  poziva na odabri mjesta*/}
                                        <Autocomplete
                                            {...defaultPropsSifrao}
                                            value={sifrao}
                                            disablePortal={true}
                                            onChange={(event, newValue) => {
                                                setForm(prev => ({ ...prev, SIFRAO: newValue && newValue != null ? newValue.SIFRA : null, OPIS: newValue && newValue != null ? newValue.NAZIV : null, }))
                                                setSifrao(newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField id='media-query' {...params} className="clients-textinput" name='SIFRAO' onFocus={() => setSifraoFocus(true)} ref={blagajnaRef} tabIndex="8" onBlur={(e) => formCheck(e)} placeholder='Pretražite po opisu' variant="standard" />
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label id="media-query-label">Opis:</label>
                                        <input type="text" id="media-query-input-text" maxLength="70" tabIndex="9" placeholder="Opis" name='OPIS' value={form ? form.OPIS : ''} onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label id="media-query-label">Konto</label>
                                        {/* Ako je Autocomplete  poziva na odabri mjesta*/}
                                        <Autocomplete
                                            {...defaultPropsKont}
                                            value={kont}
                                            disablePortal={true}
                                            onChange={(event, newValue) => {
                                                setForm(prev => ({ ...prev, KONTID: newValue && newValue != null ? newValue.ID : null }))
                                                setKont(newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField id='media-query' {...params} className="clients-textinput" name='KONTID' onFocus={() => setBrendFocus(true)} ref={kontRef} tabIndex="10" onBlur={(e) => formCheck(e)} placeholder='Pretražite po kontu' variant="standard" />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* Sirina polja */}
                            <div className="col-6" style={{ display: 'flex', paddingRight: '0px', paddingLeft: '0px', marginRight: 'auto', marginLeft: '0px' }}>
                                <div className="col-5">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label id="media-query-label">Komitent</label>
                                        {/* Ako je Autocomplete  poziva na odabri mjesta*/}
                                        <Autocomplete
                                            {...defaultPropsKomt}
                                            value={komt}
                                            disablePortal={true}
                                            onChange={(event, newValue) => {
                                                setForm(prev => ({ ...prev, KOMTID: newValue && newValue != null ? newValue.ID : null }))
                                                setKomt(newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField id='media-query' {...params} className="clients-textinput" name='KOMTID' onFocus={() => setBrendFocus(true)} ref={komtRef} tabIndex="11" onBlur={(e) => formCheck(e)} placeholder='Pretražite po komitentima' variant="standard" />
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label id="media-query-label">Mjesto Troska:</label>
                                        {/* Ako je Autocomplete  poziva na odabri mjesta*/}
                                        <Autocomplete
                                            {...defaultPropsMjesto}
                                            value={mjesto}
                                            disablePortal={true}
                                            onChange={(event, newValue) => {
                                                setForm(prev => ({ ...prev, MJESTOTROSKAID: newValue && newValue != null ? newValue.ID : null }))
                                                setMjesto(newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField id='media-query' {...params} className="clients-textinput" name='MJESTOTROSKAID' onFocus={() => setBrendFocus(true)} ref={mjestoRef} tabIndex="12" onBlur={(e) => formCheck(e)} placeholder='Pretražite po mjestu troška' variant="standard" />
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className="col-2">
                                    <div className='col-form-right'>
                                        <div className={errorInputTax ? 'inputWithError' : 'field-container-number'} style={{ marginRight: '3px' }}>
                                            <label id="media-query-label-sum">Iznos:</label>
                                            <input
                                                type="number"
                                                id="media-query-input-text"
                                                maxLength="20"
                                                tabIndex="13"
                                                ref={iznosRef}
                                                placeholder=""
                                                name='IZNOS'
                                                onFocus={() => setIznosFocus(true)}
                                                value={form ? form.IZNOS : ''}
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChange(e);

                                                }}
                                            />
                                            {errorMsgIznos ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgIznos}</span> : ''}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-2">
                                    <div className='field-container' style={{ marginRight: '3px', textAlign: 'right' }}>
                                        <label id="media-query-label">Racun:</label>
                                        <input type="number" id="media-query-input-text"
                                            maxLength="20"
                                            tabIndex="14"
                                            placeholder="Unesite račun"
                                            name='RACUN'
                                            ref={racunRef}
                                            value={form ? form.RACUN : ''}
                                            onChange={(e) => {
                                                handleChange(e)

                                            }}

                                            onBlur={() => {

                                                if ((form.VRSTA == 1)) { //  ako je vrsta jednaka 1 onda se vrsi dati raspored preracunavanja a ako je 2 onda je drugi raspored
                                                    setForm(prev => ({ ...prev, DUGUJE: (Number(form.IZNOS)), DUGUJEV: (Number(form.IZNOSV)), POTRAZ: (Number(0.00)), POTRAZV: (Number(0.00)) }))
                                                } if ((form.VRSTA == 2)) {
                                                    setForm(prev => ({ ...prev, POTRAZ: (Number(form.IZNOS)), POTRAZV: (Number(form.IZNOSV)), DUGUJE: (Number(0.00)), DUGUJEV: (Number(0.00)) }))
                                                }

                                            }}
                                        />
                                    </div>

                                </div>





                            </div>

                        </Row>


                    </div>
                </Row >
            </Container >



            {/* Stilovi i klase za polje za pretragu;  */}
            < div style={{ display: 'flex' }
            } className='search' >
                {/* vrsta inputa; poziva funkciju za pretragu(searchChange);  */}
                < input type='text' id="media-query-input-text" placeholder='Pretraga...' onChange={(e) => searchChange(e)} value={searchInput} />
                {/* dugme 'Ocisti'( poziva funkciju handleClear za brisanje) */}
                < button onClick={() => handleClear()} className='dugme-clear' > Očisti</button >
            </div >

            {/* Mreza */}
            < DataTable
                columns={columns}
                data={blagajna ? blagajna : []}
                pagination
                paginationServer
                paginationTotalRows={total}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                responsive
                highlightOnHover
                striped
                dense
                pointerOnHover
                persistTableHead
                noDataComponent
                paginationComponentOptions={defaultComponentOptions}
                onRowDoubleClicked={(row, event) => {
                    onEdit(row)
                }
                }
            />

            {/* Modalni form za gresku; prikazuje se ako nisu unijeta obavezna polja */}
            <div className="title-container">
                {/* Naziv registra */}

                <footer className="title-header">
                    <div></div>
                </footer>
                {/* Klasa za ikonice, nalazi se u istom redu kao i naziv, ikonice su formirane kao lista */}

                <div className="naslov-ikonice">
                    <ul className="nav flex-column flex-lg-row justify-content-end">
                        <div style={{ display: 'flex', justifyContent: 'end', position: 'fixed', bottom: '0', flex: '0 0 auto' }}>
                            <li className="nav-item toolbaritem" style={{ width: '50%', marginRight: '5px' }}>
                                <div className='col-form-right'>
                                    <div className="field-container-number">
                                        <label id="media-query-label-sum">Saldo</label>
                                        <input className="total" readOnly={true} type='text' id="media-query-input-text" value={sumTotal && sumTotal != 'NaN' ? numberWithCommas(sumTotal) : 0} onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>
                            </li>
                        </div>
                    </ul>
                </div>

            </div>

            <div>
                <Modal isOpen={modalDeleteItem} centered  >
                    <ModalHeader>Brisanje stavke</ModalHeader>
                    <ModalBody>
                        <span>{`Da li želite da obrišete stavku broj ${form.RED} ? `}</span>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            <button onClick={() => setModalDeleteItem(false)} className="button-no">Ne</button>
                            <button onClick={() => onDelete(companyCode, form.ORGJEDID, form.NALOG, form.VRSTA, form.RED,)} className="button-yes">Da</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            < div >
                <Modal isOpen={modalErrorOpenData} centered  >
                    <ModalHeader>Neispravan unos</ModalHeader>
                    <ModalBody>
                        <span>{`Unesite obavezna polja!`}</span>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            {/* Dugme za zatvaranje modalnog forma */}
                            <button onClick={() => setModalErrorOpen(false)} className="button-no">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div >
            {/* funnkcija za spiner progres bar */}
            <div>
                {spinner ? <Spinner className='spinner' color="primary" id='loader' /> : ''}
            </div>

        </div >
    );

}

export default Blagajna;


