// importovanje komponenti
import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import DataTable from 'react-data-table-component';
import exportIcon from '../assets/export.svg';
import saveIcon from '../assets/save.svg';
import printIcon from '../assets/print.svg';
import SVG from 'react-inlinesvg';
import { API } from '../constants';
import axios from '../api/axios';
import { Spinner } from "reactstrap";
import { getCompanyData, defaultComponentOptions } from '../components/utilities';
import { NumericFormat } from 'react-number-format';
import { useWindowSize } from '../components/useWindowSize';
import { defaultFormRegistri } from "../components/utilities";
// komponenta koju kreiramo

function Valuta() {
    const windowSize = useWindowSize();
    // definisanje konstanti koje koristimo za fokusiranje polja
    const valutaRef = useRef();
    const kursRef = useRef();
    // definisanje stejtova i prva inicijalizacija; objekat i funkcija za taj objekat
    const [valuta, setValuta] = useState(null)
    const [total, setTotal] = useState(0)
    const [perPage, setPerPage] = useState(10);
    const [searchInput, setSearchInput] = useState([]);
    const [spinner, setSpinner] = useState(false);
    const [pending, setPending] = React.useState(false);
    // error messages inputs
    const [errorInputValuta, setErrorInputValuta] = useState(false);
    const [errorInputKurs, setErrorInputKurs] = useState(false);
    // error messages inputs

    const [errorMsgValuta, setErrorMsgValuta] = useState(false);
    const [errorMsgKurs, setErrorMsgKurs] = useState(false);

    const [valutaFocus, setValutaFocus] = useState(false);
    const [kursFocus, setKursFocus] = useState(false);
    const [modalErrorOpenData, setModalErrorOpenData] = useState(false);
    const [modalErrorAuthorizationData, setModalErrorAuthorizationOpen] = useState(false);
    const [modalDeleteItem, setModalDeleteItem] = useState(false);

    const token = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : null;

    // definisanje stejta(objekat i funkcija za taj objekat), prva inicijalizacija
    const [form, setForm] = useState({
        ...defaultFormRegistri,
    });

    // funkcija za povlacenje podataka o preduzecu
    getCompanyData(form);

    // funkcija koja se poziva klikom na dugme 'Sacuvaj'
    const onSubmit = () => {
        // uslov: ako nisu unesena obavezna polja, zaustavlja upis
        if (!unosIspravanDo('UPIS')) return;

        fetch(API + '/valuta/new', {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                response.json()
                // vracanje forme na pocetnu vrijednost
                setForm((prev) => ({
                    ...prev,
                    ...defaultFormRegistri,
                }));
                valutaRef.current.focus()
                getTableData()
            })
    }

    // funkcija koja se poziva pri prvoj inicijalizaciji
    // api za mrezu
    // u responsu puni mrezi(pomocu funkcije setCountry) i puni ukupan broj stranica(setTotal)
    const getTableData = () => {
        fetch(API + `/valuta/all`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setValuta(data.result)
            })
    }

    // prilikom pocetne inicijalizacije, cita mrezu
    useEffect(() => {
        // zaustavljanje fokusa
        const timeout = setTimeout(() => {
            getTableData();
        }, 100);
        return () => {
            clearTimeout(timeout);
        };
    }, []);

    // funkcija za brisanje koja se poziva klikom na dugme 'Delete'
    //  api za brisanje
    // u responsu osvjezava polja, daje prvi slobodan broj za sifru, fokus na polje sifra, osvjezava mrezu  
    const onDelete = (VALUTA) => {
        fetch(API + '/valuta/delete/' + VALUTA, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                response.json()
                // vracanje forme na pocetnu vrijednost
                setForm((prev) => ({
                    ...prev,
                    ...defaultFormRegistri,
                }));
                valutaRef.current.focus()
                getTableData()
            })
    }

    const onEdit = (item) => {
        kursRef.current.focus();
        item.stavkaPronadjena = true;
        setForm(item)
    }

    // funkcija koja se poziva klikom na dugme 'Stampa'
    const onPrint = () => {
        // uslov preko koga saljemo naziv preduzeca sa frontenda na bekend
        // ako je objekat form popunjen, napuni polje NAZIV_PRED iz lokal storidza
        // poziva api za stampu, otvara izvjestaj(API + data.link)
        setSpinner(true);
        if (valuta && valuta.length === 0) {
            setSpinner(false)
            setModalErrorOpenData(true)
        } else {
            if (form) {
                form.NAZIV_PRED = form.companyName
            }
            fetch(API + `/valuta/print`, {
                method: 'POST',
                body: JSON.stringify(form),
                responseType: 'blob',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-type': 'application/json; charset=UTF-8'
                },
            })
                .then((response) => response.json())
                .then(data => {
                    //  console.log(data, 'DATA');
                    if (typeof window != 'undefined') {
                        setTimeout(() => {
                            window.open(API + data.link)
                        }, 200);
                    }
                    setSpinner(false);
                })
        }
    }
    // funkcija koja se poziva prilikom promjene vrijednosti u inputu
    const handleChange = (event) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name;

        // pokretanje forma i zamjena novih vrijednosti
        setForm((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    // uslov: ako nije upisana ni vrijednost, izbaci poruku, setuje crveni okvir i fokusira polje  
    // u suprotnom prolazi dalje
    const checkValutaRequired = (value) => {
        if (!value || value == 0) {
            setErrorMsgValuta('Valuta je obavezna!')
            setErrorInputValuta(true)
            valutaRef.current.focus();
            return false
        } else {
            setErrorMsgValuta(null)
            setErrorInputValuta(false)
            return true
        }
    }

    // uslov: ako nije upisana ni vrijednost, izbaci poruku, setuje crveni okvir i fokusira polje  
    // u suprotnom prolazi dalje
    const checkKursRequired = (value) => {
        if (!value || value == 0) {
            setErrorMsgKurs('Kurs je obavezan!')
            setErrorInputKurs(true)
            kursRef.current.focus();
            return false
        } else {
            setErrorMsgKurs(null)
            setErrorInputKurs(false)
            return true
        }
    }

    // funkcija za kontrolu obaveznih polja
    function unosIspravanDo(name) {
        if (name === 'VALUTA') {
            return true
        }

        if (!checkValutaRequired(form.VALUTA)) {
            return false
        }

        if (name === 'KURS') {
            return true
        }

        if (!checkKursRequired(form.KURS)) {
            return false
        }
        return true
    }

    const columns = [
        {
            name: 'Valuta',
            selector: row => row.VALUTA,
            // sirina polja po rezolucijama
            width:
                windowSize.width > 1920 ? '130px'
                    : windowSize.width > 1680 ? '100px'
                        : windowSize.width > 1600 ? '100px'
                            : windowSize.width > 1440 ? '100px'
                                : windowSize.width > 1280 ? '100px'
                                    : windowSize.width > 1024 ? '100px'
                                        : '100px',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.VALUTA}</span>
                </>
            ),
        },
        {
            name: 'Kurs',
            selector: row => row.KURS,
            // sirina polja po rezolucijama
            width:
                windowSize.width > 2048 ? '1590px'
                    : windowSize.width > 1920 ? '1500px'
                        : windowSize.width > 1680 ? '970px'
                            : windowSize.width > 1600 ? '730px'
                                : windowSize.width > 1440 ? '650px'
                                    : windowSize.width > 1280 ? '500px'
                                        : windowSize.width > 1024 ? '600px'
                                            : '350px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.KURS}</span>
                </>
            ),
        },
        {
            name: 'Action',
            //    width: '1250px',
            right: 'boolean',
            cell: (row) => (
                // klikom na dugme Edit, aktivira se funkcija onEdit 
                // klikom na dugme Brisanje, aktivira se funkcija onDelete
                <>
                    <span onClick={() => onEdit(row)} id='media-query-table-buttons' className='btn btn-primary nav flex-column flex-lg-row justify-content-end'>Edit</span>{''}
                    <span onClick={() => { onEdit(row); setModalDeleteItem(true) }} id='media-query-table-buttons' className='btn btn-danger nav flex-column flex-lg-row justify-content-end'>Brisanje</span>
                </>

            ),

        }
    ];

    // funkcija za pretragu iz mreze
    // salje zahtjev na bekend prema upitu sa parametrima koje posaljemo
    // u responsu vraca podatke koje dobio iz upita 
    const searchChange = (e) => {
        e.preventDefault();
        const searchInput = e.target.value
        setSearchInput(searchInput);
        const fetchData = async () => {
            const res = await axios.get(`${API}/valuta/query/${form.companyCode}?q=${searchInput}`, { headers: { "Authorization": `Bearer ${token}` } });
            setValuta(res.data);
        };
        // uslov za za slanje zahtjeva za povlacenje podataka
        if (searchInput.length === 0 || searchInput.length >= 1) fetchData();

    };

    return (

        <div>
            {/* Kontejner za naslov  */}
            <div className="title-container">
                {/* Naziv registra */}

                <header className="title-header">
                    <h1 id="media-query-title">Valuta</h1>
                </header>
                {/* Klasa za ikonice, nalazi se u istom redu kao i naziv, ikonice su formirane kao lista */}

                <div className="naslov-ikonice">
                    <ul className="nav flex-column flex-lg-row justify-content-end">

                        <li className="nav-item toolbaritem">
                            <input type="image" alt="save" className='dugme-sacuvaj' title='Sačuvaj' tabIndex="3" src={require('./../assets/images/floppydisk.png')} onFocus={() => onSubmit()} />
                        </li>

                        <li className="nav-item toolbaritem">
                            <input type="image" alt="print" className='dugme-stampa' title='Štampa' tabIndex="4" src={require('./../assets/images/printer.png')} onClick={onPrint} />
                        </li>

                    </ul>
                </div>

            </div>
            {/* Kontejner pune sirine */}
            <Container fluid>
                {/* postavljanje novog reda */}
                <Row>
                    <div className='products-form'>
                        <Row>
                            {/* Stilovi koji ce se primjenjivati na svim poljima */}
                            <div className="col-6" style={{ display: 'flex', marginBottom: '0px', width: '60%' }}>
                                {/* Sirina polja */}
                                <div className="col-1">

                                    <div className={errorInputValuta ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px', marginBottom: '0px' }} >
                                        <label id="media-query-label">Valuta:</label>
                                        <input type="text"
                                            id="media-query-input-text"
                                            tabIndex="1"
                                            autoFocus={true}
                                            maxLength="3"
                                            placeholder="Valuta"
                                            name='VALUTA'
                                            value={form?.VALUTA}
                                            ref={valutaRef}
                                            // Prilikom prelaska na drugo polje, aktivira se stepenasta kontrola
                                            onFocus={() => {
                                                unosIspravanDo("VALUTA")
                                            }}
                                            // Ako je izmjena na polju
                                            onChange={(e) => handleChange(e)} ></input>
                                        {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                                        {errorMsgValuta ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgValuta}</span> : ''}
                                    </div>

                                </div>
                                {/* Sirina polja */}
                                <div className="col-2">
                                    <div className='col-form-right'>
                                        <div className={errorInputKurs ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px' }} >
                                            {/* Provjera da li je polje popunjeno jer je obavezno */}
                                            <label id="media-query-label">Kurs:</label>

                                            <NumericFormat
                                                type="text"
                                                className='field-alignment p-1'
                                                getInputRef={kursRef}
                                                decimalScale={6}
                                                maxLength="10"
                                                id="media-query-input-text"

                                                tabIndex="2"
                                                placeholder="Kurs"
                                                name='KURS'
                                                value={form ? form.KURS : ''}
                                                // Postavlja stejt za fokus na true jer je potrebno zbog stepenaste kontrole
                                                onFocus={() => {
                                                    unosIspravanDo("KURS")
                                                    // uslov za citanje sloga po sifri
                                                    let selectedItem = []
                                                    selectedItem = valuta.filter((c) => c.VALUTA == form.VALUTA)
                                                    if (selectedItem.length > 0) {
                                                        onEdit(selectedItem[0])
                                                    }
                                                }}
                                                // Ako je izmjena na polju
                                                onChange={(e) => handleChange(e)} />
                                            {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                                            {errorMsgKurs ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgKurs}</span> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </div>
                </Row>
            </Container>
            {/* Stilovi i klase za polje za pretragu;  */}
            <div style={{ display: 'flex' }} className='search'>
                {/* vrsta inputa; poziva funkciju za pretragu(searchChange);  */}
                <input type='text' tabIndex='-1' id="media-query-input-text" placeholder='Pretraga...' onChange={(e) => {
                    // pretraga mreze(ukoliko je unesen bilo koji karakter, vrsi pretragu)
                    if (searchInput.length >= 0) {
                        searchChange(e)
                    } else {
                        getTableData()
                    }
                }} value={searchInput} />

            </div>
            {/* Mreza */}
            <DataTable
                columns={columns}
                data={valuta ? valuta : []}
                pagination
                paginationServer
                responsive
                highlightOnHover
                striped
                dense
                pointerOnHover
                paginationComponentOptions={defaultComponentOptions}
                paginationPerPage={[windowSize.height > 1300 ? 28
                    : windowSize.height > 1270 ? 26
                        : windowSize.height > 1222 ? 25
                            : windowSize.height > 1200 ? 24
                                : windowSize.height > 1000 ? 20
                                    : windowSize.height > 920 ? 17
                                        : windowSize.height > 900 ? 16
                                            : windowSize.height > 880 ? 15
                                                : windowSize.height > 820 ? 14
                                                    : windowSize.height > 720 ? 13
                                                        : 4]}
            />

            <div>
                <Modal isOpen={Boolean(modalErrorOpenData)} centered  >
                    <ModalHeader>Nema podataka!</ModalHeader>
                    <ModalFooter className="justify-content-center">
                        <div>
                            {/* Dugme za zatvaranje modalnog forma */}
                            <button onClick={() => { setModalErrorOpenData(false); kursRef.current.focus() }} className="button-yes">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            {/* render za poruku */}
            <div>
                <Modal isOpen={Boolean(modalDeleteItem)} centered>
                    <ModalHeader>Brisanje stavke</ModalHeader>
                    <ModalBody>
                        <span>{`Da li želite da obrišete valutu ${form.VALUTA} ? `}</span>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            <button
                                onClick={() => { setModalDeleteItem(false); kursRef.current.focus() }}
                                className="button-no">Ne
                            </button>
                            <button
                                onClick={() => {
                                    onDelete(form.VALUTA);
                                    setModalDeleteItem(false)
                                }}
                                className="button-yes">Da
                            </button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            {/* render za poruku */}
            <div>
                <Modal isOpen={Boolean(modalErrorAuthorizationData)} centered  >
                    <ModalHeader>Upozorenje!</ModalHeader>
                    <ModalBody>
                        <span>{`Nemate ovlašćenje za brisanje!`}</span>
                    </ModalBody>
                    <ModalFooter className="justify-content-center">
                        <div>
                            <button onClick={() => { setModalErrorAuthorizationOpen(false); kursRef.current.focus() }} className="button-yes">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            <div>
                {spinner ? <Spinner className='spinner' color="primary" id='loader' /> : ''}
            </div>
        </div>
    );

}

export default Valuta;


