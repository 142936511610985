import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import DataTable from 'react-data-table-component';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { API } from '../../constants';
import { RadioButton } from '../radioButton';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Spinner } from "reactstrap";
import { numberWithCommas, numberWithCommasColumns, roundTO, getCompanyData, defaultComponentOptions } from '../utilities';
import { useWindowSize } from '../useWindowSize';

function PrikazPoVrstiKnjizenja() {
    const windowSize = useWindowSize();
    const [prikazPoVrstiKnjizenja, setPrikazPoVrstiKnjizenja] = useState(null);
    const [sum, setSum] = useState(0)
    const [sumduguje, setSumDuguje] = useState(false)
    const [sumpotraz, setSumPotraz] = useState(false)
    const [clients, setClients] = useState(null);
    const [konto, setKonto] = useState(null);
    const [kontoDo, setKontoDo] = useState(null);
    const [vrsta, setVrsta] = useState(null);
    const [selectDataVrsta, setSelectDataVrsta] = useState([]);
    const [selectDataClients, setSelectDataClients] = useState([]);
    const [selectDataKonto, setSelectDataKonto] = useState([]);
    const [spinner, setSpinner] = useState(false);
    const [modalErrorOpenPrint, setModalErrorOpenPrint] = useState(false);
    const [pending, setPending] = React.useState(false);

    // stejtovi za spinere na dugmadima
    const [faSpinV, setFaSpinV] = useState(false);
    const [faSpinTV, setFaSpinTV] = useState(false);
    const [faSpinVK, setFaSpinVK] = useState(false);
    const [faSpinTVK, setFaSpinTVK] = useState(false);

    const [errorDateFrom, setErrorDateFrom] = useState(false);
    const [errorDateTo, setErrorDateTo] = useState(false);

    const [errorMsgDateFrom, setErrorMsgDateFrom] = useState(false);
    const [errorMsgDateTo, setErrorMsgDateTo] = useState(false);

    const vrstaRef = useRef();
    const komtRef = useRef();
    const kontoOdRef = useRef();
    const kontoDoRef = useRef();
    const datumOdRef = useRef();
    const datumDoRef = useRef();
    const inputRef = useRef();

    const [date, setDate] = useState({
        checkDatDok: true,
        checkDatNalog: false
    });

    const [form, setForm] = useState({
        VRSTA: '',
        KOMTID: '',
        KONTOOD: '',
        KONTODO: '',
        DATUMOD: '',
        DATUMDO: '',
        PROVJERA: '',
        buttonType: '',
    })

    // funkcija za povlacenje podataka o preduzecu
    getCompanyData(form)

    // funkcija za popunjavanje datumskih polja u zavisnosti od godine
    const checkCompanyYear = () => {
        //  let dateFrom
        //  let dateTo
        let currentDate = new Date()
        if (currentDate.getFullYear() === Number(form.companyYear)) {
            setForm(prev => ({ ...prev, DATUMOD: moment.unix(new Date(currentDate.getFullYear(), 0, 1).getTime() / 1000).format("DD.MM.YYYY"), DATUMDO: moment.unix(new Date().getTime() / 1000).format("DD.MM.YYYY") }))
        } else {
            let oldYear = new Date()
            oldYear = new Date(Number(oldYear.setFullYear(form.companyYear)))
            setForm(prev => ({ ...prev, DATUMOD: moment.unix(new Date(oldYear.getFullYear(), 0, 1).getTime() / 1000).format("DD.MM.YYYY"), DATUMDO: moment.unix(new Date(Number(oldYear.setFullYear(form.companyYear))).getTime() / 1000).format("DD.MM.YYYY") }))
        }
    }

    // funkcija koja se poziva pri prvoj inicijalizaciji
    // api za mrezu
    // u responsu puni mrezi
    const getReports = () => {
        if (form) {
            form.KOMTID = clients && clients.ID ? clients.ID : null;
            form.VRSTA = vrsta && vrsta.VRSTAD ? vrsta.VRSTAD : null;
            form.checkDatDok = date.checkDatDok;
            form.KONTOOD = konto && konto.KONTO ? konto.KONTO : form.KONTOOD;
            form.KONTODO = kontoDo && kontoDo.KONTO ? kontoDo.KONTO : form.KONTODO;
        }

        fetch(API + `/prikazPoVrstiKnjizenjaMreza/all/${form.companyCode}`, {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                'Authorization': `Bearer ${form.token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setPrikazPoVrstiKnjizenja(data.items)
                let DUGUJESum = 0;
                let POTRAZSum = 0;
                let SALDOSum = 0;
                // petlja za citanje podataka
                for (let i = 0; i < data.items.length; i++) {
                    DUGUJESum += data.items[i].DUGUJE
                    POTRAZSum += data.items[i].POTRAZ
                    SALDOSum += (data.items[i].DUGUJE) - (data.items[i].POTRAZ)
                }
                setSum(numberWithCommasColumns(roundTO(SALDOSum, 2)))
                setSumDuguje(numberWithCommasColumns(roundTO(DUGUJESum, 2)))
                setSumPotraz(numberWithCommasColumns(roundTO(POTRAZSum, 2)))
                setPending(false)
            })
    }

    const print = () => {
        setSpinner(true)
        // uslov: ako nije izabran datum od datum do i ako nije izabran izvjestaj(nije kliknuto na dugme za izvjestaj), nece otvoriti izvjestaj 
        if (!unosIspravanDo('PRINT')) return setSpinner(false)
        if (form.buttonType === "") return setSpinner(false), setModalErrorOpenPrint(true)
        //filter
        if (form) {
            form.NAZIV_PRED = form.companyName;
            form.KOMTID = clients && clients.ID ? clients.ID : null;
            form.KONTOOD = konto && konto.KONTO ? konto.KONTO : form.KONTOOD;
            form.KONTODO = kontoDo && kontoDo.KONTO ? kontoDo.KONTO : form.KONTODO;
            form.VRSTA = vrsta && vrsta.VRSTAD ? vrsta.VRSTAD : null;
            form.checkDatDok = date.checkDatDok;
        }

        // fetch za sql
        // u bodiju saljemo filter(form)
        // fetch(API + `/prikazPoVrstiKnjizenja/print/${form.companyCode}`, {
        //     method: 'POST',
        //     body: JSON.stringify({ form }),
        //     responseType: 'blob',
        //     headers: {
        //         'Authorization': `Bearer ${form.token}`,
        //         'Content-type': 'application/json; charset=UTF-8'
        //     },
        // })
        //     .then((response) => response.json())
        //     .then(data => {

        //         // fetch za crtanje izvjestaja
        //         // u bodiju saljemo sql koji je vec pokupio i form
        //         fetch(API + `/` + form.buttonType + `/print/${form.companyCode}`, {
        //             method: 'POST',
        //             body: JSON.stringify({ sql: data.sql, form: form }),
        //             responseType: 'blob',
        //             headers: {
        //                 'Authorization': `Bearer ${form.token}`,
        //                 'Content-type': 'application/json; charset=UTF-8'
        //             },
        //         })
        //             .then((response) => response.json())
        //             .then(data => {
        //                 window.open(API + data.link)
        //                 setSpinner(false)
        //                 // treba prebaciti fetch na dugme
        //                 //                       let link = API + data.link;
        //                 //                      res.status(200).send({ link })
        //                 // console.log(report, 'report')
        //                 //   console.log(data, 'data')
        //                 //   console.log(API, 'API', data.link, 'data.link')

        //                 // let link = '/uploads/' + name
        //                 // res.status(200).send({ link })
        //             })
        //         //   fetch(API + `/pregledPoVrstiIKomitentu/print/${companyCode}`, {

        // //         //             fetch(API + `/prikazPoVrstiKnjizenja/print/${companyCode}`, {
        // //         //             method: 'POST',
        // //         //             body: JSON.stringify({ podaci: data.items }),
        // //         //             responseType: 'blob',
        // //         //             headers: {
        // //         //                 'Authorization': `Bearer ${token}`,
        // //         //                 'Access- Control - Allow - Origin': 'http://127.0.0.1:3005',
        // //         //                 'Access - Control - Allow - Methods': 'POST',
        // //         //                 //'Access- Control - Allow - Headers': 'Content - Type',
        // //         //                 'Content-type': 'application/json; charset=UTF-8'
        // //         //             },
        // //         //         }) 
        // //         //         .then((response) => response.json())
        // //         // .then(data => {
        // //         //     window.open(API + data.link)
        // //         // });
        //     });

        // fetch(API + `/pregledPoVrstiIKomitentu/print/${companyCode}`, {

        fetch(API + `/` + form.buttonType + `/print/${form.companyCode}`, {
            method: 'POST',
            body: JSON.stringify({ form }),
            responseType: 'blob',
            headers: {
                'Authorization': `Bearer ${form.token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                window.open(API + data.link)
                setSpinner(false)
            });
    }

    const getClientsData = () => {
      //  console.log('upada u getClientsData')
        fetch(API + `/komitenti/all/${form.companyCode}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${form.token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataClients(data)
            })
    }

    const getKontoData = () => {
        fetch(API + `/konto/all`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${form.token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataKonto(data)
            })
    }

    const getVrstaData = () => {
      //  console.log('upada u getVrstaData')
        fetch(API + `/dokument/all`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${form.token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataVrsta(data)
            })
    }

    useEffect(() => {
        getVrstaData();
        getClientsData();
        getKontoData();
    }, [])

    // cekiranje radio dugmadi u zavisnosti odabrane opcije
    const radioChangeHandler = (e) => {
        if (e.target.name === 'checkDatDok') {
            setDate({
                // odabir datuma dokumenata
                checkDatDok: true,
                checkDatNalog: false
            });
        } else {
            setDate({
                checkDatDok: false,
                // odabir datuma storniranja
                checkDatNalog: true
            });
        }
    };
    const handleChange = (event) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name;
        // postavlja sa funkcijom  setForm((prevState) mijejenja predhodno stanje sa novom vrijednoscu
        setForm((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    // funkcija za kontrolu obaveznih polja
    function unosIspravanDo(name) {
        if (name === 'VRSTA') {
            return true
        }

        if (name === 'KOMTID') {
            return true
        }

        if (name === 'KONTOOD') {
            return true
        }

        if (name === 'KONTODO') {
            return true
        }

        if (name === 'DATUMOD') {
            return true
        }

        let reqex = /^(0[1-9]|[12]\d|3[01]).(0[1-9]|1[0-2]).([12]\d{3})$/gi;

        if (!reqex.test(form.DATUMOD)) {
            setErrorDateFrom(true)
            setErrorMsgDateFrom('Datum od nije validan!')
            datumOdRef.current.focus();
            return false
        } else {
            setErrorDateFrom(false)
            setErrorMsgDateFrom(null)
        }

        if (name === 'DATUMDO') {
            return true
        }

        let reqexDATUMDO = /^(0[1-9]|[12]\d|3[01]).(0[1-9]|1[0-2]).([12]\d{3})$/gi;

        if (!reqexDATUMDO.test(form.DATUMDO)) {
            setErrorDateTo(true)
            setErrorMsgDateTo('Datum do nije validan!')
            datumDoRef.current.focus();
            return false
        } else {
            setErrorDateTo(false)
            setErrorMsgDateTo(null)
        }

        if (name === 'PROVJERA') {
            return true
        }

        return true
    }

    let sirinaZaKoloneVrijednosti = windowSize.width > 1920 ? '220px'
        : windowSize.width > 1680 ? '120px'
            : windowSize.width > 1600 ? '120px'
                : windowSize.width > 1440 ? '120px'
                    : windowSize.width > 1280 ? '120px'
                        : windowSize.width > 1024 ? '100px'
                            : '80px';

    // funkcija za popunjavanje kolona u mrezi
    function checkingFields() {
        let vrsta =
        {
            name: 'Vrsta',
            selector: row => row.VRSTA,
            // sirina polja po rezolucijama
            width:
            windowSize.width > 2048 ? '150px'
               : windowSize.width > 1920 ? '100px'
                    : windowSize.width > 1680 ? '100px'
                        : windowSize.width > 1600 ? '100px'
                            : windowSize.width > 1440 ? '75px'
                                : windowSize.width > 1280 ? '70px'
                                    : windowSize.width > 1024 ? '60px'
                                        : '80px',
                                        right: 'boolean',
                                        cell: (row) => (
                                            <>
                                                <span id='media-query-rows'>{row.VRSTA}</span>
                                            </>
                                        ),
        }
        let vrstaNaziv =
        {
            name: 'Naziv knjiženja',
            selector: row => row.NAZIV_KNJIZENJA,
            // sirina polja po rezolucijama
            width:
            windowSize.width > 2048 ? '1380px'
               : windowSize.width > 1920 ? '1000px'
                    : windowSize.width > 1680 ? '1000px'
                        : windowSize.width > 1600 ? '940px'
                            : windowSize.width > 1440 ? '890px'
                                : windowSize.width > 1280 ? '750px'
                                    : windowSize.width > 1024 ? '730px'
                                        : '175px',
                                        cell: (row) => (
                                            <>
                                                <span id='media-query-rows-text'>{row.NAZIV_KNJIZENJA}</span>
                                            </>
                                        ),
        }
        let duguje =
        {
            name: 'Duguje',
            selector: row => row.DUGUJE,
            width: sirinaZaKoloneVrijednosti,
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{numberWithCommasColumns(row.DUGUJE)}</span>
                </>
            ),
        }
        let potraz =
        {
            name: 'Potražuje',
            selector: row => row.POTRAZ,
            width: sirinaZaKoloneVrijednosti,
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{numberWithCommasColumns(row.POTRAZ)}</span>
                </>
            ),
        }
        let saldo =
        {
            name: 'Saldo',
            selector: row => row.SALDO,
            width: sirinaZaKoloneVrijednosti,
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{numberWithCommasColumns(row.SALDO)}</span>
                </>
            ),
        }
        if (form.buttonType.startsWith('totalPoVrstiIKomitentu')) {
            let sifra =
            {
                name: 'Šifra k.',
                selector: row => row.komtSifra,
                // sirina polja po rezolucijama
                width:
                windowSize.width > 2048 ? '200px'
                   : windowSize.width > 1920 ? '100px'
                        : windowSize.width > 1680 ? '150px'
                            : windowSize.width > 1600 ? '90px'
                                : windowSize.width > 1440 ? '100px'
                                    : windowSize.width > 1280 ? '80px'
                                        : windowSize.width > 1024 ? '80px'
                                            : '80px',
                                            right: 'boolean',
                                            cell: (row) => (
                                                <>
                                                    <span id='media-query-rows'>{row.komtSifra}</span>
                                                </>
                                            ),
                                            
            }
            let komtNaziv =
            {
                name: 'Naziv',
                selector: row => row.NAZIV_KOMITENTA,
                // sirina polja po rezolucijama
                width:
                windowSize.width > 2048 ? '1185px'
                  :  windowSize.width > 1920 ? '900px'
                        : windowSize.width > 1680 ? '890px'
                            : windowSize.width > 1600 ? '840px'
                                : windowSize.width > 1440 ? '800px'
                                    : windowSize.width > 1280 ? '660px'
                                        : windowSize.width > 1024 ? '630px'
                                            : '175px',
                                            cell: (row) => (
                                                <>
                                                    <span id='media-query-rows-text'>{row.NAZIV_KOMITENTA}</span>
                                                </>
                                            ),
            }
            return [vrsta, sifra, komtNaziv, duguje, potraz, saldo]
        }
        if (form.buttonType.startsWith('pregled')) {
            let nalog =
            {
                name: 'Nalog',
                selector: row => row.NALOG,
                // sirina polja po rezolucijama
                width:
                    windowSize.width > 1920 ? '100px'
                        : windowSize.width > 1680 ? '90px'
                            : windowSize.width > 1600 ? '100px'
                                : windowSize.width > 1440 ? '75px'
                                    : windowSize.width > 1280 ? '70px'
                                        : windowSize.width > 1024 ? '60px'
                                            : '80px',
                                            right: 'boolean',
                                            cell: (row) => (
                                                <>
                                                    <span id='media-query-rows'>{row.NALOG}</span>
                                                </>
                                            ),
            }
            let red =
            {
                name: 'Red',
                selector: row => row.RED,
                // sirina polja po rezolucijama
                width:
                    windowSize.width > 1920 ? '100px'
                        : windowSize.width > 1680 ? '90px'
                            : windowSize.width > 1600 ? '100px'
                                : windowSize.width > 1440 ? '80px'
                                    : windowSize.width > 1280 ? '70px'
                                        : windowSize.width > 1024 ? '80px'
                                            : '80px',
                                            right: 'boolean',
                                            cell: (row) => (
                                                <>
                                                    <span id='media-query-rows'>{row.RED}</span>
                                                </>
                                            ),
            }
            let datN =
            {
                name: 'Dat.Nal',
                selector: row => moment(row.DATN).format('DD.MM.YYYY'),
                // sirina polja po rezolucijama
                width:
                windowSize.width > 2048 ? '150px'
                  :  windowSize.width > 1920 ? '100px'
                        : windowSize.width > 1680 ? '110px'
                            : windowSize.width > 1600 ? '100px'
                                : windowSize.width > 1440 ? '100px'
                                    : windowSize.width > 1280 ? '95px'
                                        : windowSize.width > 1024 ? '80px'
                                            : '80px',
                                            cell: (row) => (
                                                <>
                                                    <span id='media-query-rows'>{moment(row.DATN).format('DD.MM.YYYY')}</span>
                                                </>
                                            ),
            }
            let datD =
            {
                name: 'Dat.Dok',
                selector: row => moment(row.DATD).format('DD.MM.YYYY'),
                // sirina polja po rezolucijama
                width:
                windowSize.width > 2048 ? '150px'
                   : windowSize.width > 1920 ? '100px'
                        : windowSize.width > 1680 ? '100px'
                            : windowSize.width > 1600 ? '100px'
                                : windowSize.width > 1440 ? '100px'
                                    : windowSize.width > 1280 ? '95px'
                                        : windowSize.width > 1024 ? '80px'
                                            : '80px',
                                            cell: (row) => (
                                                <>
                                                    <span id='media-query-rows'>{moment(row.DATD).format('DD.MM.YYYY')}</span>
                                                </>
                                            ),
            }
            let valuta =
            {
                name: 'Valuta',
                selector: row => moment(row.VALUTA).format('DD.MM.YYYY'),
                // sirina polja po rezolucijama
                width:
                windowSize.width > 2048 ? '150px'
                : windowSize.width > 1920 ? '100px'
                        : windowSize.width > 1680 ? '100px'
                            : windowSize.width > 1600 ? '100px'
                                : windowSize.width > 1440 ? '100px'
                                    : windowSize.width > 1280 ? '95px'
                                        : windowSize.width > 1024 ? '80px'
                                            : '80px',
                                            cell: (row) => (
                                                <>
                                                    <span id='media-query-rows'>{moment(row.VALUTA).format('DD.MM.YYYY')}</span>
                                                </>
                                            ),
            }
            let sifra =
            {
                name: 'Šifra k.',
                selector: row => row.komtSifra,
                // sirina polja po rezolucijama
                width:
                    windowSize.width > 1920 ? '100px'
                        : windowSize.width > 1680 ? '100px'
                            : windowSize.width > 1600 ? '100px'
                                : windowSize.width > 1440 ? '100px'
                                    : windowSize.width > 1280 ? '70px'
                                        : windowSize.width > 1024 ? '80px'
                                            : '80px',
                                            right: 'boolean',
                                            cell: (row) => (
                                                <>
                                                    <span id='media-query-rows'>{row.komtSifra}</span>
                                                </>
                                            ),
            }
            let konto =
            {
                name: 'KONTO',
                selector: row => row.KONTO,
                // sirina polja po rezolucijama
                width:
                    windowSize.width > 1920 ? '100px'
                        : windowSize.width > 1680 ? '100px'
                            : windowSize.width > 1600 ? '100px'
                                : windowSize.width > 1440 ? '90px'
                                    : windowSize.width > 1280 ? '70px'
                                        : windowSize.width > 1024 ? '80px'
                                            : '80px',
                                            cell: (row) => (
                                                <>
                                                    <span id='media-query-rows'>{row.KONTO}</span>
                                                </>
                                            ),
            }
            let opis =
            {
                name: 'Opis',
                selector: row => row.OPIS,
                // sirina polja po rezolucijama
                width:
                windowSize.width > 2048 ? '530px'
                   : windowSize.width > 1920 ? '300px'
                        : windowSize.width > 1680 ? '350px'
                            : windowSize.width > 1600 ? '250px'
                                : windowSize.width > 1440 ? '250px'
                                    : windowSize.width > 1280 ? '185px'
                                        : windowSize.width > 1024 ? '200px'
                                            : '175px',
                                            cell: (row) => (
                                                <>
                                                    <span id='media-query-rows-text'>{row.OPIS}</span>
                                                </>
                                            ),
            }
            return [vrsta, nalog, red, datN, datD, valuta, sifra, konto, opis, duguje, potraz, saldo,]
        } else {
            return [vrsta, vrstaNaziv, duguje, potraz, saldo]
        }
    }

    const defaultPropsClients = {
        options: selectDataClients,
        getOptionLabel: (option) => option ? `${option.SIFRA} ${option.NAZIV}` : '',
    };

    const defaultPropsKonto = {
        options: selectDataKonto,
        getOptionLabel: (option) => option ? `${option.KONTO} ${option.NAZ}` : '',
    };

    const defaultPropsVrsta = {
        options: selectDataVrsta,
        getOptionLabel: (option) => option !== null ? `${option.VRSTAD} ${option.OPISD}` : ''
    };

    // fokus pri renderu
    //   React.useEffect(() => {
    //     const timeout = setTimeout(() => {
    //         inputRef.current.focus();
    //     }, 100);
    //     return () => {
    //         clearTimeout(timeout);
    //     };
    // }, []);

    return (

        <div>
            {/* Kontejner za naslov  */}
            <div className="title-container">
                {/* Naziv forma */}
                <header className="title-header">
                    <h1 id="media-query-title">Prikaz po vrsti knjiženja</h1>
                </header>

                <div >
                    <div>
                        {/* Klasa za ikonice, stampa*/}
                        <ul >
                            <input type="image" alt="print" className='dugme-stampa' title='Štampa' tabIndex="11" src={require('../../assets/images/printer.png')} onClick={print} disabled={spinner} />

                        </ul>
                    </div>
                </div>
            </div>
            <Container fluid>
                <div className="row">
                    <Col lg='2'>
                        <div className='form-field-container' style={{ display: 'contents' }}>
                            {/* red sirine */}
                            <Col lg='12'>
                                {/* definsanje froma za radio dugmad */}
                                <div className="col-sm radio-box" id='media-query-radioButton-date'>
                                    <RadioButton
                                        changed={(e) => radioChangeHandler(e)}
                                        isSelected={date?.checkDatDok}
                                        label="Dat.dok."
                                        id="1"
                                        value={date?.checkDatDok}
                                        name="checkDatDok"
                                    />
                                    {/*  radio dugme za datum storniranja   */}
                                    <RadioButton
                                        changed={(e) => radioChangeHandler(e)}
                                        isSelected={date?.checkDatNalog}
                                        label="Dat.nal."
                                        id="2"
                                        value={date?.checkDatNalog}
                                        name="checkDatNalog"
                                    />
                                </div>
                            </Col>
                        </div>
                    </Col>


                    <Col lg='6'>

                        <div className="col-12" style={{ display: 'flex' }}>
                            <div className="col-12">
                                <div className="form-group row label-box-div">
                                    <label className="col-form-label col-lg-2 label-box" id="media-query-label-report">Vrsta:</label>
                                    <div className="col-8">
                                        <Autocomplete
                                            //  onClick={() => getVrstaData()}
                                            //ukoliko nema ove funkcije, u konzoli izbacuje upozorenje da nije vrijednost jedinstvena
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            {...defaultPropsVrsta}
                                            value={vrsta}
                                            disablePortal={true}
                                            onFocus={() => {
                                                unosIspravanDo("VRSTA")
                                            }}
                                            // ako se promijeni vrsta, uzima podatke za nov izabranu vrstu
                                            onChange={(event, newValue) => {
                                                setForm(prev => ({ ...prev, VRSTA: newValue && newValue != '' ? newValue.VRSTAD : '' }))
                                                setVrsta(newValue);
                                                // fokus se mora vratiti inace tabIndex ne radi dobro
                                                vrstaRef.current.focus()
                                            }}

                                            onSelect={() => getVrstaData()}

                                            renderInput={(params) => (
                                                <TextField {...params}
                                                    autoFocus
                                                    //  inputRef={inputRef}
                                                    tabIndex="1"

                                                    className="clients-textinput"
                                                    //   autoFocus
                                                    ref={vrstaRef}
                                                    placeholder=' Izaberite vrstu'
                                                    variant="standard" />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12" style={{ display: 'flex' }}>
                            <div className="col-12">
                                <div className="form-group row label-box-div">
                                    <label className="col-form-label col-lg-2 label-box" id="media-query-label-report">Komitent:</label>
                                    <div className="col-8">
                                        <Autocomplete
                                            //ukoliko nema ove funkcije, u konzoli izbacuje upozorenje da nije vrijednost jedinstvena
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            {...defaultPropsClients}
                                            value={clients}
                                            disablePortal={true}
                                            onFocus={() => {
                                                unosIspravanDo("KOMTID")
                                            }}
                                            onChange={(event, newValue) => {
                                                setForm(prev => ({ ...prev, KOMTID: newValue && newValue != '' ? newValue.ID : '' }))
                                                setClients(newValue);
                                                // fokus se mora vratiti inace tabIndex ne radi dobro
                                                komtRef.current.focus()
                                            }}
                                            //   onSelect={() => getClientsData()}

                                            renderInput={(params) => (
                                                <TextField {...params}
                                                    tabIndex="2"
                                                    // onClick={() => getClientsData()}
                                                    className="clients-textinput"
                                                    ref={komtRef}
                                                    placeholder=' Izaberite komitenta'
                                                    variant="standard"
                                                    // fokus se mora vratiti inace nece ici na polje dokument od
                                                    onFocus={() => {
                                                        komtRef.current.focus()
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12" style={{ display: 'flex', }}>
                            <div className="col-12">

                                <div className="form-group row label-box-div">
                                    <label className="col-form-label col-lg-2 label-box" id="media-query-label-report">Konto od:</label>
                                    <div className="col-3">
                                        <Autocomplete
                                            {...defaultPropsKonto}
                                            freeSolo
                                            value={konto}
                                            disablePortal={true}
                                            onFocus={() => {
                                                unosIspravanDo("KONTOOD")
                                            }}
                                            onChange={(event, newValue) => {
                                                setForm(prev => ({ ...prev, KONTOOD: newValue && newValue != '' ? newValue.ID : '' }))
                                                setKonto(newValue);
                                                // fokus se mora vratiti inace tabIndex ne radi dobro
                                                //  kontoOdRef.current.focus()
                                            }}

                                            renderInput={(params) => (
                                                <TextField {...params}
                                                    tabIndex="3"
                                                    //   onClick={() => getKontoData()}
                                                    className="clients-textinput"
                                                    ref={kontoOdRef}
                                                    value={form?.KONTOOD}
                                                    name='KONTOOD'
                                                    placeholder=' Izaberite konto'
                                                    variant="standard"
                                                    onChange={(e) => handleChange(e)}
                                                // fokus se mora vratiti inace nece ici na polje dokument od
                                                />
                                            )}
                                        />
                                    </div>

                                    <label className="col-form-label col-lg-2 label-box-do" id="media-query-label-DateTo">do:</label>
                                    <div className="col-3">
                                        <Autocomplete
                                            //ukoliko nema ove funkcije, u konzoli izbacuje upozorenje da nije vrijednost jedinstvena
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            {...defaultPropsKonto}
                                            freeSolo
                                            // autoselect
                                            value={kontoDo}
                                            disablePortal={true}
                                            onFocus={() => {
                                                unosIspravanDo("KONTODO")
                                            }}
                                            onChange={(event, newValue) => {
                                                setForm(prev => ({ ...prev, KONTDO: newValue && newValue != '' ? newValue.ID : '' }))
                                                setKontoDo(newValue);
                                                // fokus se mora vratiti inace tabIndex ne radi dobro
                                                kontoDoRef.current.focus()
                                            }}

                                            renderInput={(params) => (
                                                <TextField {...params}
                                                    tabIndex="4"
                                                    //  onClick={() => getKontoData()}
                                                    value={form?.KONTODO}
                                                    className="clients-textinput"
                                                    ref={kontoDoRef}
                                                    placeholder=' Izaberite konto'
                                                    name='KONTODO'
                                                    variant="standard"
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        // focus se mora vratiti na polje ako se upisuje vrijednost(ne uzima se se vrijednost iz liste)
                                                        const timeout = setTimeout(() => {
                                                            kontoDoRef.current.focus()
                                                        }, 200);
                                                        return () => {
                                                            clearTimeout(timeout);
                                                        };
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12" style={{ display: 'flex', }}>
                            <div className="col-12">

                                <div className="form-group row label-box-div">
                                    <label className="col-form-label col-lg-2 label-box" id="media-query-label-report">Datum od:</label>
                                    <div className="col-2">
                                        <div className={errorDateFrom ? 'inputWithError' : 'field-container'}>
                                            <input
                                                type='text'
                                                ref={datumOdRef}
                                                tabIndex="5"
                                                id="media-query-input"
                                                onFocus={() => {
                                                    unosIspravanDo("DATUMOD")
                                                    checkCompanyYear();
                                                }}
                                                placeholder="datum od"
                                                name='DATUMOD'
                                                value={form?.DATUMOD}
                                                onChange={(e) => handleChange(e)}
                                            />
                                            {errorMsgDateFrom ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgDateFrom}</span> : ''}
                                        </div>
                                    </div>

                                    <label className="col-form-label col-lg-3 label-box-do" id="media-query-label-DateTo">do:</label>
                                    <div className="col-2">
                                        <div className={errorDateTo ? 'inputWithError' : 'field-container'}>
                                            <input type='text'
                                                tabIndex="6"
                                                id="media-query-input-to"
                                                ref={datumDoRef}
                                                onFocus={() => {
                                                    unosIspravanDo("DATUMDO")
                                                }}
                                                placeholder="datum do"
                                                value={form?.DATUMDO}
                                                name='DATUMDO'
                                                onChange={(e) => handleChange(e)} />
                                            {errorMsgDateTo ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgDateTo}</span> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg='4'>
                        <Row>
                            <Col lg='12' >
                                <div className=" flex-lg-row justify-content-end" id='media-query-buttonType'>
                                    <div style={{ display: 'inline-grid' }}>
                                        <div className={faSpinV ? "btn-report-red" : "btn-report"}>
                                            <button tabIndex={7}
                                                onFocus={() => {
                                                    unosIspravanDo("PROVJERA")
                                                }}
                                                onClick={() => {
                                                    // uslov: ako nisu unesena obavezna polja, zaustavlja upis
                                                    if (!unosIspravanDo('DUGME')) return;
                                                    form.buttonType = 'pregledPoVrsti'
                                                    setFaSpinV(true)
                                                    setFaSpinVK(false)
                                                    setFaSpinTV(false)
                                                    setFaSpinTVK(false)
                                                    setPending(true)
                                                    getReports()
                                                }}>{faSpinV && !prikazPoVrstiKnjizenja ? <i className="fa fa-spinner fa-spin"></i> : ''} Pregled po vrsti knjiženja</button>
                                        </div>
                                        <div className={faSpinVK ? "btn-report-red" : "btn-report"}>
                                            <button tabIndex={8} onClick={() => {
                                                // uslov: ako nisu unesena obavezna polja, zaustavlja upis
                                                if (!unosIspravanDo('DUGME')) return;
                                                form.buttonType = 'pregledPoVrstiIKomitentu'
                                                setFaSpinV(false)
                                                setFaSpinVK(true)
                                                setFaSpinTV(false)
                                                setFaSpinTVK(false)
                                                setPending(true)
                                                getReports()
                                            }}>{faSpinVK && !prikazPoVrstiKnjizenja ? <i className="fa fa-spinner fa-spin"></i> : ''}Pregled po vrsti i komitentu</button>
                                        </div>
                                    </div>
                                    <div style={{ display: 'inline-grid' }}>
                                        <div className={faSpinTV ? "btn-report-red" : "btn-report"}>
                                            <button tabIndex={9} onClick={() => {
                                                // uslov: ako nisu unesena obavezna polja, zaustavlja upis
                                                if (!unosIspravanDo('DUGME')) return;
                                                form.buttonType = 'totalPoVrsti'
                                                setFaSpinTV(true)
                                                setFaSpinTVK(false)
                                                setFaSpinV(false)
                                                setFaSpinVK(false)
                                                setPending(true)
                                                getReports()
                                            }}>{faSpinTV && !prikazPoVrstiKnjizenja ? <i className="fa fa-spinner fa-spin"></i> : ''}Total po vrsti knjiženja</button>
                                        </div>
                                        <div className={faSpinTVK ? "btn-report-red" : "btn-report"}>
                                            <button tabIndex={10} onClick={() => {
                                                // uslov: ako nisu unesena obavezna polja, zaustavlja upis
                                                if (!unosIspravanDo('DUGME')) return;
                                                form.buttonType = 'totalPoVrstiIKomitentu'
                                                setFaSpinTVK(true)
                                                setFaSpinTV(false)
                                                setFaSpinV(false)
                                                setFaSpinVK(false)
                                                setPending(true)
                                                getReports()
                                            }}>{faSpinTVK && !prikazPoVrstiKnjizenja ? <i className="fa fa-spinner fa-spin"></i> : ''}Total po vrsti i komitentu</button>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </div>
            </Container >

            {
                < DataTable
                    columns={form.buttonType ? checkingFields() : []}
                    data={prikazPoVrstiKnjizenja ? prikazPoVrstiKnjizenja : []}
                    pagination
                    responsive
                    striped
                    dense
                    pointerOnHover
                    paginationComponentOptions={defaultComponentOptions}
                    paginationPerPage={[windowSize.height > 1300 ? 30
                        : windowSize.height > 1246 ? 27
                        : windowSize.height > 1222 ? 26
                        : windowSize.height > 1200 ? 25
                        : windowSize.height > 1000 ? 20
                        : windowSize.height > 920 ? 17
                        : windowSize.height > 900 ? 16
                        : windowSize.height > 880 ? 15
                        : windowSize.height > 820 ? 13
                        : windowSize.height > 720 ? 11
                                            : 4]}
                    progressPending={pending}
                />
            }

            <div className="naslov-ikonice">
                <ul className="nav flex-column flex-lg-row justify-content-end">
                    <div style={{ display: 'flex', justifyContent: 'end', position: 'fixed', bottom: '0', flex: '0 0 auto' }}>

                        <li className="nav-item toolbaritem" style={{ width: '23%', marginRight: '5px' }}>
                            <div className='col-form-right'>
                                <div className="field-container-number-totals">
                                    <label id="media-query-label-sum">Duguje</label>
                                    <input className="form-control" readOnly={true} tabIndex="-1" type='text' id="media-query-input-text" value={sumduguje && sumduguje != 'NaN' ? sumduguje : 0} />
                                </div>
                            </div>
                        </li>

                        <li className="nav-item toolbaritem" style={{ width: '23%', marginRight: '4px' }}>
                            <div className='col-form-right'>
                                <div className="field-container-number-totals">
                                    <label id="media-query-label-sum">Potražuje</label>
                                    <input className="form-control" readOnly={true} type='text' tabIndex="-1" id="media-query-input-text" value={sumpotraz && sumpotraz != 'NaN' ? sumpotraz : 0} />
                                </div>
                            </div>
                        </li>

                        <li className="nav-item toolbaritem" style={{ width: '23%', marginRight: '7px' }}>
                            <div className='col-form-right'>
                                <div className="field-container-number-totals">
                                    <label id="media-query-label-sum">Saldo</label>
                                    <input type='text' className="total" readOnly={true} id="media-query-input-text" tabIndex="-1" value={sum && sum != 'NaN' ? (sum) : 0} />
                                </div>
                            </div>
                        </li>
                    </div>
                </ul>
            </div>

            {/* Modalni form za gresku; prikazuje se ako nisu unijeta obavezna polja */}

            <div>
                <Modal isOpen={modalErrorOpenPrint} centered  >
                    <ModalHeader>Upozorenje!</ModalHeader>
                    <ModalBody>
                        <span>{`Izaberite izvještaj koji želite štampati!`}</span>
                    </ModalBody>
                    <ModalFooter className="justify-content-center">
                        <div>
                            {/* Dugme za zatvaranje modalnog forma */}
                            <button onClick={() => setModalErrorOpenPrint(false)} className="button-yes">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            {/* spiner ili progres bar ya prikaz pokretanja datog izvjestaja */}
            <div>
                {spinner ? <Spinner className='spinner' color="primary" id='loader' /> : ''}
            </div>
        </div >
    );
}

export default PrikazPoVrstiKnjizenja;


