// importovanje komponenti 
import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { API } from '../constants';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import axios from '../api/axios';
import { Spinner } from "reactstrap";
import { getCompanyData, defaultComponentOptions } from '../components/utilities';
import { NumericFormat } from 'react-number-format';
import { useWindowSize } from '../components/useWindowSize';

// komponenta koju kreiramo
function Skladista() {

    const windowSize = useWindowSize();

    // definisanje konstanti koje koristimo za fokusiranje polja
    const codeRef = useRef();
    const nameRef = useRef();
    const placeRef = useRef();
    const typeOfWarehouseRef = useRef();
    const addressRef = useRef();
    const phoneRef = useRef();

    // definisanje stejtova i prva inicijalizacija; objekat i funkcija za taj objekat
    const [warehouse, setWarehouse] = useState(null)
    const [perPage, setPerPage] = useState(10);
    const [searchInput, setSearchInput] = useState([]);
    // error row inputs
    const [errorInputCode, setErrorInputCode] = useState(false);
    const [errorInputName, setErrorInputName] = useState(false);
    const [errorInputTypeOfWarehouse, setErrorInputTypeOfWarehouse] = useState(false);
    // error messages inputs
    const [errorMsgCode, setErrorMsgCode] = useState(false);
    const [errorMsgName, setErrorMsgName] = useState(false);
    const [errorMsgTypeOfWarehouse, setErrorMsgTypeOfWarehouse] = useState(false);
    const [modalErrorOpen, setModalErrorOpen] = useState(false);
    const [modalErrorOpenData, setModalErrorOpenData] = useState(false);
    const [modalErrorAuthorizationData, setModalErrorAuthorizationOpen] = useState(false);
    const [modalDeleteItem, setModalDeleteItem] = useState(false);
    const [selectData, setSelectData] = useState([]);
    const [spinner, setSpinner] = useState(false);
    const [place, setPlace] = useState(null);
    const [pending, setPending] = React.useState(false);

    // povlacenje podataka iz lokal storidza
    const token = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : null

    // definisanje stejta(objekat i funkcija za taj objekat), prva inicijalizacija
    const [form, setForm] = useState({
        SIFRAP: '',
        SIFRA: '',
        NAZIV: '',
        VRSTA_SKLADISTA: '',
        ADRESA: '',
        MJESTO: '',
        TEL: '',
        OZNAKA: '',
        IDENTBROJ: '',
        PROSCENA: '',
        stavkaPronadjena: false
    })

    // funkcija za povlacenje podataka o preduzecu
    getCompanyData(form)

    // funkcija koja se poziva klikom na dugme 'Sacuvaj'
    const onSubmit = () => {

        // uslov: ako nisu unesena obavezna polja, zaustavlja upis
        if (!unosIspravanDo('UPIS')) return;

        // ide na upis sloga
        // poziva api za upis
        // osvezavanje polja, fokus na polje SIFRA, poziva funkciju getTableData(za upis u mrezu)         
        fetch(API + `/skladista/new/${form.companyCode}`, {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                response.json()
                setForm({
                    SIFRAP: '',
                    SIFRA: '',
                    NAZIV: '',
                    VRSTA_SKLADISTA: '',
                    ADRESA: '',
                    MJESTO: '',
                    TEL: '',
                    OZNAKA: '',
                    IDENTBROJ: '',
                    PROSCENA: '',
                    stavkaPronadjena: false
                })
                codeRef.current.focus()
                getTableData()
                setPlace(null)
            })
    }

    // funkcija koja se poziva pri prvoj inicijalizaciji
    // api za mrezu
    // u responsu puni mrezi(pomocu funkcije setIme) i puni ukupan broj stranica(setTotal)
    const getTableData = () => {
        fetch(API + `/skladista/all/${form.companyCode}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setWarehouse(data.result)
                setPending(false)
            })
    }

    // funkcija za pronalazenje mjesta i odabir postojeceg
    const getPlacesData = () => {
        fetch(API + `/mjesta/all`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectData(data)
            })
    }

    useEffect(() => {
        getPlacesData()
    }, [])

    // prilikom pocetne inicijalizacije postavlja prvi slobodan broj
    useEffect(() => {
        const timeout = setTimeout(() => {
            // codeRef.current.focus()
            getWarehouseFirstFreeNumberSifra()
            getTableData()
        }, 100);
        return () => {
            clearTimeout(timeout);
        };
    }, []);

    // funkcija za pronalazenje prvog slobodnog broja
    // poziva api
    // u responsu stejt setForm poziva funkciju za pronalazenje prethodne sifre(iz data) i na osnovu nje odredjuje koja je sljedeca prva slobodna sifra
    const getWarehouseFirstFreeNumberSifra = () => {
        fetch(API + `/skladista/firstFreeNumberSifra`, {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setForm(prev => ({ ...prev, SIFRA: Number(data[0].SIFRA) }))
            })
    }

    // funkcija za brisanje koja se poziva klikom na dugme 'Delete'
    //  api za brisanje
    // u responsu osvjezava polja, daje prvi slobodan broj za sifru, fokus na polje sifra, osvjezava mrezu  
    const onDelete = (ID) => {
        // provjera ovlascenja za brisanje 
        if (form.ovlfakt == 4) return setModalErrorAuthorizationOpen(true)
        fetch(API + '/skladista/delete/' + ID, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                response.json()
                setForm({
                    SIFRA: '',
                    NAZIV: '',
                    VRSTA_SKLADISTA: '',
                    ADRESA: '',
                    MJESTO: '',
                    TEL: '',
                    OZNAKA: '',
                    IDENTBROJ: '',
                    PROSCENA: '',
                    stavkaPronadjena: false
                })
                setPlace(null)
                codeRef.current.focus()
                getTableData()
            })
    }

    // funkcija koja se poziva klikom na dugme Edit
    // postavlja fokus na polje NAZIV 
    // popunjava polja podacima iz mreze
    const onEdit = (item) => {
        setPlace(null)
        item.stavkaPronadjena = true
        selectData.map((place) => {
            if (place.SIFRA == item.MJESTO) {
                setPlace(place)
            }
        })

        setForm(item)
        const timeout = setTimeout(() => {
            nameRef.current.focus()
        }, 100);
        return () => {
            clearTimeout(timeout);
        };
    }

    // funkcija koja se poziva prilikom promjene vrijednosti u inputu
    const handleChange = (event) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name;

        setForm((prevState) => ({
            ...prevState,
            [name]: value
        }));

    };

    // funkcija koja se poziva klikom na dugme 'Stampa'
    const onPrint = () => {
        setSpinner(true)
        if (warehouse && warehouse.length === 0) {
            setSpinner(false)
            setModalErrorOpenData(true)
        } else {

            // uslov preko koga saljemo naziv preduzeca sa frontenda na bekend
            // ako je objekat form popunjen, napuni polje NAZIV_PRED iz lokal storidza
            // poziva api za stampu, otvara izvjestaj(API + data.link)
            if (form) {
                form.NAZIV_PRED = form.companyName
            }
            fetch(API + `/skladista/print`, {
                method: 'POST',
                body: JSON.stringify(form),
                responseType: 'blob',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-type': 'application/json; charset=UTF-8'
                },
            })
                .then((response) => response.json())
                .then(data => {
                    if (typeof window != 'undefined') {
                        setTimeout(() => {
                            window.open(API + data.link)
                        }, 200);
                    }
                    setSpinner(false)
                })
        }
    }

    // uslov: ako nije upisana vrijednost za sifru, izbaci poruku, setuje crveni okvir i fokusira polje  
    // u suprotnom prolazi dalje
    const checkCodeRequired = (value) => {
        if (!value || value == 0) {
            setErrorMsgCode('Šifra je obavezna!')
            setErrorInputCode(true)
            codeRef.current.focus()
            return false
        } else {
            setErrorMsgCode(null)
            setErrorInputCode(false)
            return true
        }
    }

    // uslov: ako nije upisana vrijednost za naziv, izbaci poruku, setuje crveni okvir i fokusira polje  
    // u suprotnom prolazi dalje
    const checkNameRequired = (value) => {
        if (!value || value.length == 0) {
            setErrorMsgName('Naziv je obavezan!')
            setErrorInputName(true)
            nameRef.current.focus();
            return false
        } else {
            setErrorMsgName(null)
            setErrorInputName(false)
            return true
        }
    }

    // uslov: ako nije upisana vrijednost za vrstu skladista, izbaci poruku, setuje crveni okvir i fokusira polje  
    // u suprotnom prolazi dalje
    const checkTypeOfWarehouseRequired = (value) => {
        if (!value || value.length == 0) {
            setErrorMsgTypeOfWarehouse('Vrsta skladišta je obavezna!')
            setErrorInputTypeOfWarehouse(true)
            typeOfWarehouseRef.current.focus()
            return false
        } else {
            setErrorMsgTypeOfWarehouse(null)
            setErrorInputTypeOfWarehouse(false)
            return true
        }
    }

    // funkcija za kontrolu obaveznih polja
    function unosIspravanDo(name) {
        if (name === 'SIFRA') {
            return true
        }

        if (!checkCodeRequired(form.SIFRA)) {
            return false
        }

        if (name === 'NAZIV') {
            return true
        }

        if (!checkNameRequired(form.NAZIV)) {
            return false
        }

        if (name === 'VRSTA_SKLADISTA') {
            return true
        }

        if (!checkTypeOfWarehouseRequired(form.VRSTA_SKLADISTA)) {
            return false
        }

        if (name === 'ADRESA') {
            return true
        }

        if (name === 'MJESTO') {
            return true
        }

        if (name === 'TEL') {
            return true
        }

        if (name === 'OZNAKA') {
            return true
        }

        if (name === 'IDENTBROJ') {
            return true
        }

        if (name === 'PROSCENA') {
            return true
        }

        return true
    }

    // definisu se polja u tabeli i popunjavaju
    const columns = [
        {
            name: 'Šifra',
            selector: row => row.SIFRA,
            // sirina polja po rezolucijama
            width: '100px',
            sortable: true,
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.SIFRA}</span>
                </>
            ),
        },
        {
            name: 'Naziv',
            selector: row => row.NAZIV,
            // sirina polja po rezolucijama
            width:
            windowSize.width > 2048 ? '1350px'
                : windowSize.width > 1920 ? '1000px'
                    : windowSize.width > 1680 ? '800px'
                        : windowSize.width > 1600 ? '600px'
                            : windowSize.width > 1440 ? '580px'
                                : windowSize.width > 1280 ? '600px'
                                    : windowSize.width > 1024 ? '600px'
                                        : '550px',
            sortable: true,
            cell: (row) => (
                <>
                    <span id='media-query-rows-text'>{row.NAZIV}</span>
                </>
            ),
        },
        {
            name: 'Vrsta',
            selector: row => row.VRSTA_SKLADISTA,
            // sirina polja po rezolucijama
            width:
            windowSize.width > 2048 ? '600px'
               : windowSize.width > 1920 ? '500px'
                    : windowSize.width > 1680 ? '450px'
                        : windowSize.width > 1600 ? '550px'
                            : windowSize.width > 1440 ? '500px'
                                : windowSize.width > 1280 ? '320px'
                                    : windowSize.width > 1024 ? '230px'
                                        : '250px',
            sortable: true,
            cell: (row) => (
                <>
                    <span id='media-query-rows-text'>{row.VRSTA_SKLADISTA}</span>
                </>
            ),
        },
        {
            name: 'Action',
            right: 'boolean',
            cell: (row) => (
                < div className='tableCells nav flex-column  flex-lg-row'>
                    <span onClick={() => onEdit(row)} id='media-query-table-buttons' className='btn btn-primary nav flex-column flex-lg-row justify-content-end'>Edit</span>{'     '}
                    <span onClick={() => { onEdit(row); setModalDeleteItem(true) }} id='media-query-table-buttons' className='btn btn-danger nav flex-column flex-lg-row justify-content-end'>Brisanje</span>
                </div>
            ),
        }
    ];

    // propsovi za auto komplit  - mjesto
    const defaultProps = {
        options: selectData,
        getOptionLabel: (option) => option ? `${option.SIFRA} ${option.NAZIV}` : '',
    };

    // funkcija za pretragu iz mreze
    // salje zahtjev na bekend prema upitu sa parametrima koje posaljemo
    // u responsu vraca podatke koje dobio iz upita 
    const searchChange = (e) => {
        e.preventDefault();
        const searchInput = e.target.value
        setSearchInput(searchInput);
        const fetchData = async () => {
            const res = await axios.get(`${API}/skladista/query/${form.companyCode}?q=${searchInput}`, { headers: { "Authorization": `Bearer ${token}` } });
            setWarehouse(res.data);
        };

        // uslov za za slanje zahtjeva za povlacenje podataka
        if (searchInput.length === 0 || searchInput.length >= 1) fetchData();
    };

    return (

        <div>
            {/* Kontejner za naslov  */}
            <div className="title-container">

                <header className="title-header">
                    <h1 id="media-query-title">Skladišta</h1>
                </header>
                {/* Klasa za ikonice, nalazi se u istom redu kao i naziv, ikonice su formirane kao lista */}

                <div className="naslov-ikonice">
                    <ul className="nav flex-column flex-lg-row justify-content-end">

                        <li className="nav-item toolbaritem">
                            <input type="image" alt="save" className='dugme-sacuvaj' title='Sačuvaj' tabIndex="10" src={require('./../assets/images/floppydisk.png')} onFocus={() => onSubmit()} />
                        </li>

                        <li className="nav-item toolbaritem">
                            <input type="image" alt="print" className='dugme-stampa' title='Štampa' tabIndex="11" src={require('./../assets/images/printer.png')} onClick={onPrint} />
                        </li>
                    </ul>
                </div>
            </div>

            {/* Kontejner pune sirine */}
            <Container fluid>
                <Row>
                    <div className='products-form'>
                        <Row>
                            {/* Stilovi koji ce se primjenjivati na svim poljima */}
                            <div className="col-6" style={{ display: 'flex', paddingRight: '0px', paddingLeft: '-1px', marginRight: 'auto', marginLeft: '0px' }}>
                                {/* Sirina polja */}
                                <div className="col-1">
                                    <div className='col-form-right'>
                                        {/* Provjera da li je polje popunjeno jer je obavezno */}
                                        <div className={errorInputCode ? 'inputWithError' : 'field-container-number'} style={{ marginRight: '3px' }} >
                                            <label id="media-query-label">Šifra:</label>
                                            <NumericFormat
                                                type="text"
                                                className='field-alignment p-1'
                                                getInputRef={codeRef}
                                                maxLength="10"
                                                decimalScale={0}
                                                id="media-query-input-text"
                                                tabIndex="1"
                                                autoComplete='off'
                                                autoFocus={true}
                                                placeholder="šifra"
                                                name='SIFRA'
                                                value={form ? form.SIFRA : ''}
                                                onFocus={() => {
                                                    getWarehouseFirstFreeNumberSifra()
                                                    unosIspravanDo("SIFRA")
                                                }}
                                                // Ako je izmjena na polju
                                                onChange={(e) => handleChange(e)}
                                                onClick={() => {
                                                    // ako je odradjen dvoklik na stavku u mrezi, a zatim na sifru - cisti polja  
                                                    setForm(prev => ({
                                                        ...prev,
                                                        SIFRA: '',
                                                        NAZIV: '',
                                                        VRSTA_SKLADISTA: '',
                                                        ADRESA: '',
                                                        MJESTO: '',
                                                        TEL: '',
                                                        OZNAKA: '',
                                                        IDENTBROJ: '',
                                                        PROSCENA: '',
                                                        stavkaPronadjena: false
                                                    }))
                                                    setPlace(null)
                                                }} />
                                            {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                                            {errorMsgCode ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgCode}</span> : ''}
                                        </div>
                                    </div>
                                </div>

                                {/* Sirina polja */}
                                <div className="col-10">
                                    {/* Provjera da li je polje popunjeno jer je obavezno */}
                                    <div className={errorInputName ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px' }} >
                                        <label id="media-query-label">Naziv:</label>
                                        <input type="text"
                                            id="media-query-input-text"
                                            maxLength="70"
                                            tabIndex="2"
                                            autoComplete='off'
                                            placeholder="naziv"
                                            name='NAZIV'
                                            value={form ? form.NAZIV : ''}
                                            ref={nameRef}
                                            onFocus={() => {
                                                unosIspravanDo("NAZIV")
                                                // uslov za citanje sloga po sifri
                                                if (unosIspravanDo("NAZIV")) {
                                                    let selectedItem = []
                                                    selectedItem = warehouse.filter((c) => c.SIFRA == form.SIFRA)
                                                    if (selectedItem.length > 0) {
                                                        onEdit(selectedItem[0])
                                                    }
                                                }
                                            }}
                                            // Ako je izmjena na polju
                                            onChange={(e) => handleChange(e)} />
                                        {errorMsgName ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgName}</span> : ''}
                                    </div>
                                </div>

                                {/* Sirina polja */}
                                <div className="col-1">
                                    {/* Provjera da li je polje popunjeno jer je obavezno */}
                                    <div className={errorInputTypeOfWarehouse ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px' }} >
                                        <label style={{ marginLeft: '0px' }} id="media-query-label">Vrsta:</label>
                                        <input type="text"
                                            id="media-query-input-text"
                                            maxLength="1"
                                            tabIndex="3"
                                            autoComplete='off'
                                            placeholder="vrsta skladišta"
                                            // Ako je izmjena na polju
                                            name='VRSTA_SKLADISTA'
                                            value={form ? form.VRSTA_SKLADISTA : ''}
                                            ref={typeOfWarehouseRef}
                                            onFocus={() => {
                                                unosIspravanDo("VRSTA_SKLADISTA")
                                            }}
                                            // Ako je izmjena na polju
                                            onChange={(e) => handleChange(e)} />
                                        {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                                        {errorMsgTypeOfWarehouse ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgTypeOfWarehouse}</span> : ''}
                                    </div>
                                </div>
                            </div>

                            <div className="col-6" style={{ display: 'flex', paddingRight: '0px', paddingLeft: '0px', marginRight: 'auto', marginLeft: '0px' }}>
                                {/* Sirina polja */}
                                <div className="col-6">
                                    {/* Provjera da li je polje popunjeno jer je obavezno */}
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label id="media-query-label">Adresa:</label>
                                        <input type="text" id="media-query-input-text" maxLength="30" tabIndex="4" autoComplete='off' placeholder="adresa" name='ADRESA' value={form ? form.ADRESA : ''}
                                            onFocus={() => {
                                                unosIspravanDo("ADRESA")
                                            }}
                                            onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>

                                {/* Sirina polja */}
                                <div className="col-6">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <div className='field-container' style={{ marginRight: '3px', marginBottom: '0px' }} >
                                            <label id="media-query-label">Mjesto:</label>
                                            {/* Ako je Autocomplete  poziva na odabir  mjesta*/}
                                            <Autocomplete
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                {...defaultProps}
                                                value={place}
                                                onFocus={() => {
                                                    unosIspravanDo("MJESTO")
                                                }}
                                                // Ako je izmjena na polju
                                                onChange={(event, newValue) => {
                                                    setForm(prev => ({ ...prev, MJESTO: newValue && newValue != null ? newValue.SIFRA : '' }))
                                                    setPlace(newValue);
                                                    // fokus se mora vratiti inace tabIndex ne radi dobro
                                                    placeRef.current.focus();
                                                }}

                                                renderInput={(params) => (
                                                    <TextField {...params}
                                                        className="clients-textinput"
                                                        name='MJESTO'
                                                        ref={placeRef}
                                                        tabIndex="5"
                                                        placeholder=' Izaberite mjesto'
                                                        variant="standard" />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Row>

                        <Row>
                            {/* Sirina reda */}
                            <div className="col-5" style={{ display: 'flex', paddingRight: '0px', paddingLeft: '-1px', marginRight: 'auto', marginLeft: '0px' }}>

                                {/* Sirina polja */}
                                <div className="col-5">
                                    {/* Stilovi koji ce se primjenjivati na svim poljima */}
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label id="media-query-label">Telefon:</label>
                                        <input ref={phoneRef} type="text" id="media-query-input-text" maxLength="20" tabIndex="6" placeholder="telefon" name='TEL' value={form ? form.TEL : ''}
                                            onFocus={() => {
                                                unosIspravanDo("TEL")
                                            }}
                                            onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>

                                {/* Sirina polja */}
                                <div className="col-1">
                                    {/* Provjera da li je polje popunjeno jer je obavezno */}
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label style={{ marginLeft: '0px' }} id="media-query-label">Ozn:</label>
                                        <input type="text" id="media-query-input-text" maxLength="1" tabIndex="7" placeholder="oznaka" name='OZNAKA' value={form ? form.OZNAKA : ''}
                                            onFocus={() => {
                                                unosIspravanDo("OZNAKA")
                                            }}
                                            onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>

                                <div className="col-3">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label id="media-query-label">Id. broj:</label>
                                        <input type="text" id="media-query-input-text" maxLength="20" tabIndex="8" placeholder="ident. broj" name='IDENTBROJ' value={form ? form.IDENTBROJ : ''}
                                            onFocus={() => {
                                                unosIspravanDo("IDENTBROJ")
                                            }}
                                            onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>

                                <div className="col-3">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label id="media-query-label">P. cijena:</label>
                                        <select
                                            id="media-query-input-text"
                                            value={form?.PROSCENA}
                                            name='PROSCENA'
                                            className='select-style'
                                            placeholder="izaberite porez"
                                            onChange={(e) => handleChange(e)}
                                            tabIndex="9"
                                            onFocus={() => {
                                                unosIspravanDo("PROSCENA")
                                            }}>
                                            <option value={''}>{''}</option>
                                            <option value={'D'}>{'Da'}</option>
                                            <option value={'N'}>{'Ne'}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </div>
                </Row >

            </Container >
            {/* Stilovi i klase za polje za pretragu;  */}
            <div style={{ display: 'flex' }} className='search'>
                {/* vrsta inputa; poziva funkciju za pretragu(searchChange);  */}
                <input type='text' id="media-query-input-text" placeholder='Pretraga...'
                    onChange={(e) => {
                        // pretraga mreze(ukoliko je unesen bilo koji karakter, vrsi pretragu)
                        if (searchInput.length >= 0) {
                            searchChange(e)
                        } else {
                            getTableData()
                        }
                    }} value={searchInput} />
            </div>

            {/* Mreza */}
            <DataTable
                columns={columns}
                data={warehouse ? warehouse : []}
                pagination
                responsive
                highlightOnHover
                striped
                dense
                pointerOnHover
                paginationComponentOptions={defaultComponentOptions}
                paginationPerPage={[windowSize.height > 1300 ? 25
                    : windowSize.height > 1270 ? 25
                    : windowSize.height > 1246 ? 24
                    : windowSize.height > 1222 ? 23
                    : windowSize.height > 1200 ? 23
                    : windowSize.height > 1000 ? 19
                    : windowSize.height > 920 ? 16
                    : windowSize.height > 900 ? 15
                    : windowSize.height > 880 ? 14
                    : windowSize.height > 820 ? 13
                    : windowSize.height > 720 ? 11
                                        : 4]}
                progressPending={pending}
            />

            {/* render za poruku */}
            <div>
                <Modal isOpen={Boolean(modalDeleteItem)} centered>
                    <ModalHeader>Brisanje stavke</ModalHeader>
                    <ModalBody>
                        <span>{`Da li želite da obrišete stavku broj ${form.SIFRA} ? `}</span>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            <button
                                onClick={() => { setModalDeleteItem(false); nameRef.current.focus() }}
                                className="button-no">Ne
                            </button>
                            <button
                                onClick={() => {
                                    onDelete(form.ID);
                                    setModalDeleteItem(false)
                                }}
                                className="button-yes">Da
                            </button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            {/* render za poruku */}
            <div>
                <Modal isOpen={Boolean(modalErrorAuthorizationData)} centered  >
                    <ModalHeader>Upozorenje!</ModalHeader>
                    <ModalBody>
                        <span>{`Nemate ovlašćenje za brisanje!`}</span>
                    </ModalBody>
                    <ModalFooter className="justify-content-center">
                        <div>
                            <button onClick={() => setModalErrorAuthorizationOpen(false)} className="button-yes">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            {/* Modalni form za gresku; prikazuje se ako nisu unijeta obavezna polja */}
            <div>
                <Modal isOpen={Boolean(modalErrorOpen)} centered  >
                    <ModalHeader>Neispravan unos</ModalHeader>
                    <ModalBody>
                        <span>{`Unesite obavezna polja!`}</span>
                    </ModalBody>
                    <ModalFooter className="justify-content-center">
                        <div>
                            {/* Dugme za zatvaranje modalnog forma */}
                            <button onClick={() => setModalErrorOpen(false)} className="button-yes">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            <div>
                <Modal isOpen={Boolean(modalErrorOpenData)} centered  >
                    <ModalHeader>Nema podataka!</ModalHeader>
                    <ModalFooter className="justify-content-center">
                        <div>
                            {/* Dugme za zatvaranje modalnog forma */}
                            <button onClick={() => setModalErrorOpenData(false)} className="button-yes">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            <div>
                {spinner ? <Spinner className='spinner' color="primary" id='loader' /> : ''}
            </div>

        </div>
    );

}

export default Skladista;




