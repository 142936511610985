import React, { useState, useEffect } from 'react';
import './style/global.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './components/home';
import Konto from './registri/konto';
import VrstaDok from './registri/vrstaDok';
import Login from './components/login';
import 'bootstrap/dist/css/bootstrap.min.css';
import Sidebar from './components/sidebar';
import Navbar from './components/navbar';
import OrganizacioneJedinice from './registri/organizacioneJedinice';
import Drzave from './registri/drzave';
import Blagajna from './components/blagajna/blagajna';
import VrstaKnjizenja from './registri/blagdok';
import Regije from './registri/regije';
import Komitenti from './registri/komitenti';
import Preduzeca from './registri/preduzeca';
import Mjesta from './registri/mjesta';
import Putnici from './registri/putnici';
import Porezi from './registri/porezi';
import Napomene from './registri/napomene';
import Skladista from './registri/skladista';
import Ambalaza from './registri/ambalaza';
import Artikal from './registri/artikal';
// import BarKod from './registri/barKod';
//import Lager from './components/robno/lager';
import Brend from './registri/brend';
import Grupa from './registri/grupa';
import Opza from './registri/opza';
import Podgrupa from './registri/podgrupa';
import Poslovna from './registri/poslovna';
import Predracun from './components/robno/predracun';
import Kalkulacija from './components/robno/kalkulacija';
import Ulaz from './components/robno/ulaz';
import IzlazFakturisanje from './components/robno/izlaz';
import Nivelacija from './components/robno/nivelacija';
import Kasa from './components/robno/kasa';
import Smjena from './registri/smjena';
import Trosak from './registri/trosak';
import Valuta from './registri/valuta';
import Vozila from './registri/vozila';
import Usluge from './registri/usluge';
import UslugeZaKomitente from './registri/uslugeZaKomitente';
import UnosFakture from './components/fakturisanje/unosFakture';
import IzvjestajiFakturisanje from './components/fakturisanje/izvjestajiFakturisanje';
import UnosNaloga from './components/finansije/unosNaloga';
import Dokument from './registri/dokument';
import BlagajnaReport from './components/blagajna/blagajnaReport';
import IzvjestajiPoBrojuNaloga from './components/finansije/izvjestajiPoBrojuNaloga';
import AnalitikaIBilans from './components/finansije/analitikaIBilans';
import BrutoBilansSintetike from './components/finansije/brutoBilansSintetike';
import PrikazPoVrstiKnjizenja from './components/finansije/prikazPoVrstiKnjizenja';
import PregledPoVrstiIKomitentu from './components/finansije/pregledPoVrstiIKomitentu';
import AnalitikaPoKomitentimaIKontima from './components/finansije/analitikaPoKomitentimaIKontima';
import User from './components/user';
//import Dropdown from './components/dropdown';
import Layout from './components/layout';
import Missing from './components/missing';
import Unauthorized from './components/unauthorized';
import RequireAuth from './components/requireAuth';
import '../src/components/utilities';
import './registri/pregledPoDokumentima';
const ROLES = {
  'User': 3,
  'Admin': 4,
  'SuperAdmin': 99
}

function App() {
   // povlacenje podataka iz session storidza
   const isUserLoggedIn = typeof window !== 'undefined' ? sessionStorage.getItem('isUserLogged') : null;

 //  provjera da li je zapoceta sesija(da li je user logovan)
 //  ako nije, ide na login 
   if(isUserLoggedIn === null) {
     localStorage.clear();
     sessionStorage.clear();
   }
//   const windowWidthW = typeof window !== "undefined" ? window.innerWidth : 0
// const windowHeightH = typeof window !== "undefined" ? window.innerHeight : 0

  //  const [windowWidth, setWindowWidth] = useState(0)
  //  const [windowHeight, setWindowHeight] = useState(0)
  
//  const componentDidMount = () => {
//     window.addEventListener("resize", windowWidthW, windowHeightH);

//     setWindowWidth(windowWidth)
//     setWindowHeight(windowHeight)   
//  }
// //  console.log(windowWidthW, 'windowWidth', windowHeightH, 'windowHeight' );
//   const componentWillUnmount = () => {
//     window.removeEventListener("resize", windowWidth, windowHeight);
//   }

//kontrola(osluskivac) da li je kliknuto na zatvaranje taba ili brausera 
//uslov: ako ima user, aktivira se useEffect
useEffect(() => {
  if(isUserLoggedIn !== null){
  const handleTabClose = event => {
    event.preventDefault();      
  //  return (event.returnValue ='Are you sure you want to exit?');
  };

  window.addEventListener('beforeunload', handleTabClose)

  return () => {
    window.removeEventListener('beforeunload', handleTabClose);
  };
}
}, []);


  return (
    <>
      <BrowserRouter>
        <div>
          <Routes>
            <Route path='/login' element={<Login />} />
            <Route path="unauthorized" element={<Unauthorized />} />
            <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
              <Route element= {<Layout />}>
                <Route path="/" element={<Home />} />
                <Route path='/konto' element={<Konto />} />
                <Route path='/vrstaDok' element={<VrstaDok />} />
                <Route path='/organizacioneJedinice' element={<OrganizacioneJedinice />} />
                <Route path='/blagajna' element={<Blagajna />} />
                <Route path='/blagdok' element={<VrstaKnjizenja />} />
                <Route path='/drzave' element={<Drzave />} />
                <Route path='/regije' element={<Regije />} />
                <Route path='/komitenti' element={<Komitenti />} />
                <Route path='/preduzeca' element={<Preduzeca />} />
                <Route path='/mjesta' element={<Mjesta />} />
                <Route path='/putnici' element={<Putnici />} />
                <Route path='/porezi' element={<Porezi />} />
                <Route path='/napomene' element={<Napomene />} />
                <Route path='/skladista' element={<Skladista />} />
                <Route path='/ambalaza' element={<Ambalaza />} />
                <Route path='/artikal' element={<Artikal />} />
                {/* <Route path='/barKod' element={<BarKod />} /> */}
                {/* <Route path='/lager' element={<Lager />} /> */}
                <Route path='/brend' element={<Brend />} />
                <Route path='/grupa' element={<Grupa />} />
                <Route path='/opza' element={<Opza />} />
                <Route path='/podgrupa' element={<Podgrupa />} />
                <Route path='/poslovna' element={<Poslovna />} />
                <Route path='/predracun' element={<Predracun />} />
                <Route path='/ulaz' element={<Ulaz />} />
                <Route path='/izlaz' element={<IzlazFakturisanje />} />
                <Route path='/nivelacija' element={<Nivelacija />} />
                <Route path='/kasa' element={<Kasa />} />
                <Route path='/kalkulacija' element={<Kalkulacija />} />
                <Route path='/smjena' element={<Smjena />} />
                <Route path='/trosak' element={<Trosak />} />
                <Route path='/valuta' element={<Valuta />} />
                <Route path='/vozila' element={<Vozila />} />
                <Route path='/usluge' element={<Usluge />} />
                <Route path='/uslugeZaKomitente' element={<UslugeZaKomitente />} />
                <Route path='/unosFakture' element={<UnosFakture />} />
                <Route path='/izvjestajiFakturisanje' element={<IzvjestajiFakturisanje />} />
                <Route path='/blagajnaReport' element={<BlagajnaReport />} />
                <Route path='/analitikaIBilans' element={<AnalitikaIBilans />} />
                <Route path='/brutoBilansSintetike' element={<BrutoBilansSintetike />} />
                <Route path='/prikazPoVrstiKnjizenja' element={<PrikazPoVrstiKnjizenja />} />
                <Route path='/pregledPoVrstiIKomitentu' element={<PregledPoVrstiIKomitentu />} />
                <Route path='/analitikaPoKomitentimaIKontima' element={<AnalitikaPoKomitentimaIKontima />} />
                <Route path='/izvjestajiPoBrojuNaloga' element={<IzvjestajiPoBrojuNaloga />} />
                <Route path='/unosNaloga' element={<UnosNaloga />} />
                <Route path='/dokument' element={<Dokument />} />
                <Route path='/user' element={<User />} />
                {/* <Route path='/dropdown' element={<Dropdown />} /> */}

              </Route>
            </Route>

            {/* catch all */}
            <Route path="*" element={<Missing />} />
          </Routes>
        </div>

      </BrowserRouter>
    </>
  );
}

export default App;


