import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import DataTable from 'react-data-table-component';
import exportIcon from '../assets/export.svg';
import saveIcon from '../assets/save.svg';
import printIcon from '../assets/print.svg';
import SVG from 'react-inlinesvg';
import { API } from '../constants';
import axios from '../api/axios';
import { Spinner } from "reactstrap";

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

function UslugeZaKomitente() {

    const warehouseRef = useRef();
    const clientRef = useRef();
    const serialNumberRef = useRef();
    const clientsRef = useRef()
    const serviceRef = useRef()
    const repositoryRef = useRef();
    const rbrRef = useRef()
    const descriptionRef = useRef()
    let inputRef;

    const [serviceChargeForClients, setServiceChargeForClients] = useState(null)
    const [clients, setClients] = useState(null);
    const [total, setTotal] = useState(0)
    const [perPage, setPerPage] = useState(10);
    const [repository, setRepository] = useState(null)
    const [selectData, setSelectData] = useState([]);
    const [selectDataClients, setSelectDataClients] = useState([]);
    const [searchInput, setSearchInput] = useState([]);

    const [errorInputWarehouse, setErrorInputWarehouse] = useState(false);
    const [errorInputClient, setErrorInputClient] = useState(false);
    const [errorInputSerialNumber, setErrorInputSerialNumber] = useState(false);
    const [errorInputService, setErrorInputService] = useState(false);
    const [errorMsgWarehouse, setErrorMsgWarehouse] = useState(false);
    const [errorMsgClient, setErrorMsgClient] = useState(false);
    const [errorMsgSerialNumber, setErrorMsgSerialNumber] = useState(false);
    const [errorMsgService, setErrorMsgService] = useState(false);
    const [errorInputClients, setErrorInputClients] = useState(false)
    const [errorMsgClients, setErrorMsgClients] = useState(false)
    const [errorInputRepository, setErrorInputRepository] = useState(false);
    const [errorMsgRepository, setErrorMsgRepository] = useState(false);
    const [errorInputRbr, setErrorInputRbr] = useState(false)
    const [errorMsgRbr, setErrorMsgRbr] = useState(false)

    const [repositoryFocus, setRepositoryFocus] = useState(true);
    const [clientFocus, setClientFocus] = useState(false);
    const [serialNumberFocus, setSerialNumberFocus] = useState(false);
    const [serviceFocus, setServiceFocus] = useState(false);
    const [rbrFocus, setRbrFocus] = useState(false);
    const [descriptionFocus, setDescriptionFocus] = useState(false);

    const [selectDataService, setSelectDataService] = useState([]);
    const [service, setService] = useState(null);
    const [editServiceChangeForClients, setEditServiceChangeForClients] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalErrorOpen, setModalErrorOpen] = useState(false);
    const [modalErrorOpenData, setModalErrorOpenData] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const token = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : null
    const companyCode = typeof window !== 'undefined' ? localStorage.getItem('company') : null


    const defaultComponentOptions = {
        rowsPerPageText: 'Redovi po stranici:',
        rangeSeparatorText: 'od',
        noRowsPerPage: false,
        selectAllRowsItem: false,
        selectAllRowsItemText: 'Sve',
    };

    const [form, setForm] = useState({
        SKLAID: '',
        KOMTID: '',
        RBR: '',
        USLUGA: '',
        OPIS: '',
        KOL: ''

    })

    const onSubmit = () => {

        if (form.SKLAID == " " || form.SKLAID == 0 || form.KOMTID == " " || form.KOMTID == 0 || form.RBR == " " || form.RBR == 0 || form.USLUGA == " " || form.USLUGA == 0) {
            setModalErrorOpen(true)
        } else {
            if (editServiceChangeForClients) {
                fetch(API + '/uslugeZaKomitente/update', {
                    method: 'POST',
                    body: JSON.stringify(form),
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    },
                })
                    .then((response) => {
                        if (response && response.status == 200) {
                            response.json()
                            setForm({
                                RBR: '',
                                OPIS: '',
                                KOL: ''
                            })
                            setClients(null)
                            rbrRef.current.focus()
                            setService(null)
                            getTableData(1, perPage)
                        }
                    })
            } else {
                fetch(API + '/uslugeZaKomitente/new', {
                    method: 'POST',
                    body: JSON.stringify(form),
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    },
                })
                    .then((response) => {
                        response.json()
                        setForm({
                            RBR: '',
                            OPIS: '',
                            KOL: ''
                        })
                        rbrRef.current.focus()
                        setService(null)
                        getTableData(1, perPage)
                    })

            }
        }
    }

    const getTableData = (page, limit) => {
        fetch(API + `/uslugeZaKomitente/all/${page}/${limit}`, {
            method: 'POST',
            body: JSON.stringify({ SKLAID: repository && repository.ID ? repository.ID : '' }),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setServiceChargeForClients(data.result)
                setTotal(data.total)

            })
    }




    //     fetch(API + `/serviceChargeForClients/print`, {
    //         method: 'POST',
    //         body: JSON.stringify(form),
    //         headers: {
    //             'Authorization': `Bearer ${token}`,
    //             'Content-type': 'application/json; charset=UTF-8'
    //         },
    //     })
    //    .then((response) => response.json())
    //         .then(data => {
    //             console.log(data, 'DATA');
    //             if (typeof window != 'undefined') {
    //                 setTimeout(() => {
    //                     console.log(API + data.link, 'link');
    //                     window.open(API + data.link)
    //                 }, 200);

    //             }
    //         })



    const OnServicesAndClients = () => {
        if (form) {
            form.SKLAID = repository && repository.ID ? repository.ID : null
            form.KOMTID = clients && clients.ID ? clients.ID : null
            form.USLUGA = service && service.SIFRA ? service.SIFRA : null
        }
        fetch(API + `/uslugeZaKomitente/onServicesAndClients/`, {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                if (typeof window != 'undefined') {
                    setTimeout(() => {
                        //   console.log(API + data.link, 'link');
                        window.open(API + data.link)
                    }, 1000);

                }
            })
    }


    const onClientsAndServices = () => {
        if (form) {
            form.SKLAID = repository && repository.ID ? repository.ID : null
            form.KOMTID = clients && clients.ID ? clients.ID : null
            form.USLUGA = service && service.SIFRA ? service.SIFRA : null
        }
        fetch(API + `/uslugeZaKomitente/onClientsAndServices`, {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                if (typeof window != 'undefined') {
                    setTimeout(() => {
                        window.open(API + data.link)
                    }, 1000);

                }
            })
    }
    const onRegistersOfClients = () => {
        fetch(API + `/uslugeZaKomitente/onRegistersOfClients`, {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                if (typeof window != 'undefined') {
                    setTimeout(() => {
                        window.open(API + data.link)
                    }, 1000);

                }
            })
    }
    const getRepositoryData = () => {
        fetch(API + `/skladista/all/${companyCode}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectData(data)

            })
    }

    const getClientsData = () => {
        fetch(API + `/komitenti/all/${companyCode}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataClients(data)
            })
    }

    const getServiceData = () => {
        fetch(API + `/usluge/all`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataService(data)
            })
    }


    const getFirstFreeNumberRbr = (repository, client) => {
        fetch(API + `/uslugeZaKomitente/firstFreeNumberRbr`, {
            method: 'POST',
            body: JSON.stringify({ SKLAID: repository, KOMTID: client }),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setForm(prev => ({ ...prev, RBR: Number(data) }))
            })
    }

    useEffect(() => {
        getRepositoryData()
        inputRef.focus();
        getClientsData()
        getServiceData()
    }, [perPage])

    useEffect(() => {
        getTableData(1, perPage)

    }, [repository])

    useEffect(() => {
        inputRef.focus();
    })

    const onDelete = (komtid, rbr) => {
        fetch(API + `/uslugeZaKomitente/delete/${komtid}/${rbr}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                response.json()
                rbrRef.current.focus()
                getTableData(1, perPage)
            })
    }

    const onEdit = (item) => {
        // inputRef.current.focus()
        selectDataClients.map((client) => {
            if (client.ID == item.KOMTID) {
                setClients(client)
            }
        })
        selectDataService.map((service) => {
            if (service.SIFRA == item.USLUGA) {
                setService(service)
            }
        })
        setForm(item)
        setEditServiceChangeForClients(true)

    }

    const handleChange = (event) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name;

        setForm((prevState) => ({
            ...prevState,
            [name]: value
        }));

    };

    const defaultProps = {
        options: selectData,
        getOptionLabel: (option) => option ? `${option.SIFRA} ${option.NAZIV}` : '',
    };

    const defaultPropsClients = {
        options: selectDataClients,
        getOptionLabel: (option) => option ? `${option.SIFRA} ${option.NAZIV}` : '',
    };

    const defaultPropsService = {
        options: selectDataService,
        getOptionLabel: (option) => option ? `${option.SIFRA} ${option.NAZIV}` : '',
    };

    const checkRepositoryRequired = (value) => {
        if (value && !repository || !value && !repository) {
            setErrorMsgRepository('Skladiste mora biti izabrano')
            setErrorInputRepository(true)
            inputRef.focus();
        } else {
            setErrorMsgRepository(null)
            setErrorInputRepository(false)
        }
    }

    const checkClientsRequired = (value) => {
        if (value && !clients || !value && !clients) {
            setErrorMsgClients('Komitent mora biti izabran')
            setErrorInputClients(true)
            clientRef.current.focus();

        } else {
            setErrorMsgClients(null)
            setErrorInputClients(false)
        }
    }

    const checkServiceRequired = (value) => {
        if (value && !service || !value && !service) {
            setErrorMsgService('Usluga mora biti izabrana')
            setErrorInputService(true)
            serviceRef.current.focus();
        } else {
            setErrorMsgService(null)
            setErrorInputService(false)
        }
    }

    const checkRbrRequired = (value) => {
        if (!value || value == 0) {
            setErrorMsgRbr('Rbr je obavezan')
            setErrorInputRbr(true)
            rbrRef.current.focus();
        } else {
            setErrorMsgRbr(null)
            setErrorInputRbr(false)
        }
    }


    // stepenasta
    const formCheck = (event) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name;

        switch (name) {
            case 'SKLADISTE': {
                checkRepositoryRequired(value)
                break;
            }
            case 'KOMITENT': {
                checkRepositoryRequired(form.SKLAID)
                checkClientsRequired(value)
                break;
            }
            case 'RBR': {
                checkRepositoryRequired(form.SKLAID)
                checkClientsRequired(form.KOMTID)
                checkRbrRequired(value)
                break;
            }
            case 'USLUGA': {
                checkRepositoryRequired(form.SKLAID)
                checkClientsRequired(form.KOMTID)
                checkRbrRequired(form.RBR)
                checkServiceRequired(value)
                break;
            }

            case 'OPIS': {
                checkRepositoryRequired(form.SKLAID)
                checkClientsRequired(form.KOMTID)
                checkRbrRequired(form.RBR)
                checkServiceRequired(form.USLUGA)
                break
            }

        }


        if (name === 'SKLADISTE') {
            if (errorInputRepository) {
                setRepositoryFocus(false)
                inputRef.focus()
            } else {
                setClientFocus(true)
            }

        }

        if (name === 'KOMITENT') {
            if (errorInputRepository) {
                setRepositoryFocus(true)
                inputRef.current.focus()
            } else {
                setClientFocus(false)
                setRbrFocus(true)
                rbrRef.current.focus()
            }

        }

        if (name === 'RBR') {
            if (errorInputClients) {
                setClientFocus(true)
                clientRef.current.focus()
            } else {
                setRbrFocus(false)
                serviceRef.current.focus()
            }

        }

        if (name === 'USLUGA') {
            if (errorInputRbr) {
                setRbrFocus(true)
                rbrRef.current.focus()
            } else {
                setServiceFocus(false)
                descriptionRef.current.focus()
            }

        }

        if (name === 'OPIS') {
            if (errorInputService) {
                setServiceFocus(true)
                serviceRef.current.focus()
            } else {
                setDescriptionFocus(false)

            }

        }

    }

    const columns = [
        {
            name: 'Šifra',
            selector: row => row.komtSIFRA,
            width: '100px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.komtSIFRA}</span>
                </>
            ),
        },
        {
            name: 'Naziv',
            selector: row => row.komtNAZIV,
            width: '500px',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.komtNAZIV}</span>
                </>
            ),
        },
        {
            name: 'Rbr',
            selector: row => row.RBR,
            width: '100px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.RBR}</span>
                </>
            ),
        },
        {
            name: 'Usluga',
            selector: row => row.USLUGA,
            width: '100px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.USLUGA}</span>
                </>
            ),
        },
        {
            name: 'Naziv usluge',
            selector: row => row.uslugaNAZIV,
            width: '450px',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.uslugaNAZIV}</span>
                </>
            ),
        },
        {
            name: 'Cijena',
            selector: row => row.uslugaCENA,
            right: 'boolean',
            width: '100px',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.uslugaCENA}</span>
                </>
            ),
        },
        {
            name: 'Količina',
            selector: row => row.KOL,
            width: '200px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.KOL}</span>
                </>
            ),
        },
        {
            name: 'Action',
            cell: (row) => (
                <>
                    <span onClick={() => onEdit(row)} id='media-query-table-buttons' className='btn btn-primary nav flex-column flex-lg-row justify-content-end'>Edit</span>{''}
                    <span onClick={() => onDelete(row.KOMTID, row.RBR)} id='media-query-table-buttons' className='btn btn-danger  nav flex-column flex-lg-row justify-content-end'>Brisanje</span>
                </>

            ),

        }
    ];


    const handlePageChange = page => {
        getTableData(page, perPage)
    }

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
    }



    const searchChange = (e) => {
        e.preventDefault();
        setSearchInput(e.target.value);
        const fetchData = async () => {
            const res = await axios.get(`${API}/uslugeZaKomitente/query/${companyCode}?q=${searchInput}`, { headers: { "Authorization": `Bearer ${token}` } });
            setServiceChargeForClients(res.data);
        };
        if (searchInput.length === 0 || searchInput.length > 2) fetchData();

    };
    // console.log(searchChange);

    const handleClear = () => {
        setSearchInput('');
        getTableData(1, perPage)
    }

    return (

        <div>
            <div className="title-container">

                <header className="title-header">
                    <h2 id="media-query-title">Usluge za komitente</h2>
                </header>


                <div className="naslov-ikonice">
                    <ul className="nav flex-column flex-lg-row justify-content-end">

                        <li class="nav-item toolbaritem">
                            <input type="image" alt="save" className='dugme-sacuvaj' title='Sačuvaj' tabIndex="7" src={require('./../assets/images/floppydisk.png')} onFocus={() => onSubmit()} />
                        </li>

                        <li class="nav-item toolbaritem">
                            <input type="image" alt="print" className='dugme-stampa' title='Štampa' tabIndex="8" src={require('./../assets/images/printer.png')} onClick={setModalOpen} />
                        </li>

                    </ul>
                </div>

            </div>
            <Container fluid>
                <Row>
                    <div className='products-form'>
                        <Row>
                            <div className="col-6" style={{ display: 'flex', paddingRight: '0px', paddingLeft: '-1px', marginRight: 'auto', marginLeft: '0px' }}>
                                <div className="col-5">
                                    <div className={errorInputRepository ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px', marginBottom: '0px' }} >
                                        <label id="media-query-label">Skladište:</label>
                                        <Autocomplete
                                            {...defaultProps}
                                            value={repository}
                                            disablePortal={true}
                                            onChange={(event, newValue) => {
                                                setForm(prev => ({ ...prev, SKLAID: newValue && newValue != null ? newValue.ID : null }))
                                                setRepository(newValue);
                                            }}

                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    className="clients-textinput"
                                                    name='SKLADISTE'
                                                    tabIndex="1"
                                                    onFocus={() => setRepositoryFocus(true)}
                                                    // ref={repositoryRef}
                                                    inputRef={input => {
                                                        inputRef = input;
                                                    }}
                                                    onBlur={(e) => formCheck(e)}
                                                    placeholder=' Izaberite skladište'
                                                    variant="standard" />
                                            )}
                                        />
                                        {errorMsgRepository ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgRepository}</span> : ''}
                                    </div>
                                </div>


                                <div className="col-6">
                                    <div className={errorInputClients ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px' }} >
                                        <label id="media-query-label">Komitent:</label>
                                        <Autocomplete
                                            {...defaultPropsClients}
                                            value={clients}
                                            disablePortal={true}
                                            onChange={(event, newValue) => {
                                                setForm(prev => ({ ...prev, KOMTID: newValue && newValue != null ? newValue.ID : null }))
                                                setClients(newValue)
                                                getFirstFreeNumberRbr(repository.ID, newValue.ID)
                                            }}

                                            renderInput={(params) => (
                                                <TextField {...params}
                                                    ref={clientRef}
                                                    name='KOMITENT'
                                                    onFocus={() => setClientFocus(true)}
                                                    className="clients-textinput"
                                                    tabIndex="2"
                                                    placeholder=' Izaberite komitenta'
                                                    variant="standard"
                                                    onBlur={(e) => formCheck(e)} />
                                            )}
                                        />
                                        {errorMsgClients ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgClients}</span> : ''}

                                    </div>
                                </div>

                                <div className="col-1">
                                    <div className='col-form-right'>
                                        <div className={errorInputRbr ? 'inputWithError' : 'field-container-number'} style={{ marginRight: '3px' }} >
                                            <label id="media-query-label">Rbr.:</label>
                                        </div>
                                        <input type="number"
                                            id="media-query-input-text"
                                            tabIndex="3"
                                            placeholder="rbr"
                                            name='RBR'
                                            value={form ? form.RBR : ''}
                                            ref={rbrRef}
                                            onFocus={() => setSerialNumberFocus(true)}
                                            onBlur={(e) => formCheck(e)}
                                            onChange={(e) => handleChange(e)} />
                                        {errorMsgRbr ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgRbr}</span> : ''}

                                    </div>
                                </div>
                            </div>
                            <div className="col-6" style={{ display: 'flex', paddingRight: '0px', paddingLeft: '0px', marginRight: 'auto', marginLeft: '0px' }}>
                            <div className="col-5">
                                <div className={errorInputService ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px' }} >
                                    <label id="media-query-label">Usluga:</label>
                                    <Autocomplete
                                        {...defaultPropsService}
                                        value={service}
                                        disablePortal={true}
                                        onChange={(event, newValue) => {
                                            if (newValue == null) {
                                                setForm(prev => ({ ...prev, USLUGA: '' }))
                                            } else {
                                                setForm(prev => ({ ...prev, USLUGA: newValue && newValue != null ? newValue.SIFRA : null }))
                                                setService(newValue);
                                            }
                                        }}

                                        renderInput={(params) => (
                                            <TextField {...params}
                                                tabIndex="4"
                                                ref={serviceRef}
                                                name='USLUGA'
                                                onFocus={() => setServiceFocus(true)}
                                                className="clients-textinput"
                                                placeholder=' Izaberite uslugu'
                                                variant="standard"
                                                onBlur={(e) => formCheck(e)}
                                            />
                                        )}
                                    />
                                    {errorMsgService ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgService}</span> : ''}
                                </div>
                            </div>

                            <div className="col-5">
                                <div className='field-container' style={{ marginRight: '3px' }}>
                                    <label id="media-query-label">Ugovor br.:</label>
                                    <input
                                        type="text"
                                        id="media-query-input-text"
                                        maxLength="100"
                                        tabIndex="5"
                                        placeholder="ugovor br."
                                        name='OPIS'
                                        ref={descriptionRef}
                                        onFocus={() => setDescriptionFocus(true)}
                                        value={form ? form.OPIS : ''}
                                        onChange={(e) => handleChange(e)}
                                        onBlur={(e) => formCheck(e)}
                                    />
                                </div>
                            </div>

                            <div className="col-2">
                                <div className='col-form-right'>
                                    <div className='field-container-number' style={{ marginRight: '3px' }}>
                                        <label id="media-query-label">Količina:</label>
                                    </div>
                                    <input
                                        type="number"
                                        id="media-query-input-text"
                                        tabIndex="6"
                                        placeholder="količina"
                                        name='KOL'
                                        value={form ? form.KOL : ''}
                                        onChange={(e) => handleChange(e)}
                                    />
                                </div>
                            </div>
                    </div>
                </Row>

        </div>
                </Row >
            </Container >

            <div style={{ display: 'flex' }} className='search'>
                <input type='text'id="media-query-input-text" placeholder='Pretraga...' onChange={(e) => searchChange(e)} value={searchInput} />
                <button onClick={() => handleClear()} className='dugme-clear'>Očisti</button>

                {/* // <img src={Pencil} className='search-icon' /> */}
            </div>
            <DataTable
                columns={columns}
                data={serviceChargeForClients ? serviceChargeForClients : []}
                pagination
                paginationServer
                paginationTotalRows={total}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                responsive
                highlightOnHover
                striped
                dense
                pointerOnHover
                paginationComponentOptions={defaultComponentOptions}
            />

            <div>
                <Modal isOpen={Boolean(modalOpen)} centered  >
                    <ModalHeader>Izvještaj registara usluga za komitente</ModalHeader>
                    <ModalBody>

                        <div className="row">

                            <Col lg='7'>

                                <div className="col-12" style={{ display: 'flex' }}>


                                    <div className="col-12">
                                        <div class="form-group row label-box-div">
                                            <div className={errorInputWarehouse ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px', marginBottom: '0px' }} >
                                                <label className="col-form-label col-lg-1 label-box">Skladište:</label>
                                                <div class="col-11">
                                                    <Autocomplete
                                                        {...defaultProps}
                                                        value={repository}
                                                        disablePortal={true}
                                                        onChange={(event, newValue) => {
                                                            setForm(prev => ({ ...prev, SKLAID: newValue && newValue != null ? newValue.ID : null }))
                                                            setRepository(newValue);
                                                        }}

                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                className="clients-textinput"
                                                                name='SKLADISTE'
                                                                onFocus={() => setRepositoryFocus(true)}
                                                                ref={repositoryRef} 
                                                                onBlur={(e) => formCheck(e)}
                                                                placeholder=' Izaberite skladište'
                                                                variant="standard" />
                                                        )}
                                                    />
                                                </div>
                                                {errorMsgWarehouse ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgWarehouse}</span> : ''}

                                                {/* <input type="text" tabIndex="1" autoFocus={true} placeholder="skladište" name='SKLAID' value={form?.SKLAID} onChange={(e) => handleChange(e)} ></input> */}

                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div className="col-12" style={{ display: 'flex' }}>
                                    <div className="col-12">
                                        <div class="form-group row label-box-div">
                                            <div className={errorInputClient ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px' }} >
                                                <label className="col-form-label col-lg-1 label-box">Komitent:</label>
                                                <div class="col-11">
                                                    <Autocomplete
                                                        {...defaultPropsClients}
                                                        value={clients}
                                                        disablePortal={true}
                                                        onChange={(event, newValue) => {
                                                            setForm(prev => ({ ...prev, KOMTID: newValue && newValue != null ? newValue.ID : null }))
                                                            setClients(newValue)
                                                            getFirstFreeNumberRbr(repository.ID, newValue.ID)


                                                        }}

                                                        renderInput={(params) => (
                                                            <TextField {...params}
                                                                ref={clientsRef}
                                                                name='KOMT'
                                                                onFocus={() => setClientFocus(true)}
                                                                className="clients-textinput"
                                                                tabIndex="2"
                                                                placeholder=' Izaberite komitenta'
                                                                variant="standard"
                                                                onBlur={(e) => formCheck(e)} />
                                                        )}
                                                    />
                                                </div>
                                                {errorMsgClient ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgClient}</span> : ''}

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12" style={{ display: 'flex' }}>
                                    <div className="col-12">
                                        <div class="form-group row label-box-div">
                                            <div className={errorInputService ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px' }} >
                                                <label className="col-form-label col-lg-1 label-box">Usluga:</label>
                                                <div class="col-11">
                                                    <Autocomplete
                                                        {...defaultPropsService}
                                                        value={service}
                                                        disablePortal={true}
                                                        onChange={(event, newValue) => {
                                                            if (newValue == null) {
                                                                setForm(prev => ({ ...prev, USLUGA: '' }))
                                                            } else {
                                                                setForm(prev => ({ ...prev, USLUGA: newValue && newValue != null ? newValue.SIFRA : null }))
                                                                setService(newValue);
                                                            }
                                                        }}

                                                        renderInput={(params) => (
                                                            <TextField {...params}
                                                                tabIndex="4"
                                                                ref={serviceRef}
                                                                name='USLUGA'
                                                                onFocus={() => setServiceFocus(true)}
                                                                className="clients-textinput"
                                                                placeholder=' Izaberite uslugu'
                                                                variant="standard"
                                                                onBlur={(e) => formCheck(e)} />
                                                        )}
                                                    />
                                                </div>
                                                {errorMsgService ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgService}</span> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>



                            </Col>



                            <Col lg='5' style={{ marginLeft: 'inherit' }}>


                                <div className='modal-buttons' style={{ display: 'inline-grid' }}>
                                    <button onClick={() => OnServicesAndClients()} >Po uslugama i komitentima</button>
                                    <button onClick={() => onClientsAndServices()}>Po komitentima i uslugama</button>
                                    <button onClick={() => onRegistersOfClients()} >Spisak komitenata</button>
                                </div>


                            </Col>
                        </div>

                    </ModalBody>
                    <ModalFooter>
                        <div>
                            <button onClick={() => setModalOpen(false)} className="button-cancel">Otkaži</button>
                        </div>
                    </ModalFooter>

                </Modal>
            </div>


            <div>
                <Modal isOpen={modalErrorOpen} centered  >
                    <ModalHeader>Neispravan unos</ModalHeader>
                    <ModalBody>
                        <span>{`Unesite obavezna polja!`}</span>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            <button onClick={() => setModalErrorOpen(false)} className="button-no">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>
        </div >
    );

}

export default UslugeZaKomitente;


