// importovanje komponenti
import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { API } from '../constants';
import axios from '../api/axios';
import { Spinner } from "reactstrap";
import { getCompanyData, defaultComponentOptions } from '../components/utilities';
import { NumericFormat } from 'react-number-format';
import { useWindowSize } from '../components/useWindowSize';

// komponenta koju kreiramo
function Preduzeca() {
    const windowSize = useWindowSize();
    // definisanje konstanti koje koristimo za fokusiranje polja
    const codeRef = useRef();
    const nameRef = useRef();
    const placeRef = useRef();
    const yearRef = useRef();
    const entityRef = useRef();
    const odnosRef = useRef();
    const capitalRef = useRef();

    // definisanje stejtova i prva inicijalizacija; objekat i funkcija za taj objekat
    const [company, setCompany] = useState(null)
    const [total, setTotal] = useState(0)
    const [perPage, setPerPage] = useState(10);
    const [selectData, setSelectData] = useState([]);
    const [searchInput, setSearchInput] = useState([]);
    // error row inputs
    const [errorInputCode, setErrorInputCode] = useState(false);
    const [errorInputName, setErrorInputName] = useState(false);
    const [errorInputPlace, setErrorInputPlace] = useState(false);
    const [errorInputYear, setErrorInputYear] = useState(false);
    const [errorInputEntity, setErrorInputEntity] = useState(false);
    // error messages inputs
    const [errorMsgCode, setErrorMsgCode] = useState(false);
    const [errorMsgName, setErrorMsgName] = useState(false);
    const [errorMsgPlace, setErrorMsgPlace] = useState(false);
    const [errorMsgYear, setErrorMsgYear] = useState(false);
    const [errorMsgEntity, setErrorMsgEntity] = useState(false);
    const [modalErrorOpen, setModalErrorOpen] = useState(false);
    const [modalErrorOpenData, setModalErrorOpenData] = useState(false);
    const [modalErrorAuthorizationData, setModalErrorAuthorizationOpen] = useState(false);
    const [modalDeleteItem, setModalDeleteItem] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [pending, setPending] = React.useState(false);

    // povlacenje podataka iz lokal storidza
    const token = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : null

    // definisanje stejta(objekat i funkcija za taj objekat), prva inicijalizacija
    const [form, setForm] = useState({
        SIFRA: '',
        NAZIV: '',
        MJESTO: '',
        ADRESA: '',
        TELEFON: '',
        EMAIL: '',
        IDENTBROJ: '',
        PDVBROJ: '',
        MATICNI: '',
        OPSTINA: '',
        VLASNIK: '',
        ZIRORAC: '',
        ZIRORACKM: '',
        ZIRORACDM: '',
        ZIRORAC3: '',
        ZIRORAC4: '',
        GODINA: '',
        ODNOS: '',
        KAPITAL: '',
        PDV: '',
        ENTITET: '',
        stavkaPronadjena: false
    })

    // funkcija za povlacenje podataka o preduzecu
    getCompanyData(form)

    // funkcija koja se poziva klikom na dugme 'Sacuvaj'
    const onSubmit = () => {

        // uslov: ako nisu unesena obavezna polja, zaustavlja upis
        if (!unosIspravanDo('UPIS')) return;

        // ide na upis sloga
        // poziva api za upis
        // osvezavanje polja, fokus na polje SIFRA, poziva funkciju getTableData(za upis u mrezu)    
        form.SIFKOR = form.userCode
        fetch(API + '/preduzeca/new', {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                response.json()
                setForm({
                    SIFRA: '',
                    NAZIV: '',
                    MJESTO: '',
                    ADRESA: '',
                    TELEFON: '',
                    EMAIL: '',
                    IDENTBROJ: '',
                    PDVBROJ: '',
                    MATICNI: '',
                    OPSTINA: '',
                    VLASNIK: '',
                    ZIRORAC: '',
                    ZIRORACKM: '',
                    ZIRORACDM: '',
                    ZIRORAC3: '',
                    ZIRORAC4: '',
                    GODINA: '',
                    ODNOS: '',
                    KAPITAL: '',
                    PDV: '',
                    ENTITET: '',
                    stavkaPronadjena: false
                })
                codeRef.current.focus()
                getTableData()
            })
    }

    // funkcija koja se poziva pri prvoj inicijalizaciji
    // api za mrezu
    // u responsu puni mrezi(pomocu funkcije setIme) i puni ukupan broj stranica(setTotal)
    const getTableData = () => {
        fetch(API + `/preduzeca/all`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setCompany(data.result)
                setPending(false)
            })
    }

    // prilikom pocetne inicijalizacije postavlja prvi slobodan broj
    useEffect(() => {
        // zaustavljanje fokusa(pauza za fokus dok ne odradi upis) 
        const timeout = setTimeout(() => {
            // codeRef.current.focus()
            getCompanyFirstFreeNumberSifra()
            getTableData()
        }, 100);
        return () => {
            clearTimeout(timeout);
        };
    }, []);

    // funkcija za brisanje koja se poziva klikom na dugme 'Delete'
    //  api za brisanje
    // u responsu osvjezava polja, fokus na polje sifra, osvjezava mrezu  
    const onDelete = (SIFRA) => {
        // provjera ovlascenja za brisanje 
        if (form.ovlfakt == 4 || form.ovlfik == 4) return setModalErrorAuthorizationOpen(true)
        fetch(API + '/preduzeca/delete/' + SIFRA, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                response.json()
                setForm({
                    SIFRA: '',
                    NAZIV: '',
                    MJESTO: '',
                    ADRESA: '',
                    TELEFON: '',
                    EMAIL: '',
                    IDENTBROJ: '',
                    PDVBROJ: '',
                    MATICNI: '',
                    OPSTINA: '',
                    VLASNIK: '',
                    ZIRORAC: '',
                    ZIRORACKM: '',
                    ZIRORACDM: '',
                    ZIRORAC3: '',
                    ZIRORAC4: '',
                    GODINA: '',
                    ODNOS: '',
                    KAPITAL: '',
                    PDV: '',
                    ENTITET: '',
                    stavkaPronadjena: false
                })
                codeRef.current.focus()
                getTableData()
            })
    }

    // funkcija koja se poziva klikom na dugme Edit
    // postavlja fokus na polje NAZIV 
    // popunjava polja podacima iz mreze
    const onEdit = (item) => {
        item.stavkaPronadjena = true
        setForm(item)
        // zaustavljanje fokusa
        const timeout = setTimeout(() => {
            nameRef.current.focus()
        }, 100);
        return () => {
            clearTimeout(timeout);
        };
    }

    // funkcija koja se poziva klikom na dugme 'Stampa'
    const onPrint = () => {
        setSpinner(true)
        // uslov preko koga saljemo naziv preduzeca sa frontenda na bekend
        // ako je objekat form popunjen, napuni polje NAZIV_PRED iz lokal storidza
        // poziva api za stampu, otvara izvjestaj(API + data.link)
        if (company && company.length === 0) {
            setSpinner(false)
            setModalErrorOpenData(true)
        } else {
            if (form) {
                form.NAZIV_PRED = form.companyName
            }
            fetch(API + `/preduzeca/print`, {
                method: 'POST',
                body: JSON.stringify(form),
                responseType: 'blob',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-type': 'application/json; charset=UTF-8'
                },
            })
                .then((response) => response.json())
                .then(data => {
                    if (typeof window != 'undefined') {
                        setTimeout(() => {
                            window.open(API + data.link)
                        }, 500);
                    }
                    setSpinner(false)
                })
        }
    }

    // funkcija koja se poziva prilikom promjene vrijednosti u inputu
    const handleChange = (event) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name;

        setForm((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    // funkcija za pronalazenje prvog slobodnog broja
    // poziva api
    // u responsu stejt setForm poziva funkciju za pronalazenje prethodne sifre(iz data) i na osnovu nje odredjuje koja je sljedeca prva slobodna sifra
    // setFirstCode - stejt koji se koristi za razlikovanje izmjene i upisa 
    const getCompanyFirstFreeNumberSifra = () => {
        fetch(API + `/preduzeca/firstFreeNumberSifra`, {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setForm(prev => ({ ...prev, SIFRA: Number(data[0].SIFRA) }))
            })
    }

    // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto) ili ako nije upisana ni vrijednost niti je odabran dokument, izbaci poruku, setuje crveni okvir i fokusira polje  
    // u suprotnom prolazi dalje
    const checkCodeRequired = (value) => {
        if (!value || value == 0) {
            setErrorMsgCode('Šifra je obavezna!')
            setErrorInputCode(true)
            codeRef.current.focus();
            return false
        } else {
            setErrorMsgCode(null)
            setErrorInputCode(false)
            return true
        }
    }

    // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto) ili ako nije upisana ni vrijednost, izbaci poruku, setuje crveni okvir i fokusira polje  
    // u suprotnom prolazi dalje
    const checkNameRequired = (value) => {
        if (!value || value == 0) {
            setErrorMsgName('Naziv je obavezan!')
            setErrorInputName(true)
            nameRef.current.focus();
            return false
        } else {
            setErrorMsgName(null)
            setErrorInputName(false)
            return true
        }
    }

    // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto) ili ako nije upisana ni vrijednost, izbaci poruku, setuje crveni okvir i fokusira polje  
    // u suprotnom prolazi dalje
    const checkPlaceRequired = (value) => {
        if (!value || value == 0) {
            setErrorMsgPlace('Naziv je obavezan!')
            setErrorInputPlace(true)
            placeRef.current.focus();
            return false
        } else {
            setErrorMsgPlace(null)
            setErrorInputPlace(false)
            return true
        }
    }

    // uslov: ako nije upisana neka vrijednost ili je vrijednost 0, izbaci poruku, setuje crveni okvir i fokusira polje  
    // u suprotnom prolazi dalje
    const checkYearRequired = (value) => {
        if (!value || value == 0) {
            setErrorMsgYear('Godina je obavezna!')
            setErrorInputYear(true)
            yearRef.current.focus();
            return false
        } else {
            setErrorMsgYear(null)
            setErrorInputYear(false)
            return true
        }
    }

    // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto) ili ako nije upisana ni vrijednost niti je odabran dokument, izbaci poruku, setuje crveni okvir i fokusira polje  
    // u suprotnom prolazi dalje
    const checkEntityRequired = (value) => {
        if (!value || value.length == 0) {
            setErrorMsgEntity('Entitet je obavezan!')
            setErrorInputEntity(true)
            entityRef.current.focus()
            return false
        } else {
            setErrorMsgEntity(null)
            setErrorInputEntity(false)
            return true
        }
    }

    // funkcija za kontrolu obaveznih polja
    function unosIspravanDo(name) {
        if (name === 'SIFRA') {
            return true
        }

        if (!checkCodeRequired(form.SIFRA)) {
            return false
        }

        if (name === 'NAZIV') {
            return true
        }

        if (!checkNameRequired(form.NAZIV)) {
            return false
        }

        if (name === 'MJESTO') {
            return true
        }

        if (!checkPlaceRequired(form.MJESTO)) {
            return false
        }

        if (name === 'ADRESA') {
            return true
        }

        if (name === 'TELEFON') {
            return true
        }

        if (name === 'EMAIL') {
            return true
        }

        if (name === 'IDENTBROJ') {
            return true
        }

        if (name === 'MATICNI') {
            return true
        }

        if (name === 'OPSTINA') {
            return true
        }

        if (name === 'VLASNIK') {
            return true
        }

        if (name === 'GODINA') {
            return true
        }

        if (!checkYearRequired(form.GODINA)) {
            return false
        }

        if (name === 'ODNOS') {
            return true
        }

        if (name === 'KAPITAL') {
            return true
        }

        if (name === 'PDV') {
            return true
        }

        if (name === 'PDVBROJ') {
            return true
        }

        if (name === 'ENTITET') {
            return true
        }

        if (!checkEntityRequired(form.ENTITET)) {
            return false
        }

        if (name === 'ZIRORAC') {
            return true
        }

        if (name === 'ZIRORACKM') {
            return true
        }

        if (name === 'ZIRORACDM') {
            return true
        }

        if (name === 'ZIRORAC3') {
            return true
        }

        if (name === 'ZIRORAC4') {
            return true
        }

        return true
    }

    // definisu se polja u tabeli i popunjavaju
    const columns = [
        {
            name: 'Šifra',
            selector: row => row.SIFRA,
            // sirina polja po rezolucijama
            width:
                windowSize.width > 1920 ? '90px'
                    : windowSize.width > 1680 ? '100px'
                        : windowSize.width > 1600 ? '100px'
                            : windowSize.width > 1440 ? '100px'
                                : windowSize.width > 1280 ? '80px'
                                    : windowSize.width > 1024 ? '80px'
                                        : '80px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.SIFRA}</span>
                </>
            ),
        },
        {
            name: 'Naziv',
            selector: row => row.NAZIV,
            // sirina polja po rezolucijama
            width:
            windowSize.width > 2048 ? '570px'
                : windowSize.width > 1920 ? '400px'
                    : windowSize.width > 1680 ? '290px'
                        : windowSize.width > 1600 ? '260px'
                            : windowSize.width > 1440 ? '250px'
                                : windowSize.width > 1280 ? '220px'
                                    : windowSize.width > 1024 ? '220px'
                                        : '200px',
            cell: (row) => (
                <>
                    <span id='media-query-rows-text'>{row.NAZIV}</span>
                </>
            ),
        },
        {
            name: 'Mjesto',
            selector: row => row.MJESTO,
            // sirina polja po rezolucijama
            width:
            windowSize.width > 2048 ? '360px'
                : windowSize.width > 1920 ? '290px'
                    : windowSize.width > 1680 ? '200px'
                        : windowSize.width > 1600 ? '140px'
                            : windowSize.width > 1440 ? '130px'
                                : windowSize.width > 1280 ? '120px'
                                    : windowSize.width > 1024 ? '100px'
                                        : '100px',
            cell: (row) => (
                <>
                    <span id='media-query-rows-text'>{row.MJESTO}</span>
                </>
            ),
        },
        {
            name: 'Adresa',
            selector: row => row.ADRESA,
            // sirina polja po rezolucijama
            width:
            windowSize.width > 2048 ? '360px'
                : windowSize.width > 1920 ? '300px'
                    : windowSize.width > 1680 ? '240px'
                        : windowSize.width > 1600 ? '260px'
                            : windowSize.width > 1440 ? '250px'
                                : windowSize.width > 1280 ? '200px'
                                    : windowSize.width > 1024 ? '230px'
                                        : '190px',
            cell: (row) => (
                <>
                    <span id='media-query-rows-text'>{row.ADRESA}</span>
                </>
            ),
        },
        {
            name: 'Račun 1',
            selector: row => row.ZIRORAC,
            // sirina polja po rezolucijama
            width:
            windowSize.width > 2048 ? '320px'
                : windowSize.width > 1920 ? '280px'
                    : windowSize.width > 1680 ? '250px'
                        : windowSize.width > 1600 ? '240px'
                            : windowSize.width > 1440 ? '240px'
                                : windowSize.width > 1280 ? '200px'
                                    : windowSize.width > 1024 ? '180px'
                                        : '180px',
            cell: (row) => (
                <>
                    <span id='media-query-rows-text'>{row.ZIRORAC}</span>
                </>
            ),
        },
        {
            name: 'Telefon',
            selector: row => row.TELEFON,
            // sirina polja po rezolucijama
            width:
            windowSize.width > 2048 ? '320px'
                : windowSize.width > 1920 ? '280px'
                    : windowSize.width > 1680 ? '250px'
                        : windowSize.width > 1600 ? '250px'
                            : windowSize.width > 1440 ? '200px'
                                : windowSize.width > 1280 ? '190px'
                                    : windowSize.width > 1024 ? '140px'
                                        : '120px',
            cell: (row) => (
                <>
                    <span id='media-query-rows-text'>{row.TELEFON}</span>
                </>
            ),
        },
        {
            name: 'Action',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span onClick={() => onEdit(row)} id='media-query-table-buttons' className='btn btn-primary nav flex-column flex-lg-row justify-content-end'>Edit</span>{'     '}
                    <span onClick={() => { onEdit(row); setModalDeleteItem(true) }} id='media-query-table-buttons' className='btn btn-danger nav flex-column flex-lg-row justify-content-end'>Brisanje</span>
                </>
            ),
        }
    ];

    // funkcija za pretragu iz mreze
    // salje zahtjev na bekend prema upitu sa parametrima koje posaljemo
    // u responsu vraca podatke koje dobio iz upita 
    const searchChange = (e) => {
        e.preventDefault();
        const searchInput = e.target.value
        setSearchInput(searchInput);
        const fetchData = async () => {
            const res = await axios.get(`${API}/preduzeca/query/${form.companyCode}?q=${searchInput}`, { headers: { "Authorization": `Bearer ${token}` } });
            setCompany(res.data);
        };
        // uslov za za slanje zahtjeva za povlacenje podataka
        if (searchInput.length === 0 || searchInput.length >= 1) fetchData();
    };

    return (

        <div>
            {/* Kontejner za naslov  */}

            <div className="title-container">
                {/* Naziv registra */}
                <header className="title-header">
                    <h1 id="media-query-title">Preduzeća</h1>
                </header>

                <div className="naslov-ikonice">
                    <ul className="nav flex-column flex-lg-row justify-content-end">

                        <li className="nav-item toolbaritem">
                            <input type="image" alt="save" className='dugme-sacuvaj' title='Sačuvaj' tabIndex="22" src={require('./../assets/images/floppydisk.png')} onFocus={() => onSubmit()} />
                        </li>

                        <li className="nav-item toolbaritem">
                            <input type="image" alt="print" className='dugme-stampa' title='Štampa' tabIndex="23" src={require('./../assets/images/printer.png')} onClick={onPrint} />
                        </li>
                    </ul>
                </div>
            </div>

            {/* Kontejner pune sirine */}
            <Container fluid >
                <Row>
                    <div className='products-form'>
                        <Row>
                            {/* Stilovi koji ce se primjenjivati na svim poljima */}
                            <div className="col-6" style={{ display: 'flex', paddingRight: '0px', paddingLeft: '-1px', marginRight: 'auto', marginLeft: '0px' }}>
                                {/* Sirina polja */}
                                <div className="col-1">
                                    <div className='col-form-right'>
                                        <div className={errorInputCode ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px' }} >
                                            <label id="media-query-label">Šifra:</label>
                                            <NumericFormat
                                                type="text"
                                                className='field-alignment p-1'
                                                getInputRef={codeRef}
                                                id="media-query-input-text"
                                                maxLength="10"
                                                tabIndex="1"
                                                decimalScale={0}
                                                autoFocus={true}
                                                placeholder="šifra"
                                                name='SIFRA'
                                                // Postavlja stejt za fokus na true jer je potrebno zbog stepenaste kontrole
                                                value={form ? form.SIFRA : ''}
                                                onFocus={() => {
                                                    getCompanyFirstFreeNumberSifra()
                                                    unosIspravanDo("SIFRA")
                                                }}
                                                // Ako je izmjena na polju
                                                onChange={(e) => handleChange(e)}
                                                onClick={() => {
                                                    // klikom na polje sifra, ciste se ostala polja
                                                    setForm(prev => ({
                                                        ...prev,
                                                        NAZIV: '',
                                                        MJESTO: '',
                                                        ADRESA: '',
                                                        TELEFON: '',
                                                        EMAIL: '',
                                                        IDENTBROJ: '',
                                                        PDVBROJ: '',
                                                        MATICNI: '',
                                                        OPSTINA: '',
                                                        VLASNIK: '',
                                                        ZIRORAC: '',
                                                        ZIRORACKM: '',
                                                        ZIRORACDM: '',
                                                        ZIRORAC3: '',
                                                        ZIRORAC4: '',
                                                        GODINA: '',
                                                        ODNOS: '',
                                                        KAPITAL: '',
                                                        PDV: '',
                                                        ENTITET: '', stavkaPronadjena: false
                                                    }))
                                                }} />
                                            {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                                            {errorMsgCode ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgCode}</span> : ''}
                                        </div>
                                    </div>
                                </div>
                                {/* Sirina polja */}

                                <div className="col-5">
                                    {/* Provjera da li je polje popunjeno jer je obavezno */}
                                    <div className={errorInputName ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px' }} >
                                        <label id="media-query-label">Naziv:</label>
                                        <input type="text"
                                            id="media-query-input-text"
                                            maxLength="70"
                                            tabIndex="2"
                                            autoComplete='off'
                                            placeholder="naziv"
                                            name='NAZIV'
                                            value={form ? form.NAZIV : ''}
                                            ref={nameRef}
                                            onFocus={() => {
                                                unosIspravanDo("NAZIV")
                                                // uslov za citanje sloga po sifri
                                                if (unosIspravanDo("NAZIV")) {
                                                    let selectedItem = []
                                                    selectedItem = company.filter((c) => c.SIFRA == form.SIFRA)
                                                    if (selectedItem.length > 0) {
                                                        onEdit(selectedItem[0])
                                                    }
                                                }
                                            }}
                                            // Ako je izmjena na polju
                                            onChange={(e) => handleChange(e)} />
                                        {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                                        {errorMsgName ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgName}</span> : ''}

                                    </div>
                                </div>

                                {/* Sirina polja */}

                                <div className="col-6">
                                    <div className={errorInputPlace ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px' }} >
                                        <label id="media-query-label">Mjesto:</label>
                                        <input type="text"
                                            id="media-query-input-text"
                                            maxLength="70"
                                            tabIndex="3"
                                            placeholder="mjesto"
                                            name='MJESTO'
                                            value={form ? form.MJESTO : ''}
                                            ref={placeRef}
                                            onFocus={() => {
                                                unosIspravanDo("MJESTO")
                                            }}
                                            onChange={(e) => handleChange(e)} />
                                        {errorMsgPlace ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgPlace}</span> : ''}

                                    </div>
                                </div>
                            </div>

                            <div className="col-6" style={{ display: 'flex', paddingRight: '0px', paddingLeft: '0px', marginRight: 'auto', marginLeft: '0px' }}>
                                <div className="col-4">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label id="media-query-label">Adresa:</label>
                                        <input type="text" id="media-query-input-text" maxLength="70" tabIndex="4" placeholder="adresa" name='ADRESA' value={form ? form.ADRESA : ''}
                                            onFocus={() => {
                                                unosIspravanDo("ADRESA")
                                            }} onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>



                                <div className="col-4">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label id="media-query-label">Telefon:</label>
                                        <input type="text" id="media-query-input-text" maxLength="70" tabIndex="5" placeholder="telefon" name='TELEFON' value={form ? form.TELEFON : ''}
                                            onFocus={() => {
                                                unosIspravanDo("TELEFON")
                                            }}
                                            onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>


                                <div className="col-4">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label id="media-query-label">E-mail:</label>
                                        <input type="text" id="media-query-input-text" maxLength="100" tabIndex="6" placeholder="e-mail" name='EMAIL' value={form ? form.EMAIL : ''}
                                            onFocus={() => {
                                                unosIspravanDo("EMAIL")
                                            }}
                                            onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>
                            </div>

                        </Row>

                        <Row>

                            <div className="col-6" style={{ display: 'flex', paddingRight: '0px', paddingLeft: '-1px', marginRight: 'auto', marginLeft: '0px' }}>

                                <div className="col-2">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label id="media-query-label">JIB:</label>
                                        <input type="text" id="media-query-input-text" maxLength="20" tabIndex="7" placeholder="ident. broj" name='IDENTBROJ' value={form ? form.IDENTBROJ : ''}
                                            onFocus={() => {
                                                unosIspravanDo("IDENTBROJ")
                                            }}
                                            onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>

                                <div className="col-5">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label id="media-query-label">Matični:</label>
                                        <input type="text" id="media-query-input-text" maxLength="20" tabIndex="8" placeholder="matični" name='MATICNI' value={form ? form.MATICNI : ''}
                                            onFocus={() => {
                                                unosIspravanDo("MATICNI")
                                            }}
                                            onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>

                                <div className="col-5">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label id="media-query-label">Opština:</label>
                                        <input type="text" id="media-query-input-text" maxLength="20" tabIndex="9" placeholder="opština" name='OPSTINA' value={form ? form.OPSTINA : ''}
                                            onFocus={() => {
                                                unosIspravanDo("OPSTINA")
                                            }}
                                            onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>
                            </div>

                            <div className="col-6" style={{ display: 'flex', paddingRight: '0px', paddingLeft: '0px', marginRight: 'auto', marginLeft: '0px' }}>
                                <div className="col-5">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label id="media-query-label">Vlasnik:</label>
                                        <input type="text" id="media-query-input-text" maxLength="20" tabIndex="10" placeholder="vlasnik" name='VLASNIK' value={form ? form.VLASNIK : ''}
                                            onFocus={() => {
                                                unosIspravanDo("VLASNIK")
                                            }}
                                            onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>

                                {/* Sirina polja */}
                                <div className="col-1">
                                    {/* Provjera da li je polje popunjeno jer je obavezno */}
                                    <div className='col-form-right'>
                                        <div className={errorInputYear ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px' }} >
                                            <label id="media-query-label">Godina:</label>
                                            <NumericFormat
                                                type="text"
                                                className='field-alignment p-1'
                                                getInputRef={yearRef}
                                                maxLength="4"
                                                decimalScale={0}
                                                id="media-query-input-text"
                                                tabIndex="11"
                                                placeholder="godina"
                                                name='GODINA'
                                                value={form ? form.GODINA : ''}
                                                onFocus={() => {
                                                    unosIspravanDo("GODINA")
                                                }}
                                                // Ako je izmjena na polju
                                                onChange={(e) => handleChange(e)} />
                                            {errorMsgYear ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgYear}</span> : ''}
                                        </div>
                                    </div>
                                </div>

                                {/* Sirina polja */}
                                <div className="col-2">
                                    <div className='col-form-right'>
                                        <div className='field-container' style={{ marginRight: '3px' }}>
                                            <label id="media-query-label">Odnos:</label>
                                        </div>
                                        <NumericFormat
                                            type="text"
                                            className='field-alignment p-1'
                                            getInputRef={odnosRef}
                                            maxLength="10"
                                            decimalScale={5}
                                            id="media-query-input-text"
                                            tabIndex="12"
                                            placeholder="odnos"
                                            name='ODNOS'
                                            value={form ? form.ODNOS : ''}
                                            onFocus={() => {
                                                unosIspravanDo("ODNOS")
                                            }}
                                            onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>

                                {/* Sirina polja */}

                                <div className="col-2">
                                    <div className='col-form-right'>
                                        <div className='field-container' style={{ marginRight: '3px' }}>
                                            <label id="media-query-label">Kapital:</label>
                                        </div>
                                        <NumericFormat 
                                            type="text"
                                            className='field-alignment p-1'
                                            getInputRef={capitalRef}
                                            maxLength="13"
                                            decimalScale={2}
                                            id="media-query-input-text"
                                            tabIndex="13"
                                            placeholder="kapital"
                                            name='KAPITAL'
                                            value={form ? form.KAPITAL : ''}
                                            onFocus={() => {
                                                unosIspravanDo("KAPITAL")
                                            }}
                                            onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>

                                {/* Sirina polja */}

                                <div className="col-2">
                                    {/* Provjera da li je polje popunjeno jer je obavezno */}
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label id="media-query-label">Pdv obv.:</label>
                                        <select
                                            id="media-query-input-text"
                                            value={form ? form.PDV : ''}
                                            name='PDV'
                                            className='select-style'
                                            placeholder="izaberite porez"
                                            onFocus={() => {
                                                unosIspravanDo("PDV")
                                            }}
                                            // Ako je izmjena na polju
                                            onChange={(e) => handleChange(e)}
                                            tabIndex="14">
                                            <option value={''}>{''}</option>
                                            <option value={'N'}>{'Ne'}</option>
                                            <option value={'D'}>{'Da'}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </Row>

                        <Row>
                            {/* Sirina reda */}
                            <div className="col-12" style={{ display: 'flex', paddingRight: '0px', paddingLeft: '-1px', marginRight: 'auto', marginLeft: '0px' }}>

                                <div className="col-1">
                                    {/* Provjera  polja i stilova */}
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label id="media-query-label">PDV broj:</label>
                                        {/* Provjera unosa pola i duzine  */}
                                        <input type="text" id="media-query-input-text" maxLength="20" tabIndex="15" placeholder="pdv broj" name='PDVBROJ' value={form ? form.PDVBROJ : ''}
                                            onFocus={() => {
                                                unosIspravanDo("PDVBROJ")
                                            }}
                                            onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>

                                {/* Sirina polja */}
                                <div className="col-1">
                                    {/* Provjera da li je polje popunjeno jer je obavezno */}
                                    <div className={errorInputEntity ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px' }} >
                                        <label id="media-query-label">Entitet:</label>
                                        <select
                                            id="media-query-input-text"
                                            value={form?.ENTITET}
                                            name='ENTITET'
                                            ref={entityRef}
                                            className='select-style'
                                            placeholder="izaberite entitet"
                                            onFocus={() => {
                                                unosIspravanDo("ENTITET")
                                            }}
                                            onChange={(e) => handleChange(e)}
                                            tabIndex="16">
                                            <option value={''}>{''}</option>
                                            <option value={'RS'}>{'Republika Srpska'}</option>
                                            <option value={'FBH'}>{'Federacija BiH'}</option>
                                            <option value={'BD'}>{'Brčko Distrikt'}</option>
                                            <option value={'INO'}>{'Inostranstvo'}</option>
                                        </select>
                                        {errorMsgEntity ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgEntity}</span> : ''}
                                    </div>
                                </div>

                                <div className="col-2">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label id="media-query-label">Žiroračun 1:</label>
                                        <input type="text" id="media-query-input-text" maxLength="70" tabIndex="17" placeholder="žiroračun 1" name='ZIRORAC' value={form ? form.ZIRORAC : ''}
                                            onFocus={() => {
                                                unosIspravanDo("ZIRORAC")
                                            }}
                                            onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>

                                <div className="col-2">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label id="media-query-label">Žiroračun 2:</label>
                                        <input type="text" id="media-query-input-text" maxLength="70" tabIndex="18" placeholder="žiroračun 2" name='ZIRORACKM' value={form ? form.ZIRORACKM : ''}
                                            onFocus={() => {
                                                unosIspravanDo("ZIRORACKM")
                                            }}
                                            onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>

                                <div className="col-2">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label id="media-query-label">Žiroračun 3:</label>
                                        <input type="text" id="media-query-input-text" maxLength="70" tabIndex="19" placeholder="žiroračun 3" name='ZIRORACDM' value={form ? form.ZIRORACDM : ''}
                                            onFocus={() => {
                                                unosIspravanDo("ZIRORACDM")
                                            }}
                                            onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>

                                <div className="col-2">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label id="media-query-label">Žiroračun 4:</label>
                                        <input type="text" id="media-query-input-text" maxLength="70" tabIndex="20" placeholder="žiroračun 4" name='ZIRORAC3' value={form ? form.ZIRORAC3 : ''}
                                            onFocus={() => {
                                                unosIspravanDo("ZIRORAC3")
                                            }}
                                            onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>

                                <div className="col-2">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label id="media-query-label">Žiroračun 5:</label>
                                        <input type="text" id="media-query-input-text" maxLength="70" tabIndex="21" placeholder="žiroračun 5" name='ZIRORAC4' value={form ? form.ZIRORAC4 : ''}
                                            onFocus={() => {
                                                unosIspravanDo("ZIRORAC4")
                                            }}
                                            onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </div>
                </Row >
            </Container >

            {/* Stilovi i klase za polje za pretragu;  */}
            < div style={{ display: 'flex' }
            } className='search' >
                {/* vrsta inputa; poziva funkciju za pretragu(searchChange);  */}
                < input type='text' id="media-query-input-text" placeholder='Pretraga...' onChange={(e) => {
                    // pretraga mreze(ukoliko je unesen bilo koji karakter, vrsi pretragu)
                    if (searchInput.length >= 0) {
                        searchChange(e)
                    } else {
                        getTableData()
                    }
                }} value={searchInput} />
            </div >

            {/* Mreza */}
            < DataTable
                columns={columns}
                data={company ? company : []}
                pagination
                responsive
                highlightOnHover
                striped
                dense
                pointerOnHover
                paginationComponentOptions={defaultComponentOptions}
                paginationPerPage={[windowSize.height > 1300 ? 25
                    : windowSize.height > 1246 ? 23
                    : windowSize.height > 1222 ? 22
                    : windowSize.height > 1200 ? 22
                    : windowSize.height > 1000 ? 17
                    : windowSize.height > 900 ? 14
                    : windowSize.height > 880 ? 13
                    : windowSize.height > 820 ? 11
                    : windowSize.height > 720 ? 10
                                        : 4]}
                progressPending={pending}
            />

            {/* render za poruku */}
            <div>
                <Modal isOpen={Boolean(modalDeleteItem)} centered>
                    <ModalHeader>Brisanje stavke</ModalHeader>
                    <ModalBody>
                        <span>{`Da li želite da obrišete stavku broj ${form.SIFRA} ? `}</span>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            <button
                                onClick={() => { setModalDeleteItem(false); nameRef.current.focus() }}
                                className="button-no">Ne
                            </button>
                            <button
                                onClick={() => {
                                    onDelete(form.SIFRA);
                                    setModalDeleteItem(false)
                                }}
                                className="button-yes">Da
                            </button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            {/* render za poruku */}
            <div>
                <Modal isOpen={Boolean(modalErrorAuthorizationData)} centered  >
                    <ModalHeader>Upozorenje!</ModalHeader>
                    <ModalBody>
                        <span>{`Nemate ovlašćenje za brisanje!`}</span>
                    </ModalBody>
                    <ModalFooter className="justify-content-center">
                        <div>
                            <button onClick={() => setModalErrorAuthorizationOpen(false)} className="button-yes">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            {/* Modalni form za gresku; prikazuje se ako nisu unijeta obavezna polja */}
            < div >
                <Modal isOpen={Boolean(modalErrorOpen)} centered  >
                    <ModalHeader>Neispravan unos</ModalHeader>
                    <ModalBody>
                        <span>{`Unesite obavezna polja!`}</span>
                    </ModalBody>
                    <ModalFooter className="justify-content-center">
                        <div>
                            {/* Dugme za zatvaranje modalnog forma */}
                            <button onClick={() => setModalErrorOpen(false)} className="button-yes">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div >

            <div>
                <Modal isOpen={Boolean(modalErrorOpenData)} centered  >
                    <ModalHeader>Nema podataka!</ModalHeader>
                    <ModalFooter className="justify-content-center">
                        <div>
                            {/* Dugme za zatvaranje modalnog forma */}
                            <button onClick={() => setModalErrorOpenData(false)} className="button-yes">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>


            <div>
                {spinner ? <Spinner className='spinner' color="primary" id='loader' /> : ''}
            </div>

        </div >
    );

}

export default Preduzeca;


