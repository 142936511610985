// importovanje komponenti 
import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { API } from '../constants';
import axios from '../api/axios';
import { Spinner } from "reactstrap";
import { getCompanyData, defaultComponentOptions } from '../components/utilities';


// komponenta koju kreiramo
function VrstaDok() {
    // definisanje konstanti koje koristimo za fokusiranje polja
    const vrstaRef = useRef();
    const nameRef = useRef();

    // definisanje stejtova i prva inicijalizacija; objekat i funkcija za taj objekat
    const [vrstaDok, setVrstaDok] = useState(null)
    // error row inputs
    const [errorInputVrsta, setErrorInputVrsta] = useState(false);
    const [errorInputName, setErrorInputName] = useState(false);
    // error messages inputs
    const [errorMsgVrsta, setErrorMsgVrsta] = useState(false);
    const [errorMsgName, setErrorMsgName] = useState(false);
    const [pending, setPending] = React.useState(false);
    const [searchInput, setSearchInput] = useState([]);
    const [modalErrorOpenData, setModalErrorOpenData] = useState(false);
    const [modalErrorAuthorizationData, setModalErrorAuthorizationOpen] = useState(false);
    const [spinner, setSpinner] = useState(false);
    // povlacenje podataka iz lokal storidza

    const token = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : null;

    // definisanje stejta(objekat i funkcija za taj objekat), prva inicijalizacija
    const [form, setForm] = useState({
        VRSTA: '',
        NAZIV: '',
        stavkaPronadjena: false,
    })

    // funkcija za povlacenje podataka o preduzecu
    getCompanyData(form)

    // funkcija koja se poziva klikom na dugme 'Sacuvaj'
    const onSubmit = () => {
        // uslov: ako nisu unesena obavezna polja, zaustavlja upis
        if (!unosIspravanDo('UPIS')) return;

        fetch(API + '/vrstaDok/new', {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                response.json()
                setForm({
                    VRSTA: '',
                    NAZIV: ''
                })
                vrstaRef.current.focus()
                getTableData()
            })
    }

    // funkcija koja se poziva klikom na dugme 'Stampa'
    const onPrint = () => {
        setSpinner(true)
        if (vrstaDok && vrstaDok.length === 0) {
            setSpinner(false)
            setModalErrorOpenData(true)
        } else {
            // uslov preko koga saljemo naziv preduzeca sa frontenda na bekend
            // ako je objekat form popunjen, napuni polje NAZIV_PRED iz lokal storidza
            // poziva api za stampu, otvara izvjestaj(API + data.link)
            if (form) {
                form.NAZIV_PRED = form.companyName
            }
            fetch(API + '/vrstaDok/print/', {
                method: 'POST',
                body: JSON.stringify(form),
                responseType: 'blob',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-type': 'application/json; charset=UTF-8'
                },
            })
                .then((response) => response.json())
                .then(data => {
                    if (typeof window != 'undefined') {
                        setTimeout(() => {
                            window.open(API + data.link)
                        }, 200);
                    }
                    setSpinner(false)
                })
        }
    }

    // funkcija koja se poziva pri prvoj inicijalizaciji
    // api za mrezu
    // u responsu puni mrezu
    const getTableData = () => {
        fetch(API + `/vrstaDok/all`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setVrstaDok(data.result)
                setPending(false)
            })
    }

  // prilikom pocetne inicijalizacije, cita mrezu
  useEffect(() => {
    // zaustavljanje fokusa
    const timeout = setTimeout(() => {
        getTableData()
    }, 100);
    return () => {
        clearTimeout(timeout);
    };
}, []);

    // funkcija za brisanje koja se poziva klikom na dugme 'Delete'
    //  api za brisanje
    // u responsu osvjezava polja, daje prvi slobodan broj za sifru, fokus na polje sifra, osvjezava mrezu  
    const onDelete = (VRSTA) => {
         // provjera ovlascenja za brisanje 
         if (form.ovlfakt == 4 || form.ovlfik == 4) return setModalErrorAuthorizationOpen(true)

        fetch(API + '/vrstaDok/delete/' + VRSTA, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                response.json()
                getTableData()
            })
    }

    // funkcija koja se poziva klikom na dugme Edit
    // postavlja fokus na polje NAZIV 
    // popunjava polja podacima iz mreze
    const onEdit = (item) => {
        item.stavkaPronadjena = true
        setForm(item)

        // zaustavljanje fokusa
        const timeout = setTimeout(() => {
            nameRef.current.focus()
        }, 100);
        return () => {
            clearTimeout(timeout);
        };
    }

    // funckija koja se poziva prilikom promjene vrednosti u inputu
    const handleChange = (event) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name;

        setForm((prevState) => ({
            ...prevState,
            [name]: value
        }));

    };

      // uslov: ako nije upisana ni vrijednost, izbaci poruku, setuje crveni okvir i fokusira polje  
    // u suprotnom prolazi dalje
    const checkTypeRequired = (value) => {
        if (!value || value == 0) {
            setErrorMsgVrsta('Vrsta je obavezna!')
            setErrorInputVrsta(true)
            vrstaRef.current.focus();
            return false
        } else {
            setErrorMsgVrsta(null)
            setErrorInputVrsta(false)
            return true
        }
    }

    // uslov: ako nije upisana ni vrijednost, izbaci poruku, setuje crveni okvir i fokusira polje  
    // u suprotnom prolazi dalje
    const checkNameRequired = (value) => {
        if (!value || value == 0) {
            setErrorMsgName('Naziv je obavezan!')
            setErrorInputName(true)
            nameRef.current.focus();
            return false
        } else {
            setErrorMsgName(null)
            setErrorInputName(false)
            return true
        }
    }


  // funkcija za kontrolu obaveznih polja
  function unosIspravanDo(name) {
    if (name === 'VRSTA') {
        return true
    }

    if (!checkTypeRequired(form.VRSTA)) {
        return false
    }

    if (name === 'NAZIV') {
        return true
    }

    if (!checkNameRequired(form.NAZIV)) {
        return false
    }
    return true
}

    // definisu se polja u tabeli
    const columns = [
        {
            name: 'Vrsta',
            selector: row => row.VRSTA,
            width: '100px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.VRSTA}</span>
                </>
            ),

        },
        {
            name: 'Naziv',
            selector: row => row.NAZIV,
            width: '500px',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.NAZIV}</span>
                </>
            ),
        },
        {
            name: 'Action',
            width: '900px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span onClick={() => onEdit(row)} id='media-query-table-buttons' className='btn btn-primary nav flex-column flex-lg-row justify-content-end'>Edit</span>{''}
                    <span onClick={() => onDelete(row.VRSTA)} id='media-query-table-buttons' className='btn btn-danger nav flex-column flex-lg-row justify-content-end'>Brisanje</span>
                </>
            ),
        }
    ];

    // funkcija za pretragu iz mreze
    // salje zahtjev na bekend prema upitu sa parametrima koje posaljemo
    // u responsu vraca podatke koje dobio iz upita 

    const searchChange = (e) => {
        e.preventDefault();
        const searchInput = e.target.value
        setSearchInput(searchInput);
        const fetchData = async () => {
            const res = await axios.get(`${API}/vrstaDok/query/${form.companyCode}?q=${searchInput}`, { headers: { "Authorization": `Bearer ${token}` } });
            setVrstaDok(res.data);
        };
        // uslov za za slanje zahtjeva za povlacenje podataka
        if (searchInput.length === 0 || searchInput.length >= 1) fetchData();

    };

    return (

        <div>
            {/* Kontejner za naslov  */}
            <div className="title-container">

                <header className="title-header">
                    <h1 id="media-query-title">Vrsta dokumenata</h1>
                </header>
                {/* Klasa za ikonice, nalazi se u istom redu kao i naziv, ikonice su formirane kao lista */}


                <div className="naslov-ikonice">
                    <ul className="nav flex-column flex-lg-row justify-content-end">

                        <li className="nav-item toolbaritem">
                            <input type="image" alt="save" className='dugme-sacuvaj' title='Sačuvaj' tabIndex="3" src={require('./../assets/images/floppydisk.png')} onFocus={() => onSubmit()} />
                        </li>

                        <li className="nav-item toolbaritem">
                            <input type="image" alt="print" className='dugme-stampa' title='Štampa' tabIndex="4" src={require('./../assets/images/printer.png')} onClick={onPrint} />
                        </li>

                    </ul>
                </div>

            </div>
            {/* Kontejner pune sirine */}
            <Container fluid>
                <Row>
                    {/* Sirina reda */}
                    <Col lg='12'>
                        <div className='products-form'>
                            {/* Stilovi koji ce se primjenjivati na svim poljima */}
                            <div className="col-6" style={{ display: 'flex', marginBottom: '0px', width: '60%' }}>
                                {/* Sirina polja */}
                                <div className="col-1">
                                    <div className={errorInputVrsta ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px' }} >
                                        <label id="media-query-label">Vrsta:</label>
                                        <input type="text"
                                        autoFocus={true}
                                            id="media-query-input-text"
                                            maxLength="2"
                                            tabIndex="1"
                                            placeholder="Vrsta"
                                            name='VRSTA'
                                            // Postavlja stejt za fokus na true jer je potrebno zbog stepenaste kontrole
                                            value={form?.VRSTA}
                                            ref={vrstaRef}
                                            onFocus={() => {
                                                unosIspravanDo("VRSTA")
                                            }}
                                            // Ako je izmjena na polju
                                            onChange={(e) => handleChange(e)}  
                                            onClick={() => {
                                                // klikom na polje sifra, ciste se ostala polja
                                                setForm(prev => ({
                                                    ...prev, NAZIV: '', stavkaPronadjena: false
                                                }))
                                            }} />
                                        {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                                        {errorMsgVrsta ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgVrsta}</span> : ''}
                                    </div>
                                </div>
                                {/* Sirina polja */}

                                <div className="col-8">
                                    {/* Provjera da li je polje popunjeno jer je obavezno */}
                                    <div className={errorInputName ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px' }} >
                                        <label id="media-query-label">Naziv:</label>
                                        <input type="text"
                                            id="media-query-input-text"
                                            maxLength="40"
                                            ref={nameRef}
                                            tabIndex="2"
                                            placeholder="Naziv"
                                            name='NAZIV'
                                            value={form?.NAZIV}
                                            onFocus={() => {
                                                unosIspravanDo("NAZIV")
                                            }}
                                            // Prilikom prelaska na drugo polje, aktivira se stepenasta kontrola
                                            onChange={(e) => handleChange(e)} />
                                        {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                                        {errorMsgName ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgName}</span> : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

             {/* Stilovi i klase za polje za pretragu;  */}
             <div style={{ display: 'flex' }} className='search'>
                {/* vrsta inputa; poziva funkciju za pretragu(searchChange);  */}
                <input type='text' id="media-query-input-text" placeholder='Pretraga...'
                    onChange={(e) => {
                        // pretraga mreze(ukoliko je unesen bilo koji karakter, vrsi pretragu)
                        if (searchInput.length >= 0) {
                            searchChange(e)
                        } else {
                            getTableData()
                        }
                    }}
                    value={searchInput} />

            </div>
            {/* Mreza */}
            <DataTable
                columns={columns}
                data={vrstaDok ? vrstaDok : []}
                pagination
                responsive
                highlightOnHover
                striped
                dense
                pointerOnHover
                paginationComponentOptions={defaultComponentOptions}
                progressPending={pending}

            />

               {/* render za poruku */}
               <div>
                <Modal isOpen={Boolean(modalErrorAuthorizationData)} centered  >
                    <ModalHeader>Upozorenje!</ModalHeader>
                    <ModalBody>
                        <span>{`Nemate ovlašćenje za brisanje!`}</span>
                    </ModalBody>
                    <ModalFooter className="justify-content-center">
                        <div>
                            <button onClick={() => setModalErrorAuthorizationOpen(false)} className="button-yes">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            {/* Modalni form za gresku; prikazuje se ako nisu unijeta obavezna polja */}
            <div>
                <Modal isOpen={modalErrorOpenData} centered  >
                    <ModalHeader>Nema podataka!</ModalHeader>
                    <ModalFooter className="justify-content-center">
                        <div>
                            {/* Dugme za zatvaranje modalnog forma */}
                            <button onClick={() => setModalErrorOpenData(false)} className="button-yes">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            <div>
                {spinner ? <Spinner className='spinner' color="primary" id='loader' /> : ''}
            </div>

        </div>

    );

}

export default VrstaDok;


