// importovanje komponenti
import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { API } from '../constants';
import axios from '../api/axios';
import { Spinner } from "reactstrap";
import exportIcon from '../assets/export.svg';
import { getCompanyData, defaultComponentOptions } from '../components/utilities';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { defaultFormRegistri } from "../components/utilities";
import { useWindowSize } from '../components/useWindowSize';
import { NumericFormat } from "react-number-format";

// komponenta koju kreiramo
function Poslovna() {
    const windowSize = useWindowSize();
    // definisanje konstanti koje koristimo za fokusiranje polja
    const codeRef = useRef();
    const nameRef = useRef();
    const clientsRef = useRef();
    const placesRef = useRef();
    const valutaRef = useRef();
    const commercialistRef = useRef()
    // definisanje stejtova i prva inicijalizacija; objekat i funkcija za taj objekat
    const [poslovna, setPoslovna] = useState(null)
    const [clients, setClients] = useState(null);
    const [places, setPlaces] = useState(null);
    const [commercialist, setCommercialist] = useState(null);
    const [selectData, setSelectData] = useState([]);
    const [selectDataKomt, setSelectDataKomt] = useState([]);
    const [selectDataPlaces, setSelectDataPlaces] = useState([]);
    const [searchInput, setSearchInput] = useState([]);
    const [spinner, setSpinner] = useState(false);
    const [pending, setPending] = React.useState(false);
    // error row inputs
    const [errorInputClient, setErrorInputClient] = useState(false);
    const [errorInputCode, setErrorInputCode] = useState(false);
    const [errorInputName, setErrorInputName] = useState(false);
    const [errorInputPlaces, setErrorInputPlaces] = useState(false);
    // error messages inputs
    const [errorMsgClient, setErrorMsgClient] = useState(false);
    const [errorMsgName, setErrorMsgName] = useState(false);
    const [errorMsgCode, setErrorMsgCode] = useState(false);
    const [errorMsgPlaces, setErrorMsgPlaces] = useState(false);

    const [modalErrorOpenData, setModalErrorOpenData] = useState(false);
    const [modalErrorAuthorizationData, setModalErrorAuthorizationOpen] = useState(false);
    const [modalDeleteItem, setModalDeleteItem] = useState(false);

    // povlacenje podataka iz lokal storidza
    const token = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : null

    // definisanje stejta(objekat i funkcija za taj objekat), prva inicijalizacija
    const [form, setForm] = useState({
        ...defaultFormRegistri,
    });

    // funkcija za povlacenje podataka o preduzecu
    getCompanyData(form);

    // funkcija koja se poziva klikom na dugme 'Sacuvaj'
    const onSubmit = () => {
        // uslov: ako nisu unesena obavezna polja, zaustavlja upis
        if (!unosIspravanDo('UPIS')) return;

        fetch(API + `/poslovna/new`, {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                response.json()
                // vracanje forme na pocetnu vrijednost
                setForm((prev) => ({
                    ...prev,
                    ...defaultFormRegistri,
                }));
                setClients(null)
                setPlaces(null)
                setCommercialist(null)
                codeRef.current.focus()
                getTableData()
            })
    }


    // funkcija koja se poziva pri prvoj inicijalizaciji
    // api za mrezu
    // u responsu puni mrezi(pomocu funkcije setIme) i puni ukupan broj stranica(setTotal)
    const getTableData = () => {
        fetch(API + `/poslovna/all`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setPoslovna(data.result)
            })
    }

    // prilikom pocetne inicijalizacije, cita mrezu
    useEffect(() => {
        // zaustavljanje fokusa
        const timeout = setTimeout(() => {
            getPoslovnaFirstFreeNumberSIFRA();
            getTableData();
        }, 100);
        return () => {
            clearTimeout(timeout);
        };
    }, []);

    const getCommercialistData = () => {
        fetch(API + `/putnici/all`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectData(data)
            })
    }

    const getKomtData = () => {
        fetch(API + `/komitenti/all/${form.companyCode}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataKomt(data)
            })
    }

    const getPlacesData = () => {
        fetch(API + `/mjesta/all/`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataPlaces(data)
            })
    }


    // funkcija za brisanje koja se poziva klikom na dugme 'Delete'
    //  api za brisanje
    // u responsu osvjezava polja, daje prvi slobodan broj za sifru, fokus na polje SIFRAP, osvjezava mrezu  
    const onDelete = (ID) => {
        // provjera ovlascenja za brisanje 
        if (form.ovlrobno == 4) return setModalErrorAuthorizationOpen(true);

        fetch(API + '/poslovna/delete/' + ID, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                response.json()
                // vracanje forme na pocetnu vrijednost
                setForm((prev) => ({
                    ...prev,
                    ...defaultFormRegistri,
                }));
                setClients(null);
                setPlaces(null);
                setCommercialist(null);
                codeRef.current.focus();
                getTableData();
            })
    }

    // funkcija koja se poziva klikom na dugme Edit
    // postavlja fokus na polje NAZIV 
    // popunjava polja podacima iz mreze
    const onEdit = (item) => {
        nameRef.current.focus()
        setClients(null)
        setPlaces(null)
        setCommercialist(null)
        selectDataKomt.map((client) => {
            if (client.ID == item.KOMTID) {
                setClients(client)
            }
        })
        selectDataPlaces.map((places) => {
            if (places.SIFRA == item.MESTO) {
                setPlaces(places)
            }
        })
        // selectDataValuta.map((valuta) => {
        //     if (valuta.VALUTA == item.VALUTA) {
        //         setValuta(valuta)
        //     }
        // })

        selectData.map((commercialist) => {
            if (commercialist.SIFRA == item.PUTNIK) {
                setCommercialist(commercialist)
            }
        })

        item.stavkaPronadjena = true
        setForm(item);

        // zaustavljanje fokusa
        const timeout = setTimeout(() => {
            nameRef.current.focus()
        }, 100);
        return () => {
            clearTimeout(timeout);
        };

    }

    // funkcija koja se poziva klikom na dugme 'Stampa'
    const onPrint = () => {
        // uslov preko koga saljemo naziv preduzeca sa frontenda na bekend
        // ako je objekat form popunjen, napuni polje NAZIV_PRED iz lokal storidza
        // poziva api za stampu, otvara izvjestaj(API + data.link)
        setSpinner(true);
        if (poslovna && poslovna.length === 0) {
            setSpinner(false)
            setModalErrorOpenData(true)
        } else {
            if (form) {
                form.NAZIV_PRED = form.companyName
            }
            fetch(API + `/izvjestajPoslovnihJedinica/print`, {
                method: 'POST',
                body: JSON.stringify(form),
                responseType: 'blob',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-type': 'application/json; charset=UTF-8'
                },
            })
                .then((response) => response.json())
                .then(data => {
                    if (typeof window != 'undefined') {
                        setTimeout(() => {
                            //     console.log(API + data.link, 'link');
                            window.open(API + data.link)
                        }, 3000);
                    }
                    setSpinner(false);
                })
        }
    }
    // funkcija koja se poziva prilikom promjene vrijednosti u inputu
    const handleChange = (event) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name;

        setForm((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    // funkcija za pronalazenje prvog slobodnog broja
    // poziva api
    // u responsu stejt setForm poziva funkciju za pronalazenje prethodne sifre(iz data) i na osnovu nje odredjuje koja je sljedeca prva slobodna SIFRAP
    // setFirstCode - stejt koji se koristi za razlikovanje izmjene i upisa 

    const getPoslovnaFirstFreeNumberSIFRA = () => {
        fetch(API + `/poslovna/firstFreeNumberSifra`, {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setForm(prev => ({ ...prev, SIFRA: Number(data[0].SIFRA) }))
                // setfirstCode(Number(data))
            })
    }

    // uslov: ako nije upisana ni vrijednost, izbaci poruku, setuje crveni okvir i fokusira polje  
    // u suprotnom prolazi dalje
    const checkCodeRequired = (value) => {
        if (!value || value == 0) {
            setErrorInputCode(true)
            setErrorMsgCode('Šifra je obavezna!')
            codeRef.current.focus();
            return false
        } else {
            setErrorMsgCode(null)
            setErrorInputCode(false)
            return true
        }
    }

    // uslov: ako nije upisana ni vrijednost, izbaci poruku, setuje crveni okvir i fokusira polje  
    // u suprotnom prolazi dalje
    const checkNameRequired = (value) => {
        if (!value || value == 0) {
            setErrorMsgName('Naziv je obavezan!')
            setErrorInputName(true)
            nameRef.current.focus();
            return false
        } else {
            setErrorMsgName(null)
            setErrorInputName(false)
            return true
        }
    }

    // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto) ili ako nije upisana ni vrijednost niti je odabran komitent, izbaci poruku, setuje crveni okvir i fokusira polje
    // u suprotnom prolazi dalje
    const checkClientsRequired = (value) => {
        if ((value && !clients) || (!value && !clients)) {
            setErrorMsgClient("Komitent mora biti izabran!");
            setErrorInputClient(true)
            clientsRef.current.focus();
            return false;
        } else {
            setErrorInputClient(false)
            setErrorMsgClient(null);
            return true;
        }
    };

    // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto) ili ako nije upisana ni vrijednost niti je odabran komitent, izbaci poruku, setuje crveni okvir i fokusira polje
    // u suprotnom prolazi dalje
    const checkPlaceRequired = (value) => {
        if ((value && !places) || (!value && !places)) {
            setErrorMsgPlaces("Mjesto mora biti izabrano!");
            setErrorInputPlaces(true);
            placesRef.current.focus();
            return false;
        } else {
            setErrorMsgPlaces(null);
            setErrorInputPlaces(true);
            return true;
        }
    };

    // funkcija za kontrolu obaveznih polja
    function unosIspravanDo(name) {
        if (name === 'SIFRA') {
            return true
        }

        if (!checkCodeRequired(form.SIFRA)) {
            return false
        }

        if (name === 'NAZIV') {
            return true
        }

        if (!checkNameRequired(form.NAZIV)) {
            return false
        }

        if (name === 'KOMTID') {
            return true
        }

        if (!checkClientsRequired(form.KOMTID)) {
            return false
        }

        if (name === 'MESTO') {
            return true
        }

        if (!checkPlaceRequired(form.MESTO)) {
            return false
        }

        if (name === 'ADRESA') {
            return true
        }

        if (name === 'TEL') {
            return true
        }

        if (name === 'PUTNIK') {
            return true
        }

        if (name === 'IDENTBROJ') {
            return true
        }

        if (name === 'VALUTA') {
            return true
        }

        return true
    }

    useEffect(() => {
        getCommercialistData();
        getKomtData();
        getPlacesData();
    }, [])

    // definisu se polja u tabeli i popunjavaju

    const columns = [
        {
            name: 'Šifra',
            selector: row => row.SIFRA,
            right: 'boolean',
            // sirina polja po rezolucijama
            width:
                windowSize.width > 1920 ? '100px'
                    : windowSize.width > 1680 ? '100px'
                        : windowSize.width > 1600 ? '100px'
                            : windowSize.width > 1440 ? '100px'
                                : windowSize.width > 1280 ? '100px'
                                    : windowSize.width > 1024 ? '80px'
                                        : '80px',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.SIFRA}</span>
                </>
            ),
        },
        {
            name: 'Naziv',
            selector: row => row.NAZIV,
            // sirina polja po rezolucijama
            width:
                windowSize.width > 2048 ? '540px'
                    : windowSize.width > 1920 ? '530px'
                        : windowSize.width > 1680 ? '400px'
                            : windowSize.width > 1600 ? '410px'
                                : windowSize.width > 1440 ? '380px'
                                    : windowSize.width > 1280 ? '300px'
                                        : windowSize.width > 1024 ? '260px'
                                            : '220px',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.NAZIV}</span>
                </>
            ),
        },
        {
            name: 'Šifra k.',
            selector: row => row.komtSifra,
            // sirina polja po rezolucijama
            width:
                windowSize.width > 2048 ? '400px'
                    : windowSize.width > 1920 ? '300px'
                        : windowSize.width > 1680 ? '340px'
                            : windowSize.width > 1600 ? '240px'
                                : windowSize.width > 1440 ? '220px'
                                    : windowSize.width > 1280 ? '220px'
                                        : windowSize.width > 1024 ? '220px'
                                            : '200px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.komtSifra}</span>
                </>
            ),
        },
        {
            name: 'Naziv k.',
            selector: row => row.komtNaziv,
            // sirina polja po rezolucijama
            width:
                windowSize.width > 2048 ? '500px'
                    : windowSize.width > 1920 ? '350px'
                        : windowSize.width > 1680 ? '260px'
                            : windowSize.width > 1600 ? '250px'
                                : windowSize.width > 1440 ? '240px'
                                    : windowSize.width > 1280 ? '200px'
                                        : windowSize.width > 1024 ? '200px'
                                            : '200px',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.komtNaziv}</span>
                </>
            ),
        },
        {
            name: 'Mjesto',
            selector: row => row.mestoNaziv,
            // sirina polja po rezolucijama
            width:
                windowSize.width > 2048 ? '500px'
                    : windowSize.width > 1920 ? '350px'
                        : windowSize.width > 1680 ? '250px'
                            : windowSize.width > 1600 ? '250px'
                                : windowSize.width > 1440 ? '230px'
                                    : windowSize.width > 1280 ? '200px'
                                        : windowSize.width > 1024 ? '200px'
                                            : '200px',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.mestoNaziv}</span>
                </>
            ),
        },
        {
            name: 'Action',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span onClick={() => onEdit(row)} id='media-query-table-buttons' className='btn btn-primary nav flex-column flex-lg-row justify-content-end'>Edit</span>{'     '}
                    <span onClick={() => { onEdit(row); setModalDeleteItem(true) }} id='media-query-table-buttons' className='btn btn-danger nav flex-column flex-lg-row justify-content-end'>Brisanje</span>
                </>

            ),

        }
    ];

    // propsovi za auto komplit  PUTNIKA
    const defaultProps = {
        options: selectData,
        getOptionLabel: (option) => option ? `${option.SIFRA} ${option.NAZIV}` : '',
    };
    // propsovi za uvoznika 

    // propsovi za auto komplit  KOMT
    const defaultPropsClient = {
        options: selectDataKomt,
        getOptionLabel: (option) => option ? `${option.SIFRA} ${option.NAZIV}` : '',
    };

    // MESTO

    const defaultPropsPlaces = {
        options: selectDataPlaces,
        getOptionLabel: (option) => option ? `${option.SIFRA} ${option.NAZIV}` : '',
    };

    // funkcija za pretragu iz mreze
    // salje zahtjev na bekend prema upitu sa parametrima koje posaljemo
    // u responsu vraca podatke koje dobio iz upita 

    const searchChange = (e) => {
        e.preventDefault();
        const searchInput = e.target.value
        setSearchInput(searchInput);
        const fetchData = async () => {
            const res = await axios.get(`${API}/poslovna/query/${form.companyCode}?q=${searchInput}`, { headers: { "Authorization": `Bearer ${token}` } });
            setPoslovna(res.data);
        };
        // uslov za za slanje zahtjeva za povlacenje podataka
        if (searchInput.length === 0 || searchInput.length >= 1) fetchData();

    };

    return (

        <div>
            {/* Kontejner za naslov  */}

            <div className="title-container">
                {/* Naziv registra */}
                <header className="title-header">
                    <h1 id="media-query-title">Poslovne jedinice</h1>
                </header>


                <div className="naslov-ikonice">
                    <ul className="nav flex-column flex-lg-row justify-content-end">

                        <li className="nav-item toolbaritem">
                            <input type="image" alt="save" className='dugme-sacuvaj' title='Sačuvaj' tabIndex="10" src={require('./../assets/images/floppydisk.png')} onFocus={onSubmit} />
                        </li>

                        <li className="nav-item toolbaritem">
                            <input type="image" alt="print" className='dugme-stampa' title='Štampa' tabIndex="11" src={require('./../assets/images/printer.png')} onClick={onPrint} />
                        </li>

                    </ul>
                </div>

            </div>
            {/* Kontejner pune sirine */}
            <Container fluid >
                <Row>
                    <div className='products-form'>
                        <Row>
                            {/* Stilovi koji ce se primjenjivati na svim poljima */}
                            <div className="col-6" style={{ display: 'flex', paddingRight: '0px', paddingLeft: '-1px', marginRight: 'auto', marginLeft: '0px' }}>
                                <div className="col-1">
                                    <div className='col-form-right'>
                                        <div className={errorInputCode ? "inputWithError" : "field-container"} style={{ marginRight: '3px' }} >
                                            <label id="media-query-label">Šifra:</label>
                                            <NumericFormat
                                                type="text"
                                                className='field-alignment p-1'
                                                getInputRef={codeRef}
                                                id="media-query-input-text"
                                                tabIndex="1"
                                                decimalScale={0}
                                                maxLength="6"
                                                autoFocus={true}
                                                placeholder="Šifra"
                                                name='SIFRA'
                                                value={form?.SIFRA}
                                                // Prilikom prelaska na drugo polje, aktivira se stepenasta kontrola
                                                onFocus={() => {
                                                    getPoslovnaFirstFreeNumberSIFRA();
                                                    unosIspravanDo("SIFRA")
                                                }}
                                                // Ako je izmjena na polju
                                                onChange={(e) => handleChange(e)}
                                                onClick={() => {
                                                    // ako je kliknuto na dugme Edit, a zatim na sifru - cisti polja  
                                                    setForm(prev => ({
                                                        ...prev, NAZIV: '', ADRESA: '', TEL: '', IDENTBROJ: '', VALUTA: '', stavkaPronadjena: false
                                                    }))
                                                    setClients(null);
                                                    setCommercialist(null);
                                                    setPlaces(null);
                                                }} />
                                            {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                                            {errorMsgCode ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgCode}</span> : ''}
                                        </div>
                                    </div>
                                </div>

                                {/* Sirina polja */}

                                <div className="col-6">
                                    {/* Provjera da li je polje popunjeno jer je obavezno */}
                                    <div className={errorInputName ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px' }} >
                                        <label id="media-query-label">Naziv:</label>
                                        <input type="text"
                                            id="media-query-input-text"
                                            maxLength="80"
                                            tabIndex="2"
                                            placeholder="naziv"
                                            name='NAZIV'
                                            value={form?.NAZIV}
                                            ref={nameRef}
                                            // Postavlja stejt za fokus na true jer je potrebno zbog stepenaste kontrole
                                            onFocus={() => {
                                                unosIspravanDo("NAZIV")
                                                // uslov za citanje sloga po sifri
                                                let selectedItem = []
                                                selectedItem = poslovna.filter((c) => c.SIFRA == form.SIFRA)
                                                if (selectedItem.length > 0) {
                                                    onEdit(selectedItem[0])
                                                }
                                            }}

                                            // Ako je izmjena na polju
                                            onChange={(e) => handleChange(e)} />
                                        {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                                        {errorMsgName ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgName}</span> : ''}

                                    </div>
                                </div>

                                {/* Sirina polja */}
                                <div className="col-5">
                                    <div className={errorInputClient ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px' }} >
                                        <label id="media-query-label">Komitent:</label>
                                        {/* Ako je Autocomplete  poziva na odabri MESTO*/}
                                        <Autocomplete
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            {...defaultPropsClient}
                                            value={clients}
                                            disablePortal={true}
                                            onFocus={() => {
                                                unosIspravanDo("KOMTID")
                                            }}
                                            onChange={(event, newValue) => {
                                                setForm(prev => ({ ...prev, KOMTID: newValue && newValue != null ? newValue.ID : '' }))
                                                setClients(newValue);
                                                // fokus se mora vratiti inace tabIndex ne radi dobro
                                                clientsRef.current.focus();
                                            }}
                                            renderInput={(params) => (
                                                <TextField id='media-query' {...params} className="clients-textinput" name='KOMTID'
                                                    ref={clientsRef}
                                                    tabIndex="3"
                                                    placeholder=' komitent'
                                                    variant="standard" />
                                            )}
                                        />
                                        {errorMsgClient ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgClient}</span> : ''}
                                    </div>
                                </div>
                            </div>
                            <div className="col-6" style={{ display: 'flex', paddingRight: '0px', paddingLeft: '0px', marginRight: 'auto', marginLeft: '0px' }}>
                                {/* Sirina polja */}
                                <div className="col-5">
                                    <div className={errorInputPlaces ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px' }} >
                                        <label id="media-query-label">Mjesto:</label>
                                        <Autocomplete
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            {...defaultPropsPlaces}
                                            value={places}
                                            onFocus={() => {
                                                unosIspravanDo("MESTO")
                                            }}
                                            onChange={(event, newValue) => {
                                                setPlaces(newValue);
                                                setForm(prev => ({ ...prev, MESTO: newValue && newValue != null ? newValue.SIFRA : '' }))
                                                // fokus se mora vratiti inace tabIndex ne radi dobro
                                                placesRef.current.focus();
                                            }}

                                            renderInput={(params) => (
                                                <TextField {...params}
                                                    className="clients-textinput"
                                                    name='MESTO'
                                                    ref={placesRef}
                                                    tabIndex={4}
                                                    autoComplete="on"
                                                    placeholder='Mjesto'
                                                    variant="standard" />
                                            )}
                                        />
                                        {errorMsgPlaces ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgPlaces}</span> : ''}
                                    </div>
                                </div>

                                <div className="col-7">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label id="media-query-label">Adresa:</label>
                                        <input type="text"
                                            id="media-query-input-text"
                                            maxLength="80"
                                            tabIndex="5"
                                            placeholder="Adresa"
                                            name='ADRESA'
                                            value={form ? form.ADRESA : ''}
                                            onFocus={() => {
                                                unosIspravanDo("ADRESA")
                                            }}
                                            onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>
                            </div>
                        </Row>
                        <Row>
                            {/* Sirina reda */}
                            <div className="col-12" style={{ display: 'flex' }}>

                                <div className="col-3">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label id="media-query-label">Telefon:</label>
                                        <input type="text"
                                            id="media-query-input-text"
                                            maxLength="50"
                                            tabIndex="6"
                                            placeholder="Telefon"
                                            name='TEL'
                                            value={form ? form.TEL : ''}
                                            onFocus={() => {
                                                unosIspravanDo("TEL")
                                            }}
                                            onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>

                                <div className="col-2">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label id="media-query-label">Putnik:</label>
                                        {/* Ako je Autocomplete  poziva na odabri mjesta*/}
                                        <Autocomplete
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            {...defaultProps}
                                            value={commercialist}
                                            disablePortal={true}
                                            onChange={(event, newValue) => {
                                                setForm(prev => ({ ...prev, PUTNIK: newValue && newValue != null ? newValue.SIFRA : '' }))
                                                setCommercialist(newValue);
                                                // fokus se mora vratiti inace tabIndex ne radi dobro
                                                commercialistRef.current.focus();
                                            }}
                                            renderInput={(params) => (
                                                <TextField id='media-query' {...params}
                                                    className="clients-textinput"
                                                    name='PUTNIK'
                                                    ref={commercialistRef}
                                                    tabIndex="7"
                                                    placeholder=' putnik'
                                                    variant="standard" />
                                            )}
                                        />
                                    </div>
                                </div>

                                {/* Sirina polja */}
                                <div className="col-1">
                                    {/* Provjera  polja i stilova */}
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label id="media-query-label">JIB:</label>
                                        <input type="text"
                                            id="media-query-input-text"
                                            maxLength="20"
                                            tabIndex="8"
                                            placeholder="Jib"
                                            name='IDENTBROJ'
                                            value={form ? form.IDENTBROJ : ''}
                                            onFocus={() => {
                                                unosIspravanDo("IDENTBROJ")
                                            }}
                                            onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>

                                <div className="col-1">
                                    <div className='col-form-right'>
                                        <div className='field-container' style={{ marginRight: '3px' }}>
                                            <label id="media-query-label">Valuta:</label>
                                            <NumericFormat
                                                type="text"
                                                className='field-alignment p-1'
                                                getInputRef={valutaRef}
                                                id="media-query-input-text"
                                                maxLength="6"
                                                decimalScale={0}
                                                tabIndex="9"
                                                placeholder="valuta"
                                                name='VALUTA'
                                                value={form ? form.VALUTA : ''}
                                                onFocus={() => {
                                                    unosIspravanDo("VALUTA")
                                                }}
                                                onChange={(e) => handleChange(e)} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </Row>
                    </div>
                </Row >
            </Container >



            {/* Stilovi i klase za polje za pretragu;  */}
            <div style={{ display: 'flex' }} className='search'>
                {/* vrsta inputa; poziva funkciju za pretragu(searchChange);  */}
                <input type='text' tabIndex='-1' id="media-query-input-text" placeholder='Pretraga...'
                    onChange={(e) => {
                        // pretraga mreze(ukoliko je unesen bilo koji karakter, vrsi pretragu)
                        if (searchInput.length >= 0) {
                            searchChange(e)
                        } else {
                            getTableData()
                        }
                    }}
                    value={searchInput} />
            </div>

            {/* Mreza */}
            <DataTable
                columns={columns}
                data={poslovna ? poslovna : []}
                pagination
                responsive
                highlightOnHover
                striped
                dense
                pointerOnHover
                paginationComponentOptions={defaultComponentOptions}
                paginationPerPage={[windowSize.height > 1300 ? 25
                    : windowSize.height > 1270 ? 25
                        : windowSize.height > 1246 ? 24
                            : windowSize.height > 1222 ? 23
                                : windowSize.height > 1200 ? 23
                                    : windowSize.height > 1000 ? 19
                                        : windowSize.height > 920 ? 16
                                            : windowSize.height > 900 ? 15
                                                : windowSize.height > 880 ? 14
                                                    : windowSize.height > 820 ? 13
                                                        : windowSize.height > 720 ? 11
                                                            : 4]}
                progressPending={pending}
            />

            <div>
                <Modal isOpen={Boolean(modalErrorOpenData)} centered  >
                    <ModalHeader>Nema podataka!</ModalHeader>
                    <ModalFooter className="justify-content-center">
                        <div>
                            {/* Dugme za zatvaranje modalnog forma */}
                            <button onClick={() => { setModalErrorOpenData(false); nameRef.current.focus() }} className="button-yes">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            {/* render za poruku */}
            <div>
                <Modal isOpen={Boolean(modalDeleteItem)} centered>
                    <ModalHeader>Brisanje stavke</ModalHeader>
                    <ModalBody>
                        <span>{`Da li želite da obrišete stavku broj ${form.SIFRA} ? `}</span>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            <button
                                onClick={() => { setModalDeleteItem(false); nameRef.current.focus() }}
                                className="button-no">Ne
                            </button>
                            <button
                                onClick={() => {
                                    onDelete(form.ID);
                                    setModalDeleteItem(false)
                                }}
                                className="button-yes">Da
                            </button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            {/* render za poruku */}
            <div>
                <Modal isOpen={Boolean(modalErrorAuthorizationData)} centered  >
                    <ModalHeader>Upozorenje!</ModalHeader>
                    <ModalBody>
                        <span>{`Nemate ovlašćenje za brisanje!`}</span>
                    </ModalBody>
                    <ModalFooter className="justify-content-center">
                        <div>
                            <button onClick={() => { setModalErrorAuthorizationOpen(false); nameRef.current.focus() }} className="button-yes">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            <div>
                {spinner ? <Spinner className='spinner' color="primary" id='loader' /> : ''}
            </div>
        </div >
    );

}

export default Poslovna;


