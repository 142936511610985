import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { API } from '../../constants';
import { RadioButton } from '../radioButton';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Spinner } from "reactstrap";
import { numberWithCommas, numberWithCommasColumns, roundTO, getCompanyData, defaultComponentOptions } from '../utilities';
import { useWindowSize } from '../useWindowSize';

function AnalitikaIBilans() {
    const windowSize = useWindowSize();
    const [finBrutoBilans, setFinBrutoBilans] = useState(null);
    const [sumduguje, setSumDuguje] = useState(0);
    const [sumpotraz, setSumPotraz] = useState(0);
    const [sum, setSum] = useState(0);
    const [konto, setKonto] = useState(null);
    const [kontoDo, setKontoDo] = useState(null);
    const [selectDataKonto, setSelectDataKonto] = useState([]);
    const [spinner, setSpinner] = useState(false);
    const [modalErrorOpenPrint, setModalErrorOpenPrint] = useState(false);
    const [pending, setPending] = React.useState(false);

    // stejtovi za spinere na dugmadima
    const [faSpin, setFaSpin] = useState(false);
    const [faSpinB, setFaSpinB] = useState(false);

    const [errorDateFrom, setErrorDateFrom] = useState(false);
    const [errorDateTo, setErrorDateTo] = useState(false);
    const [errorMsgDateFrom, setErrorMsgDateFrom] = useState(false);
    const [errorMsgDateTo, setErrorMsgDateTo] = useState(false);

    const kontoOdRef = useRef();
    const kontoDoRef = useRef();
    const datumOdRef = useRef();
    const datumDoRef = useRef();

    const [date, setDate] = useState({
        checkDatDok: true,
        checkDatNalog: false
    });

    const [form, setForm] = useState({
        KONTOOD: '',
        KONTODO: '',
        DATUMOD: '',
        DATUMDO: '',
        PROVJERA: '',
        buttonType: '',
    })

    // funkcija za povlacenje podataka o preduzecu
    getCompanyData(form)

    // funkcija za popunjavanje datumskih polja u zavisnosti od godine
    const checkCompanyYear = () => {
        //  let dateFrom
        //  let dateTo
        let currentDate = new Date()
        if (currentDate.getFullYear() === Number(form.companyYear)) {
            setForm(prev => ({ ...prev, DATUMOD: moment.unix(new Date(currentDate.getFullYear(), 0, 1).getTime() / 1000).format("DD.MM.YYYY"), DATUMDO: moment.unix(new Date().getTime() / 1000).format("DD.MM.YYYY") }))
        } else {
            let oldYear = new Date()
            oldYear = new Date(Number(oldYear.setFullYear(form.companyYear)))
            setForm(prev => ({ ...prev, DATUMOD: moment.unix(new Date(oldYear.getFullYear(), 0, 1).getTime() / 1000).format("DD.MM.YYYY"), DATUMDO: moment.unix(new Date(Number(oldYear.setFullYear(form.companyYear))).getTime() / 1000).format("DD.MM.YYYY") }))
        }
    }

    const getReports = () => {
        if (form) {
            form.checkDatDok = date.checkDatDok;
            form.KONTOOD = konto && konto.KONTO ? konto.KONTO : form.KONTOOD;
            form.KONTODO = kontoDo && kontoDo.KONTO ? kontoDo.KONTO : form.KONTODO;
        }

        fetch(API + `/analitikaIBilansMreza/all/${form.companyCode}`, {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                'Authorization': `Bearer ${form.token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setFinBrutoBilans(data.items)
                let DUGUJESum = 0;
                let POTRAZSum = 0;
                let SALDOSum = 0;

                // petlja za citanje podataka
                for (let i = 0; i < data.items.length; i++) {
                    DUGUJESum += data.items[i].DUGUJE
                    POTRAZSum += data.items[i].POTRAZ
                    SALDOSum += (data.items[i].DUGUJE) - (data.items[i].POTRAZ)
                }
                setSum(numberWithCommasColumns(roundTO(SALDOSum, 2)))
                setSumDuguje(numberWithCommasColumns(roundTO(DUGUJESum, 2)))
                setSumPotraz(numberWithCommasColumns(roundTO(POTRAZSum, 2)))
                setPending(false)
            })
    }

    const print = () => {
        setSpinner(true)
        // uslov: ako nije izabran datum od datum do i ako nije izabran izvjestaj(nije kliknuto na dugme za izvjestaj), nece otvoriti izvjestaj 
        if (!unosIspravanDo('PRINT')) return setSpinner(false)
        if (form.buttonType === "") return setSpinner(false), setModalErrorOpenPrint(true)
        //filter
        if (form) {
            form.NAZIV_PRED = form.companyName;
            form.KONTOOD = konto && konto.KONTO ? konto.KONTO : form.KONTOOD;
            form.KONTODO = kontoDo && kontoDo.KONTO ? kontoDo.KONTO : form.KONTODO;
            form.checkDatDok = date.checkDatDok;
        }

        fetch(API + `/` + form.buttonType + `/print/${form.companyCode}`, {
            method: 'POST',
            body: JSON.stringify({ form }),
            headers: {
                'Authorization': `Bearer ${form.token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                window.open(API + data.link)

                // zatvaranje spinera
                setSpinner(false)
            })
    }

    // funkcija koja poziva citanje konta
    const getKontoData = () => {
        fetch(API + `/konto/all`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${form.token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataKonto(data)
            })
    }

    useEffect(() => {
        getKontoData()
    }, [])


    // cekiranje radio dugmadi u zavisnosti odabrane opcije
    const radioChangeHandler = (e) => {
        if (e.target.name === 'checkDatDok') {
            setDate({
                // odabir datuma dokumenata
                checkDatDok: true,
                checkDatNalog: false
            });
        } else {
            setDate({
                checkDatDok: false,
                // odabir datuma storniranja
                checkDatNalog: true
            });
        }
    };

    // funkcija koja se poziva prilikom promjene vrijednosti u inputu
    const handleChange = (event) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name;

        setForm((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    // funkcija za kontrolu obaveznih polja
    function unosIspravanDo(name) {

        if (name === 'KONTOOD') {
            return true
        }

        if (name === 'KONTODO') {
            return true
        }

        if (name === 'DATUMOD') {
            return true
        }

        let reqex = /^(0[1-9]|[12]\d|3[01]).(0[1-9]|1[0-2]).([12]\d{3})$/gi;

        if (!reqex.test(form.DATUMOD)) {
            setErrorDateFrom(true)
            setErrorMsgDateFrom('Datum od nije validan!')
            datumOdRef.current.focus();
            return false
        } else {
            setErrorDateFrom(false)
            setErrorMsgDateFrom(null)
        }

        if (name === 'DATUMDO') {
            return true
        }

        let reqexDATUMDO = /^(0[1-9]|[12]\d|3[01]).(0[1-9]|1[0-2]).([12]\d{3})$/gi;

        if (!reqexDATUMDO.test(form.DATUMDO)) {
            setErrorDateTo(true)
            setErrorMsgDateTo('Datum do nije validan!')
            datumDoRef.current.focus();
            return false
        } else {
            setErrorDateTo(false)
            setErrorMsgDateTo(null)
        }

        if (name === 'PROVJERA') {
            return true
        }

        return true
    }

    let sirinaZaKoloneVrijednosti = windowSize.width > 1920 ? '220px'
        : windowSize.width > 1680 ? '120px'
            : windowSize.width > 1600 ? '120px'
                : windowSize.width > 1440 ? '120px'
                    : windowSize.width > 1280 ? '120px'
                        : windowSize.width > 1024 ? '100px'
                            : '80px';

    // funkcija za popunjavanje kolona u mrezi
    function checkingFields() {
        let konto =
        {
            name: 'Konto',
            selector: row => row.KONTO,
            // sirina polja po rezolucijama
            width:
                windowSize.width > 2048 ? '200px'
                    : windowSize.width > 1920 ? '100px'
                        : windowSize.width > 1680 ? '100px'
                            : windowSize.width > 1600 ? '100px'
                                : windowSize.width > 1440 ? '100px'
                                    : windowSize.width > 1280 ? '80px'
                                        : windowSize.width > 1024 ? '80px'
                                            : '80px',
        }
        let kontoNaziv =
        {
            name: 'Naziv konta',
            selector: row => row.NAZIV_KONTA,
            // sirina polja po rezolucijama
            width:
                windowSize.width > 2048 ? '1330px'
                    : windowSize.width > 1920 ? '1000px'
                        : windowSize.width > 1680 ? '1000px'
                            : windowSize.width > 1600 ? '950px'
                                : windowSize.width > 1440 ? '870px'
                                    : windowSize.width > 1280 ? '720px'
                                        : windowSize.width > 1024 ? '700px'
                                            : '175px',
        }
        let duguje =
        {
            name: 'Duguje',
            selector: row => row.DUGUJE,
            // sirina polja po rezolucijama
            width: sirinaZaKoloneVrijednosti,
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{numberWithCommasColumns(row.DUGUJE)}</span>
                </>
            ),
        }
        let potraz =
        {
            name: 'Potražuje',
            selector: row => row.POTRAZ,
            // sirina polja po rezolucijama
            width: sirinaZaKoloneVrijednosti,
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{numberWithCommasColumns(row.POTRAZ)}</span>
                </>
            ),
        }
        let saldo =
        {
            name: 'Saldo',
            selector: row => row.SALDO,
            // sirina polja po rezolucijama
            width: sirinaZaKoloneVrijednosti,
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{numberWithCommasColumns(row.SALDO)}</span>
                </>
            ),
        }
        return [konto, kontoNaziv, duguje, potraz, saldo]
    }

    const defaultPropsKonto = {
        options: selectDataKonto,
        getOptionLabel: (option) => option ? `${option.KONTO} ${option.NAZ}` : '',
    };

    return (

        <div>
            {/* Kontejner za naslov  */}
            <div className="title-container">
                {/* Naziv forma */}
                <header className="title-header">
                    <h1 id="media-query-title">Analitička kartica po kontima</h1>
                </header>

                <div >
                    <div>
                        {/* Klasa za ikonice, stampa*/}
                        <ul >
                            <input type="image" alt="print" className='dugme-stampa' title='Štampa' tabIndex="7" src={require('../../assets/images/printer.png')} onClick={print} />
                        </ul>
                    </div>
                </div>
            </div>
            <Container fluid>
                <div className="row">
                    <Col lg='2'>

                        <div className='form-field-container' style={{ display: 'contents' }}>
                            {/* red sirine */}
                            <Col lg='12'>
                                {/* definsanje froma za radio dugmad */}
                                <div className="col-sm radio-box" id='media-query-radioButton-date'>
                                    <RadioButton
                                        changed={(e) => radioChangeHandler(e)}
                                        isSelected={date?.checkDatDok}
                                        label="Dat.dok."
                                        id="1"
                                        value={date?.checkDatDok}
                                        name='checkDatDok'
                                    />
                                    {/*  radio dugme za datum storniranja   */}
                                    <RadioButton
                                        changed={(e) => radioChangeHandler(e)}
                                        isSelected={date?.checkDatNalog}
                                        label="Dat.nal."
                                        id="2"
                                        value={date?.checkDatNalog}
                                        name='checkDatNalog'
                                    />
                                </div>
                            </Col>
                        </div>
                    </Col>

                    <Col lg='6'>

                        <div className="col-12" style={{ display: 'flex', }}>
                            <div className="col-12">

                                <div className="form-group row label-box-div">
                                    <label className="col-form-label col-lg-2 label-box" id="media-query-label-report">Konto od:</label>
                                    <div className="col-4">
                                        <Autocomplete
                                            {...defaultPropsKonto}
                                            freeSolo
                                            value={konto}
                                            disablePortal={true}
                                            onFocus={() => {
                                                unosIspravanDo("KONTOOD");
                                            }}
                                            onChange={(event, newValue) => {
                                                setForm(prev => ({ ...prev, KONTOOD: newValue && newValue != '' ? newValue.ID : '' }))
                                                setKonto(newValue);
                                                // fokus se mora vratiti inace tabIndex ne radi dobro
                                                kontoOdRef.current.focus()
                                            }}

                                            renderInput={(params) => (
                                                <TextField {...params}
                                                    autoFocus
                                                    tabIndex="1"
                                                    className="clients-textinput"
                                                    ref={kontoOdRef}
                                                    value={form?.KONTOOD}
                                                    placeholder=' Izaberite konto'
                                                    name='KONTOOD'
                                                    variant="standard"
                                                    onChange={(e) => handleChange(e)} />
                                            )}
                                        />
                                    </div>

                                    <label className="col-form-label col-lg-1 label-box" id="media-query-label-DateTo">do:</label>
                                    <div className="col-4">
                                        <Autocomplete
                                            {...defaultPropsKonto}
                                            freeSolo
                                            value={kontoDo}
                                            disablePortal={true}
                                            onFocus={() => {
                                                unosIspravanDo("KONTODO");
                                                // focus se mora vratiti na polje ako se upisuje vrijednost(ne uzima se se vrijednost iz liste)  
                                                //   const timeout = setTimeout(() => {
                                                //     kontoDoRef.current.focus()
                                                // }, 200);
                                                // return () => {
                                                //     clearTimeout(timeout);
                                                // };
                                            }}
                                            onChange={(event, newValue) => {
                                                setForm(prev => ({ ...prev, KONTDO: newValue && newValue != '' ? newValue.ID : '' }))
                                                setKontoDo(newValue);
                                                // fokus se mora vratiti inace tabIndex ne radi dobro
                                                kontoDoRef.current.focus()
                                            }}

                                            renderInput={(params) => (
                                                <TextField {...params}
                                                    tabIndex="2"
                                                    value={form?.KONTODO}
                                                    className="clients-textinput"
                                                    ref={kontoDoRef}
                                                    placeholder=' Izaberite konto'
                                                    name='KONTODO'
                                                    variant="standard"
                                                    onChange={(e) => {
                                                        handleChange(e)
                                                        // focus se mora vratiti na polje ako se upisuje vrijednost(ne uzima se se vrijednost iz liste)  
                                                        const timeout = setTimeout(() => {
                                                            kontoDoRef.current.focus()
                                                        }, 200);
                                                        return () => {
                                                            clearTimeout(timeout);
                                                        };
                                                    }} />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12" style={{ display: 'flex', }}>
                            <div className="col-12">

                                <div className="form-group row label-box-div">
                                    <label className="col-form-label col-lg-2 label-box" id="media-query-label-report">Datum od:</label>
                                    <div className="col-2">
                                        <div className={errorDateFrom ? 'inputWithError' : 'field-container'}>
                                            <input
                                                type='text'
                                                ref={datumOdRef}
                                                tabIndex="3"
                                                id="media-query-input"
                                                onFocus={() => {
                                                    unosIspravanDo("DATUMOD")
                                                    checkCompanyYear();
                                                }}
                                                placeholder="datum od"
                                                name='DATUMOD'
                                                value={form?.DATUMOD}
                                                onChange={(e) => handleChange(e)} />
                                            {errorMsgDateFrom ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgDateFrom}</span> : ''}
                                        </div>
                                    </div>

                                    <label className="col-form-label col-lg-3 label-box" id="media-query-label-DateTo">do:</label>
                                    <div className="col-2">
                                        <div className={errorDateTo ? 'inputWithError' : 'field-container'}>
                                            <input type='text'
                                                tabIndex="4"
                                                id="media-query-input-to"
                                                ref={datumDoRef}
                                                onFocus={() => {
                                                    unosIspravanDo("DATUMDO")
                                                }}
                                                placeholder="datum do"
                                                value={form?.DATUMDO}
                                                name='DATUMDO'
                                                onChange={(e) => handleChange(e)} />
                                            {errorMsgDateTo ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgDateTo}</span> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg='4'>
                        <Row>
                            <Col lg='12' >
                                <div className=" flex-lg-row justify-content-end" id='media-query-buttonType'>
                                    <div style={{ display: 'inline-grid' }} className={faSpin ? "btn-report-red" : "btn-report"}>
                                        <button
                                            tabIndex={5}
                                            onFocus={() => {
                                                unosIspravanDo("PROVJERA")
                                            }}
                                            onClick={() => {
                                                // uslov: ako nisu unesena obavezna polja, zaustavlja upis
                                                if (!unosIspravanDo('DUGME')) return;
                                                form.buttonType = 'sintetikaBrutoBilans'
                                                setFaSpin(true)
                                                setFaSpinB(false)
                                                setPending(true)
                                                getReports()
                                            }}>{faSpin && !finBrutoBilans ? <i className="fa fa-spinner fa-spin"></i> : ''}Bruto bilans sintetika</button>
                                    </div>
                                    <div style={{ display: 'inline-grid' }} className={faSpinB ? "btn-report-red" : "btn-report"}>
                                        <button tabIndex={6}
                                            onClick={() => {
                                                // uslov: ako nisu unesena obavezna polja, zaustavlja upis
                                                if (!unosIspravanDo('DUGME')) return;
                                                form.buttonType = 'brutoBilans'
                                                setFaSpin(false)
                                                setFaSpinB(true)
                                                setPending(true)
                                                getReports()
                                            }}>{faSpinB && !finBrutoBilans ? <i className="fa fa-spinner fa-spin"></i> : ''}Bruto bilans</button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </div>
            </Container >
            {
                < DataTable
                    columns={form.buttonType ? checkingFields() : []}
                    data={finBrutoBilans ? finBrutoBilans : []}
                    pagination
                    responsive
                    striped
                    dense
                    pointerOnHover
                    paginationComponentOptions={defaultComponentOptions}
                    paginationPerPage={[windowSize.height > 1300 ? 32
                        : windowSize.height > 1246 ? 29
                            : windowSize.height > 1222 ? 28
                                : windowSize.height > 1200 ? 27
                                    : windowSize.height > 1000 ? 23
                                        : windowSize.height > 920 ? 19
                                            : windowSize.height > 900 ? 18
                                                : windowSize.height > 880 ? 17
                                                    : windowSize.height > 820 ? 15
                                                        : windowSize.height > 720 ? 14
                                                            : 4]}
                    progressPending={pending}
                />
            }

            <div>
                <Modal isOpen={modalErrorOpenPrint} centered  >
                    <ModalHeader>Upozorenje!</ModalHeader>
                    <ModalBody>
                        <span>{`Izaberite izvještaj koji želite štampati!`}</span>
                    </ModalBody>
                    <ModalFooter className="justify-content-center">
                        <div>
                            {/* Dugme za zatvaranje modalnog forma */}
                            <button onClick={() => setModalErrorOpenPrint(false)} className="button-yes">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            <div className="naslov-ikonice">
                <ul className="nav flex-column flex-lg-row justify-content-end">
                    <div style={{ display: 'flex', justifyContent: 'end', position: 'fixed', bottom: '0', flex: '0 0 auto' }}>

                        <li className="nav-item toolbaritem" style={{ width: '23%', marginRight: '5px' }}>
                            <div className='col-form-right'>
                                <div className="field-container-number-totals">
                                    <label id="media-query-label-sum">Duguje</label>
                                    <input className="form-control" readOnly={true} tabIndex="-1" type='text' id="media-query-input-text" value={sumduguje && sumduguje != 'NaN' ? sumduguje : 0} />
                                </div>
                            </div>
                        </li>

                        <li className="nav-item toolbaritem" style={{ width: '23%', marginRight: '4px' }}>
                            <div className='col-form-right'>
                                <div className="field-container-number-totals">
                                    <label id="media-query-label-sum">Potražuje</label>
                                    <input className="form-control" readOnly={true} type='text' tabIndex="-1" id="media-query-input-text" value={sumpotraz && sumpotraz != 'NaN' ? sumpotraz : 0} />
                                </div>
                            </div>
                        </li>

                        <li className="nav-item toolbaritem" style={{ width: '23%', marginRight: '7px' }}>
                            <div className='col-form-right'>
                                <div className="field-container-number-totals">
                                    <label id="media-query-label-sum">Saldo</label>
                                    <input type='text' className="total" readOnly={true} id="media-query-input-text" tabIndex="-1" value={sum && sum != 'NaN' ? (sum) : 0} />
                                </div>
                            </div>
                        </li>
                    </div>
                </ul>
            </div>

            <div>
                {spinner ? <Spinner className='spinner' color="primary" id='loader' /> : ''}
            </div>
        </div >
    );
}

export default AnalitikaIBilans;


