import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import styled from "styled-components";
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as FaWindowClose from "react-icons/fa";
import { SidebarData } from "./sidebarData";
import SubMenu from "./subMenu";
import SubMenuItems from "./subMenuItems";
//import { IconContext } from 'react-icons/lib';
import SVG from "react-inlinesvg";
import Navbar from "./navbar";
import logoutIcon from "../assets/logout3.svg";
import userIcon from "../assets/user.svg";
import { useNavigate } from "react-router-dom";
import {
  ovlascenje,
  ovlfik,
  ovlrobno,
  ovlfakt,
  ovlblagajna,
} from "../components/utilities";

const Nav = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  background-color: #015092;
  border-radius: 5px;
  margin-left: 2px .hr {
    border: 10px solid green;
    border-radius: 5px;
    width: 100%;
  }

  .search {
    position: relative;

    input {
      width: 220px;
    }

    &-icon {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translate(0, -50%);
      width: 16px;
    }
  }
`;

const NavIcon = styled(Link)`
  margin-left: 2rem;
  font-size: 2rem;
  height: 35px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const SidebarNav = styled.nav`
  // background: #060b26;
  max-width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  transition: 350ms;
  z-index: 10;
  overflow: auto;
  a {
    svg {
      background-color: white;
      fill: black;
      width: 20%;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 0;
      font-size: 1 rem;
    }
  }
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

const Sidebar = (props) => {
  const [inactive, setInactive] = useState(false);
  const [modalLogout, setModalLogout] = useState(false);
  const [sidebar, setSidebar] = useState(
    typeof window != "undefined" && window.innerWidth < 1440 ? false : true
  );
  const navigate = useNavigate();

  const [text, setText] = useState("");

  // navigacija ka korisnicima
  const navigateToUsers = () => {
    navigate("/user");
  };

    // navigacija ka loginu
    const navigateToLogin = () => {
      navigate("/");
    };

  const showSidebar = () => {
    setInactive(false);
    setSidebar(!sidebar);
  };

  const toggleSidebar = () => {
    setInactive(true);
    setSidebar(!sidebar);
  };

  // console.log(window.innerHeight, 'visina', window.innerWidth, 'sirina');
  // console.log(sidebar);

  //console.log(inactive, 'sidebar');
  useEffect(() => {
    props.onCollapse(inactive);
  }, [inactive]);

  const companyCode =
    typeof window !== "undefined"
      ? localStorage.getItem("companyYear") +
        " " +
        localStorage.getItem("company") +
        " " +
        localStorage.getItem("companyNaziv")
      : null;

  const removeToken = () => {
    if (typeof window !== "undefined" && localStorage.getItem("accessToken")) {
      localStorage.clear();
    //  sessionStorage.clear();
      navigateToLogin();
     // window.location.reload(true);
    }
  };

  return (
    <>
      <SVG value={{ color: "#fff" }}>
        <Nav className="mb-0" id="media-query-nav">
          <NavIcon className="nab-bar-svg" to="#">
            <FaIcons.FaBars
              id="media-query-showsidebar"
              onClick={showSidebar}
            />
          </NavIcon>
          <div className="header">
            <div className="header-company-name">
              <h2 id="media-query-title">{companyCode}</h2>
            </div>
            <hr />
            <div className="naslov-ikonice">
              <ul className="nav flex-column flex-lg-row justify-content-end">
                {ovlascenje == 4 || ovlascenje == 99 ? (
                  <li className="nav-item toolbaritem">
                    <SVG
                      onClick={() => navigateToUsers()}
                      src={userIcon}
                      className="pointer"
                      title="Korisnik"
                    />
                  </li>
                ) : (
                  <li></li>
                )}

                <li className="nav-item toolbaritem">
                  <SVG
                    onClick={setModalLogout}
                    src={logoutIcon}
                    className="pointer"
                    title="Izlaz"
                  />
                </li>
              </ul>
            </div>
          </div>
        </Nav>

        <SidebarNav
          sidebar={sidebar}
          id="sidebarMenuFirst"
          className={sidebar ? "sidebar collapsed" : "sidebar"}
        >
          <SidebarWrap>
            <NavIcon to="#">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "end",
                  height: "100%",
                }}
              >
                {/* <div>
                  <img style={{ width: '90%' }} src={require('./../assets/images/logo.png')} className='logo'></img>
                </div> */}

                <div
                  className="button-close"
                  id="button-close"
                  onClick={toggleSidebar}
                >
                  <FaIcons.FaWindowClose style={{ width: "30px" }} />
                </div>
              </div>
            </NavIcon>
            {SidebarData.map((item, index) => {
              if (item.visible) return <SubMenu item={item} key={index}/>;
            })}
          </SidebarWrap>
        </SidebarNav>
      </SVG>

      <div>
                <Modal isOpen={Boolean(modalLogout)} centered  >
                    <ModalHeader>Upozorenje!</ModalHeader>
                    <ModalBody>
                        <span>{`Da li ste sigurni da želite izaći iz aplikacije ? `}</span>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            {/* Dugme za zatvaranje modalnog forma */}
                            <button onClick={() => setModalLogout(false)} className="button-no">Ne</button>
                            <button onClick={() => removeToken()} className="button-yes">Da</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>
    </>
  );
};
export default Sidebar;
