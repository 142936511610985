import React, { useEffect, useRef, useState } from "react";
import { Container } from "reactstrap";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { NumericFormat } from "react-number-format";
import printiconica from "../../assets/print.svg";
import star from "../../assets/star.svg";
import starfill from "../../assets/starfill.svg";
import { defaultForm, defaultPrometForm } from "../utilities";
import { API } from "../../constants";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import SVG from "react-inlinesvg";
import { useLocation, useNavigate } from "react-router-dom";

function Kalkulacija() {
  // povlacenje podataka iz lokal storidza
  const token =
    typeof window !== "undefined" ? localStorage.getItem("accessToken") : null;
  const companyCode = localStorage.getItem("company");
  const companyAddress = localStorage.getItem("companyAddress");
  const companyPlaces = localStorage.getItem("companyPlaces");
  const companyNaziv = localStorage.getItem("companyNaziv");
  const companyPdv = localStorage.getItem("companyPdv");
  const location = useLocation();
  const repositoryRef = useRef();
  const vrstaRef = useRef();
  const documentRef = useRef();

  const [itemUpdate, setItemUpdate] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [errorInputVrsta, setErrorInputVrsta] = useState(false);
  const [errorMsgVrsta, setErrorMsgVrsta] = useState(false);
  const [errorMsgDocument, setErrorMsgDocument] = useState(false);

  const [selectData, setSelectData] = useState([]);
  const [selectDataVrsta, setSelectDataVrsta] = useState([]);
  const [selectDataClients, setSelectDataClients] = useState([]);
  const [repository, setRepository] = useState(null);
  const [repositoryFocus, setRepositoryFocus] = useState(true);
  const [errorMsgRepository, setErrorMsgRepository] = useState(false);
  const [firstDocumentNumber, setFirstDocumentNumber] = useState(null);
  const [selectedName, setSelectedName] = useState("");
  const [selektovanaVrstaSkladista, setSelektovanaVrstaSkladista] =
    useState("");
  const [errorInputRepository, setErrorInputRepository] = useState(false);
  const [vrsta, setVrsta] = useState(null);
  const [clients, setClients] = useState(null);
  const [komt, setKomt] = useState(null);
  const [nazivSkladista, setNazivSkladista] = useState("");

  let skladistePodaci = [];
  let vrstaPodaci = [];

  // definisanje formi
  const [form, setForm] = useState({
    ...defaultForm,
  });
  const [prometForm, setPrometForm] = useState({
    ...defaultPrometForm,
  });

  // modal
  const toggle = () => setOpenModal(!openModal);
  const [modalHeader, setModalHeader] = useState("");
  const [naslovi, setNaslovi] = useState([]);

  // propsovi za auto komplit  - skladiste, komitent, artikal i vrstu; objekat za dati autokomplit iz koga preko mape mozemo procitati podatak upisan u bazu
  //  getOptionLabel - polja koja ce biti prikazana u autokomplitu
  const defaultProps = {
    options: selectData,
    getOptionLabel: (option) =>
      option
        ? `${option.SIFRA} - ${option.NAZIV} - ${option.VRSTA_SKLADISTA} `
        : "",
  };

  const defaultPropsVrsta = {
    options: selectDataVrsta,
    getOptionLabel: (option) =>
      option ? ` ${option.VRSTA} ${option.NAZIV}` : "",
  };
  const defaultPropsClients = {
    options: selectDataClients,
    getOptionLabel: (option) =>
      option ? `${option.SIFRA} ${option.NAZIV}` : "",
  };

  // funkcija koja hvata promjene na poljima prometForme i setuje vrijednost u form state
  const handleChangePromet = (event) => {
    const target = event.target;
    let value = target.value;
    const name = event.target.name;
    setPrometForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // funkcije za provjeru obaveznih polja - skladiste
  const checkRepositoryRequired = (value) => {
    // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto) ili ako nije upisana ni vrijednost niti je odabrano skladiste, izbaci poruku, setuje crveni okvir i fokusira polje
    // u suprotnom prolazi dalje
    if ((value && !repository) || (!value && !repository)) {
      setErrorMsgRepository("Skladiste mora biti izabrano!");
      setErrorInputRepository(true);
      repositoryRef.current.focus();
      setRepositoryFocus(true);
      return false;
    } else {
      setErrorMsgRepository(null);
      setErrorInputRepository(false);
      return true;
    }
  };

  // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto) ili ako nije upisana ni vrijednost niti je odabrana vrsta, izbaci poruku, setuje crveni okvir i fokusira polje
  // u suprotnom prolazi dalje
  const checkVrstaRequired = (value) => {
    if ((value && !vrsta) || (!value && !vrsta)) {
      setErrorMsgVrsta("Vrsta mora biti izabrana!");
      setErrorInputVrsta(true);
      vrstaRef.current.focus();
      return false;
    } else {
      setErrorMsgVrsta(null);
      setErrorInputVrsta(false);
      return true;
    }
  };

  // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto) ili ako nije upisana ni vrijednost niti je odabran komitent, izbaci poruku, setuje crveni okvir i fokusira polje
  // u suprotnom prolazi dalje
  const checkDocumentRequired = (value) => {
    if (!value || value == 0) {
      setErrorMsgDocument("Dokument je obavezan!");
      setShowModal(true);
      documentRef.current.focus();
      return false;
    } else {
      setErrorMsgDocument(null);
      setShowModal(false);
      return true;
    }
  };

  // funkcija unosIspravanDo(validacija), na klik svakog input elementa iz formi,
  // provjerava obavezna polja iz prethodnih te ukazuje na gresku ukoliko je ima.
  function unosIspravanDo(name) {
    // skladiste
    if (name === "SKLAID") {
      return true;
    }

    if (!checkRepositoryRequired(prometForm.SKLAID)) {
      return false;
    }

    // vrstaprometa
    if (name === "VRPROM") {
      return true;
    }

    if (!checkVrstaRequired(prometForm.VRPROM)) {
      return false;
    }

    // dokument
    if (name === "DOK") {
      return true;
    }

    if (!checkDocumentRequired(prometForm.DOK)) {
      return false;
    }
    // dugme maloprodajna kalkulacija
    if (name === "MKALK") {
      return true;
    }

    // dugme veleprodajna kalkulacija
    if (name === "VKALK") {
      return true;
    }

    return true;
  }

  // funkcija koja poziva citanje skladista
  const getRepositoryData = () => {
    fetch(API + `/skladista/all/${companyCode}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        skladistePodaci = data;
        setSelectData(data);
      });
  };

  // funkcija koja poziva citanje vrste
  const getVrstaData = () => {
    fetch(API + `/vrstaDok/all`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        vrstaPodaci = data;
        setSelectDataVrsta(data);
      });
  };

  // funkcija koja poziva citanje komitenta
  const getClientsData = () => {
    fetch(API + `/komitenti/all/${companyCode}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setSelectDataClients(data);
      });
  };

  // funkcija koja poziva api za pronalazenje prvog slobodnog dokumenta
  // const getPrometFirstFreeNumber = () => {
  //   fetch(API + `/ulaz-kalkulacije/firstFreeNumber`, {
  //     method: "POST",
  //     body: JSON.stringify({
  //       sklaId: repository && repository.ID ? repository.ID : "",
  //       vrprom: vrsta && vrsta.VRSTA ? vrsta.VRSTA : "",
  //     }),
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //       "Content-type": "application/json; charset=UTF-8",
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       let promet = data[0];
  //       setPrometForm((prev) => ({
  //         ...prev,
  //         DOK: promet.DOK,
  //         ID: promet.ID,
  //       }));
  //       setFirstDocumentNumber(promet.DOK, promet.ID);
  //     });
  // };

  // funckija koje je zaduzena za dobijanje novog promet id na promjenu dokumenta
  function getPrometId(dok) {
    fetch(API + `/kalkulacija/promet-id`, {
      method: "POST",
      body: JSON.stringify({
        VRPROM: prometForm.VRPROM,
        SKLAID: prometForm.SKLAID,
        DOK: dok,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let result = data.result[0];
        // ukoliko rezultat postoji postavljamo prometid
        if (result) {
          setPrometForm((prev) => ({
            ...prev,
            PROMETID: result.ID,
          }));
          setOpenModal(true); // otvaramo modal
        } else {
          // ukoliko ne postoji vracamo korisnika na dokument input
          documentRef.current.focus();
          setErrorMsgDocument("Unesite ispravan broj dokumenta.");
          setOpenModal(false); // otvaramo modal
        }
      });
  }

  // funkcija koja poziva naslove na osnovu formname-a
  function getNaslovi(naslov) {
    fetch(API + `/kalkulacija/naslovi`, {
      method: "POST",
      body: JSON.stringify({
        formName: `${naslov}` || "IzvMaloprodajnaKalkulacija",
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let result = data.result;
        if (result) {
          setNaslovi(result);
          // iteracija kroz niz, te ukoliko je glavni naziv podesen,
          // postavljamo ga kao vrijednost inputa
          result.forEach((r) => {
            if (r.GLAVNI == "D") {
              setSelectedName(r.NASLOV);
            }
          });
        }
      });
  }

  // funkcija koja poziva printanje
  function printPromet() {
    // na osnovu vrste skladista targetujemo odgovarajuci endpoint
    let prodaja =
      prometForm.VRSTA_SKLADISTA == "M" ? "maloprodajnaKalkulacijaStampa" : "veleprodajnaKalkulacijaStampa";

    fetch(API + `/${prodaja}/print`, {
      method: "POST",
      body: JSON.stringify({
        NAME: modalHeader,
        HEADER: selectedName + " - " + prometForm.DOK,
        COMPANY: companyNaziv,
        form: form,
        NAZIV: nazivSkladista,
        prometForm: prometForm,
        NASLOV: `${companyNaziv} - ${companyPlaces} - ${companyAddress}`,
        PDV: `${companyPdv}`,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (typeof window !== "undefined") {
          setTimeout(() => {
            window.open(API + data.link);
          }, 1000);
        }
      });
  }

  useEffect(() => {
    // zaustavljanje fokusa(pauza za fokus dok ne odradi upis) 
    const timeout = setTimeout(() => {
      ulaz(location)
    }, 100);
    return () => {
        clearTimeout(timeout);
    };
}, []);

  useEffect(() => {
    getRepositoryData();
    getVrstaData();
    getClientsData();
    // dobijanje vrijednosti iz localStorage-a
    // let ls = localStorage.getItem("kalkulacija");
    // ls = JSON.parse(ls);
    // // ukoliko postoji localStorage item, selektujemo skladiste, vrstu promjene, broj dokumenta
    // if (ls) {
    //   setVrsta(ls.VRPROM);
    //   setRepository(ls.SKLADISTE);
    //   setNazivSkladista(ls.SKLADISTE.NAZIV);
    //   setPrometForm((prev) => ({
    //     ...prev,
    //     PROMETID: ls.ID,
    //     SKLAID: ls.SKLADISTE.ID,
    //     VRPROM: ls.VRPROM.VRSTA,
    //     DOK: ls.DOK,
    //     VRSTA_SKLADISTA: ls.SKLADISTE.VRSTA_SKLADISTA,
    //   }));
    // }
  }, []);
  // let sklaidUlaz;

  // funkcija kojom se preuzima broj naloga kad se klikne na dugme stampa na unosu naloga
  function ulaz(location) {
    const { id } = location.state ? location.state : '';
    const { dok } = location.state ? location.state : '';
    const { skladiste } = location.state ? location.state : '';
    const { vrstaSkladista } = location.state ? location.state : '';
    const { vrstaUlaz } = location.state ? location.state : '';
    prometForm.VRSTA_SKLADISTA = vrstaSkladista;
    prometForm.SKLAID = skladiste;
    prometForm.VRPROM = vrstaUlaz;
    setPrometForm((prev) => ({ ...prev, DOK: dok ? dok : '', ID: id ? id : '', SKLAID: prometForm.SKLAID, VRSTA_SKLADISTA: prometForm.VRSTA_SKLADISTA, VRPROM: prometForm.VRPROM }));
    if (prometForm.SKLAID != 0) {
      skladistePodaci.map((repository) => {
        if (repository.ID == prometForm.SKLAID) {
          setRepository(repository)
        }
      })
    }

    if (prometForm.VRPROM != 0) {
      vrstaPodaci.map((vrsta) => {
        if (vrsta.VRSTA == prometForm.VRPROM) {
          setVrsta(vrsta)
        }
      })
    }
  }

  return (
    <div>
      <div className="title-container">
        <header className="title-header">
          <h1 id="media-query-title">Ulazne Kalkulacije</h1>
        </header>
      </div>
      <Container fluid>
        <div className="col-12 d-flex">
          <div className="col-6 d-flex flex-column gap-2">
            <div className="col-10">
              <div
                className={
                  errorInputRepository
                    ? "my-input d-flex"
                    : "field-container d-flex"
                }
              >
                <label className="col-4 fs-6 m-0 text-nowrap text-end pe-4">
                  Skladište:
                </label>
                {/* autokomplit za skladiste */}
                <Autocomplete
                  //ukoliko nema ove funkcije, u konzoli izbacuje upozorenje da nije vrijednost jedinstvena
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  {...defaultProps}
                  className="w-100"
                  value={repository}
                  disablePortal={true}
                  onFocus={() => {
                    setRepositoryFocus(true);
                    unosIspravanDo("SKLAID");
                  }}
                  // ako se promijeni skladiste, uzima podatke za novo izabrano skladiste
                  onChange={(event, newValue) => {
                    // postavljanje naziva skladista na osnovu promijenjenog
                    setNazivSkladista(newValue.NAZIV);
                    // postavljanje vrste skladista na osnovu promijenjenog
                    setSelektovanaVrstaSkladista(newValue.VRSTA_SKLADISTA);
                    // postavljanje forme na osnovu promijenjenog skladista
                    setPrometForm((prev) => ({
                      ...prev,
                      SKLAID: newValue && newValue != null ? newValue.ID : "",
                      VRSTA_SKLADISTA:
                        newValue && newValue != null
                          ? newValue.VRSTA_SKLADISTA
                          : "",
                    }));
                    // postavljanje skladista na osnovu promijenjenog
                    setRepository(newValue);
                    repositoryRef.current.focus();
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoFocus
                      className="clients-textinput"
                      maxLength="295"
                      name="SKLAID"
                      tabIndex="1"
                      ref={repositoryRef}
                      placeholder="Izaberite skladište"
                      variant="standard"
                    />
                  )}
                />
              </div>
              {/* prikazivanje greske ukoliko skladiste nije izabrano */}
              {errorMsgRepository && (
                <div className="col-12">
                  <span className="error-message">{errorMsgRepository}</span>
                </div>
              )}
            </div>
            <div className="col-10">
              <div
                className={
                  errorInputVrsta ? "my-input d-flex" : "field-container d-flex"
                }
              >
                <label className="col-4 fs-6 m-0 text-nowrap text-end pe-4">
                  Vrsta promjene:
                </label>

                {/* autokomplit za vrstu */}
                <Autocomplete
                  //ukoliko nema ove funkcije, u konzoli izbacuje upozorenje da nije vrijednost jedinstvena
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  {...defaultPropsVrsta}
                  value={vrsta}
                  className="w-100"
                  disablePortal={true}
                  onFocus={() => {
                    unosIspravanDo("VRPROM");
                  }}
                  // ako se promijeni vrsta, uzima podatke za novu izabranu vrstu
                  onChange={(event, newValue) => {
                    setPrometForm((prev) => ({
                      ...prev,
                      VRPROM:
                        newValue && newValue != null ? newValue.VRSTA : "",
                      NAZIV: newValue && newValue != null ? newValue.NAZIV : "",
                    }));
                    setVrsta(newValue);
                    vrstaRef.current.focus();
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      tabIndex="2"
                      maxLength="2"
                      className="clients-textinput"
                      name="VRPROM"
                      ref={vrstaRef}
                      placeholder=""
                      variant="standard"
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-10">
              <div
                className={
                  errorMsgDocument
                    ? "my-input d-flex"
                    : "field-container d-flex"
                }
              >
                <label className="col-4 fs-6 m-0 text-nowrap text-end pe-4">
                  Broj dokumenta:
                </label>
                {/* komponenta za formatiranje brojeva */}
                <NumericFormat
                  type="number"
                  getInputRef={documentRef}
                  className="col-8 w-auto"
                  maxLength="9"
                  tabIndex="3"
                  decimalScale={0}
                  onFocus={() => {
                    unosIspravanDo("DOK");
                   // getPrometFirstFreeNumber();
                    setKomt(null);
                  }}
                  placeholder="0"
                  name="DOK"
                  value={prometForm?.DOK}
                  onChange={(e) => {
                    setPrometForm((prev) => ({
                      ...prev,
                      DOK: e.target.value,
                    }));
                    handleChangePromet(e);
                  }}
                  onClick={() => {
                    setItemUpdate(false);
                  }}
                />
              </div>
              {/* prikazivanje greske ukoliko komitent nije izabran */}
              {errorMsgDocument && (
                <div className="col-8 text-end">
                  <span className="error-message">{errorMsgDocument}</span>
                </div>
              )}
            </div>
          </div>
          <div className="col-6 d-flex flex-column gap-2">
            <div className="col-6 d-flex flex-column gap-2">
              <h4 className="labelColor fs-6 m-0 text-nowrap">
                Maloprodajna Kalkulacija
              </h4>
              <button
                tabIndex={4}
                onClick={() => {
                  unosIspravanDo("MKALK");
                  if (unosIspravanDo("MKALK")) {
                    setModalHeader("Maloprodajna Kalkulacija"); // setovanje headera unutar modala na osnovu izabrane vrste printa
                    getNaslovi("IzvMaloprodajnaKalkulacija"); // naslov za maloprodaju
                    getPrometId(prometForm.DOK); // dobijanje promet id-a
                  }
                }}
                type="button"
                className="col-10 btn btn-secondary bg-gradient text-start d-flex gap-5 align-items-center"
              >
                <SVG src={printiconica}></SVG>
                <span>Maloprodajna Kalkulacija</span>
              </button>
            </div>
            <div className="col-6 d-flex flex-column gap-2">
              <h4 className="labelColor fs-6 m-0 text-nowrap">
                Veleprodajna Kalkulacija
              </h4>
              <button
                tabIndex={5}
                onClick={() => {
                  unosIspravanDo("VKALK");
                  if (unosIspravanDo("VKALK")) {
                    setModalHeader("Veleprodajna Kalkulacija"); // setovanje headera unutar modala na osnovu izabrane vrste printa
                    getNaslovi("IzvVeleprodajnaKalkulacija"); // naslov za veleprodaju
                    setOpenModal(true); // otvaramo modal
                    getPrometId(prometForm.DOK); // dobijanje promet id-a
                  }
                }}
                type="button"
                className="col-10 btn btn-secondary bg-gradient text-start d-flex gap-5 align-items-center"
              >
                <SVG src={printiconica}></SVG>
                <span>Veleprodajna Kalkulacija</span>
              </button>
            </div>
          </div>
        </div>
      </Container>
      <Modal
        size="lg"
        isOpen={openModal}
        toggle={toggle}
        animation="false"
        centered
      >
        <ModalHeader toggle={toggle}>{modalHeader}</ModalHeader>
        <ModalBody className="d-flex col-12 flex-column gap-4">
          <div className="col-12 d-flex justify-content-between">
            <div className="col-2">
              <button
                className="btn btn-primary bg-light border text-dark w-100 h-50 p-0"
                onClick={() => {
                  printPromet();
                }}
              >
                Prikaz
              </button>
            </div>
            <div className="col-2">
              <button
                className="btn btn-primary bg-light border text-dark w-100 h-50 p-0"
                onClick={() => {
                  printPromet();
                }}
              >
                Štampa
              </button>
            </div>
            <div className="col-2">
              <button className="btn btn-primary bg-light border text-dark w-100 h-50 disabled">
                Download
              </button>
            </div>
            <div className="col-2">
              <button className="btn btn-primary bg-light border text-dark w-100 h-50 disabled">
                Email
              </button>
            </div>
          </div>
          <div className="col-12 d-flex flex-column gap-1">
            <input
              type="text"
              placeholder="Izaberite Naslov"
              className="py-4 fs-3"
              value={selectedName}
              onChange={(e) => setSelectedName(e.target.value)}
            />
            {/* mapiramo kroz niz naslova i prikazujemo ih, te prikazujemo svg(item.GLAVNI=="D" => starfill : star ) */}
            {naslovi.map((item, index) => (
              <a
                key={index}
                className="btn col-12 btn-primary bg-light text-dark d-flex justify-content-between align-items-center fs-5"
                onClick={() => setSelectedName(item.NASLOV)}>
                {item.NASLOV}
                <SVG src={item.GLAVNI == "D" ? starfill : star} />
              </a>
            ))}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default Kalkulacija;
