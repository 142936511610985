import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import DataTable from 'react-data-table-component';
import exportIcon from '../assets/export.svg';
import saveIcon from '../assets/save.svg';
import printIcon from '../assets/print.svg';
import SVG from 'react-inlinesvg';
import { API } from '../constants';
import axios from '../api/axios';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Spinner } from "reactstrap";
import { defaultFormRegistri } from "../components/utilities";
import { getCompanyData, defaultComponentOptions } from '../components/utilities';
import { NumericFormat } from 'react-number-format';
import { useWindowSize } from '../components/useWindowSize';

function Trosak() {
    const windowSize = useWindowSize();
    const codeRef = useRef();
    const nameRef = useRef();
    const accountRef = useRef();

    const [trosak, setTrosak] = useState(null)
    const [account, setAccount] = useState(null);
    const [selectDataAccount, setSelectDataAccount] = useState([]);
    const [searchInput, setSearchInput] = useState([]);

    const [errorInputCode, setErrorInputCode] = useState(false);
    const [errorInputName, setErrorInputName] = useState(false);
    const [errorInputAccount, setErrorInputAccount] = useState(false)
    const [errorMsgCode, setErrorMsgCode] = useState(false);
    const [errorMsgName, setErrorMsgName] = useState(false);

    const [modalErrorOpenData, setModalErrorOpenData] = useState(false);
    const [modalErrorAuthorizationData, setModalErrorAuthorizationOpen] = useState(false);
    const [modalDeleteItem, setModalDeleteItem] = useState(false);

    const [spinner, setSpinner] = useState(false);
    const [pending, setPending] = React.useState(false);

    const token = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : null;

    // definisanje stejta(objekat i funkcija za taj objekat), prva inicijalizacija
    const [form, setForm] = useState({
        ...defaultFormRegistri,
    });

    // funkcija za povlacenje podataka o preduzecu
    getCompanyData(form);

    const onSubmit = () => {
        // uslov: ako nisu unesena obavezna polja, zaustavlja upis
        if (!unosIspravanDo('UPIS')) return;

        fetch(API + `/trosak/new/${form.companyCode}`, {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                response.json()
                // vracanje forme na pocetnu vrijednost
                setForm((prev) => ({
                    ...prev,
                    ...defaultFormRegistri,
                }));
                setAccount(null)
                codeRef.current.focus()
                getTableData()
            })
    }

    const getAccountData = () => {
        fetch(API + `/konto/all`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataAccount(data)
            })
    }

    const getTableData = () => {
        fetch(API + `/trosak/all/${form.companyCode}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setTrosak(data.result)
            })
    }

    useEffect(() => {
        getAccountData()
    }, [])

    // prilikom pocetne inicijalizacije, cita mrezu
    useEffect(() => {
        // zaustavljanje fokusa
        const timeout = setTimeout(() => {
            getTrosakFirstFreeNumberSifra();
            getTableData();
        }, 100);
        return () => {
            clearTimeout(timeout);
        };
    }, []);


    const getTrosakFirstFreeNumberSifra = () => {
        fetch(API + `/trosak/firstFreeNumberSifra/${form.companyCode}`, {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setForm(prev => ({ ...prev, SIFRA: Number(data[0].SIFRA) }))
            })
    }

    // funkcija za brisanje koja se poziva klikom na dugme 'Delete'
    //  api za brisanje
    // u responsu osvjezava polja, daje prvi slobodan broj za sifru, fokus na polje sifra, osvjezava mrezu  
    const onDelete = () => {
        // provjera ovlascenja za brisanje 
        if (form.ovlrobno == 4) return setModalErrorAuthorizationOpen(true);

        fetch(API + `/trosak/delete/${form.companyCode}`, {
            method: 'DELETE',
            body: JSON.stringify(form),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                response.json()
                // vracanje forme na pocetnu vrijednost
                setForm((prev) => ({
                    ...prev,
                    ...defaultFormRegistri,
                }));
                setAccount(null)
                codeRef.current.focus()
                getTableData()
            })
    }

    const onEdit = (item) => {
        nameRef.current.focus()
        setAccount(null);

        selectDataAccount.map((account) => {
            if (account.ID == item.KONTID) {
                setAccount(account)
            }
        })
        item.stavkaPronadjena = true;
        setForm(item)
    }

    const handleChange = (event) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name;

        setForm((prevState) => ({
            ...prevState,
            [name]: value
        }));

    };

    const onPrint = () => {
        // uslov preko koga saljemo naziv preduzeca sa frontenda na bekend
        // ako je objekat form popunjen, napuni polje NAZIV_PRED iz lokal storidza
        // poziva api za stampu, otvara izvjestaj(API + data.link)
        setSpinner(true)
        if (trosak && trosak.length === 0) {
            setSpinner(false)
            setModalErrorOpenData(true)
        } else {
            if (form) {
                form.NAZIV_PRED = form.companyName
            }
            fetch(API + `/trosak/print/${form.companyCode}`, {
                method: 'POST',
                body: JSON.stringify(form),
                responseType: 'blob',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-type': 'application/json; charset=UTF-8'
                },
            })
                .then((response) => response.json())
                .then(data => {
                    if (typeof window != 'undefined') {
                        setTimeout(() => {
                            window.open(API + data.link)
                        }, 200);
                    }
                    setSpinner(false)
                })
        }
    }

    const defaultPropsAccount = {
        options: selectDataAccount,
        getOptionLabel: (option) => option ? `${option.KONTO} ${option.NAZ}` : '',
    };

    // uslov: ako nije upisana ni vrijednost, izbaci poruku, setuje crveni okvir i fokusira polje  
    // u suprotnom prolazi dalje
    const checkCodeRequired = (value) => {
        if (!value || value == 0) {
            setErrorInputCode(true)
            setErrorMsgCode('Šifra je obavezna!')
            codeRef.current.focus();
            return false
        } else {
            setErrorMsgCode(null)
            setErrorInputCode(false)
            return true
        }
    }

    // uslov: ako nije upisana ni vrijednost, izbaci poruku, setuje crveni okvir i fokusira polje  
    // u suprotnom prolazi dalje
    const checkNameRequired = (value) => {
        if (!value || value == 0) {
            setErrorMsgName('Naziv je obavezan!')
            setErrorInputName(true)
            nameRef.current.focus();
            return false
        } else {
            setErrorMsgName(null)
            setErrorInputName(false)
            return true
        }
    }

    // funkcija za kontrolu obaveznih polja
    function unosIspravanDo(name) {
        if (name === 'SIFRA') {
            return true
        }

        if (!checkCodeRequired(form.SIFRA)) {
            return false
        }

        if (name === 'NAZIV') {
            return true
        }

        if (!checkNameRequired(form.NAZIV)) {
            return false
        }

        if (name === 'KONTID') {
            return true
        }

        return true
    }

    const columns = [
        {
            name: 'Šifra',
            selector: row => row.SIFRA,
            width: '100px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.SIFRA}</span>
                </>
            ),
        },
        {
            name: 'Naziv',
            selector: row => row.NAZIV,
            // sirina polja po rezolucijama
            width:
                windowSize.width > 2048 ? '900px'
                    : windowSize.width > 1920 ? '1000px'
                        : windowSize.width > 1680 ? '800px'
                            : windowSize.width > 1600 ? '600px'
                                : windowSize.width > 1440 ? '580px'
                                    : windowSize.width > 1280 ? '600px'
                                        : windowSize.width > 1024 ? '600px'
                                            : '550px',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.NAZIV}</span>
                </>
            ),
        },
        {
            name: 'Konto',
            selector: row => row.kontoKONTO,
            selector: row => row.kontNaziv,
            // sirina polja po rezolucijama
            width:
                windowSize.width > 2048 ? '1000px'
                    : windowSize.width > 1920 ? '500px'
                        : windowSize.width > 1680 ? '450px'
                            : windowSize.width > 1600 ? '550px'
                                : windowSize.width > 1440 ? '500px'
                                    : windowSize.width > 1280 ? '320px'
                                        : windowSize.width > 1024 ? '230px'
                                            : '250px',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.kontoKONTO} {row.kontNaziv}</span>
                </>
            ),
        },
        {
            name: 'Action',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span onClick={() => onEdit(row)} id='media-query-table-buttons' className='btn btn-primary nav flex-column flex-lg-row justify-content-end'>Edit</span>{''}
                    <span onClick={() => { onEdit(row); setModalDeleteItem(true) }} id='media-query-table-buttons' className='btn btn-danger nav flex-column flex-lg-row justify-content-end'>Brisanje</span>
                </>

            ),

        }
    ];

    const searchChange = (e) => {
        e.preventDefault();
        const searchInput = e.target.value
        setSearchInput(searchInput);
        const fetchData = async () => {
            const res = await axios.get(`${API}/trosak/query/${form.companyCode}?q=${searchInput}`, { headers: { "Authorization": `Bearer ${token}` } });
            setTrosak(res.data);
        };
        // uslov za za slanje zahtjeva za povlacenje podataka
        if (searchInput.length === 0 || searchInput.length >= 1) fetchData();
    };

    return (

        <div>
            <div className="title-container">

                <header className="title-header">
                    <h1 id="media-query-title">Trošak</h1>
                </header>

                <div className="naslov-ikonice">
                    <ul className="nav flex-column flex-lg-row justify-content-end">

                        <li className="nav-item toolbaritem">
                            <input type="image" alt="save" className='dugme-sacuvaj' title='Sačuvaj' tabIndex="4" src={require('./../assets/images/floppydisk.png')} onFocus={() => onSubmit()} />
                        </li>

                        <li className="nav-item toolbaritem">
                            <input type="image" alt="print" className='dugme-stampa' title='Štampa' tabIndex="5" src={require('./../assets/images/printer.png')} onClick={onPrint} />
                        </li>

                    </ul>
                </div>
            </div>

            <Container fluid>
                <Row>
                    <div className='products-form'>
                        <Row>
                            <div className="col-6" style={{ display: 'flex', marginBottom: '0px', width: '60%' }}>
                                <div className="col-1">
                                    <div className='col-form-right'>
                                        <div className={errorInputCode ? 'inputWithError' : 'field-container-number'} style={{ marginRight: '3px', marginBottom: '0px' }} >
                                            <label id="media-query-label">Šifra:</label>
                                            <NumericFormat
                                                type="text"
                                                className='field-alignment p-1'
                                                getInputRef={codeRef}
                                                decimalScale={0}
                                                maxLength="6"
                                                autoFocus={true}
                                                id="media-query-input-text"
                                                tabIndex="1"
                                                placeholder="Šifra"
                                                name='SIFRA'
                                                value={form?.SIFRA}
                                                // Prilikom prelaska na drugo polje, aktivira se stepenasta kontrola
                                                onFocus={() => {
                                                    getTrosakFirstFreeNumberSifra();
                                                    unosIspravanDo("SIFRA")
                                                }}
                                                onChange={(e) => handleChange(e)}
                                                onClick={() => {
                                                    // ako je kliknuto na dugme Edit, a zatim na sifru - cisti polja  
                                                    setForm(prev => ({
                                                        ...prev, NAZIV: '', KONTID: '', stavkaPronadjena: false
                                                    }))
                                                    setAccount(null);
                                                }} />
                                            {errorMsgCode ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgCode}</span> : ''}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-7">
                                    <div className={errorInputName ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px' }} >
                                        <label id="media-query-label">Naziv:</label>
                                        <input type="text"
                                            id="media-query-input-text"
                                            maxLength="50"
                                            ref={nameRef}
                                            tabIndex="2"
                                            placeholder="Naziv"
                                            name='NAZIV'
                                            value={form ? form.NAZIV : ''}
                                            // Postavlja stejt za fokus na true jer je potrebno zbog stepenaste kontrole
                                            onFocus={() => {
                                                unosIspravanDo("NAZIV")
                                                // uslov za citanje sloga po sifri
                                                let selectedItem = []
                                                selectedItem = trosak.filter((c) => c.SIFRA == form.SIFRA)
                                                if (selectedItem.length > 0) {
                                                    onEdit(selectedItem[0])
                                                }
                                            }}
                                            onChange={(e) => handleChange(e)} />
                                        {errorMsgName ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgName}</span> : ''}
                                    </div>
                                </div>


                                <div className="col-4">
                                    <div className={errorInputAccount ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px' }} >
                                        <label id="media-query-label">Konto:</label>
                                        <Autocomplete
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            {...defaultPropsAccount}
                                            value={account}
                                            disablePortal={true}
                                            onFocus={() => {
                                                unosIspravanDo("KONTID")
                                            }}
                                            onChange={(event, newValue) => {
                                                setForm(prev => ({ ...prev, KONTID: newValue && newValue != null ? newValue.ID : '' }))
                                                setAccount(newValue);
                                                // fokus se mora vratiti inace tabIndex ne radi dobro
                                                accountRef.current.focus();
                                            }}

                                            renderInput={(params) => (
                                                <TextField {...params}
                                                    className="clients-textinput"
                                                    tabIndex="3"
                                                    name='KONTID'
                                                    ref={accountRef}
                                                    placeholder=' Izaberite konto'
                                                    variant="standard" />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </div>
                </Row>
            </Container>

            <div style={{ display: 'flex' }} className='search'>
                <input type='text' tabIndex={-1} id="media-query-input-text" placeholder='Pretraga...' onChange={(e) => {
                    // pretraga mreze(ukoliko je unesen bilo koji karakter, vrsi pretragu)
                    if (searchInput.length >= 0) {
                        searchChange(e)
                    } else {
                        getTableData()
                    }
                }} value={searchInput} />

                {/* // <img src={Pencil} className='search-icon' /> */}
            </div>
            <DataTable
                columns={columns}
                data={trosak ? trosak : []}
                pagination
                paginationServer
                responsive
                highlightOnHover
                striped
                dense
                pointerOnHover
                paginationComponentOptions={defaultComponentOptions}
                paginationPerPage={[windowSize.height > 1300 ? 28
                    : windowSize.height > 1270 ? 26
                        : windowSize.height > 1222 ? 25
                            : windowSize.height > 1200 ? 24
                                : windowSize.height > 1000 ? 20
                                    : windowSize.height > 920 ? 17
                                        : windowSize.height > 900 ? 16
                                            : windowSize.height > 880 ? 15
                                                : windowSize.height > 820 ? 14
                                                    : windowSize.height > 720 ? 13
                                                        : 4]}
                progressPending={pending}
            />

            {/* render za poruku */}
            <div>
                <Modal isOpen={Boolean(modalDeleteItem)} centered>
                    <ModalHeader>Brisanje stavke!</ModalHeader>
                    <ModalBody>
                        <span>{`Da li želite da obrišete stavku broj ${form.SIFRA} ? `}</span>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            <button
                                onClick={() => { setModalDeleteItem(false); nameRef.current.focus() }}
                                className="button-no">Ne
                            </button>
                            <button
                                onClick={() => {
                                    onDelete();
                                    setModalDeleteItem(false)
                                }}
                                className="button-yes">Da
                            </button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            {/* render za poruku */}
            <div>
                <Modal isOpen={Boolean(modalErrorAuthorizationData)} centered  >
                    <ModalHeader>Upozorenje!</ModalHeader>
                    <ModalBody>
                        <span>{`Nemate ovlašćenje za brisanje!`}</span>
                    </ModalBody>
                    <ModalFooter className="justify-content-center">
                        <div>
                            <button onClick={() => { setModalErrorAuthorizationOpen(false); nameRef.current.focus() }} className="button-yes">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            <div>
                <Modal isOpen={modalErrorOpenData} centered  >
                    <ModalHeader>Nema podataka!</ModalHeader>
                    <ModalFooter>
                        <div>
                            {/* Dugme za zatvaranje modalnog forma */}
                            <button onClick={() => { setModalErrorOpenData(false); nameRef.current.focus() }} className="button-yes">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            <div>
                {spinner ? <Spinner className='spinner' color="primary" id='loader' /> : ''}
            </div>
        </div>
    );

}

export default Trosak;


