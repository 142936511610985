// importovanje komponenti
import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import { API } from "../../constants";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import SVG from "react-inlinesvg";
import { Spinner } from "reactstrap";
import moment from "moment";
import {
  defaultPrometForm,
  defaultForm,
  numberWithCommasColumns,
  numberWithCommasColumns3,
  onFirstClick,
  onPreviosClick,
  onPaginationClick,
  onNextClick,
  onLastClick,
  getPageNumber,
  getIznosRabataIzlaz,
  getFakcenMinusRabat,
  getIznosPDVa,
  getIznosMarze,
  getProcenatMarze,
  roundTO,
  getPorezUIznosu,
  getNabavniIznos,
  getIznosMaloprodaje,
  getMarzavelFromValues,
  getMarza,
  getProdajnaCijena,
  getProdajniIznos,
  getRabvelFromValues,
  getFakturniIznos,
  numberWithCommas,
} from "../utilities";
import { NumericFormat } from "react-number-format";
import deleteDocument from "../../assets/delete_document.svg";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import "dayjs/locale/de";

const IzlazFakturisanje = () => {
  // definisanje referenci, stejtova i prva inicijalizacija; objekat i funkcija za taj objekat
  const kursRef = useRef();
  const dateDeliveryRef = useRef();
  const documentRef = useRef();
  const nomenRef = useRef();
  const dateRef = useRef();
  const clientsRef = useRef();
  const faktCenRef = useRef();
  const prodajnaCijenaRef = useRef();
  const iznFaktRef = useRef();
  const komtRef = useRef();
  const malcenRef = useRef();
  const jmRef = useRef();
  const repositoryRef = useRef();
  const rbrRef = useRef();
  const sifraRef = useRef();
  const kolicinaRef = useRef();
  const fiRef = useRef();
  const rabRef = useRef();
  const vrstaRef = useRef();
  const malcenPRef = useRef();
  const racunRef = useRef();
  const rabvelRef = useRef();
  const rabatRef = useRef();
  const iznRabatRef = useRef();
  const trosakPRef = useRef();
  const trosakRef = useRef();
  const marzapRef = useRef();
  const cijenaRef = useRef();
  const iznosRef = useRef();

  let date = new Date();

  const [izlazFakturisanje, setIzlazFakturisanje] = useState([]);
  const [vrsta, setVrsta] = useState(null);
  const [repository, setRepository] = useState(null);
  const [updateDocument, setUpdateDocument] = useState(false);
  const [clients, setClients] = useState(null);
  const [komt, setKomt] = useState(null);
  const [artikal, setArtikal] = useState(null);
  const [selectData, setSelectData] = useState([]);
  const [selectDataNomen, setSelectDataNomen] = useState([]);
  const [selectDataVrsta, setSelectDataVrsta] = useState([]);
  const [selectDataClients, setSelectDataClients] = useState([]);
  const [endDate, setEndDate] = useState(date);
  const [currencyDate, setCurrencyDate] = useState(date);
  const [firstDocumentNumber, setFirstDocumentNumber] = useState(null);
  const [itemUpdate, setItemUpdate] = useState(false);
  const [sumNomenIznos, setSumNomenIznos] = useState(false);
  const [sumOsnovica, setSumOsnovica] = useState(false);
  const [sumProdajniIznos, setSumProdajniIznos] = useState(false);
  const [sumIznosRabata, setSumIznosRabata] = useState(false);
  const [sumIznosMarze, setSumIznosMarze] = useState(false);
  const [sumIznosPoreza, setSumIznosPoreza] = useState(false);

  const [errorInputRepository, setErrorInputRepository] = useState(false);
  const [errorInputClients, setErrorInputClients] = useState(false);
  const [errorInputVrsta, setErrorInputVrsta] = useState(false);
  const [errorInputDocument, setErrorInputDocument] = useState(false);
  const [errorInputRbr, setErrorInputRbr] = useState(false);
  const [errorDate, setErrorDate] = useState(false);
  const [errorDateDelivery, setErrorDateDelivery] = useState(false);
  const [errorInputJM, setErrorInputJM] = useState(false);
  const [errorInputMarza, setErrorInputMarza] = useState(false);
  const [errorInputMalcenP, setErrorInputMalcenP] = useState(false);

  const [errorMsgRepository, setErrorMsgRepository] = useState(false);
  const [errorMsgDocument, setErrorMsgDocument] = useState(false);
  const [errorMsgNomen, setErrorMsgNomen] = useState(false);
  const [errorMsgClients, setErrorMsgClients] = useState(false);
  const [errorMsgKolicina, setErrorMsgKolicina] = useState(false);
  const [errorMsgRbr, setErrorMsgRbr] = useState(false);
  const [errorMsgFaktcen, setErrorMsgFaktcen] = useState(null);
  const [errorMsgIznFaktcen, setErrorMsgIznFaktcen] = useState(null);

  const [errorMsgNabcen, setErrorMsgNabcen] = useState(null);
  const [errorMsgSifra, setErrorMsgSifra] = useState(null);
  const [errorMsgRabat, setErrorMsgRabat] = useState(null);
  const [errorMsgIznosRabata, setErrorMsgIznosRabata] = useState(null);

  const [errorMsgDate, setErrorMsgDate] = useState(false);
  const [errorMsgDateDelivery, setErrorMsgDateDelivery] = useState(false);
  const [errorMsgCurrency, setErrorMsgCurrency] = useState(false);
  const [errorCurrency, setErrorCurrency] = useState(false);
  const [errorMsgVrsta, setErrorMsgVrsta] = useState(false);
  const [errorMsgPrice, setErrorMsgPrice] = useState(false);
  const [errorMsgJM, setErrorMsgJM] = useState(false);
  const [errorMsgMalcenP, setErrorMsgMalcenP] = useState(false);
  const [errorMsgIznos, setErrorMsgIznos] = useState(false);

  const [repositoryFocus, setRepositoryFocus] = useState(true);
  const [vrstaFocus, setVrstaFocus] = useState(true);
  const [documentFocus, setDocumentFocus] = useState(false);
  const [clientsFocus, setClientsFocus] = useState(false);
  const [sifraFocus, setSifraFocus] = useState(false);
  const [malcenPFocus, setMalcenPFocus] = useState(false);

  const [dateFocus, setDateFocus] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const [modalDeleteItem, setModalDeleteItem] = useState(false);
  const [modalEditErrorOpen, setModalEditErrorOpen] = useState(false);
  const [modalChangePriceOpen, setModalChangePriceOpen] = useState(false);
  const [modalErrorDeleteDocumentOpen, setModalErrorDeleteDocumentOpen] =
    useState(false);
  const [modalErrorDeleteItemOpen, setModalErrorDeleteItemOpen] =
    useState(false);
    const [modalErrorAuthorizationData, setModalErrorAuthorizationOpen] =
    useState(false);
  const [modalTrosakErrorOpen, setModalTrosakErrorOpen] = useState(false);
  const [modalTrosakErrorOpenedOnce, setModalTrosakErrorOpenedOnce] =
    useState(false);

  const [modalErrorOpenData, setModalErrorOpenData] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [modalLagerErrorOpen, setModalLagerErrorOpen] = useState(false);

  // tabela i paginacija
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [duzinaNiza, setDuzinaNiza] = useState(0);
  const pageNumbers = [];

  // forma posebni usestatovi radi mijenjanja vrijednosti od nazad
  const [rabatPercentage, setRabatPercentage] = useState(null);
  const [rabat, setRabat] = useState(null);
  const [marzaValue, setMarzaValue] = useState(null);
  const [cijena, setCijena] = useState(null);

  // trosak
  const [trosakOpen, setTrosakOpen] = useState(false);
  const [promjenaNabavneCijene, setPromjenaNabavneCijene] = useState(false);
  const [calculatedMarza, setCalculatedMarza] = useState(false);

  // iznosi , kalkulacije na dnu stranice
  const [totalIznos, setTotalIznos] = useState(0);
  const [totalRabat, setTotalRabat] = useState(0);
  const [totalIznosNabavneCijene, setTotalIznosNabavneCijene] = useState(0);
  const [totalNabavnaCijena, setTotalNabavnaCijena] = useState(0);
  const [totalIznosPDV, setTotalIznosPDV] = useState(0);
  const [totalIznosMarze, setTotalIznosMarze] = useState(0);

  // funkcija koja nullira totale
  const SetTotalsToDefaults = () => {
    setTotalIznos(0);
    setTotalRabat(0);
    setTotalIznosPDV(0);
    setTotalIznosNabavneCijene(0);
    setTotalNabavnaCijena(0);
    setTotalIznosMarze(0);
  };

  // povlacenje podataka iz lokal storidza
  const token =
    typeof window !== "undefined" ? localStorage.getItem("accessToken") : null;
  const companyCode = localStorage.getItem("company");
  const companyYear = localStorage.getItem("companyYear");
  const userCode = localStorage.getItem("userCode");

  const [updateCijenaUCjenovniku, setUpdateCijenaUCjenovniku] = useState(false);
  const [updateNivelisiCjenovnik, setUpdateNivelisiCjenovnik] = useState(false);
  const [proslaVrijednostCijene, setProslaVrijednostCijene] = useState(null);
  // datepicker
  let todaysDate = moment().year(companyYear);
  const [datumK, setDatumK] = useState(dayjs(todaysDate));
  const [datumR, setDatumR] = useState(dayjs(todaysDate));
  const [valuta, setValuta] = useState(dayjs(todaysDate));

  // pomocne varijable
  const [selektovanaVrstaSkladista, setSelektovanaVrstaSkladista] =
    useState("");
  const [iznosTroska, setIznosTroska] = useState(0);
  const [iznosTrosak1, setIznosTrosak1] = useState(null);
  const [izmjenaDPVObveznika, setIzmjenaDPVObveznika] = useState(false);

  // cjenovnik dropdown
  const [izmjenaCijene, setIzmjenaCijene] = useState("D");
  const handleIzmjenaCijene = (event) => {
    setIzmjenaCijene(event.target.value);
  };

  // definisanje formi
  const [form, setForm] = useState({
    ...defaultForm,
  });
  const [prometForm, setPrometForm] = useState({
    ...defaultPrometForm,
  });

  // funkcija koja hvata promjene na poljima prometForme i setuje vrijednost u form state
  const handleChangePromet = (event) => {
    const target = event.target;
    let value = target.value;
    const name = event.target.name;
    setPrometForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // funkcija koja hvata promjene na poljima forme i setuje vrijednost u form state
  const handleChangePrometS = (event) => {
    const target = event.target;
    let value = target.value;
    const name = event.target.name;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // propsovi za auto komplit  - skladiste, komitent, artikal i vrstu; objekat za dati autokomplit iz koga preko mape mozemo procitati podatak upisan u bazu
  //  getOptionLabel - polja koja ce biti prikazana u autokomplitu
  const defaultProps = {
    options: selectData,
    getOptionLabel: (option) =>
      option ? `${option.SIFRA} ${option.NAZIV}` : "",
  };
  const defaultPropsNomen = {
    options: selectDataNomen,
    getOptionLabel: (option) =>
      option ? `${option.SIFRA} ${option.NAZIV}` : "",
  };
  const defaultPropsVrsta = {
    options: selectDataVrsta,
    getOptionLabel: (option) =>
      option ? ` ${option.VRSTA} ${option.NAZIV}` : "",
  };
  const defaultPropsClients = {
    options: selectDataClients,
    getOptionLabel: (option) =>
      option ? `${option.SIFRA} ${option.NAZIV}` : "",
  };

  // funkcija koja poziva citanje skladista
  const getRepositoryData = () => {
    fetch(API + `/skladista/all/${companyCode}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setSelectData(data);
      });
  };

  // funkcija koja poziva citanje artikla
  const getArticles = () => {
    fetch(API + `/ulaz/getAllArticles`, {
      method: "POST",
      body: JSON.stringify({
        SKLAID: prometForm.SKLAID,
        companyCode: companyCode,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // upisujemo dobijeni rezultat u useState
        setSelectDataNomen(data);
      });
  };

  // funkcija koja poziva citanje vrste
  const getVrstaData = () => {
    fetch(API + `/vrstaDok/all`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setSelectDataVrsta(data);
      });
  };

  // funkcija koja poziva citanje komitenta
  const getClientsData = () => {
    fetch(API + `/komitenti/all/${companyCode}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setSelectDataClients(data);
      });
  };

  // funkcija koja poziva api za pronalazenje prvog slobodnog dokumenta
  const getPrometFirstFreeNumber = () => {
    fetch(API + `/ulaz/firstFreeNumber`, {
      method: "POST",
      body: JSON.stringify({
        sklaId: repository && repository.ID ? repository.ID : "",
        vrprom: vrsta && vrsta.VRSTA ? vrsta.VRSTA : "",
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let promet = data[0];
        setPrometForm((prev) => ({
          ...prev,
          DOK: promet.DOK,
          ID: promet.ID,
        }));
        setFirstDocumentNumber(promet.DOK, promet.ID);
      });
  };
  // funkcija koja prati promjenu stanja(poziva apije za citanje i popunjavanje autokomplitova)
  useEffect(() => {
    getRepositoryData();
    getArticles();
    getVrstaData();
    getClientsData();
    // setovanje vrste prometa na UL
    setVrsta({
      VRSTA: "IZ",
      NAZIV: "IZLAZ",
      KOEF: 1,
      STRANA: "I",
      VEZA: "",
      ZABRANA: "N",
      naziv1: "RAČUN",
      PRIORITET: 0,
    });

    setPrometForm((prev) => ({
      ...prev,
      VRPROM: "IZ",
    }));
  }, [!repository && !artikal && !clients && !vrsta]);

  // funkcija koja poziva citanje lagera
  const getLagerData = (nomenid) => {
    fetch(API + `/lager/all`, {
      method: "POST",
      body: JSON.stringify({
        sklaId: prometForm?.SKLAID || "",
        nomenId: nomenid || 0,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data.length) {
          setForm(prev => ({ ...prev, LAGER: data[0].LAGER }))
        } else {
          setForm(prev => ({ ...prev, LAGER: 0 }))
        }
      });
  };

  // dobijanje odgovarajuceg poreza za artikal
  const getTaxData = (nomenid) => {
    fetch(API + `/porezi/procenat`, {
      method: "POST",
      body: JSON.stringify({ nomenId: nomenid }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data.length) {
          setForm((prev) => ({ ...prev, PDV: data[0].PROCENAT, POREZID: data[0].ID }));
        }
       // console.log(form.POREZID, 'form.POREZID')
      });
  };

  // funkcija koja poziva api za pronalazenje informacija o artiklu na osnovu Sifre artikla
  const getArticleInfo = () => {
    fetch(API + `/ulaz/articleInfo`, {
      method: "POST",
      body: JSON.stringify({
        SKLAID: prometForm?.SKLAID || "",
        SIFRAARTIKLA: form?.SIFRA || 0,
        companyCode: companyCode || 0,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let articleData = data[0];
        if (articleData) {
          getLagerData(articleData?.NOMENID);
          getTaxData(articleData?.NOMENID);
          setForm((prev) => ({
            ...prev,
            FAKTCEN: articleData?.NABCENA || 0,
            NAZIV: articleData?.NAZIV || "",
            SIFRA: articleData?.SIFRA || 0,
            NABCEN: articleData?.NABCENA || 0,
            MALCEN: articleData?.PRODCENA || 0,
            JM: articleData?.JM || "",
            NOMENID: articleData?.NOMENID || 0,
          }));
          setProslaVrijednostCijene(articleData?.PRODCENA);
          setArtikal({
            NAZIV: articleData?.NAZIV || "",
            SIFRA: articleData?.SIFRA || 0,
            NABCEN: articleData?.NABCENA || 0,
            JM: articleData?.JM || "",
          });
        }
      });
  };

  // funkcija koja poziva api za pronalazenje prvog slobodnog rednog broja
  // setovanje polja RBR
  const getPrometFirstFreeNumberRbr = () => {
    fetch(API + `/izlaz/firstFreeNumberRbr`, {
      method: "POST",
      body: JSON.stringify({
        sklaId: prometForm.SKLAID || "",
        vrprom: prometForm.VRPROM || "",
        dok: prometForm.DOK,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setRabat(null);
        setMarzaValue(null);
        setCijena(null);
        setForm((prev) => ({ ...prev, RBR: data[0].RBR }));
      });
  };

  // funckija koja poziva upisivanje stavki u tabetu
  const getPromet = () => {
    fetch(
      API + `/izlaz/all?page=${currentPage}&limit=${itemsPerPage}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          SKLAID: prometForm.SKLAID,
          DOK: prometForm.DOK,
          VRPROM: prometForm.VRPROM,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setIzlazFakturisanje(data.result);
        setDuzinaNiza(Math.ceil(data.total / itemsPerPage));
        getSumTotals(data.result);
        // let totals = {
        //   UKUPNO: 0,
        //   IZNOS_POREZA: 0,
        //   IZNOS_RABATA: 0,
        //   MARZA: 0,
        //   OSNOVICA: 0,
        // };
        // data.result.forEach((s) => {
        //   totals.UKUPNO += roundTO(s.UKUPNO, 3);
        //   totals.IZNOS_POREZA += roundTO(s.PDV, 2);
        //   totals.IZNOS_RABATA += roundTO(s.RABAT, 2);
        //   totals.MARZA += roundTO(s.MARZA, 2);
        // });
        // setTotalIznos(roundTO(totals.UKUPNO, 3));
        // setTotalIznosPDV(roundTO(totals.IZNOS_POREZA, 2));
        // setTotalNabavnaCijena(
        //   roundTO(totals.UKUPNO - totals.IZNOS_POREZA + totals.IZNOS_RABATA, 2)
        // );
        // setTotalIznosNabavneCijene();
        // setTotalRabat(roundTO(totals.IZNOS_RABATA, 3));
        // setTotalIznosMarze(roundTO(totals.MARZA, 2));
        // setTotalIznosNabavneCijene(
        //   roundTO(totals.UKUPNO - totals.IZNOS_POREZA, 2)
        // );
      });
  };

  // funckija koja sabira totale i prikazuje ih u desnom donjem cosku
  const getSumTotals = (iznosi) => {
    let totals = {
      UKUPNO: 0,
      IZNOS_POREZA: 0,
      IZNOS_RABATA: 0,
      MARZA: 0,
      OSNOVICA: 0,
    };
    iznosi.forEach((s) => {
      totals.UKUPNO += roundTO(s.UKUPNO, 3);
      totals.IZNOS_POREZA += roundTO(s.PDV, 2);
      totals.IZNOS_RABATA += roundTO(s.RABAT, 2);
      totals.MARZA += roundTO(s.MARZA, 2);
    });
    setTotalIznos(roundTO(totals.UKUPNO, 3));
    setTotalIznosPDV(roundTO(totals.IZNOS_POREZA, 2));
    setTotalNabavnaCijena(
      roundTO(totals.UKUPNO - totals.IZNOS_POREZA + totals.IZNOS_RABATA, 2)
    );
    setTotalIznosNabavneCijene();
    setTotalRabat(roundTO(totals.IZNOS_RABATA, 3));
    setTotalIznosMarze(roundTO(totals.MARZA, 2));
    setTotalIznosNabavneCijene(roundTO(totals.UKUPNO - totals.IZNOS_POREZA, 2));
  };

  // funkcija koja poziva upisivanje informacija u prometS formu
  const getPrometInfo = () => {
    // console.log( prometForm.SKLAID, 'prometForm.SKLAID', prometForm.DOK, 'prometForm.DOK', prometForm.VRPROM, 'prometForm.VRPROM')
    fetch(API + `/ulaz/promet`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({
        SKLAID: prometForm.SKLAID,
        DOK: prometForm.DOK,
        VRPROM: prometForm.VRPROM,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        let promet = data[0];
        if (promet) {
          setPrometForm((prev) => ({ ...prev, ...promet, KOMTID: data[0].KOMTID }));
          // setClients({
          //   NAZIV: promet.NAZIV,
          //   SIFRA: promet.KSifra,
          // });
          // setKomt(promet.KOMTID);
          setDatumK(dayjs(promet.DPO, "DD.MM.YYYY"));
          setDatumR(dayjs(promet.DPOR, "DD.MM.YYYY"));
          setValuta(dayjs(promet.VALUTA, "DD.MM.YYYY"));
          setIzlazFakturisanje([]);
          setCurrentPage(1);
          setDuzinaNiza(0);
          SetTotalsToDefaults();
          getPromet();
          getArticles();
          getPrometFirstFreeNumberRbr();

          selectDataClients.map((item) => {
            if (item.ID == promet.KOMTID) {
              setClients({
                NAZIV: promet.NAZIV,
                SIFRA: promet.KSifra,
              });
            }
          })
        } else {
          // vracanje datepickera na pocetne vrijednosti
          getArticles();
          setDatumK(todaysDate);
          setDatumR(todaysDate);
          setValuta(todaysDate);
          // vracanje forme na pocetne vrijednost
          setForm((prev) => ({
            ...prev,
            ...defaultForm,
          }));
          // vracanje na pocetne vrijednosti prometID, datuma racuna, naziva racuna, kursa
          setPrometForm((prev) => ({
            ...prev,
            PROMETID: 0,
            DPO: moment(todaysDate).format("DD.MM.YYYY"),
            RACUN: "",
            KURS: "",
          }));
          setIzlazFakturisanje([]);
          setCurrentPage(1);
          setDuzinaNiza(0);
          SetTotalsToDefaults();
          // setClients(null);
          getPrometFirstFreeNumberRbr();
        }
      });
  };

  // funkcije za provjeru obaveznih polja - skladiste
  const checkRepositoryRequired = (value) => {
    // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto)
    // ili ako nije upisana ni vrijednost niti je odabrano skladiste, izbaci poruku, setuje crveni okvir i fokusira polje
    // u suprotnom prolazi dalje
    if ((value && !repository) || (!value && !repository)) {
      setErrorMsgRepository("Skladiste mora biti izabrano!");
      setErrorInputRepository(true);
      repositoryRef.current.focus();
      setRepositoryFocus(true);
      return false;
    } else {
      setErrorMsgRepository(null);
      setErrorInputRepository(false);
      return true;
    }
  };

  // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto)
  // ili ako nije upisana ni vrijednost niti je odabrana vrsta, izbaci poruku, setuje crveni okvir i fokusira polje
  // u suprotnom prolazi dalje
  const checkVrstaRequired = (value) => {
    if ((value && !vrsta) || (!value && !vrsta)) {
      setErrorMsgVrsta("Vrsta mora biti izabrana!");
      setErrorInputVrsta(true);
      vrstaRef.current.focus();
      setVrstaFocus(true);
      return false;
    } else {
      setErrorMsgVrsta(null);
      setErrorInputVrsta(false);
      return true;
    }
  };

  // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto)
  // ili ako nije upisana ni vrijednost niti je odabran komitent, izbaci poruku, setuje crveni okvir i fokusira polje
  // u suprotnom prolazi dalje
  const checkDocumentRequired = (value) => {
    if (!value || value == 0) {
      setErrorMsgDocument("Dokument je obavezan!");
      setErrorInputDocument(true);
      documentRef.current.focus();
      return false;
    } else {
      setErrorMsgDocument(null);
      setErrorInputDocument(false);
      return true;
    }
  };

  // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto)
  // ili ako nije upisana ni vrijednost niti je odabran artikal, izbaci poruku, setuje crveni okvir i fokusira polje
  // u suprotnom prolazi dalje
  const checkNomenRequired = (value) => {
    if (value === null || value == 0 || value == "" || value.SIFRA == 0) {
      if (!form.SIFRA) {
        setErrorMsgNomen("Artikal je obavezan!");
        // setErrorInputNomen(true);
        nomenRef.current.focus();
        return false;
      }
    } else {
      setErrorMsgNomen(null);
      // setErrorInputNomen(false);
      return true;
    }
  };

  // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto) ili ako nije upisana ni vrijednost niti je odabran komitent, izbaci poruku, setuje crveni okvir i fokusira polje
  // u suprotnom prolazi dalje
  const checkClientsRequired = (value) => {
    if ((value && !clients) || (!value && !clients)) {
      setErrorMsgClients("Komitent mora biti izabran!");
      clientsRef.current.focus();
      return false;
    } else {
      setErrorMsgClients(null);
      return true;
    }
  };

  const checkRBRRequired = (value) => {
    if (!value || value <= 0) {
      setErrorMsgRbr("Rbr je obavezan!");
      setErrorInputRbr(true);
      rbrRef.current.focus();
      return false;
    } else {
      setErrorMsgRbr(null);
      setErrorInputRbr(false);
      return true;
    }
  };

  const checkKolRequired = (value) => {
    // uslov: ako nije upisana vrijednost manja od 0 ili je vrijednost 0,
    // izbaci poruku. U suprotnom: prolazi dalje.
    if (value <= 0 || value == "") {
      setErrorMsgKolicina("Količina je obavezna!");
      kolicinaRef.current.focus();
      return false;
    }
    if (form.LAGER - value < 0) {
      setModalLagerErrorOpen(true);
    } else {
      setModalLagerErrorOpen(false);
      setErrorMsgKolicina(null);
      return true;
    }
  };

  const checkFaktcenRequired = (value) => {
    if (value <= 0) {
      setErrorMsgFaktcen("Cijena je obavezna!");
      faktCenRef.current.focus();
      return false;
    } else {
      setErrorMsgFaktcen(null);
      return true;
    }
  };

  // const checkIZNFAKTRequired = (value) => {
  //   if (value <= 0 || value == "" || value == undefined) {
  //     setErrorMsgIznFaktcen("Iznos je obavezan!");
  //     iznFaktRef.current.focus();
  //     return false;
  //   } else {
  //     setErrorMsgIznFaktcen(null);
  //     return true;
  //   }
  // };

  // funkcije za provjeru obaveznih polja - cijena
  const checkPriceRequired = (value) => {
    if (value == 0) {
      setErrorMsgPrice("Unesite ispravnu cijenu");
      prodajnaCijenaRef.current.focus();
      return false;
    } else {
      setErrorMsgPrice(null);
      return true;
    }
  };

  // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto)
  // ili ako nije upisana ni vrijednost niti je odabran artikal, izbaci poruku, setuje crveni okvir i fokusira polje
  // u suprotnom prolazi dalje
  const checkSifraRequired = (value) => {
    //  console.log('upada u checkSifraRequired')
    let doesItemExistInArtikli = selectDataNomen.some(
      (artikal) => artikal.SIFRA == value
    );
    //  console.log(doesItemExistInArtikli, 'doesItemExistInArtikli')
    //  console.log(!doesItemExistInArtikli, '!doesItemExistInArtikli')
    if (!doesItemExistInArtikli) {
      setErrorMsgSifra("Unesite šifru artikla!");
      sifraRef.current.focus();
      return false;
    } else {
      setErrorMsgSifra(null);
      return true;
    }
  };

  function unosIspravanDo(name) {
    // skladiste
    if (name == "SKLAID") {
      return true;
    }

    if (!checkRepositoryRequired(prometForm.SKLAID)) {
      return false;
    }
    // vrsta prometa
    if (name == "VRPROM") {
      return true;
    }

    if (!checkVrstaRequired(prometForm.VRPROM)) {
      return false;
    }

    // dokument
    if (name == "DOK") {
      return true;
    }

    if (!checkDocumentRequired(prometForm.DOK)) {
      return false;
    }

    // datum fakt.
    if (name == "DPO") {
      return true;
    }

    let regex = /^(0[1-9]|[12]\d|3[01]).(0[1-9]|1[0-2]).([12]\d{3})$/gi;
    if (!regex.test(prometForm.DPO)) {
      setErrorDate(true);
      setErrorMsgDate("Datum nije validan!");
      dateRef.current.focus();
      return false;
    } else {
      setErrorDate(false);
      setErrorMsgDate(null);
    }

    // racun
    if (name == "RACUN") {
      return true;
    }

    // komitent
    if (name == "KOMTID") {
      return true;
    }

    if (!checkClientsRequired(prometForm.KOMTID)) {
      return false;
    }

    // redni broj
    if (name == "RBR") {
      return true;
    }

    if (!checkRBRRequired(form.RBR)) {
      return false;
    }

    // sifra artikla
    if (name == "SIFRA") {
      return true;
    }

    if (!checkSifraRequired(form.SIFRA)) {
      return false;
    }

    // artikal
    if (name == "ARTIKAL") {
      return true;
    }

    if (!checkNomenRequired(artikal)) {
      return false;
    }

    // kolicina
    if (name == "KOLICINA") {
      return true;
    }

    if (!checkKolRequired(form.KOLICINA)) {
      return false;
    }

    // maloprodajana cijena
    if (name == "MALCEN") {
      return true;
    }

    if (!checkFaktcenRequired(form.MALCEN)) {
      return false;
    }


    // procenat rabata
    if (name == "RABAT") {
      return true;
    }

    // iznos rabata
    if (name == "IZNRABAT") {
      return true;
    }

    // let regexMalcen = /^(0[1-9]|[12]\d|3[01]).(0[1-9]|1[0-2])$/gi;
    // prodajna cijena
    if (name == "PRODAJNACIJENA") {
      return true;
    }

    if (!checkPriceRequired(form.PRODAJNACIJENA)) {
      return false;
    }
    // if (!regexMalcen.test(prometForm.PRODAJNACIJENA)) {
    //   setErrorDate(true);
    //   setErrorMsgDate("Cijena nije validna!");
    //   dateRef.current.focus();
    //   return false;
    // } else {
    //   setErrorDate(false);
    //   setErrorMsgDate(null);
    // }
    // kontrolise sva polja do polja UPIS
    if (name === "UPIS") {
      return true;
    }

    return true;
  }

  const onEdit = async (item) => {
    setItemUpdate(true);
    // await fetch(API + `/cjenovnik/all`, {
    //   method: "POST",
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //     "Content-type": "application/json; charset=UTF-8",
    //   },
    //   body: JSON.stringify({
    //     sklaId: prometForm.SKLAID,
    //     nomenId: item.ARTIKAL,
    //   }),
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     let artikal = data[0];
    //     if (artikal) {
    //       getLagerData(item.ARTIKAL);
    //       getTaxData(item.ARTIKAL);
    setForm((prev) => ({
      ...prev,
      KOLICINA: item.KOL,
      MALCEN: item.NOM_CENA,
      IZNMALD: item.SALDO,
      RABAT: item.PROC_R,
      IZNRABAT: item.RABAT,
      PRODAJNACIJENA: item.CENA,
      IZNMALDP: item.UKUPNO,
      PDV: item.PROCENAT,
      IZPDV: item.PDV,
      RBR: item.RBR,
      SIFRA: item.ARTIKAL,
      RAZCEN: item.RAZCEN,
      NOMENID: item.NOMENID,
      IZNRAZD: item.MARZA,
    }));
    //     }
    //   });

    selectDataNomen.map((artikal) => {
      if (artikal.SIFRA == item.ARTIKAL) {
        setArtikal(artikal);
      }
    });

    // form.NOMENID se puni da bi radilo citanje lagera iz mreze
    let NOMENID = item.NOMENID
    getLagerData(NOMENID);

  };

  const onSubmit = async () => {
    if (!unosIspravanDo("UPIS")) return;
    form.PROMETID = prometForm.PROMETID;
    form.VRPROM = prometForm.VRPROM;
    form.DOK = prometForm.DOK;
    form.SKLAID = prometForm.SKLAID;
    form.KOMTID = prometForm.KOMTID;
    form.RACUN = prometForm.RACUN;
    form.SIFKOR = userCode;
    form.VRSTA_SKLADISTA = prometForm.VRSTA_SKLADISTA;
    // datumi, formatiran datum DD.MM.YYYY
    form.DPO = prometForm.DPO;
    form.DPOR = dayjs(datumR).format("DD.MM.YYYY");
    form.VALUTA = dayjs(valuta).format("DD.MM.YYYY");
    // iznos pdva
    form.IZPDV = getIznosPDVa(
      selektovanaVrstaSkladista,
      form.PDV,
      form.KOLICINA,
      form.IZNMALD
    );
    form.IZNOS_POREZA = getIznosPDVa(
      selektovanaVrstaSkladista,
      form.PDV,
      form.KOLICINA,
      form.IZNMALD
    );

    fetch(API + "/izlaz/new", {
      method: "POST",
      body: JSON.stringify(form),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        response.json();
        setForm((prev) => ({
          ...prev,
          ...defaultForm,
        }));
      })
      .then(() => {
        getPrometInfo();
        rbrRef.current.focus();
      });
  };

  function onPrint() { }

  // funkcija koja poziva brisanje dokumenta
  const onDelete = (sklaId, vrprom, dok) => {
    if (form.ovlrobno == 4)
      return setModalErrorAuthorizationOpen(true), setModalDeleteItem(false);

    fetch(API + `/izlaz/document/delete/${sklaId}/${vrprom}/${dok}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-type': 'application/json; charset=UTF-8'
      },
    })
      .then((response) => {
        if (response.status == 200) {
          setModalOpen(false);
          setModalDeleteItem(false);
          setIzlazFakturisanje(null);
          setClients(null)
          setArtikal(null);
          // checkDocument();
          setItemUpdate(false);
          getPrometInfo();
          setForm((prev) => ({
            ...prev,
            ...defaultForm,
          }));
        }
      })

    //  zaustavljanje fokusa(pauza za fokus dok ne odradi brisanje) 
    const timeout = setTimeout(() => {
      documentRef.current.focus()
    }, 400);
    return () => {
      clearTimeout(timeout);
    };
  }

  // funkcija koja poziva api za brisanje stavki
  const onDeleteItems = (prometID, rbr) => {
    // provjera ovlascenja za brisanje
    if (form.ovlrobno == 4)
      return setModalErrorAuthorizationOpen(true), setModalOpen(false);

    fetch(API + `/izlaz/item/delete/${prometID}/${rbr}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    }).then((response) => {
      // uslov: ako je uspjesno obrisano, cisti sva polja u vezi sa stavkom
      if (response.status == 200) {
        setModalDeleteItem(false);
        setIzlazFakturisanje(null);
        setArtikal(null);
        // checkDocument();
        setItemUpdate(false);
        getPrometInfo();
        setForm((prev) => ({
          ...prev,
          ...defaultForm,
        }));
      }
      // zaustavljanje fokusa(pauza za fokus dok ne odradi brisanje)
      const timeout = setTimeout(() => {
        rbrRef.current.focus();
      }, 500);
      return () => {
        clearTimeout(timeout);
      };
    });
  };

  // unosenje headera i stilova unutar tabele sljedecim parametrima:
  const columns = [
    {
      name: "Rbr.",
      class: "col-1 fs-6 text-end px-3 text-nowrap ",
    },
    {
      name: "Šifra",
      class: "col-1 fs-6 text-end px-3 text-nowrap",
    },
    {
      name: "Artikal",
      class: "col-2 fs-6 px-3 text-nowrap",
    },
    {
      name: "Količina",
      class: "col-1 fs-6 text-end px-3 text-nowrap",
    },
    {
      name: "Nom. Cijena",
      class: "col-1 fs-6 text-end px-3 text-nowrap",
    },
    // {
    //   name: "Art. Iznos",
    //   class: "col-1 fs-6 text-end px-3 text-nowrap",
    // },
    {
      name: "Rabat %",
      class: "col-1 fs-6 text-end px-3 text-nowrap",
    },
    {
      name: "Rabat",
      class: "col-1 fs-6 text-end px-3 text-nowrap",
    },
    {
      name: "Prod. cijena",
      class: "col-1 fs-6 text-end px-3 text-nowrap",
    },
    {
      name: "Prod. Iznos",
      class: "col-1 fs-6 text-end px-3 text-nowrap",
    },
  ];

  // funkcija koja je zaduzena za prikazivanje sljedecih i prethodnih brojeva stranica na paginaciji
  getPageNumber(currentPage, duzinaNiza, pageNumbers);

  // useeffect koji se poziva na svaku promjenu u paginaciji (currentPage ,itemsPerPage ) ukoliko prometForm.DOK postoji
  useEffect(() => {
    if (prometForm.DOK) {
      getPromet(currentPage, itemsPerPage);
    }
  }, [currentPage, itemsPerPage]);

  let prodajniIznos;
  let prodajnaCijena;

  return (
    <div>
      {/* kontejner za naslov i ikonice */}
      <div className="title-container">
        <header className="title-header">
          <h1 id="media-query-title">Izlaz Fakturisanje</h1>
        </header>

        <div className="naslov-ikonice">
          <ul className="nav flex-lg-row justify-content-end">
            <li className="nav-item toolbaritem">
              <input
                type="image"
                alt="save"
                className="dugme-sacuvaj"
                title="Sačuvaj"
                tabIndex="18"
                src={require("./../../assets/images/floppydisk.png")}
                onFocus={() => (!itemUpdate ? onSubmit() : null)}
              />
            </li>

            <li className="nav-item toolbaritem">
              <input
                type="image"
                alt="print"
                className="dugme-stampa"
                title="Štampa"
                tabIndex="19"
                src={require("./../../assets/images/printer.png")}
                onClick={onPrint}
              />
            </li>

            <li className="nav-item toolbaritem">
              <div
                onClick={
                  prometForm.DOK && prometForm.DOK !== "" && prometForm.DOK !== 0
                    ? setModalOpen
                    : setModalErrorDeleteDocumentOpen
                }
                tabIndex="22"
              >
                <SVG
                  src={deleteDocument}
                  style={{ margin: "1px" }}
                  title="Brisanje dokumenta"
                  className="dugme-brisanje"
                />
              </div>
            </li>

            <li className="nav-item toolbaritem">
              <input
                type="image"
                alt="delete item"
                className="dugme-stampa"
                title="Brisanje stavke"
                tabIndex="20"
                src={require("./../../assets/images/trash1.png")}
                onClick={
                  itemUpdate ? setModalDeleteItem : setModalErrorDeleteItemOpen
                }
              />
            </li>
          </ul>
        </div>
      </div>
      <Container fluid>
        <Row>
          <div className="col-12 d-flex">
            <div className="col-5 d-flex flex-column gap-2">
              <div className="col-12">
                <div
                  className={
                    errorInputRepository
                      ? "inputWithError d-flex "
                      : "field-container d-flex "
                  }
                >
                  <label className="fs-7 font-weight-bold col-2 m-0 text-nowrap minWidth80">
                    Skladište:
                  </label>
                  {/* autokomplit za skladiste */}
                  <Autocomplete
                    //ukoliko nema ove funkcije, u konzoli izbacuje upozorenje da nije vrijednost jedinstvena
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    {...defaultProps}
                    className="w-100"
                    value={repository}
                    disablePortal={true}
                    onFocus={() => {
                      setRepositoryFocus(true);
                      unosIspravanDo("SKLAID");
                    }}
                    // ako se promijeni skladiste, uzima podatke za novo izabrano skladiste
                    onChange={(event, newValue) => {
                      setSelektovanaVrstaSkladista(newValue.VRSTA_SKLADISTA);
                      setPrometForm((prev) => ({
                        ...prev,
                        SKLAID: newValue && newValue != null ? newValue.ID : "",
                        VRSTA_SKLADISTA:
                          newValue && newValue != null
                            ? newValue.VRSTA_SKLADISTA
                            : "",
                      }));
                      setRepository(newValue);
                      repositoryRef.current.focus();
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        autoFocus
                        className="clients-textinput"
                        maxLength="295"
                        name="SKLAID"
                        tabIndex="1"
                        ref={repositoryRef}
                        //   onBlur={(e) => formCheck(e)}
                        placeholder="Izaberite skladište"
                        variant="standard"
                      />
                    )}
                  />
                </div>
                {/* prikazivanje greske ukoliko skladiste nije izabrano */}
                {errorMsgRepository && (
                  <div className="col-12">
                    <span className="error-message">{errorMsgRepository}</span>
                  </div>
                )}
              </div>
              <div className="col-12 d-flex justify-content-between">
                <div className="d-flex col-6">
                  <div
                    className={
                      errorMsgDate
                        ? "inputWithError"
                        : "d-flex col-8 justify-content-between "
                    }
                  >
                    <label className="fs-7 m-0 font-weight-bold text-nowrap minWidth80">
                      Datum fakt.:
                    </label>
                    <input
                      type="text"
                      className="w-50 m-0"
                      tabIndex="4"
                      ref={dateRef}
                      onFocus={() => {
                        setDateFocus(true);
                        unosIspravanDo("DPO");
                        getPrometInfo();
                      }}
                      placeholder=""
                      name="DPO"
                      value={prometForm.DPO}
                      onChange={(e) => handleChangePromet(e)}
                    />
                    {/* prikazivanje greske ukoliko je izabran los datum  */}
                    {errorMsgDate && (
                      <div className="col-6">
                        <span className="error-message">{errorMsgDate}</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex col-6 justify-content-between">
                  <div className="col-4 minWidth80">
                    <label className="ps-3 fs-7 font-weight-bold m-0 text-nowrap gray-500">
                      Dat. isporuke:
                    </label>
                  </div>
                  <div className="col-8">
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="de"
                    >
                      <DatePicker
                        value={dayjs(datumR)}
                        onChange={(newDate) => {
                          unosIspravanDo("DPOR");
                          setDatumR(newDate);
                        }}
                        ref={dateDeliveryRef}
                        TextField={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div
                  className={
                    errorMsgClients
                      ? "inputWithError d-flex"
                      : "field-container d-flex"
                  }
                >
                  <label className="fs-7 col-2 m-0 font-weight-bold text-nowrap minWidth80">
                    Komitent:
                  </label>
                  {/* autokomplit za komitenta */}
                  <Autocomplete
                    ref={komtRef}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    {...defaultPropsClients}
                    value={clients}
                    className="w-100"
                    disablePortal={true}
                    onFocus={() => {
                      setClientsFocus(true);
                      unosIspravanDo("KOMTID");
                    }}
                    // ako se promijeni komitent, uzima podatke za novog izabranog komitenta
                    onChange={(event, newValue) => {
                      setPrometForm((prev) => ({
                        ...prev,
                        KSifra:
                          newValue && newValue != null ? newValue?.SIFRA : "",
                        KOMTID:
                          newValue && newValue != null ? newValue?.ID : "",
                      }));
                      setClients(newValue);
                      // fokus se mora vratiti inace tabIndex ne radi dobro
                      clientsRef.current.focus();
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        tabIndex="8"
                        maxLength="283"
                        className="clients-textinput"
                        name="KOMTID"
                        ref={clientsRef}
                        placeholder="Izaberite komitenta"
                        variant="standard"
                      />
                    )}
                  />
                </div>
                {/* prikazivanje greske ukoliko komitent nije izabran */}
                {errorMsgClients && (
                  <div className="col-12">
                    <span className="error-message">{errorMsgClients}</span>
                  </div>
                )}
              </div>
            </div>
            <div className="col-3 d-flex flex-column gap-2 ps-2">
              <div className="col-12 d-flex flex-column">
                <div
                  className={
                    errorInputVrsta
                      ? "inputWithError d-flex"
                      : "field-container d-flex"
                  }
                >
                  <label className="fs-7 m-0 font-weight-bold text-nowrap minWidth80">
                    Vrsta:
                  </label>

                  {/* autokomplit za vrstu */}
                  <Autocomplete
                    //ukoliko nema ove funkcije, u konzoli izbacuje upozorenje da nije vrijednost jedinstvena
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    {...defaultPropsVrsta}
                    value={vrsta}
                    className="w-100"
                    disablePortal={true}
                    onFocus={() => {
                      setVrstaFocus(true);
                      unosIspravanDo("VRPROM");
                    }}
                    // ako se promijeni vrsta, uzima podatke za novu izabranu vrstu
                    onChange={(event, newValue) => {
                      setPrometForm((prev) => ({
                        ...prev,
                        VRPROM:
                          newValue && newValue != null ? newValue.VRSTA : "",
                        NAZIV:
                          newValue && newValue != null ? newValue.NAZIV : "",
                      }));
                      setVrsta(newValue);
                      vrstaRef.current.focus();
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        tabIndex="2"
                        maxLength="2"
                        className="clients-textinput"
                        name="VRPROM"
                        ref={vrstaRef}
                        placeholder=""
                        variant="standard"
                      />
                    )}
                  />
                </div>
                <div className="col-4"></div>
                {/* prikazivanje greske ukoliko vrsta nije izabrana */}
                {errorMsgVrsta && (
                  <div className="col-12">
                    <span className="error-message">{errorMsgVrsta}</span>
                  </div>
                )}
              </div>
              <div className="col-12 d-flex">
                <div className="d-flex col-6">
                  <div className="col-4 minWidth80">
                    <label className="fs-7 m-0 font-weight-bold text-nowrap gray-500">
                      Valuta:
                    </label>
                  </div>
                  <div className="col-10">
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="de"
                    >
                      <DatePicker
                        value={dayjs(valuta)}
                        onChange={(newDate) => {
                          unosIspravanDo("DPOR");
                          setValuta(newDate);
                        }}
                        ref={dateDeliveryRef}
                        TextField={(params) => (
                          <TextField tabIndex={6} {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-2 d-flex flex-column gap-1 ps-2">
              <div className="col-12 d-flex">
                <label className="fs-7 m-0 font-weight-bold text-nowrap minWidth80">Dok.:</label>
                {/* komponenta za formatiranje brojeva */}
                <NumericFormat
                  type="number"
                  getInputRef={documentRef}
                  className="w-25"
                  maxLength="9"
                  tabIndex="3"
                  decimalScale={0}
                  onFocus={() => {
                    setDocumentFocus(true);
                    unosIspravanDo("DOK");
                    getPrometFirstFreeNumber();
                    setKomt(null);
                  }}
                  placeholder="0"
                  name="DOK"
                  value={prometForm?.DOK}
                  onChange={(e) => {
                    handleChangePromet(e);
                  }}
                  onClick={() => {
                    // ako je odradjen dvoklik na stavku u mrezi, a zatim klik na dokument, cisti polja
                    setUpdateDocument(false);
                    setItemUpdate(false);
                    setPrometForm((prev) => ({
                      ...prev,
                      defaultPrometForm,
                    }));
                    setArtikal(null);
                  }}
                />
              </div>
              <div className="col-12">
                <div className="field-container d-flex">
                  <label className="fs-7 m-0 font-weight-bold text-nowrap minWidth80">
                    Račun:
                  </label>
                  <input
                    type="text"
                    ref={racunRef}
                    onFocus={() => {
                      unosIspravanDo("RACUN");
                    }}
                    maxLength="30"
                    tabIndex={7}
                    placeholder="Unesite racun "
                    name="RACUN"
                    className="m-0 w-50"
                    value={prometForm.RACUN}
                    onChange={(e) => handleChangePromet(e)}
                  />
                </div>
              </div>
            </div>
            <div className="col-2 d-flex flex-column gap-1 ps-2">
              <div className="col-12 d-flex">
                <div className="col-6 d-flex flex-column gap-2">
                  <div className="col-12">
                    <div className="d-flex justify-content-between">
                      <label className="fs-7 font-weight-bold text-nowrap m-0  text-end">
                        Porez %:
                      </label>
                      {/* komponenta za formatiranje brojeva */}
                      <input
                        type="text"
                        className={
                          !itemUpdate &&
                            form.NOVA_CENA &&
                            form.NOVA_CENA < form.NABCEN
                            ? "bg-danger w-50 px-2 text-end"
                            : "w-50 px-2 text-end"
                        }
                        disabled
                        maxLength="15"
                        //  decimalScale={3}
                        placeholder="0"
                        value={numberWithCommasColumns(form.PDV)}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex justify-content-between">
                      <label className="fs-7 font-weight-bold text-nowrap m-0">Marža %:</label>
                      {/* komponenta za formatiranje brojeva */}
                      <input
                        type="text"
                        className="w-50 px-2 text-end"
                        disabled
                        maxLength="15"
                        //  decimalScale={3}
                        placeholder="0"
                        value={numberWithCommasColumns3(form.RAZCEN)}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex justify-content-between">
                      <label className="fs-7 font-weight-bold text-nowrap m-0">Lager:</label>
                      {/* komponenta za formatiranje brojeva */}
                      <input
                        type='text'
                        className="w-50 px-2 text-end"
                        disabled
                        maxLength="15"
                        placeholder="0.00"
                        value={numberWithCommasColumns(form.LAGER)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-6 d-flex flex-column gap-2 ps-2">
                  <div className="col-12">
                    <div className="d-flex justify-content-between">
                      <label className="fs-7 font-weight-bold text-nowrap m-0">Porez:</label>
                      {/* komponenta za formatiranje brojeva */}
                      <NumericFormat
                        type="number"
                        className="w-50 px-2"
                        disabled
                        maxLength="15"
                        decimalScale={3}
                        placeholder="0"
                        value={form.IZPDV}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="d-flex justify-content-between">
                      <label className="fs-7 font-weight-bold text-nowrap m-0">Marža:</label>
                      {/* komponenta za formatiranje brojeva */}
                      <NumericFormat
                        type="number"
                        className={
                          form.IZNRAZD < 0 ? "bg-danger w-50 px-2" : "w-50 px-2"
                        }
                        disabled
                        maxLength="15"
                        decimalScale={3}
                        placeholder="0"
                        value={form.IZNRAZD}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Row>
        <Row>
          <div className="col-12 d-flex flex-lg-row flex-wrap my-3 p-0">
            <div className="col-6 col-lg-1">
              <div className={errorMsgRbr ? "text-end inputWithError" : "text-end"}>
                <div>
                  <label className="fs-7 font-weight-bold text-nowrap m-0 px-1">Rbr.</label>
                  {/* komponenta za formatiranje brojeva */}
                  <NumericFormat
                    type="number"
                    getInputRef={rbrRef}
                    maxLength="9"
                    decimalScale={0}
                    tabIndex={9}
                    name="RBR"
                    value={form.RBR}
                    onFocus={() => {
                      unosIspravanDo("RBR");
                      if (komt && komt !== clients.NAZIV) {
                        getPrometInfo();
                      } else {
                        getPromet();
                      }
                      getPrometFirstFreeNumberRbr();
                      let rbr = form.RBR;
                      setForm((prev) => ({
                        ...prev,
                        ...defaultForm,
                        RBR: rbr,
                      }));
                      setItemUpdate(false);
                      setTrosakOpen(false);
                      setArtikal(null);
                      setIznosTroska(0);
                      setIznosTrosak1(null);
                      setCalculatedMarza(false);
                    }}
                    onChange={(e) => {
                      handleChangePrometS(e);
                    }}
                  />
                  {/* prikazivanje poruke o pogresnom upisu  */}
                  {errorMsgRbr && (
                    <div className="col-12">
                      <span className="error-message">{errorMsgRbr}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-1 ps-1">
              <div className={errorMsgSifra ? "text-end inputWithError" : "text-end"}>
                <div>
                  <label className="fs-7 font-weight-bold text-nowrap m-0 px-1">Šifra</label>
                  {/* komponenta za formatiranje brojeva */}
                  <NumericFormat
                    type="number"
                    getInputRef={sifraRef}
                    maxLength="3"
                    // broj decimala
                    decimalScale={0}
                    tabIndex={10}
                    placeholder="0"
                    name="SIFRA"
                    value={form.SIFRA}
                    onFocus={() => {
                      if (itemUpdate) {
                        setModalErrorOpen(true);
                      }
                      unosIspravanDo("SIFRA");
                    }}
                    onChange={(e) => {
                      handleChangePrometS(e);
                      setForm((prev) => ({
                        ...prev,
                        SIFRA: e.target.value,
                      }));
                    }}
                  />
                  {/* prikazivanje poruke o pogresnom upisu  */}
                  {errorMsgSifra && (
                    <div className="col-12">
                      <span className="error-message">{errorMsgSifra}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-4 ps-1">
              {/* artikal */}
              <div className={errorMsgNomen ? "inputWithError" : "field-container"}>
                <label className="m-0">Artikal</label>
                <Autocomplete
                  ref={nomenRef}
                  // svaki od ponudjenih artikala mora biti jedinstven(svaka stavka(child) u objektu artikal mora imati jedinstven kljuc)
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderOption={(props, artikal) => {
                    // svaki artikal renderujemo kao -li element unutar koga postoje 2 spana, te su odvojeni ps-3
                    return (
                      <li {...props} key={artikal.SIFRA}>
                        <span>{artikal.SIFRA}</span>
                        <span className="ps-1">{artikal.NAZIV}</span>
                      </li>
                    );
                  }}
                  {...defaultPropsNomen}
                  value={artikal}
                  disablePortal={true}
                  onFocus={() => {
                    // ukoliko je korisnik kliknuo na edit, izbaci gresku
                    if (itemUpdate) {
                      setModalErrorOpen(true);
                    }
                    unosIspravanDo("ARTIKAL");
                  }}
                  // ako se promijeni artikal, uzima podatke za novi izabrani artikal
                  onChange={(event, newValue) => {
                    setForm((prev) => ({
                      ...prev,
                      NOMENID: newValue && newValue != null ? newValue?.ID : "",
                      POREZID:
                        newValue && newValue != null ? newValue?.POREZID : "",
                      JM: newValue && newValue != null ? newValue?.JM : "",
                      SIFRA:
                        newValue && newValue != null ? newValue?.SIFRA : "",
                    }));
                    // setujemo artikal
                    setArtikal(newValue);
                    setTimeout(() => {
                      kolicinaRef.current.focus();
                    }, 50);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      maxLength="283"
                      className={itemUpdate ? "form-controlvr" : ""}
                      name="NOMENID"
                      readOnly={itemUpdate}
                      placeholder="artikal"
                      variant="standard"
                    />
                  )}
                />
                {/* prikazivanje greske ukoliko artikal nije izabran */}
                {errorMsgNomen && (
                  <div className="col-12">
                    <span className="error-message">{errorMsgNomen}</span>
                  </div>
                )}
              </div>
            </div>
            <div className="col-6 col-lg-1 ps-1">
              <div
                className={errorMsgKolicina ? "inputWithError text-end" : "text-end"}
              >
                <div>
                  <label className="fs-7 font-weight-bold text-nowrap m-0 px-1">Količina</label>
                  {/* komponenta za formatiranje brojeva */}
                  <NumericFormat
                    type="number"
                    customInput={itemUpdate?.TextField}
                    getInputRef={kolicinaRef}
                    // broj decimala
                    decimalScale={2}
                    tabIndex={13}
                    className="text-dark text-end"
                    placeholder="0"
                    name="KOLICINA"
                    value={form && itemUpdate ? numberWithCommasColumns(form.KOLICINA) : form.KOLICINA}
                    onFocus={() => {
                      unosIspravanDo("KOLICINA");
                      if (itemUpdate) {
                        setModalErrorOpen(true);
                      }
                      if (form.RBR !== 0 && form.SIFRA !== "") {
                        getArticleInfo();
                      }
                    }}
                    onChange={(e) => {
                      handleChangePrometS(e);
                      setForm((prev) => ({
                        ...prev,
                        KOLICINA: e.target.value,
                      }));
                    }}
                  />
                  {/* prikazivanje poruke o pogresnom upisu  */}
                  {errorMsgKolicina && (
                    <div className="col-12">
                      <span className="error-message">{errorMsgKolicina}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-1 ps-1">
              <div
                className={errorMsgFaktcen ? "inputWithError text-end" : "text-end"}
              >
                <div>
                  <label className="fs-7 font-weight-bold text-nowrap m-0 px-1">
                    N. Cijena
                  </label>
                  {/* komponenta za formatiranje brojeva */}
                  <NumericFormat
                    type="number"
                    customInput={itemUpdate?.TextField}
                    getInputRef={faktCenRef}
                    // broj decimala
                    decimalScale={3}
                    tabIndex={14}
                    className="text-dark text-end"
                    placeholder="0"
                    name="MALCEN"
                    //  value={numberWithCommasColumns3(form.MALCEN)}
                    value={form && itemUpdate ? numberWithCommasColumns3(form.MALCEN) : form.MALCEN}
                    onFocus={() => {
                      if (itemUpdate) {
                        setModalErrorOpen(true);
                      }
                      unosIspravanDo("MALCEN");
                      prodajnaCijena = form.MALCEN;
                      // izracunavanje prodajnog iznosa
                      prodajniIznos = prodajnaCijena * form.KOLICINA;
                      // izracunavanje prodajne cijene
                      //  prodajnaCijena = getFakcenMinusRabat(
                      //   form.RABAT,
                      //   form.MALCEN
                      // );
                      setForm((prev) => ({
                        ...prev,
                        PRODAJNACIJENA: prodajnaCijena,
                        IZNMALD: prodajniIznos,
                      }));
                    }}
                    onChange={(e) => {
                      handleChangePrometS(e);
                    }}
                  />
                  {/* prikazivanje poruke o pogresnom upisu  */}
                  {errorMsgFaktcen && (
                    <div className="col-12">
                      <span className="error-message">{errorMsgFaktcen}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="col-6 col-lg-1 ps-1">
              <div className="text-end">
                <div>
                  <label className="fs-7 font-weight-bold text-nowrap m-0 px-1">Rabat %</label>
                  {/* komponenta za formatiranje brojeva */}
                  <NumericFormat
                    type="number"
                    customInput={itemUpdate?.TextField}
                    getInputRef={rabatRef}
                    // broj decimala
                    decimalScale={2}
                    tabIndex={15}
                    className="text-dark text-end"
                    placeholder="0"
                    name="RABAT"
                    value={form && itemUpdate ? numberWithCommasColumns(form.RABAT) : form.RABAT}
                    // value={numberWithCommasColumns(form.RABAT)}
                    onFocus={() => {
                      if (itemUpdate) {
                        setModalErrorOpen(true);
                      }
                      unosIspravanDo("RABAT");

                      // dobijanje fakturne cijene
                      // let fakturnaCijena = form.IZNFAKT / form.KOLICINA;
                      // setForm((prev) => ({
                      //   ...prev,
                      //   FAKTCEN: fakturnaCijena,
                      // }));
                    }}
                    onChange={(e) => {
                      handleChangePrometS(e);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-1 ps-1">
              <div className="text-end">
                <div>
                  <label className="fs-7 font-weight-bold text-nowrap m-0 px-1">Rabat</label>
                  {/* komponenta za formatiranje brojeva */}
                  <NumericFormat
                    type="number"
                    customInput={itemUpdate?.TextField}
                    getInputRef={iznRabatRef}
                    // broj decimala
                    decimalScale={2}
                    tabIndex={16}
                    className="text-dark text-end"
                    placeholder="0"
                    name="IZNRABAT"
                    value={form && itemUpdate ? numberWithCommasColumns(form.IZNRABAT) : form.IZNRABAT}
                    //  value={numberWithCommasColumns(form.IZNRABAT)}
                    onFocus={() => {
                      if (itemUpdate) {
                        setModalErrorOpen(true);
                      }
                      unosIspravanDo("IZNRABAT");
                      if (form.MALCEN && form.MALCEN != "") {
                        // izracunavanje iznosa rabata
                        let iznosRabata = getIznosRabataIzlaz(
                          form.RABAT,
                          form.IZNMALD
                        );

                        form.IZNRABAT = getIznosRabataIzlaz(
                          form.RABAT,
                          form.IZNMALD
                        );
                        setForm(prev => ({ ...prev, IZNRABAT: Number(form.IZNRABAT) }))

                        // uslov: ako iznos rabata ima neku vrijednost i ta vrijednost nije 0, racuna prodajnu cijenu umanjenu za iznos rabata, a i prodajni iznos, takodje. U suprotnom: prodajna cijena i prodajni iznos ostaju na pocetnim vrijednostima
                        if (form.IZNRABAT && form.IZNRABAT !== 0) {
                          form.PRODAJNACIJENA = getProdajnaCijena(form.KOLICINA, form.IZNMALD, form.IZNRABAT)
                          setForm(prev => ({ ...prev, PRODAJNACIJENA: Number(form.PRODAJNACIJENA) }))
                          form.IZNMALDP = getProdajniIznos(form.IZNMALD, form.IZNRABAT)
                          setForm(prev => ({ ...prev, IZNMALDP: Number(form.IZNMALDP) }))
                        } else {
                          setForm(prev => ({ ...prev, PRODAJNACIJENA: Number(form.PRODAJNACIJENA), IZNMALD: Number(form.IZNMALD) }))
                        }

                        // izracunavanje prodajne cijene
                        // let prodajnaCijena = getFakcenMinusRabat(
                        //   form.RABAT,
                        //   form.MALCEN
                        // );

                        // izracunavanje nabavnog iznosa
                        //  let nabavniIznos = form.NABCEN * form.KOLICINA;

                        form.IZNFAKT = getFakturniIznos(form.KOLICINA, form.FAKTCEN)
                        setForm(prev => ({ ...prev, IZNFAKT: Number(form.IZNFAKT) }))

                        const nabavniIznos = getNabavniIznos(form.KOLICINA, form.NABCEN)
                        setForm(prev => ({ ...prev, IZND: Number(nabavniIznos) }))

                        // izracunavanje iznosa poreza
                        let porezUIznosu = getPorezUIznosu(
                          form.PDV,
                          form.KOLICINA,
                          form.MALCEN
                        );
                        // izracunavanje iznosa marze
                        form.IZNRAZD = getIznosMarze(
                          selektovanaVrstaSkladista,
                          form.IZNMALD,
                          iznosTroska,
                          nabavniIznos,
                          porezUIznosu,
                          form.IZNRABAT
                        );

                        setForm(prev => ({ ...prev, IZNRAZD: Number(form.IZNRAZD) }))
                        // izracuvanaje procenta marze
                        form.RAZCEN = getProcenatMarze(
                          form.IZNRAZD,
                          nabavniIznos
                        );

                        setForm(prev => ({ ...prev, RAZCEN: Number(form.RAZCEN) }))
                        // update forme na osnovu dobijenih vrijednosti
                        setForm((prev) => ({
                          ...prev,
                          //  IZNRABAT: iznosRabata,
                          //  PRODAJNACIJENA: prodajnaCijena,
                          //  IZNRAZD: marzaValue,
                          // RAZCEN: marzaPercentage,
                          //  IZNOSPRODAJNECIJENE: prodajniIznos,
                          IZPDV: porezUIznosu,
                        }));
                      }
                    }}
                    onChange={(e) => {
                      handleChangePrometS(e);
                      // dobijanje procenta rabata na osnovu vrijednosti
                      let procenatRabata = getRabvelFromValues(
                        e.target.value,
                        form.IZNMALD
                      );

                      // izracunavanje prodajne cijene
                      let prodajnaCijena = getFakcenMinusRabat(
                        form.RABAT,
                        form.MALCEN
                      );

                      // izracunavanje prodajnog iznosa
                      let prodajniIznos = prodajnaCijena * form.KOLICINA;
                      // izracunavanje nabavnog iznosa
                      let nabavniIznos = form.NABCEN * form.KOLICINA;

                      // izracunavanje iznosa poreza
                      let porezUIznosu = getPorezUIznosu(
                        form.PDV,
                        form.KOLICINA,
                        form.MALCEN
                      );

                      // izracunavanje iznosa marze
                      let marzaValue = getIznosMarze(
                        selektovanaVrstaSkladista,
                        prodajniIznos,
                        iznosTroska,
                        nabavniIznos,
                        porezUIznosu,
                        e.target.value
                      );

                      // izracunavanje procenta marze
                      let marzaPercentage = getProcenatMarze(
                        marzaValue,
                        nabavniIznos + e.target.value
                      );

                      // setovanje u formu
                      setForm((prev) => ({
                        ...prev,
                        RABAT: procenatRabata,
                        PRODAJNACIJENA: prodajnaCijena,
                        IZNRAZD: marzaValue,
                        RAZCEN: marzaPercentage,
                        IZNMALD: prodajniIznos,
                        IZPDV: porezUIznosu,
                      }));
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-1 ps-1">
              <div
                className={errorMsgPrice ? "inputWithError text-end" : "text-end"}>
                <div>
                  <label className="fs-7 font-weight-bold m-0 px-1">
                    Prod. cijena
                  </label>
                  {/* komponenta za formatiranje brojeva */}
                  <NumericFormat
                    type="number"
                    customInput={itemUpdate?.TextField}
                    getInputRef={prodajnaCijenaRef}
                    // broj decimala
                    decimalScale={3}
                    className="text-dark text-end"
                    placeholder="0"
                    tabIndex={17}
                    onFocus={() => {
                      if (itemUpdate) {
                        setModalErrorOpen(true);
                      }
                      unosIspravanDo("PRODAJNACIJENA");
                    }}
                    name="PRODAJNACIJENA"
                    value={form && itemUpdate ? numberWithCommasColumns3(form.PRODAJNACIJENA) : form.PRODAJNACIJENA}
                    //  value={numberWithCommasColumns3(form.PRODAJNACIJENA)}
                    //  value={numberWithCommasColumns3(form.PRODAJNACIJENA)}
                    onChange={(e) => {
                      handleChangePrometS(e);
                    }}
                  />
                  {/* prikazivanje poruke o pogresnom upisu  */}
                  {errorMsgPrice && (
                    <div className="col-12">
                      <span className="error-message">{errorMsgPrice}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-1 ps-1">
              <div className="text-end">
                <div>
                  <label className="fs-7 font-weight-bold text-nowrap m-0 px-1">
                    Prod. iznos
                  </label>
                  {/* komponenta za formatiranje brojeva */}
                  <NumericFormat
                    type="number"
                    customInput={itemUpdate?.TextField}
                    // getInputRef={prodajnaCijenaRef}
                    // broj decimala
                    decimalScale={2}
                    disabled
                    className="text-dark text-end"
                    placeholder="0"
                    name="IZNOSPRODAJNECIJENE"
                    value={form && itemUpdate ? numberWithCommasColumns(form.IZNMALDP) : form.IZNMALDP}
                  //  value={numberWithCommasColumns(form.IZNMALDP)}
                  />
                </div>
              </div>
            </div>
          </div>
        </Row>
        <div className="table-responsive">
          <table className="table table-sm table-striped table-hover table-light ">
            <thead>
              <tr>
                {/* iteracija kroz columns i dodjeljivanje table headera vrijednostima iz columns[i].name */}
                {columns.map((column, index) => {
                  return (
                    <th key={index} scope="col" className={column.class}>
                      {column.name}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {/* iteracija kroz niz kalkulacija i dodjeljivanje klasa vrijednostima iz columns[i].class */}
              {izlazFakturisanje &&
                izlazFakturisanje?.map((s, index) => {
                  return (
                    <tr
                      onDoubleClick={() => {
                        onEdit(s);
                      }}
                      key={index}
                      className="col-12 user-select-none"
                    >
                      <td className={columns[0].class}>{s.RBR}</td>
                      <td className={columns[1].class}>{s.ARTIKAL}</td>
                      <td className={columns[2].class}>{s.NAZIV_ARTIKLA}</td>
                      <td className={columns[3].class}>{numberWithCommasColumns(s.KOL)}</td>
                      <td className={columns[4].class}>
                        {numberWithCommasColumns(s.NOM_CENA)}
                      </td>
                      <td className={columns[5].class}>
                        {numberWithCommasColumns(s.PROC_R)}
                      </td>
                      <td className={columns[6].class}>{s.RABAT}</td>
                      <td className={columns[7].class}>
                        {numberWithCommasColumns3(s.CENA)}
                      </td>
                      <td className={columns[8].class}>
                        {numberWithCommasColumns(roundTO(s.UKUPNO, 2))}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        {/* paginacija */}
        {duzinaNiza > 0 && (
          // wrapper za select i paginaciju
          <div className="d-flex justify-content-center align-items-center gap-1">
            {/* paginacija za bootstrap tabelu */}
            <div className="mb-3">
              <select
                className="form-select"
                value={parseInt(itemsPerPage)}
                onChange={(e) => {
                  setItemsPerPage(parseInt(e.target.value));
                  setCurrentPage(1);
                }}
              >
                <option def="5">5</option>
                <option defaultValue value="10">
                  10
                </option>
              </select>
            </div>
            <nav aria-label="Page navigation">
              <ul className="pagination">
                <li
                  className="page-item"
                  onClick={(event) => {
                    onFirstClick(event, setCurrentPage);
                  }}
                >
                  <a type="button" className="page-link user-select-none">
                    First
                  </a>
                </li>
                <li
                  className="page-item"
                  onClick={(event) => {
                    onPreviosClick(event, currentPage, setCurrentPage);
                  }}
                >
                  <a className="page-link user-select-none" type="button">
                    Previous
                  </a>
                </li>

                {pageNumbers.map((pageNumber) => (
                  <li
                    type="button"
                    key={pageNumber}
                    className={
                      currentPage == pageNumber
                        ? "selected page-item user-select-none"
                        : "page-item user-select-none"
                    }
                  >
                    <a
                      className="page-link user-select-none"
                      id={pageNumber}
                      onClick={(event) =>
                        onPaginationClick(event, setCurrentPage)
                      }
                    >
                      {pageNumber}
                    </a>
                  </li>
                ))}

                <li
                  className="page-item"
                  onClick={(event) => {
                    onNextClick(
                      event,
                      currentPage + 1,
                      setCurrentPage,
                      duzinaNiza
                    );
                  }}
                >
                  <a type="button" className="page-link user-select-none">
                    Next
                  </a>
                </li>
                <li
                  className="page-item"
                  onClick={(event) => {
                    onLastClick(event, setCurrentPage, duzinaNiza);
                  }}
                >
                  <a type="button" className="page-link user-select-none">
                    Last
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        )}
      </Container>
      {/* totals */}
      <div>
        <ul className="nav  flex-column flex-lg-row justify-content-end">
          <div className="d-flex col-9 ms-auto justify-content-end align-items-center fixed-bottom mb-3 me-3 gap-3">
            <div className="user-select-none">
              <label htmlFor="iznos" className="form-label text-nowrap">
                Vrijednost
              </label>
              <input
                type="text"
                value={numberWithCommas(totalNabavnaCijena)}
                placeholder="0"
                className="text-end px-2"
                id="iznos"
                readOnly={true}
              />
            </div>
            <div className="user-select-none">
              <label htmlFor="iznosRabata" className="form-label text-nowrap">
                Rabat
              </label>
              <input
                type="text"
                readOnly={true}
                value={numberWithCommas(totalRabat)}
                placeholder="0"
                className="text-end px-2"
                id="iznosRabata"
              />
            </div>
            {/* <div className="user-select-none">
              <label
                htmlFor="fakturnaMinusRabat"
                className="form-label text-nowrap"
              >
                F-R
              </label>
              <input
                type="text"
                readOnly={true}
                value={numberWithCommas(totalIznos)}
                placeholder="0"
                className="text-end px-2"
                id="fakturnaMinusRabat"
              />
            </div> */}
            <div className="user-select-none">
              <label htmlFor="marza" className="form-label text-nowrap">
                RUC
              </label>
              <input
                type="text"
                readOnly={true}
                value={numberWithCommas(totalIznosMarze)}
                placeholder="0"
                className={
                  totalIznosMarze < 0
                    ? "bg-danger text-end px-2 text-dark"
                    : "text-end px-2"
                }
                id="marza"
              />
            </div>
            <div className="user-select-none">
              <label htmlFor="osnovica" className="form-label text-nowrap">
                Osnovica
              </label>
              <input
                type="text"
                readOnly={true}
                value={numberWithCommas(totalIznosNabavneCijene)}
                placeholder="0"
                className="text-end px-2"
                id="osnovica"
              />
            </div>
            <div className="user-select-none">
              <label htmlFor="porez" className="form-label text-nowrap">
                Porez
              </label>
              <input
                type="text"
                readOnly={true}
                value={numberWithCommas(totalIznosPDV)}
                placeholder="0"
                className="text-end px-2"
                id="porez"
              />
            </div>
            <div className="user-select-none">
              <label htmlFor="zaPlacanje" className="form-label text-nowrap">
                Za plaćanje
              </label>
              <input
                type="text"
                readOnly={true}
                value={numberWithCommas(totalIznos)}
                placeholder="0"
                className="text-end px-2"
                id="zaPlacanje"
              />
            </div>
          </div>
        </ul>
      </div>

      {/* render za poruku */}
      <div>
        <Modal isOpen={Boolean(modalErrorDeleteItemOpen)} centered>
          <ModalHeader>Neispravan unos!</ModalHeader>
          <ModalBody>
            <span>{`Morate odabrati stavku za brisanje!`}</span>
          </ModalBody>
          <ModalFooter style={{ justifyContent: "center" }}>
            <div>
              <button
                onClick={() => setModalErrorDeleteItemOpen(false)}
                className="button-yes"
              >
                Ok
              </button>
            </div>
          </ModalFooter>
        </Modal>
      </div>

      {/* render za poruku */}
      <div>
        <Modal isOpen={Boolean(modalErrorDeleteDocumentOpen)} centered>
          <ModalHeader>Neispravan unos!</ModalHeader>
          <ModalBody>
            <span>{`Morate odabrati dokument za brisanje!`}</span>
          </ModalBody>
          <ModalFooter style={{ justifyContent: "center" }}>
            <div>
              <button
                onClick={() => setModalErrorDeleteDocumentOpen(false)}
                className="button-yes"
              >
                Ok
              </button>
            </div>
          </ModalFooter>
        </Modal>
      </div>

      {/* render za poruku */}
      <div>
        <Modal
          isOpen={Boolean(modalOpen)}
          //  modalTransition={{ timeout: 2000 }}
          centered
        >
          <ModalHeader>Brisanje dokumenta</ModalHeader>
          <ModalBody>
            <span>{`Da li želite da obrišete dokument broj ${prometForm.DOK} ? `}</span>
          </ModalBody>
          <ModalFooter>
            <div>
              <button onClick={() => setModalOpen(false)} className="button-no">
                Ne
              </button>
              <button
                onClick={() => onDelete(prometForm.SKLAID, prometForm.VRPROM, prometForm.DOK)}
                className="button-yes"
              >
                Da
              </button>
            </div>
          </ModalFooter>
        </Modal>
      </div>

      <Modal isOpen={Boolean(modalDeleteItem)} animation="false" centered>
        <ModalHeader>Brisanje stavke</ModalHeader>
        <ModalBody>
          <span>Da li želite da obrišete stavku broj '{form.RBR}' ?</span>
        </ModalBody>
        <ModalFooter>
          <div>
            <button
              onClick={() => setModalDeleteItem(false)}
              className="button-no"
            >
              Ne
            </button>
            <button
              onClick={() => onDeleteItems(prometForm.PROMETID, form.RBR)}
              className="button-yes"
            >
              Da
            </button>
          </div>
        </ModalFooter>
      </Modal>

      {/* render za poruku greska na editu  */}
      <Modal isOpen={modalErrorOpen} animation="false" centered>
        <ModalHeader>Neispravan unos</ModalHeader>
        <ModalBody>
          <span>
            Ne možete mijenjati slog '{form.RBR}' koji već postoji u dokumentu!
          </span>
        </ModalBody>
        <ModalFooter>
          <div>
            <button
              onClick={() => {
                rbrRef.current.focus();
                setModalErrorOpen(false);
              }}
              className="button-no"
            >
              Ok
            </button>
          </div>
        </ModalFooter>
      </Modal>

      {/* render za poruku greska na submitu, iznos lagera bi u minusu  */}
      <Modal isOpen={modalLagerErrorOpen} animation="false" centered>
        <ModalHeader>Neispravan unos</ModalHeader>
        <ModalBody>
          <span>Nemate dovoljnu kolićinu na lageru!</span>
        </ModalBody>
        <ModalFooter>
          <div>
            <button
              onClick={() => {
                setModalLagerErrorOpen(false);
                setTimeout(() => {
                  rbrRef.current.focus();
                }, 150);
              }}
              className="button-no"
            >
              Ok
            </button>
          </div>
        </ModalFooter>
      </Modal>

    </div>
  );
};

export default IzlazFakturisanje;
