// importovanje komponenti 
import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import DataTable from 'react-data-table-component';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { API } from '../../constants';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import SVG from 'react-inlinesvg';
import axios from '../../api/axios';
import deleteNalog from '../../assets/delete_document.svg';
import moment from 'moment';
import { Spinner } from "reactstrap";
import { NumericFormat } from 'react-number-format';
import { numberWithCommasColumns, numberWithCommas, roundTO, getCompanyData, onPaginationClick, onPreviosClick, onNextClick, onFirstClick, onLastClick, defaultComponentOptions } from '../utilities';
import { useWindowSize } from '../useWindowSize';
import { useNavigate } from 'react-router-dom';
//import FinReport from '../components/finansije/finReport';

// komponenta koju kreiramo
function UnosNaloga() {
    const navigate = useNavigate();
    const windowSize = useWindowSize();
    // definisanje konstanti koje koristimo za fokusiranje polja
    //    const yearRef = useRef();
    const nalogRef = useRef()
    const dateNRef = useRef()
    const dateDRef = useRef()
    const clientsRef = useRef()
    const kontoRef = useRef()
    const descriptionRef = useRef()
    const dugujeRef = useRef()
    const potrazRef = useRef()
    const vrstaRef = useRef()
    const redRef = useRef()


    let date = new Date();
    let year = new Date().getFullYear()
    // definisanje stejtova i prva inicijalizacija; objekat i funkcija za taj objekat
    // const { FinReport } = FinReport();
    const [finNalog, setFinNalog] = useState([])
    const [clients, setClients] = useState(null);
    const [konto, setKonto] = useState(null);
    const [vrsta, setVrsta] = useState(null);
    const [selectDataClients, setSelectDataClients] = useState([]);
    const [selectDataKonto, setSelectDataKonto] = useState([]);
    const [selectDataVrsta, setSelectDataVrsta] = useState([]);
    const [firstNalogNumber, setFirstNalogNumber] = useState(null);
    const [firstRedNumber, setFirstRedNumber] = useState(null);
    const [searchInput, setSearchInput] = useState("");
    // error row inputs
    const [errorInputNalog, setErrorInputNalog] = useState(false);
    const [errorMsgNalog, setErrorMsgNalog] = useState(false);
    const [errorInputClients, setErrorInputClients] = useState(false);
    const [errorMsgClients, setErrorMsgClients] = useState(false);
    const [errorInputKonto, setErrorInputKonto] = useState(false);
    const [errorMsgKonto, setErrorMsgKonto] = useState(false);
    const [errorDateN, setErrorDateN] = useState(false);
    const [errorDateD, setErrorDateD] = useState(false);
    const [errorInputRed, setErrorInputRed] = useState(false);
    const [errorMsgRed, setErrorMsgRed] = useState(false);
    const [errorInputVrsta, setErrorInputVrsta] = useState(false);
    const [errorMsgVrsta, setErrorMsgVrsta] = useState(false);
    const [errorMsgDateN, setErrorMsgDateN] = useState(false);
    const [errorMsgDateD, setErrorMsgDateD] = useState(false);
    const [sumDuguje, setSumDuguje] = useState(false);
    const [sumPotraz, setSumPotraz] = useState(false);
    const [sumTotal, setSumTotal] = useState(false);
    const [pending, setPending] = React.useState(false);

    // boostrap tabela useState-ovi
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [duzinaNiza, setDuzinaNiza] = useState(1);
    const pageNumbers = [];

    // paginacija
    const [showPagination, setShowPagination] = useState(true);

    const [modalOpen, setModalOpen] = useState(false);
    const [modalDeleteItem, setModalDeleteItem] = useState(false);

    const [modalErrorDeleteNalogOpen, setModalErrorDeleteNalogOpen] = useState(false);

    const [modalErrorPrint, setModalErrorPrint] = useState(false);
    const [modalErrorCheckDP, setModalErrorCheckDP] = useState(false);

    const [modalErrorDeleteItemOpen, setModalErrorDeleteItemOpen] = useState(false);
    const [modalErrorAuthorizationData, setModalErrorAuthorizationOpen] = useState(false);

    const [spinner, setSpinner] = useState(false);

    // povlacenje podataka iz lokal storidza
    const token = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : null;

    // definisanje stejta(objekat i funkcija za taj objekat), prva inicijalizacija
    const [form, setForm] = useState({
        SIFRA: '',
        NALOG: '',
        RED: '',
        DATN: '',
        DATD: '',
        KOMTID: '',
        KONTID: '',
        OPIS: '',
        VRSTA: '',
        DUGUJE: '',
        POTRAZ: '',
        stavkaPronadjena: false,
        OZNAKA: ''
    })
    // funkcija za povlacenje podataka o preduzecu
    getCompanyData(form);

    const onSubmit = () => {
        // uslov: ako nisu unesena obavezna polja, zaustavlja upis
        if (!unosIspravanDo('UPIS')) return;

        // if (konto.OZNAKA === 'D' && !clients) {
        //     setModalErrorOpen(true)
        //  } else {
        //   if (form.DUGUJE !== "" || form.POTRAZ !== "") {
        // if (form) {
        //     form.DATN = startDate
        //     form.DATD = documentDate
        // }

        form.SIFKOR = form.userCode

        fetch(API + `/unosNaloga/new/${form.companyCode}`, {

            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                response.json()
                setForm(prev => ({ ...prev, VRSTA: '', KONTID: '', OPIS: '', KOMTID: '', DUGUJE: '', POTRAZ: '', OZNAKA: '', stavkaPronadjena: false }))
                setClients(null)
                setKonto(null)
                //   getTotal()
            }).then(() => {
                getFinNalogData()
            })

        // zaustavljanje fokusa(pauza za fokus dok ne odradi upis) 
        const timeout = setTimeout(() => {
            redRef.current.focus()
        }, 100);
        return () => {
            clearTimeout(timeout);
        };

    }

    useEffect(() => {
        getVrstaData()
        getClientsData()
        getKontoData()
    }, [])

    // prilikom pocetne inicijalizacije, cita mrezu
    useEffect(() => {
        // zaustavljanje fokusa
        const timeout = setTimeout(() => {
            getFinNalogFirstFreeNumber()
            nalogRef.current.focus();
        }, 100);
        return () => {
            clearTimeout(timeout);
        };
    }, []);


    // prati promjene state form i vrijednosti u polju dokument i u zavisnosti od uslova mjenja vrijednosti u samom state form
    // useEffect(() => {
    //     if (form && form.NALOG && form.NALOG != firstNalogNumber) {
    //         setForm(prev => ({
    //             ...prev, DATN: '', DATN: '', RED: 1, VRSTA: '',
    //             DATD: '', KOMTID: '', OPIS: '', KONTID: '', DUGUJE: '', POTRAZ: '',
    //         }))
    //         setFinNalog(null)
    //         setVrsta(null)
    //         setClients(null)
    //         setKonto(null)
    //         checkNalog()
    //     } else {
    //         setFinNalog(null)
    //     }

    // }, [form.NALOG])

    // funkcija za pronalazenje komitenta i odabir postojeceg
    const getClientsData = () => {
        fetch(API + `/komitenti/all/${form.companyCode}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataClients(data)
            })

    }

    // funkcija za pronalazenje konta i odabir postojeceg
    const getKontoData = () => {
        fetch(API + `/konto/all`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                //   setForm(prev => ({ ...prev, OZNAKA: data[0].OZNAKA }))
                setSelectDataKonto(data)
            })

    }

    // funkcija za pronalazenje vrste dokumenta i odabir postojeceg
    const getVrstaData = () => {
        fetch(API + `/dokument/all`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataVrsta(data)
            })
    }

    // funkcija za pronalazenje prvog slobodnog broja
    // poziva api
    // u responsu stejt setForm poziva funkciju za pronalazenje prethodnog naloga(iz data) i na osnovu nje odredjuje koja je sljedeca prva slobodna sifra
    const getFinNalogFirstFreeNumber = () => {
        fetch(API + `/unosNaloga/firstFreeNumber/${form.companyCode}`, {

            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setForm(prev => ({ ...prev, NALOG: Number(data[0].NALOG) }))
                setFirstNalogNumber(data[0].NALOG)
            })

    }

    // funkcija za pronalazenje prvog rednog broja u okviru naloga
    const getFinNalogFirstFreeNumberRed = () => {
        fetch(API + `/unosNaloga/firstFreeNumberRed/${form.companyCode}`, {
            method: 'POST',
            body: JSON.stringify({ sifra: form.SIFRA, nalog: form.NALOG }),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setForm(prev => ({ ...prev, RED: Number(data[0].RED) }))
                setFirstRedNumber(data[0].RED)
            })
    }

    // funkcija koja poziva citanje po nalogu
    const checkNalog = () => {
        fetch(API + `/unosNaloga/check/nalogData/${form.companyCode}`, {

            method: 'POST',
            body: JSON.stringify({ sifra: form.SIFRA, nalog: form.NALOG }),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                if (data && data.length) {
                    setForm(prev => ({ ...prev, NALOG: data[0].NALOG, DATN: moment.unix(new Date(data[0].DATN).getTime() / 1000).format("DD.MM.YYYY") }))
                    // selectDataClients.map((item) => {
                    //     if (item.ID == data[0].KOMTID) {
                    //         setClients(item)
                    //     }
                    // })
                    //    setStartDate(new Date(data[0].DATN))
                    //    setDocumentDate(new Date(data[0].DATD))
                    getFinNalogData()
                    //    setUpdateDocument(true)
                    //   getTotal()
                } else {
                    form.stavkaPronadjena = false
                    setSumDuguje(false)
                    setSumPotraz(false)
                    setSumTotal(false)
                }
            })

    }

    // api za mrezu
    // u responsu puni mrezu
    const getFinNalogData = () => {
        setPending(true)
        //  fetch(API + `/finNalog/nalog/${form.companyCode}?page=${currentPage}&limit=${itemsPerPage}`, {
        fetch(`${API}/unosNaloga/nalog/${form.companyCode}`, {
            method: 'POST',
            body: JSON.stringify({ sifra: form.SIFRA, nalog: form.NALOG }),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setFinNalog(data.result)
                //   setDuzinaNiza(Math.ceil(data.total / itemsPerPage));
                let DUGUJESum = 0;
                let POTRAZSum = 0;
                let SALDOSum = 0;
                // petlja za citanje podataka
                for (let i = 0; i < data?.result?.length; i++) {
                    DUGUJESum += data.result[i].DUGUJE
                    POTRAZSum += data.result[i].POTRAZ
                    SALDOSum += (data.result[i].DUGUJE) - (data.result[i].POTRAZ)
                }
                setSumTotal(numberWithCommasColumns(roundTO(SALDOSum, 2)))
                setSumDuguje(numberWithCommasColumns(roundTO(DUGUJESum, 2)))
                setSumPotraz(numberWithCommasColumns(roundTO(POTRAZSum, 2)))
                setPending(false)
            })

    }

    // na svaku promjenu u paginaciji (broj stranice, broj prikaza elemenata po strinici) zove funkciju getFinNalogData(selektovani broj stranice, selektovani broj elemenata po stranici) ili setuje setcurrentpage(1) (default) , koja izaziva ponovno zvanje useeffect-a
    // useEffect(() => {
    //     getFinNalogData();
    // }, []);

    // const getTotal = () => {
    //     fetch(API + `/finNalog/total/${form.companyCode}`, {
    //         method: 'POST',
    //         body: JSON.stringify({ sifra: form.SIFRA, nalog: form.NALOG }),
    //         headers: {
    //             'Authorization': `Bearer ${token}`,
    //             'Content-type': 'application/json; charset=UTF-8'
    //         },
    //     }).then((response) => response.json())
    //         .then(data => {
    //             if (data && data.length) {
    //                 setSumDuguje(data[0].DUGUJE.toFixed(3))
    //                 setSumPotraz(data[0].POTRAZ.toFixed(3))
    //                 setSumTotal(data[0].UKUPNO.toFixed(3))
    //             }
    //         })

    // }

    // validacija za brisanje naloga 
    const onDelete = (companyCode, nalog) => {
        // provjera ovlascenja za brisanje 
        if (form.ovlfik == 4) return setModalErrorAuthorizationOpen(true), setModalOpen(false)

        fetch(API + `/unosNaloga/delete/${companyCode}/${nalog}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                if (response.status == 200) {
                    setModalOpen(false)
                    setFinNalog(null)
                    setClients(null)
                    setKonto(null)
                    setVrsta(null)
                    checkNalog()
                    setForm(prev => ({
                        ...prev, DATN: '', RED: '', VRSTA: '', DATD: '', KOMTID: '', OPIS: '', KONTID: '', DUGUJE: '', POTRAZ: '', stavkaPronadjena: false
                    }))
                }
            })

        //  zaustavljanje fokusa(pauza za fokus dok ne odradi brisanje) 
        const timeout = setTimeout(() => {
            nalogRef.current.focus()
        }, 400);
        return () => {
            clearTimeout(timeout);
        };

    }
    // validacija za brisanje reda u okviru naloga 
    const onDeleteItems = (companyCode, nalog, red) => {
        // provjera ovlascenja za brisanje 
        if (form.ovlfik == 4) return setModalErrorAuthorizationOpen(true), setModalDeleteItem(false)

        fetch(API + `/unosNaloga/item/delete/${companyCode}/${nalog}/${red}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                if (response.status == 200) {
                    setModalDeleteItem(false)
                    //   setItemUpdate(false)
                    setFinNalog(null)
                    setClients(null)
                    setKonto(null)
                    setVrsta(null)
                    checkNalog()
                    setForm(prev => ({
                        ...prev, DATD: '', OPIS: '', DUGUJE: '', POTRAZ: '', stavkaPronadjena: false
                    }))
                }
            })

        // zaustavljanje fokusa(pauza za fokus dok ne odradi brisanje) 
        const timeout = setTimeout(() => {
            redRef.current.focus()
        }, 400);
        return () => {
            clearTimeout(timeout);
        };
    }

    // funkcija koja se poziva klikom na dugme Edit
    // postavlja fokus na polje VRSTA
    // popunjava polja podacima iz mreze
    const onEdit = (item) => {
        //  setItemUpdate(true)
        form.stavkaPronadjena = true
        //  setVrsta(null)
        //   setKonto(null)
        //   setClients(null)

        setForm((prevState) => ({
            ...prevState, SIFRA: item.SIFRA, NALOG: item.NALOG, RED: item.RED, VRSTA: item.VRSTA, DATD: moment.unix(new Date(item.DATD).getTime() / 1000).format("DD.MM.YYYY"), KOMTID: item.KOMTID, OPIS: item.OPIS,
            KONTID: item.KONTID, DUGUJE: item.DUGUJE, POTRAZ: item.POTRAZ
        }));

        // funkcija koja se poziva Vrstu dokumenta
        selectDataVrsta.map((vrsta) => {
            if (vrsta.VRSTAD == item.VRSTA) {
                setVrsta(vrsta)
            }
        })
        // funkcija koja se poziva Konto
        selectDataKonto.map((konto) => {
            if (konto.ID == item.KONTID) {
                setKonto(konto)
            }
        })

        // funkcija koja se poziva Komitenta
        selectDataClients.map((clients) => {
            if (clients.ID == item.KOMTID) {
                setClients(clients)
            }
        })
    }

    // funkcija koja hvata promjene na poljima i  setuje vrijednost u form state
    const handleChange = (event) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name;

        setForm((prevState) => ({
            ...prevState,
            [name]: value
        }));

    };

    // propsovi za auto komplit  - vrsta dokumenta komitent, konto
    const defaultPropsVrsta = {
        options: selectDataVrsta,
        getOptionLabel: (option) => option ? `${option.VRSTAD} ${option.OPISD}` : '',
    };

    const defaultPropsClients = {
        options: selectDataClients,
        getOptionLabel: (option) => option ? `${option.SIFRA} ${option.NAZIV}` : '',
    };

    const defaultPropsKonto = {
        options: selectDataKonto,
        getOptionLabel: (option) => option ? `${option.KONTO} ${option.NAZ}` : '',
    };

    // funkcije za provjeru obaveznih polja - 3 vrsta, komitent, konto

    const checkVrstaRequired = (value) => {
        if (value && !vrsta || !value && !vrsta) {
            setErrorMsgVrsta('Vrsta dokumenta mora biti izabrana!')
            setErrorInputVrsta(true)
            vrstaRef.current.focus();
            return false

        } else {
            setErrorMsgVrsta(null)
            setErrorInputVrsta(false)
            return true
        }
    }

    const checkClientsRequired = (value) => {
        if (value && !clients || !value && !clients) {
            setErrorMsgClients('Komitent mora biti izabran!')
            setErrorInputClients(true)
            clientsRef.current.focus();
            return false
        } else {
            setErrorMsgClients(null)
            setErrorInputClients(false)
            return true
        }
    }

    const checkKontoRequired = (value) => {
        if (value && !konto || !value && !konto) {
            setErrorMsgKonto('Konto mora biti izabrano!')
            setErrorInputKonto(true)
            kontoRef.current.focus();
            return false
        } else {
            setErrorMsgKonto(null)
            setErrorInputKonto(false)
            return true
        }
    }

    //povjera naloga
    const checkNalogRequired = (value) => {
        if (!value || value == 0) {
            setErrorMsgNalog('Nalog je obavezan!')
            setErrorInputNalog(true)
            nalogRef.current.focus();
            return false
        } else {
            setErrorMsgNalog(null)
            setErrorInputNalog(false)
            return true
        }
    }

    // uslov: ako nije upisana neka vrijednost ili je vrijednost 0, izbaci poruku, setuje crveni okvir i fokusira polje
    const checkRBRRequired = (value) => {
        if (!value || value == 0) {
            setErrorMsgRed('Red je obavezan!')
            setErrorInputRed(true)
            redRef.current.focus();
            return false
        } else {
            setErrorMsgRed(null)
            setErrorInputRed(false)
            return true
        }
    }

    // uslov: ako nije upisana neka vrijednost ili je vrijednost 0, izbaci poruku, setuje crveni okvir i fokusira polje
    const checkDugujePotrazRequired = (value) => {
        if (!value || value == 0) {
            setModalErrorCheckDP(true);
            dugujeRef.current.focus();
            return false
        } else {
            setModalErrorCheckDP(false);
            return true
        }
    }

    // stepenasta provjera
    // const formCheck = (event) => {

    //     const target = event.target;
    //     let value = target.value;
    //     const name = event.target.name;

    //     switch (name) {
    //         case 'NALOG': {
    //             checkNalogRequired(form.NALOG)
    //             break;
    //         }
    //         case 'DATN': {

    //             break
    //         }
    //         case 'RED': {
    //             if (!value || value == 0) {
    //                 setErrorMsgRed('Red je obavezan')
    //                 setErrorInputRed(true)
    //                 redRef.current.focus();
    //                 // setDocumentFocus(true)
    //             } else {
    //                 setErrorMsgRed(null)
    //                 setErrorInputRed(false)
    //             }
    //             break
    //         }

    //         case 'VRSTA': {
    //             checkVrstaRequired(value)
    //             break
    //         }
    //         case 'DATD': {
    //             checkVrstaRequired(form.VRSTA)


    //             break
    //         }
    //         case 'KONTO': {
    //             checkVrstaRequired(form.VRSTA)
    //             // if (form.VRSTA == '') {
    //             //     setErrorMsgVrsta(helperValidateOnlyNumbers(value))
    //             //     setErrorInputVrsta(helperValidateOnlyNumbers(value) ? true : false)
    //             //     setErrorMsgVrsta('Vrsta mora se unijeti!')
    //             //     vrstaRef.current.focus();
    //             //     setVrstaFocus(true)

    //             // } else {
    //             //     setErrorMsgVrsta(null)
    //             //     setErrorInputVrsta(false)
    //             //     //                   setForm(prev => ({ ...prev, VRED: (Number(form.KOL) * Number(form.CENA)) }))
    //             // }
    //             // console.log(value);
    //             break
    //         }

    //         case 'OPIS': {
    //             checkVrstaRequired(form.VRSTA)
    //             checkKontoRequired(value)
    //             break
    //         }

    //         case 'KOMT': {
    //             checkVrstaRequired(form.VRSTA)
    //             checkKontoRequired(value)
    //             checkClientsRequired(value)
    //             break
    //         }

    //         // case 'DUGUJE': {
    //         //     checkVrstaRequired(form.VRSTA)
    //         //     checkKontoRequired(form.KONTO)
    //         //     checkClientsRequired(form.KOMTID)
    //         //     break
    //         // }

    //         // case 'POTRAZ': {
    //         //     checkVrstaRequired(form.VRSTA)
    //         //     checkKontoRequired(form.KONTO)
    //         //     checkClientsRequired(form.KOMTID)
    //         //     if (form.DUGUJE == 0 || form.POTRAZ == 0) {
    //         //         setErrorMsgPotraz(helperValidateOnlyNumbers(value))
    //         //         setErrorInputPotraz(helperValidateOnlyNumbers(value) ? true : false)
    //         //         setErrorMsgPotraz('Duguje ili potrazuje mora se unijeti!')
    //         //         potrazRef.current.focus();
    //         //         setPotrazFocus(true)

    //         //     } else {
    //         //         setErrorMsgPotraz(null)
    //         //         setErrorInputPotraz(false)
    //         //         //                   setForm(prev => ({ ...prev, VRED: (Number(form.KOL) * Number(form.CENA)) }))
    //         //     }

    //         //     break
    //         // }
    //     }


    //     if (name === 'NALOG') {
    //         // if (errorInputRepository) {
    //         //     repositoryRef.current.focus()
    //         // } else {
    //         //     setDocumentFocus(false)
    //         //     // dateRef.current.focus()
    //         // }

    //     }

    //     if (name === 'DATN') {
    //         if (errorInputNalog) {
    //             //   console.log(errorInputNalog, 'focus');
    //             setNalogFocus(false)
    //             nalogRef.current.focus()
    //         }
    //     }

    //     if (name === 'VRSTA') {
    //         if (errorInputNalog) {
    //             setNalogFocus(false)
    //             nalogRef.current.focus()
    //         }
    //     }
    //     if (name === 'DATD') {
    //         if (errorInputVrsta) {
    //             //   console.log(errorInputVrsta, 'focus');
    //             setVrstaFocus(false)
    //             vrstaRef.current.focus()
    //         }
    //     }

    //     if (name === 'KONTO') {
    //         if (errorInputVrsta) {
    //             //   console.log(errorInputVrsta, 'focus');
    //             setVrstaFocus(false)
    //             vrstaRef.current.focus()
    //         }
    //     }

    //     if (name === 'OPIS') {
    //         setDescriptionFocus(false)
    //         if (errorInputVrsta) {
    //             //   console.log(errorInputVrsta, 'focus');
    //             setVrstaFocus(false)
    //             vrstaRef.current.focus()
    //         }
    //         if (errorInputKonto) {
    //             setKontoFocus(false)
    //             kontoRef.current.focus()
    //         }

    //     }
    //     if (name === 'KOMT') {
    //         if (errorInputVrsta) {
    //             //    console.log(errorInputVrsta, 'focus');
    //             setVrstaFocus(false)
    //             vrstaRef.current.focus()
    //         }
    //         if (errorInputKonto) {
    //             setKontoFocus(false)
    //             kontoRef.current.focus()
    //         }

    //     }

    // if (name === 'DUGUJE') {
    //     if (errorInputVrsta) {
    //         console.log(errorInputVrsta, 'focus');
    //         setVrstaFocus(false)
    //         vrstaRef.current.focus()
    //     }
    //     if (errorInputKonto) {
    //         setKontoFocus(false)
    //         kontoRef.current.focus()
    //     }

    //     if (!form.KOMT) {
    //         komtRef.current.focus();
    //         setDescriptionFocus(false)
    //     } else {
    //         dugujeRef.current.focus()
    //     }
    // }
    // if (name === 'POTRAZ') {
    //     // if (form.DUGUJE === 0 && form.POTRAZ === 0) {
    //     //     dugujeRef.current.focus();
    //     //     setDugujeFocus(false)
    //     // }
    //     if (errorInputVrsta) {
    //         console.log(errorInputVrsta, 'focus');
    //         setVrstaFocus(false)
    //         vrstaRef.current.focus()
    //     }
    //     if (errorInputKonto) {
    //         setKontoFocus(false)
    //         kontoRef.current.focus()
    //     }

    //     if (!form.KOMT) {
    //         komtRef.current.focus();
    //         setDescriptionFocus(false)
    //     } else {
    //         potrazRef.current.focus()
    //     }

    // }
    // }

    // funkcija za kontrolu obaveznih polja
    function unosIspravanDo(name) {
        if (name === 'NALOG') {
            return true
        }

        if (!checkNalogRequired(form.NALOG)) {
            return false
        }

        if (name === 'DATN') {
            return true
        }

        let reqex = /^(0[1-9]|[12]\d|3[01]).(0[1-9]|1[0-2]).([12]\d{3})$/gi;

        if (!reqex.test(form.DATN)) {
            setErrorDateN(true)
            setErrorMsgDateN('Datum naloga nije validan!')
            dateNRef.current.focus()
            return false
        } else {
            setErrorDateN(false)
            setErrorMsgDateN(null)
        }

        if (name === 'RED') {
            return true
        }

        if (!checkRBRRequired(form.RED)) {
            return false
        }

        if (name === 'VRSTA') {
            return true
        }

        if (!checkVrstaRequired(form.VRSTA)) {
            return false
        }

        if (name === 'DATD') {
            return true
        }

        let reqexDATD = /^(0[1-9]|[12]\d|3[01]).(0[1-9]|1[0-2]).([12]\d{3})$/gi;
        if (!reqexDATD.test(form.DATD)) {
            setErrorDateD(true)
            setErrorMsgDateD('Datum dokumenta nije validan!')
            dateDRef.current.focus()
            return false
        } else {
            setErrorDateD(false)
            setErrorMsgDateD(null)
        }
      //  console.log(form.OZNAKA, 'OZNAKA')

        if (name === 'KOMTID') {
            return true
        }

        if (name === 'OPIS') {
            if (form.OZNAKA == 'D') {
                if (!checkClientsRequired(form.KOMTID)) {
                    return false
                }
            }
            return true
        }

        if (name === 'KONTID') {
            return true
        }

        if (!checkKontoRequired(form.KONTID)) {
            return false
        }

        if (name === 'DUGUJE') {
            if (form.OZNAKA == 'D') {
                if (!checkClientsRequired(form.KOMTID)) {
                    return false
                }
            }
            return true
        }

        if (name === 'POTRAZ') {
            return true
        }

        if (form.DUGUJE === '' && form.POTRAZ === '') {
            setModalErrorCheckDP(true);
            dugujeRef.current.focus();
            return false
        }

        // uslov: ako je sve u redu, prolazi na upis
        return true
    }

    // funkcija koja se poziva klikom na dugme 'Stampa'
    const onPrint = () => {
        // navigacija ka štampi
        navigate('/izvjestajiPoBrojuNaloga', { state: { nalog: form.NALOG } });
    }

    // definisu se polja u tabeli i popunjavaju
    // const columns = [
    //     {
    //         name: "Rbr",
    //         class: "w-5 fs-6 text-end  ",
    //     },
    //     {
    //         name: "Vrsta",
    //         class: "col-2 fs-6 ",
    //     },
    //     {
    //         name: "Dat. dokumenta",
    //         class: "col-1 fs-6 ",
    //     },
    //     {
    //         name: "Komitent",
    //         class: "col-2 fs-6 ",
    //     },
    //     {
    //         name: "Opis",
    //         class: "col-2 fs-6",
    //     },
    //     {
    //         name: "Konto",
    //         class: "col-2 fs-6",
    //     },

    //     {
    //         name: "Duguje",
    //         class: "col-1 fs-6 text-end",
    //     },
    //     {
    //         name: "Potražuje",
    //         class: "col-1 fs-6 text-end",
    //     },
    // ];


    // definisu se polja u tabeli i popunjavaju
    const columns = [
        {
            name: 'Red',
            selector: row => row.RED,
            width:
                windowSize.width > 3072 ? '100px'
                    : windowSize.width > 2048 ? '100px'
                        : windowSize.width > 1920 ? '100px'
                            : windowSize.width > 1680 ? '70px'
                                : windowSize.width > 1600 ? '90px'
                                    : windowSize.width > 1440 ? '60px'
                                        : windowSize.width > 1280 ? '60px'
                                            : windowSize.width > 1024 ? '60px'
                                                : '60px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.RED}</span>
                </>
            ),
        },
        {
            name: 'Vrsta',
            selector: row => row.VRSTA,
            width:
                windowSize.width > 3072 ? '300px'
                    : windowSize.width > 2048 ? '300px'
                        : windowSize.width > 1920 ? '350px'
                            : windowSize.width > 1680 ? '200px'
                                : windowSize.width > 1600 ? '190px'
                                    : windowSize.width > 1440 ? '200px'
                                        : windowSize.width > 1280 ? '150px'
                                            : windowSize.width > 1024 ? '130px'
                                                : '300px',
            center: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.VRSTA}</span>
                </>
            ),
        },
        {
            name: 'Dat. dok',
            selector: row => moment(row.DATD).format('DD.MM.YYYY'),
            // sirina polja po rezolucijama
            width:
                windowSize.width > 3072 ? '200px'
                    : windowSize.width > 2048 ? '150px'
                        : windowSize.width > 1920 ? '100px'
                            : windowSize.width > 1680 ? '100px'
                                : windowSize.width > 1600 ? '100px'
                                    : windowSize.width > 1440 ? '100px'
                                        : windowSize.width > 1280 ? '100px'
                                            : windowSize.width > 1024 ? '100px'
                                                : '80px',
            //right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows-text'>{moment(row.DATD).format('DD.MM.YYYY')}</span>
                </>
            ),
        },
        {
            name: 'Šifra komit.',
            selector: row => row.KOMTSIFRA,
            width:
                windowSize.width > 3072 ? '200px'
                    : windowSize.width > 2048 ? '200px'
                        : windowSize.width > 1920 ? '100px'
                            : windowSize.width > 1680 ? '100px'
                                : windowSize.width > 1600 ? '80px'
                                    : windowSize.width > 1440 ? '90px'
                                        : windowSize.width > 1280 ? '80px'
                                            : windowSize.width > 1024 ? '60px'
                                                : '60px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.KOMTSIFRA}</span>
                </>
            ),
        },
        {
            name: 'Naziv komitenta',
            selector: row => row.KOMTNAZIV,
            width:
                windowSize.width > 3072 ? '420px'
                    : windowSize.width > 2048 ? '420px'
                        : windowSize.width > 1920 ? '340px'
                            : windowSize.width > 1680 ? '270px'
                                : windowSize.width > 1600 ? '230px'
                                    : windowSize.width > 1440 ? '230px'
                                        : windowSize.width > 1280 ? '150px'
                                            : windowSize.width > 1024 ? '160px'
                                                : '230px',
            left: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows-text'>{row.KOMTNAZIV}</span>
                </>
            ),
        },
        {
            name: 'Opis',
            selector: row => row.OPIS,
            width:
                windowSize.width > 3072 ? '310px'
                    : windowSize.width > 2048 ? '310px'
                        : windowSize.width > 1920 ? '340px'
                            : windowSize.width > 1680 ? '250px'
                                : windowSize.width > 1600 ? '240px'
                                    : windowSize.width > 1440 ? '200px'
                                        : windowSize.width > 1280 ? '150px'
                                            : windowSize.width > 1024 ? '180px'
                                                : '300px',
            cell: (row) => (
                <>
                    <span id='media-query-rows-text'>{row.OPIS}</span>
                </>
            ),
        },
        {
            name: 'Konto',
            selector: row => row.KONTO,
            width:
                windowSize.width > 3072 ? '100px'
                    : windowSize.width > 2048 ? '100px'
                        : windowSize.width > 1920 ? '100px'
                            : windowSize.width > 1680 ? '70px'
                                : windowSize.width > 1600 ? '70px'
                                    : windowSize.width > 1440 ? '70px'
                                        : windowSize.width > 1280 ? '80px'
                                            : windowSize.width > 1024 ? '60px'
                                                : '80px',
            left: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows-text'>{row.KONTO}</span>
                </>
            ),
        },
        {
            name: 'Naziv konta',
            selector: row => row.KONTONAZIV,
            width:
                windowSize.width > 3072 ? '300px'
                    : windowSize.width > 2048 ? '300px'
                        : windowSize.width > 1920 ? '340px'
                            : windowSize.width > 1680 ? '200px'
                                : windowSize.width > 1600 ? '210px'
                                    : windowSize.width > 1440 ? '190px'
                                        : windowSize.width > 1280 ? '200px'
                                            : windowSize.width > 1024 ? '160px'
                                                : '300px',
            left: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows-text'>{row.KONTONAZIV}</span>
                </>
            ),
        },
        {
            name: 'Duguje',
            selector: row => row.DUGUJE,
            // sirina polja po rezolucijama
            width:
                windowSize.width > 3072 ? '220px'
                    : windowSize.width > 2048 ? '220px'
                        : windowSize.width > 1920 ? '190px'
                            : windowSize.width > 1680 ? '120px'
                                : windowSize.width > 1600 ? '100px'
                                    : windowSize.width > 1440 ? '100px'
                                        : windowSize.width > 1280 ? '95px'
                                            : windowSize.width > 1024 ? '95px'
                                                : '90px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{numberWithCommasColumns(row.DUGUJE)}</span>
                </>
            ),
        },
        {
            name: 'Potražuje',
            selector: row => row.POTRAZ,
            // sirina polja po rezolucijama
            width:
                windowSize.width > 3072 ? '200px'
                    : windowSize.width > 2048 ? '200px'
                        : windowSize.width > 1920 ? '190px'
                            : windowSize.width > 1680 ? '120px'
                                : windowSize.width > 1600 ? '100px'
                                    : windowSize.width > 1440 ? '100px'
                                        : windowSize.width > 1280 ? '95px'
                                            : windowSize.width > 1024 ? '95px'
                                                : '90px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{numberWithCommasColumns(row.POTRAZ)}</span>
                </>
            ),
        },
    ]

    const searchChange = (e) => {
        e.preventDefault();
        const searchInput = e.target.value;
        setSearchInput(searchInput);
        const fetchData = async () => {
            const res = await axios.get(`${API}/unosNaloga/query/${form.companyCode}/${form.NALOG}?q=${searchInput}`, { headers: { "Authorization": `Bearer ${token}` } });
            setFinNalog(res.data);
        };

        // uslov za za slanje zahtjeva za povlacenje podataka
        if (searchInput.length >= 1) fetchData();

    };

    // const getPageNumber = () => {
    //     for (
    //         let i = Math.max(1, currentPage - 2);
    //         i <= Math.min(duzinaNiza, currentPage + 2);
    //         i++
    //     ) {
    //         pageNumbers.push(i);
    //     }
    // };

    // useEffect(() => {

    //     if (!searchInput.length) {
    //         setShowPagination(true);
    //         getFinNalogData(currentPage, itemsPerPage);
    //     } else {
    //         setShowPagination(false);
    //     }
    // }, [searchInput]);

    // getPageNumber();

    return (

        <div>
            {/* Kontejner za naslov  */}

            <div className="title-container">
                {/* Naziv registra */}

                <header className="title-header">
                    <h1 id="media-query-title">Unos Naloga</h1>
                </header>
                {/* Klasa za ikonice, nalazi se u istom redu kao i naziv, ikonice su formirane kao lista */}


                <div className="naslov-ikonice">
                    <ul className="nav flex-column flex-lg-row justify-content-end">
                        <li className="nav-item toolbaritem">
                            <input type="image" alt="save" className='dugme-sacuvaj' title='Sačuvaj' tabIndex="11" src={require('../../assets/images/floppydisk.png')} onFocus={onSubmit} />
                        </li>

                        <li className="nav-item toolbaritem">
                            <input type="image" alt="print" className='dugme-stampa' title='Štampa' tabIndex="12" src={require('../../assets/images/printer.png')} onClick={() => onPrint()}>
                            </input>
                        </li>

                        <li className="nav-item toolbaritem">
                            <div onClick={form.NALOG && form.NALOG !== '' && form.NALOG !== 0 && finNalog ? setModalOpen : setModalErrorDeleteNalogOpen} tabIndex="13">
                                <SVG src={deleteNalog} style={{ margin: '1px' }} title='Brisanje naloga' className='dugme-brisanje' />
                            </div>
                        </li>

                        <li className="nav-item toolbaritem">
                            <input type="image" alt="delete item" className='dugme-stampa' title='Brisanje stavke' tabIndex="14" src={require('../../assets/images/trash1.png')} onClick={form.RED && form.RED !== '' && form.RED !== 0 && form.RED !== firstRedNumber ? setModalDeleteItem : setModalErrorDeleteItemOpen} />
                        </li>

                    </ul>
                </div>

            </div>
            {/* Kontejner pune sirine */}
            <Container fluid>
                <Row>
                    <div className='products-form'>
                        <Row>
                            {/* Stilovi koji ce se primjenjivati na svim poljima */}
                            <div className="col-12 d-flex p-0 m-0" >
                                {/* Sirina polja */}
                                <div className="col-1">
                                    <div className='col-form-right'>
                                        {/* Provjera da li je polje popunjeno jer je obavezno */}
                                        <div className={errorInputNalog ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px' }} >
                                            <label id="media-query-label">Nalog:</label>
                                            <NumericFormat
                                                type="text"
                                                className='field-alignment p-1'
                                                getInputRef={nalogRef}
                                                id="media-query-input-text"
                                                maxLength="9"
                                                // broj decimala
                                                decimalScale={0}
                                                tabIndex="1"
                                                placeholder="nalog"
                                                name='NALOG'
                                                value={form?.NALOG}
                                                onFocus={() => {
                                                    unosIspravanDo("NALOG")
                                                    getFinNalogFirstFreeNumber()
                                                }}
                                                // Ako je izmjena na polju
                                                onChange={(e) => handleChange(e)}
                                                onClick={() => {
                                                    // ako je odradjen dvoklik na stavku u mrezi, a zatim klik na dokument, cisti polja         
                                                    form.stavkaPronadjena = false
                                                    setForm(prev => ({
                                                        ...prev, RED: '',
                                                        DATD: '', OPIS: '', DUGUJE: '', POTRAZ: ''
                                                    }))
                                                    setVrsta(null)
                                                    setKonto(null)
                                                    setClients(null)
                                                }} />
                                            {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                                            {errorMsgNalog ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgNalog}</span> : ''}
                                        </div>
                                    </div>
                                </div>
                                {/* Sirina polja */}
                                <div className="col-1">
                                    <div className={errorDateN ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px' }} >
                                        <label id="media-query-label">Dat. nal.:</label>
                                        <input
                                            id="media-query-input-text"
                                            type="text"
                                            tabIndex="2"
                                            ref={dateNRef}
                                            onFocus={() => {
                                                unosIspravanDo("DATN")
                                                if (form.NALOG === firstNalogNumber || form.NALOG > firstNalogNumber) {
                                                    setVrsta(null);
                                                    setKonto(null);
                                                    setClients(null);
                                                    setFinNalog(null)
                                                    setForm(prev => ({ ...prev, DATN: moment.unix(new Date().getTime() / 1000).format("DD.MM.YYYY") }))
                                                } else {
                                                    checkNalog()
                                                }
                                            }}
                                            placeholder="datum"
                                            name='DATN'
                                            value={form?.DATN}
                                            // ako izmjena na datuma postavi novi datum odabrani
                                            onChange={(e) => handleChange(e)} />
                                        {errorMsgDateN ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgDateN}</span> : ''}
                                    </div>
                                </div>
                            </div>
                        </Row>

                        <Row>
                            <div className="col-6 d-flex p-0 m-0" >
                                <div className="col-1">
                                    <div className='col-form-right'>
                                        <div className={errorInputRed ? 'inputWithError' : 'field-container-number'} style={{ marginRight: '3px' }} >
                                            <label id="media-query-label">Red:</label>
                                            <NumericFormat
                                                type="text"
                                                className='field-alignment p-1'
                                                getInputRef={redRef}
                                                id="media-query-input-text"
                                                maxLength="7"
                                                // broj decimala
                                                decimalScale={0}
                                                onFocus={() => {
                                                    unosIspravanDo("RED")
                                                    getFinNalogFirstFreeNumberRed()
                                                }}
                                                tabIndex="3"
                                                placeholder="red"
                                                name='RED'
                                                value={form ? form.RED : ''}
                                                // Ako je izmjena na polju
                                                onChange={(e) => handleChange(e)}
                                                onClick={() => {
                                                    // ako je odradjen dvoklik na stavku u mrezi, cisti polja  
                                                    form.stavkaPronadjena = false
                                                    setForm(prev => ({
                                                        ...prev, VRSTA: '', DATD: '', KONTID: '', OPIS: '', KOMTID: '', DUGUJE: '', POTRAZ: '', stavkaPronadjena: false,
                                                    }))
                                                    setVrsta(null)
                                                    setKonto(null)
                                                    setClients(null)
                                                }} />
                                            {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                                            {errorMsgRed ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgRed}</span> : ''}
                                        </div>
                                    </div>
                                </div>
                                {/* Sirina polja */}
                                <div className="col-4">
                                    {/* Provjera da li je polje popunjeno jer je obavezno */}
                                    <div className={errorInputVrsta ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px' }} >
                                        <label id="media-query-label">Vrsta:</label>
                                        {/* Ako je Autocomplete  poziva odabir vrste dokumenta*/}
                                        <Autocomplete
                                            // svaki od ponudjenih artikala mora biti jedinstven(svaka stavka(child) u objektu artikal mora imati jedinstven kljuc)
                                            renderOption={(props, vrsta) => {
                                                return (
                                                    <li {...props} key={vrsta.VRSTAD}>
                                                        <span>{vrsta?.VRSTAD}</span>
                                                        <span className="ps-1">{vrsta?.OPISD}</span>
                                                    </li>
                                                );
                                            }}
                                            //ukoliko nema ove funkcije, u konzoli izbacuje upozorenje da nije vrijednost jedinstvena
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            {...defaultPropsVrsta}
                                            value={vrsta}
                                            disablePortal={true}
                                            onFocus={() => {
                                                unosIspravanDo("VRSTA")
                                                setForm(prev => ({ ...prev, DATD: moment.unix(new Date().getTime() / 1000).format("DD.MM.YYYY") }))
                                                // uslov za citanje sloga po rednom broju
                                                if (unosIspravanDo("VRSTA")) {
                                                    let selectedItem = []
                                                    selectedItem = finNalog.filter((c) => c.RED == form.RED)
                                                    if (selectedItem.length > 0) {
                                                        onEdit(selectedItem[0])
                                                    }
                                                }
                                            }}
                                            // Ako je izmjena na polju
                                            onChange={(event, newValue) => {
                                                //   console.log(newValue);
                                                setForm(prev => ({ ...prev, VRSTA: newValue && newValue != null ? newValue.VRSTAD : null }))
                                                setVrsta(newValue)
                                                // fokus se mora vratiti inace tabIndex ne radi dobro
                                                vrstaRef.current.focus()
                                            }}

                                            renderInput={(params) => (
                                                <TextField {...params}
                                                    tabIndex='4'
                                                    ref={vrstaRef}
                                                    name='VRSTA'
                                                    className="vrsta-textinput"
                                                    placeholder=' Izaberite vrstu dokumenta'
                                                    variant="standard"
                                                    onFocus={() => {
                                                        // fokus se mora vratiti inace tabIndex ne radi dobro
                                                        vrstaRef.current.focus()
                                                    }} />
                                            )}
                                        />
                                        {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                                        {errorMsgVrsta ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgVrsta}</span> : ''}
                                    </div>
                                </div>
                                {/* Sirina polja */}
                                <div className="col-2">
                                    <div className={errorDateD ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px' }}>
                                        <label id="media-query-label">Dat. dok:</label>
                                        <input
                                            type="text"
                                            id="media-query-input-text"
                                            tabIndex="5"
                                            ref={dateDRef}
                                            name='DATD'
                                            value={form?.DATD}
                                            onFocus={() => {
                                                unosIspravanDo("DATD")
                                            }}
                                            // ako izmjena na datuma postavi novi datum odabrani
                                            onChange={(e) => handleChange(e)} />
                                        {errorMsgDateD ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgDateD}</span> : ''}
                                    </div>
                                </div>

                                <div className="col-5">
                                    {/* Provjera da li je polje popunjeno jer je obavezno */}
                                    <div className={errorInputClients ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px' }} >
                                        <label id="media-query-label">Komitent:</label>
                                        {/* Ako je Autocomplete  poyiva na odabri mjesta*/}
                                        <Autocomplete
                                            // svaka od ponudjenih  usluga mora biti jedinstvena(svaka stavka(child) u objektu usluga mora imati jedinstven kljuc)
                                            renderOption={(props, clients) => {
                                                return (
                                                    <li {...props} key={clients.SIFRA}>
                                                        <span>{clients?.SIFRA}</span>
                                                        <span className="ps-1">{clients?.NAZIV}</span>
                                                    </li>
                                                );
                                            }}
                                            //ukoliko nema ove funkcije, u konzoli izbacuje upozorenje da nije vrijednost jedinstvena
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            {...defaultPropsClients}
                                            value={clients}
                                            disablePortal={true}
                                            onFocus={() => {
                                                unosIspravanDo("KOMTID")
                                            }}
                                            onChange={(event, newValue) => {
                                                setForm(prev => ({ ...prev, KOMTID: newValue && newValue != null ? newValue.ID : null }))
                                                setClients(newValue)
                                                // fokus se mora vratiti inace tabIndex ne radi dobro
                                                clientsRef.current.focus()
                                            }}

                                            renderInput={(params) => (
                                                <TextField {...params}
                                                    tabIndex='6'
                                                    ref={clientsRef}
                                                    name='KOMTID'
                                                    className="clients-textinput"
                                                    placeholder=' Izaberite komitenta'
                                                    variant="standard"
                                                />
                                            )}
                                        />
                                        {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                                        {errorMsgClients ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgClients}</span> : ''}
                                    </div>
                                </div>
                            </div>
                            {/* Sirina polja */}
                            <div className="col-6 d-flex p-0 m-0" >
                                <div className="col-4">
                                    {/* Provjera da li je polje popunjeno jer je obavezno */}
                                    <div className='field-container' style={{ marginRight: '3px' }} >
                                        <label id="media-query-label">Opis:</label>
                                        <input type="text" id="media-query-input-text" ref={descriptionRef} maxLength="100" tabIndex="7" placeholder="opis" name='OPIS' value={form ? form.OPIS : ''}
                                            onFocus={() => {
                                                unosIspravanDo("OPIS")
                                            }} onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>

                                {/* Sirina polja */}
                                <div className="col-4">
                                    {/* Provjera da li je polje popunjeno jer je obavezno */}
                                    <div className={errorInputKonto ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px' }} >
                                        <label id="media-query-label">Konto:</label>
                                        {/* Ako je Autocomplete  poyiva na odabri mjesta*/}
                                        <Autocomplete
                                            // svaki od ponudjenih artikala mora biti jedinstven(svaka stavka(child) u objektu artikal mora imati jedinstven kljuc)
                                            renderOption={(props, konto) => {
                                                return (
                                                    <li {...props} key={konto.KONTO}>
                                                        <span>{konto?.KONTO}</span>
                                                        <span className="ps-1">{konto?.NAZ}</span>
                                                    </li>
                                                );
                                            }}

                                            //ukoliko nema ove funkcije, u konzoli izbacuje upozorenje da nije vrijednost jedinstvena
                                            isOptionEqualToValue={(option, value) => option.id === value.id}

                                            {...defaultPropsKonto}
                                            value={konto}
                                            disablePortal={true}
                                            onFocus={() => {
                                                unosIspravanDo("KONTID")
                                            }}
                                            // ako se promijeni konto, uzima podatke za novi konto
                                            onChange={(event, newValue) => {
                                                setForm(prev => ({ ...prev, KONTID: newValue && newValue != '' ? newValue.ID : '', OZNAKA: newValue && newValue != '' ? newValue.OZNAKA : '' }))
                                                setKonto(newValue)
                                                // fokus se mora vratiti inace tabIndex ne radi dobro
                                                kontoRef.current.focus()
                                            }}

                                            renderInput={(params) => (
                                                <TextField {...params}
                                                    tabIndex='8'
                                                    ref={kontoRef}
                                                    name='KONTID'
                                                    className="clients-textinput"
                                                    placeholder=' Izaberite konto'
                                                    variant="standard"
                                                />
                                            )}
                                        />
                                        {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                                        {errorMsgKonto ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgKonto}</span> : ''}
                                    </div>
                                </div>

                                {/* Sirina polja */}
                                <div className="col-2">
                                    <div className='col-form-right'>
                                        {/* Provjera da li je polje popunjeno jer je obavezno */}
                                        <div className='field-container-number' style={{ marginRight: '3px' }} >
                                            <label id="media-query-label">Duguje:</label>
                                            {/* komponenta za formatiranje brojeva */}
                                            <NumericFormat
                                                id="media-query-input-text"
                                                type="text"
                                                className='field-alignment p-1'
                                                getInputRef={dugujeRef}
                                                // broj decimala
                                                decimalScale={2}
                                                tabIndex="9"
                                                maxLength="15"
                                                placeholder="duguje"
                                                name='DUGUJE'
                                                value={form ? form.DUGUJE : ''}
                                                onFocus={() => {
                                                    unosIspravanDo("DUGUJE")
                                                }}
                                                onChange={(e) => handleChange(e)} />
                                        </div>
                                    </div>
                                </div>

                                {/* Sirina polja */}
                                <div className="col-2">
                                    <div className='col-form-right'>
                                        {/* Provjera da li je polje popunjeno jer je obavezno */}
                                        <div className='field-container-number' style={{ marginRight: '3px' }} >
                                            <label id="media-query-label">Potražuje:</label>
                                            {/* komponenta za formatiranje brojeva */}
                                            <NumericFormat
                                                id="media-query-input-text"
                                                type="text"
                                                className='field-alignment p-1'
                                                getInputRef={potrazRef}
                                                maxLength="15"
                                                // broj decimala
                                                decimalScale={2}
                                                tabIndex="10"
                                                placeholder="potraz"
                                                name='POTRAZ'
                                                value={form ? form.POTRAZ : ''}
                                                onFocus={() => {
                                                    unosIspravanDo("POTRAZ")
                                                }}
                                                // Ako je izmjena na polju
                                                onChange={(e) => handleChange(e)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </div>

                </Row >
            </Container >
            {/* Mreza */}

            {/* Stilovi i klase za polje za pretragu;  */}
            <div style={{ display: 'flex' }} className='search'>
                {/* vrsta inputa; poziva funkciju za pretragu(searchChange);  */}
                <input type='text' id="media-query-input-text" placeholder='Pretraga...'
                    onChange={(e) => {
                        // pretraga mreze(ukoliko je unesen bilo koji karakter, vrsi pretragu)
                        if (searchInput.length >= 0) {
                            searchChange(e)
                        }
                    }} value={searchInput} />
            </div>

            {
                <DataTable
                    columns={columns}
                    data={finNalog ? finNalog : []}
                    pagination
                    responsive
                    highlightOnHover
                    striped
                    dense
                    pointerOnHover
                    persistTableHead
                    paginationComponentOptions={defaultComponentOptions}
                    paginationPerPage={[windowSize.height > 1300 ? 28
                        : windowSize.height > 1200 ? 28
                            : windowSize.height > 1000 ? 20
                                : windowSize.height > 900 ? 14
                                    : windowSize.height > 820 ? 13
                                        : windowSize.height > 720 ? 10
                                            : 6]}
                    progressPending={pending}
                    onRowDoubleClicked={(row, event) => {
                        onEdit(row)
                    }}
                />
            }

            {/* bootstrap table  */}
            {/* <table className="table table-sm table-striped table-hover table-light ">
                <thead>
                    <tr>
                        {columns.map((column, index) => {
                            return (
                                <th key={index} scope="col" className={column.class}>
                                    {column.name}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {Array.isArray(finNalog) &&
                        finNalog?.map((c, index) => {
                            return (
                                <tr
                                    onDoubleClick={() => onEdit(c)}
                                    key={index}
                                    className="col-12 "
                                >
                                    <td className={columns[0].class}>
                                        {c.RED}
                                    </td>
                                    <td className={columns[1].class}>{c.VRSTA} {"  "}  {c.OPISD} </td>
                                    <td className={columns[2].class}>{c.DATD}</td>
                                    <td className={columns[3].class}>{c.KOMTSIFRA} {""} {c.KOMTNAZIV}</td>
                                    <td className={columns[4].class}>{c.OPIS}</td>
                                    <td className={columns[5].class}>{c.KONTO}{c.KONTONAZIV}</td>
                                    <td className={columns[6].class}>{numberWithCommasColumns(c.DUGUJE)}</td>
                                    <td className={columns[7].class}>{numberWithCommasColumns(c.POTRAZ)}</td>
                                </tr>
                            );
                        })}
                </tbody>
            </table> */}

            <div className="naslov-ikonice">
                <ul className="nav flex-column flex-lg-row justify-content-end">
                    <div style={{ display: 'flex', justifyContent: 'end', position: 'fixed', bottom: '0', flex: '0 0 auto' }}>

                        <li className="nav-item toolbaritem" style={{ width: '21%', marginRight: '5px' }}>
                            <div className='col-form-right'>
                                <div className="field-container-number-totals">
                                    <label id="media-query-label-sum">Saldo</label>
                                    <input className="total" readOnly={true} type='text' id="media-query-input-text" value={sumTotal && sumTotal != 'NaN' ? numberWithCommas(sumTotal) : 0} />
                                </div>
                            </div>
                        </li>

                        <li className="nav-item toolbaritem" style={{ width: '21%', marginRight: '4px' }}>
                            <div className='col-form-right'>
                                <div className="field-container-number-totals">
                                    <label id="media-query-label-sum">Duguje</label>
                                    <input className="form-control" readOnly={true} type='text' id="media-query-input-text" value={sumDuguje && sumDuguje != 'NaN' ? numberWithCommas(sumDuguje) : 0} />
                                </div>
                            </div>
                        </li>

                        <li className="nav-item toolbaritem" style={{ width: '21%', marginRight: '7px' }}>
                            <div className='col-form-right'>
                                <div className="field-container-number-totals">
                                    <label id="media-query-label-sum">Potražuje</label>
                                    <input type='text' className="form-control" readOnly={true} id="media-query-input-text" value={sumPotraz && sumPotraz != 'NaN' ? numberWithCommas(sumPotraz) : 0} />
                                </div>
                            </div>
                        </li>

                    </div>
                </ul>
            </div>

            <div>
                <Modal isOpen={Boolean(modalOpen)} centered  >
                    <ModalHeader>Brisanje naloga</ModalHeader>
                    <ModalBody>
                        <span>{`Da li želite da obrišete nalog broj ${form.NALOG} ? `}</span>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            {/* Dugme za zatvaranje modalnog forma */}
                            <button onClick={() => setModalOpen(false)} className="button-no">Ne</button>
                            <button onClick={() => onDelete(form.companyCode, form.NALOG)} className="button-yes">Da</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            <div>
                <Modal isOpen={Boolean(modalDeleteItem)} centered  >
                    <ModalHeader>Brisanje stavke</ModalHeader>
                    <ModalBody>
                        <span>{`Da li želite da obrišete stavku broj ${form.RED} ? `}</span>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            <button onClick={() => setModalDeleteItem(false)} className="button-no">Ne</button>
                            <button onClick={() => onDeleteItems(form.companyCode, form.NALOG, form.RED)} className="button-yes">Da</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            <div>
                <Modal isOpen={Boolean(modalErrorCheckDP)} centered  >
                    <ModalHeader>Neispravan unos!</ModalHeader>
                    <ModalBody>
                        <span>{`Unesite dugovnu ili potražnu stranu! `}</span>
                    </ModalBody>
                    <ModalFooter className="justify-content-center">
                        <div>
                            {/* Dugme za zatvaranje modalnog forma */}
                            <button onClick={() => setModalErrorCheckDP(false)} className="button-yes">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>


            <div>
                <Modal isOpen={Boolean(modalErrorDeleteNalogOpen)} centered  >
                    <ModalHeader>Neispravan unos!</ModalHeader>
                    <ModalBody>
                        <span>{`Morate odabrati nalog za brisanje!`}</span>
                    </ModalBody>
                    <ModalFooter className="justify-content-center">
                        <div>
                            <button onClick={() => setModalErrorDeleteNalogOpen(false)} className="button-yes">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            {/* render za poruku */}
            <div>
                <Modal isOpen={Boolean(modalErrorPrint)} centered  >
                    <ModalHeader>Neispravan unos!</ModalHeader>
                    <ModalBody>
                        <span>{`Unesite validan nalog!`}</span>
                    </ModalBody>
                    <ModalFooter className="justify-content-center">
                        <div>
                            <button onClick={() => {
                                setModalErrorPrint(false)
                            }} className="button-yes">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            <div>
                <Modal isOpen={Boolean(modalErrorDeleteItemOpen)} centered  >
                    <ModalHeader>Neispravan unos</ModalHeader>
                    <ModalBody>
                        <span>{`Morate odabrati stavku za brisanje!`}</span>
                    </ModalBody>
                    <ModalFooter className="justify-content-center">
                        <div>
                            <button onClick={() => setModalErrorDeleteItemOpen(false)} className="button-yes">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            {/* spiner ili progres bar ya prikaz pokretanja datog izvjestaja */}
            <div>
                {spinner ? <Spinner className='spinner' color="primary" id='loader' /> : ''}
            </div>

        </div >
    );

}
export default UnosNaloga;


