import axios from "axios";

export default axios.create({
  baseURL: "https://mica.infosistemweb.com",
});

/* export default axios.create({
  baseURL: "http://192.168.0.103:3103",
}); */

// export default axios.create({
//     baseURL: 'https://aplikacije-api.infosistemweb.com'
// });

// export default axios.create({
//     baseURL: 'http://testreactapi.infosistemweb.com'
// });

// export default axios.create({
//     baseURL: 'http://testfinansijeapi.infosistemweb.com'
// });

/* export default axios.create({
  baseURL: "http://finansije1api.infosistemweb.com",
}); */
