// importovanje komponenti
import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { API } from '../constants';
import { Spinner } from "reactstrap";
import SVG from 'react-inlinesvg';
import barcodeIcon from "../assets/barcode.svg";
import closeIcon from "../assets/close.svg";
import { getCompanyData, defaultComponentOptions } from '../components/utilities';
import { NumericFormat } from 'react-number-format';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import axios from '../api/axios';
import { defaultFormRegistri } from "../components/utilities";
import { useWindowSize } from '../components/useWindowSize';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
// komponenta koju kreiramo

function Artikal() {
    const navigate = useNavigate();
    const windowSize = useWindowSize();
    // definisanje konstanti koje koristimo za fokusiranje polja
    const codeRef = useRef();
    const codeNRef = useRef();
    const nameRef = useRef();
    const nameNRef = useRef();
    const kompakRef = useRef();
    const akcizaRef = useRef();
    const clientsRef = useRef()
    const taxRef = useRef()
    const grupaRef = useRef()
    const podgrupaRef = useRef()
    const brendRef = useRef()
    const jmRef = useRef()
    const barKodRef = useRef()
    const repositoryRef = useRef()
    const proizvodRef = useRef()
    const nabcenaRef = useRef()
    const prodcenaRef = useRef()
    const kodRef = useRef()
    const vrstaBKRef = useRef()
    // definisanje stejtova i prva inicijalizacija; objekat i funkcija za taj objekat
    const [showContent, setShowContent] = useState(false);
    const [artikal, setartikal] = useState(null)
    //  const [artikalPromet, setArtikalPromet] = useState(null)
    const [cjenovnik, setCjenovnik] = useState(null)
    const [barKod, setBarKod] = useState(null)
    const [clients, setClients] = useState(null);
    const [repository, setRepository] = useState(null);
    const [tax, setTax] = useState(null);
    const [grupa, setGrupa] = useState(null);
    const [podgrupa, setPodgrupa] = useState(null);
    const [brend, setBrend] = useState(null);
    const [selectDataSkladiste, setSelectDataSkladiste] = useState([]);
    const [selectDataBrend, setSelectDataBrend] = useState([]);
    const [selectDataBarKod, setSelectDataBarKod] = useState([]);
    const [selectDataKomt, setSelectDataKomt] = useState([]);
    const [selectDataPorez, setSelectDataPorez] = useState([]);
    const [selectDataGrupa, setSelectDataGrupa] = useState([]);
    const [selectDataPodgrupa, setSelectDataPodgrupa] = useState([]);
    const [searchInput, setSearchInput] = useState([]);
    const [spinner, setSpinner] = useState(false);
    const [itemUpdate, setItemUpdate] = useState(false)
    const [pending, setPending] = React.useState(false);
    // error row inputs
    const [errorInputCode, setErrorInputCode] = useState(false);
    const [errorInputName, setErrorInputName] = useState(false);
    const [errorInputKod, setErrorInputKod] = useState(false);
    const [errorInputVrstaBK, setErrorInputVrstaBK] = useState(false);
    const [errorInputTax, setErrorInputTax] = useState(false);
    const [errorInputJM, setErrorInputJM] = useState(false);
    const [barKodFound, setBarKodFound] = useState(false)
    // error messages inputs
    const [errorMsgCode, setErrorMsgCode] = useState(false);
    const [errorMsgName, setErrorMsgName] = useState(false);
    const [errorMsgTax, setErrorMsgTax] = useState(false);
    const [errorMsgJM, setErrorMsgJM] = useState(false);
    const [errorMsgVrstaBK, setErrorMsgVrstaBK] = useState(false);
    const [errorMsgKod, setErrorMsgKod] = useState(false);
    const [errorMsgBarKod, setErrorMsgBarKod] = useState(false);

    const [modalErrorOpenData, setModalErrorOpenData] = useState(false);
    const [modalErrorAuthorizationData, setModalErrorAuthorizationOpen] = useState(false);
    const [modalDeleteItem, setModalDeleteItem] = useState(false);
    const [modalDeleteItemBarKod, setModalDeleteItemBarKod] = useState(false);
    const [modalDeleteItemCjenovnik, setModalDeleteItemCjenovnik] = useState(false);
    const [modalNomenExists, setModalNomenExists] = useState(false);
    const [modalErrorDeleteItemOpen, setModalErrorDeleteItemOpen] = useState(false)
    // povlacenje podataka iz lokal storidza
    const token = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : null;

    // definisanje stejta(objekat i funkcija za taj objekat), prva inicijalizacija
    const [form, setForm] = useState({
        ...defaultFormRegistri,
    });
    let artikalPredracun;
    let artikalPromet;

    // funkcija za povlacenje podataka o preduzecu
    getCompanyData(form);

    // funkcija koja se poziva klikom na dugme 'Sacuvaj'
    const onSubmit = () => {
        // console.log('upada u onSubmit')
        form.SIFKOR = form.userCode
        form.izmjenaCijene = false;
        // uslov: ako nisu unesena obavezna polja, zaustavlja upis
        //  if (!unosIspravanDo('UPIS')) return;

        // poziva api za upis
        // osvezavanje polja, fokus na polje SIFRAP, poziva funkciju getTableData(za upis u mrezu)
        fetch(API + `/artikal/new/${form.companyCode}`, {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                response.json()
                    .then(data => {
                        // vracanje forme na pocetnu vrijednost
                        // setForm((prev) => ({
                        //     ...prev,
                        //     NOMENID: Number(data[0].ID),
                        // }));
                        form.ID = data[0].ID
                    })
                // setClients(null)
                // setTax(null)
                // setGrupa(null)
                // setPodgrupa(null)
                // setBrend(null)
                //  codeRef.current.focus()
                getTableData()
            })
    }

    const onSubmitBARKOD = () => {
        if (form.BAR_KOD.length > 0) {
            onBarKodFound();
            setTimeout(() => {
              //  console.log(form.BAR_KOD_duplikat == 0, 'form.BAR_KOD_duplikat == 0', form.BAR_KOD_duplikat, 'form.BK duplikat')
                if (form.BAR_KOD_duplikat == 0) {
                    form.VRSTABK = 'B'
                 //   console.log(form.ID, 'form.ID')
                    fetch(API + `/barKod/new`, {
                        method: 'POST',
                        body: JSON.stringify({
                            kod: form.BAR_KOD,
                            nomenId: form.ID,
                            vrsta: form.VRSTABK,
                            sifraKorisnika: form.SIFKOR
                        }),
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-type': 'application/json; charset=UTF-8'
                        },
                    })
                        .then((response) => {
                            response.json()
                            // setForm({
                            //     KOD: '',
                            //     VRSTABK: '',
                            //     SIFKOR: '',
                            //    // NOMENID: '',
                            // })
                             getBarKodData();
                        })
                }
            }, 200);
        }
    }

    const onSubmitKATBROJ = () => {
        if (form.KATBROJ.length > 0) {
            onKatBrojFound();
            setTimeout(() => {
                if (form.KATBROJ_duplikat == 0) {
                    form.VRSTABK = 'K'
                    fetch(API + `/barKod/new`, {
                        method: 'POST',
                        body: JSON.stringify({
                            kod: form.KATBROJ,
                            nomenId: form.ID,
                            vrsta: form.VRSTABK,
                            sifraKorisnika: form.SIFKOR
                        }),
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-type': 'application/json; charset=UTF-8'
                        },
                    })
                        .then((response) => {
                            response.json()
                            // setForm({
                            //     KOD: '',
                            //     VRSTABK: '',
                            //     SIFKOR: '',
                            //   //  NOMENID: '',
                            // })
                              getBarKodData();
                        })
                }
            }, 200);
        }
    }

    // funkcija koja se poziva klikom na dugme 'Sacuvaj'
    const onSubmitBarKod = () => {

        // uslov: ako nisu unesena obavezna polja, zaustavlja upis
        //  if (!unosIspravanDo('UPIS')) return;

        // poziva api za upis
        // osvezavanje polja, fokus na polje SIFRA, poziva funkciju getTableData(za upis u mrezu)
        form.SIFKOR = form.userCode
        // if (form.BAR_KOD.length > 0) {
        //     form.VRSTABK = 'B'
        // }
        // if (form.KATBROJ.length > 0) {
        //     form.VRSTABK = 'K'
        // }
        fetch(API + `/barKod/new`, {
            method: 'POST',
            body: JSON.stringify({
                kod: form.KOD,
                nomenId: itemUpdate ? form.ID : form.NOMENID,
                vrsta: form.VRSTABK,
                sifraKorisnika: form.SIFKOR
            }),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                response.json()
                setForm((prev) => ({
                    ...prev,
                    KOD: '',
                    VRSTABK: ''
                }));
                kodRef.current.focus();
                getBarKodData();
            })
    }

    // funkcija za provjeru da li vec postoji upisani bar kod
    const onBarKodFound = () => {
        form.BAR_KOD_duplikat = 0;
     //  console.log('upada u onBarKodFound')
        form.VRSTABK = 'B'
        fetch(API + `/barKod/foundBarKod/${form.companyCode}`, {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
           //     console.log(data, 'dataonBarKodFound')
                form.BAR_KOD_duplikat = data[0].NOMENID
            })
    }

        // funkcija za provjeru da li vec postoji upisani bar kod
        const onBarKodDuplikateFound = () => {
           console.log('upada u onBarKodDuplikateFound')
            form.VRSTABK = 'B'
            fetch(API + `/barKod/foundBarKodDuplikate/${form.companyCode}`, {
                method: 'POST',
                body: JSON.stringify(form),
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-type': 'application/json; charset=UTF-8'
                },
            })
                .then((response) => response.json())
                .then(data => {
                    console.log(data, 'data')
                    if(data && data.length) {
                        form.SIFRAN = data[0].SIFRA
                    } else {
                        form.SIFRAN = 0
                    }         
                })
        }

    // funkcija za provjeru da li vec postoji upisani bar kod
    const onKatBrojFound = () => {
        form.KATBROJ_duplikat = 0;
        //   console.log('upada u onBarKodFound')
        form.VRSTABK = 'K'
        fetch(API + `/barKod/foundKatBroj/${form.companyCode}`, {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
             //   console.log(data, 'data')
                form.KATBROJ_duplikat = data[0].NOMENID
            })
    }

    // funkcija za provjeru prometa artikla
    const checkNomenPromets = () => {
        checkNomenPredracs();
        setTimeout(() => {
            fetch(API + `/artikal/prometArtiklaPromets`, {
                method: 'POST',
                body: JSON.stringify({ nomenId: form.ID }),
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-type': 'application/json; charset=UTF-8'
                },
            })
                .then((response) => {
                    response.json()
                        .then(data => {
                            if (data && data.length) {
                                //   setForm(prev => ({ ...prev, NOMENIDPROMETS: data[0].NOMENID }))
                                artikalPromet = data[0].NOMENID
                                //   setModalNomenExists(true)
                            } else {
                                //    setForm(prev => ({ ...prev, NOMENIDPROMETS: 0 }))
                                artikalPromet = 0
                                //   setModalNomenExists(false)
                            }
                            //    console.log(artikalPromet !== 0 || artikalPredracun !== 0, 'artikalPromet !== 0 || artikalPredracun !== 0', artikalPromet, artikalPredracun)
                            if (artikalPromet !== 0 || artikalPredracun !== 0) {
                                setModalNomenExists(true)
                            } else {
                                if (!form.stavkaPronadjena) {
                                    form.ID = form.NOMENID
                                }
                                onDelete(form.ID)

                            }
                        })
                })
        }, 50);

    }

    // funkcija za provjeru prometa artikla
    const checkNomenPredracs = () => {
        fetch(API + `/artikal/prometArtiklaPredracuns`, {
            method: 'POST',
            body: JSON.stringify({ nomenId: form.ID }),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                response.json()
                    .then(data => {
                        //    console.log(data, 'data', data[0].NOMENID, 'data[0].NOMENID')
                        // if (data && data.length) {
                        //   setForm(prev => ({ ...prev, NOMENIDPREDRACUN: data[0].NOMENID }))
                        artikalPredracun = data[0].NOMENID
                        //  setModalNomenExists(true)
                        // } else {
                        //     //   setForm(prev => ({ ...prev, NOMENIDPREDRACUN: 0 }))
                        //     artikalPredracun = 0
                        //     //   setModalNomenExists(false)
                        // }
                        //    console.log(artikalPredracun, ' checkNomenPredracs')
                    })
            })
    }

    // funkcija koja se poziva pri prvoj inicijalizaciji
    // api za mrezu
    // u responsu puni mrezi(pomocu funkcije setIme) i puni ukupan broj stranica(setTotal)
    const getTableData = () => {
        fetch(API + `/artikal/all/${form.companyCode}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                "Content-type": "application/json; charset=UTF-8",
            },
        })
            .then((response) => response.json())
            .then(data => {
                //  console.log(data, 'data')
                setartikal(data.result)
            })
    }

    // funkcija koja poziva citanje skladista
    const getRepositoryData = () => {
        fetch(API + `/skladista/all/${form.companyCode}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataSkladiste(data)
            })
    }

    const getBrendData = () => {
        fetch(API + `/brend/all`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataBrend(data)
            })
    }

    const getBarKodData = () => {
    //    console.log('upada u getBarKodData')
    //    console.log(form.ID, 'form.ID')
        fetch(API + `/barKod/all`, {
            method: 'POST',
            body: JSON.stringify({
                nomenId: form.ID,
            }),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setBarKod(data)
            })
    }

    // const onSubmitBarKodData = () => {
    //     form.SIFKOR = form.userCode
    //     if (form.BAR_KOD.length > 0) {
    //         form.VRSTABK = 'B'

    //         fetch(API + `/barKod/new`, {
    //             method: 'POST',
    //             body: JSON.stringify({
    //                 kod: form.BAR_KOD,
    //                 nomenId: form.NOMENID,
    //                 vrsta: form.VRSTABK,
    //                 sifraKorisnika: form.SIFKOR
    //             }),
    //             headers: {
    //                 'Authorization': `Bearer ${token}`,
    //                 'Content-type': 'application/json; charset=UTF-8'
    //             },
    //         })
    //             .then((response) => {
    //                 response.json()
    //                 setForm({
    //                     KOD: '',
    //                     VRSTABK: '',
    //                     SIFKOR: '',
    //                     NOMENID: '',
    //                 })
    //                 getBarKodData();
    //             })
    //     }

    //     if (form.KATBROJ.length > 0) {
    //         form.VRSTABK = 'K'

    //         fetch(API + `/barKod/new`, {
    //             method: 'POST',
    //             body: JSON.stringify({
    //                 kod: form.BAR_KOD,
    //                 nomenId: form.NOMENID,
    //                 vrsta: form.VRSTABK,
    //                 sifraKorisnika: form.SIFKOR
    //             }),
    //             headers: {
    //                 'Authorization': `Bearer ${token}`,
    //                 'Content-type': 'application/json; charset=UTF-8'
    //             },
    //         })
    //             .then((response) => {
    //                 response.json()
    //                 setForm({
    //                     KOD: '',
    //                     VRSTABK: '',
    //                     SIFKOR: '',
    //                     NOMENID: '',
    //                 })
    //                 getBarKodData();
    //             })
    //     }
    // }

    const onSubmitCjenovnik = () => {
        // form.NOMENID = form.ID
        fetch(API + `/cjenovnik/new`, {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                response.json()
                // setForm({
                //     NABCENA: '',
                //     PRODCENA: '',
                //     stavkaPronadjenaCjenovnik: true,
                // })
                form.NABCENA = ''
                form.PRODCENA = ''
                getCjenovnikData()
                repositoryRef.current.focus()
            })
    }

    const getCjenovnikData = () => {
        fetch(API + `/cjenovnik/readLagerCijena`, {
            method: 'POST',
            body: JSON.stringify({
                nomenId: form.NOMENID,
            }),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setCjenovnik(data)
            })
    }


    const getKomtData = () => {
        fetch(API + `/komitenti/all/${form.companyCode}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataKomt(data)
            })
    }

    const getPorezData = () => {
        fetch(API + `/porezi/all/`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataPorez(data)
            })
    }

    const getGrupaData = () => {
        fetch(API + `/grupa/all/`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataGrupa(data)
            })
    }

    const getPodgrupaData = () => {
        fetch(API + `/podgrupa/all/`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataPodgrupa(data)
            })
    }

    // prilikom pocetne inicijalizacije, cita mrezu
    useEffect(() => {
        // zaustavljanje fokusa
        const timeout = setTimeout(() => {
            getArtikalFirstFreeNumberSIFRA();
            getTableData();
        }, 100);
        return () => {
            clearTimeout(timeout);
        };
    }, []);

    // funkcija za brisanje koja se poziva klikom na dugme 'Delete'
    //  api za brisanje
    // u responsu osvjezava polja, daje prvi slobodan broj za sifru, fokus na polje SIFRAP, osvjezava mrezu  
    const onDelete = (ID) => {
        //  console.log(form, 'form')
        // provjera ovlascenja za brisanje 
        if (form.ovlrobno == 4) return setModalErrorAuthorizationOpen(true);

        fetch(API + '/artikal/delete/' + ID, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                response.json()
                // vracanje forme na pocetnu vrijednost
                setForm((prev) => ({
                    ...prev,
                    ...defaultFormRegistri,
                }));
                setClients(null)
                setTax(null)
                setGrupa(null)
                setPodgrupa(null)
                setBrend(null)
                setRepository(null)
                codeRef.current.focus()
                getTableData();
                getBarKodData();
                getCjenovnikData();
            })
    }

    // funkcija za brisanje koja se poziva klikom na dugme 'Delete'
    //  api za brisanje
    // u responsu osvjezava polja, daje prvi slobodan broj za sifru, fokus na polje sifra, osvjezava mrezu  
    const onDeleteBarKod = (kod, vrsta) => {
        // provjera ovlascenja za brisanje 
        if (form.ovlfakt == 4 || form.ovlfik == 4) return setModalErrorAuthorizationOpen(true)

        fetch(API + `/barKod/delete/${kod}/${vrsta}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                response.json()
                setForm((prev) => ({
                    ...prev,
                    KOD: '',
                    VRSTABK: ''
                }));
                // zaustavljanje fokusa(pauza za fokus dok ne odradi brisanje) 
                const timeout = setTimeout(() => {
                    //  kodRef.current.focus()
                    getBarKodData();
                }, 500);
                return () => {
                    clearTimeout(timeout);
                };
            })
    }

    // funkcija za brisanje koja se poziva klikom na dugme 'Delete'
    //  api za brisanje
    // u responsu osvjezava polja, daje prvi slobodan broj za sifru, fokus na polje sifra, osvjezava mrezu  
    const onDeleteCjenovnik = (sklaId, nomenId) => {
        // provjera ovlascenja za brisanje 
        if (form.ovlfakt == 4 || form.ovlfik == 4) return setModalErrorAuthorizationOpen(true)

        fetch(API + `/cjenovnik/delete/${sklaId}/${nomenId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                response.json()
                setForm((prev) => ({
                    ...prev,
                    NABCENA: '',
                    PRODCENA: ''
                }));
                // zaustavljanje fokusa(pauza za fokus dok ne odradi brisanje) 
                const timeout = setTimeout(() => {
                    //  kodRef.current.focus()
                    getCjenovnikData();
                }, 500);
                return () => {
                    clearTimeout(timeout);
                };
            })
    }

    // funkcija koja se poziva klikom na dugme Edit
    // postavlja fokus na polje NAZIV 
    // popunjava polja podacima iz mreze
    const onEdit = (item) => {
        nameRef.current.focus()
        setClients(null)
        setTax(null)
        setGrupa(null)
        setPodgrupa(null)
        setBrend(null)
        selectDataKomt.map((client) => {
            if (client.ID == item.UVOZNIKID) {
                setClients(client)
            }
        })
        selectDataPorez.map((tax) => {
            if (tax.ID == item.POREZID) {
                setTax(tax)
            }
        })
        selectDataGrupa.map((grupa) => {
            if (grupa.ID == item.GRUPAID) {
                setGrupa(grupa)
            }
        })

        selectDataPodgrupa.map((podgrupa) => {
            if (podgrupa.ID == item.PODGRUPAID) {
                setPodgrupa(podgrupa)
            }
        })

        selectDataBrend.map((brend) => {
            if (brend.SIFRA == item.BREND) {
                setBrend(brend)
            }
        })
        setForm(item);
        setItemUpdate(true)
        item.stavkaPronadjena = true;
        item.stavkaPronadjenaCjenovnik = false;
        form.NOMENID = item.ID
        getBarKodData();
        getCjenovnikData();

    }

    // funkcija koja se poziva klikom na dugme Edit
    // postavlja fokus na polje NAZIV 
    // popunjava polja podacima iz mreze
    const onEditCjenovnik = (item) => {
        if (!form.stavkaPronadjenaCjenovnik) {
            repositoryRef.current.focus();
        }
        //    console.log(item, 'item onEditCjenovnik')
        // setRepository(null)

        selectDataSkladiste.map((repository) => {
            if (repository.ID == item.SKLAID) {
                setRepository(repository)
            }
        })
        // setForm(item);
        setForm((prev) => ({
            ...prev,
            SKLAID: item.SKLAID,
            SIFRAS: item.Skl,
            NABCENA: item.NABCENA,
            PRODCENA: item.PRODCENA,
            //  NOMENID: item.ID,
            NOMENID: item.NOMENID,
            stavkaPronadjenaCjenovnik: true
        }));
        // item.stavkaPronadjenaCjenovnik = true;
    }

    // funkcija koja se poziva klikom na dugme Edit
    // postavlja fokus na polje NAZIV 
    // popunjava polja podacima iz mreze
    const onEditBarKod = (item) => {
        if (!showContent) {
            barKodRef.current.focus();
        }
        kodRef.current.focus();
        item.stavkaPronadjena = false;
        item.stavkaPronadjenaCjenovnik = false;
        setForm((prev) => ({
            ...prev,
            BAR_KOD: item.Kod,
            KOD: item.Kod,
            VRSTABK: item.Vrsta
        }));
    }

    // funkcija koja se poziva klikom na dugme 'Stampa'

    const onPrint = () => {
        setSpinner(true);
        if (artikal && artikal.length === 0) {
            setSpinner(false);
            setModalErrorOpenData(true);
        } else {
            // uslov preko koga saljemo naziv preduzeca sa frontenda na bekend
            // ako je objekat form popunjen, napuni polje NAZIV_PRED iz lokal storidza
            // poziva api za stampu, otvara izvjestaj(API + data.link)
            if (form) {
                form.NAZIV_PRED = form.companyName
                form.SIFRA_PRED = form.companyCode
            }
            fetch(API + `/artikal/print`, {
                method: 'POST',
                body: JSON.stringify(form),
                responseType: 'blob',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-type': 'application/json; charset=UTF-8'
                },
            })
                .then((response) => response.json())
                .then(data => {
                    if (typeof window != 'undefined') {
                        setTimeout(() => {
                            window.open(API + data.link)
                        }, 500);
                    }
                    setSpinner(false);
                })
        }
    }

    // funkcija koja se poziva klikom na dugme 'Stampa'
    const onPrintBarKod = () => {
        // uslov preko koga saljemo naziv preduzeca sa frontenda na bekend
        // ako je objekat form popunjen, napuni polje NAZIV_PRED iz lokal storidza
        // poziva api za stampu, otvara izvjestaj(API + data.link)
        setSpinner(true)
        if (barKod && barKod.length === 0) {
            setSpinner(false)
            setModalErrorOpenData(true)
        } else {
            if (form) {
                form.NAZIV_PRED = form.companyName
            }
            fetch(API + `/barKod/print`, {
                method: 'POST',
                body: JSON.stringify(form),
                responseType: 'blob',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-type': 'application/json; charset=UTF-8'
                },
            })
                .then((response) => response.json())
                .then(data => {
                    if (typeof window != 'undefined') {
                        setTimeout(() => {
                            window.open(API + data.link)
                        }, 200);
                    }
                    setSpinner(false)
                })
        }
    }
    // funkcija koja se poziva prilikom promjene vrijednosti u inputu
    const handleChange = (event) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name;

        setForm((prevState) => ({
            ...prevState,
            [name]: value
        }));

    };

    // funkcija za pronalazenje prvog slobodnog broja
    // poziva api
    // u responsu stejt setForm poziva funkciju za pronalazenje prethodne sifre(iz data) i na osnovu nje odredjuje koja je sljedeca prva slobodna SIFRAP
    // setFirstCode - stejt koji se koristi za razlikovanje izmjene i upisa 
    const getArtikalFirstFreeNumberSIFRA = () => {
        fetch(API + `/artikal/firstFreeNumberSifra`, {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setForm(prev => ({ ...prev, SIFRA: Number(data[0].SIFRA) }))
                // setfirstCode(Number(data))
            })
    }

    // klikom na dugme barkod, otvara se form za registar barkodova
    const barKodForm = () => {
        setShowContent(true);
        setForm((prev) => ({
            ...prev,
            BAR_KOD: '',
            VRSTABK: ''
        }));
    }

    const checkCodeRequired = (value) => {
        if (!value || value == 0) {
            setErrorMsgCode('Šifra je obavezna!')
            setErrorInputCode(true)
            codeRef.current.focus();
            return false
        } else {
            setErrorMsgCode(null)
            setErrorInputCode(false)
            return true
        }
    }

    // funkcija za validaciju obaveznog polja
    const checkNameRequired = (value) => {
        if (!value || value.length == 0) {
            setErrorMsgName('Naziv je obavezan!')
            setErrorInputName(true)
            nameRef.current.focus();
            return false
        } else {
            setErrorMsgName(null)
            setErrorInputName(false)
            return true
        }
    }

    const checkJMRequired = (value) => {
        if (!value || value.length == 0) {
            setErrorMsgJM('Jedinica mjere je obavezna!')
            setErrorInputJM(true)
            jmRef.current.focus();
            return false
        } else {
            setErrorMsgJM(null)
            setErrorInputJM(false)
            return true
        }
    }

    const checkTaxRequired = (value) => {
        if (!value || value.length == 0) {
            setErrorMsgTax('Porez mora biti izabran!')
            setErrorInputTax(true)
            taxRef.current.focus();
            return false;
        } else {
            setErrorMsgTax(null)
            setErrorInputTax(false)
            return true;
        }
    }

    const checkKodRequired = (value) => {
        if (!value || value == 0) {
            setErrorMsgKod('Bar-kod je obavezan!')
            setErrorInputKod(true)
            kodRef.current.focus();
            return false
        } else {
            setErrorMsgKod(null)
            setErrorInputKod(false)
            return true
        }
    }

    // funkcija za validaciju obaveznog polja
    const checkVrstaBKRequired = (value) => {
        if (!value || value.length == 0) {
            setErrorMsgVrstaBK('Vrsta bar koda je obavezna!')
            setErrorInputVrstaBK(true)
            vrstaBKRef.current.focus();
            return false
        } else {
            setErrorMsgVrstaBK(null)
            setErrorInputVrstaBK(false)
            return true
        }
    }

    // funkcije za provjeru obaveznih polja - skladiste
    const checkRepositoryRequired = (value) => {
        // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto) ili ako nije upisana ni vrijednost niti je odabrano skladiste, izbaci poruku, setuje crveni okvir i fokusira polje  
        // u suprotnom prolazi dalje
        if ( !value && !repository) {
            setForm((prev) => ({
                ...prev,
                ...defaultFormRegistri,
            }));
            codeRef.current.focus();
            return false
        } else {
            return true
        }
    }

    const checkBarKodFound = (value) => {
        if (value) onBarKodDuplikateFound();
        setTimeout(() => {
            if (value && form.SIFRAN !== 0 && !form.stavkaPronadjena) {
                setBarKodFound(true)
                setErrorMsgBarKod(`Uneseni bar-kod je već iskorišten za artikal: "${form.SIFRAN}" !`)
                barKodRef.current.focus();
                return false
            } else {
                setBarKodFound(false)
                setErrorMsgBarKod(null)
                return true
            }
          }, 100);
    }

    // funkcija za kontrolu obaveznih polja
    function unosIspravanDo(name) {
        if (name === 'SIFRA') {
            return true
        }

        if (!checkCodeRequired(form.SIFRA)) {
            return false
        }

        if (name === 'NAZIV') {
            return true
        }

        if (!checkNameRequired(form.NAZIV)) {
            return false
        }

        if (name === 'JM') {
            return true
        }

        if (!checkJMRequired(form.JM)) {
            return false
        }

        if (name === 'BAR_KOD') {
            return true
        }

        if (!checkBarKodFound(form.BAR_KOD)) {
            return false
        }

        if (name === 'PROIZVOD') {
            return true
        }

        if (name === 'OZNAKA') {
            return true
        }

        if (name === 'POREZID') {
            return true
        }

        if (!checkTaxRequired(form.POREZID)) {
            return false
        }

        if (name === 'KOMPAK') {
            return true
        }

        if (name === 'UVOZNIKID') {
            return true
        }

        if (name === 'FAB_BROJ') {
            return true
        }

        if (name === 'GRUPAID') {
            return true
        }

        if (name === 'KATBROJ') {
            return true
        }

        if (name === 'PODGRUPAID') {
            return true
        }

        if (name === 'AKCIZA') {
            return true
        }

        if (name === 'BREND') {
            return true
        }

        if (name === 'POLJEZAUPIS') {
            return true
        }

        if (name === 'SKLAID') {
            return true
        }

        if (!checkRepositoryRequired(form.SKLAID)) {
            return false
        }

        if (name === 'NABCENA') {
            return true
        }

        if (name === 'PRODCENA') {
            return true
        }

        if (name === 'POLJEZAUPISCIJENA') {
            return true
        }

        return true
    }

    // funkcija za kontrolu obaveznih polja na registru bar kodova
    function unosIspravanDoBarKod(name) {
        if (name === 'KOD') {
            return true
        }

        if (!checkKodRequired(form.KOD)) {
            return false
        }

        if (name === 'VRSTABK') {
            return true
        }

        if (!checkVrstaBKRequired(form.VRSTABK)) {
            return false
        }

        return true
    }

    useEffect(() => {
        getRepositoryData();
        getBrendData();
        getKomtData();
        getPorezData();
        getGrupaData();
        getPodgrupaData();
    }, [])


    // definisu se polja u tabeli i popunjavaju
    const columns = [
        {
            name: 'Šifra',
            selector: row => row.SIFRA,
            width:
                windowSize.width > 1920 ? '80px'
                    : windowSize.width > 1680 ? '100px'
                        : windowSize.width > 1600 ? '100px'
                            : windowSize.width > 1440 ? '100px'
                                : windowSize.width > 1280 ? '80px'
                                    : windowSize.width > 1024 ? '80px'
                                        : '80px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.SIFRA}</span>
                </>
            ),
        },
        {
            name: 'Naziv',
            selector: row => row.NAZIV,
            width:
                windowSize.width > 2048 ? '800px'
                    : windowSize.width > 1920 ? '770px'
                        : windowSize.width > 1680 ? '290px'
                            : windowSize.width > 1600 ? '260px'
                                : windowSize.width > 1440 ? '250px'
                                    : windowSize.width > 1280 ? '220px'
                                        : windowSize.width > 1024 ? '220px'
                                            : '200px',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.NAZIV}</span>
                </>
            ),
        },
        {
            name: 'JM',
            selector: row => row.JM,
            width:
                windowSize.width > 2048 ? '100px'
                    : windowSize.width > 1920 ? '100px'
                        : windowSize.width > 1680 ? '200px'
                            : windowSize.width > 1600 ? '140px'
                                : windowSize.width > 1440 ? '130px'
                                    : windowSize.width > 1280 ? '120px'
                                        : windowSize.width > 1024 ? '100px'
                                            : '100px',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.JM}</span>
                </>
            ),
        },
        {
            name: 'Porez',
            selector: row => row.PPROCENAT,
            width:
                windowSize.width > 2048 ? '100px'
                    : windowSize.width > 1920 ? '120px'
                        : windowSize.width > 1680 ? '240px'
                            : windowSize.width > 1600 ? '260px'
                                : windowSize.width > 1440 ? '250px'
                                    : windowSize.width > 1280 ? '200px'
                                        : windowSize.width > 1024 ? '230px'
                                            : '190px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.PPROCENAT}</span>
                </>
            ),
        },
        {
            name: 'Grupa',
            selector: row => row.GNAZIV,
            width:
                windowSize.width > 2048 ? '300px'
                    : windowSize.width > 1920 ? '450px'
                        : windowSize.width > 1680 ? '250px'
                            : windowSize.width > 1600 ? '240px'
                                : windowSize.width > 1440 ? '240px'
                                    : windowSize.width > 1280 ? '200px'
                                        : windowSize.width > 1024 ? '180px'
                                            : '180px',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.GNAZIV}</span>
                </>
            ),
        },
        {
            name: 'Action',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span onClick={() => onEdit(row)} id='media-query-table-buttons' className='btn btn-primary nav flex-column flex-lg-row justify-content-end'>Edit</span>{'     '}
                    <span onClick={() => { onEdit(row); codeRef.current.focus(); setModalDeleteItem(true) }} id='media-query-table-buttons' className='btn btn-danger nav flex-column flex-lg-row justify-content-end'>Brisanje</span>
                </>

            ),

        }
    ];

    // definisu se polja u tabeli i popunjavaju
    const columnsBarKod = [
        {
            name: 'Šifra',
            selector: row => row.Sifra,
            width:
                windowSize.width > 1920 ? '80px'
                    : windowSize.width > 1680 ? '100px'
                        : windowSize.width > 1600 ? '100px'
                            : windowSize.width > 1440 ? '100px'
                                : windowSize.width > 1280 ? '80px'
                                    : windowSize.width > 1024 ? '80px'
                                        : '80px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.Sifra}</span>
                </>
            ),
        },
        {
            name: 'Vrsta',
            selector: row => row.Vrsta,
            width:
                windowSize.width > 2048 ? '300px'
                    : windowSize.width > 1920 ? '770px'
                        : windowSize.width > 1680 ? '290px'
                            : windowSize.width > 1600 ? '260px'
                                : windowSize.width > 1440 ? '250px'
                                    : windowSize.width > 1280 ? '220px'
                                        : windowSize.width > 1024 ? '220px'
                                            : '200px',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.Vrsta}</span>
                </>
            ),
        },
        {
            name: 'Kod',
            selector: row => row.Kod,
            width:
                windowSize.width > 2048 ? '200px'
                    : windowSize.width > 1920 ? '100px'
                        : windowSize.width > 1680 ? '200px'
                            : windowSize.width > 1600 ? '140px'
                                : windowSize.width > 1440 ? '130px'
                                    : windowSize.width > 1280 ? '120px'
                                        : windowSize.width > 1024 ? '100px'
                                            : '100px',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.Kod}</span>
                </>
            ),
        },
        {
            name: 'Action',
            right: 'boolean',
            cell: (row) => showContent ? (
                <>
                    <span onClick={() => { onEditBarKod(row); setModalDeleteItemBarKod(true) }} id='media-query-table-buttons' className='btn btn-danger nav flex-column flex-lg-row justify-content-end'>Brisanje</span>
                </>
            ) : (
                <>
                    <span onClick={() => onEditBarKod(row)} id='media-query-table-buttons' className='btn btn-primary nav flex-column flex-lg-row justify-content-end'>Edit</span>{'     '}
                </>
            ),
        }
    ];

    // definisu se polja u tabeli i popunjavaju
    const columnsCjenovnik = [
        {
            name: 'Skl.',
            selector: row => row.Skl,
            width:
                windowSize.width > 1920 ? '100px'
                    : windowSize.width > 1680 ? '100px'
                        : windowSize.width > 1600 ? '100px'
                            : windowSize.width > 1440 ? '100px'
                                : windowSize.width > 1280 ? '80px'
                                    : windowSize.width > 1024 ? '80px'
                                        : '80px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.Skl}</span>
                </>
            ),
        },
        {
            name: 'Nab. cijena',
            selector: row => row.NABCENA,
            width:
                windowSize.width > 2048 ? '120px'
                    : windowSize.width > 1920 ? '770px'
                        : windowSize.width > 1680 ? '290px'
                            : windowSize.width > 1600 ? '260px'
                                : windowSize.width > 1440 ? '250px'
                                    : windowSize.width > 1280 ? '220px'
                                        : windowSize.width > 1024 ? '220px'
                                            : '200px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.NABCENA}</span>
                </>
            ),
        },
        {
            name: 'Prod. cijena',
            selector: row => row.PRODCENA,
            width:
                windowSize.width > 2048 ? '120px'
                    : windowSize.width > 1920 ? '100px'
                        : windowSize.width > 1680 ? '200px'
                            : windowSize.width > 1600 ? '140px'
                                : windowSize.width > 1440 ? '130px'
                                    : windowSize.width > 1280 ? '120px'
                                        : windowSize.width > 1024 ? '100px'
                                            : '100px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.PRODCENA}</span>
                </>
            ),
        },
        {
            name: 'Ulaz',
            selector: row => row.Ulaz,
            width:
                windowSize.width > 2048 ? '120px'
                    : windowSize.width > 1920 ? '120px'
                        : windowSize.width > 1680 ? '240px'
                            : windowSize.width > 1600 ? '260px'
                                : windowSize.width > 1440 ? '250px'
                                    : windowSize.width > 1280 ? '200px'
                                        : windowSize.width > 1024 ? '230px'
                                            : '190px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.Ulaz}</span>
                </>
            ),
        },
        {
            name: 'Izlaz',
            selector: row => row.Izlaz,
            width:
                windowSize.width > 2048 ? '120px'
                    : windowSize.width > 1920 ? '450px'
                        : windowSize.width > 1680 ? '250px'
                            : windowSize.width > 1600 ? '240px'
                                : windowSize.width > 1440 ? '240px'
                                    : windowSize.width > 1280 ? '200px'
                                        : windowSize.width > 1024 ? '180px'
                                            : '180px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.Izlaz}</span>
                </>
            ),
        },
        {
            name: 'Lager',
            selector: row => row.Lager,
            // sirina polja po rezolucijama
            width:
                windowSize.width > 2048 ? '110px'
                    : windowSize.width > 1920 ? '100px'
                        : windowSize.width > 1680 ? '250px'
                            : windowSize.width > 1600 ? '250px'
                                : windowSize.width > 1440 ? '200px'
                                    : windowSize.width > 1280 ? '190px'
                                        : windowSize.width > 1024 ? '140px'
                                            : '120px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.Lager}</span>
                </>
            ),
        },
        {
            name: 'Dat_z_Ul',
            selector: row => row.Dat_z_Ul ? moment(row.Dat_z_Ul).format('DD.MM.YYYY') : '',
            // sirina polja po rezolucijama
            width:
                windowSize.width > 2048 ? '140px'
                    : windowSize.width > 1920 ? '100px'
                        : windowSize.width > 1680 ? '250px'
                            : windowSize.width > 1600 ? '250px'
                                : windowSize.width > 1440 ? '200px'
                                    : windowSize.width > 1280 ? '190px'
                                        : windowSize.width > 1024 ? '140px'
                                            : '120px',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.Dat_z_Ul ? moment(row.Dat_z_Ul).format('DD.MM.YYYY') : ''}</span>
                </>
            ),
        },
        {
            name: 'Dat_z_Iz',
            selector: row => row.Dat_z_Iz ? moment(row.Dat_z_Iz).format('DD.MM.YYYY') : '',
            // sirina polja po rezolucijama
            width:
                windowSize.width > 2048 ? '140px'
                    : windowSize.width > 1920 ? '100px'
                        : windowSize.width > 1680 ? '250px'
                            : windowSize.width > 1600 ? '250px'
                                : windowSize.width > 1440 ? '200px'
                                    : windowSize.width > 1280 ? '190px'
                                        : windowSize.width > 1024 ? '140px'
                                            : '120px',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.Dat_z_Iz ? moment(row.Dat_z_Iz).format('DD.MM.YYYY') : ''}</span>
                </>
            ),
        },
        {
            name: 'Action',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span onClick={() => { onEditCjenovnik(row) }} id='media-query-table-buttons' className='btn btn-primary nav flex-column flex-lg-row justify-content-end'>Edit</span>{'     '}
                    <span onClick={() => { onEditCjenovnik(row); repositoryRef.current.focus(); setModalDeleteItemCjenovnik(true) }} id='media-query-table-buttons' className='btn btn-danger nav flex-column flex-lg-row justify-content-end'>Brisanje</span>
                </>

            ),

        }
    ];

    // propsovi za auto komplit  brend
    const defaultPropsSkladiste = {
        options: selectDataSkladiste,
        getOptionLabel: (option) => option ? `${option.SIFRA} ${option.NAZIV}` : '',
    };

    // propsovi za auto komplit  brend
    const defaultPropsBrend = {
        options: selectDataBrend,
        getOptionLabel: (option) => option ? `${option.SIFRA} ${option.NAZIV}` : '',
    };
    // propsovi za uvoznika 

    // propsovi za auto komplit  uvoznik
    const defaultPropsKomt = {
        options: selectDataKomt,
        getOptionLabel: (option) => option ? `${option.SIFRA} ${option.NAZIV}` : '',
    };

    // porez

    const defaultPropsPorez = {
        options: selectDataPorez,
        getOptionLabel: (option) => option ? `${option.SIFRA} ${option.NAZIV}` : '',
    };

    // grupa

    const defaultPropsGrupa = {
        options: selectDataGrupa,
        getOptionLabel: (option) => option ? `${option.SIFRA} ${option.NAZIV}` : '',
    };

    const defaultPropsPodgrupa = {
        options: selectDataPodgrupa,
        getOptionLabel: (option) => option ? `${option.SIFRA} ${option.NAZIV}` : '',
    };
    // funkcija za pretragu iz mreze
    // salje zahtjev na bekend prema upitu sa parametrima koje posaljemo
    // u responsu vraca podatke koje dobio iz upita 

    const searchChange = (e) => {
        e.preventDefault();
        const searchInput = e.target.value
        setSearchInput(searchInput);
        const fetchData = async () => {
            const res = await axios.get(`${API}/artikal/query/${form.companyCode}?q=${searchInput}`, { headers: { "Authorization": `Bearer ${token}` } });
            setartikal(res.data);
        };
        // uslov za za slanje zahtjeva za povlacenje podataka
        if (searchInput.length === 0 || searchInput.length >= 1) fetchData();

    };

    return (

        <div>
            {/* Kontejner za naslov  */}

            <div className="title-container">
                {/* Naziv registra */}
                <header className="title-header">
                    <h1 id="media-query-title">Artikal</h1>
                </header>

                <div className="naslov-ikonice">
                    <ul className="nav flex-column flex-lg-row justify-content-end">

                        <li className="nav-item toolbaritem">
                            <input type="image" alt="save" className='dugme-sacuvaj' title='Sačuvaj' tabIndex="70" src={require('./../assets/images/floppydisk.png')} onClick={onSubmit} />
                        </li>

                        <li className="nav-item toolbaritem">
                            <input type="image" alt="print" className='dugme-stampa' title='Štampa' tabIndex="71" src={require('./../assets/images/printer.png')} onClick={onPrint} />
                        </li>

                        <li className="nav-item toolbaritem">
                            <input type="image" alt="delete item" className='dugme-stampa' title='Brisanje stavke' tabIndex="21" src={require('./../assets/images/trash1.png')} onClick={setModalDeleteItem} />
                        </li>

                        <li className="nav-item toolbaritem">
                            <SVG src={barcodeIcon} className='barcode-icon' title='Bar Kod' tabIndex="72" onClick={barKodForm}></SVG>
                        </li>

                    </ul>
                </div>
            </div>
            {/* Kontejner pune sirine */}
            <Container fluid >
                <Row className="flex-lg-row">

                    <div className="col-12 col-lg-2 d-flex flex-column gap-1">
                        <div className="form-group row label-box-div">
                            <div className={errorInputCode ? 'form-group row inputWithError-columns' : 'form-group row label-box-div'} >
                                <label className="col-form-label col-lg-4 label-box" id="media-query-label-report">Šifra:</label>
                                <div className="col-4">
                                    <NumericFormat
                                        type="text"
                                        className='field-alignment p-1'
                                        id="media-query-input-text"
                                        tabIndex="1"
                                        maxLength="6"
                                        decimalScale={0}
                                        autoFocus={true}
                                        placeholder="šifra"
                                        name='SIFRA'
                                        // Postavlja stejt za fokus na true jer je potrebno zbog stepenaste kontrole
                                        value={form ? form.SIFRA : ''}
                                        getInputRef={codeRef}
                                        onFocus={() => {
                                            //  if(!form.stavkaPronadjenaCjenovnik)
                                            getArtikalFirstFreeNumberSIFRA();
                                            unosIspravanDo("SIFRA")
                                        }}
                                        // Ako je izmjena na polju
                                        onChange={(e) => handleChange(e)}
                                        onClick={() => {
                                            // ako je kliknuto na dugme Edit, a zatim na sifru - cisti polja  
                                            setForm(prev => ({
                                                ...prev, NAZIV: '',
                                                JM: '',
                                                KOMPAK: '',
                                                OZNAKA: '',
                                                UVOZNIKID: '',
                                                POREZID: '',
                                                BAR_KOD: '',
                                                GRUPAID: '',
                                                BREND: '',
                                                KATBROJ: '',
                                                FAB_BROJ: '',
                                                AKCIZA: '', stavkaPronadjena: false, stavkaPronadjenaCjenovnik: false
                                            }))
                                            setClients(null);
                                            setTax(null);
                                            setGrupa(null);
                                            setPodgrupa(null);
                                            setBrend(null);
                                            setBarKod(null)
                                            setCjenovnik(null)
                                        }} />
                                </div>
                                {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                                {errorMsgCode ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgCode}</span> : ''}
                            </div>

                            {/* Sirina polja */}

                            <div className={errorInputJM ? 'form-group row inputWithError-columns' : 'form-group row label-box-div'} >
                                <label className="col-form-label col-lg-4 label-box" id="media-query-label-report">JM:</label>
                                <div className="col-4">
                                    <input type="text"
                                        id="media-query-input-text"
                                        maxLength="3"
                                        tabIndex="3"
                                        placeholder="Jm"
                                        name='JM'
                                        value={form ? form.JM : ''}
                                        ref={jmRef}
                                        onFocus={() => {
                                            unosIspravanDo("JM")
                                        }}
                                        onChange={(e) => handleChange(e)} />
                                    {errorMsgJM ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgJM}</span> : ''}
                                </div>
                            </div>

                            <div className='form-group row label-box-div'>
                                <label className="col-form-label col-lg-4 label-box" id="media-query-label-report">Oznaka:</label>
                                <div className="col-4">
                                    <input type="text"
                                        id="media-query-input-text"
                                        maxLength="1"
                                        tabIndex="6"
                                        placeholder="Oznaka"
                                        name='OZNAKA'
                                        value={form ? form.OZNAKA : ''}
                                        // Prilikom prelaska na drugo polje, aktivira se stepenasta kontrola
                                        onFocus={() => {
                                            unosIspravanDo("OZNAKA")
                                        }}
                                        onChange={(e) => handleChange(e)} />
                                </div>
                            </div>

                            <div className='form-group row label-box-div'>
                                <label className="col-form-label col-lg-4 label-box" id="media-query-label-report">Kom/Pak:</label>
                                <div className="col-8">
                                    <NumericFormat
                                        type="text"
                                        className='field-alignment p-1'
                                        getInputRef={kompakRef}
                                        decimalScale={2}
                                        id="media-query-input-text"
                                        maxLength="9"
                                        tabIndex="8"
                                        placeholder="Kom/pak"
                                        name='KOMPAK'
                                        value={form ? form.KOMPAK : ''}
                                        // Prilikom prelaska na drugo polje, aktivira se stepenasta kontrola
                                        onFocus={() => {
                                            unosIspravanDo("KOMPAK")
                                        }}
                                        onChange={(e) => handleChange(e)} />
                                </div>
                            </div>

                            <div className='form-group row label-box-div'>
                                <label className="col-form-label col-lg-4 label-box" id="media-query-label-report">Fabr. broj:</label>
                                <div className="col-8">
                                    <input type="text" id="media-query-input-text" maxLength="20" tabIndex="10" placeholder="fabricki broj" name='FAB_BROJ' value={form ? form.FAB_BROJ : ''} onFocus={() => {
                                        unosIspravanDo("FAB_BROJ")
                                    }} onChange={(e) => handleChange(e)} />
                                </div>
                            </div>

                            <div className='form-group row label-box-div'>
                                <label className="col-form-label col-lg-4 label-box" id="media-query-label-report">Kat. broj:</label>
                                <div className="col-8">
                                    <input type="text" id="media-query-input-text" maxLength="20" tabIndex="12" placeholder="kat. broj" name='KATBROJ' value={form ? form.KATBROJ : ''}
                                        onFocus={() => {
                                            unosIspravanDo("KATBROJ")
                                        }} onChange={(e) => handleChange(e)} />
                                </div>
                            </div>

                            {/* Sirina polja */}
                            <div className='form-group row label-box-div'>
                                <label className="col-form-label col-lg-4 label-box" id="media-query-label-report">Akciza:</label>
                                <div className="col-4">
                                    <NumericFormat
                                        type="text"
                                        className='field-alignment p-1'
                                        getInputRef={akcizaRef}
                                        decimalScale={5}
                                        id="media-query-input-text"
                                        maxLength="13"
                                        tabIndex="14"
                                        placeholder="akciza"
                                        name='AKCIZA'
                                        value={form ? form.AKCIZA : ''}
                                        onFocus={() => {
                                            unosIspravanDo("AKCIZA")
                                        }} onChange={(e) => handleChange(e)} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-lg-4 d-flex flex-column gap-1">
                        <div className="form-group row label-box-div">
                            <div className={errorInputName ? 'form-group row inputWithError-columns' : 'form-group row label-box-div'} style={{ marginRight: '3px' }} >
                                <label className="col-form-label col-lg-2 label-box" id="media-query-label-report">Naziv:</label>
                                <div className="col-9">
                                    {/* Provjera da li je polje popunjeno jer je obavezno */}
                                    <input type="text"
                                        id="media-query-input-text"
                                        maxLength="100"
                                        tabIndex="2"
                                        autoComplete='off'
                                        placeholder="naziv"
                                        name='NAZIV'
                                        value={form ? form.NAZIV : ''}
                                        ref={nameRef}
                                        // stepenaste kontrole
                                        onFocus={() => {
                                            unosIspravanDo("NAZIV")
                                            // uslov za citanje sloga po sifri
                                            let selectedItem = []
                                            selectedItem = artikal.filter((c) => c.SIFRA == form.SIFRA)
                                         //   console.log(selectedItem[0], 'selectedItem[0]')
                                            if (selectedItem.length > 0) {
                                                onEdit(selectedItem[0])
                                                form.NOMENID = selectedItem[0].ID
                                            }
                                            getCjenovnikData();
                                        }}
                                        onBlur={() => {
                                               getBarKodData();
                                        }}
                                        // Ako je izmjena na polju
                                        onChange={(e) => handleChange(e)} />
                                </div>
                                {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                                {errorMsgName ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgName}</span> : ''}
                            </div>

                            <div className={barKodFound ? 'form-group row inputWithError-columns' : 'form-group row label-box-div'} >
                                {/* Provjera  polja i stilova */}
                                <label className="col-form-label col-lg-2 label-box" id="media-query-label-report">Barkod:</label>
                                <div className="col-3">
                                    {/* Provjera unosa polja i duzine  */}
                                    <input type="text"
                                        id="media-query-input-text"
                                        className="field-container"
                                        maxLength="15"
                                        ref={barKodRef}
                                        tabIndex="4"
                                        placeholder="barkod"
                                        name='BAR_KOD'
                                        value={form ? form.BAR_KOD : ''}
                                        onFocus={() => {
                                            unosIspravanDo("BAR_KOD")
                                        }}
                                        onChange={(e) => handleChange(e)} />
                                </div>

                                <label className="col-form-label col-lg-4 label-box-do" id="media-query-label-report">Proizvod/Usluga:</label>
                                <div className="col-2">
                                    {/* Provjera unosa polja i duzine  */}
                                    <input type="text" id="media-query-input-text" className="field-container" maxLength="15" ref={proizvodRef} tabIndex="5" placeholder="proizvod/Usluga" name='PROIZVOD' value={form ? form.PROIZVOD : ''} onFocus={() => {
                                       unosIspravanDo("PROIZVOD")

                                    }} onChange={(e) => handleChange(e)} />
                                </div>

                                {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                                {errorMsgBarKod ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgBarKod}</span> : ''}
                            </div>

                            <div className={errorInputTax ? 'form-group row inputWithError-columns' : 'form-group row label-box-div mb-3'}>
                                <label className="col-form-label col-lg-2 label-box" id="media-query-label-report">Porez:</label>
                                <div className="col-9">
                                    <Autocomplete
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        {...defaultPropsPorez}
                                        value={tax}
                                        disablePortal={true}
                                        onFocus={() => {
                                            unosIspravanDo("POREZID")
                                        }}
                                        onChange={(event, newValue) => {
                                            setForm(prev => ({ ...prev, POREZID: newValue && newValue != null ? newValue.ID : '' }))
                                            setTax(newValue);
                                            // fokus se mora vratiti inace tabIndex ne radi dobro
                                            taxRef.current.focus();
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params}
                                                className="clients-textinput"
                                                name='POREZID'
                                                ref={taxRef}
                                                tabIndex={7}
                                                placeholder='Porez'
                                                variant="standard" />
                                        )}
                                    />
                                    {errorMsgTax ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgTax}</span> : ''}
                                </div>
                            </div>

                            <div className='form-group row label-box-div mb-3' >
                                <label className="col-form-label col-lg-2 label-box" id="media-query-label-report">Uvoznik:</label>
                                <div className="col-9">
                                    {/* Ako je Autocomplete  poziva na odabri mjesta*/}
                                    <Autocomplete
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        {...defaultPropsKomt}
                                        value={clients}
                                        disablePortal={true}
                                        onFocus={() => {
                                            unosIspravanDo("UVOZNIKID")
                                        }}
                                        onChange={(event, newValue) => {
                                            setForm(prev => ({ ...prev, UVOZNIKID: newValue && newValue != null ? newValue.ID : '' }))
                                            setClients(newValue);
                                            // fokus se mora vratiti inace tabIndex ne radi dobro
                                            clientsRef.current.focus();
                                        }}
                                        renderInput={(params) => (
                                            <TextField id='media-query' {...params} className="clients-textinput" name='UVOZNIK' ref={clientsRef} tabIndex="9" placeholder=' izaberite uvoznika' variant="standard" />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className='form-group row label-box-div mb-3'>
                                <label className="col-form-label col-lg-2 label-box" id="media-query-label-report">Grupa:</label>
                                <div className="col-9">
                                    {/* Ako je Autocomplete  poziva na odabri mjesta*/}
                                    <Autocomplete
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        {...defaultPropsGrupa}
                                        value={grupa}
                                        disablePortal={true}
                                        onFocus={() => {
                                            unosIspravanDo("GRUPAID")
                                        }}
                                        onChange={(event, newValue) => {
                                            setForm(prev => ({ ...prev, GRUPAID: newValue && newValue != null ? newValue.ID : '' }))
                                            setGrupa(newValue);
                                            // fokus se mora vratiti inace tabIndex ne radi dobro
                                            grupaRef.current.focus();
                                        }}
                                        renderInput={(params) => (
                                            <TextField id='media-query' {...params}
                                                className="clients-textinput"
                                                name='GRUPA'
                                                ref={grupaRef}
                                                tabIndex="11"
                                                placeholder=' grupa'
                                                variant="standard" />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className='form-group row label-box-div mb-2'>
                                <label className="col-form-label col-lg-2 label-box" id="media-query-label-report">Podgrupa:</label>
                                <div className="col-9">
                                    {/* Ako je Autocomplete  poziva na odabri mjesta*/}
                                    <Autocomplete
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        {...defaultPropsPodgrupa}
                                        value={podgrupa}
                                        disablePortal={true}
                                        onFocus={() => {
                                            unosIspravanDo("PODGRUPAID")
                                        }}
                                        onChange={(event, newValue) => {
                                            setForm(prev => ({ ...prev, PODGRUPAID: newValue && newValue != null ? newValue.ID : '' }))
                                            setPodgrupa(newValue);
                                            // fokus se mora vratiti inace tabIndex ne radi dobro
                                            podgrupaRef.current.focus();
                                        }}
                                        renderInput={(params) => (
                                            <TextField id='media-query' {...params}
                                                className="clients-textinput"
                                                name='PODGRUPAID'
                                                ref={podgrupaRef}
                                                tabIndex="13"
                                                placeholder='podgrupa'
                                                variant="standard" />
                                        )}
                                    />
                                </div>
                            </div>

                            <div className='form-group row label-box-div mb-3'>
                                <label className="col-form-label col-lg-2 label-box" id="media-query-label-report">Brend:</label>
                                <div className="col-9">
                                    {/* Ako je Autocomplete  poziva na odabri mjesta*/}
                                    <Autocomplete
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        {...defaultPropsBrend}
                                        value={brend}
                                        disablePortal={true}
                                        onFocus={() => {
                                            unosIspravanDo("BREND")
                                        }}
                                        onChange={(event, newValue) => {
                                            setForm(prev => ({ ...prev, BREND: newValue && newValue != null ? newValue.SIFRA : '' }))
                                            setBrend(newValue);
                                            // fokus se mora vratiti inace tabIndex ne radi dobro
                                            brendRef.current.focus();
                                        }}
                                        renderInput={(params) => (
                                            <TextField id='media-query' {...params}
                                                className="clients-textinput"
                                                name='BREND'
                                                ref={brendRef}
                                                tabIndex="15"
                                                placeholder=' izaberite brend'
                                                variant="standard" />
                                        )}
                                    />
                                </div>

                                <div className="col-1">
                                    <input type="text" className='submitField' tabIndex="16" name='POLJEZAUPIS'
                                        onFocus={() => {
                                            unosIspravanDo("POLJEZAUPIS")
                                            // if (form.stavkaPronadjenaCjenovnik == false) {
                                            onSubmit();
                                            setTimeout(() => {
                                                onSubmitBARKOD();
                                                onSubmitKATBROJ();
                                                form.stavkaPronadjena = true;
                                            }, 500);
                                            // }
                                            repositoryRef.current.focus();
                                        }}
                                        onChange={(e) => handleChange(e)} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-lg-6 d-flex flex-column gap-1">
                        <div className="form-group row label-box-div">
                            <label className="col-form-label col-lg-2 label-box" id="media-query-label-report">Skladište:</label>
                            <div className="col-8">
                                <Autocomplete
                                    //ukoliko nema ove funkcije, u konzoli izbacuje upozorenje da nije vrijednost jedinstvena
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    {...defaultPropsSkladiste}
                                    value={repository}
                                    disablePortal={true}
                                    onFocus={() => {
                                        unosIspravanDo("SKLAID")

                                        // if (form.stavkaPronadjenaCjenovnik == false) {
                                        //     onSubmit();
                                        //     // if (onSubmit) {
                                        //     //     form.stavkaPronadjena = true;
                                        //     // }
                                        // }
                                        // setTimeout(() => {
                                        //     onSubmitBarKodData()
                                        // }, 200);
                                    }}
                                    // ako se promijeni skladiste, uzima podatke za nov izabran0 skladiste
                                    onChange={(event, newValue) => {
                                        setForm(prev => ({ ...prev, SKLAID: newValue && newValue != '' ? newValue.ID : '', SIFRAS: newValue && newValue != '' ? newValue.SIFRA : '', NAZIV_SKLADISTA: newValue && newValue != '' ? newValue.NAZIV : '' }))
                                        setRepository(newValue);
                                        // fokus se mora vratiti inace tabIndex ne radi dobro
                                        repositoryRef.current.focus()
                                    }}

                                    renderInput={(params) => (
                                        <TextField {...params}
                                            className="clients-textinput"
                                            tabIndex="17"
                                            name='SKLAID'
                                            ref={repositoryRef}
                                            placeholder=' Izaberite skladište'
                                            variant="standard" />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="form-group row label-box-div">
                            {/* Provjera  polja i stilova */}
                            <label className="col-form-label col-lg-2 label-box" id="media-query-label-report">Nab. cijena:</label>
                            <div className="col-2">
                                {/* Provjera unosa polja i duzine  */}
                                <NumericFormat type="text" id="media-query-input-text" customInput={itemUpdate?.TextField} className='field-alignment p-1' maxLength="18" getInputRef={nabcenaRef} tabIndex="18" placeholder="nab.cijena" name='NABCENA' value={form ? form.NABCENA : ''} onFocus={() => {
                                    unosIspravanDo("NABCENA")
                                    let selectedItem = []
                                    selectedItem = cjenovnik.filter((c) => c.SKLAID == form.SKLAID)
                                    if (selectedItem.length > 0) {
                                        onEditCjenovnik(selectedItem[0])
                                    } else {
                                        //  form.stavkaPronadjenaCjenovnik = false;
                                        //  ukoliko nije izmjena cijena, ciste se  polja
                                        setForm(prev => ({
                                            ...prev, NABCENA: '', PRODCENA: '', stavkaPronadjenaCjenovnik: false
                                        }))
                                    }

                                }} onChange={(e) => handleChange(e)} />
                            </div>

                            <label className="col-form-label col-lg-4 label-box-do" id="media-query-label-report">Prod. cijena:</label>
                            <div className="col-2">
                                {/* Provjera unosa polja i duzine  */}
                                <NumericFormat type="text" id="media-query-input-text" customInput={itemUpdate?.TextField} className='field-alignment p-1' maxLength="19" getInputRef={prodcenaRef} tabIndex="19" placeholder="prod.cijena" name='PRODCENA' value={form ? form.PRODCENA : ''} onFocus={() => {
                                    unosIspravanDo("PRODCENA")
                                }}
                                    onChange={(e) => handleChange(e)} />
                            </div>
                            <div className="col-1">

                                <input type="text" className="submitField" name='POLJEZAUPISCIJENA' tabIndex="20" onFocus={() => {
                                    unosIspravanDo("POLJEZAUPISCIJENA")
                                    onSubmitCjenovnik();

                                }}
                                    onChange={(e) => handleChange(e)} />
                            </div>
                        </div>


                        {/* <Container fluid> */}
                        <Row className="flex-lg-row">
                            <div className="col-12 col-lg-12 d-flex flex-lg-row flex-column pe-0 ps-0">
                                <div className="col-12">
                                    {/* Mreza */}
                                    <DataTable
                                        columns={columnsCjenovnik}
                                        data={cjenovnik ? cjenovnik : []}
                                        pagination
                                        responsive
                                        highlightOnHover
                                        striped
                                        dense
                                        pointerOnHover
                                        paginationComponentOptions={defaultComponentOptions}
                                        paginationPerPage={[windowSize.height > 1300 ? 25
                                            : windowSize.height > 1270 ? 3
                                                : windowSize.height > 1246 ? 24
                                                    : windowSize.height > 1222 ? 23
                                                        : windowSize.height > 1200 ? 23
                                                            : windowSize.height > 1000 ? 19
                                                                : windowSize.height > 920 ? 16
                                                                    : windowSize.height > 900 ? 15
                                                                        : windowSize.height > 880 ? 14
                                                                            : windowSize.height > 820 ? 13
                                                                                : windowSize.height > 720 ? 11
                                                                                    : 4]}
                                        progressPending={pending}
                                    />

                                </div>
                            </div>
                        </Row>
                        {/* </Container> */}
                    </div>
                </Row>

            </Container >

            {/* Stilovi i klase za polje za pretragu;  */}
            {/* < div style={{ display: 'flex' }} className='search' >
                {/* vrsta inputa; poziva funkciju za pretragu(searchChange);  */}
            {/* <input type='text' id="media-query-input-text" placeholder='Pretraga...' onChange={(e) => {
                    // pretraga mreze(ukoliko je unesen bilo koji karakter, vrsi pretragu)
                    //   console.log(searchInput, 'searchInput')
                    if (searchInput.length >= 0) {
                        searchChange(e)
                    } else {
                        getTableData()
                    }
                }} value={searchInput} /> */}
            {/* </div > */}

            <Container fluid>
                <Row className="flex-lg-row">
                    <div className="col-12 col-lg-8 d-flex flex-column pe-0 ps-0">
                        <div className="col-12">
                            {/* Mreza */}
                            <DataTable
                                columns={columns}
                                data={artikal ? artikal : []}
                                pagination
                                responsive
                                highlightOnHover
                                striped
                                dense
                                pointerOnHover
                                paginationComponentOptions={defaultComponentOptions}
                                paginationPerPage={[windowSize.height > 1300 ? 25
                                    : windowSize.height > 1270 ? 20
                                        : windowSize.height > 1246 ? 24
                                            : windowSize.height > 1222 ? 23
                                                : windowSize.height > 1200 ? 23
                                                    : windowSize.height > 1000 ? 19
                                                        : windowSize.height > 920 ? 16
                                                            : windowSize.height > 900 ? 15
                                                                : windowSize.height > 880 ? 14
                                                                    : windowSize.height > 820 ? 13
                                                                        : windowSize.height > 720 ? 11
                                                                            : 4]}
                                progressPending={pending}
                            />

                        </div>
                    </div>
                    <div className="col-12 col-lg-4 d-flex flex-column-row pe-0">
                        <div className="col-12">
                            {/* Mreza */}
                            <DataTable
                                columns={columnsBarKod}
                                data={barKod ? barKod : []}
                                pagination
                                responsive
                                highlightOnHover
                                striped
                                dense
                                pointerOnHover
                                paginationComponentOptions={defaultComponentOptions}
                                paginationPerPage={[windowSize.height > 1300 ? 25
                                    : windowSize.height > 1270 ? 20
                                        : windowSize.height > 1246 ? 24
                                            : windowSize.height > 1222 ? 23
                                                : windowSize.height > 1200 ? 23
                                                    : windowSize.height > 1000 ? 19
                                                        : windowSize.height > 920 ? 16
                                                            : windowSize.height > 900 ? 15
                                                                : windowSize.height > 880 ? 14
                                                                    : windowSize.height > 820 ? 13
                                                                        : windowSize.height > 720 ? 11
                                                                            : 4]}
                                progressPending={pending}
                            />
                        </div>
                    </div>
                </Row>
            </Container>

            {/* Modalni form za gresku; prikazuje se ako nisu unijeta obavezna polja */}
            <div>
                <Modal isOpen={Boolean(modalErrorOpenData)} centered  >
                    <ModalHeader>Nema podataka!</ModalHeader>
                    <ModalFooter className="justify-content-center">
                        <div>
                            {/* Dugme za zatvaranje modalnog forma */}
                            <button onClick={() => setModalErrorOpenData(false)} className="button-yes">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>


            {/* render za poruku */}
            <div>
                <Modal isOpen={Boolean(modalDeleteItem)} centered>
                    <ModalHeader>Brisanje stavke</ModalHeader>
                    <ModalBody>
                        <span>{`Da li želite da obrišete artikal "${form.NAZIV}" ? `}</span>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            <button
                                onClick={() => { setModalDeleteItem(false); nameRef.current.focus() }}
                                className="button-no">Ne
                            </button>
                            <button
                                onClick={() => {
                                    //    console.log(artikalPromet, 'artikalPromet', artikalPredracun, 'artikalPredracun')
                                    checkNomenPromets()
                                    //  checkNomenPredracs()
                                    //    console.log(form.NOMENIDPREDRACUN, 'form.NOMENIDPREDRACUN', form.NOMENIDPROMETS, 'form.NOMENIDPROMETS')
                                    //   console.log(form.NOMENIDPREDRACUN == 0 || form.NOMENIDPROMETS == 0, 'form.NOMENIDPREDRACUN == 0 || form.NOMENIDPROMETS == 0')
                                    // if (form.NOMENIDPREDRACUN == 0 || form.NOMENIDPROMETS == 0) {
                                    //     console.log('upada u if')
                                    //     //  setModalNomenExists(true)
                                    // }
                                    setModalDeleteItem(false);
                                }}
                                className="button-yes">Da
                            </button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            {/* render za poruku */}
            <div>
                <Modal isOpen={Boolean(modalNomenExists)} centered>
                    <ModalHeader>Brisanje stavke</ModalHeader>
                    <ModalBody>
                        <span>{`Ne možete brisati artikal koji postoji u prometu ili predračunu!`}</span>
                    </ModalBody>
                    <ModalFooter className="justify-content-center">
                        <div>
                            <button
                                onClick={() => { setModalNomenExists(false); nameRef.current.focus() }}
                                className="button-yes">OK
                            </button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            {/* render za poruku */}
            <div>
                <Modal isOpen={Boolean(modalDeleteItemBarKod)} centered>
                    <ModalHeader>Brisanje stavke</ModalHeader>
                    <ModalBody>
                        <span>{`Da li želite da obrišete bar-kod ${form.KOD} ? `}</span>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            <button
                                onClick={() => { setModalDeleteItemBarKod(false); }}
                                className="button-no">Ne
                            </button>
                            <button
                                onClick={() => {
                                    onDeleteBarKod(form.KOD, form.VRSTABK);
                                    setModalDeleteItemBarKod(false);
                                }}
                                className="button-yes">Da
                            </button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            {/* render za poruku */}
            <div>
                <Modal isOpen={Boolean(modalDeleteItemCjenovnik)} centered>
                    <ModalHeader>Brisanje stavke</ModalHeader>
                    <ModalBody>
                        <span>{`Da li želite da obrišete cijene za artikal "${form.NAZIV}" u skladištu "${form.SIFRAS}" ? `}</span>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            <button
                                onClick={() => { setModalDeleteItemCjenovnik(false); repositoryRef.current.focus() }}
                                className="button-no">Ne
                            </button>
                            <button
                                onClick={() => {
                                    onDeleteCjenovnik(form.SKLAID, form.NOMENID)
                                    setModalDeleteItemCjenovnik(false);
                                }}
                                className="button-yes">Da
                            </button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            {/* render za poruku */}
            <div>
                <Modal isOpen={Boolean(modalErrorAuthorizationData)} centered  >
                    <ModalHeader>Upozorenje!</ModalHeader>
                    <ModalBody>
                        <span>{`Nemate ovlašćenje za brisanje!`}</span>
                    </ModalBody>
                    <ModalFooter className="justify-content-center">
                        <div>
                            <button onClick={() => setModalErrorAuthorizationOpen(false)} className="button-yes">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            <div>
                {spinner ? <Spinner className='spinner' color="primary" id='loader' /> : ''}
            </div>

            {showContent && (
                <div className='smallForm'>
                    <form className='form'>
                        <Container fluid>
                            <Row>

                                {/* Kontejner za naslov  */}
                                <div className="title-container">

                                    {/* Naziv registra */}
                                    <header className="title-header">
                                        <h2 id="media-query-title">Bar kod</h2>
                                    </header>

                                    {/* Klasa za ikonice, nalazi se u istom redu kao i naziv, ikonice su formirane kao lista */}
                                    <div className="naslov-ikonice">
                                        <ul className="nav flex-column flex-lg-row justify-content-end">

                                            <li className="nav-item toolbaritem">
                                                <input type="image" alt="save" className='dugme-sacuvaj' title='Sačuvaj' tabIndex="52" src={require('./../assets/images/floppydisk.png')} onFocus={() => onSubmitBarKod()} />
                                            </li>

                                            <li className="nav-item toolbaritem">
                                                <input type="image" alt="print" className='dugme-stampa' title='Štampa' tabIndex="53" src={require('./../assets/images/printer.png')} onClick={onPrintBarKod} />
                                            </li>

                                            {/* <li className="nav-item toolbaritem">
                                                <input type="image" alt="delete item" className='dugme-stampa' title='Brisanje stavke' tabIndex="54" src={require('./../assets/images/trash1.png')} onClick={itemUpdate ? setModalDeleteItemBarKod : setModalErrorDeleteItemOpen} />
                                            </li> */}

                                            <li className="nav-item toolbaritem">
                                                <SVG src={closeIcon} alt="exit" className='barcode-icon' title='Izlaz' tabIndex="55" onClick={() => setShowContent(false)}></SVG>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                {/* Kontejner pune sirine */}
                                <Container fluid>
                                    <Row className="flex-lg-row">
                                        {/* Stilovi koji ce se primjenjivati na svim poljima */}
                                        <div className="col-12 col-lg-6 d-flex flex-column gap-1">
                                            <div className="form-group row label-box-div">
                                                {/* Provjera da li je polje popunjeno jer je obavezno */}
                                                <div className={errorInputKod ? 'form-group row inputWithError-columns' : 'form-group row label-box-div'} >
                                                    <label className="col-form-label col-lg-4 label-box" id="media-query-label-report">Bar kod:</label>
                                                    <div className="col-4">
                                                        <NumericFormat
                                                            type="text"
                                                            className='field-alignment p-1'
                                                            getInputRef={kodRef}
                                                            id="media-query-input-text"
                                                            maxLength="10"
                                                            tabIndex="50"
                                                            decimalScale={0}
                                                            autoFocus={true}
                                                            placeholder="Šifra"
                                                            name='KOD'
                                                            value={form?.KOD}
                                                            onFocus={() => {
                                                                unosIspravanDoBarKod("KOD")
                                                            }}
                                                            // Ako je izmjena na polju
                                                            onChange={(e) => handleChange(e)}
                                                            onClick={() => {
                                                                // klikom na polje sifra, ciste se ostala polja
                                                                setForm(prev => ({
                                                                    ...prev, VRSTABK: '', stavkaPronadjena: false
                                                                }))
                                                            }} />
                                                    </div>
                                                    {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                                                    {errorMsgKod ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgKod}</span> : ''}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6 d-flex flex-column gap-1">
                                            <div className="form-group row label-box-div">

                                                {/* Provjera da li je polje popunjeno jer je obavezno */}
                                                <div className={errorInputVrstaBK ? 'form-group row inputWithError-columns' : 'form-group row label-box-div'} >
                                                    <label className="col-form-label col-lg-3 label-box" id="media-query-label-report">Vrsta:</label>
                                                    <div className="col-3">
                                                        <select
                                                            id="media-query-input-text"
                                                            value={form ? form.VRSTABK : ''}
                                                            name='VRSTABK'
                                                            className='select-style'
                                                            placeholder="Naziv"
                                                            ref={vrstaBKRef}
                                                            onFocus={() => {
                                                                unosIspravanDoBarKod("VRSTABK")
                                                            }}
                                                            // Ako je izmjena na polju
                                                            onChange={(e) => handleChange(e)}
                                                            tabIndex="51">
                                                            <option value={''}>{''}</option>
                                                            <option value={'B'}>{'Bar-kod'}</option>
                                                            <option value={'K'}>{'Kat. broj'}</option>
                                                        </select>
                                                    </div>
                                                    {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                                                    {errorMsgVrstaBK ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgVrstaBK}</span> : ''}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group row label-box-div">
                                            <label className="col-form-label col-lg-2 label-box" id="media-query-label-report">Artikal:</label>
                                            <div className="col-1 me-1 pe-0">
                                                <NumericFormat
                                                    type="text"
                                                    className='field-alignment p-1 rounded-start rounded-end'
                                                    getInputRef={codeNRef}
                                                    id="media-query-input-text"
                                                    maxLength="10"
                                                    tabIndex="1"
                                                    decimalScale={0}
                                                    disabled={true}
                                                    placeholder="Šifra"
                                                    name='SIFRA'
                                                    value={form?.SIFRA}
                                                    onFocus={() => {
                                                        unosIspravanDo("SIFRA")
                                                    }}
                                                    // Ako je izmjena na polju
                                                    onChange={(e) => handleChange(e)}
                                                />
                                            </div>

                                            <div className="col-6 ms-0 ps-0">
                                                <input type="text"
                                                    className='rounded-start rounded-end'
                                                    id="media-query-input-text"
                                                    ref={nameNRef}
                                                    maxLength="100"
                                                    tabIndex="2"
                                                    disabled={true}
                                                    placeholder="Naziv"
                                                    name='NAZIV'
                                                    value={form?.NAZIV}
                                                    onFocus={() => {
                                                        unosIspravanDo("NAZIV")
                                                        // uslov za citanje sloga po sifri
                                                        if (unosIspravanDo("NAZIV")) {
                                                        }
                                                    }}
                                                    // Ako je izmjena na polju
                                                    onChange={(e) => {
                                                        handleChange(e)
                                                    }
                                                    } />
                                            </div>

                                        </div>
                                    </Row>
                                </Container>

                                {/* Stilovi i klase za polje za pretragu;  */}
                                <div style={{ display: 'flex' }} className='search'>
                                    {/* vrsta inputa; poziva funkciju za pretragu(searchChange);  */}
                                    <input type='text' id="media-query-input-text" placeholder='Pretraga...'
                                        onChange={(e) => {
                                            // pretraga mreze(ukoliko je unesen bilo koji karakter, vrsi pretragu)
                                            if (searchInput.length >= 0) {
                                                searchChange(e)
                                            } else {
                                                getTableData()
                                            }
                                        }}
                                        value={searchInput} />
                                </div>

                                {/* Mreza */}
                                <DataTable
                                    columns={columnsBarKod}
                                    data={barKod ? barKod : []}
                                    pagination
                                    responsive
                                    highlightOnHover
                                    striped
                                    dense
                                    pointerOnHover
                                    paginationComponentOptions={defaultComponentOptions}
                                    paginationPerPage={[windowSize.height > 1300 ? 28
                                        : windowSize.height > 1270 ? 26
                                            : windowSize.height > 1222 ? 25
                                                : windowSize.height > 1200 ? 24
                                                    : windowSize.height > 1000 ? 20
                                                        : windowSize.height > 920 ? 17
                                                            : windowSize.height > 900 ? 16
                                                                : windowSize.height > 880 ? 15
                                                                    : windowSize.height > 820 ? 14
                                                                        : windowSize.height > 720 ? 13
                                                                            : 4]}
                                    progressPending={pending}

                                />
                            </Row>
                        </Container>
                    </form>
                </div >
            )}
        </div >

    );

}

export default Artikal;


