import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import DataTable from 'react-data-table-component';
import "react-datepicker/dist/react-datepicker.css";
import { API } from '../../constants';
import { RadioButton } from '../radioButton';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Spinner } from "reactstrap";
import { numberWithCommas, numberWithCommasColumns, roundTO, getCompanyData, defaultComponentOptions } from '../utilities';
import { useWindowSize } from '../useWindowSize';

function AnalitikaPoKomitentimaIKontima() {
    const windowSize = useWindowSize();
    const [analitikaPoKomitentimaIKontima, setAnalitikaPoKomitentimaIKontima] = useState(null);
    const [sum, setSum] = useState(0)
    const [sumduguje, setSumDuguje] = useState(false)
    const [sumpotraz, setSumPotraz] = useState(false)
    const [clients, setClients] = useState(null);
    const [konto, setKonto] = useState(null);
    const [selectDataClients, setSelectDataClients] = useState([]);
    const [selectDataKonto, setSelectDataKonto] = useState([]);
    const [spinner, setSpinner] = useState(false);
    const [modalErrorOpenPrint, setModalErrorOpenPrint] = useState(false);
    const [pending, setPending] = React.useState(false);

    // stejtovi za spinere na dugmadima
    const [faSpinAKomKont, setFaSpinAKomKont] = useState(false);
    const [faSpinAKontKomt, setFaSpinAKontKomt] = useState(false);
    const [faSpinTKomtKont, setFaSpinTKomtKont] = useState(false);
    const [faSpinTKontKomt, setFaSpinTKontKomt] = useState(false);

    const [errorDateFrom, setErrorDateFrom] = useState(false);
    const [errorDateTo, setErrorDateTo] = useState(false);

    const [errorMsgDateFrom, setErrorMsgDateFrom] = useState(false);
    const [errorMsgDateTo, setErrorMsgDateTo] = useState(false);

    const komtRef = useRef();
    const kontRef = useRef();
    const datumOdRef = useRef();
    const datumDoRef = useRef();

    const [date, setDate] = useState({
        checkDatDok: true,
        checkDatNalog: false
    });

    const [form, setForm] = useState({
        KOMTID: '',
        KONTID: '',
        DATUMOD: '',
        DATUMDO: '',
        PROVJERA: '',
        buttonType: '',
    })

    // funkcija za povlacenje podataka o preduzecu
    getCompanyData(form)

    // funkcija za popunjavanje datumskih polja u zavisnosti od godine
    const checkCompanyYear = () => {
        //  let dateFrom
        //  let dateTo
        let currentDate = new Date()
        if (currentDate.getFullYear() === Number(form.companyYear)) {
            setForm(prev => ({ ...prev, DATUMOD: moment.unix(new Date(currentDate.getFullYear(), 0, 1).getTime() / 1000).format("DD.MM.YYYY"), DATUMDO: moment.unix(new Date().getTime() / 1000).format("DD.MM.YYYY") }))
        } else {
            let oldYear = new Date()
            oldYear = new Date(Number(oldYear.setFullYear(form.companyYear)))
            setForm(prev => ({ ...prev, DATUMOD: moment.unix(new Date(oldYear.getFullYear(), 0, 1).getTime() / 1000).format("DD.MM.YYYY"), DATUMDO: moment.unix(new Date(Number(oldYear.setFullYear(form.companyYear))).getTime() / 1000).format("DD.MM.YYYY") }))
        }
    }

    // funkcija koja se poziva pri prvoj inicijalizaciji
    // api za mrezu
    // u responsu puni mrezi
    const getReports = () => {
        if (form) {
            // form.DATUMOD = startDate
            // form.DATUMDO = endDate
            form.KOMTID = clients && clients.ID ? clients.ID : null;
            form.KONTID = konto && konto.ID ? konto.ID : null;
            //    form.buttonType = buttonType
            //    form.buttonTypeSin = buttonTypeSin
            form.checkDatDok = date.checkDatDok
        }

        fetch(API + `/analitikaPoKomitentimaIKontimaMreza/all/${form.companyCode}`, {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                'Authorization': `Bearer ${form.token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setAnalitikaPoKomitentimaIKontima(data.items)
                let DUGUJESum = 0;
                let POTRAZSum = 0;
                let SALDOSum = 0;
                // petlja za citanje podataka
                for (let i = 0; i < data.items.length; i++) {
                    DUGUJESum += data.items[i].DUGUJE
                    POTRAZSum += data.items[i].POTRAZ
                    SALDOSum += (data.items[i].DUGUJE) - (data.items[i].POTRAZ)
                }
                setSum(numberWithCommasColumns(roundTO(SALDOSum, 2)))
                setSumDuguje(numberWithCommasColumns(roundTO(DUGUJESum, 2)))
                setSumPotraz(numberWithCommasColumns(roundTO(POTRAZSum, 2)))
                setPending(false)
            })
    }

    const print = () => {
        setSpinner(true)
        // uslov: ako nije izabran datum od datum do i ako nije izabran izvjestaj(nije kliknuto na dugme za izvjestaj), nece otvoriti izvjestaj 
        if (!unosIspravanDo('PRINT')) return setSpinner(false)
        if (form.buttonType === "") return setSpinner(false), setModalErrorOpenPrint(true)
        //filter
        if (form) {
            form.NAZIV_PRED = form.companyName;
            form.KOMTID = clients && clients.ID ? clients.ID : null;
            form.KONTID = konto && konto.ID ? konto.ID : null;
            form.KONTO = konto && konto.KONTO ? konto.KONTO : null;
            form.KSIFRA = clients && clients.SIFRA ? clients.SIFRA : null;
            form.checkDatDok = date.checkDatDok;
        }

        // fetch(API + `/prikazPoVrstiKnjizenja/print/${companyCode}`, {
        //     method: 'POST',
        //     body: JSON.stringify(form),
        //     headers: {
        //         'Authorization': `Bearer ${token}`,
        //         'Content-type': 'application/json; charset=UTF-8'
        //     },
        // })
        //     .then((response) => response.json())
        //     .then(data => {

        //         console.log(API, 'API', data, 'data')
        //         // if (data && data.items && data.items.length !== 0) {
        //         if (typeof window != 'undefined') {
        //             setTimeout(() => {
        //                 window.open(API + data.link)
        //             }, 500);

        //         }

        //         // } else {
        //         //     setModalErrorOpenData(true)
        //         // }
        //         // zatvaranje spinera
        //         setSpinner(false)
        //     })

        //    fetch(API + `/` + buttonType + `/print`, {



        // fetch za crtanje izvjestaja
        // u bodiju saljemo sql koji je vec pokupio i form
        fetch(API + `/` + form.buttonType + `/print/${form.companyCode}`, {
            method: 'POST',
            body: JSON.stringify({ form }),
            responseType: 'blob',
            headers: {
                'Authorization': `Bearer ${form.token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                window.open(API + data.link)
                setSpinner(false)
                // treba prebaciti fetch na dugme
                //                       let link = API + data.link;
                //                      res.status(200).send({ link })
                // console.log(report, 'report')
                //   console.log(data, 'data')
                //   console.log(API, 'API', data.link, 'data.link')

                // let link = '/uploads/' + name
                // res.status(200).send({ link })
            })


        //   fetch(API + `/pregledPoVrstiIKomitentu/print/${companyCode}`, {

        //             fetch(API + `/prikazPoVrstiKnjizenja/print/${companyCode}`, {
        //             method: 'POST',
        //             body: JSON.stringify({ podaci: data.items }),
        //             responseType: 'blob',
        //             headers: {
        //                 'Authorization': `Bearer ${token}`,
        //                 'Access- Control - Allow - Origin': 'http://127.0.0.1:3005',
        //                 'Access - Control - Allow - Methods': 'POST',
        //                 //'Access- Control - Allow - Headers': 'Content - Type',
        //                 'Content-type': 'application/json; charset=UTF-8'
        //             },
        //         }) 
        //         .then((response) => response.json())
        // .then(data => {
        //     window.open(API + data.link)
        // });
    }

    const getClientsData = () => {
        fetch(API + `/komitenti/all/${form.companyCode}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${form.token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataClients(data)
            })
    }

    const getKontoData = () => {
        fetch(API + `/konto/all`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${form.token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataKonto(data)
            })
    }

    useEffect(() => {
        getClientsData();
        getKontoData();
        komtRef.current.focus();
    }, [])

    // cekiranje radio dugmadi u zavisnosti odabrane opcije
    const radioChangeHandler = (e) => {
        if (e.target.name === 'checkDatDok') {
            setDate({
                // odabir datuma dokumenata
                checkDatDok: true,
                checkDatNalog: false
            });
        } else {
            setDate({
                checkDatDok: false,
                // odabir datuma storniranja
                checkDatNalog: true
            });
        }
    };

    // funkcija koja se poziva prilikom promjene vrijednosti u inputu
    const handleChange = (event) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name;
        // postavlja sa funkcijom  setForm((prevState) mijejenja predhodno stanje sa novom vrijednoscu
        setForm((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    // funkcija za kontrolu obaveznih polja
    function unosIspravanDo(name) {

        if (name === 'KOMTID') {
            return true
        }

        if (name === 'KONTID') {
            return true
        }

        if (name === 'DATUMOD') {
            return true
        }

        let reqex = /^(0[1-9]|[12]\d|3[01]).(0[1-9]|1[0-2]).([12]\d{3})$/gi;

        if (!reqex.test(form.DATUMOD)) {
            setErrorDateFrom(true)
            setErrorMsgDateFrom('Datum od nije validan!')
            datumOdRef.current.focus();
            return false
        } else {
            setErrorDateFrom(false)
            setErrorMsgDateFrom(null)
        }

        if (name === 'DATUMDO') {
            return true
        }

        let reqexDATUMDO = /^(0[1-9]|[12]\d|3[01]).(0[1-9]|1[0-2]).([12]\d{3})$/gi;

        if (!reqexDATUMDO.test(form.DATUMDO)) {
            setErrorDateTo(true)
            setErrorMsgDateTo('Datum do nije validan!')
            datumDoRef.current.focus();
            return false
        } else {
            setErrorDateTo(false)
            setErrorMsgDateTo(null)
        }

        if (name === 'PROVJERA') {
            return true
        }

        return true
    }

    let sirinaZaKoloneVrijednosti =
        windowSize.width > 2048 ? '220px'
            : windowSize.width > 1920 ? '200px'
                : windowSize.width > 1680 ? '120px'
                    : windowSize.width > 1600 ? '120px'
                        : windowSize.width > 1440 ? '120px'
                            : windowSize.width > 1280 ? '120px'
                                : windowSize.width > 1024 ? '100px'
                                    : '80px';

    // funkcija za popunjavanje kolona u mrezi
    function checkingFields() {
        let konto =
        {
            name: 'KONTO',
            selector: row => row.KONTO,
            // sirina polja po rezolucijama
            width:
                windowSize.width > 1920 ? '100px'
                    : windowSize.width > 1680 ? '90px'
                        : windowSize.width > 1600 ? '90px'
                            : windowSize.width > 1440 ? '80px'
                                : windowSize.width > 1280 ? '80px'
                                    : windowSize.width > 1024 ? '70px'
                                        : '80px',
            cell: (row) => (
                <>
                    <span id='media-query-rows-text'>{row.KONTO}</span>
                </>
            ),
        }
        let sifra =
        {
            name: 'Šifra k.',
            selector: row => row.KOMI,
            // sirina polja po rezolucijama
            width:
                windowSize.width > 1920 ? '100px'
                    : windowSize.width > 1680 ? '90px'
                        : windowSize.width > 1600 ? '90px'
                            : windowSize.width > 1440 ? '80px'
                                : windowSize.width > 1280 ? '70px'
                                    : windowSize.width > 1024 ? '80px'
                                        : '80px',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.KOMI}</span>
                </>
            ),
            right: 'boolean',
        }
        let komtNaziv =
        {
            name: 'Naziv',
            selector: row => row.NAZIV,
            // sirina polja po rezolucijama
            width:
                windowSize.width > 2048 ? '480px'
                    : windowSize.width > 1920 ? '400px'
                        : windowSize.width > 1680 ? '320px'
                            : windowSize.width > 1600 ? '290px'
                                : windowSize.width > 1440 ? '280px'
                                    : windowSize.width > 1280 ? '260px'
                                        : windowSize.width > 1024 ? '200px'
                                            : '175px',
            cell: (row) => (
                <>
                    <span id='media-query-rows-text'>{row.NAZIV}</span>
                </>
            ),
        }
        let komtMjesto =
        {
            name: 'K. mjesto',
            selector: row => row.MJESTO,
            // sirina polja po rezolucijama
            width:
                windowSize.width > 2048 ? '400px'
                    : windowSize.width > 1920 ? '280px'
                        : windowSize.width > 1680 ? '320px'
                            : windowSize.width > 1600 ? '290px'
                                : windowSize.width > 1440 ? '250px'
                                    : windowSize.width > 1280 ? '200px'
                                        : windowSize.width > 1024 ? '200px'
                                            : '175px',
            cell: (row) => (
                <>
                    <span id='media-query-rows-text'>{row.MJESTO}</span>
                </>
            ),
        }
        let telefon =
        {
            name: 'Telefon',
            selector: row => row.TEL,
            // sirina polja po rezolucijama
            width:
                windowSize.width > 2048 ? '450px'
                    : windowSize.width > 1920 ? '300px'
                        : windowSize.width > 1680 ? '320px'
                            : windowSize.width > 1600 ? '290px'
                                : windowSize.width > 1440 ? '280px'
                                    : windowSize.width > 1280 ? '200px'
                                        : windowSize.width > 1024 ? '200px'
                                            : '175px',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.TEL}</span>
                </>
            ),
        }
        let duguje =
        {
            name: 'Duguje',
            selector: row => row.DUGUJE,
            width: sirinaZaKoloneVrijednosti,
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{numberWithCommasColumns(row.DUGUJE)}</span>
                </>
            ),
        }
        let potraz =
        {
            name: 'Potražuje',
            selector: row => row.POTRAZ,
            width: sirinaZaKoloneVrijednosti,
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{numberWithCommasColumns(row.POTRAZ)}</span>
                </>
            ),
        }
        let saldo =
        {
            name: 'Saldo',
            selector: row => row.SALDO,
            width: sirinaZaKoloneVrijednosti,
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{numberWithCommasColumns(row.SALDO)}</span>
                </>
            ),
        }
        if (form.buttonType.startsWith('analitika')) {
            let nalog =
            {
                name: 'Nalog',
                selector: row => row.NALOG,
                // sirina polja po rezolucijama
                width:
                    windowSize.width > 2048 ? '110px'
                        : windowSize.width > 1920 ? '100px'
                            : windowSize.width > 1680 ? '100px'
                                : windowSize.width > 1600 ? '90px'
                                    : windowSize.width > 1440 ? '80px'
                                        : windowSize.width > 1280 ? '70px'
                                            : windowSize.width > 1024 ? '60px'
                                                : '80px',
                right: 'boolean',
                cell: (row) => (
                    <>
                        <span id='media-query-rows'>{row.NALOG}</span>
                    </>
                ),
            }
            let datN =
            {
                name: 'Dat.Nal',
                selector: row => moment(row.DATN).format('DD.MM.YYYY'),
                width: windowSize.width > 1024 ? '100px' : '',
                cell: (row) => (
                    <>
                        <span id='media-query-rows-text'>{moment(row.DATN).format('DD.MM.YYYY')}</span>
                    </>
                ),
            }
            let red =
            {
                name: 'Red',
                selector: row => row.RED,
                // sirina polja po rezolucijama
                width:
                    windowSize.width > 1920 ? '100px'
                        : windowSize.width > 1680 ? '90px'
                            : windowSize.width > 1600 ? '100px'
                                : windowSize.width > 1440 ? '80px'
                                    : windowSize.width > 1280 ? '70px'
                                        : windowSize.width > 1024 ? '70px'
                                            : '80px',
                right: 'boolean',
                cell: (row) => (
                    <>
                        <span id='media-query-rows'>{row.RED}</span>
                    </>
                ),
            }
            let vrsta =
            {
                name: 'Vrsta',
                selector: row => row.VRSTA,
                // sirina polja po rezolucijama
                width:
                    windowSize.width > 2048 ? '110px'
                        : windowSize.width > 1920 ? '100px'
                            : windowSize.width > 1680 ? '90px'
                                : windowSize.width > 1600 ? '100px'
                                    : windowSize.width > 1440 ? '90px'
                                        : windowSize.width > 1280 ? '70px'
                                            : windowSize.width > 1024 ? '60px'
                                                : '80px',
                cell: (row) => (
                    <>
                        <span id='media-query-rows-text'>{row.VRSTA}</span>
                    </>
                ),
                right: 'boolean',
            }
            let datD =
            {
                name: 'Dat.Dok',
                selector: row => moment(row.DATD).format('DD.MM.YYYY'),
                // sirina polja po rezolucijama
                width:
                    windowSize.width > 2048 ? '150px'
                        : windowSize.width > 1920 ? '100px'
                            : windowSize.width > 1680 ? '110px'
                                : windowSize.width > 1600 ? '100px'
                                    : windowSize.width > 1440 ? '100px'
                                        : windowSize.width > 1280 ? '95px'
                                            : windowSize.width > 1024 ? '80px'
                                                : '80px',
                cell: (row) => (
                    <>
                        <span id='media-query-rows-text'>{moment(row.DATD).format('DD.MM.YYYY')}</span>
                    </>
                ),
            }
            let valuta =
            {
                name: 'Valuta',
                selector: row => moment(row.VALUTA).format('DD.MM.YYYY'),
                // sirina polja po rezolucijama
                width:
                    windowSize.width > 2048 ? '150px'
                        : windowSize.width > 1920 ? '100px'
                            : windowSize.width > 1680 ? '110px'
                                : windowSize.width > 1600 ? '100px'
                                    : windowSize.width > 1440 ? '100px'
                                        : windowSize.width > 1280 ? '95px'
                                            : windowSize.width > 1024 ? '80px'
                                                : '80px',
                cell: (row) => (
                    <>
                        <span id='media-query-rows-text'>{moment(row.VALUTA).format('DD.MM.YYYY')}</span>
                    </>
                ),
            }
            let opis =
            {
                name: 'Opis',
                selector: row => row.OPIS,
                // sirina polja po rezolucijama
                width:
                    windowSize.width > 2048 ? '610px'
                        : windowSize.width > 1920 ? '380px'
                            : windowSize.width > 1680 ? '350px'
                                : windowSize.width > 1600 ? '290px'
                                    : windowSize.width > 1440 ? '270px'
                                        : windowSize.width > 1280 ? '170px'
                                            : windowSize.width > 1024 ? '200px'
                                                : '175px',
                cell: (row) => (
                    <>
                        <span id='media-query-rows-text'>{row.OPIS}</span>
                    </>
                ),
            }
            return [konto, sifra, nalog, datN, red, vrsta, datD, valuta, opis, duguje, potraz, saldo]
        } else {
            return [konto, sifra, komtNaziv, komtMjesto, telefon, duguje, potraz, saldo]
        }
    }

    const defaultPropsClients = {
        options: selectDataClients,
        getOptionLabel: (option) => option ? `${option.SIFRA} ${option.NAZIV}` : '',
    };

    const defaultPropsKonto = {
        options: selectDataKonto,
        getOptionLabel: (option) => option ? `${option.KONTO} ${option.NAZ}` : '',
    };

    return (

        <div>
            {/* Kontejner za naslov  */}
            <div className="title-container">
                {/* Naziv forma */}
                <header className="title-header">
                    <h1 id="media-query-title">Analitika po komitentima i kontima</h1>
                </header>

                <div >
                    <div>
                        {/* Klasa za ikonice, stampa*/}
                        <ul >
                            <input type="image" alt="print" className='dugme-stampa' title='Štampa' tabIndex="10" src={require('../../assets/images/printer.png')} onClick={print} disabled={spinner} />

                        </ul>
                    </div>
                </div>
            </div>
            <Container fluid>
                <div className="row">
                    <Col lg='2'>
                        <div className='form-field-container' style={{ display: 'contents' }}>
                            {/* red sirine */}
                            <Col lg='12'>
                                {/* definsanje froma za radio dugmad */}
                                <div className="col-sm radio-box" id='media-query-radioButton-date'>
                                    <RadioButton
                                        changed={(e) => radioChangeHandler(e)}
                                        isSelected={date?.checkDatDok}
                                        label="Dat.dok."
                                        id="1"
                                        value={date?.checkDatDok}
                                        name="checkDatDok"
                                    />
                                    {/*  radio dugme za datum storniranja   */}
                                    <RadioButton
                                        changed={(e) => radioChangeHandler(e)}
                                        isSelected={date?.checkDatNalog}
                                        label="Dat.nal."
                                        id="2"
                                        value={date?.checkDatNalog}
                                        name="checkDatNalog"
                                    />
                                </div>
                            </Col>
                        </div>
                    </Col>


                    <Col lg='6'>

                        <div className="col-12" style={{ display: 'flex' }}>
                            <div className="col-12">
                                <div className="form-group row label-box-div">
                                    <label className="col-form-label col-lg-2 label-box" id="media-query-label-report">Komitent:</label>
                                    <div className="col-8">
                                        <Autocomplete
                                            //ukoliko nema ove funkcije, u konzoli izbacuje upozorenje da nije vrijednost jedinstvena
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            {...defaultPropsClients}
                                            value={clients}
                                            disablePortal={true}
                                            onFocus={() => {
                                                unosIspravanDo("KOMTID")
                                            }}
                                            onChange={(event, newValue) => {
                                                setForm(prev => ({ ...prev, KOMTID: newValue && newValue != '' ? newValue.ID : '' }))
                                                setClients(newValue);
                                                // fokus se mora vratiti inace tabIndex ne radi dobro
                                                komtRef.current.focus()
                                            }}

                                            renderInput={(params) => (
                                                <TextField {...params}
                                                    autoFocus
                                                    tabIndex="2"
                                                    className="clients-textinput"
                                                    ref={komtRef}
                                                    placeholder=' Izaberite komitenta'
                                                    variant="standard" />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12" style={{ display: 'flex' }}>
                            <div className="col-12">
                                <div className="form-group row label-box-div">
                                    <label className="col-form-label col-lg-2 label-box" id="media-query-label-report">Konto:</label>
                                    <div className="col-8">
                                        <Autocomplete
                                            //ukoliko nema ove funkcije, u konzoli izbacuje upozorenje da nije vrijednost jedinstvena
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            {...defaultPropsKonto}
                                            value={konto}
                                            disablePortal={true}
                                            onFocus={() => {
                                                unosIspravanDo("KONTID")
                                            }}
                                            onChange={(event, newValue) => {
                                                setForm(prev => ({ ...prev, KONTID: newValue && newValue != '' ? newValue.ID : '' }))
                                                setKonto(newValue);
                                                // fokus se mora vratiti inace tabIndex ne radi dobro
                                                kontRef.current.focus()
                                            }}

                                            renderInput={(params) => (
                                                <TextField {...params}
                                                    tabIndex="3"
                                                    className="clients-textinput"
                                                    ref={kontRef}
                                                    placeholder=' Izaberite konto'
                                                    variant="standard" />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12" style={{ display: 'flex', }}>
                            <div className="col-12">

                                <div className="form-group row label-box-div">
                                    <label className="col-form-label col-lg-2 label-box" id="media-query-label-report">Datum od:</label>
                                    <div className="col-2">
                                        <div className={errorDateFrom ? 'inputWithError' : 'field-container'}>
                                            <input
                                                type='text'
                                                ref={datumOdRef}
                                                tabIndex="4"
                                                id="media-query-input"
                                                onFocus={() => {
                                                    unosIspravanDo("DATUMOD")
                                                    checkCompanyYear();
                                                }}
                                                placeholder="datum od"
                                                name='DATUMOD'
                                                value={form?.DATUMOD}
                                                onChange={(e) => handleChange(e)} />
                                            {errorMsgDateFrom ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgDateFrom}</span> : ''}
                                        </div>
                                    </div>

                                    <label className="col-form-label col-lg-4 label-box-do" id="media-query-label-DateTo">do:</label>
                                    <div className="col-2">
                                        <div className={errorDateTo ? 'inputWithError' : 'field-container'}>
                                            <input type='text'
                                                tabIndex="5"
                                                id="media-query-input-to"
                                                ref={datumDoRef}
                                                onFocus={() => {
                                                    unosIspravanDo("DATUMDO")
                                                }}
                                                placeholder="datum do"
                                                value={form?.DATUMDO}
                                                name='DATUMDO'
                                                onChange={(e) => handleChange(e)} />
                                            {errorMsgDateTo ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgDateTo}</span> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg='4'>
                        <Row>
                            <Col lg='12' >
                                <div className=" flex-lg-row justify-content-end" id='media-query-buttonType'>
                                    <div style={{ display: 'inline-grid' }}>
                                        <div className={faSpinAKomKont ? "btn-report-red" : "btn-report"}>
                                            <button tabIndex={6}
                                                onFocus={() => {
                                                    unosIspravanDo("PROVJERA")
                                                }}
                                                onClick={() => {
                                                    // uslov: ako nisu unesena obavezna polja, zaustavlja upis
                                                    if (!unosIspravanDo('DUGME')) return;
                                                    form.buttonType = 'analitikaPoKomitentimaIKontima'
                                                    setFaSpinAKomKont(true)
                                                    setFaSpinAKontKomt(false)
                                                    setFaSpinTKomtKont(false)
                                                    setFaSpinTKontKomt(false)
                                                    setPending(true)
                                                    getReports()
                                                }}>{faSpinAKomKont && !analitikaPoKomitentimaIKontima ? <i className="fa fa-spinner fa-spin"></i> : ''}Analitika po komitentima i kontima</button>
                                        </div>
                                        <div className={faSpinAKontKomt ? "btn-report-red" : "btn-report"}>
                                            <button tabIndex={7} onClick={() => {
                                                // uslov: ako nisu unesena obavezna polja, zaustavlja upis
                                                if (!unosIspravanDo('DUGME')) return;
                                                form.buttonType = 'analitikaPoKontimaIKomitentima'
                                                setFaSpinAKontKomt(true)
                                                setFaSpinAKomKont(false)
                                                setFaSpinTKomtKont(false)
                                                setFaSpinTKontKomt(false)
                                                setPending(true)
                                                getReports()
                                            }}>{faSpinAKontKomt && !analitikaPoKomitentimaIKontima ? <i className="fa fa-spinner fa-spin"></i> : ''}Analitika po kontima i komitentima</button>
                                        </div>
                                    </div>
                                    <div style={{ display: 'inline-grid' }} className="btn-report">
                                        <div className={faSpinTKomtKont ? "btn-report-red" : "btn-report"}>
                                            <button tabIndex={8} onClick={() => {
                                                // uslov: ako nisu unesena obavezna polja, zaustavlja upis
                                                if (!unosIspravanDo('DUGME')) return;
                                                form.buttonType = 'totalPoKomitentimaIKontima'
                                                setFaSpinAKontKomt(false)
                                                setFaSpinAKomKont(false)
                                                setFaSpinTKomtKont(true)
                                                setFaSpinTKontKomt(false)
                                                setPending(true)
                                                getReports()
                                            }}>{faSpinTKomtKont && !analitikaPoKomitentimaIKontima ? <i className="fa fa-spinner fa-spin"></i> : ''}Total po komitentima i kontima</button>
                                        </div>
                                        <div className={faSpinTKontKomt ? "btn-report-red" : "btn-report"}>
                                            <button tabIndex={9} onClick={() => {
                                                // uslov: ako nisu unesena obavezna polja, zaustavlja upis
                                                if (!unosIspravanDo('DUGME')) return;
                                                form.buttonType = 'totalPoKontimaIKomitentima'
                                                setFaSpinAKontKomt(false)
                                                setFaSpinAKomKont(false)
                                                setFaSpinTKomtKont(false)
                                                setFaSpinTKontKomt(true)
                                                setPending(true)
                                                getReports()
                                            }}>{faSpinTKontKomt && !analitikaPoKomitentimaIKontima ? <i className="fa fa-spinner fa-spin"></i> : ''}Total po kontima i komitentima</button>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </div>
            </Container >

            {
                < DataTable
                    columns={form.buttonType ? checkingFields() : []}
                    data={analitikaPoKomitentimaIKontima ? analitikaPoKomitentimaIKontima : []}
                    pagination
                    responsive
                    striped
                    dense
                    pointerOnHover
                    paginationComponentOptions={defaultComponentOptions}
                    paginationPerPage={[windowSize.height > 1300 ? 30
                        : windowSize.height > 1246 ? 28
                            : windowSize.height > 1222 ? 27
                                : windowSize.height > 1200 ? 26
                                    : windowSize.height > 1000 ? 21
                                        : windowSize.height > 920 ? 18
                                            : windowSize.height > 900 ? 17
                                                : windowSize.height > 880 ? 16
                                                    : windowSize.height > 820 ? 14
                                                        : windowSize.height > 720 ? 12
                                                            : 4]}
                    progressPending={pending}
                />
            }
            {/* <div className="naslov-ikonice">
                <ul className="nav flex-column flex-lg-row justify-content-end">
                    <div style={{ display: 'flex', justifyContent: 'end', position: 'fixed', bottom: '0', flex: '0 0 auto' }}>

                        <li className="nav-item toolbaritem" style={{ width: '21%', marginRight: '4px' }}>
                            <div className='col-form-right'>
                                <div className="field-container-number">
                                    <label id="media-query-label-sum">Duguje</label>
                                    <input className="form-control" readOnly={true} type='text' id="media-query-input-text" value={sumduguje && sumduguje != 'NaN' ? numberWithCommas(sumduguje) : 0} />
                                </div>
                            </div>
                        </li>

                        <li className="nav-item toolbaritem" style={{ width: '21%', marginRight: '7px' }}>
                            <div className='col-form-right'>
                                <div className="field-container-number">
                                    <label id="media-query-label-sum">Potražuje</label>
                                    <input type='text' className="form-control" readOnly={true} id="media-query-input-text" value={sumpotraz && sumpotraz != 'NaN' ? numberWithCommas(sumpotraz) : 0} />
                                </div>
                            </div>
                        </li>

                        <li className="nav-item toolbaritem" style={{ width: '21%', marginRight: '5px' }}>
                            <div className='col-form-right'>
                                <div className="field-container-number">
                                    <label id="media-query-label-sum">Saldo</label>
                                    <input className="total" readOnly={true} type='text' id="media-query-input-text" value={sum && sum != 'NaN' ? numberWithCommas(sum) : 0} />
                                </div>
                            </div>
                        </li>

                    </div>
                </ul>
            </div> */}

            <div className="naslov-ikonice">
                <ul className="nav flex-column flex-lg-row justify-content-end">
                    <div style={{ display: 'flex', justifyContent: 'end', position: 'fixed', bottom: '0', flex: '0 0 auto' }}>

                        <li className="nav-item toolbaritem" style={{ width: '23%', marginRight: '5px' }}>
                            <div className='col-form-right'>
                                <div className="field-container-number-totals">
                                    <label id="media-query-label-sum">Duguje</label>
                                    <input className="form-control" readOnly={true} tabIndex="-1" type='text' id="media-query-input-text" value={sumduguje && sumduguje != 'NaN' ? sumduguje : 0} />
                                </div>
                            </div>
                        </li>

                        <li className="nav-item toolbaritem" style={{ width: '23%', marginRight: '4px' }}>
                            <div className='col-form-right'>
                                <div className="field-container-number-totals">
                                    <label id="media-query-label-sum">Potražuje</label>
                                    <input className="form-control" readOnly={true} type='text' tabIndex="-1" id="media-query-input-text" value={sumpotraz && sumpotraz != 'NaN' ? sumpotraz : 0} />
                                </div>
                            </div>
                        </li>

                        <li className="nav-item toolbaritem" style={{ width: '23%', marginRight: '7px' }}>
                            <div className='col-form-right'>
                                <div className="field-container-number-totals">
                                    <label id="media-query-label-sum">Saldo</label>
                                    <input type='text' className="total" readOnly={true} id="media-query-input-text" tabIndex="-1" value={sum && sum != 'NaN' ? (sum) : 0} />
                                </div>
                            </div>
                        </li>
                    </div>
                </ul>
            </div>

            {/* Modalni form za gresku; prikazuje se ako nisu unijeta obavezna polja */}

            <div>
                <Modal isOpen={modalErrorOpenPrint} centered  >
                    <ModalHeader>Upozorenje!</ModalHeader>
                    <ModalBody>
                        <span>{`Izaberite izvještaj koji želite štampati!`}</span>
                    </ModalBody>
                    <ModalFooter className="justify-content-center">
                        <div>
                            {/* Dugme za zatvaranje modalnog forma */}
                            <button onClick={() => setModalErrorOpenPrint(false)} className="button-yes">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            {/* spiner ili progres bar ya prikaz pokretanja datog izvjestaja */}
            <div>
                {spinner ? <Spinner className='spinner' color="primary" id='loader' /> : ''}
            </div>

        </div >
    );

}

export default AnalitikaPoKomitentimaIKontima;


