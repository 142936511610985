// importovanje komponenti 
import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { API } from '../constants';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import axios from '../api/axios';
import { Spinner } from "reactstrap";
import { getCompanyData, defaultComponentOptions } from '../components/utilities';
import { NumericFormat } from 'react-number-format';
import { useWindowSize } from '../components/useWindowSize';
import { defaultFormRegistri } from "../components/utilities";

// komponenta koju kreiramo
function Opza() {
    const windowSize = useWindowSize();
    // definisanje konstanti koje koristimo za fokusiranje polja
    const opisRef = useRef();
    const artikalRef = useRef();
    // definisanje stejtova i prva inicijalizacija; objekat i funkcija za taj objekat

    const [opza, setOpza] = useState(null)
    const [selectData, setSelectData] = useState([]);
    const [searchInput, setSearchInput] = useState([]);
    const [spinner, setSpinner] = useState(false);
    const [modalErrorOpenData, setModalErrorOpenData] = useState(false);
    const [modalErrorAuthorizationData, setModalErrorAuthorizationOpen] = useState(false);
    const [modalDeleteItem, setModalDeleteItem] = useState(false);
    // povlacenje podataka iz lokal storidza

    const token = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : null

    // error row inputs
    const [errorInputArtikal, setErrorInputArtikal] = useState(false);

    // error messages inputs
    const [errorMsgArtikal, setErrorMsgArtikal] = useState('');
    const [artikal, setArtikal] = useState(null);

    // definisanje stejta(objekat i funkcija za taj objekat), prva inicijalizacija
    const [form, setForm] = useState({
        ...defaultFormRegistri,
    });

    // funkcija za povlacenje podataka o preduzecu
    getCompanyData(form);

    // funkcija koja se poziva klikom na dugme 'Sacuvaj'
    const onSubmit = () => {
        // uslov: ako nisu unesena obavezna polja, zaustavlja upis
        if (!unosIspravanDo('UPIS')) return;

        fetch(API + `/opza/new`, {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                response.json()
                // vracanje forme na pocetnu vrijednost
                setForm((prev) => ({
                    ...prev,
                    ...defaultFormRegistri,
                }));
                setArtikal(null)
                artikalRef.current.focus()
                getTableData()
            })

    }

    // funkcija koja se poziva pri prvoj inicijalizaciji
    // api za mrezu
    // u responsu puni mrezi(pomocu funkcije setIme) i puni ukupan broj stranica(setTotal)
    const getTableData = () => {
        fetch(API + `/opza/all`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                // popuni mrezu i postavi podatke o komintentima
                setOpza(data.result)
            })
    }

    // prilikom pocetne inicijalizacije, cita mrezu
    useEffect(() => {
        // zaustavljanje fokusa
        const timeout = setTimeout(() => {
            getTableData();
        }, 100);
        return () => {
            clearTimeout(timeout);
        };
    }, []);

    useEffect(() => {
        getArtikalData();
    }, [])


    // funkcija za pronalazenje mjesta i odabir postojeceg
    const getArtikalData = () => {
        fetch(API + `/artikal/all`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectData(data)
            })
    }

    // funkcija za brisanje koja se poziva klikom na dugme 'Delete'
    //  api za brisanje
    // u responsu osvjezava polja, daje prvi slobodan broj za sifru, fokus na polje sifra, osvjezava mrezu  
    const onDelete = (NOMENID) => {
        // provjera ovlascenja za brisanje 
        if (form.ovlrobno == 4) return setModalErrorAuthorizationOpen(true);

        fetch(API + '/opza/delete/' + NOMENID, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                response.json()
                // vracanje forme na pocetnu vrijednost
                setForm((prev) => ({
                    ...prev,
                    ...defaultFormRegistri,
                }));
                setArtikal(null)
                artikalRef.current.focus()
                getTableData()
            })
    }

    // funkcija koja se poziva klikom na dugme Edit
    // postavlja fokus na polje NAZIV 
    // popunjava polja podacima iz mreze
    const onEdit = (item) => {
        item.stavkaPronadjena = true;
        setArtikal(null)
        selectData.map((artikal) => {
            if (artikal.ID == item.NOMENID) {
                setArtikal(artikal)
            }
        })

        setForm(item)
        const timeout = setTimeout(() => {
            opisRef.current.focus();
        }, 100);
        return () => {
            clearTimeout(timeout);
        };
    }
    // funkcija koja se poziva klikom na dugme 'Stampa'

    const onPrint = () => {
        setSpinner(true)
        if (opza && opza.length === 0) {
            setSpinner(false)
            setModalErrorOpenData(true)
        } else {

            // uslov preko koga saljemo naziv preduzeca sa frontenda na bekend
            // ako je objekat form popunjen, napuni polje NAZIV_PRED iz lokal storidza
            // poziva api za stampu, otvara izvjestaj(API + data.link)
            if (form) {
                form.NAZIV_PRED = form.companyNaziv
            }

            fetch(API + `/opza/print`, {
                method: 'POST',
                body: JSON.stringify(form),
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-type': 'application/json; charset=UTF-8'
                },

            }).then((response) => response.json())
                .then(data => {
                    if (typeof window != 'undefined') {
                        setTimeout(() => {
                            window.open(API + data.link)
                        }, 500);

                    }
                    setSpinner(false)
                })
        }
    }
    // funkcija koja se poziva prilikom promjene vrijednosti u inputu

    const handleChange = (event) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name;

        setForm((prevState) => ({
            ...prevState,
            [name]: value
        }));

    };

    // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto) ili ako nije upisana ni vrijednost niti je odabran artikal, izbaci poruku, setuje crveni okvir i fokusira polje  
    // u suprotnom prolazi dalje
    const checkNomenRequired = (value) => {
        if (value && !artikal || !value && !artikal) {
            setErrorMsgArtikal('Artikal je obavezan!')
            setErrorInputArtikal(true)
            artikalRef.current.focus();
            return false
        } else {
            setErrorMsgArtikal(null)
            setErrorInputArtikal(false)
            return true
        }
    }

    // funkcija za kontrolu obaveznih polja
    function unosIspravanDo(name) {
        if (name === 'NOMENID') {
            return true
        }

        if (!checkNomenRequired(form.NOMENID)) {
            return false
        }

        if (name === 'OPIS1') {
            return true
        }
        if (name === 'OPIS2') {
            return true
        }
        if (name === 'OPIS3') {
            return true
        }

        return true
    }

    // propsovi za auto komplit  - artikal
    const defaultProps = {
        options: selectData,
        getOptionLabel: (option) => option ? `${option.SIFRA} ${option.NAZIV}` : '',
    };

    // definisu se polja u tabeli i popunjavaju
    const columns = [
        {
            name: 'Sifra',
            selector: row => row.nomenSifra,
            // sirina polja po rezolucijama
            width:
                windowSize.width > 1920 ? '100px'
                    : windowSize.width > 1680 ? '90px'
                        : windowSize.width > 1600 ? '100px'
                            : windowSize.width > 1440 ? '100px'
                                : windowSize.width > 1280 ? '100px'
                                    : windowSize.width > 1024 ? '80px'
                                        : '80px',
            sortable: true,
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.nomenSifra}</span>
                </>
            ),
        },
        {
            name: 'Naziv',
            selector: row => row.nomenNaziv,
            width: '500px',
            sortable: true,
            // sirina polja po rezolucijama
            width:
                windowSize.width > 2048 ? '640px'
                    : windowSize.width > 1920 ? '530px'
                        : windowSize.width > 1680 ? '400px'
                            : windowSize.width > 1600 ? '410px'
                                : windowSize.width > 1440 ? '380px'
                                    : windowSize.width > 1280 ? '300px'
                                        : windowSize.width > 1024 ? '260px'
                                            : '220px',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.nomenNaziv}</span>
                </>
            ),
        },
        {
            name: 'Opis 1',
            selector: row => row.OPIS1,
            sortable: true,
            // sirina polja po rezolucijama
            width:
                windowSize.width > 2048 ? '400px'
                    : windowSize.width > 1920 ? '300px'
                        : windowSize.width > 1680 ? '340px'
                            : windowSize.width > 1600 ? '240px'
                                : windowSize.width > 1440 ? '220px'
                                    : windowSize.width > 1280 ? '220px'
                                        : windowSize.width > 1024 ? '220px'
                                            : '200px',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.OPIS1}</span>
                </>
            ),
        },
        {
            name: 'Opis 2',
            selector: row => row.OPIS2,
            sortable: true,
            // sirina polja po rezolucijama
            width:
                windowSize.width > 2048 ? '500px'
                    : windowSize.width > 1920 ? '350px'
                        : windowSize.width > 1680 ? '270px'
                            : windowSize.width > 1600 ? '250px'
                                : windowSize.width > 1440 ? '240px'
                                    : windowSize.width > 1280 ? '200px'
                                        : windowSize.width > 1024 ? '200px'
                                            : '200px',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.OPIS2}</span>
                </>
            ),
        },
        {
            name: 'Opis 3',
            selector: row => row.OPIS3,
            sortable: true,
            // sirina polja po rezolucijama
            width:
                windowSize.width > 2048 ? '410px'
                    : windowSize.width > 1920 ? '350px'
                        : windowSize.width > 1680 ? '250px'
                            : windowSize.width > 1600 ? '250px'
                                : windowSize.width > 1440 ? '230px'
                                    : windowSize.width > 1280 ? '200px'
                                        : windowSize.width > 1024 ? '200px'
                                            : '200px',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.OPIS3}</span>
                </>
            ),
        },
        {
            name: 'Action',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span onClick={() => onEdit(row)} id='media-query-table-buttons' className='btn btn-primary nav flex-column flex-lg-row justify-content-end'>Edit</span>{'    '}
                    <span onClick={() => { onEdit(row); setModalDeleteItem(true) }} id='media-query-table-buttons' className='btn btn-danger nav flex-column flex-lg-row justify-content-end'>Brisanje</span>
                </>

            ),

        }
    ];
    // const searchChange = (e) => {
    //     e.preventDefault();
    //     setSearchInput(e.target.value);

    //     var searchData = opzaAll.filter((item) => {
    //         return Object.keys(item).some(key => {
    //             if (item[key] !== null)
    //                 return item[key].toString().toLowerCase().includes(e.target.value.toLowerCase())
    //         })
    //     });
    //     setopza(searchData);
    // };

    // funkcija za pretragu iz mreze
    // salje zahtjev na bekend prema upitu sa parametrima koje posaljemo
    // u responsu vraca podatke koje dobio iz upita 

    const searchChange = (e) => {
        e.preventDefault();
        const searchInput = e.target.value
        setSearchInput(searchInput);

        const fetchData = async () => {
            const res = await axios.get(`${API}/opza/query ?q=${searchInput}`);
            setOpza(res.data);
        };
        // uslov za za slanje zahtjeva za povlacenje podataka

        if (searchInput.length === 0 || searchInput.length >= 1) fetchData();

    };

    return (

        <div>
            {/* Kontejner za naslov  */}

            <div className="title-container">
                {/* Naziv registra */}

                <header className="title-header">
                    <h1 id="media-query-title">Opis za artikle</h1>
                </header>
                {/* Klasa za ikonice, nalazi se u istom redu kao i naziv, ikonice su formirane kao lista */}

                <div className="naslov-ikonice">
                    <ul className="nav flex-column flex-lg-row justify-content-end">

                        <li className="nav-item toolbaritem">
                            <input type="image" alt="save" className='dugme-sacuvaj' title='Sačuvaj' tabIndex="5" src={require('./../assets/images/floppydisk.png')} onFocus={() => onSubmit()} />
                        </li>

                        <li className="nav-item toolbaritem">
                            <input type="image" alt="print" className='dugme-stampa' title='Štampa' tabIndex="6" src={require('./../assets/images/printer.png')} onClick={onPrint} />
                        </li>

                    </ul>
                </div>

            </div>
            {/* Kontejner pune sirine */}
            <Container fluid >
                <Row>
                    <div className='products-form'>

                        {/* Stilovi koji ce se primjenjivati na svim poljima */}
                        <div className="col-12" style={{ display: 'inherit', paddingRight: '0px', paddingLeft: '-1px', marginRight: 'auto', marginLeft: '0px' }}>
                            {/* Sirina polja */}
                            <div className={errorInputArtikal ? 'form-group row inputWithError-columns' : 'form-group row required label-box-div'} >
                                <label className="col-lg-2 label-box" id="media-query-label-report">Artikal:</label>
                                <div className="col-5">
                                    {/* Ako je Autocomplete  poziva na odabri ARTIKAL*/}
                                    <Autocomplete
                                        {...defaultProps}
                                        autoFocus
                                        value={artikal}
                                        disablePortal={true}
                                        onFocus={() => {
                                            unosIspravanDo("NOMENID")
                                        }}
                                        onChange={(event, newValue) => {
                                            setForm(prev => ({ ...prev, NOMENID: newValue && newValue != null ? newValue.ID : '' }))
                                            setArtikal(newValue);
                                            // fokus se mora vratiti inace tabIndex ne radi dobro
                                            artikalRef.current.focus()
                                        }}
                                        renderInput={(params) => (
                                            <TextField id='media-query' {...params}
                                                className="clients-textinput"
                                                name='NOMENID'
                                                ref={artikalRef}
                                                tabIndex="1"
                                                placeholder=' artikal'
                                                variant="standard" />
                                        )}
                                    />
                                    {errorMsgArtikal ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgArtikal}</span> : ''}
                                </div>
                            </div>
                        </div>

                        <Row>
                            <div className="col-12">
                                {/* Stilovi i klase za polje za pretragu;  */}
                                <div style={{ display: 'flex', alignItems: 'baseline', paddingRight: '0px', paddingLeft: '-1px', marginRight: 'auto', marginLeft: '0px' }}>
                                    <label style={{ marginLeft: '25px', alignItems: 'center', marginRight: '10px', color: '#00009D', fontWeight: '600', fontSize: '17px' }} id="media-query-input-text">Opis1</label>
                                    <input type="text" id="media-query-input-text" ref={opisRef} maxLength="100" tabIndex="2" className='field-container' placeholder="opis 1" name='OPIS1' value={form ? form.OPIS1 : ''} onFocus={() => {
                                        unosIspravanDo("OPIS1")
                                    }} onChange={(e) => handleChange(e)} />
                                </div>
                                <div className="col-12" style={{ display: 'flex', alignItems: 'baseline', paddingRight: '0px', paddingLeft: '-1px', marginRight: 'auto', marginLeft: '0px' }}>
                                    <label style={{ marginLeft: '25px', alignItems: 'center', marginRight: '10px', color: '#00009D', fontWeight: '600', fontSize: '17px' }} id="media-query-input-text">Opis2</label>
                                    <input type="text" id="media-query-input-text" maxLength="100" tabIndex="3" placeholder="opis 2" name='OPIS2' value={form ? form.OPIS2 : ''} onFocus={() => {
                                        unosIspravanDo("OPIS2")
                                    }} onChange={(e) => handleChange(e)} />
                                </div>


                                <div className="col-12" style={{ display: 'flex', alignItems: 'baseline', paddingRight: '0px', paddingLeft: '-1px', marginRight: 'auto', marginLeft: '0px' }}>
                                    <label style={{ marginLeft: '25px', alignItems: 'center', marginRight: '10px', color: '#00009D', fontWeight: '600', fontSize: '17px' }} id="media-query-input-text">Opis3</label>
                                    <input type="text" id="media-query-input-text" maxLength="100" tabIndex="4" placeholder="opis 3" name='OPIS3' value={form ? form.OPIS3 : ''} onFocus={() => {
                                        unosIspravanDo("OPIS3")
                                    }} onChange={(e) => handleChange(e)} />
                                </div>
                            </div>
                        </Row>
                    </div>
                </Row >
            </Container >

            {/* Stilovi i klase za polje za pretragu;  */}
            <div style={{ display: 'flex' }} className='search'>
                {/* vrsta inputa; poziva funkciju za pretragu(searchChange);  */}
                <input type='text' tabIndex={-1} id="media-query-input-text" placeholder='Pretraga...' onChange={(e) => {
                    // pretraga mreze(ukoliko je unesen bilo koji karakter, vrsi pretragu)
                    if (searchInput.length >= 0) {
                        searchChange(e);
                    } else {
                        getTableData();
                    }
                }} value={searchInput} />
                {/* // <img src={Pencil} className='search-icon' /> */}
            </div>
            {/* Mreza */}
            <DataTable
                columns={columns}
                data={opza ? opza : []}
                pagination
                responsive
                highlightOnHover
                striped
                dense
                pointerOnHover
                paginationComponentOptions={defaultComponentOptions}
                paginationPerPage={[windowSize.height > 1300 ? 26
                    : windowSize.height > 1270 ? 24
                        : windowSize.height > 1246 ? 23
                            : windowSize.height > 1222 ? 22
                                : windowSize.height > 1200 ? 22
                                    : windowSize.height > 1000 ? 17
                                        : windowSize.height > 920 ? 15
                                            : windowSize.height > 900 ? 14
                                                : windowSize.height > 880 ? 13
                                                    : windowSize.height > 820 ? 12
                                                        : windowSize.height > 720 ? 9
                                                            : 4]}
            />

            {/* render za poruku */}
            <div>
                <Modal isOpen={Boolean(modalDeleteItem)} centered>
                    <ModalHeader>Brisanje stavke!</ModalHeader>
                    <ModalBody>
                        <span>{`Da li želite da obrišete artikal ${form.nomenSifra} ? `}</span>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            <button
                                onClick={() => { setModalDeleteItem(false); opisRef.current.focus() }}
                                className="button-no">Ne
                            </button>
                            <button
                                onClick={() => {
                                    onDelete(form.NOMENID);
                                    setModalDeleteItem(false)
                                }}
                                className="button-yes">Da
                            </button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            {/* render za poruku */}
            <div>
                <Modal isOpen={Boolean(modalErrorAuthorizationData)} centered  >
                    <ModalHeader>Upozorenje!</ModalHeader>
                    <ModalBody>
                        <span>{`Nemate ovlašćenje za brisanje!`}</span>
                    </ModalBody>
                    <ModalFooter className="justify-content-center">
                        <div>
                            <button onClick={() => setModalErrorAuthorizationOpen(false)} className="button-yes">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            <div>
                <Modal isOpen={Boolean(modalErrorOpenData)} centered  >
                    <ModalHeader>Nema podataka!</ModalHeader>
                    <ModalFooter className="justify-content-center">
                        <div>
                            {/* Dugme za zatvaranje modalnog forma */}
                            <button onClick={() => setModalErrorOpenData(false)} className="button-yes">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            <div>
                {spinner ? <Spinner className='spinner' color="primary" id='loader' /> : ''}
            </div>

        </div >

    );

}

export default Opza;


