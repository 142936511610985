// importovanje komponenti 
import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import DataTable from 'react-data-table-component';
import axios from '../api/axios';
import { API } from '../constants';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Spinner } from "reactstrap";
import { getCompanyData, defaultComponentOptions } from '../components/utilities';
import { NumericFormat } from 'react-number-format';
import { useWindowSize } from '../components/useWindowSize';

// komponenta koju kreiramo
function Usluge() {

    const windowSize = useWindowSize();
    // definisanje konstanti koje koristimo za fokusiranje polja
    const codeRef = useRef();
    const nameRef = useRef();
    const taxRef = useRef();
    const accountRef = useRef();
    const priceRef = useRef();

    // definisanje stejtova i prva inicijalizacija; objekat i funkcija za taj objekat
    const [serviceCharge, setServiceCharge] = useState(null)
    const [total, setTotal] = useState(0)
    const [perPage, setPerPage] = useState(10);
    const [account, setAccount] = useState(null);
    const [selectDataAccount, setSelectDataAccount] = useState([]);
    const [tax, setTax] = useState(null);
    const [selectDataTax, setSelectDataTax] = useState([]);
    const [searchInput, setSearchInput] = useState([]);
    const [pending, setPending] = React.useState(false);

    // error row inputs
    const [errorInputCode, setErrorInputCode] = useState(false);
    const [errorInputName, setErrorInputName] = useState(false);
    const [errorInputTax, setErrorInputTax] = useState(false)

    // error messages inputs
    const [errorMsgCode, setErrorMsgCode] = useState(false);
    const [errorMsgName, setErrorMsgName] = useState(false);
    const [errorMsgTax, setErrorMsgTax] = useState(false)

    const [modalErrorOpen, setModalErrorOpen] = useState(false);
    const [modalErrorOpenData, setModalErrorOpenData] = useState(false);
    const [modalErrorAuthorizationData, setModalErrorAuthorizationOpen] = useState(false);
    const [modalDeleteItem, setModalDeleteItem] = useState(false);

    const [spinner, setSpinner] = useState(false);

    // povlacenje podataka iz lokal storidza
    const token = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : null

    // definisanje stejta(objekat i funkcija za taj objekat), prva inicijalizacija
    const [form, setForm] = useState({
        SIFRA: '',
        NAZIV: '',
        KONTID: '',
        POREZID: '',
        JM: '',
        CENA: '',
        stavkaPronadjena: false
    })

    // funkcija za povlacenje podataka o preduzecu
    getCompanyData(form)

    // funkcija koja se poziva klikom na dugme 'Sacuvaj'
    const onSubmit = () => {

        // uslov: ako nisu unesena obavezna polja, zaustavlja upis
        if (!unosIspravanDo('UPIS')) return;

        fetch(API + '/usluge/new', {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                response.json()
                setForm({
                    SIFRA: '',
                    NAZIV: '',
                    KONTID: '',
                    POREZID: '',
                    JM: '',
                    CENA: '',
                    stavkaPronadjena: false
                })
                setTax(null)
                setAccount(null)
                codeRef.current.focus()
                getTableData()
            })
    }

    // funkcija za povlacenje podataka iz baze
    const getAccountData = () => {
        fetch(API + `/konto/all`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataAccount(data)
            })
    }

    // funkcija za povlacenje podataka iz baze
    const getTaxData = () => {
        fetch(API + `/porezi/all`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataTax(data)
            })
    }

    // funkcija koja se poziva pri prvoj inicijalizaciji
    // api za mrezu
    // u responsu puni mrezu(pomocu funkcije setIme) i puni ukupan broj stranica(setTotal)
    const getTableData = () => {
        fetch(API + `/usluge/all`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setServiceCharge(data.result)
                setPending(false)
            })
    }

    // poziva se inicijalno pri otvaranju forma i poziva api za popunjavanje polja
    useEffect(() => {
        getAccountData()
        getTaxData()
    }, [])

    // prilikom pocetne inicijalizacije postavlja prvi slobodan broj
    useEffect(() => {
        const timeout = setTimeout(() => {
            getServiceChargeFirstFreeNumberSifra()
            getTableData()
        }, 100);
        return () => {
            clearTimeout(timeout);
        };
    }, []);

    // funkcija za pronalazenje prvog slobodnog broja
    // poziva api
    // u responsu stejt setForm poziva funkciju za pronalazenje prethodne sifre(iz data) i na osnovu nje odredjuje koja je sljedeca prva slobodna sifra
    const getServiceChargeFirstFreeNumberSifra = () => {
        fetch(API + `/usluge/firstFreeNumberSifra`, {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setForm(prev => ({ ...prev, SIFRA: Number(data[0].SIFRA) }))
            })
    }

    // funkcija za brisanje koja se poziva klikom na dugme 'Delete'
    //  api za brisanje
    // u responsu osvjezava polja, daje prvi slobodan broj za sifru, fokus na polje sifra, osvjezava mrezu  
    const onDelete = (SIFRA) => {
        // provjera ovlascenja za brisanje 
        if (form.ovlfakt == 4) return setModalErrorAuthorizationOpen(true)
        fetch(API + '/usluge/delete/' + SIFRA, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                response.json()
                setForm({
                    SIFRA: '',
                    NAZIV: '',
                    KONTID: '',
                    POREZID: '',
                    JM: '',
                    CENA: '',
                    stavkaPronadjena: false
                })
                setTax(null)
                setAccount(null)
                codeRef.current.focus()
                getTableData()
            })
    }

    // funkcija koja se poziva klikom na dugme Edit
    // postavlja fokus na polje NAZIV 
    // popunjava polja podacima iz mreze
    const onEdit = (item) => {
        item.stavkaPronadjena = true
        setAccount(null)
        setTax(null)
        selectDataAccount.map((account) => {
            if (account.ID == item.KONTID) {
                setAccount(account)
            }
        })

        selectDataTax.map((tax) => {
            if (tax.ID == item.POREZID) {
                setTax(tax)
            }
        })

        setForm(item)
        const timeout = setTimeout(() => {
            nameRef.current.focus()
        }, 100);
        return () => {
            clearTimeout(timeout);
        };
    }

    // funkcija koja se poziva prilikom promjene vrijednosti u inputu
    const handleChange = (event) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name;

        setForm((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    // funkcija koja se poziva klikom na dugme 'Stampa'
    const onPrint = () => {
        setSpinner(true)
        if (serviceCharge && serviceCharge.length === 0) {
            setSpinner(false)
            setModalErrorOpenData(true)
        } else {

            // uslov preko koga saljemo naziv preduzeca sa frontenda na bekend
            // ako je objekat form popunjen, napuni polje NAZIV_PRED iz lokal storidza
            // poziva api za stampu, otvara izvjestaj(API + data.link)
            if (form) {
                form.NAZIV_PRED = form.companyNaziv
            }
            fetch(API + `/usluge/print`, {
                method: 'POST',
                body: JSON.stringify(form),
                responseType: 'blob',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-type': 'application/json; charset=UTF-8'
                },
            })
                .then((response) => response.json())
                .then(data => {
                    if (typeof window != 'undefined') {
                        setTimeout(() => {
                            window.open(API + data.link)
                        }, 200);
                    }
                    setSpinner(false)
                })
        }
    }

    // propsovi za auto komplit
    const defaultPropsAccount = {
        options: selectDataAccount,
        getOptionLabel: (option) => option ? `${option.KONTO} ${option.NAZ}` : '',
    };

    // propsovi za auto komplit
    const defaultPropsTax = {
        options: selectDataTax,
        getOptionLabel: (option) => option ? `${option.SIFRA} ${option.NAZIV}` : '',
    };

    // uslov: ako nije upisana ni vrijednost, izbaci poruku, setuje crveni okvir i fokusira polje  
    // u suprotnom prolazi dalje
    const checkCodeRequired = (value) => {
        if (!value || value == 0) {
            setErrorMsgCode('Šifra je obavezna!')
            setErrorInputCode(true)
            codeRef.current.focus()
            return false
        } else {
            setErrorMsgCode(null)
            setErrorInputCode(false)
            return true
        }
    }

    // uslov: ako nije upisana ni vrijednost, izbaci poruku, setuje crveni okvir i fokusira polje  
    // u suprotnom prolazi dalje
    const checkNameRequired = (value) => {
        if (!value || value.length == 0) {
            setErrorMsgName('Naziv je obavezan!')
            setErrorInputName(true)
            nameRef.current.focus();
            return false
        } else {
            setErrorMsgName(null)
            setErrorInputName(false)
            return true
        }
    }

    // funkcije za provjeru obaveznih polja - porez
    const checkTaxRequired = (value) => {
        if (value && !tax || !value && !tax) {
            setErrorMsgTax('Porez mora biti izabran')
            setErrorInputTax(true)
            taxRef.current.focus();
            return false
        } else {
            setErrorMsgTax(null)
            setErrorInputTax(false)
            return true
        }
    }

    // funkcija za kontrolu obaveznih polja
    function unosIspravanDo(name) {
        if (name === 'SIFRA') {
            return true
        }

        if (!checkCodeRequired(form.SIFRA)) {
            return false
        }

        if (name === 'NAZIV') {
            return true
        }

        if (!checkNameRequired(form.NAZIV)) {
            return false
        }

        if (name === 'KONTID') {
            return true
        }

        if (name === 'POREZID') {
            return true
        }

        if (!checkTaxRequired(form.POREZID)) {
            return false
        }

        if (name === 'JM') {
            return true
        }

        if (name === 'CENA') {
            return true
        }

        return true
    }

    // definisu se polja u tabeli i popunjavaju
    const columns = [
        {
            name: 'Šifra',
            selector: row => row.SIFRA,
            // sirina polja po rezolucijama
            width:
                windowSize.width > 1920 ? '100px'
                    : windowSize.width > 1680 ? '100px'
                        : windowSize.width > 1600 ? '100px'
                            : windowSize.width > 1440 ? '100px'
                                : windowSize.width > 1280 ? '100px'
                                    : windowSize.width > 1024 ? '90px'
                                        : '80px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.SIFRA}</span>
                </>
            ),
        },
        {
            name: 'Naziv',
            selector: row => row.NAZIV,
            // sirina polja po rezolucijama
            width:
            windowSize.width > 2048 ? '1050px'
                : windowSize.width > 1920 ? '870px'
                    : windowSize.width > 1680 ? '690px'
                        : windowSize.width > 1600 ? '600px'
                            : windowSize.width > 1440 ? '570px'
                                : windowSize.width > 1280 ? '510px'
                                    : windowSize.width > 1024 ? '380px'
                                        : '400px',
            cell: (row) => (
                <>
                    <span id='media-query-rows-text'>{row.NAZIV}</span>
                </>
            ),
        },
        {
            name: 'Konto',
            selector: row => row.kontoNaziv,
            // sirina polja po rezolucijama
            width:
            windowSize.width > 2048 ? '430px'
                : windowSize.width > 1920 ? '330px'
                    : windowSize.width > 1680 ? '300px'
                        : windowSize.width > 1600 ? '270px'
                            : windowSize.width > 1440 ? '250px'
                                : windowSize.width > 1280 ? '200px'
                                    : windowSize.width > 1024 ? '240px'
                                        : '205px',
            left: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows-text'>{row.kontoNaziv}</span>
                </>
            ),
        },
        {
            name: 'Porez',
            selector: row => row.porezNaziv,
            // sirina polja po rezolucijama
            width:
            windowSize.width > 2048 ? '430px'
                : windowSize.width > 1920 ? '330px'
                    : windowSize.width > 1680 ? '250px'
                        : windowSize.width > 1600 ? '270px'
                            : windowSize.width > 1440 ? '250px'
                                : windowSize.width > 1280 ? '200px'
                                    : windowSize.width > 1024 ? '240px'
                                        : '200px',
            left: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows-text'>{row.porezNaziv}</span>
                </>
            ),
        },
        {
            name: 'Action',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span onClick={() => onEdit(row)} id='media-query-table-buttons' className='btn btn-primary nav flex-column flex-lg-row justify-content-end'>Edit</span>{''}
                    <span onClick={() => { onEdit(row); setModalDeleteItem(true) }} id='media-query-table-buttons' className='btn btn-danger nav flex-column flex-lg-row justify-content-end'>Brisanje</span>
                </>
            ),
        }
    ];

    // funkcija za pretragu iz mreze
    // salje zahtjev na bekend prema upitu sa parametrima koje posaljemo
    // u responsu vraca podatke koje dobio iz upita 
    const searchChange = (e) => {
        e.preventDefault();
        const searchInput = e.target.value
        setSearchInput(searchInput);
        const fetchData = async () => {
            const res = await axios.get(`${API}/usluge/query/${form.companyCode}?q=${searchInput}`, { headers: { "Authorization": `Bearer ${token}` } });
            setServiceCharge(res.data);
        };

        // uslov za za slanje zahtjeva za povlacenje podataka
        if (searchInput.length === 0 || searchInput.length >= 1) fetchData();
    };

    return (

        <div>
            {/* Kontejner za naslov  */}
            <div className="title-container">

                <header className="title-header">
                    {/* Naziv registra */}
                    <h1 id="media-query-title">Usluge</h1>
                </header>

                {/* Klasa za ikonice, nalazi se u istom redu kao i naziv, ikonice su formirane kao lista */}
                <div className="naslov-ikonice">
                    <ul className="nav flex-column flex-lg-row justify-content-end">

                        <li className="nav-item toolbaritem">
                            <input type="image" alt="save" className='dugme-sacuvaj' title='Sačuvaj' tabIndex={7} src={require('./../assets/images/floppydisk.png')} onFocus={() => onSubmit()} />
                        </li>

                        <li className="nav-item toolbaritem">
                            <input type="image" alt="print" className='dugme-stampa' title='Štampa' tabIndex={8} src={require('./../assets/images/printer.png')} onClick={onPrint} />
                        </li>
                    </ul>
                </div>
            </div>

            {/* Kontejner pune sirine */}
            <Container fluid>
                <Row>
                    <div className='products-form'>
                        <Row>
                            {/* Stilovi koji ce se primjenjivati na svim poljima */}
                            <div className="col-4" style={{ display: 'flex', paddingRight: '0px', paddingLeft: '-1px', marginRight: 'auto', marginLeft: '0px' }}>
                                {/* Sirina polja */}
                                <div className="col-2">
                                    <div className='col-form-right'>
                                        {/* Provjera da li je polje popunjeno jer je obavezno */}
                                        <div className={errorInputCode ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px', marginBottom: '0px' }} >
                                            <label id="media-query-label">Šifra</label>
                                            <NumericFormat
                                                type="text"
                                                className='field-alignment p-1'
                                                getInputRef={codeRef}
                                                maxLength="10"
                                                decimalScale={0}
                                                autoFocus={true}
                                                id="media-query-input-text"
                                                tabIndex={1}
                                                placeholder="Šifra"
                                                name='SIFRA'
                                                value={form?.SIFRA}
                                                onFocus={() => {
                                                    getServiceChargeFirstFreeNumberSifra()
                                                    unosIspravanDo("SIFRA")
                                                }}
                                                // Ako je izmjena na polju
                                                onChange={(e) => handleChange(e)}
                                                onClick={() => {
                                                    // ako je kliknuto na dugme Edit, a zatim na sifru - cisti polja  
                                                    setForm(prev => ({
                                                        ...prev,
                                                        NAZIV: '',
                                                        KONTID: '',
                                                        POREZID: '',
                                                        JM: '',
                                                        CENA: '',
                                                        stavkaPronadjena: false
                                                    }))
                                                    setAccount(null)
                                                    setTax(null)
                                                }} />
                                            {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                                            {errorMsgCode ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgCode}</span> : ''}
                                        </div>
                                    </div>
                                </div>

                                {/* Sirina polja */}
                                <div className="col-10">
                                    <div className={errorInputName ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px' }} >
                                        <label id="media-query-label">Naziv</label>
                                        <input type="text"
                                            id="media-query-input-text"
                                            maxLength="40"
                                            ref={nameRef}
                                            tabIndex={2}
                                            placeholder="Naziv"
                                            name='NAZIV'
                                            value={form ? form.NAZIV : ''}
                                            onFocus={() => {
                                                unosIspravanDo("NAZIV")
                                                // uslov za citanje sloga po sifri
                                                if (unosIspravanDo("NAZIV")) {
                                                    let selectedItem = []
                                                    selectedItem = serviceCharge.filter((c) => c.SIFRA == form.SIFRA)
                                                    if (selectedItem.length > 0) {
                                                        onEdit(selectedItem[0])
                                                    }
                                                }
                                            }}
                                            // Ako je izmjena na polju
                                            onChange={(e) => handleChange(e)} />
                                        {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                                        {errorMsgName ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgName}</span> : ''}
                                    </div>
                                </div>
                            </div>

                            <div className="col-8" style={{ display: 'flex', paddingRight: '0px', paddingLeft: '0px', marginRight: 'auto', marginLeft: '0px' }}>

                                <div className="col-5">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label id="media-query-label">Konto</label>
                                        <Autocomplete
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            {...defaultPropsAccount}
                                            value={account}
                                            disablePortal={true}
                                            onFocus={() => {
                                                unosIspravanDo("KONTID")
                                            }}
                                            onChange={(event, newValue) => {
                                                setForm(prev => ({ ...prev, KONTID: newValue && newValue != null ? newValue.ID : '' }))
                                                setAccount(newValue);
                                                // fokus se mora vratiti inace tabIndex ne radi dobro
                                                accountRef.current.focus();
                                            }}

                                            renderInput={(params) => (
                                                <TextField {...params}
                                                    className="clients-textinput"
                                                    tabIndex={3}
                                                    name='KONTO'
                                                    // fokus se mora vratiti inace tabIndex ne radi dobro(ne ide na upis)
                                                    onFocus={() => {
                                                        accountRef.current.focus();
                                                    }}
                                                    ref={accountRef}
                                                    placeholder=' Izaberite konto'
                                                    variant="standard" />
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className="col-3">
                                    <div className={errorInputTax ? 'inputWithError' : 'field-container'} style={{ marginRight: '3px' }} >
                                        <label id="media-query-label">Porez</label>
                                        <Autocomplete
                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                            {...defaultPropsTax}
                                            value={tax}
                                            disablePortal={true}
                                            onFocus={() => {
                                                unosIspravanDo("POREZID")
                                            }}
                                            onChange={(event, newValue) => {
                                                setTax(newValue);
                                                setForm(prev => ({ ...prev, POREZID: newValue && newValue != null ? newValue.ID : '' }))
                                                // fokus se mora vratiti inace tabIndex ne radi dobro
                                                taxRef.current.focus();
                                            }}

                                            renderInput={(params) => (
                                                <TextField {...params}
                                                    className="clients-textinput"
                                                    name='POREZID'
                                                    ref={taxRef}
                                                    tabIndex={4}
                                                    placeholder='Porez'
                                                    variant="standard" />
                                            )}
                                        />
                                        {errorMsgTax ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgTax}</span> : ''}
                                    </div>
                                </div>

                                <div className="col-1">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label id="media-query-label">JM</label>
                                        <input
                                            type="text"
                                            id="media-query-input-text"
                                            maxLength="3"
                                            tabIndex={5}
                                            placeholder="jm"
                                            name='JM'
                                            value={form ? form.JM : ''}
                                            onChange={(e) => handleChange(e)}
                                            onFocus={() => {
                                                unosIspravanDo("JM")
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="col-2">
                                    <div className='col-form-right'>
                                        <div className='field-container' style={{ marginRight: '3px' }}>
                                            <label id="media-query-label">Cijena</label>
                                        </div>
                                        <NumericFormat
                                            type="text"
                                            className='field-alignment p-1'
                                            getInputRef={priceRef}
                                            id="media-query-input-text"
                                            maxLength="10"
                                            tabIndex={6}
                                            decimalScale={3}
                                            placeholder="Cijena"
                                            name='CENA'
                                            value={form ? form.CENA : ''}
                                            onFocus={() => {
                                                unosIspravanDo("CENA")
                                            }}
                                            onChange={(e) => handleChange(e)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </div>
                </Row >
            </Container >

            {/* Stilovi i klase za polje za pretragu;  */}
            < div style={{ display: 'flex' }
            } className='search' >
                {/* vrsta inputa; poziva funkciju za pretragu(searchChange);  */}
                < input type='text' tabIndex='-1' id="media-query-input-text" placeholder='Pretraga...'
                    onChange={(e) => {
                        // pretraga mreze(ukoliko je unesen bilo koji karakter, vrsi pretragu)
                        if (searchInput.length >= 0) {
                            searchChange(e)
                        } else {
                            getTableData()
                        }
                    }} value={searchInput} />
            </div >

            {/* Mreza */}
            < DataTable
                columns={columns}
                data={serviceCharge ? serviceCharge : []}
                pagination
                responsive
                highlightOnHover
                striped
                dense
                pointerOnHover
                paginationComponentOptions={defaultComponentOptions}
                paginationPerPage={[windowSize.height > 1300 ? 28
                    : windowSize.height > 1270 ? 26
                    : windowSize.height > 1222 ? 25
                    : windowSize.height > 1200 ? 24
                    : windowSize.height > 1000 ? 20
                    : windowSize.height > 920 ? 17
                    : windowSize.height > 900 ? 16
                    : windowSize.height > 880 ? 15
                    : windowSize.height > 820 ? 14
                    : windowSize.height > 720 ? 13
                                        : 4]}
                progressPending={pending}
            />

            {/* render za poruku */}
            <div>
                <Modal isOpen={Boolean(modalDeleteItem)} centered>
                    <ModalHeader>Brisanje stavke</ModalHeader>
                    <ModalBody>
                        <span>{`Da li želite da obrišete stavku broj ${form.SIFRA} ? `}</span>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            <button
                                onClick={() => { setModalDeleteItem(false); nameRef.current.focus() }}
                                className="button-no">Ne
                            </button>
                            <button
                                onClick={() => {
                                    onDelete(form.SIFRA);
                                    setModalDeleteItem(false)
                                }}
                                className="button-yes">Da
                            </button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            {/* render za poruku */}
            <div>
                <Modal isOpen={Boolean(modalErrorAuthorizationData)} centered  >
                    <ModalHeader>Upozorenje!</ModalHeader>
                    <ModalBody>
                        <span>{`Nemate ovlašćenje za brisanje!`}</span>
                    </ModalBody>
                    <ModalFooter className="justify-content-center">
                        <div>
                            <button onClick={() => setModalErrorAuthorizationOpen(false)} className="button-yes">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            {/* Modalni form za gresku; prikazuje se ako nisu unijeta obavezna polja */}
            < div >
                <Modal isOpen={Boolean(modalErrorOpen)} centered  >
                    <ModalHeader>Neispravan unos</ModalHeader>
                    <ModalBody>
                        <span>{`Unesite obavezna polja!`}</span>
                    </ModalBody>
                    <ModalFooter className="justify-content-center">
                        <div>
                            <button onClick={() => setModalErrorOpen(false)} className="button-yes">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div >

            {/* Modalni form za gresku */}
            < div >
                <Modal isOpen={Boolean(modalErrorOpenData)} centered  >
                    <ModalHeader>Nema podataka!</ModalHeader>
                    <ModalFooter className="justify-content-center">
                        <div>
                            {/* Dugme za zatvaranje modalnog forma */}
                            <button onClick={() => setModalErrorOpenData(false)} className="button-yes">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div >

            <div>
                {spinner ? <Spinner className='spinner' color="primary" id='loader' /> : ''}
            </div>
        </div >
    );
}

export default Usluge;


