import React, { useEffect, useState, useRef } from 'react';
import { API } from '../constants';

let result = false;
let resultNomen = false;
let stavkaPronadjena = false;
let dataNomen = [];

// funkcija koja poziva citanje cjenovnika
const getCjenovnikData = (sklaID, nomenID) => {
  fetch(API + `/cjenovnik/all`, {
    method: 'POST',
    body: JSON.stringify({ sklaId: sklaID, nomenId: nomenID }),
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-type': 'application/json; charset=UTF-8'
    },
  })
    .then((response) => response.json())
    .then(data => {
      console.log(data, 'dataCjenovnik')
      if (data && data.length) {
        result = true;
      } else {
        result = false;
      }
    })

}

export function numberWithCommasColumns(x, noZeros = false) {
  if (x) {
    let val = Number(x);
    val = Math.round(val * 100) / 100;
    if (val) {
      let returnValue = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      if (!noZeros) {
        let returnValueSplit = returnValue.split(".");
        if (returnValueSplit && returnValueSplit[1]) {
          if (returnValueSplit[1].length == 1) {
            returnValue += "0";
          }
        } else {
          returnValue += ".00";
        }
      }

      return returnValue;
    } else {
      if (!noZeros) {
        return "0.00";
      } else {
        return "0";
      }
    }
  } else {
    if (!noZeros) {
      return "0.00";
    } else {
      return "0";
    }
  }
}

export function numberWithCommasColumns3(x, noZeros = false) {
  if (x) {
    let val = Number(x);
    val = Math.round(val * 1000) / 1000;
    if (val) {
      let returnValue = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      if (!noZeros) {
        let returnValueSplit = returnValue.split(".");
        if (returnValueSplit && returnValueSplit[1]) {
          if (returnValueSplit[1].length == 1) {
            returnValue += "0";
          }
        } else {
          returnValue += ".000";
        }
      }

      return returnValue;
    } else {
      if (!noZeros) {
        return "0.00";
      } else {
        return "0";
      }
    }
  } else {
    if (!noZeros) {
      return "0.00";
    } else {
      return "0";
    }
  }
}

const companyCode =
  typeof window !== "undefined" ? localStorage.getItem("company") : null;
const companyName =
  typeof window !== "undefined" ? localStorage.getItem("companyNaziv") : null;
const companyPdv =
  typeof window !== "undefined" ? localStorage.getItem("companyPdv") : null;
const companyJib =
  typeof window !== "undefined" ? localStorage.getItem("companyJib") : null;
const companyAddress =
  typeof window !== "undefined" ? localStorage.getItem("companyAddress") : null;
const companyPlaces =
  typeof window !== "undefined" ? localStorage.getItem("companyPlaces") : null;
const companyMb =
  typeof window !== "undefined" ? localStorage.getItem("companyMb") : null;
const companyTelephone =
  typeof window !== "undefined"
    ? localStorage.getItem("companyTelephone")
    : null;
const companyYear =
  typeof window !== "undefined" ? localStorage.getItem("companyYear") : null;
const BankAccount =
  typeof window !== "undefined" ? localStorage.getItem("BankAccount") : null;
const BankAccount1 =
  typeof window !== "undefined" ? localStorage.getItem("BankAccount1") : null;
const pdv =
  typeof window !== "undefined" ? localStorage.getItem("pdv") : null;
const userCode =
  typeof window !== "undefined" ? localStorage.getItem("userCode") : null;
const token =
  typeof window !== "undefined" ? localStorage.getItem("accessToken") : null;

export const ovlfik =
  typeof window !== "undefined" ? localStorage.getItem("ovlfik") : null;
export const ovlrobno =
  typeof window !== "undefined" ? localStorage.getItem("ovlrobno") : null;
export const ovlfakt =
  typeof window !== "undefined" ? localStorage.getItem("ovlfakt") : null;
export const ovlblagajna =
  typeof window !== "undefined" ? localStorage.getItem("ovlblagajna") : null;
export const ovlascenje =
  typeof window !== "undefined" ? localStorage.getItem("ovlascenje") : null;

// promjenljive za provjeru vidljivosti aplikacija
export const robno =
  typeof window !== "undefined" ? localStorage.getItem("robno") : null;
export const finansije =
  typeof window !== "undefined" ? localStorage.getItem("finansije") : null;
export const fakturisanje =
  typeof window !== "undefined" ? localStorage.getItem("fakturisanje") : null;
export const blagajna =
  typeof window !== "undefined" ? localStorage.getItem("blagajna") : null;
export const kufkif =
  typeof window !== "undefined" ? localStorage.getItem("kufkif") : null;

//promjenljive za citanje polja iz tabele opcije
export const zal_plus =
  typeof window !== "undefined" ? localStorage.getItem("zal_plus") : null;
export const cjenovnik =
  typeof window !== "undefined" ? localStorage.getItem("cjenovnik") : null;

export function getCompanyData(form) {
  form.companyCode = companyCode;
  form.companyName = companyName;
  form.companyPdv = companyPdv;
  form.companyJib = companyJib;
  form.companyAddress = companyAddress;
  form.companyPlaces = companyPlaces;
  form.companyMb = companyMb;
  form.companyTelephone = companyTelephone;
  form.companyYear = companyYear;
  form.token = token;
  form.BankAccount = BankAccount;
  form.BankAccount1 = BankAccount1;
  form.Pdv = pdv;
  form.userCode = userCode;
  form.ovlfik = ovlfik;
  form.ovlfakt = ovlfakt;
  form.zal_plus = zal_plus;
  form.cjenovnik = cjenovnik;
  return form;
}

// funkcija koja se poziva klikom na dugme 'Sacuvaj'
const onSubmitNomen = (CENA, CENAM, MALCEN, ID, dataNomen) => {

  // poziva api za upis
  fetch(API + `/artikal/upisCijena/${companyCode}`, {
    method: 'POST',
    body: JSON.stringify({ CENA: CENA, CENAM: CENAM, MALCEN: MALCEN, ID: ID, stavkaPronadjena: stavkaPronadjena, dataNomen: dataNomen }),
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-type': 'application/json; charset=UTF-8'
    },
  })
    .then((response) => {
      response.json()
    })
}

const onSubmitCjenovnik = (sklaID, NOMENID, PRODCENA, NABCENA, SIFKOR, izmjenaCijene, stavkaPronadjenaCjenovnik) => {
  // form.NOMENID = form.ID
  fetch(API + `/cjenovnik/new`, {
    method: 'POST',
    body: JSON.stringify({ SKLAID: sklaID, NOMENID: NOMENID, NABCENA: NABCENA, PRODCENA: PRODCENA, SIFKOR: SIFKOR, izmjenaCijene: izmjenaCijene, stavkaPronadjenaCjenovnik: stavkaPronadjenaCjenovnik }),
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-type': 'application/json; charset=UTF-8'
    },
  })
    .then((response) => {
      response.json()
    })
}

// funkcija koja poziva citanje artikla
const getNomenData = (NOMENID) => {
  fetch(API + `/artikal/findNomen/${companyCode}`, {
    method: 'POST',
    body: JSON.stringify({ nomenId: NOMENID }),
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-type': 'application/json; charset=UTF-8'
    },
  })
    .then((response) => response.json())
    .then(data => {
      console.log(data, 'data getNomenData')
      if (data && data.length) {
        dataNomen = data[0]
        resultNomen = true;
        console.log(dataNomen, 'dataNomen')
        console.log(resultNomen, 'resultNomen getNomenData')
      } else {
        resultNomen = false;
      }
    })
}

export const defaultComponentOptions = {
  rowsPerPageText: "Redovi po stranici:",
  rangeSeparatorText: "od",
  noRowsPerPage: true,
  selectAllRowsItem: true,
  selectAllRowsItemText: "Sve",
};

const roundTo = (number, places) => {
  const factor = 10 ** places;
  return Math.round(number * factor) / factor;
};

export function roundTO(number, places) {
  const factor = 10 ** places;
  return Math.round(number * factor) / factor;
}

// validacija broja i obaveznog polja
export function helperValidateOnlyNumbers(input) {
  if (input.replace(/^[0-9.]+$/, "").length >= 0) {
    return "Dozvoljeni su samo brojevi!";
  } else if (input.length < 1) {
    return "Polje je obavezno!";
  } else {
    return false;
  }
}

// funkcija za validaciju praznog polja tipa varchar
const helperValidateEmptyField = (input) => {
  // ako je duzina unosa manja od 1(ako nije nista unijeto u polje)
  if (input.length < 1) return "Polje je obavezno!";
};

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function getFakturniIznos(KOL, FAKTCEN) {
  let _KOL = KOL;
  let _FAKTCEN = FAKTCEN;
  let IZNFAKT = roundTo(_KOL * _FAKTCEN, 2);
  //    console.log(IZNFAKT)
  return IZNFAKT;
}

// export function getNabavniIznos(KOL, NABCEN) {
//   //  console.log(KOL, NABCEN)
//   let _KOL = KOL;
//   let _NABCEN = NABCEN;
//   let IZND = roundTo(_KOL * _NABCEN, 2);
//   //   console.log(IZND)
//   return IZND;
// }

// export function getFakturnaCijena(KOL, IZNFAKT) {
//   let _KOL = KOL;
//   let _IZNFAKT = IZNFAKT;
//   let FAKTCEN = roundTo(_IZNFAKT / _KOL, 2);
//   return FAKTCEN;
// }

// export function getIznosRabataIzlaz(RABVEL, IZNMALD) {
//   let _RABVEL = RABVEL;
//   let _IZNMALD = IZNMALD;
//   let IZNOSRAB = roundTo((_IZNMALD * _RABVEL) / 100, 2);
//   return IZNOSRAB;
// }

export function getProcenatRabataIzlaz(IZNOSRAB, IZNMALD) {
  let _IZNOSRAB = IZNOSRAB;
  let _IZNMALD = IZNMALD;
  let RABVEL = roundTo((_IZNOSRAB * 100) / _IZNMALD, 2);
  return RABVEL;
}

export function getPorezUIznosu(PDV, KOL, MALCEN) {
  let _PDV = PDV;
  let _KOL = KOL;
  let _CENA = MALCEN;
  let IZPDV = roundTo((_KOL * _CENA * _PDV) / (_PDV + 100), 2);
  //  let osnovica = (_IZNMALD - IZPDV).toFixed(2);
  return IZPDV;
}

export function getPorezIzIznosa(PDV, KOL, MALCENP) {
  let _PDV = PDV;
  let _KOL = KOL;
  let _MALCENP = MALCENP;
  let IZPDV = roundTo((_KOL * _MALCENP * _PDV) / 100, 4);
  //  let osnovica = (_IZNMALD - _IZNOSRAB).toFixed(2);
  return IZPDV;
}

export function getULPDV(IZNFAKT, IZNRABAT, PDV) {
  //console.log(IZNFAKT, 'IZNFAKT', IZNRABAT, 'IZNRABAT', PDV, 'PDV')
  let _PDV = PDV;
  let _IZNFAKT = IZNFAKT;
  let _IZNRABAT = IZNRABAT;
  let ULPDV = roundTo(((_IZNFAKT - _IZNRABAT) * _PDV) / 100, 2);
  //  let osnovica = (_IZNMALD - _IZNOSRAB).toFixed(2);
  return ULPDV;
}

export function getIznosMarzeMPredrac(IZNMALDP, IZND, IZPDV, IZNOSRAB) {
  //  let _KOL = KOL;
  let _IZNMALD = IZNMALDP;
  let _IZND = IZND;
  let _IZPDV = IZPDV;
  let _IZNOSRAB = IZNOSRAB;
  let IZNRAZD = roundTo(_IZNMALD - _IZNOSRAB - _IZND - _IZPDV, 2);
  //  let osnovica = (_IZNMALD - _IZNOSRAB).toFixed(2);
  return IZNRAZD;
}

// export function getIznosMarzeV(IZNMALD, IZND, IZNOSRAB) {
//   //   console.log(IZNMALD, IZND)
//   let _IZNMALD = IZNMALD;
//   let _IZND = IZND;
//   let _IZNOSRAB = IZNOSRAB;
//   let IZNRAZD = roundTo(_IZNMALD - _IZND - _IZNOSRAB, 2);
//   //  let osnovica = (_IZNMALD - _IZNOSRAB).toFixed(2);
//   return IZNRAZD;
// }

// export function getProcenatMarzeM(IZNRAZD, IZND) {
//   // console.log(IZNRAZD,IZND)
//   let _IZND = IZND;
//   let _IZNRAZD = IZNRAZD;
//   let RAZCEN = roundTo((_IZNRAZD * 100) / _IZND, 3);
//   //   console.log(RAZCEN)
//   return RAZCEN;
// }

// export function getProcenatMarzeV(IZND, IZNRAZD) {
//   //   console.log(IZND, IZNRAZD)
//   let _IZND = IZND;
//   let _IZNRAZD = IZNRAZD;
//   let RAZCEN = roundTo((_IZNRAZD * 100) / _IZND, 3);
//   //    console.log(RAZCEN)
//   return RAZCEN;
// }

// export function getProdajnaCijena(KOL, IZNMALD, IZNOSRAB) {
//   //   console.log(IZNMALD,IZNOSRAB)
//   let _KOL = KOL;
//   let _IZNMALD = IZNMALD;
//   let _IZNOSRAB = IZNOSRAB;
//   let MALCENP = roundTo((_IZNMALD - _IZNOSRAB) / _KOL, 2);
//   //  console.log(MALCENP);
//   return MALCENP;
// }

// export function getProdajniIznos(IZNMALD, IZNOSRAB) {
//   //   console.log(IZNMALD,IZNOSRAB)
//   let _IZNMALD = IZNMALD;
//   let _IZNOSRAB = IZNOSRAB;
//   let IZNMALDP = roundTo(_IZNMALD - _IZNOSRAB, 2);
//   //   console.log(IZNMALDP);
//   return IZNMALDP;
// }

// export function getRabatProcenat(IZNOSRAB, IZNMALD) {
//   //      console.log(IZNOSRABP,IZNMALD)
//   let _IZNMALD = IZNMALD;
//   let _IZNOSRAB = IZNOSRAB;
//   let RABVEL = roundTo((_IZNOSRAB * 100) / _IZNMALD, 2);
//   //     console.log(RABVEL);
//   return RABVEL;
// }

// funkcije vezane za bootstrap tabelu i paginaciju
//
// klik na paginaciju
export const onPaginationClick = (event, setCurrentPage) => {
  event.preventDefault();
  setCurrentPage(Number(event.target.id));
};
// klik na prethodnu(broj ispod) stranu
export const onPreviosClick = (event, pageNumber, setCurrentPage) => {
  event.preventDefault();
  pageNumber - 1 > 0 ? setCurrentPage(pageNumber - 1) : setCurrentPage(1);
};
// klik na sljedecu(broj iznad) stranu
export const onNextClick = (event, pageNumber, setCurrentPage, duzinaNiza) => {
  event.preventDefault();
  pageNumber < duzinaNiza
    ? setCurrentPage(pageNumber)
    : setCurrentPage(duzinaNiza);
};
// klika na prvu (pocetnu) stranu
export const onFirstClick = (event, setCurrentPage) => {
  event.preventDefault();
  setCurrentPage(1);
};
// klik na zadnju stranu
export const onLastClick = (event, setCurrentPage, duzinaNiza) => {
  event.preventDefault();
  // console.log(setCurrentPage, duzinaNiza, 'last');
  setCurrentPage(duzinaNiza);
};

// izracunavanje fakturnog iznosa (kolicina, fakturna cijena)
// export function getFakturniIznos(KOL, FAKTCEN) {
//   let _KOL = KOL;
//   let _FAKTCEN = FAKTCEN;
//   let IZNFAKT = roundTo(_KOL * _FAKTCEN, 2);
//   return IZNFAKT;
// }

// izracunavanje nabavnog iznosa (kolicina , nabavna cijena)
export function getNabavniIznos(KOL, NABCEN) {
  let nabavniIznos = KOL * NABCEN;
  return roundTO(nabavniIznos, 2);
}

// izracunavanje nabavnog iznosa (iznostroskova, kolicina , nabavna cijena)
export function getNabavnaCijena(IZNTROSKOVA, KOL, NABCEN) {
  let iznos = roundTO(IZNTROSKOVA / KOL, 3);
  let nabavniIznos = iznos + NABCEN;
  return roundTO(nabavniIznos, 3);
}
// izracunavanje fakturne cijene (kolicina , fakturni iznos)
export function getFakturnaCijena(KOL, IZNFAKT) {
  let _KOL = KOL;
  let _IZNFAKT = IZNFAKT;
  let FAKTCEN = roundTo(_IZNFAKT / _KOL, 2);
  return FAKTCEN;
}

// izracunavanje maloprodajne cijene (cijena, kolicina)
export function getIznosMaloprodaje(CIJENA, KOL) {
  return roundTo(CIJENA * KOL, 2);
}

// izracunavanje procenta rabata  (iznos rabata, iznos maloprodaje)
// export function getProcenatRabataIzlaz(IZNOSRAB, IZNMALD) {
//   let _IZNOSRAB = IZNOSRAB;
//   let _IZNMALD = IZNMALD;
//   let RABVEL = roundTo((_IZNOSRAB * 100) / _IZNMALD, 2);
//   return RABVEL;
// }

// export function getPorezUIznosu(PDV, KOL, MALCEN) {
//   let _PDV = PDV;
//   let _KOL = KOL;
//   let _CENA = MALCEN;
//   let IZPDV = roundTo((_KOL * _CENA * _PDV) / (_PDV + 100), 1);
//   return IZPDV;
// }

// izracuvanje poreza u nivelaciji
export function getIznosPDVa(VRSKLAD, PDV, KOL, MALCEN) {
  let vrstaSkladista = VRSKLAD;
  let ukupanPorez = getPorezUIznosu(PDV, KOL, MALCEN);
  let porezPoStavci =
    vrstaSkladista === "M" ? roundTo(ukupanPorez / KOL, 2) : 0;
  return porezPoStavci;
}

// izracunavanje vrijednosti cijene bez rabat-a ( rabat(procenat), fakturna cijena)
export function getFakcenMinusRabatUlaz(RABAT, FAKTCEN) {
  let umanjenaCIjena = roundTO(FAKTCEN - (RABAT / 100) * FAKTCEN, 3);
  return umanjenaCIjena;
}

// izracunavanje vrijednosti cijene bez rabat-a ( rabat(procenat), fakturna cijena)
export function getFakcenMinusRabat(RABAT, MALCEN) {
  let umanjenaCIjena = roundTO(MALCEN - (RABAT / 100) * MALCEN, 3);
  return umanjenaCIjena;
}

// izracunavanje vrijednosti pdv-a ( pdv(procenat), kolicina , maloprodajna cijena)
// export function getPorezIzIznosa(PDV, KOL, MALCENP) {
//   let _PDV = PDV;
//   let _KOL = KOL;
//   let _MALCENP = MALCENP;
//   let IZPDV = roundTo((_KOL * _MALCENP * _PDV) / 100, 4);
//   return IZPDV;
// }

// dobijanje iznosa na osnovu procenta
export function iznosNaOsnovuProcenta(quantity, percent) {
  return roundTO((quantity * percent) / 100, 2);
}

// dobijanje procenta na osnovu iznosa
export function procenatNaOsnovuIznosa(result, quantity, KOL) {
  let procenat = roundTo((result / quantity) * 100, 2);
  return roundTo(procenat / KOL, 2);
}

// dobijanje iznosa marze
export function getIznosMarze(
  VRSKLAD,
  IZNMALDP,
  IZNTROSAK,
  IZND,
  IZPDV,
  IZNOSRAB) {
  let selektovanaVrstaSkladista = VRSKLAD;
  let result = 0;
  if (selektovanaVrstaSkladista === "M") {
    result = getIznosMarzeM(IZNMALDP, IZNTROSAK, IZND, IZPDV, IZNOSRAB);
  } else {
    result = getIznosMarzeV(IZNMALDP, IZND, IZNOSRAB, IZNTROSAK);
  }
  return result;
}

// koristi se za izracunavanje marze u nazad (preko iznosa)
export function getIznosMarzeM(IZNMALDP, IZNTROSAK, IZND, IZPDV, IZNOSRAB) {
  //console.log( IZNMALDP, 'IZNMALDP', IZNTROSAK, 'IZNTROSAK', IZND, 'IZND', IZPDV, 'IZPDV', IZNOSRAB, 'IZNOSRAB')
  let _IZNMALD = IZNMALDP;
  let _IZND = roundTO(IZND, 2);
  let _IZPDV = IZPDV;
  let _IZNOSRAB = IZNOSRAB;
  let IZNRAZD = roundTo(_IZNMALD - IZNTROSAK - _IZNOSRAB - _IZND - _IZPDV, 2);
  return IZNRAZD;
}

export function getIznosMarzeV(IZNMALD, IZND, IZNOSRAB, IZNTROSAK) {
  let _IZNMALD = IZNMALD;
  let _IZND = IZND;
  let _IZNOSRAB = IZNOSRAB;
  let _IZNTROSAK = IZNTROSAK;
  let IZNRAZD = roundTo(_IZNMALD - _IZND - _IZNOSRAB - _IZNTROSAK, 2);
  return IZNRAZD;
}

// kalkulacija marze , sabiranje fakt. cijene - procenta rabata + procenta marze (RAZCEN) i dijenjenje sa KOLICINOM te + pdv
export function getMarza(FAKTCEN, IZNRABAT, RAZCEN) {
  let nabavnaCijena = FAKTCEN;
  let rabat = IZNRABAT;
  let marzaP = RAZCEN;
  let total = roundTO(nabavnaCijena - rabat, 2);
  let percentageTotal = total * (marzaP / 100);
  let result = roundTO(percentageTotal, 2);
  return result;
}

// kalkulacija procenta marze na osnovu iznosa marze i nabavnog iznosa
export function getProcenatMarze(IZNRAZD, IZND) {
  // console.log(IZNRAZD, 'IZNRAZD', IZND, 'IZND')
  return roundTO((IZNRAZD * 100) / IZND, 3);
}

// nazad
export function getMarzavelFromValues(IZNMALD, IZNRABAT, marzaVrijednost) {
  let vrijednost = marzaVrijednost;
  let iznosRabata = IZNRABAT;
  let cijena = IZNMALD;
  let total = cijena - iznosRabata;
  let procenat = roundTO((vrijednost / total) * 100, 3);
  return procenat;
}

// kalkulacija cijene, u zavisnosti od vrste skladista.
export function calculateFinalPrice(
  VRSKLAD,
  FAKTCEN,
  IZNRABAT,
  IZNOSMARZE,
  PDV
) {
  let result = 0;
  let vrstaSkladista = VRSKLAD;

  // maloprodajna cijena
  if (vrstaSkladista === "M") {
    result = calculateFinalPriceM(FAKTCEN, IZNRABAT, IZNOSMARZE, PDV);
  }
  // veleprodajna cijena
  if (vrstaSkladista !== "M") {
    result = calculateFinalPriceV(FAKTCEN, IZNRABAT, IZNOSMARZE);
  }
  return result;
}

// kalkulacija veleprodajne cijene
export function calculateFinalPriceM(FAKTCEN, IZNRABAT, IZNOSMARZE, PDV) {
  let result = 0;
  let reducedPrice = FAKTCEN - (FAKTCEN * IZNRABAT) / 100;
  let increasedPrice = reducedPrice + (reducedPrice * IZNOSMARZE) / 100;
  let finalPrice = increasedPrice + (increasedPrice * PDV) / 100;
  result = roundTO(finalPrice, 2);
  return result;
}

// kalkulacija veleprodajne cijene
export function calculateFinalPriceV(FAKTCEN, IZNRABAT, IZNOSMARZE) {
  let result = 0;
  let reducedPrice = FAKTCEN - (FAKTCEN * IZNRABAT) / 100;
  let increasedPrice = reducedPrice + (reducedPrice * IZNOSMARZE) / 100;
  result = roundTO(increasedPrice, 2);
  return result;
}

// kalkulacija iznosa
export function calculateFinalSum(
  VRSKLAD,
  FAKTCEN,
  IZNRABAT,
  IZNOSMARZE,
  PDV,
  KOL
) {
  let selektovanaVrstaSkladista = VRSKLAD;

  let finalPrice = calculateFinalPrice(
    VRSKLAD,
    FAKTCEN,
    IZNRABAT,
    IZNOSMARZE,
    selektovanaVrstaSkladista === "M" ? PDV : 0
  );
  let finalSum = roundTO(finalPrice * KOL, 3);
  return finalSum;
}

export function getProcenatMarzeM(IZNRAZD, IZND) {
  let _IZND = IZND;
  let _IZNRAZD = IZNRAZD;
  let RAZCEN = roundTo((_IZNRAZD * 100) / _IZND, 3);
  return RAZCEN;
}

export function getProcenatMarzeV(IZND, IZNRAZD) {
  let _IZND = IZND;
  let _IZNRAZD = IZNRAZD;
  let RAZCEN = roundTo((_IZNRAZD * 100) / _IZND, 3);
  return RAZCEN;
}

export function getProdajnaCijena(KOL, IZNMALD, IZNOSRAB) {
  let _KOL = KOL;
  let _IZNMALD = IZNMALD;
  let _IZNOSRAB = IZNOSRAB;
  let MALCENP = numberWithCommasColumns3((_IZNMALD - _IZNOSRAB) / _KOL);
  return MALCENP;
}

export function getProdajniIznos(IZNMALD, IZNOSRAB) {
  let _IZNMALD = IZNMALD;
  let _IZNOSRAB = IZNOSRAB;
  let IZNMALDP = roundTo(_IZNMALD - _IZNOSRAB, 2);
  return IZNMALDP;
}

// rabat
export function getRabatProcenat(IZNOSRAB, IZNMALD) {
  let vrijednostRabata = IZNOSRAB;
  let iznosMaloProdaje = IZNMALD;
  let procenatRabata = roundTO((iznosMaloProdaje * vrijednostRabata) / 100, 2);
  return procenatRabata;
}

// naprijed
export function getIznosRabataIzlaz(RABVEL, IZNMALD) {
  // console.log(RABVEL, 'RABVEL', IZNMALD, 'IZNMALD')
  let procenat = RABVEL;
  let cijena = IZNMALD;
  let vrijednost = roundTO((procenat * cijena) / 100, 2);
  return vrijednost;
}
// nazad
export function getRabvelFromValues(rabatVrijednost, IZNMALD) {
  let vrijednostR = rabatVrijednost;
  let vrijednost = IZNMALD;
  let procenat = roundTO((vrijednostR / vrijednost) * 100, 2);
  return procenat;
}

// funkcije vezane za bootstrap tabelu i paginaciju
//
// klik na paginaciju
// export const onPaginationClick = (event, setCurrentPage) => {
//   event.preventDefault();
//   setCurrentPage(Number(event.target.id));
// };
// // klik na prethodnu(broj ispod) stranu
// export const onPreviosClick = (event, pageNumber, setCurrentPage) => {
//   event.preventDefault();
//   pageNumber - 1 > 0 ? setCurrentPage(pageNumber - 1) : setCurrentPage(1);
// };
// // klik na sljedecu(broj iznad) stranu
// export const onNextClick = (event, pageNumber, setCurrentPage, duzinaNiza) => {
//   event.preventDefault();
//   pageNumber < duzinaNiza
//     ? setCurrentPage(pageNumber)
//     : setCurrentPage(duzinaNiza);
// };
// // klik na prvu (pocetnu) stranu
// export const onFirstClick = (event, setCurrentPage) => {
//   event.preventDefault();
//   setCurrentPage(1);
// };
// // klik na zadnju stranu
// export const onLastClick = (event, setCurrentPage, duzinaNiza) => {
//   event.preventDefault();
//   setCurrentPage(duzinaNiza);
// };

//forme
export const defaultFormRegistri = {
  KOMTID: 0,
  SIFRA: 0,
  SIFRAN: 0,
  NAZIV: '',
  MESTO: 0,
  ADRESA: '',
  TEL: '',
  IDENTBROJ: '',
  PUTNIK: 0,
  VALUTA: 0,
  SIFRAP: '',
  JM: '',
  KOMPAK: '',
  OZNAKA: '',
  UVOZNIKID: '',
  POREZID: '',
  BAR_KOD: '',
  BAR_KOD_duplikat: 0,
  GRUPAID: '',
  PODGRUPAID: '',
  BREND: '',
  KATBROJ: '',
  KATBROJ_duplikat: 0,
  FAB_BROJ: '',
  AKCIZA: '',
  OPIS1: '',
  OPIS2: '',
  OPIS3: '',
  NOMENID: 0,
  CIJENA: '',
  KONTID: '',
  BROJ: '',
  VALUTA: '',
  KURS: '',
  PROIZVOD: '',
  VRSTABK: '',
  KOD: '',
  SIFKOR: '',
  SKLAID: '',
  SIFRAS: '',
  ID: 0,
  NABCENA: '',
  PRODCENA: '',
  NOMENIDPREDRACUN: 0,
  NOMENIDPROMETS: 0,
  stavkaPronadjena: false,
  stavkaPronadjenaCjenovnik: false,
  izmjenaCijene: false
};

// forme
export const defaultPrometForm = {
  SKLAID: "",
  PRSID: 0,
  GODINA: "",
  DOK: "",
  VRPROM: "",
  VALUTA: "",
  DPOR: "",
  RACUN: "",
  DPO: "",
  PROMETID: 0,
  ID: 0,
  KSifra: "",
  KURS: "",
  NAZIV: "",
  VRSTA_SKLADISTA: "",
  KOMTID: 0,
  SMJENA: "",
  DEPOZIT: "",
  ZAKLJUCENJE: "",
};

export const defaultForm = {
  IZNOS_RAZLIKE: "",
  RAZLIKA: "",
  NOVA_CENA: "",
  STARA_CENA: "",
  DATUMKNJIG: "",
  SifraArtikla: 0,
  DPO: "",
  ID: 0,
  PROMETID: 0,
  KSifra: "",
  KURS: "",
  NAZIV: "",
  RACUN: "",
  VALUTA: "",
  VRPROM: "",
  DATUM_U: "",
  SIFKOR: 0,
  SKLAID: "",
  DOK: "",
  DPOR: "",
  KOMTID: 0,
  NOMENID: "",
  RBR: 0,
  KOLICINA: "",
  IZNRABAT: "",
  FAKTURNACIJENA: "",
  FAKTURNIIZNOS: "",
  RABAT: "",
  T: "",
  RABATP: "",
  TROSAK: "",
  TROSAKP: "",
  IZNTROSAK: "",
  TROSAK1PER: "",
  IZNTROSAK1PER: "",
  MARZAP: "",
  KUSUR: "",
  MARZA: "",
  CIJENA: "",
  IZNOS: "",
  RABVEL: "",
  IZNOSRAB: "",
  NABCEN: "",
  IZNABCEN: "",
  IZND: "",
  JM: "",
  SIFRA: "",
  VRSTA_SKLADISTA: "",
  MALCEN: "",
  MALCENP: "",
  IZNMALD: "",
  IZNMALDP: "",
  KOL: "",
  PDV: "",
  POREZID: "",
  IZNOS_POREZA: "",
  IZPDV: "",
  ULPDV: "",
  RAZCEN: "",
  IZNRAZD: "",
  PREDRACID: "",
  LAGER: '0.00',
  KOLUL: "",
  KOLIZ: "",
  FAKTCEN: "",
  IZNFAKT: "",
  PRODAJNACIJENA: "",
  IZNOSPRODAJNECIJENE: "",
  GOTOVINA: "",
  VIRMAN: "",
  CEK: "",
  KARTICA: "",
  UKUPNOPLACENO: "",
  OZNAKA: "",
  PROIZVOD: "",
  ZAL_PLUS: "",
  zal_plus: "",
  VAL: "",
  BRISANJE: false,
  ProvjeraLagera: false,
};

// funkcija koja je zaduzena za prikazivanje sljedecih i prethodnih brojeva stranica na paginaciji
export const getPageNumber = (currentPage, duzinaNiza, pageNumbers) => {
  for (
    let i = Math.max(1, currentPage - 2);
    i <= Math.min(duzinaNiza, currentPage + 2);
    i++
  ) {
    pageNumbers.push(i);
  }
};

// funkcija za kontrolu lagera
export function kontrolaLagera(SKLAID, GODINA, PRSID,
  sifranW, vrprom, kolW, izndW, iznmaldW, BRISANJE, vrsta, repository, ZAL_PLUS, lager) {
  console.log('upada u kontrolu lagera')
  let result;
  let resultLager;
  let sklaid = SKLAID;
  let godina = GODINA;
  let prsid = PRSID;
  console.log(sklaid, 'sklaid', godina, 'godina', prsid, 'prsid', sifranW, 'sifranW', vrprom, 'vrprom', kolW, 'kolW', izndW, 'izndW', iznmaldW, 'iznmaldW', ZAL_PLUS, 'ZAL_PLUS', BRISANJE, 'brisanje', vrsta, 'vrsta', repository, 'repository', lager, 'lager')

  let vrstaVeza = '';
  let brisanje = BRISANJE;

  // console.log(vrsta.STRANA, 'vrsta.STRANA', vrsta.VEZA, 'vrsta.VEZA', vrsta.KOEF, 'vrsta.KOEF')
  // vrstaStrana = vrsta.STRANA ? vrsta.STRANA : '';
  vrstaVeza = vrsta.VEZA ? vrsta.VEZA.trim() : '';
  // vrstaKoef = vrsta.KOEF ? vrsta.KOEF : 0;

  // console.log(vrsta.STRANA == 'F' || vrsta.STRANA == 'N', 'vrsta.STRANA == F || vrsta.STRANA == N')
  if (vrsta.STRANA == 'F' || vrsta.STRANA == 'N') {
    return true
  }
  // console.log(vrsta.STRANA == 'U' && vrstaVeza == '', 'vrsta.STRANA == U && vrsta.VEZA == ', vrsta.STRANA, 'vrsta.STRANA', vrstaVeza == '', 'vrsta.VEZA')
  if (vrsta.STRANA == 'U' && vrstaVeza == '') {
    if (vrsta.KOEF == 1) {
      vrprom = "UL"
    } else {
      vrprom = "IZ"
    }
  }

  //  console.log(vrsta.STRANA == 'I' && vrstaVeza == '', 'vrsta.STRANA == I && vrsta.VEZA == ')
  if (vrsta.STRANA == 'I' && vrstaVeza == '') {
    if (vrsta.KOEF == 1) {
      vrprom = "IZ"
    } else {
      vrprom = "UL"
    }
  }

  //console.log(brisanje, 'brisanje')
  // console.log(brisanje == true, 'brisanje == true')
  if (brisanje == true) {
    if (vrprom == 'UL') {
      vrprom = 'IZ'
    } else if (vrprom == 'IZ') {
      vrprom = 'UL'
    } else if (vrprom == 'PU') {
      vrprom = 'PI'
    } else if (vrprom == 'PI') {
      vrprom = 'PU'
    }
  }

  //  console.log(vrprom == 'UL', 'vrprom == UL')
  if (vrprom == 'UL') {
    return true
  }

  result = repository
  //   console.log(result, 'result repository')
  if (!result) {
    return false
  }
  //else {
  //  console.log('!result repository else')
  //  oznakaSkla = repository.VRSTA_SKLADISTA ? repository.VRSTA_SKLADISTA : "";
  //  prosNabCenaSkla = repository.PROSCENA && repository.PROSCENA == 'D' ? true : false;
  //  veznoSkla = repository.VEZNOID ? repository.VEZNOID : "";
  //   console.log(repository, 'repository', repository.VRSTA_SKLADISTA, 'repository.VRSTA_SKLADISTA', repository.PROSCENA , 'repository.PROSCENA ', repository.VEZNOID, 'repository.VEZNOID')
  // }

  // if(form.PRSID != 0) {
  //   result = repository

  //   if(!result) {
  //     return false 
  //   } else {
  //     oznakaPrs = repository.VRSTA_SKLADISTA
  //   }
  // }
  //  console.log(ZAL_PLUS == 'N' && repository.VRSTA_SKLADISTA !== 'P' && repository.PROSCENA == 'N', 'ZAL_PLUS == N && repository.VRSTA_SKLADISTA !== P && !prosNabCenaSkla', ZAL_PLUS, 'ZAL_PLUS', repository.VRSTA_SKLADISTA, 'repository.VRSTA_SKLADISTA', repository.PROSCENA, 'repository.PROSCENA')
  if (ZAL_PLUS == 'N' && repository.VRSTA_SKLADISTA !== 'P' && repository.PROSCENA == 'N') {
    return true
  }
  //  console.log(vrprom == 'PU' || vrprom == 'PU' && repository.VEZNOID !== 0, 'vrprom == PU || vrprom == PU && repository.VEZNOID !== 0')
  if (vrprom == 'PU' || vrprom == 'PU' && repository.VEZNOID !== 0) {
    return true
  }

  // resultLager = lager
  //  if (resultLager) {
  // kolSkla = lager.KOLUL - lager.KOLIZ
  // vredNabSkla = lager.VREDNABUL - lager.VREDNABIZ
  // vredProdSkla = lager.VREDPRODUL - lager.VREDPRODIZ
  console.log(lager, 'result lager')
  //}
  //  console.log(vrprom, 'vrprom')
  if (vrprom == 'IZ') {
    if ((roundTo(kolW), 2) > (roundTo(lager.KOLUL - lager.KOLIZ), 2)) {
      return false
    }
  }

  //  console.log(repository.VRSTA_SKLADISTA == 'P' && (roundTo(iznmaldW), 2) > (roundTo(lager.VREDPRODUL - lager.VREDPRODIZ),2), 'repository.VRSTA_SKLADISTA == P && (roundTo(iznmaldW), 2) > (roundTo(lager.VREDPRODUL - lager.VREDPRODIZ),2)')
  if (repository.VRSTA_SKLADISTA == 'P' && (roundTo(iznmaldW), 2) > (roundTo(lager.VREDPRODUL - lager.VREDPRODIZ), 2)) {
    return false
  }

  //  console.log(repository.PROSCENA == 'D' && (roundTo(izndW), 2) > roundTo(lager.VREDNABUL - lager.VREDNABIZ), 2, 'repository.PROSCENA == D && (roundTo(izndW), 2) > roundTo(lager.VREDNABUL - lager.VREDNABIZ), 2')
  if (repository.PROSCENA == 'D' && (roundTo(izndW), 2) > roundTo(lager.VREDNABUL - lager.VREDNABIZ), 2) {
    return false
  }

  return true;
}

// funkcija za upis cijena
export function upisCijena(NOMENID, SKLAID, GODINA,
  VRSTA_SKLADISTA, LAGER, PRODCENA, NABCENA, SIFKOR) {
  console.log(NOMENID, 'NOMENID', SKLAID, 'SKLAID', PRODCENA, 'PRODCENA', NABCENA, 'NABCENA', SIFKOR)
  console.log('upada u upisCijena')

  let izmjenaCijene = false;
  let stavkaPronadjenaCjenovnik = false;
  console.log(cjenovnik == 'D', 'cjenovnik == D')
  if (cjenovnik == 'D') {
    getCjenovnikData(SKLAID, NOMENID)
    setTimeout(() => {
      console.log(result, 'result')
      if (!result) {
        console.log('ovdje bi trebalo da upada')
        izmjenaCijene = false;
        stavkaPronadjenaCjenovnik = false;
        console.log(SKLAID, 'SKLAID', NOMENID, 'NOMENID', PRODCENA, 'PRODCENA', NABCENA, 'NABCENA', SIFKOR, 'SIFKOR', izmjenaCijene, 'izmjenaCijene', stavkaPronadjenaCjenovnik, 'stavkaPronadjenaCjenovnik')
        onSubmitCjenovnik(SKLAID, NOMENID, PRODCENA, NABCENA, SIFKOR, izmjenaCijene, stavkaPronadjenaCjenovnik)
      } else {
        console.log('ovdje ne bi trebalo da upada')
        izmjenaCijene = true;
        stavkaPronadjenaCjenovnik = false;
        onSubmitCjenovnik(SKLAID, NOMENID, PRODCENA, NABCENA, SIFKOR, izmjenaCijene, stavkaPronadjenaCjenovnik)
      }
    }, 100);
  }

  console.log(cjenovnik == 'N', 'cjenovnik == N')
  if (cjenovnik == 'N') {
    getNomenData(NOMENID);
    setTimeout(() => {
      console.log(resultNomen, 'resultNomen')
      if (resultNomen) {
        let CENAM = VRSTA_SKLADISTA == 'M' ? PRODCENA : 0;
        let CENA = VRSTA_SKLADISTA == 'N' ? PRODCENA : 0;
        let MALCEN = VRSTA_SKLADISTA == 'V' ? PRODCENA : 0;
        stavkaPronadjena = true;
        let ID = NOMENID;
        onSubmitNomen(CENA, CENAM, MALCEN, ID, dataNomen);
      }
    }, 500);
  }
}
